/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../fragment/PageInfo/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductVariantsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.ProductVariantFilterInput>;
}>;


export type ProductVariantsQuery = { __typename?: 'Query', productVariants?: { __typename?: 'ProductVariantCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'ProductVariantCountableEdge', node: { __typename?: 'ProductVariant', id: string, name?: string | null, sku: string, product?: { __typename?: 'Product', name?: string | null, productType: { __typename?: 'ProductType', hasVariants: boolean, name?: string | null, id: string }, category?: { __typename?: 'Category', name?: string | null, id: string } | null, images?: Array<{ __typename?: 'ProductImage', alt: string, url: string, id: string } | null> | null } | null, attributes: Array<{ __typename?: 'SelectedAttribute', attribute: { __typename?: 'Attribute', id: string, name?: string | null }, values: Array<{ __typename?: 'AttributeValue', id: string, name?: string | null } | null> }>, vendorProductVariant?: { __typename?: 'VendorProductVariant', id: string, stocks?: Array<{ __typename?: 'Stock', quantity: number, branch?: { __typename?: 'Branch', id: string, name: string, nameAr?: string | null } | null } | null> | null, price?: { __typename?: 'Money', currency: string, amount: number } | null } | null, images?: Array<{ __typename?: 'ProductImage', alt: string, url: string, id: string } | null> | null } }> } | null };


export const ProductVariantsDocument = gql`
    query productVariants($first: Int, $after: String, $before: String, $last: Int, $filter: ProductVariantFilterInput) {
  productVariants(
    first: $first
    after: $after
    before: $before
    last: $last
    filter: $filter
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    totalCount
    edges {
      node {
        id
        name
        product {
          name
          productType {
            hasVariants
            name
            id
          }
          category {
            name
            id
          }
          images {
            alt
            url
            id
          }
        }
        attributes {
          attribute {
            id
            name
          }
          values {
            id
            name
          }
        }
        vendorProductVariant {
          id
          stocks {
            quantity
            branch {
              id
              name
              nameAr
            }
          }
          price {
            currency
            amount
          }
        }
        sku
        images {
          alt
          url
          id
        }
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useProductVariantsQuery__
 *
 * To run a query within a React component, call `useProductVariantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductVariantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductVariantsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProductVariantsQuery(baseOptions?: Apollo.QueryHookOptions<ProductVariantsQuery, ProductVariantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductVariantsQuery, ProductVariantsQueryVariables>(ProductVariantsDocument, options);
      }
export function useProductVariantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductVariantsQuery, ProductVariantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductVariantsQuery, ProductVariantsQueryVariables>(ProductVariantsDocument, options);
        }
export type ProductVariantsQueryHookResult = ReturnType<typeof useProductVariantsQuery>;
export type ProductVariantsLazyQueryHookResult = ReturnType<typeof useProductVariantsLazyQuery>;
export type ProductVariantsQueryResult = Apollo.QueryResult<ProductVariantsQuery, ProductVariantsQueryVariables>;