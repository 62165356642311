import { getAssetsFromFileStorage, UserContext } from "@health/common";
import { useTranslation } from "@health/i18n";
import { Container } from "@mui/material";
import React, { FC, useContext } from "react";
import { RoutingErrorPage } from "./RoutingErrorPage";
import { PagesProps } from "./types";

export const NotFoundContainer: FC<PagesProps> = ({ onHandleGoHome, onHandleLogIn }) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useContext(UserContext);

  return (
    <Container
      sx={{
        marginTop: "40px",
        marginBottom: "40px",
      }}
    >
      <RoutingErrorPage
        img={<img src={getAssetsFromFileStorage("error404.png")} alt='Error404' />}
        descriptionText={t("Sorry, page not found !")}
        buttonText1={t("Back to Home")}
        buttonText2={isAuthenticated ? undefined : t("Sign in")}
        actionButton1={onHandleGoHome}
        actionButton2={onHandleLogIn}
      />
    </Container>
  );
};
