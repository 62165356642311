import { formGirdBreakPoints, formGirdSpacing, getRequiredValidation, patternWebsite } from "@health/common";
import { useTranslation } from "@health/i18n";
import { VendorWebhook } from "@health/queries";
import { Box, Grid, TextField, ToggleButtonController } from "@health/ui";
import React, { FC } from "react";
import { useFormContext } from "react-hook-form";

interface InformationProps {
  loading: boolean;
  webHook: VendorWebhook;
}

const Information: FC<InformationProps> = props => {
  const { webHook } = props;
  const { t } = useTranslation();
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          placeholder={t("Secret Key")}
          label={t("Secret Key")}
          type='password'
          autoComplete='webhook-secretKey'
          {...register("secretKey", {
            required: getRequiredValidation(t, true),
          })}
          defaultValue={webHook?.secretKey}
          error={Boolean(errors.secretKey?.message)}
          helperText={t(errors.secretKey?.message)}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          defaultValue={webHook?.vendorUrl}
          placeholder={t("Provider URL")}
          label={t("Provider URL")}
          error={Boolean(errors.vendorUrl?.message)}
          helperText={t(errors.vendorUrl?.message)}
          {...register("vendorUrl", { required: getRequiredValidation(t, true), pattern: patternWebsite })}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <Box sx={{ display: "flex", height: 50, alignItems: "center" }}>
          <ToggleButtonController control={control} name='isActive' label={t("ActiveHook")} defaultValue={Boolean(webHook?.isActive)} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Information;
