import { toPascalCase } from "@health/common";
import { Button, Grid, Typography } from "@mui/material";
import { default as React, FC } from "react";
import { useActionsBarStyles } from "./ActionsBar.styles";
import { ActionsBarProps } from "./ActionsBar.types";

const ActionsBar: FC<ActionsBarProps> = props => {
  const { open, children, activeFilters = {}, direction = "top" } = props;
  const { classes } = useActionsBarStyles();

  const widthValue = () => {
    if (direction === "top") return "inherit";
    else return open ? "calc(100% - 250px)" : "100%";
  };
  const width = widthValue();
  const counter = Object.keys(activeFilters)?.length || 0;

  return (
    <Grid container className={direction === "top" ? `${classes.root}` : `${classes.root} ${classes.direction}`}>
      {activeFilters && (
        <Grid item md={4} className={classes.filters} component='div'>
          {Object.keys(activeFilters)
            ?.slice(0, 3)
            ?.map(filter => {
              return activeFilters[filter] ? (
                <Typography key={`active-filter-${filter}`} className={classes.filterItem} component='div'>
                  <Typography fontSize={10} className={classes.itemStyle}>
                    {toPascalCase(filter?.replace("_", " "))}
                  </Typography>
                  <Typography fontSize={14} fontFamily='Airbnb Cereal App Medium' className={classes.itemStyle}>
                    {activeFilters[filter]}
                  </Typography>
                </Typography>
              ) : null;
            })}
          <>
            {counter > 3 && (
              <Button sx={{ fontFamily: "Airbnb Cereal App Medium, Arab Kufi Medium" }} variant='text'>
                +{counter - 3} items
              </Button>
            )}
          </>
        </Grid>
      )}
      <Grid item md={8} component='div' width={width}>
        {children}
      </Grid>
    </Grid>
  );
};

export default ActionsBar;
