import { i18next } from "@health/i18n";
import { City, CityOrderField } from "@health/queries";
import { CustomTableColumnProps } from "@health/ui";

export const PharmacyListingCriteriaColumns = (t: i18next.TFunction): CustomTableColumnProps<City>[] => [
  {
    key: "Name",
    header: t("Name"),
    accessor: ({ name }) => name,
    type: "string",
    disableToggleVisibility: true,
    isSortable: true,
    sortDirection: undefined,
    sortColumnEnum: CityOrderField.Name,
    sx: {
      minWidth: "100px !important",
    },
  },
  {
    key: "maxRadiusForPickup",
    header: t("Max. radius for pickup"),
    accessor: ({ maxRadiusForPickup }) => maxRadiusForPickup,
    disableToggleVisibility: true,
  },
  {
    key: "Rounds",
    header: t("Max. num of Rounds"),
    accessor: ({ maxNumberOfRounds }) => maxNumberOfRounds,
    disableToggleVisibility: true,
  },
  {
    key: "Radius",
    header: t("Radius expansion each round"),
    accessor: ({ rounds }) => rounds?.map(r => r?.radius).join(" | "),
    disableToggleVisibility: true,
  },
  {
    key: "PharmaciesPerTry",
    header: t("Max. num of Pharmacies in each try"),
    accessor: ({ rounds }) => rounds?.map(r => r?.maxNumberOfPharmacies).join(" | "),
    disableToggleVisibility: true,
  },
  {
    key: "MaxNumOfTrials",
    header: t("Max. num of each try"),
    accessor: ({ maxNumberOfTries }) => maxNumberOfTries,
    disableToggleVisibility: true,
  },
  {
    key: "TimeoutToTry",
    header: t("Timeout to do next try (minutes)"),
    accessor: ({ timeOutPeriod }) => timeOutPeriod,
    disableToggleVisibility: true,
  },
];
