import { useTranslation } from "@health/i18n";
import { formatMessageErrors } from "@health/queries";
import { FormActions, FormCard, PageWrapper, useToasts } from "@health/ui";
import { useUpdateSubListItemMutation } from "admin-gql";
import React, { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useReportAnError from "shared/hooks/useLogError";
import { useQueryString } from "shared/hooks/useQueryString";
import { fromBase64 } from "shared/utils/queryStringUtils";
import { SubListItemInputs } from "../SubListForm/types";
import SubListItemForm from "./SubListItemForm";

const EditSubListItemForm: FC = () => {
  const { t } = useTranslation();
  const [fetchSaveItem, { loading: isSubmitting }] = useUpdateSubListItemMutation();
  const { addToast } = useToasts();
  const { itemId: id } = useParams();
  const query = useQueryString();
  const navigate = useNavigate();
  const { reportGraphQlErrors } = useReportAnError();

  const item = JSON.parse(fromBase64(query.get("item") || ""));
  const methods = useForm<SubListItemInputs>({
    mode: "all",
  });
  const addItem = (input: SubListItemInputs) => {
    fetchSaveItem({
      variables: {
        subListItem: {
          id: id,
          code: input.code,
          display: input.display,
        },
      },
      onCompleted: e => {
        if (e.updateSubListItem?.errors?.length) {
          addToast(t("Failed to update item") + "\n" + formatMessageErrors(e.updateSubListItem?.errors), {
            appearance: "error",
            autoDismiss: true,
          });
          return;
        }
        addToast(t("Item updated successfully"), {
          appearance: "success",
          autoDismiss: true,
        });
        navigate(-1);
      },
      onError: ({ graphQLErrors }) => reportGraphQlErrors(t("Failed to update sublist item"))(graphQLErrors),
    });
  };
  const handleSaveListItem = methods.handleSubmit(addItem);
  const handleNavigation = () => {
    navigate(-1);
  };

  return (
    <PageWrapper
      actions={
        <FormActions
          hasSave
          hasCancel
          onSave={handleSaveListItem}
          onNavigation={handleNavigation}
          loadingIndicators={{ save: isSubmitting }}
        />
      }
    >
      <FormCard loading={false} title={t("Edit item")} doYouHaveData={true}>
        <FormProvider {...methods}>
          <SubListItemForm onHandleSubmit={handleSaveListItem} defaults={item} />
        </FormProvider>
      </FormCard>
    </PageWrapper>
  );
};

export default EditSubListItemForm;
