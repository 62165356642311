/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminAttributesQueryVariables = Types.Exact<{
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.AttributeFilterInput>;
}>;


export type AdminAttributesQuery = { __typename?: 'Query', attributes?: { __typename?: 'AttributeCountableConnection', pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'AttributeCountableEdge', node: { __typename?: 'Attribute', id: string, name?: string | null, slug?: string | null, valueRequired: boolean, values?: Array<{ __typename?: 'AttributeValue', id: string, name?: string | null, slug?: string | null } | null> | null } }> } | null };


export const AdminAttributesDocument = gql`
    query adminAttributes($last: Int, $first: Int, $after: String, $before: String, $filter: AttributeFilterInput) {
  attributes(
    last: $last
    first: $first
    after: $after
    before: $before
    filter: $filter
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        id
        name
        slug
        valueRequired
        values {
          id
          name
          slug
        }
      }
    }
  }
}
    `;

/**
 * __useAdminAttributesQuery__
 *
 * To run a query within a React component, call `useAdminAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminAttributesQuery({
 *   variables: {
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminAttributesQuery(baseOptions?: Apollo.QueryHookOptions<AdminAttributesQuery, AdminAttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminAttributesQuery, AdminAttributesQueryVariables>(AdminAttributesDocument, options);
      }
export function useAdminAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminAttributesQuery, AdminAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminAttributesQuery, AdminAttributesQueryVariables>(AdminAttributesDocument, options);
        }
export type AdminAttributesQueryHookResult = ReturnType<typeof useAdminAttributesQuery>;
export type AdminAttributesLazyQueryHookResult = ReturnType<typeof useAdminAttributesLazyQuery>;
export type AdminAttributesQueryResult = Apollo.QueryResult<AdminAttributesQuery, AdminAttributesQueryVariables>;