import { i18n } from "@health/i18n";

export const getTranslationForObjProperty = (defaultValue, field: string, lng?: string) => {
  const language = lng || i18n?.language.toUpperCase();
  const selectedTranslation = defaultValue?.translations?.find(item => item?.language?.code === language);

  return lng === "AR" && !selectedTranslation ? selectedTranslation?.[field] : selectedTranslation?.[field] || defaultValue?.[field];
};

export const getTranslatedNameField = defaultValue => {
  const language = i18n?.language.toUpperCase();

  return language === "EN" ? defaultValue?.name : defaultValue?.nameAr || defaultValue?.name;
};

export const formatAddressFullTranslated = address => {
  const area = getTranslationForObjProperty(address, "area");

  const streetAddress1 = getTranslationForObjProperty(address, "streetAddress1");
  const streetAddress2 = getTranslationForObjProperty(address, "streetAddress2");
  const buildingNumber = getTranslationForObjProperty(address, "buildingNumber");
  const city = getTranslatedNameField(address?.city);

  return [buildingNumber, streetAddress1, streetAddress2, area, city, address?.postalCode].filter(Boolean).join(", ");
};
