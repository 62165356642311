/* eslint-disable react/jsx-handler-names */
import { useTranslation } from "@health/i18n";
import { Box, Button, CloudErrorIcon, Typography } from "@health/ui";
import React from "react";

// eslint-disable-next-line react/require-optimization
export const ErrorPage = () => {
  const { t } = useTranslation();
  return (
    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' height='100vh'>
      <CloudErrorIcon sx={{ height: "190px", width: "190px", fill: "#1B2346" }} />
      <Typography fontSize='40px' fontFamily='Airbnb Cereal App Book' justifyContent='center' textAlign='center'>
        {t("Something Wrong Happened")}
      </Typography>
      <Button onClick={() => window.location.reload()} sx={{ backgroundColor: "#1B2346", color: "white", margin: "20px 0" }}>
        {t("Reload")}
      </Button>
    </Box>
  );
};
