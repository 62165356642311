import { combineErrors, patternMobile } from "@health/common";
import { useTranslation } from "@health/i18n";
import { Box, Button, TextField, useIsMobileView } from "@health/ui";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { useForgetPasswordHooks } from "../ForgetPassword.hook";
import { FormErrors } from "../FormErrors.component";
import { useForgetPasswordStyle } from "../styles/useForgetPasswordStyle";
import { FormProps } from "./types";

export const ForgetPasswordForm: FC<FormProps> = ({ app, onChangeStep }) => {
  const { t } = useTranslation();

  const { classes } = useForgetPasswordStyle();
  const { ...methods } = useForm({
    mode: "all",
  });
  const { errorsForget, handleSubmitData, handleLogin } = useForgetPasswordHooks(app, onChangeStep);
  const {
    handleSubmit,
    register,
    formState: { errors: formErrorsData },
  } = methods;

  const fromErrors = combineErrors(formErrorsData, errorsForget);
  const isMobile = useIsMobileView();

  return (
    <div className={isMobile ? `${classes.container} ${classes.containerMobile}` : classes.container}>
      <form onSubmit={handleSubmit(handleSubmitData)}>
        <Box mb='15px'>
          <TextField
            fullWidth
            name='mobile'
            label={t("Mobile Number")}
            placeholder={t("Mobile Number")}
            inputProps={{ className: "mobile-phone-rtl-fix" }}
            error={Boolean(fromErrors?.mobile?.message)}
            helperText={
              <div
                dangerouslySetInnerHTML={{
                  __html: t(fromErrors?.mobile?.message, {
                    phone: "<span class='mobile-phone-rtl-fix'>+966999999999</span>",
                    interpolation: { escapeValue: false },
                  }),
                }}
              />
            }
            {...register("mobile", {
              required: { value: true, message: t("Mobile  is required") },
              pattern: patternMobile,
            })}
          />
        </Box>

        {errorsForget.length > 0 && <FormErrors errors={errorsForget} />}
        <Button type='submit' fullWidth className={classes.button}>
          {t("Request a verification code")}
        </Button>
        <Box m='5px 0px'>
          <Button variant='text' onClick={handleLogin}>
            {t("Back to Sign in")}
          </Button>
        </Box>
      </form>
    </div>
  );
};
