const medicalDeliveryRequest = "medical-delivery-request";
const newDeliveryRequest = "new-delivery-request";

export const medicalDeliveryRequestRoutes = {
  name: "Medical Delivery Request",
  path: medicalDeliveryRequest,
  fullPath: medicalDeliveryRequest,
  medicalDeliveryRequest: {
    name: "Medical Delivery Request",
    new: {
      name: "New Delivery Request",
      path: [medicalDeliveryRequest, newDeliveryRequest].join("/"),
      fullPath: [medicalDeliveryRequest, newDeliveryRequest].join("/"),
    },
  },
};
