import { split } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { httpLink } from "./httpLink";
import sseLink from "./sseLink";

export const splitLinkSSE =
  typeof window !== "undefined"
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return definition.kind === "OperationDefinition" && definition.operation === "subscription";
        },
        sseLink,
        httpLink
      )
    : httpLink;
