import { makeStyles } from "@health/ui";

export const useCardContentStyles = makeStyles()({
  text: {
    fontSize: 14,
  },
  textBold: {
    fontSize: 14,
    fontFamily: "Airbnb Cereal App Bold, Arab Kufi Bold",
  },
});
