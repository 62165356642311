import { getAssetsFromFileStorage } from "@health/common";
import { useTranslation } from "@health/i18n";
import { Container } from "@mui/material";
import React, { FC } from "react";
import { RoutingErrorPage } from "./RoutingErrorPage";
import { PagesProps } from "./types";

export const UnAuthorizedContainer: FC<PagesProps> = ({ onHandleGoHome }) => {
  const { t } = useTranslation();

  return (
    <Container
      sx={{
        marginTop: "40px",
        marginBottom: "40px",
      }}
    >
      <RoutingErrorPage
        img={<img src={getAssetsFromFileStorage("error403.png")} alt='Error403' />}
        descriptionText={t("Sorry, you are not authorized")}
        buttonText1={t("Back to Home")}
        actionButton1={onHandleGoHome}
      />
    </Container>
  );
};
