/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../fragment/PageInfo/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrdersPrescriptionsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.OrderFilterInput>;
}>;


export type OrdersPrescriptionsQuery = { __typename?: 'Query', orders?: { __typename?: 'OrderCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'OrderCountableEdge', node: { __typename: 'Order', id: string, type?: Types.OrderTypesEnum | null, number?: string | null, status: Types.OrderStatus, branch?: { __typename?: 'Branch', name: string, nameAr?: string | null, contactNumber?: string | null } | null } }> } | null };


export const OrdersPrescriptionsDocument = gql`
    query ordersPrescriptions($first: Int, $after: String, $filter: OrderFilterInput) {
  orders(first: $first, after: $after, filter: $filter) {
    totalCount
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        id
        type
        number
        status
        branch {
          name
          nameAr
          contactNumber
        }
        __typename
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useOrdersPrescriptionsQuery__
 *
 * To run a query within a React component, call `useOrdersPrescriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersPrescriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersPrescriptionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrdersPrescriptionsQuery(baseOptions?: Apollo.QueryHookOptions<OrdersPrescriptionsQuery, OrdersPrescriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersPrescriptionsQuery, OrdersPrescriptionsQueryVariables>(OrdersPrescriptionsDocument, options);
      }
export function useOrdersPrescriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersPrescriptionsQuery, OrdersPrescriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersPrescriptionsQuery, OrdersPrescriptionsQueryVariables>(OrdersPrescriptionsDocument, options);
        }
export type OrdersPrescriptionsQueryHookResult = ReturnType<typeof useOrdersPrescriptionsQuery>;
export type OrdersPrescriptionsLazyQueryHookResult = ReturnType<typeof useOrdersPrescriptionsLazyQuery>;
export type OrdersPrescriptionsQueryResult = Apollo.QueryResult<OrdersPrescriptionsQuery, OrdersPrescriptionsQueryVariables>;