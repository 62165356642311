/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type NotificationFragmentFragment = { __typename?: 'Notification', id: string, created: any, body?: string | null, category?: Types.NotificationCategory | null, extraData?: string | null, title?: string | null, modified: any, type: Types.NotificationType };

export const NotificationFragmentFragmentDoc = gql`
    fragment NotificationFragment on Notification {
  id
  created
  body
  category
  extraData
  title
  modified
  type
}
    `;