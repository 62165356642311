/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../fragment/PageInfo/__generated__/pageInfoFragment';
import { AddressFragmentFragmentDoc } from '../../../../fragment/address/__generated__/address';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BranchesQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.BranchFilterInput>;
  sortBy?: Types.InputMaybe<Types.BranchOrder>;
}>;


export type BranchesQuery = { __typename?: 'Query', branches?: { __typename?: 'BranchCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'BranchCountableEdge', node: { __typename?: 'Branch', id: string, isOpen?: boolean | null, isActive: boolean, name: string, healthLicense?: string | null, nameAr?: string | null, description: string, contactNumber?: string | null, created: any, type?: Types.BranchType | null, vendor: { __typename?: 'Vendor', id: string, logo?: string | null, description?: string | null, backGroundImage?: string | null, ownerName: string, nationalId: string, tradeName: string, taxLicenseNumber: string, isVip: boolean, hasMultipleBranches: boolean, bankInfo?: { __typename?: 'VendorBankInfo', id: string, bankName: string, accountNumber: string, iban: string } | null }, address?: { __typename: 'Address', id: string, name?: string | null, area?: string | null, streetAddress1?: string | null, streetAddress2?: string | null, buildingName?: string | null, buildingNumber?: string | null, phone?: string | null, isDefaultShippingAddress?: boolean | null, region?: string | null, city: { __typename?: 'City', id: string, name: string, nameAr?: string | null }, coordinates?: { __typename?: 'LocationType', lng?: number | null, lat?: number | null } | null, translations?: Array<{ __typename?: 'AddressTranslation', streetAddress1: string, streetAddress2: string, area: string, language: { __typename?: 'LanguageDisplay', code: Types.LanguageCodeEnum, language: string } } | null> | null } | null, workingHours?: Array<{ __typename?: 'WorkingHour', day?: Types.DayOfWeekEnum | null, openTimeRanges?: Array<{ __typename?: 'TimeRange', openTime?: any | null, closeTime?: any | null } | null> | null } | null> | null } }> } | null };

export type CreateBranchMutationVariables = Types.Exact<{
  branch: Types.BranchInput;
  vendorId: Types.Scalars['ID'];
}>;


export type CreateBranchMutation = { __typename?: 'Mutation', branchCreate?: { __typename?: 'BranchCreate', branchErrors: Array<{ __typename?: 'BranchError', field?: string | null, message?: string | null, code: Types.VendorErrorCode }>, branch?: { __typename?: 'Branch', id: string, name: string, isOpen?: boolean | null } | null } | null };


export const BranchesDocument = gql`
    query Branches($first: Int, $after: String, $before: String, $last: Int, $filter: BranchFilterInput, $sortBy: BranchOrder) {
  branches(
    first: $first
    after: $after
    before: $before
    last: $last
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        id
        isOpen
        isActive
        name
        healthLicense
        nameAr
        description
        vendor {
          id
          logo
        }
        address {
          ...AddressFragment
        }
        workingHours {
          day
          openTimeRanges {
            openTime
            closeTime
          }
        }
        contactNumber
        created
        vendor {
          id
          description
          logo
          backGroundImage
          ownerName
          nationalId
          tradeName
          taxLicenseNumber
          isVip
          hasMultipleBranches
          bankInfo {
            id
            bankName
            accountNumber
            iban
          }
        }
        type
      }
    }
    totalCount
  }
}
    ${PageInfoFragmentFragmentDoc}
${AddressFragmentFragmentDoc}`;

/**
 * __useBranchesQuery__
 *
 * To run a query within a React component, call `useBranchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useBranchesQuery(baseOptions?: Apollo.QueryHookOptions<BranchesQuery, BranchesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BranchesQuery, BranchesQueryVariables>(BranchesDocument, options);
      }
export function useBranchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BranchesQuery, BranchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BranchesQuery, BranchesQueryVariables>(BranchesDocument, options);
        }
export type BranchesQueryHookResult = ReturnType<typeof useBranchesQuery>;
export type BranchesLazyQueryHookResult = ReturnType<typeof useBranchesLazyQuery>;
export type BranchesQueryResult = Apollo.QueryResult<BranchesQuery, BranchesQueryVariables>;
export const CreateBranchDocument = gql`
    mutation CreateBranch($branch: BranchInput!, $vendorId: ID!) {
  branchCreate(input: $branch, vendorId: $vendorId) {
    branchErrors {
      field
      message
      code
    }
    branch {
      id
      name
      isOpen
    }
  }
}
    `;
export type CreateBranchMutationFn = Apollo.MutationFunction<CreateBranchMutation, CreateBranchMutationVariables>;

/**
 * __useCreateBranchMutation__
 *
 * To run a mutation, you first call `useCreateBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBranchMutation, { data, loading, error }] = useCreateBranchMutation({
 *   variables: {
 *      branch: // value for 'branch'
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useCreateBranchMutation(baseOptions?: Apollo.MutationHookOptions<CreateBranchMutation, CreateBranchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBranchMutation, CreateBranchMutationVariables>(CreateBranchDocument, options);
      }
export type CreateBranchMutationHookResult = ReturnType<typeof useCreateBranchMutation>;
export type CreateBranchMutationResult = Apollo.MutationResult<CreateBranchMutation>;
export type CreateBranchMutationOptions = Apollo.BaseMutationOptions<CreateBranchMutation, CreateBranchMutationVariables>;