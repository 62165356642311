/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConsumerViewPreferenceQueryVariables = Types.Exact<{
  view: Types.Scalars['String'];
}>;


export type ConsumerViewPreferenceQuery = { __typename?: 'Query', consumerViewPreference?: { __typename?: 'ConsumerViewPreference', id: string, view: string, data?: any | null, user: { __typename?: 'User', id: string } } | null };


export const ConsumerViewPreferenceDocument = gql`
    query consumerViewPreference($view: String!) {
  consumerViewPreference(view: $view) {
    id
    user {
      id
    }
    view
    data
  }
}
    `;

/**
 * __useConsumerViewPreferenceQuery__
 *
 * To run a query within a React component, call `useConsumerViewPreferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsumerViewPreferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsumerViewPreferenceQuery({
 *   variables: {
 *      view: // value for 'view'
 *   },
 * });
 */
export function useConsumerViewPreferenceQuery(baseOptions: Apollo.QueryHookOptions<ConsumerViewPreferenceQuery, ConsumerViewPreferenceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsumerViewPreferenceQuery, ConsumerViewPreferenceQueryVariables>(ConsumerViewPreferenceDocument, options);
      }
export function useConsumerViewPreferenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsumerViewPreferenceQuery, ConsumerViewPreferenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsumerViewPreferenceQuery, ConsumerViewPreferenceQueryVariables>(ConsumerViewPreferenceDocument, options);
        }
export type ConsumerViewPreferenceQueryHookResult = ReturnType<typeof useConsumerViewPreferenceQuery>;
export type ConsumerViewPreferenceLazyQueryHookResult = ReturnType<typeof useConsumerViewPreferenceLazyQuery>;
export type ConsumerViewPreferenceQueryResult = Apollo.QueryResult<ConsumerViewPreferenceQuery, ConsumerViewPreferenceQueryVariables>;