import { useTranslation } from "@health/i18n";
import { ApproveIcon, Button, CustomIcon, Divider, ExcelIcon, MenuItem, UploadIcon } from "@health/ui";
import React, { FC } from "react";
import { MedListActionsProps } from "./types";

export const MedListActions: FC<MedListActionsProps> = props => {
  const {
    selectedItems,
    hasClone,
    hasReject,
    hasApprove,
    hasPublish,
    hasReassign,
    hasDownload,
    hasImport,
    hasUnderReview,
    cloneDisabled,
    reviewerUserId,
    editorUserId,
    onCloneClicked: handleCloneClicked,
    onRejectClicked: handleRejectClicked,
    onImportClicked: handleImportClicked,
    onApproveClicked: handleApproveClicked,
    onPublishClicked: handlePublishClicked,
    onReassignClicked: handleReassignClicked,
    onDownloadClicked: handleDownloadClicked,
    onUnderReviewClicked: handleUnderReviewClicked,
    onCloseFetchToSubList: handleOpenFetchDialog,
  } = props;
  const { t } = useTranslation("Admin");
  return (
    <>
      {hasImport && (
        <MenuItem onClick={handleImportClicked}>
          <Button color='primary' variant='text' startIcon={<ExcelIcon fill='inherit' />}>
            {t("Import")} .xlsx
          </Button>
        </MenuItem>
      )}
      {hasDownload && (
        <MenuItem onClick={handleDownloadClicked}>
          <Button color='primary' variant='text' startIcon={<ExcelIcon fill='inherit' />}>
            {t("Download")} .xlsx
          </Button>
        </MenuItem>
      )}
      <Divider />
      {hasClone && cloneDisabled && (
        <MenuItem onClick={handleCloneClicked}>
          <Button color='primary' size='medium' variant='text' startIcon={<CustomIcon icon='clone' />} disabled={!cloneDisabled}>
            {t("Clone")}
          </Button>
        </MenuItem>
      )}
      {hasReject && (
        <MenuItem onClick={handleRejectClicked}>
          <Button disabled={!!selectedItems?.length} variant='text' startIcon={<CustomIcon icon='x' viewBox='0 0 20 20' />}>
            {t("Reject")}
          </Button>
        </MenuItem>
      )}
      {hasApprove && (
        <MenuItem onClick={handleApproveClicked}>
          <Button disabled={!!selectedItems?.length} variant='text' startIcon={<ApproveIcon color='inherit' />}>
            {t("Approve")}
          </Button>
        </MenuItem>
      )}
      {hasPublish && (
        <MenuItem onClick={handlePublishClicked}>
          <Button disabled={!!selectedItems?.length} variant='text' startIcon={<UploadIcon color='inherit' />}>
            {t("Publish")}
          </Button>
        </MenuItem>
      )}
      {hasUnderReview && (
        <MenuItem onClick={handleUnderReviewClicked}>
          <Button disabled={!!selectedItems?.length} variant='text' startIcon={<CustomIcon icon='share' />}>
            {t("Send To Review")}
          </Button>
        </MenuItem>
      )}
      {hasReassign && (
        <MenuItem onClick={handleReassignClicked}>
          <Button disabled={!!selectedItems?.length} variant='text' startIcon={<CustomIcon icon='correct' viewBox='0 0 20 20' />}>
            {editorUserId || reviewerUserId ? t("Reassign") : t("Assign")}
          </Button>
        </MenuItem>
      )}
      <MenuItem onClick={handleOpenFetchDialog}>
        <Button variant='text' startIcon={<CustomIcon icon='fetchToSubList' />}>
          {t("Fetch To Sublist")}
        </Button>
      </MenuItem>
    </>
  );
};
