import { i18n } from "@health/i18n";
import { Divider, IconButton, Typography } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import React, { FC } from "react";
import { ModalCloseIcon } from "../../icons";
import { useCustomMuiDialogStyle } from "./CustomDialog.style";
import { CustomMuiDialogTitleProps } from "./CustomMuiDialog.types";

export const CustomMuiDialogTitle: FC<CustomMuiDialogTitleProps> = props => {
  const { onClose: handleClose, title, modalCloseIcon, children, ...rest } = props;
  const { classes } = useCustomMuiDialogStyle();
  return (
    <React.Fragment>
      <DialogTitle className={classes.header} id='scroll-dialog-title' {...rest}>
        {title && (
          <Typography className={i18n.language === "en" ? classes.title : `${classes.title} ${classes.arabicTitle}`}>{title}</Typography>
        )}
        {children}
        <IconButton onClick={handleClose} sx={{ height: "30px", width: "30px" }}>
          {modalCloseIcon ? (
            React.cloneElement(modalCloseIcon, { sx: { width: "unset", height: "unset" } })
          ) : (
            <ModalCloseIcon sx={{ width: "unset", height: "unset" }} />
          )}
        </IconButton>
      </DialogTitle>
      <Divider className={classes.divider} />
    </React.Fragment>
  );
};
