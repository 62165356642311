import { Box, Button, SuccessIcon, Typography } from "@health/ui";
import React, { FC } from "react";
import { useSuccessStyle } from "../styles/useSuccessStyle";
import { SuccessViewComponentProps } from "./types";

export const SuccessViewComponent: FC<SuccessViewComponentProps> = props => {
  const { title, submitTitle, onChangeStep } = props;
  const { classes } = useSuccessStyle();

  const handleStart = () => {
    onChangeStep("login");
  };

  return (
    <Box margin='20px 15px 15px' textAlign='center'>
      <div>
        <SuccessIcon />
      </div>
      <Typography className={classes.text}>{title}</Typography>

      <Button variant='contained' type='submit' size='large' className={classes.button} onClick={handleStart}>
        {submitTitle}
      </Button>
    </Box>
  );
};
