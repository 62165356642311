import { useTranslation } from "@health/i18n";
import { formatMessageErrors, useProductQuery, useProductVariantCreateMutation } from "@health/queries";
import { useToasts } from "@health/ui";
import { useState } from "react";

const useVariantProduct = (id: string, idRoute: string) => {
  const { t } = useTranslation("Admin");
  const [successCreateVariant, setSuccessCreateVariant] = useState(false);
  const { addToast } = useToasts();

  const successfulCreation = () => {
    addToast(t("Product Variant Created Successfully"), {
      appearance: "success",
      autoDismiss: true,
    });
    setSuccessCreateVariant(true);
    setTimeout(() => setSuccessCreateVariant(false), 1000);
    refetch();
  };
  const successfully = () => {
    addToast(t("Product Variant Created Successfully"), {
      appearance: "success",
      autoDismiss: true,
    });
    setSuccessCreateVariant(true);
    setTimeout(() => setSuccessCreateVariant(false), 1000);
  };
  const [productVariantCreate, { loading: loadingCreateProductVariant, data }] = useProductVariantCreateMutation({
    onCompleted: data => {
      const errors = data?.productVariantCreate?.productErrors;
      refetch();
      if (errors) {
        if (errors?.length === 0) {
          successfulCreation();
        } else if (errors?.length > 0) {
          addToast(t(formatMessageErrors(errors)), {
            appearance: "error",
            autoDismiss: true,
          });
          console.error("<< Product Variant Creation >>", formatMessageErrors(errors));
        }
      } else {
        successfully();
      }
    },
    onError: () => {
      addToast(t("Product Variant Created Failed"), {
        appearance: "error",
        autoDismiss: true,
      });
    },
  });
  const handleSubmit = (data: any) => {
    productVariantCreate({
      variables: {
        input: {
          ...data,
        },
      },
    });
  };

  const {
    data: productData,
    refetch,
    loading: LoadingProduct,
  } = useProductQuery({
    variables: {
      id: id || idRoute,
    },
    skip: !(idRoute || id),
  });

  return {
    defaultProductData: productData?.product,
    successCreateVariant,
    data,
    loadingCreateProductVariant,
    LoadingProduct,
    productVariantCreate,
    refetch,
    handleSubmit,
  };
};

export default useVariantProduct;
