import { getRequiredValidation, lodashGet, MIN_VALUE_MESSAGE } from "@health/common";
import { useTranslation } from "@health/i18n";
import { StandardTextFieldProps, TextField } from "@health/ui";
import React, { FC } from "react";
import { RegisterOptions, useFormContext } from "react-hook-form";

export const NumericField: FC<{
  label: string;
  fieldName: string;
  defaultValue: string | number;
  required?: boolean;
  TextFieldProps?: Partial<StandardTextFieldProps>;
  registerOptions?: RegisterOptions;
}> = ({ label, fieldName, defaultValue, required = false, TextFieldProps = {}, registerOptions = {} }) => {
  const { t } = useTranslation("Admin");
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const handleKeyDown = e => {
    if (e.key == "Backspace" || e.key == "Delete") return;
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };
  return (
    <TextField
      label={label}
      placeholder={label}
      fullWidth
      inputMode='numeric'
      type='text'
      onKeyDown={handleKeyDown}
      defaultValue={defaultValue}
      inputProps={{ maxLength: 4 }}
      {...register(fieldName, {
        required: getRequiredValidation(t, required),
        min: {
          value: 1,
          message: t(MIN_VALUE_MESSAGE, { value: 1 }),
        },
        ...registerOptions,
      })}
      error={Boolean(lodashGet(errors, `${fieldName}.message`, ""))}
      helperText={lodashGet(errors, `${fieldName}.message`, "")}
      {...TextFieldProps}
    />
  );
};
NumericField.defaultProps = {
  required: false,
  TextFieldProps: {},
  registerOptions: {},
};
export default NumericField;
