/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
export type CategoryClientFragmentFragment = { __typename?: 'Category', id: string, name?: string | null, level: number, description?: string | null, seoTitle?: string | null, backgroundImage?: { __typename?: 'Image', alt?: string | null, url?: string | null } | null, icon?: { __typename?: 'Image', alt?: string | null, url?: string | null } | null };

export const CategoryClientFragmentFragmentDoc = gql`
    fragment CategoryClientFragment on Category {
  id
  name
  level
  description
  seoTitle
  backgroundImage {
    alt
    url
  }
  icon {
    alt
    url
  }
}
    `;