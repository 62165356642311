import { SvgIcon, SvgIconProps } from "@mui/material";
import React, { FC } from "react";

export const Approve: FC<SvgIconProps> = props => {
  const { color } = props;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='19' height='19' viewBox='0 0 19 19' {...props}>
      <path
        id='icons8-approval'
        d='M20.492,10.6l-.664-.4a1.269,1.269,0,0,1-.561-.767.158.158,0,0,0-.005-.018,1.267,1.267,0,0,1,.1-.954l.376-.677a1.05,1.05,0,0,0-.9-1.559l-.782-.013a1.27,1.27,0,0,1-.872-.387l-.007-.007a1.264,1.264,0,0,1-.387-.872l-.013-.782a1.05,1.05,0,0,0-1.56-.9l-.677.376a1.266,1.266,0,0,1-.954.1l-.018-.005a1.271,1.271,0,0,1-.767-.561l-.4-.664a1.049,1.049,0,0,0-1.8,0l-.4.661a1.282,1.282,0,0,1-.773.565l-.01,0a1.275,1.275,0,0,1-.962-.1l-.675-.375a1.049,1.049,0,0,0-1.559.9l-.013.782a1.27,1.27,0,0,1-.387.872l-.007.007a1.264,1.264,0,0,1-.872.387l-.782.013a1.05,1.05,0,0,0-.9,1.56l.376.677a1.267,1.267,0,0,1,.1.954.159.159,0,0,1-.005.018,1.271,1.271,0,0,1-.561.767l-.664.4a1.049,1.049,0,0,0,0,1.8l.664.4a1.269,1.269,0,0,1,.561.767.159.159,0,0,0,.005.018,1.267,1.267,0,0,1-.1.954l-.376.679a1.05,1.05,0,0,0,.9,1.559l.782.013a1.27,1.27,0,0,1,.872.387l.007.007a1.264,1.264,0,0,1,.387.872l.013.781a1.05,1.05,0,0,0,1.559.9l.677-.376a1.266,1.266,0,0,1,.954-.1l.018.005a1.271,1.271,0,0,1,.767.561l.4.664a1.049,1.049,0,0,0,1.8,0l.4-.664a1.269,1.269,0,0,1,.767-.561l.018-.005a1.267,1.267,0,0,1,.954.1l.677.376a1.05,1.05,0,0,0,1.559-.9l.013-.781a1.27,1.27,0,0,1,.387-.872l.007-.007a1.264,1.264,0,0,1,.872-.387l.782-.013a1.05,1.05,0,0,0,.9-1.559l-.376-.677a1.267,1.267,0,0,1-.1-.954.158.158,0,0,1,.005-.018,1.271,1.271,0,0,1,.561-.767l.664-.4A1.049,1.049,0,0,0,20.492,10.6Zm-4.821-.775-4.794,4.794a.73.73,0,0,1-1.033,0L7.321,12.1a.731.731,0,1,1,1.033-1.033l2.007,2.007,4.278-4.278a.731.731,0,1,1,1.033,1.033Z'
        transform='translate(-2 -2.001)'
        fill={color || "#fff"}
      />
    </SvgIcon>
  );
};
