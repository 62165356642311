import { SvgIcon, SvgIconProps } from "@mui/material";
import React, { FC } from "react";

export const Product: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='17.747' height='17.758' viewBox='0 0 17.747 17.758' {...props}>
      <path
        id='icons8-product'
        d='M12.829,3.967a.371.371,0,0,0-.04.013H6.875a.417.417,0,0,0-.185.092l-2.5,2.086-.04.026a.4.4,0,0,0-.053.04l-.013.013v.013H4.076v.026c-.01.013-.018.026-.026.04a.356.356,0,0,0-.04.079A.441.441,0,0,0,4,6.476a.318.318,0,0,0,0,.079V21.3a.423.423,0,0,0,.422.422h16.9a.423.423,0,0,0,.422-.422V6.581a.44.44,0,0,0,0-.066V6.449l-.013-.013V6.4a.2.2,0,0,0-.013-.026L21.7,6.357V6.331l-.013-.013a.2.2,0,0,0-.013-.026l-.013-.013-.013-.013a.2.2,0,0,0-.013-.026l-.013-.013-.013-.013L21.595,6.2l-.013-.013-.04-.026a.2.2,0,0,0-.026-.026l-2.469-2.06a.425.425,0,0,0-.264-.092H12.961A.4.4,0,0,0,12.829,3.967Zm-5.716.858h5.334V6.093H5.594Zm6.179,0h5.334l1.518,1.267H13.291ZM4.842,6.938H20.9V20.879H4.842Zm6.548,1.69a1.056,1.056,0,0,0,0,2.112h2.957a1.056,1.056,0,0,0,0-2.112Zm0,.845h2.957a.211.211,0,1,1,0,.422H11.39a.211.211,0,0,1,0-.422ZM15.4,15.387l-1.267,1.267h.845v1.69h.845v-1.69h.845Zm2.957,0-1.267,1.267h.845v1.69h.845v-1.69h.845Zm-4.225,3.38v.845h5.492v-.845Z'
        transform='translate(-3.994 -3.966)'
      />
    </SvgIcon>
  );
};
