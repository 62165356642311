import { Permission } from "@health/queries";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import React, { FC, useEffect, useState } from "react";
import { useCustomListStyle } from "./CustomTransferList.styles";
import { ListComponent } from "./List.component";

export const CustomList: FC<{
  title: string;
  items: Permission | any | undefined;
  handleToggle: (value) => () => void;
  checked: Permission | any | undefined;
  handleSearch: (value) => void;
  searchedValue: any;
}> = ({ title, items, handleToggle: onToggle, checked, handleSearch: onSearch, searchedValue }) => {
  const { classes } = useCustomListStyle();
  const [itemsWithSearch, setItemsWithSearch] = useState(items);

  useEffect(() => {
    if (searchedValue) {
      const _items = items.filter(el => el.name.toLowerCase().includes(searchedValue.toLowerCase()));
      setItemsWithSearch(_items.slice(0));
    } else {
      setItemsWithSearch(items);
    }
  }, [searchedValue, JSON.stringify(items)]);

  return (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 2 }}
        title={title}
        classes={{
          title: classes.title,
        }}
      />
      <Divider className={classes.divider} />
      <React.Fragment>
        <ListComponent
          items={itemsWithSearch}
          handleToggle={onToggle}
          checked={checked}
          handleSearch={e => onSearch(e.target.value)}
          searchedValue={searchedValue}
        />
      </React.Fragment>
    </Card>
  );
};
