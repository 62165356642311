import { i18next } from "@health/i18n";
import { ProductVariant } from "@health/queries";
import { CustomTableColumnProps, textTruncate } from "@health/ui";

export const VariantColumns = (t: i18next.TFunction): CustomTableColumnProps<ProductVariant>[] => [
  {
    key: "sku",
    header: t("SKU"),
    accessor: ({ sku }) => textTruncate(sku, 20),
  },
  {
    key: "variant",
    header: t("Variant"),
    accessor: ({ name }) => textTruncate(name, 20),
  },
];
