import {
  ActiveFilters,
  CustomTable,
  CustomTableColumnProps,
  PageWrapper,
  StaticFilterDialog,
  TableSettings,
  tableSettingsUtils,
} from "@health/ui";
import React from "react";
import { useMarketplaceOrdersTrackingPage } from "./useMarketplaceOrdersTrackingPage";
import { useMarketplaceOrdersTrackingScreenColumns } from "./useMarketplaceOrdersTrackingScreenColumns";

export const MarketplaceOrdersTrackingScreen = () => {
  const {
    filters,
    prescriptions,
    isLoading,
    pageSize,
    pageInfo,
    totalCount,
    activeFilters,
    isFilterOpened,
    activeFiltersDisplay,
    handleGotoNext,
    handleGoToPrevious,
    handlePageSizeChange,
    handleSortData,
    handleApplyFilters,
    handleToggleFilters,
  } = useMarketplaceOrdersTrackingPage();
  const { columns, setColumns } = useMarketplaceOrdersTrackingScreenColumns();

  const handleToggleColumnVisibility = updatedColumn => {
    setColumns(tableSettingsUtils.toggleColumnVisibility(columns, updatedColumn));
  };

  const handleSetAllColumnVisibility = (isHidden: boolean) => {
    setColumns(tableSettingsUtils.toggleAllColumnsVisibility(columns, isHidden));
  };
  return (
    <PageWrapper
      start={<ActiveFilters onOpenFilters={handleToggleFilters} activeFilters={activeFiltersDisplay} i18nNs='Admin' />}
      actions={
        <>
          <StaticFilterDialog
            isOpen={isFilterOpened}
            onToggleDialog={handleToggleFilters}
            activeFilters={activeFilters}
            filterFields={filters}
            onApplyFilters={handleApplyFilters}
          />
          <TableSettings
            columns={columns! as CustomTableColumnProps<Record<string, unknown>>[]}
            onToggleColumnVisibility={handleToggleColumnVisibility}
            onToggleAllColumnsVisibility={handleSetAllColumnVisibility}
          />
        </>
      }
    >
      <CustomTable
        totalCount={totalCount}
        data={prescriptions || []}
        columns={columns}
        isLoading={isLoading}
        isEditVisible={false}
        isDeleteVisible={false}
        pageSize={pageSize}
        pageIndex={1}
        pagesCount={1}
        hasNextPage={pageInfo?.hasNextPage}
        hasPreviousPage={pageInfo?.hasPreviousPage}
        onGoToNext={handleGotoNext}
        onGoToPrevious={handleGoToPrevious}
        onPageSizeChange={handlePageSizeChange}
        onSortColumn={handleSortData}
      />
    </PageWrapper>
  );
};
