/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnreadMessagesCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UnreadMessagesCountQuery = { __typename?: 'Query', unreadMessagesCount?: { __typename?: 'User', unReadMessagesCount?: number | null, id: string, email: string, firstName?: string | null, lastName?: string | null } | null };


export const UnreadMessagesCountDocument = gql`
    query unreadMessagesCount {
  unreadMessagesCount: me {
    unReadMessagesCount
    id
    email
    firstName
    lastName
  }
}
    `;

/**
 * __useUnreadMessagesCountQuery__
 *
 * To run a query within a React component, call `useUnreadMessagesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnreadMessagesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnreadMessagesCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnreadMessagesCountQuery(baseOptions?: Apollo.QueryHookOptions<UnreadMessagesCountQuery, UnreadMessagesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnreadMessagesCountQuery, UnreadMessagesCountQueryVariables>(UnreadMessagesCountDocument, options);
      }
export function useUnreadMessagesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnreadMessagesCountQuery, UnreadMessagesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnreadMessagesCountQuery, UnreadMessagesCountQueryVariables>(UnreadMessagesCountDocument, options);
        }
export type UnreadMessagesCountQueryHookResult = ReturnType<typeof useUnreadMessagesCountQuery>;
export type UnreadMessagesCountLazyQueryHookResult = ReturnType<typeof useUnreadMessagesCountLazyQuery>;
export type UnreadMessagesCountQueryResult = Apollo.QueryResult<UnreadMessagesCountQuery, UnreadMessagesCountQueryVariables>;