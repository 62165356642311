import { formatUserNameFull } from "@health/common";
import { useTranslation } from "@health/i18n";
import { Order, OrderDirection, OrderSortField } from "@health/queries";
import { Box, CustomTableColumnProps, getTranslatedNameField, TruncateTypography } from "@health/ui";
import moment from "moment-timezone";
import "moment/locale/ar";
import "moment/locale/en-il";
import React, { useState } from "react";
import { useAppType } from "../hooks/useAppType";
import { LifeCycleModal } from "./components/LifeCycleModal.components";
import { OpenLinesDialogButton } from "./components/OpenLinesDialog";
import { Status } from "./components/Status.components";

export const useMarketplaceOrdersTrackingScreenColumns = () => {
  const { t, i18n } = useTranslation("Admin");
  const { isAdminApp } = useAppType();
  const [columns, setColumns] = useState<CustomTableColumnProps<Order>[]>(
    [
      {
        key: "number",
        header: t("Order Number"),
        accessor: ({ number }) => number,
        isSortable: true,
        sortDirection: OrderDirection?.Asc,
        sortColumnEnum: OrderSortField.Number,
      },
      isAdminApp && {
        key: "Vendor",
        header: t("Vendor"),
        accessor: ({ branch }) => <TruncateTypography text={`${getTranslatedNameField(branch?.vendor)}`} />,
      },
      {
        key: "Branch",
        header: t("Branch"),
        accessor: ({ branch }) => <TruncateTypography text={`${getTranslatedNameField(branch)}`} />,
      },
      {
        key: "Customer",
        header: t("Customer"),
        accessor: ({ user }) => <TruncateTypography text={formatUserNameFull(user)} />,
      },

      {
        key: "Mobile",
        header: t("Mobile"),
        accessor: ({ user }) => <span className='mobile-phone-rtl-fix'>{String(user?.mobile || "-")}</span>,
      },
      {
        key: "Payment Price",
        header: t("Payment Price"),
        accessor: ({ total }) => `${total?.gross?.amount || 0} ${total?.gross?.currency}`,
      },

      {
        key: "Order Status",
        header: <Box sx={{ minWidth: 150 }}>{t("Order Status")}</Box>,

        accessor: ({ status }) => <Status status={status} />,
      } as CustomTableColumnProps<Order>,
      {
        key: "Delivery Status",
        header: <Box sx={{ minWidth: 180 }}>{t("Delivery Status")}</Box>,
        accessor: ({ deliveryStatus }) => <Status status={deliveryStatus} />,
      },
      {
        key: "Products",
        header: t("Products"),
        accessor: ({ lines }) => <OpenLinesDialogButton lines={(lines! as any) || []} />,
      },

      {
        key: "auditHistory",
        header: t("Audit History"),
        accessor: ({ number, prescription, shipmentInformation }) => (
          <LifeCycleModal
            isMarketPlaceOrder
            number={number!}
            dispenseStatus={prescription?.dispenseStatus!}
            prescriptionID={prescription?.id!}
            referenceNumber={prescription?.referenceNumber!}
            shipmentInformation={shipmentInformation?.data}
          />
        ),
      },
      {
        key: "Created",
        header: t("Created"),
        accessor: ({ created }) => {
          moment.localeData("ar");
          const createdTz = moment.tz.guess();
          moment.locale(i18n.language);
          return moment.tz(created, createdTz).utc().format("DD MMM YYYY hh:mm a");
        },
        isSortable: true,
        sortDirection: OrderDirection?.Asc,
        sortColumnEnum: OrderSortField.CreationDate,
      },
    ].filter(Boolean) as CustomTableColumnProps<Order>[]
  );
  return {
    columns,
    setColumns,
  };
};
