/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TicketPrescriptionDispenseMutationVariables = Types.Exact<{
  input: Types.TicketPrescriptionDispenseInput;
}>;


export type TicketPrescriptionDispenseMutation = { __typename?: 'Mutation', ticketPrescriptionDispense?: { __typename?: 'TicketPrescriptionDispense', order?: { __typename?: 'Order', id: string, number?: string | null, status: Types.OrderStatus, source: Types.OrderSource, modified: any, deliveryStatus?: string | null, isFreeDelivery: boolean } | null, orderErrors: Array<{ __typename?: 'OrderError', message?: string | null, field?: string | null, code: Types.OrderErrorCode, branch?: string | null, orderLine?: string | null }> } | null };


export const TicketPrescriptionDispenseDocument = gql`
    mutation ticketPrescriptionDispense($input: TicketPrescriptionDispenseInput!) {
  ticketPrescriptionDispense(input: $input) {
    order {
      id
      number
      status
      source
      modified
      deliveryStatus
      isFreeDelivery
    }
    orderErrors {
      message
      field
      code
      branch
      orderLine
    }
  }
}
    `;
export type TicketPrescriptionDispenseMutationFn = Apollo.MutationFunction<TicketPrescriptionDispenseMutation, TicketPrescriptionDispenseMutationVariables>;

/**
 * __useTicketPrescriptionDispenseMutation__
 *
 * To run a mutation, you first call `useTicketPrescriptionDispenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTicketPrescriptionDispenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ticketPrescriptionDispenseMutation, { data, loading, error }] = useTicketPrescriptionDispenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTicketPrescriptionDispenseMutation(baseOptions?: Apollo.MutationHookOptions<TicketPrescriptionDispenseMutation, TicketPrescriptionDispenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TicketPrescriptionDispenseMutation, TicketPrescriptionDispenseMutationVariables>(TicketPrescriptionDispenseDocument, options);
      }
export type TicketPrescriptionDispenseMutationHookResult = ReturnType<typeof useTicketPrescriptionDispenseMutation>;
export type TicketPrescriptionDispenseMutationResult = Apollo.MutationResult<TicketPrescriptionDispenseMutation>;
export type TicketPrescriptionDispenseMutationOptions = Apollo.BaseMutationOptions<TicketPrescriptionDispenseMutation, TicketPrescriptionDispenseMutationVariables>;