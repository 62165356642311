/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConfirmAccountMobileMutationVariables = Types.Exact<{
  mobile: Types.Scalars['String'];
  sessionToken: Types.Scalars['String'];
  token: Types.Scalars['String'];
}>;


export type ConfirmAccountMobileMutation = { __typename?: 'Mutation', confirmAccountMobile?: { __typename?: 'ConfirmAccountMobile', success?: boolean | null, accountErrors: Array<{ __typename?: 'AccountError', message?: string | null, field?: string | null }> } | null };


export const ConfirmAccountMobileDocument = gql`
    mutation confirmAccountMobile($mobile: String!, $sessionToken: String!, $token: String!) {
  confirmAccountMobile(
    mobile: $mobile
    sessionToken: $sessionToken
    token: $token
  ) {
    success
    accountErrors {
      message
      field
    }
  }
}
    `;
export type ConfirmAccountMobileMutationFn = Apollo.MutationFunction<ConfirmAccountMobileMutation, ConfirmAccountMobileMutationVariables>;

/**
 * __useConfirmAccountMobileMutation__
 *
 * To run a mutation, you first call `useConfirmAccountMobileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmAccountMobileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmAccountMobileMutation, { data, loading, error }] = useConfirmAccountMobileMutation({
 *   variables: {
 *      mobile: // value for 'mobile'
 *      sessionToken: // value for 'sessionToken'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmAccountMobileMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmAccountMobileMutation, ConfirmAccountMobileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmAccountMobileMutation, ConfirmAccountMobileMutationVariables>(ConfirmAccountMobileDocument, options);
      }
export type ConfirmAccountMobileMutationHookResult = ReturnType<typeof useConfirmAccountMobileMutation>;
export type ConfirmAccountMobileMutationResult = Apollo.MutationResult<ConfirmAccountMobileMutation>;
export type ConfirmAccountMobileMutationOptions = Apollo.BaseMutationOptions<ConfirmAccountMobileMutation, ConfirmAccountMobileMutationVariables>;