import { capitalize } from "@health/common";
import {
  OrderDeliveryStatusEnum,
  OrderEventsEnum,
  OrderLineAuthStatus,
  OrderSource,
  OrderStatus,
  OrderStatusEnum,
  OrderTypesEnum,
} from "@health/queries";
import { darkBlue, darkRed, green } from "@health/ui";

export const getStatus = item => Object.keys(OrderStatus).filter(status => OrderStatus[status] === item);
export const getType = item => Object.keys(OrderTypesEnum).filter(status => OrderTypesEnum[status] === item);
export const getTypeEvent = item => capitalize(item.toLowerCase().split("_"));

export const statuses = Object.values(OrderStatusEnum)
  .map(value => ({
    id: value,
    display: capitalize(value.toLowerCase().split("_")),
  }))
  .filter(
    item =>
      !(
        item.id === OrderStatusEnum.Draft ||
        item.id === OrderStatusEnum.Unfulfilled ||
        item.id === OrderStatusEnum.InProgress ||
        item.id === OrderStatusEnum.Fulfilled ||
        item.id === OrderStatusEnum.PartiallyFulfilled
      )
  );

export const deliveryStatus = Object.values(OrderDeliveryStatusEnum).map(value => ({
  id: value,
  display: capitalize(value.toLowerCase().split("_")),
}));

export const getAuthStatusColor = (authStatus: OrderLineAuthStatus) => {
  let color = darkBlue;

  if (authStatus === OrderLineAuthStatus.Approved) {
    color = green;
  } else if (authStatus === OrderLineAuthStatus.Rejected) {
    color = darkRed;
  }

  return color;
};
export const isDeliveryStatus = (orderEvent: OrderEventsEnum) => {
  return (
    orderEvent === OrderEventsEnum.DeliveryOrderCreated ||
    orderEvent === OrderEventsEnum.OrderDeliveryFailed ||
    orderEvent === OrderEventsEnum.OrderDeliveryIsCancelled ||
    orderEvent === OrderEventsEnum.OrderDeliveryIsResumed ||
    orderEvent === OrderEventsEnum.OrderDeliveryIsSuspended ||
    orderEvent === OrderEventsEnum.OrderDeliveryTimedOut ||
    orderEvent === OrderEventsEnum.OutForDelivery
  );
};
export const sourceValues = Object.values(OrderSource).map(value => ({
  id: value,
  display: capitalize(value.toLowerCase().split("_")),
}));
