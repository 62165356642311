import { TFunction } from "@health/i18n";
import { ProcessingStatus } from "@health/queries";
import { darkGrey, darkRed, green, orange, primary, tealBlue } from "@health/ui";

export const getCodeSystemProcessingStatusColor = lastCadeSystemProcessingStatus => {
  switch (lastCadeSystemProcessingStatus) {
    case ProcessingStatus.UnderReview:
      return orange;
    case ProcessingStatus.Approved:
      return tealBlue;
    case ProcessingStatus.Published:
      return green;
    case ProcessingStatus.Rejected:
      return darkRed;
    case ProcessingStatus.Expired:
      return primary;
    case ProcessingStatus.Draft:
      return darkGrey;
    default:
      return primary;
  }
};

export const getCodeSystemProcessingStatusText = (t: TFunction<"Admin", undefined>, lastCadeSystemProcessingStatus: ProcessingStatus) => {
  switch (lastCadeSystemProcessingStatus) {
    case ProcessingStatus.UnderReview:
      return t("Under review");
    case ProcessingStatus.Approved:
      return t("Approved");
    case ProcessingStatus.Draft:
      return t("Draft");
    case ProcessingStatus.Expired:
      return t("Expired");
    case ProcessingStatus.Published:
      return t("Published");
    case ProcessingStatus.Rejected:
      return t("Rejected");
    default:
      return t("No Data ");
  }
};
