import { NetworkStatus } from "@apollo/client";
import { useMemo } from "react";
import { AutocompleteCustomHProps } from "./types";

export const useAutocompleteCustomHook = ({
  useAutocompleteControllerQuery,
  input,
  onCompleted,
  onError,
  dataAccessor,
}: AutocompleteCustomHProps) => {
  const queryVariables = useMemo(() => input, [JSON.stringify(input)]);
  const { data, loading, networkStatus, fetchMore, refetch, ...queryResult } = useAutocompleteControllerQuery({
    variables: { ...queryVariables },
    onCompleted: onCompleted,
    onError: onError,
  });

  const onFetchMoreData = () => {
    if (data?.[dataAccessor]?.pageInfo?.hasNextPage) {
      fetchMore({
        variables: { ...queryVariables, after: data?.[dataAccessor]?.pageInfo?.endCursor },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          if (!fetchMoreResult) return prev;
          return Object.assign({}, prev, {
            ...fetchMoreResult,
            [dataAccessor]: {
              ...fetchMoreResult?.[dataAccessor],
              edges: [...prev?.[dataAccessor]?.edges, ...fetchMoreResult?.[dataAccessor]?.edges],
            },
          });
        },
      });
    }
  };

  const isLoading: boolean = loading || networkStatus === NetworkStatus.fetchMore || networkStatus === NetworkStatus.refetch;

  return {
    ...queryResult,
    data,
    loading,
    isLoading,
    fetchMore,
    refetch,
    input: queryVariables,
    networkStatus,
    onFetchMoreData,
  };
};
