import { UserContext } from "@health/common";
import { useTranslation } from "@health/i18n";
import { formatMessageErrors, useSetVendorWebHookMutation, useVendorWebHookQuery, Vendor, VendorWebhookInput } from "@health/queries";
import { useToasts } from "@health/ui";
import { useContext } from "react";
import { useForm } from "react-hook-form";

export type CustomVendorWebHookInput = Omit<VendorWebhookInput, "vendor">;
export const useVendorWebHookFormHooks = (vendor?: Vendor, shouldSkip?: boolean, handleToggle?: () => void) => {
  const { addToast } = useToasts();
  const { user } = useContext(UserContext);

  const methods = useForm<CustomVendorWebHookInput>({
    mode: "onChange",
  });

  const { handleSubmit } = methods;

  const { t } = useTranslation();

  const { data: webHook, loading } = useVendorWebHookQuery({
    variables: {
      vendorId: vendor?.id ?? user?.vendor?.id,
    },
    fetchPolicy: "network-only",
    skip: shouldSkip,
  });

  const [setVendorWebHook, { loading: isSubmitting }] = useSetVendorWebHookMutation({
    onCompleted: response => {
      const webHookError = response?.setVendorWebHook?.errors;
      if (webHookError?.length) {
        addToast(formatMessageErrors(webHookError), {
          appearance: "error",
          autoDismiss: true,
        });
        console.error("<< User Create >>", formatMessageErrors(webHookError));
      } else {
        addToast(t("Provider webhook saved successfully"), {
          appearance: "success",
          autoDismiss: true,
        });
        handleToggle?.();
      }
    },
    onError: () => {
      addToast(t("Saving webhook failed"), {
        appearance: "error",
        autoDismiss: true,
      });
    },
  });

  const onSubmit = (data: CustomVendorWebHookInput) => {
    setVendorWebHook({
      variables: {
        inputs: {
          vendor: vendor?.id ?? user?.vendor?.id,
          ...data,
        },
      },
    });
  };

  return { t, methods, webHook, loading, isSubmitting, onSubmit, handleSubmit };
};
