import { useTranslation } from "@health/i18n";
import { formatMessageErrors, SiteSettingsInput, useSiteSettingsQuery, useUpdateSiteSettingsMutation } from "@health/queries";
import { useToasts } from "@health/ui";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useReportAnError from "shared/hooks/useLogError";
import { convertSettingsToBeInMinutes, convertSettingsToBeInSeconds } from "./formUtils";
import { OrderTimeoutsFormInputs } from "./types";

export const useOrderTimeoutFormSettings = () => {
  const methods = useForm<OrderTimeoutsFormInputs>({
    mode: "all",
  });
  const navigate = useNavigate();

  const { data, loading } = useSiteSettingsQuery();
  const defaults = convertSettingsToBeInMinutes(data?.siteSettings as any);
  const { handleSubmit } = methods;
  const { t } = useTranslation("Admin");
  const [update, { loading: isSubmitting }] = useUpdateSiteSettingsMutation();
  const { reportGraphQlErrors } = useReportAnError();
  const { addToast } = useToasts();
  const onSubmit = (settings?: OrderTimeoutsFormInputs) => {
    update({
      variables: {
        input: {
          ...convertSettingsToBeInSeconds(settings),
        } as SiteSettingsInput,
      },
      onCompleted: e => {
        if (e.siteSettingsUpdate?.errors?.length) {
          addToast(formatMessageErrors(e.siteSettingsUpdate?.errors), { appearance: "error", autoDismiss: true });
          return;
        }
        addToast(t("Updated successfully"), {
          appearance: "success",
          autoDismiss: true,
        });
      },
      onError: ({ graphQLErrors }) => reportGraphQlErrors(t("Error saving settings"))(graphQLErrors),
    });
  };
  const handleNavigation = () => {
    navigate(-1);
  };

  return {
    defaults,
    methods,
    isSubmitting,
    handleSubmit,
    onSubmit,
    loading,
    handleNavigation,
  };
};
