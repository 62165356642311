import { makeStyles } from "@health/ui";

export const useStyles = makeStyles()(() => ({
  container: {
    height: "100%",
  },
  backDropStyle: {
    backgroundColor: "rgba(0,0,0,0.7)",
  },
  paperStyle: {
    minHeight: 460,
    borderRadius: 15,
  },
  dialogContainerStyle: {
    boxShadow: "0px 0px 6px #0000001A",
  },
  dialogContentContainer: { overflowY: "initial" },
  componentRoot: {
    height: 50,
    borderRadius: 10,
  },
  progressRoot: {
    borderRadius: 10,
    backgroundColor: "#C9D5E2",
  },
  dragArea: {
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: "1rem",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
}));
