import { useTranslation } from "@health/i18n";
import { Prescription, TicketPrescriptionDispenseInput } from "@health/queries";
import { FormActions, Grid, PageWrapper } from "@health/ui";
import React, { FC } from "react";
import { FormProvider } from "react-hook-form";
import { formGirdSpacing } from "shared/constants";
import LocationCard from "./components/Location/LocationCardForm.component";
import { OrderInformation } from "./components/OrderInformation/OrderInformation.component";
import { useManualPrescriptionOrderForm } from "./ManualPrescriptionOrderForm.hook";

type ManualPrescriptionOrderFormProps = {
  isEditMode?: boolean;
  isLoading?: boolean;
  prescription?: Partial<Prescription>;
  onDone: (data: TicketPrescriptionDispenseInput) => void;
  orderErrors;
};

export const ManualPrescriptionOrderForm: FC<ManualPrescriptionOrderFormProps> = ({ isLoading, onDone, orderErrors: errorsInput }) => {
  const { t } = useTranslation("Admin");
  const { methods, handleSubmit, handleCancel, handleClick } = useManualPrescriptionOrderForm({ onDone });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleClick)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              newButtonDisabled={isLoading}
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={t("Create")}
              onNavigation={handleCancel}
            />
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <OrderInformation errorsInput={errorsInput} />
            </Grid>
            <Grid item xs={12}>
              <LocationCard errorsInput={errorsInput} />
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};

ManualPrescriptionOrderForm.defaultProps = {
  isEditMode: undefined,
  isLoading: undefined,
  prescription: undefined,
};
