import Box from "@mui/material/Box";
import React, { FC, memo } from "react";
import { useDotStyle } from "./dot.styles";
import { DotProps } from "./dot.types";

const Dot: FC<DotProps> = props => {
  const {
    classes: { root },
  } = useDotStyle({ ...props });

  return <Box className={root} />;
};

export default memo(Dot);
