import { TFunction } from "@health/i18n";
import { darkRed, green, orange } from "../../customization/colors";

export const mapAppearanceToCardColor = (appearance: string) => {
  if (appearance === "success") {
    return green;
  } else if (appearance === "warning") {
    return orange;
  } else if (appearance === "error") {
    return darkRed;
  }
};

export const mapAppearanceToSelectedIcon = (appearance: string) => {
  if (appearance === "success") {
    return "successAlert";
  } else if (appearance === "warning" || appearance === "error") {
    return "warningAlert";
  }
};

export const mapAppearanceToCardTitle = (appearance: string, t: TFunction) => {
  if (appearance === "success") {
    return t("Success");
  } else if (appearance === "warning") {
    return t("Warning");
  } else if (appearance === "error") {
    return t("Error");
  }
};
