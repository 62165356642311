/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { LanguageDisplayFragmentDoc } from '../../../../fragment/languageDisplay/__generated__/languageDisplay';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClientProfileAddressesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ClientProfileAddressesQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, firstName?: string | null, addresses?: Array<{ __typename?: 'Address', name?: string | null, streetAddress1?: string | null, area?: string | null, postalCode: string, phone?: string | null, isDefaultShippingAddress?: boolean | null, city: { __typename?: 'City', name: string, nameAr?: string | null }, translations?: Array<{ __typename?: 'AddressTranslation', streetAddress1: string, area: string, language: { __typename?: 'LanguageDisplay', code: Types.LanguageCodeEnum, language: string } } | null> | null } | null> | null } | null };


export const ClientProfileAddressesDocument = gql`
    query ClientProfileAddresses {
  me {
    id
    firstName
    addresses {
      name
      streetAddress1
      area
      city {
        name
        nameAr
      }
      translations {
        streetAddress1
        area
        language {
          ...languageDisplay
        }
      }
      postalCode
      phone
      isDefaultShippingAddress
    }
  }
}
    ${LanguageDisplayFragmentDoc}`;

/**
 * __useClientProfileAddressesQuery__
 *
 * To run a query within a React component, call `useClientProfileAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientProfileAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientProfileAddressesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientProfileAddressesQuery(baseOptions?: Apollo.QueryHookOptions<ClientProfileAddressesQuery, ClientProfileAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientProfileAddressesQuery, ClientProfileAddressesQueryVariables>(ClientProfileAddressesDocument, options);
      }
export function useClientProfileAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientProfileAddressesQuery, ClientProfileAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientProfileAddressesQuery, ClientProfileAddressesQueryVariables>(ClientProfileAddressesDocument, options);
        }
export type ClientProfileAddressesQueryHookResult = ReturnType<typeof useClientProfileAddressesQuery>;
export type ClientProfileAddressesLazyQueryHookResult = ReturnType<typeof useClientProfileAddressesLazyQuery>;
export type ClientProfileAddressesQueryResult = Apollo.QueryResult<ClientProfileAddressesQuery, ClientProfileAddressesQueryVariables>;