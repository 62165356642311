/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductVariantCreateMutationVariables = Types.Exact<{
  input: Types.ProductVariantCreateInput;
}>;


export type ProductVariantCreateMutation = { __typename?: 'Mutation', productVariantCreate?: { __typename?: 'ProductVariantCreate', productVariant?: { __typename?: 'ProductVariant', id: string, name?: string | null } | null, productErrors: Array<{ __typename?: 'ProductError', field?: string | null, message?: string | null }> } | null };


export const ProductVariantCreateDocument = gql`
    mutation productVariantCreate($input: ProductVariantCreateInput!) {
  productVariantCreate(input: $input) {
    productVariant {
      id
      name
    }
    productErrors {
      field
      message
    }
  }
}
    `;
export type ProductVariantCreateMutationFn = Apollo.MutationFunction<ProductVariantCreateMutation, ProductVariantCreateMutationVariables>;

/**
 * __useProductVariantCreateMutation__
 *
 * To run a mutation, you first call `useProductVariantCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductVariantCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productVariantCreateMutation, { data, loading, error }] = useProductVariantCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductVariantCreateMutation(baseOptions?: Apollo.MutationHookOptions<ProductVariantCreateMutation, ProductVariantCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductVariantCreateMutation, ProductVariantCreateMutationVariables>(ProductVariantCreateDocument, options);
      }
export type ProductVariantCreateMutationHookResult = ReturnType<typeof useProductVariantCreateMutation>;
export type ProductVariantCreateMutationResult = Apollo.MutationResult<ProductVariantCreateMutation>;
export type ProductVariantCreateMutationOptions = Apollo.BaseMutationOptions<ProductVariantCreateMutation, ProductVariantCreateMutationVariables>;