import React, { FC } from "react";
import { ManualPrescriptionOrderForm } from "../components/Form/ManualPrescriptionOrderForm";
import { useTicketAddHook } from "./TicketAdd.hook";

const FailedPrescriptionFormScreen: FC = () => {
  const { handleSubmit, isCreateLoading, errors } = useTicketAddHook();

  return <ManualPrescriptionOrderForm isLoading={isCreateLoading} orderErrors={errors} onDone={handleSubmit} />;
};

export default FailedPrescriptionFormScreen;
