/* eslint-disable sonarjs/no-duplicate-string */
import { useTranslation } from "@health/i18n";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ExpandMoreIcon,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useAddToast,
} from "@health/ui";
import React, { FC, memo, useState } from "react";
import { useStyles } from "./medListDialog.styles";
import { MedListDialogProps } from "./MedListDialog.types";

const MedListDialog: FC<MedListDialogProps> = props => {
  const { t } = useTranslation("Admin");
  const { code, isOpen, users, handleAssign, handleClose } = props;
  const {
    classes: { container, backDropStyle, paperStyle, dialogContainerStyle, dialogContentContainer, componentRoot },
  } = useStyles();

  const { failed } = useAddToast();
  const [activeReviewer, setActiveReviewer] = useState("");
  const [activeEditor, setActiveEditor] = useState("");

  const handleReviewerChange = event => {
    setActiveReviewer(event.target.value);
  };

  const handleEditorChange = event => {
    setActiveEditor(event.target.value);
  };

  const handleAssignClick = () => {
    if (!activeReviewer || !activeEditor) {
      return failed(t("All Fields are required"));
    }

    handleClose();
    handleAssign && handleAssign(code, activeReviewer, activeEditor);
  };

  return (
    <Dialog
      BackdropProps={{
        className: backDropStyle,
      }}
      classes={{
        container,
      }}
      PaperProps={{
        className: paperStyle,
        sx: {
          width: "75%",
        },
      }}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        <Typography fontSize={16}>{t("Assign")}</Typography>
        <Box marginTop={1}>
          <Divider />
        </Box>
      </DialogTitle>
      <DialogContent className={dialogContentContainer}>
        <FormControl fullWidth>
          <InputLabel color='primary' id='demo-simple-select-label'>
            {t("Reviewer Name")}
          </InputLabel>
          <Select
            componentsProps={{
              root: {
                className: componentRoot,
              },
            }}
            IconComponent={ExpandMoreIcon}
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={activeReviewer}
            label={t("Reviewer Name")}
            onChange={handleReviewerChange}
          >
            {users?.map(user => (
              <MenuItem key={user.id} value={user?.id}>
                {user.firstName} {user.lastName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box marginTop={3}>
          <FormControl fullWidth>
            <InputLabel color='primary' id='demo-simple-select-label'>
              {t("Editor Name")}
            </InputLabel>
            <Select
              IconComponent={ExpandMoreIcon}
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={activeEditor}
              label={t("Reviewer Name")}
              componentsProps={{
                root: {
                  className: componentRoot,
                },
              }}
              onChange={handleEditorChange}
            >
              {users?.map(user => (
                <MenuItem key={user.id} value={user?.id}>
                  {user.firstName} {user.lastName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions className={dialogContainerStyle}>
        <Button onClick={handleAssignClick} disabled={!activeReviewer || !activeEditor}>
          {t("Assign")}
        </Button>
        <Button variant='outlined' onClick={handleClose} autoFocus>
          {t("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(MedListDialog);
