/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrderLifeCycleVendorQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type OrderLifeCycleVendorQuery = { __typename?: 'Query', order?: { __typename?: 'Order', id: string, status: Types.OrderStatus, number?: string | null, failReason?: string | null, helpDiskTicketId?: string | null, branch?: { __typename?: 'Branch', id: string, name: string, nameAr?: string | null } | null, events?: Array<{ __typename?: 'OrderEvent', id: string, date?: any | null, type?: Types.OrderEventsEnum | null } | null> | null } | null };


export const OrderLifeCycleVendorDocument = gql`
    query orderLifeCycleVendor($id: ID!) {
  order(id: $id) {
    id
    status
    number
    failReason
    helpDiskTicketId
    branch {
      id
      name
      nameAr
    }
    events {
      id
      date
      type
    }
  }
}
    `;

/**
 * __useOrderLifeCycleVendorQuery__
 *
 * To run a query within a React component, call `useOrderLifeCycleVendorQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderLifeCycleVendorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderLifeCycleVendorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderLifeCycleVendorQuery(baseOptions: Apollo.QueryHookOptions<OrderLifeCycleVendorQuery, OrderLifeCycleVendorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderLifeCycleVendorQuery, OrderLifeCycleVendorQueryVariables>(OrderLifeCycleVendorDocument, options);
      }
export function useOrderLifeCycleVendorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderLifeCycleVendorQuery, OrderLifeCycleVendorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderLifeCycleVendorQuery, OrderLifeCycleVendorQueryVariables>(OrderLifeCycleVendorDocument, options);
        }
export type OrderLifeCycleVendorQueryHookResult = ReturnType<typeof useOrderLifeCycleVendorQuery>;
export type OrderLifeCycleVendorLazyQueryHookResult = ReturnType<typeof useOrderLifeCycleVendorLazyQuery>;
export type OrderLifeCycleVendorQueryResult = Apollo.QueryResult<OrderLifeCycleVendorQuery, OrderLifeCycleVendorQueryVariables>;