/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IsPrescriptionEligibleForFreeDeliveryMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.FreeDeliveryEligibilityCheckInput;
}>;


export type IsPrescriptionEligibleForFreeDeliveryMutation = { __typename?: 'Mutation', isPrescriptionEligibleForFreeDelivery?: { __typename?: 'IsPrescriptionEligibleForFreeDelivery', isFreeDeliveryEligible?: boolean | null, prescriptionErrors: Array<{ __typename?: 'PrescriptionError', code: Types.PrescriptionErrorCode, message?: string | null, field?: string | null }> } | null };


export const IsPrescriptionEligibleForFreeDeliveryDocument = gql`
    mutation IsPrescriptionEligibleForFreeDelivery($id: ID!, $input: FreeDeliveryEligibilityCheckInput!) {
  isPrescriptionEligibleForFreeDelivery(id: $id, input: $input) {
    isFreeDeliveryEligible
    prescriptionErrors {
      code
      message
      field
    }
  }
}
    `;
export type IsPrescriptionEligibleForFreeDeliveryMutationFn = Apollo.MutationFunction<IsPrescriptionEligibleForFreeDeliveryMutation, IsPrescriptionEligibleForFreeDeliveryMutationVariables>;

/**
 * __useIsPrescriptionEligibleForFreeDeliveryMutation__
 *
 * To run a mutation, you first call `useIsPrescriptionEligibleForFreeDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIsPrescriptionEligibleForFreeDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [isPrescriptionEligibleForFreeDeliveryMutation, { data, loading, error }] = useIsPrescriptionEligibleForFreeDeliveryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIsPrescriptionEligibleForFreeDeliveryMutation(baseOptions?: Apollo.MutationHookOptions<IsPrescriptionEligibleForFreeDeliveryMutation, IsPrescriptionEligibleForFreeDeliveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IsPrescriptionEligibleForFreeDeliveryMutation, IsPrescriptionEligibleForFreeDeliveryMutationVariables>(IsPrescriptionEligibleForFreeDeliveryDocument, options);
      }
export type IsPrescriptionEligibleForFreeDeliveryMutationHookResult = ReturnType<typeof useIsPrescriptionEligibleForFreeDeliveryMutation>;
export type IsPrescriptionEligibleForFreeDeliveryMutationResult = Apollo.MutationResult<IsPrescriptionEligibleForFreeDeliveryMutation>;
export type IsPrescriptionEligibleForFreeDeliveryMutationOptions = Apollo.BaseMutationOptions<IsPrescriptionEligibleForFreeDeliveryMutation, IsPrescriptionEligibleForFreeDeliveryMutationVariables>;