import { makeStyles } from "tss-react/mui";

export const useBreadcrumbsStyles = makeStyles()({
  root: {
    border: "1px solid #DFE8F2",
  },
  link: {
    color: "#5B7798",
    fontSize: 12,
    cursor: "pointer",
  },
  text: {
    fontSize: 12,
    color: "#1B2346",
  },
});
