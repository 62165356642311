import { ApolloClient, from, InMemoryCache } from "@apollo/client";
import { cacheUtils } from "./cacheUtils";
import { errorLink } from "./errorLink";
import { splitLink } from "./splitLink";

export const client = new ApolloClient({
  link: from([errorLink, splitLink]),
  cache: new InMemoryCache(cacheUtils),
  connectToDevTools: true,
});
