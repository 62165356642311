import { Box, CircularProgress } from "@mui/material";
import React, { FC } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteScrollStyles } from "./CustomInfiniteScroll.styles";
import { InfiniteScrollProps } from "./CustomInfiniteScroll.types";

export const CustomInfiniteScroll: FC<InfiniteScrollProps> = props => {
  const { classes } = useInfiniteScrollStyles();

  const { height, children, dataLength, onFetchMore, onRefresh, isFetchingMore } = props;

  const onHandleFetchMoreData = () => {
    onFetchMore && onFetchMore();
  };

  const onRefreshFunction = () => {
    onRefresh && onRefresh();
  };

  return (
    <InfiniteScroll
      dataLength={dataLength || 0}
      height={height}
      next={onHandleFetchMoreData}
      hasMore={isFetchingMore}
      loader={
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      }
      className={classes.scroll}
      refreshFunction={onRefreshFunction}
      pullDownToRefresh
      pullDownToRefreshThreshold={50}
      pullDownToRefreshContent={<Box textAlign='center'>&#8595; Pull down to refresh</Box>}
      releaseToRefreshContent={<Box textAlign='center'>&#8593; Release to refresh</Box>}
    >
      {children}
    </InfiniteScroll>
  );
};
