import { makeVar, useReactiveVar } from "@apollo/client";
import { UserContext } from "@health/common";
import { useTranslation } from "@health/i18n";
import { Address, AddressesDocument, AddressTranslationInput, AddressTypeEnum, LanguageCodeEnum } from "@health/queries";
import { useToasts } from "@health/ui";
import { useContext } from "react";
import { AddAnonymousAddressOption } from "../AddressPickerWithAnonymous";
import { AddressInput } from "../FormInputs/FormInputs.types";
import { useAddressFormQueries } from "../useAddressFormQueries";
import { AddressesFormDialogProps } from "./types";

export const anoynemousAddressVar = makeVar<Omit<Address, "id"> | undefined>(undefined);
export const isAddressFormDialogOpenVar = makeVar<boolean>(false);

export const useAddressesFormDialogHooks = ({ onSelectAddress, address, onCancel }: AddressesFormDialogProps) => {
  const { isAuthenticated } = useContext(UserContext);
  const isAddressFormDialogOpen = useReactiveVar(isAddressFormDialogOpenVar);
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { createAccountAddress, updateAccountAddress, setAccountAddressDefault } = useAddressFormQueries({
    onAddSuccess: () => {
      handleCloseDialog();
    },
    onUpdateSuccess: () => {
      handleCloseDialog();
    },
  });

  const handleSubmit = (formInputs: AddressInput, { isDefault, shouldSaveAddress }: { isDefault: boolean; shouldSaveAddress: boolean }) => {
    anoynemousAddressVar(formInputs as unknown as Address);
    if (isAuthenticated && (shouldSaveAddress || address.id !== "UNSAVED_ADDRESS")) {
      handleAddressSubmit(formInputs, isDefault);
    } else {
      onSelectAddress?.({ ...address, ...formInputs } as unknown as Address);
      handleCloseDialog();
    }
  };

  const handleAddressSubmit = (formInputs: AddressInput, isDefault: boolean) => {
    if (!address?.id || address?.id === AddAnonymousAddressOption.id) {
      const input = { ...formInputs };
      delete input["id"];
      delete input.shouldSaveAddress;
      createAccountAddress({
        variables: {
          input: { ...input, city: typeof input.city === "object" ? input.city?.["id"] : input.city },
          type: isDefault ? AddressTypeEnum.Shipping : undefined,
        },
        onCompleted: ({ accountAddressCreate }) => {
          if (!accountAddressCreate?.accountErrors?.length) {
            onSelectAddress?.(accountAddressCreate?.address as Address);
            handleCloseDialog();
          } else {
            addToast(t("failed to add address") + ".\n\r " + accountAddressCreate?.accountErrors?.map(e => e?.message).join("\n\r"));
          }
        },
      });
    } else {
      const city = formInputs?.city as Partial<{ id: string }>;
      address?.id &&
        updateAccountAddress({
          variables: {
            id: address?.id as string,
            input: {
              name: formInputs?.name,
              streetAddress1: formInputs?.streetAddress1,
              streetAddress2: formInputs?.streetAddress2,
              area: formInputs?.area,
              district: formInputs?.district,
              region: formInputs?.region,
              buildingName: formInputs?.region,
              buildingNumber: formInputs?.region,
              coordinates: {
                lat: formInputs?.coordinates?.lat as number,
                lng: formInputs?.coordinates?.lng as number,
              },
              translations: formInputs?.translations?.map(
                item =>
                  ({
                    name: item?.name,
                    streetAddress1: item?.streetAddress1,
                    streetAddress2: item?.streetAddress2,
                    area: item?.area,
                    district: item?.district,
                    region: item?.region,
                    buildingName: item?.region,
                    buildingNumber: item?.region,
                    languageCode: item?.languageCode || LanguageCodeEnum.En,
                  } as AddressTranslationInput)
              ),
              city: city.id,
            },
          },
          refetchQueries: [AddressesDocument],
          onCompleted: data => {
            onSelectAddress?.(data?.accountAddressUpdate?.address as any);
            handleCloseDialog();
          },
        });
      isDefault &&
        setAccountAddressDefault({
          variables: {
            id: address?.id as string,
            type: AddressTypeEnum.Shipping,
          },
        });
    }
  };

  const handleOpenDialog = () => {
    isAddressFormDialogOpenVar(true);
  };
  const handleCloseDialog = () => {
    isAddressFormDialogOpenVar(false);
  };
  const handleCancelDialog = () => {
    onCancel?.();
    isAddressFormDialogOpenVar(false);
  };

  return {
    isOpened: isAddressFormDialogOpen,
    handleSubmit,
    handleCancelDialog,
    handleOpenDialog,
  };
};
