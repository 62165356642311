/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminProductImagesQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type AdminProductImagesQuery = { __typename?: 'Query', product?: { __typename?: 'Product', id: string, images?: Array<{ __typename?: 'ProductImage', url: string, id: string, alt: string } | null> | null } | null };


export const AdminProductImagesDocument = gql`
    query adminProductImages($id: ID!) {
  product(id: $id) {
    id
    images {
      url
      id
      alt
    }
  }
}
    `;

/**
 * __useAdminProductImagesQuery__
 *
 * To run a query within a React component, call `useAdminProductImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminProductImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminProductImagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminProductImagesQuery(baseOptions: Apollo.QueryHookOptions<AdminProductImagesQuery, AdminProductImagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminProductImagesQuery, AdminProductImagesQueryVariables>(AdminProductImagesDocument, options);
      }
export function useAdminProductImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminProductImagesQuery, AdminProductImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminProductImagesQuery, AdminProductImagesQueryVariables>(AdminProductImagesDocument, options);
        }
export type AdminProductImagesQueryHookResult = ReturnType<typeof useAdminProductImagesQuery>;
export type AdminProductImagesLazyQueryHookResult = ReturnType<typeof useAdminProductImagesLazyQuery>;
export type AdminProductImagesQueryResult = Apollo.QueryResult<AdminProductImagesQuery, AdminProductImagesQueryVariables>;