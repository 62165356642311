const providersManagement = "health-providers";
const newPath = "new";
const editPath = `edit/:id`;
const editBranchPath = `edit/:idBranch`;
const branchUser = `branch-user/:idBranch`;

const branches = `branches/:id`;
const users = `:id/provider-users`;

// const provider = "provider";
export const providersManagementRoutes = {
  name: "Providers",
  path: providersManagement,
  fullPath: "/health-providers",
  provider: {
    name: "providers",
    new: {
      fullPath: [providersManagement, newPath].join("/"),
      name: "New",
      path: [providersManagement, newPath].join("/"),
    },
    edit: {
      fullPath: [providersManagement, editPath].join("/"),
      name: "Edit",
      path: [providersManagement, editPath].join("/"),
    },
    branch: {
      fullPath: [providersManagement, branches].join("/"),
      name: "Branch",
      path: [providersManagement, branches].join("/"),
    },
    newBranch: {
      fullPath: [providersManagement, branches, newPath].join("/"),
      name: "New Branch",
      path: [providersManagement, branches, newPath].join("/"),
    },
    editBranch: {
      fullPath: [providersManagement, branches, editBranchPath].join("/"),
      name: "New Branch",
      path: [providersManagement, branches, editBranchPath].join("/"),
    },
    branchUsers: {
      fullPath: [providersManagement, branches, branchUser].join("/"),
      name: "branch Users",
      path: [providersManagement, branches, branchUser].join("/"),
    },
    user: {
      fullPath: [providersManagement, users].join("/"),
      name: "provider-users",
      path: [providersManagement, users].join("/"),
    },
  },
};
