/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../fragment/PageInfo/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCodeSystemConceptsByCodeQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  codeSystemCode?: Types.InputMaybe<Types.CodeSystemCode>;
  searchParameters?: Types.InputMaybe<Array<Types.InputMaybe<Types.SearchParameter>> | Types.InputMaybe<Types.SearchParameter>>;
  showOnlyVisibleProperties?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type GetCodeSystemConceptsByCodeQuery = { __typename?: 'Query', getCodeSystemConceptsByCode?: { __typename?: 'CodeSystemConceptConnection', totalCount?: number | null, pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null, edges?: Array<{ __typename?: 'CodeSystemConceptEdge', node?: { __typename?: 'CodeSystemConcept', code?: string | null, display?: string | null } | null } | null> | null } | null };


export const GetCodeSystemConceptsByCodeDocument = gql`
    query getCodeSystemConceptsByCode($first: Int, $last: Int, $after: String, $before: String, $codeSystemCode: CodeSystemCode, $searchParameters: [SearchParameter], $showOnlyVisibleProperties: Boolean) {
  getCodeSystemConceptsByCode(
    first: $first
    after: $after
    before: $before
    last: $last
    codeSystemCode: $codeSystemCode
    searchParameters: $searchParameters
    showOnlyVisibleProperties: $showOnlyVisibleProperties
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        code
        display
      }
    }
    totalCount
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useGetCodeSystemConceptsByCodeQuery__
 *
 * To run a query within a React component, call `useGetCodeSystemConceptsByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCodeSystemConceptsByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCodeSystemConceptsByCodeQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      codeSystemCode: // value for 'codeSystemCode'
 *      searchParameters: // value for 'searchParameters'
 *      showOnlyVisibleProperties: // value for 'showOnlyVisibleProperties'
 *   },
 * });
 */
export function useGetCodeSystemConceptsByCodeQuery(baseOptions?: Apollo.QueryHookOptions<GetCodeSystemConceptsByCodeQuery, GetCodeSystemConceptsByCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCodeSystemConceptsByCodeQuery, GetCodeSystemConceptsByCodeQueryVariables>(GetCodeSystemConceptsByCodeDocument, options);
      }
export function useGetCodeSystemConceptsByCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCodeSystemConceptsByCodeQuery, GetCodeSystemConceptsByCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCodeSystemConceptsByCodeQuery, GetCodeSystemConceptsByCodeQueryVariables>(GetCodeSystemConceptsByCodeDocument, options);
        }
export type GetCodeSystemConceptsByCodeQueryHookResult = ReturnType<typeof useGetCodeSystemConceptsByCodeQuery>;
export type GetCodeSystemConceptsByCodeLazyQueryHookResult = ReturnType<typeof useGetCodeSystemConceptsByCodeLazyQuery>;
export type GetCodeSystemConceptsByCodeQueryResult = Apollo.QueryResult<GetCodeSystemConceptsByCodeQuery, GetCodeSystemConceptsByCodeQueryVariables>;