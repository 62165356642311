import { makeStyles } from "tss-react/mui";
import { darkGrey } from "../../customization";

export const useAutocompleteStyles = makeStyles()({
  autocomplete: {
    width: "100%",
    "& .MuiFilledInput-root.Mui-focused": {
      borderRadius: "10px 10px 0px 0px",
      borderColor: "#617695",
    },
  },
  customTextField: {
    "& input::placeholder": {
      color: darkGrey,
      opacity: 1,
      fontWeight: 100,
      fontSize: "14px",
    },
  },
  paper: {
    boxShadow: "none",
    border: "1px solid #617695",
    borderTop: 0,
    height: "200px",
    position: "absolute",
    width: "calc(100% + 2px)",
    margin: "0px -1px",
    padding: 0,
    borderRadius: "0px 0px 10px 10px",
    overflow: "hidden",
  },
  height: {
    height: "inherit",
  },
  textField: {
    borderRadius: "15px 10px 0px 0px",
    borderBottom: "none",
    padding: 0,
    height: "100%",
  },
});
