import { useTranslation } from "@health/i18n";
import React, { FC } from "react";
import { PlusIcon } from "../../../icons/icons";
import { StyledButton } from "./StyledButton.styles";
import { MainListActionsProps } from "./types";

export const MainListActions: FC<MainListActionsProps> = ({ children, hasAddNew, onAddNewItem: handleAddNewItem }) => {
  const { t } = useTranslation();

  return (
    <>
      <>{children}</>

      {hasAddNew && (
        <StyledButton color='success' size='medium' startIcon={<PlusIcon color='inherit' />} onClick={handleAddNewItem}>
          {t("Add New")}
        </StyledButton>
      )}
    </>
  );
};
