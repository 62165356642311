/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
/* eslint-disable max-statements */
import { NetworkStatus } from "@apollo/client";
import { getEnvVariable } from "@health/common";
import { useTranslation } from "@health/i18n";
import {
  CodeSystem,
  CodeSystemCode,
  formatGraphQLError,
  Maybe,
  ProcessingStatus,
  SearchParameter,
  Type,
  useAssignCodeSystemEditorAndReviewerMutation,
  useCloneCodeSystemMutation,
  useCodeSystemConceptAutoCompleteSearchLazyQuery,
  useCurrentUserQuery,
  useDeleteCodeSystemConceptMutation,
  useDownloadCodeSystemFileLazyQuery,
  useGetCodeSystemConceptsLazyQuery,
  useGetCodeSystemDefinitionQuery,
  useGetCodeSystemQuery,
  useGetCodeSystemsLazyQuery,
  useMedlistUsersQuery,
  usePublishCodeSystemMutation,
  User,
  useReviewCodeSystemMutation,
  useSendCodeSystemToReviewMutation,
  useUploadCodeSystemFileMutation,
} from "@health/queries";
import { Box, FieldsTypes, useAddToast } from "@health/ui";
import { useTablePreferences } from "hooks";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useMedListTableHooks = () => {
  const navigate = useNavigate();
  const { code, id: _id } = useParams();
  const [openFetchDialogue, setOpenFetchDialogue] = useState(false);
  const [editorUserId, setEditorUserId] = useState();
  const [reviewerUserId, setReviewerUserId] = useState();
  const [pageSize, setPageSize] = useState<number>(10);
  const [columns, setColumns] = useState<any>([]);
  const { t } = useTranslation("translation");
  const [isOpen, setIsOpen] = useState(false);
  const [searchOptions, setSearchOptions] = useState<Record<string, any>[]>([]);
  const [isAssignDialogOpen, setIsAssignDialogOpen] = useState(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [activeFilters, setActiveFilters] = useState<Record<string, any>>({});
  const id = _id == "0" ? null : _id;
  const { succeeded, failed } = useAddToast();

  const [menuActionsAnchorEl, setMenuActionsAnchorEl] = useState<null | HTMLElement>(null);
  const openActionsMenu = Boolean(menuActionsAnchorEl);
  const handleClickActionsMenuAnchorEl = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuActionsAnchorEl(event.currentTarget);
  };
  const handleCloseActionsMenuAnchorEl = () => {
    setMenuActionsAnchorEl(null);
  };
  const handleOpenFetchDialog = () => {
    handleCloseActionsMenuAnchorEl();
    setOpenFetchDialogue(true);
  };
  const handleCloseFetchDialog = () => {
    setOpenFetchDialogue(false);
  };
  const { data: useCurrentUserQueryResponse } = useCurrentUserQuery({
    variables: {
      isVendorApp: false,
      isAdminApp: true,
      isClientApp: false,
    },
  });
  const user = useCurrentUserQueryResponse?.me;

  const { data: codeSystemDefinitionData, loading: isLoading } = useGetCodeSystemDefinitionQuery({
    variables: {
      code: code as CodeSystemCode,
    },
    onCompleted: ({ getCodeSystemDefinition }) => {
      setEditorUserId(getCodeSystemDefinition?.editorUserId as any);
      setReviewerUserId(getCodeSystemDefinition?.reviewerUserId as any);
      const columnsData = getCodeSystemDefinition?.codeSystemConceptFields?.map(item => ({
        key: item?.display,
        header: item?.display,
        accessor: ({ properties }) => {
          let name = "-";
          const itemData = properties?.find(row => row?.code == item?.code);
          if (!itemData) return name;
          switch (item?.fieldType) {
            case Type.String:
              name = itemData?.valueString;
              break;
            case Type.Boolean:
              name = itemData?.valueBoolean ? "Yes" : "No";
              break;
            case Type.Float:
              name = itemData?.valueFloat;
              break;
            case Type.Integer:
              name = itemData?.valueInteger;
              break;
            case Type.Date:
              name = itemData?.valueDate;
              break;
            default:
              name = "-";
          }
          return (
            <Box component='div' sx={{ width: "max-content" }}>
              {name}
            </Box>
          );

          return name;
        },
        isSortable: true,
      }));
      setColumns(columnsData);
    },
  });
  const { codeSystemConceptFields } = codeSystemDefinitionData?.getCodeSystemDefinition || {};

  const getInputType = (item: any) => {
    let type = "text";
    switch (item?.fieldType) {
      case Type.String:
        type = "text";
        break;
      case Type.Boolean:
        type = "text";
        break;
      case Type.Float:
        type = "number";
        break;
      case Type.Integer:
        type = "number";
        break;
      case Type.Date:
        type = "date";
        break;
      default:
        type = "text";
    }
    return type;
  };

  const { isColumnsLoading, columns: columnsData, saveTablePreferences } = useTablePreferences(`medlist-${code}`, columns);

  const [getCodeSystem, { data: _codeSystemConceptsData, refetch, networkStatus: conceptsNetworkStatus }] =
    useGetCodeSystemConceptsLazyQuery({
      notifyOnNetworkStatusChange: true,
    });
  const isTableLoading = conceptsNetworkStatus !== NetworkStatus.ready;

  useEffect(() => {
    id && getCodeSystem({ variables: { first: pageSize, codeSystemId: id, showOnlyVisibleProperties: true, searchParameters: [] } });
  }, [id]);

  const codeSystemConceptsData = _codeSystemConceptsData?.getCodeSystemConcepts;
  const totalCount = _codeSystemConceptsData?.getCodeSystemConcepts?.totalCount;
  const { hasNextPage = false, hasPreviousPage = false, endCursor, startCursor } = codeSystemConceptsData?.pageInfo || {};

  const codeSystemConcepts = codeSystemConceptsData?.edges?.map(item => item?.node);

  const getInputOptions = (item: any) => {
    if (item?.fieldType == Type.Boolean) {
      return ["Yes", "No"];
    }

    const data = codeSystemConcepts
      ?.map(rowData => {
        const itemData = rowData?.properties?.find(row => row?.code == item?.code);

        let value;
        switch (item?.fieldType) {
          case Type.String:
            value = itemData?.valueString;
            break;
          case Type.Boolean:
            value = itemData?.valueBoolean ? "Yes" : "No";
            break;
          case Type.Float:
            value = itemData?.valueFloat;
            break;
          case Type.Integer:
            value = itemData?.valueInteger;
            break;
          case Type.Date:
            value = itemData?.valueDate;
            break;
          default:
            value = null;
        }
        return value;
      })
      .filter(item => item)
      .map(rowData => ({
        label: rowData,
        id: rowData,
      }));
    return data || [];
  };

  const filterFields = codeSystemConceptFields?.map(item => {
    return {
      id: item?.code || "",
      grid: 6,
      type:
        item?.fieldType == Type.String || item?.fieldType == Type.Boolean
          ? FieldsTypes.autocomplete
          : item?.fieldType === Type.Date
          ? FieldsTypes.date
          : FieldsTypes.input,
      isFloat: item?.fieldType === Type.Float,
      fieldProps: {
        placeholder: item?.fieldType === Type.Date ? undefined : item?.display,
        label: item?.display,
        name: item?.code,
        type: getInputType(item),
        getOptionLabel: o => o,
        isOptionEqualToValue: (option, value) => option === value,
        options:
          (item?.fieldType == Type.String &&
            item?.code &&
            !!searchOptions &&
            searchOptions?.find(field => field.id === item?.code)?.data) ||
          getInputOptions(item),
        defaultValue: activeFilters?.[item?.code || ""] || "",
        disableClearable: item?.fieldType != Type.Boolean,
        freeSolo: item?.fieldType != Type.Boolean,
        InputLabelProps:
          item?.fieldType === Type.Date
            ? {
                shrink: true,
              }
            : undefined,
      },
    };
  });
  const [getDownloadCodeSystemFile] = useDownloadCodeSystemFileLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data?.downloadCodeSystemFile) {
        window.open(getEnvVariable("SERVER_URL") + data.downloadCodeSystemFile, "_self");
      }
    },
  });

  const [hasUploadFileErrors, setHasUploadFileErrors] = useState(false);
  const [errorUploadMessage, setErrorUploadMessage] = useState("");
  const [uploadCodeSystemFile] = useUploadCodeSystemFileMutation({
    onCompleted: data => {
      setDialogLoading(false);
      succeeded(t("Excel file has been imported successfully"));
      onDialogClose();
      if (data.uploadCodeSystemFile?.id) {
        handleVersionOpenClick(data.uploadCodeSystemFile?.id);
        setIsOpen(false);
      }
    },
    onError: error => {
      setDialogLoading(false);
      setHasUploadFileErrors(true);
      setErrorUploadMessage(error?.message);
    },
  });

  const [
    getCodeSystems,
    { data: versionResponseData, loading: versionDataLoading, fetchMore: fetchMoreVersions, networkStatus, refetch: refetchVersions },
  ] = useGetCodeSystemsLazyQuery();

  const handleOnFetchMoreVersions = () => {
    fetchMoreVersions({
      variables: {
        first: pageSize,
        after: versionResponseData?.getCodeSystems?.pageInfo?.endCursor,
      },
    });
  };
  const handleChangeActiveVersion = (versionId?: Maybe<string>) => {
    versionId && onVersionActiveChange(versionId);
  };

  const versionData = versionResponseData?.getCodeSystems?.edges?.map(item => item?.node) as CodeSystem[];
  const hasNextPageVersions = versionResponseData?.getCodeSystems?.pageInfo?.hasNextPage;
  const [publishCodeSystem, { loading: isPublishing }] = usePublishCodeSystemMutation({
    onCompleted: data => {
      const message = data?.publishCodeSystem?.errors;
      if (message?.length) {
        failed(message[0]?.message as string);
      } else {
        succeeded(t("Data published successfully"));
      }
    },
    onError: error => {
      failed(t(error?.message));
    },
  });
  const [cloneCodeSystem, { loading: cloneLoading }] = useCloneCodeSystemMutation({
    onCompleted: data => {
      const codeId = data?.cloneCodeSystem?.id;
      if (!codeId) return;
      navigate(`/med-lists/${codeId}/${code}`);
    },
    onError: data => {
      failed(t(data?.message));
    },
  });
  const [deleteCodeSystemConcept] = useDeleteCodeSystemConceptMutation();
  const [reviewCodeSystem] = useReviewCodeSystemMutation();

  const { data: GetCodeSystemResponse, refetch: refetchCodeSystem } = useGetCodeSystemQuery({
    variables: {
      id: id as string,
    },
    skip: !id,
  });
  const { processingStatus } = GetCodeSystemResponse?.getCodeSystem || {};
  const medListItemName = GetCodeSystemResponse?.getCodeSystem?.codeSystemDefinition?.display;
  const versionNumber = GetCodeSystemResponse?.getCodeSystem?.version;
  const editorUserName = ((GetCodeSystemResponse?.getCodeSystem?.codeSystemDefinition?.editorUserId?.firstName as string) +
    " " +
    GetCodeSystemResponse?.getCodeSystem?.codeSystemDefinition?.editorUserId?.lastName) as string;

  const reviewerUserName = ((GetCodeSystemResponse?.getCodeSystem?.codeSystemDefinition?.reviewerUserId?.firstName as string) +
    " " +
    GetCodeSystemResponse?.getCodeSystem?.codeSystemDefinition?.reviewerUserId?.lastName) as string;

  const [sendCodeSystemToReview] = useSendCodeSystemToReviewMutation();
  const approvedHistoryArray = GetCodeSystemResponse?.getCodeSystem?.codeSystemHistory?.filter(
    item => item?.processingStatus === ProcessingStatus.Approved
  );
  const assignedReviewerData = approvedHistoryArray?.[approvedHistoryArray?.length - 1];
  const reviewerData = assignedReviewerData?.user?.firstName + " " + assignedReviewerData?.user?.lastName;
  const { data: _users } = useMedlistUsersQuery({
    variables: {
      first: pageSize,
    },
  });
  const users = _users?.medlistUsers?.edges.map(item => item.node) as User[];

  const handleDownloadFile = () => {
    handleDownloadFileFromVersion(id as string);
  };
  const handleDownloadFileFromVersion = (fileId?: string) => {
    handleCloseActionsMenuAnchorEl();
    getDownloadCodeSystemFile({
      variables: {
        code: code as CodeSystemCode,
        codeSystemId: fileId,
      },
    });
  };
  const [assignCodeSystemEditorAndReviewer] = useAssignCodeSystemEditorAndReviewerMutation({
    onCompleted: data => {
      if (data?.assignCodeSystemEditorAndReviewer?.reviewerUserId) {
        succeeded(t("Medlist assigned successfully."));
        setEditorUserId(data?.assignCodeSystemEditorAndReviewer?.editorUserId as any);
        setReviewerUserId(data?.assignCodeSystemEditorAndReviewer?.reviewerUserId as any);
      }
    },

    onError: ({ graphQLErrors }) => {
      formatGraphQLError(graphQLErrors);
    },
  });

  const handleGotoNext = () => {
    hasNextPage && endCursor && refetch?.({ first: pageSize, after: endCursor, last: undefined, before: undefined });
  };

  const handleGoToPrevious = () => {
    hasPreviousPage && startCursor && refetch?.({ first: undefined, after: undefined, last: pageSize, before: startCursor });
  };

  const handleSortData = () => {
    // const sortDirection: any = nextSortDirection(variables?.sortBy?.direction);
    // setColumns((columns: any) =>
    //   columns.map((column: any) => {
    //     if (column.key === data.key) {
    //       column.sortDirection = sortDirection ? sortDirection : null;
    //     } else {
    //       column.sortDirection = undefined;
    //     }
    //     return column;
    //   })
    // );
    // doSort(data.sortColumnEnum);
  };

  const handlePageSizeChange = (size: number): void => {
    setPageSize(size);
  };

  const onUploadCodeSystemFile = file => {
    setDialogLoading(true);
    setHasUploadFileErrors(false);
    uploadCodeSystemFile({
      variables: {
        codeSystemCode: code as CodeSystemCode,
        file,
      },
    });
  };

  const handleImportFile = () => {
    handleCloseActionsMenuAnchorEl();
    setIsOpen(true);
  };

  const onDialogClose = () => {
    setIsOpen(false);
    setHasUploadFileErrors(false);
    setErrorUploadMessage("");
  };

  const onResetErrorMessage = () => {
    setHasUploadFileErrors(false);
    setErrorUploadMessage("");
  };

  const getFilterPropertyName = (fieldType: any) => {
    let name;
    switch (fieldType) {
      case Type.String:
        name = "valueString";
        break;
      case Type.Boolean:
        name = "valueBoolean";
        break;
      case Type.Float:
        name = "valueFloat";
        break;
      case Type.Integer:
        name = "valueInteger";
        break;
      case Type.Date:
        name = "valueDate";
        break;
      default:
        name = "valueString";
    }
    return name;
  };

  const getFilterPropertyType = (item: string) => {
    return codeSystemConceptFields?.find(column => column?.code == item)?.fieldType;
  };

  const handleApplyFilters = (data: any) => {
    setActiveFilters(data);
    refetch &&
      refetch({
        searchParameters: getSearchParameters(data),
      });
  };

  function getSearchParameters(data: Record<string, any> = activeFilters) {
    return Object.keys(data).map(item => {
      const valueFloat = getFilterPropertyType(item) === Type.Float ? Number(data[item]) : null;

      const searchParam: SearchParameter = {
        code: item,
        type: getFilterPropertyType(item),
        [getFilterPropertyName(getFilterPropertyType(item))]:
          getFilterPropertyType(item) === Type.Boolean ? data[item]?.toLowerCase() === "yes" : data[item],
        ...(valueFloat !== null && { valueFloat }), // Add valueFloat only if it's not null
      };

      return searchParam;
    });
  }

  const handleDismissFilters = () => {
    setActiveFilters({});
  };

  const handleVersionDialogOpen = () => {
    getCodeSystems({
      variables: {
        first: 10,
        code: code as CodeSystemCode,
      },
    });
  };

  const handleVersionOpenClick = (id: string) => {
    navigate(`/med-lists/${id}/${code}`);
  };

  const onVersionActiveChange = (id: string) => {
    publishCodeSystem({
      variables: {
        codeSystemId: id,
      },
    }).then(() => {
      refetchCodeSystem();
      refetchVersions();
    });
  };

  const onCloneClicked = () => {
    handleCloseActionsMenuAnchorEl();
    cloneCodeSystem({
      variables: {
        codeSystemCode: code as CodeSystemCode,
      },
    });
  };

  const handleDeleteRow = data => {
    deleteCodeSystemConcept({
      variables: {
        codeSystemConceptId: data.id,
      },
      update(cache) {
        const normalizedId = cache.identify({ id: data.id, __typename: "CodeSystemConcept" });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
  };

  const handleEditRow = (rowData: any) => {
    navigate(`/med-lists/${id}/${code}/${rowData?.id}`);
  };

  const handleRejectClicked = () => {
    handleCloseActionsMenuAnchorEl();
    reviewCodeSystem({
      variables: {
        codeSystemId: id as string,
        processingStatus: ProcessingStatus.Rejected,
      },
    });
  };

  const handleApprove = () => {
    handleCloseActionsMenuAnchorEl();
    reviewCodeSystem({
      variables: {
        codeSystemId: id as string,
        processingStatus: ProcessingStatus.Approved,
      },
    }).then(() => {
      refetchCodeSystem();
    });
  };

  const handlePublishClicked = () => {
    handleCloseActionsMenuAnchorEl();
    publishCodeSystem({
      variables: {
        codeSystemId: id as string,
      },
    }).then(() => {
      refetchCodeSystem();
    });
  };

  const handleUnderReview = () => {
    sendCodeSystemToReview({
      variables: {
        codeSystemId: id as string,
      },
    }).then(() => {
      refetchCodeSystem();
    });
  };

  const handleReassign = () => {
    handleCloseActionsMenuAnchorEl();
    setIsAssignDialogOpen(true);
  };

  const onAssign = (code: CodeSystemCode, editorUser?: string, reviewerUser?: string, onFinish?: () => void) => {
    editorUser &&
      reviewerUser &&
      assignCodeSystemEditorAndReviewer({
        variables: {
          code,
          editorUser,
          reviewerUser,
        },
      });
    onFinish && onFinish();
  };

  const onAssignDialogClose = () => {
    setIsAssignDialogOpen(false);
  };

  const [getOptions] = useCodeSystemConceptAutoCompleteSearchLazyQuery();
  const handleGetSearchOptions = (prop_id: string, keyword?: string) => {
    console;
    _id &&
      getOptions({
        variables: {
          codeSystemId: _id,
          keyword: keyword || "",
          propertyCode: prop_id,
        },
      }).then(data => {
        prop_id && setSearchOptions(prev => [...prev, { id: prop_id, data: data?.data?.codeSystemConceptAutoCompleteSearch || [] }]);
      });
  };

  const handleColumnsChanged = (data: any) => {
    saveTablePreferences({
      variables: {
        input: {
          view: `medlist-${code}`,
          data: JSON.stringify({
            version: "v1",
            columns: data,
          }),
        },
      },
    });
  };
  const isEditable = codeSystemDefinitionData?.getCodeSystemDefinition?.isEditable;
  const handleUndefinedString = name => {
    if (name === "undefined undefined") {
      return undefined;
    } else {
      return name;
    }
  };
  return {
    isOpen,
    user,
    code: code as CodeSystemCode,
    isAssignDialogOpen,
    users,
    dialogLoading,
    codeSystemConceptFields,
    codeSystemConcepts,
    isColumnsLoading,
    columns: columnsData || columns,
    isLoading,
    isTableLoading,
    cloneLoading,
    pageSize,
    totalCount,
    filterFields,
    activeFilters,
    versionData,
    versionNumber,
    versionDataLoading,
    processingStatus,
    isPublishing,
    handleColumnsChanged,
    handleGetSearchOptions,
    getSearchParameters,
    onAssign,
    onAssignDialogClose,
    handleApplyFilters,
    handleImportFile,
    onDialogClose,
    onResetErrorMessage,
    onUploadCodeSystemFile,
    handleDownloadFile,
    handleGotoNext,
    handleGoToPrevious,
    handleSortData,
    handlePageSizeChange,
    handleDismissFilters,
    handleVersionDialogOpen,
    handleVersionOpenClick,
    onVersionActiveChange,
    onCloneClicked,
    handleDeleteRow,
    handleEditRow,
    handleRejectClicked,
    handlePublishClicked,
    handleApprove,
    handleUnderReview,
    handleReassign,
    errorUploadMessage,
    hasUploadFileErrors,
    hasNextPage,
    hasPreviousPage,
    handleOnFetchMoreVersions,
    hasNextPageVersions,
    isLoadingVersions: NetworkStatus.loading === networkStatus,
    isRefetchingVersions: NetworkStatus.refetch === networkStatus,
    isFetchingMoreVersions: NetworkStatus.fetchMore === networkStatus,
    handleChangeActiveVersion,
    editorUserName,
    reviewerUserName,
    medListItemName,
    editorUserId,
    reviewerUserId,
    isEditable,
    menuActionsAnchorEl,
    openActionsMenu,
    openFetchDialogue,
    setMenuActionsAnchorEl,
    handleClickActionsMenuAnchorEl,
    handleCloseActionsMenuAnchorEl,
    handleOpenFetchDialog,
    handleCloseFetchDialog,
    reviewerData,
    handleUndefinedString,
    handleDownloadFileFromVersion,
  };
};
