import { InMemoryCacheConfig } from "@apollo/client";
import { concatPagination, relayStylePagination } from "@apollo/client/utilities";
// import { relayStylePagination } from "@apollo/client/utilities";

export const cacheUtils: InMemoryCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        // categories: relayStylePagination(),
        // vendors: relayStylePagination(),
        consumerViewPreference: concatPagination(["view"]),
        getCodeSystems: relayStylePagination(),
      },
    },
  },
};
