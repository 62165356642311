import { Box, CustomTable, PageWrapper } from "@health/ui";
import React, { FC } from "react";
import { ProviderCard } from "shared/components";
import { useBranchUsersHook } from "./BranchUsers.hook";

const BranchUsersManagement: FC = () => {
  const { columns, loading, users, vendor, handleSortColumn } = useBranchUsersHook();

  return (
    <PageWrapper>
      <ProviderCard vendor={vendor} loading={loading} />
      <Box marginTop={"20px"}>
        <CustomTable
          data={users}
          columns={columns}
          pageSize={10}
          isLoading={loading}
          pageIndex={10}
          onSortColumn={handleSortColumn}
          pagesCount={10}
        />
      </Box>
    </PageWrapper>
  );
};
export default BranchUsersManagement;
