/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { AddressFragmentFragmentDoc } from '../../../../fragment/address/__generated__/address';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountAddressDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type AccountAddressDeleteMutation = { __typename?: 'Mutation', accountAddressDelete?: { __typename?: 'AccountAddressDelete', user?: { __typename?: 'User', id: string, addresses?: Array<{ __typename: 'Address', id: string, name?: string | null, area?: string | null, streetAddress1?: string | null, streetAddress2?: string | null, buildingName?: string | null, buildingNumber?: string | null, phone?: string | null, isDefaultShippingAddress?: boolean | null, region?: string | null, city: { __typename?: 'City', id: string, name: string, nameAr?: string | null }, coordinates?: { __typename?: 'LocationType', lng?: number | null, lat?: number | null } | null, translations?: Array<{ __typename?: 'AddressTranslation', streetAddress1: string, streetAddress2: string, area: string, language: { __typename?: 'LanguageDisplay', code: Types.LanguageCodeEnum, language: string } } | null> | null } | null> | null } | null, accountErrors: Array<{ __typename?: 'AccountError', field?: string | null, message?: string | null, code: Types.AccountErrorCode }> } | null };


export const AccountAddressDeleteDocument = gql`
    mutation AccountAddressDelete($id: ID!) {
  accountAddressDelete(id: $id) {
    user {
      id
      addresses {
        ...AddressFragment
      }
    }
    accountErrors {
      field
      message
      code
    }
  }
}
    ${AddressFragmentFragmentDoc}`;
export type AccountAddressDeleteMutationFn = Apollo.MutationFunction<AccountAddressDeleteMutation, AccountAddressDeleteMutationVariables>;

/**
 * __useAccountAddressDeleteMutation__
 *
 * To run a mutation, you first call `useAccountAddressDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountAddressDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountAddressDeleteMutation, { data, loading, error }] = useAccountAddressDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountAddressDeleteMutation(baseOptions?: Apollo.MutationHookOptions<AccountAddressDeleteMutation, AccountAddressDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AccountAddressDeleteMutation, AccountAddressDeleteMutationVariables>(AccountAddressDeleteDocument, options);
      }
export type AccountAddressDeleteMutationHookResult = ReturnType<typeof useAccountAddressDeleteMutation>;
export type AccountAddressDeleteMutationResult = Apollo.MutationResult<AccountAddressDeleteMutation>;
export type AccountAddressDeleteMutationOptions = Apollo.BaseMutationOptions<AccountAddressDeleteMutation, AccountAddressDeleteMutationVariables>;