import { i18next } from "@health/i18n";
import { Address } from "@health/queries";

export const AddAnonymousAddressOption = {
  id: "UNSAVED_ADDRESS",
  name: `+ ${i18next.t("Add Address")}`,
} as unknown as Address;
export const AnonymousAddressOption = {
  id: "UNSAVED_ADDRESS",
  name: i18next.t("Unsaved Address"),
} as unknown as Address;
