/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type SiteSettingsFragmentFragment = { __typename?: 'SiteSettings', id: string, vatPercentage?: number | null, enablePickup?: boolean | null, defaultCurrency: string, orderDeliveryTimeout?: number | null, orderBaseDeliveryFee?: number | null, orderAcceptanceTimeout?: number | null, orderDispensingTimeout?: number | null, orderErxHubApprovalTimeout?: number | null, multiplePharmaciesDelivery?: boolean | null, orderErxHubApprovalResubmitTimeout?: number | null, orderPaymentTimeout?: number | null, deliveryNumberOfReminders?: number | null, deliveryMinutesTimeGapBeforeEachReminder?: number | null, marketPlaceEnabled?: boolean | null, scheduleDeliveryEnabled?: boolean | null };

export const SiteSettingsFragmentFragmentDoc = gql`
    fragment SiteSettingsFragment on SiteSettings {
  id
  vatPercentage
  enablePickup
  defaultCurrency
  orderDeliveryTimeout
  orderBaseDeliveryFee
  orderAcceptanceTimeout
  orderDispensingTimeout
  orderErxHubApprovalTimeout
  multiplePharmaciesDelivery
  orderErxHubApprovalResubmitTimeout
  orderPaymentTimeout
  deliveryNumberOfReminders
  deliveryMinutesTimeGapBeforeEachReminder
  marketPlaceEnabled
  scheduleDeliveryEnabled
}
    `;