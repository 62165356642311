import { useConfirmAccountMobileMutation, useConfirmPasswordRestOtpMutation } from "@health/queries";
import { useEffect, useState } from "react";
import { useAccountReSendOtpCodeHook } from "./AccountReSendOtpCode.hook";
import { dialogStep } from "./dialog/utils";
import { registerVar } from "./reactiveVar";

export const useConfirmationHooks = (onChangeStep, isPage) => {
  const [code, setCode] = useState("");
  const [errors, setErrors] = useState([]);
  const [isWrongLanguage, setIsWrongLanguage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countDown, setCountDown] = useState(60);
  const [triedToSubmit, setTreidToSubmit] = useState(false);
  const [previousCode, setPreviousCode] = useState("");

  const isSamePreviousCode = previousCode === code;
  const isTokenExpired = errors?.some(item => item.message === "Invalid or expired token.");
  const disableVerifyButton = code?.length !== 4;

  const register = registerVar();
  const mobile = register?.mobile;

  const [confirmAccountMobile, { loading: isSendingConfirmAccountMobile }] = useConfirmAccountMobileMutation({
    onCompleted: data => {
      const accountErrors = data?.confirmAccountMobile?.accountErrors;
      if (accountErrors?.length === 0) {
        registerVar({ ...register, token: code });
        isPage ? onChangeStep() : onChangeStep(register.isForget ? dialogStep.changePassword : dialogStep.success);
      } else {
        setErrors(accountErrors);
      }
    },
  });
  const { handleResendCode, isLoading: isSendingOtpCode } = useAccountReSendOtpCodeHook(mobile, onChangeStep);

  const [confirmAccountMobileOtp, { loading: isSendingConfirmAccountMobileOtp }] = useConfirmPasswordRestOtpMutation({
    onCompleted: data => {
      const accountErrors = data?.confirmPasswordRestOtp?.accountErrors;
      if (accountErrors?.length === 0) {
        registerVar({ ...register, token: data?.confirmPasswordRestOtp.token });
        onChangeStep(register.isForget ? dialogStep.changePassword : dialogStep.success);
      } else {
        setErrors([{ message: "Invalid/Expired confirmation code, Please enter the correct one." }]);
      }
    },
  });

  useEffect(() => {
    const timer = countDown > 0 && setInterval(() => setCountDown(countDown - 1), 1000);
    return () => clearInterval(timer);
  }, [countDown]);

  const handleResendCodeClicked = () => {
    if (countDown > 0) return;
    setLoading(false);
    setCountDown(60);
    setIsWrongLanguage(false);
    setErrors([]);
    setCode("");
    handleResendCode();
  };

  const handleChangeInput = data => {
    const trimmedCode = data.trim(" ").substring(0, 4);
    setCode(trimmedCode);
    data === "" && setErrors([]);
  };

  const handleConfirmAccountMobile = () => {
    const variables = {
      token: code,
      mobile,
      sessionToken: register?.sessionToken,
    };

    setTreidToSubmit(true);
    setErrors([]);
    setPreviousCode(code);

    setLoading(true);
    register?.isForget
      ? confirmAccountMobileOtp({
          variables,
        })
      : confirmAccountMobile({
          variables,
        });
  };

  const handleLogin = () => {
    onChangeStep("login");
  };

  const handleCheckLanguage = e => {
    const re = /[^A-Za-z0-9]+/;
    const a = e.key.match(re);
    if (a == null) {
      setIsWrongLanguage(false);
    } else {
      setIsWrongLanguage(true);
    }
  };

  return {
    code,
    mobile,
    errors,
    loading,
    countDown,
    isTokenExpired,
    isSendingOtpCode,
    isSendingConfirmAccountMobileOtp,
    isSendingConfirmAccountMobile,
    triedToSubmit,
    disableVerifyButton,
    isSamePreviousCode,
    isResendButtonDisabled: countDown !== 0,
    isWrongLanguage,
    handleLogin,
    handleChangeInput,
    handleCheckLanguage,
    handleResendCode: handleResendCodeClicked,
    handleConfirmAccountMobile,
  };
};
