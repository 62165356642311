import { getRequiredValidation } from "@health/common";
import { useTranslation } from "@health/i18n";
import { Button, Grid, TextField } from "@health/ui";
import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { SubListItemInputs } from "../SubListForm/types";

const SubListItemForm: FC<{
  onHandleSubmit: () => void;
  submitButtonText?: string | undefined;
  defaults?: { code: string; display: string };
}> = ({ onHandleSubmit: handleSubmit, submitButtonText = undefined, defaults = undefined }) => {
  const { t } = useTranslation();
  const methods = useFormContext<SubListItemInputs>();

  return (
    <form onSubmit={handleSubmit}>
      <Grid container columnSpacing={2} alignItems='center'>
        <Grid item>
          <TextField
            label={t("Name")}
            defaultValue={defaults?.display}
            {...methods.register("display", {
              required: getRequiredValidation(t, true),
            })}
            error={Boolean(methods.formState.errors.display?.message)}
            helperText={methods.formState.errors.display?.message}
          />
        </Grid>
        <Grid item>
          <TextField
            label={t("Code")}
            defaultValue={defaults?.code}
            {...methods.register("code", {
              required: getRequiredValidation(t, true),
            })}
            error={Boolean(methods.formState.errors.code?.message)}
            helperText={methods.formState.errors.code?.message}
          />
        </Grid>
        {Boolean(submitButtonText) && (
          <Grid item>
            <Button onClick={handleSubmit}> {submitButtonText}</Button>
          </Grid>
        )}
      </Grid>
    </form>
  );
};
SubListItemForm.defaultProps = {
  submitButtonText: undefined,
  defaults: undefined,
};

export default SubListItemForm;
