/* eslint-disable max-lines */
import { useTranslation } from "@health/i18n";
import { useDeliveryTimeSlotsQuery } from "@health/queries";
import { FieldsTypes, FilterField, getTranslatedNameField } from "@health/ui";
import moment from "moment";
import { deliveryStatus, sourceValues, statuses } from "./components/utils";

export const formatDeliveryTimeSlot = (startTime: string, endTime: string, lang: string) => {
  const deliveryStartTime = moment(startTime, "HH:mm:ss");
  const deliveryEndTime = moment(endTime, "HH:mm:ss");
  return `${deliveryStartTime.locale("en").format("hh:mm") + deliveryStartTime.locale(lang).format("A")} -
  ${deliveryEndTime.locale("en").format("hh:mm") + deliveryEndTime.locale(lang).format("A")}`;
};

export const formatTimeSlotForColumn = (time: string, lang: string) => {
  const slotTime = moment(time, "HH:mm:ss");
  return slotTime.locale(lang).format("hh:mmA");
};

export const usePrescriptionsFilter = (activeFilters?: any, branches?) => {
  const { t, i18n } = useTranslation("Admin");

  const { data: deliveryTimeSlotsData, loading: isLoadingTimeSlots } = useDeliveryTimeSlotsQuery({
    variables: {
      first: 100,
      filter: {
        isActive: true,
        deliveryType: null,
      },
    },
  });
  const timeSlotsData = deliveryTimeSlotsData?.deliveryTimeSlots?.edges?.map(item => item?.node);
  const deliveryTypeOptions = [
    { label: t("PICKUP"), value: false },
    { label: t("DELIVERY"), value: true },
  ];
  const filters: FilterField[] = [
    {
      id: "ordersNumbers",
      grid: 6,
      name: "ordersNumbers",
      type: FieldsTypes.input,
      fieldProps: {
        placeholder: t("Order Number"),
        label: t("Order Number"),
        name: "ordersNumbers",
        defaultValue: activeFilters?.ordersNumbers,
      },
    },
    {
      id: "referenceNumber",
      type: FieldsTypes.input,
      name: "referenceNumber",
      fieldProps: {
        defaultValue: activeFilters?.referenceNumber_Icontains,
        placeholder: t("eRx Number"),
        label: t("eRx Number"),
        name: "referenceNumber",
      },
    },
    {
      id: "status",
      grid: 6,
      name: "status",
      type: FieldsTypes.autocomplete,
      fieldProps: {
        placeholder: t("Status"),
        label: t("Status"),
        name: "status",
        defaultValue: statuses?.find(item => item?.display === activeFilters?.status),
        getOptionLabel: o => o.display,
        isOptionEqualToValue: (option, value) => option.id === value.id,
        options: statuses,
      },
    },
    {
      id: "source",
      grid: 6,
      name: "source",
      type: FieldsTypes.autocomplete,
      fieldProps: {
        placeholder: t("Source"),
        label: t("Source"),
        name: "source",
        defaultValue: sourceValues?.find(item => item?.display === activeFilters?.source),
        getOptionLabel: o => o.display,
        isOptionEqualToValue: (option, value) => option.id === value.id,
        options: sourceValues,
      },
    },
    {
      id: "deliveryStatus",
      grid: 6,
      name: "deliveryStatus",
      type: FieldsTypes.autocomplete,
      fieldProps: {
        placeholder: t("Delivery Status"),
        label: t("Delivery Status"),
        name: "deliveryStatus",
        defaultValue: deliveryStatus?.find(item => item?.display === activeFilters?.deliveryStatus),
        getOptionLabel: o => o.display,
        isOptionEqualToValue: (option, value) => option.id === value.id,
        options: deliveryStatus,
      },
    },
    {
      id: "csTicketId",
      grid: 6,
      name: "csTicketId",
      type: FieldsTypes.input,
      fieldProps: {
        placeholder: t("CS Ticket ID"),
        label: t("CS Ticket ID"),
        name: "csTicketId",
        defaultValue: activeFilters?.csTicketId,
      },
    },
    {
      id: "created",
      grid: 6,
      name: "created",
      type: FieldsTypes.date,
      fieldProps: {
        placeholder: t("Created Date From"),
        label: t("Created Date From"),
        name: "created",
        defaultValue: activeFilters?.created,
      },
    },
    {
      id: "createdTo",
      grid: 6,
      name: "createdTo",
      type: FieldsTypes.date,
      fieldProps: {
        placeholder: t("Created Date To"),
        label: t("Created Date To"),
        name: "createdTo",
        defaultValue: activeFilters?.createdTo,
      },
    },
    {
      id: "branches",
      grid: 6,
      name: "branches",
      type: FieldsTypes.lazyAutocomplete,
      fieldProps: {
        placeholder: t("Branches"),
        loading: branches?.loading,
        name: "branches",
        onFetchMore: branches?.onFetchMore,
        hasMore: branches?.hasMore,
        defaultValue: branches?.options?.length ? branches?.options?.find(item => item?.id === activeFilters?.branches?.id) : undefined,
        isOptionEqualToValue: (option, value) => option === value,
        getOptionLabel: option => `${getTranslatedNameField(option)} - ${option?.healthLicense ?? ""}`,

        options: branches?.options,
      },
    },
    {
      id: "helpDiskTicketId_Icontains",
      grid: 6,
      name: "helpDiskTicketId_Icontains",
      type: FieldsTypes.input,
      fieldProps: {
        placeholder: t("Help Disk Ticket Id"),
        label: t("Help Disk Ticket Id"),
        name: "helpDiskTicketId_Icontains",
        defaultValue: activeFilters?.helpDiskTicketId_Icontains,
      },
    },
    {
      id: "deliveryTimeSlot",
      grid: 6,
      type: FieldsTypes.lazyAutocomplete,
      name: "deliveryTimeSlot",
      fieldProps: {
        placeholder: t("Delivery Time Slot"),
        getOptionLabel: o => `${t(o.deliveryType)}: ${formatDeliveryTimeSlot(o?.startTime, o?.endTime, i18n.language)} `,
        isOptionEqualToValue: (option, value) => option.id === value.id || option.id === value,
        name: "deliveryTimeSlot",
        defaultValue: activeFilters?.deliveryTimeSlot || undefined,
        hasMore: false,
        onFetchMore: () => {},
        loading: isLoadingTimeSlots,
        options: timeSlotsData || [],
      },
    },
    {
      id: "deliveryDate",
      grid: 6,
      type: FieldsTypes.date,
      name: "deliveryDate",
      fieldProps: {
        placeholder: t("Delivery Date"),
        label: t("Delivery Date"),
        name: "deliveryDate",
        defaultValue: null,
      },
    },
    {
      id: "orderType",
      grid: 6,
      name: "orderType",
      type: FieldsTypes.autocomplete,
      fieldProps: {
        placeholder: t("Order Type"),
        label: t("Order Type"),
        name: "orderType",
        getOptionLabel: o => o.label,
        defaultValue: deliveryTypeOptions.find(item => item.label === activeFilters?.[t("orderType")]),
        isOptionEqualToValue: (option, value) => {
          return option.value === value.value;
        },
        options: deliveryTypeOptions,
      },
    },
    {
      id: "hasHelpDiskTicket",
      grid: 6,
      name: "hasHelpDiskTicket",
      type: FieldsTypes.checkbox,
      fieldProps: {
        defaultChecked: activeFilters?.hasHelpDiskTicket ?? undefined,
        label: t("Has Help Disk Ticket"),
        name: "hasHelpDiskTicket",
      },
    },
  ];

  return { filters, deliveryTypeOptions };
};
