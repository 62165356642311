/* eslint-disable react/jsx-handler-names */
import { useTranslation } from "@health/i18n";
import { ExpandMore } from "@mui/icons-material";
import { Autocomplete, Box, Button, Dialog, Divider, Grid, TextField } from "@mui/material";
import React, { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FilterIcon } from "../../icons";
import { CustomMuiDialogActions, CustomMuiDialogContent, CustomMuiDialogTitle } from "../CustomMuiDialog";
import { Filter } from "../Filter";
import { StyledIconButton } from "./DynamicFilterDialog.style";
import { DynamicFilterDialogProps } from "./DynamicFilterDialog.types";
import { useDynamicFilterDialogCustomHook } from "./DynamicFilterDialogCustomHooks.hook";

export const DynamicFilterDialog: FC<DynamicFilterDialogProps> = ({
  filterFields,
  activeFilters,
  isFilterOpened,
  onFilterChanged,
  onGetSearchOptions,
  onApplyFilters: onSubmit,
}) => {
  const { t } = useTranslation();
  const { register, control, handleSubmit, reset, unregister, formState } = useForm<any>();
  const [activeFields, setActiveFields] = React.useState([]);
  const handleToggle = () => {
    onFilterChanged();
  };
  const handleSubmitClicked = (data: any) => {
    onSubmit(data);
    handleToggle();
  };
  const handleTextChange = (_, newValue) => {
    const selectedField = filterFields?.find(item => item.fieldProps?.name == newValue?.id);
    if (selectedField) {
      onGetSearchOptions?.(selectedField?.id);
      setActiveFields(prev => [...prev, selectedField]);
    }
  };

  const handleFilterTextChange = (field_id: string, keyword: string) => {
    onGetSearchOptions(field_id, keyword);
  };
  const { optimizedSearchSubFilter } = useDynamicFilterDialogCustomHook(handleFilterTextChange);

  useEffect(() => {
    !!activeFilters && handleSaveActiveFields();
  }, [JSON.stringify(activeFilters), open]);

  useEffect(() => {
    !!activeFields?.length && handleSaveActiveFields();
  }, [JSON.stringify(filterFields)]);

  const handleSaveActiveFields = () => {
    const activeFilterKeys = activeFields?.map(key => key?.id);
    const selectedFields = filterFields?.filter(item => activeFilterKeys?.includes(item?.id));
    setActiveFields(selectedFields);
  };

  const handleFieldDelete = (field: any) => {
    unregister(field?.id);
    setActiveFields(prev => {
      return prev?.filter(item => item?.id != field?.id);
    });
  };

  const options = React.useMemo(() => {
    return filterFields
      ?.map(item => ({
        id: item?.fieldProps?.name,
        label: item?.fieldProps?.label,
      }))
      .filter(item => !activeFields?.find(selected => selected.fieldProps?.name == item.id));
  }, [filterFields, activeFields]);

  const handleFormReset = () => {
    const resetField = filterFields?.reduce((acc, item) => ({ ...acc, [item?.id]: "" }), {});
    reset(resetField);

    setActiveFields([]);
  };

  return (
    <>
      <React.Fragment>
        <StyledIconButton color='primary' size='medium' onClick={handleToggle}>
          <FilterIcon />
        </StyledIconButton>
        <Dialog
          maxWidth={"md"}
          fullWidth
          open={isFilterOpened || false}
          PaperProps={{
            style: { borderRadius: "15px" },
          }}
          scroll={"paper"}
          aria-labelledby='scroll-dialog-title'
          aria-describedby='scroll-dialog-description'
        >
          <form onSubmit={handleSubmit(handleSubmitClicked)}>
            <CustomMuiDialogTitle onClose={handleToggle} title={t("Filter")} />
            <CustomMuiDialogContent>
              <>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    popupIcon={<ExpandMore />}
                    onChange={handleTextChange}
                    options={options}
                    noOptionsText={t("No Options")}
                    renderInput={params => <TextField hiddenLabel fullWidth {...params} placeholder={t("Chose a filter field")} />}
                  />
                </Grid>
              </>
              {activeFilters && (
                <>
                  <Box marginTop={1} marginBottom={1}>
                    <Divider />
                  </Box>

                  <Filter
                    fields={activeFields}
                    onFieldDelete={handleFieldDelete}
                    register={register}
                    onChangeText={optimizedSearchSubFilter}
                    control={control}
                  />
                </>
              )}
            </CustomMuiDialogContent>
            <CustomMuiDialogActions>
              <Button type='submit'>{t("Submit")}</Button>
              <Button variant='outlined' onClick={handleFormReset} disabled={!formState?.isDirty}>
                {t("Reset")}
              </Button>
            </CustomMuiDialogActions>
          </form>
        </Dialog>
      </React.Fragment>
    </>
  );
};
