/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type PermissionGroupErrorsFragmentFragment = { __typename?: 'PermissionGroupError', field?: string | null, message?: string | null, code: Types.PermissionGroupErrorCode };

export const PermissionGroupErrorsFragmentFragmentDoc = gql`
    fragment PermissionGroupErrorsFragment on PermissionGroupError {
  field
  message
  code
}
    `;