/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../fragment/PageInfo/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CitiesSettingsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.CityFilterInput>;
  sortBy?: Types.InputMaybe<Types.CityOrder>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CitiesSettingsQuery = { __typename?: 'Query', cities?: { __typename?: 'CityCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'CityCountableEdge', node: { __typename?: 'City', id: string, name: string, maxNumberOfRounds?: number | null, maxNumberOfTries?: number | null, timeOutPeriod?: number | null, maxRadiusForPickup: number, rounds?: Array<{ __typename?: 'CityRound', id: string, radius: number, maxNumberOfPharmacies: number, pharmaciesTypes?: Array<Types.VendorBranchTypeEnum | null> | null } | null> | null } }> } | null };


export const CitiesSettingsDocument = gql`
    query citiesSettings($first: Int, $last: Int, $filter: CityFilterInput, $sortBy: CityOrder, $after: String, $before: String) {
  cities(
    first: $first
    last: $last
    filter: $filter
    after: $after
    sortBy: $sortBy
    before: $before
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        id
        name
        maxNumberOfRounds
        maxNumberOfTries
        timeOutPeriod
        maxRadiusForPickup
        rounds {
          id
          radius
          maxNumberOfPharmacies
          pharmaciesTypes
        }
      }
    }
    totalCount
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useCitiesSettingsQuery__
 *
 * To run a query within a React component, call `useCitiesSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCitiesSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCitiesSettingsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useCitiesSettingsQuery(baseOptions?: Apollo.QueryHookOptions<CitiesSettingsQuery, CitiesSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CitiesSettingsQuery, CitiesSettingsQueryVariables>(CitiesSettingsDocument, options);
      }
export function useCitiesSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CitiesSettingsQuery, CitiesSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CitiesSettingsQuery, CitiesSettingsQueryVariables>(CitiesSettingsDocument, options);
        }
export type CitiesSettingsQueryHookResult = ReturnType<typeof useCitiesSettingsQuery>;
export type CitiesSettingsLazyQueryHookResult = ReturnType<typeof useCitiesSettingsLazyQuery>;
export type CitiesSettingsQueryResult = Apollo.QueryResult<CitiesSettingsQuery, CitiesSettingsQueryVariables>;