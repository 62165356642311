/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PrescriptionValidateMutationVariables = Types.Exact<{
  input: Types.ValidatePrescriptionInput;
}>;


export type PrescriptionValidateMutation = { __typename?: 'Mutation', validatePrescription?: { __typename?: 'ValidatePrescription', allowedDeliveryTypes?: Array<Types.DeliveryTypes | null> | null, allowedFastDeliveryTypes?: Array<Types.DeliveryTypes | null> | null, prescription?: { __typename?: 'Prescription', patientIdentifier?: string | null, referenceNumber: string, dispenseStatus: Types.PrescriptionDispenseStatus, isFreeDelivery?: boolean | null } | null, prescriptionErrors: Array<{ __typename?: 'PrescriptionError', code: Types.PrescriptionErrorCode, message?: string | null, field?: string | null }> } | null };


export const PrescriptionValidateDocument = gql`
    mutation PrescriptionValidate($input: ValidatePrescriptionInput!) {
  validatePrescription(input: $input) {
    prescription {
      patientIdentifier
      referenceNumber
      dispenseStatus
      isFreeDelivery
    }
    allowedDeliveryTypes
    allowedFastDeliveryTypes
    prescriptionErrors {
      code
      message
      field
    }
  }
}
    `;
export type PrescriptionValidateMutationFn = Apollo.MutationFunction<PrescriptionValidateMutation, PrescriptionValidateMutationVariables>;

/**
 * __usePrescriptionValidateMutation__
 *
 * To run a mutation, you first call `usePrescriptionValidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrescriptionValidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prescriptionValidateMutation, { data, loading, error }] = usePrescriptionValidateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrescriptionValidateMutation(baseOptions?: Apollo.MutationHookOptions<PrescriptionValidateMutation, PrescriptionValidateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PrescriptionValidateMutation, PrescriptionValidateMutationVariables>(PrescriptionValidateDocument, options);
      }
export type PrescriptionValidateMutationHookResult = ReturnType<typeof usePrescriptionValidateMutation>;
export type PrescriptionValidateMutationResult = Apollo.MutationResult<PrescriptionValidateMutation>;
export type PrescriptionValidateMutationOptions = Apollo.BaseMutationOptions<PrescriptionValidateMutation, PrescriptionValidateMutationVariables>;