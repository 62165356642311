/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../fragment/PageInfo/__generated__/pageInfoFragment';
import { CategoryFragmentFragmentDoc } from '../../../../fragment/categories/__generated__/Category';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CategoriesQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.CategoryFilterInput>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  sortBy?: Types.InputMaybe<Types.CategorySortingInput>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  level?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type CategoriesQuery = { __typename?: 'Query', categories?: { __typename?: 'CategoryCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'CategoryCountableEdge', node: { __typename?: 'Category', id: string, name?: string | null, level: number, seoTitle?: string | null, description?: string | null, slug: string, backgroundImage?: { __typename?: 'Image', url?: string | null, alt?: string | null } | null, parent?: { __typename?: 'Category', id: string } | null, children?: { __typename?: 'CategoryCountableConnection', totalCount?: number | null } | null } }> } | null };


export const CategoriesDocument = gql`
    query categories($first: Int, $filter: CategoryFilterInput, $after: String, $sortBy: CategorySortingInput, $before: String, $last: Int, $level: Int) {
  categories(
    first: $first
    filter: $filter
    after: $after
    sortBy: $sortBy
    before: $before
    last: $last
    level: $level
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    totalCount
    edges {
      node {
        ...CategoryFragment
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}
${CategoryFragmentFragmentDoc}`;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *      after: // value for 'after'
 *      sortBy: // value for 'sortBy'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      level: // value for 'level'
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
      }
export function useCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
        }
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesQueryResult = Apollo.QueryResult<CategoriesQuery, CategoriesQueryVariables>;