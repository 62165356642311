import { ProcessingStatus } from "@health/queries";

export const getProcessDotColor = (lastCadeSystemProcessingStatus: ProcessingStatus) => {
  switch (lastCadeSystemProcessingStatus) {
    case ProcessingStatus.UnderReview:
      return "warning";
    case ProcessingStatus.Approved:
      return "success";
    case ProcessingStatus.Published:
      return "secondary";
    case ProcessingStatus.Rejected:
      return "error";
    case ProcessingStatus.Expired:
      return "primary";
    case ProcessingStatus.Draft:
      return "info";
    default:
      return "error";
  }
};
