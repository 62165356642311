/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../fragment/PageInfo/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCodeSystemsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']>;
  code?: Types.InputMaybe<Types.CodeSystemCode>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  isActive?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type GetCodeSystemsQuery = { __typename?: 'Query', getCodeSystems?: { __typename?: 'CodeSystemConnection', edges?: Array<{ __typename?: 'CodeSystemEdge', node?: { __typename?: 'CodeSystem', id?: string | null, display?: string | null, isActive?: boolean | null, version?: number | null, processingStatus?: Types.ProcessingStatus | null } | null } | null> | null, pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null } | null };


export const GetCodeSystemsDocument = gql`
    query getCodeSystems($after: String, $code: CodeSystemCode, $first: Int, $isActive: Boolean) {
  getCodeSystems(first: $first, after: $after, code: $code, isActive: $isActive) {
    edges {
      node {
        id
        display
        isActive
        version
        processingStatus
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useGetCodeSystemsQuery__
 *
 * To run a query within a React component, call `useGetCodeSystemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCodeSystemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCodeSystemsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      code: // value for 'code'
 *      first: // value for 'first'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useGetCodeSystemsQuery(baseOptions?: Apollo.QueryHookOptions<GetCodeSystemsQuery, GetCodeSystemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCodeSystemsQuery, GetCodeSystemsQueryVariables>(GetCodeSystemsDocument, options);
      }
export function useGetCodeSystemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCodeSystemsQuery, GetCodeSystemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCodeSystemsQuery, GetCodeSystemsQueryVariables>(GetCodeSystemsDocument, options);
        }
export type GetCodeSystemsQueryHookResult = ReturnType<typeof useGetCodeSystemsQuery>;
export type GetCodeSystemsLazyQueryHookResult = ReturnType<typeof useGetCodeSystemsLazyQuery>;
export type GetCodeSystemsQueryResult = Apollo.QueryResult<GetCodeSystemsQuery, GetCodeSystemsQueryVariables>;