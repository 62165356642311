/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetShipmentOrderForDeliveryShipmentQueryVariables = Types.Exact<{
  allocateOrderId?: Types.InputMaybe<Types.Scalars['String']>;
  dispenseOrderId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetShipmentOrderForDeliveryShipmentQuery = { __typename?: 'Query', getShipmentOrderForDeliveryShipment?: { __typename?: 'DeliveryShipmentGetShipmentResponseResult', data?: { __typename?: 'DeliveryShipmentGetShipmentResponse', aggregator?: string | null, awb?: string | null, dispenseOrderIdAccumulated?: string | null, dispenseOrderIdSeparator?: string | null, tripCost?: number | null, carrierCompany?: { __typename?: 'DeliveryShipmentCarrierCompany', name?: string | null } | null, driver?: { __typename?: 'DeliveryShipmentDriver', licenseNo?: string | null, name?: string | null, phoneNumber?: string | null } | null } | null } | null };


export const GetShipmentOrderForDeliveryShipmentDocument = gql`
    query getShipmentOrderForDeliveryShipment($allocateOrderId: String, $dispenseOrderId: String) {
  getShipmentOrderForDeliveryShipment(
    allocateOrderId: $allocateOrderId
    dispenseOrderId: $dispenseOrderId
  ) {
    data {
      aggregator
      awb
      dispenseOrderIdAccumulated
      dispenseOrderIdSeparator
      carrierCompany {
        name
      }
      driver {
        licenseNo
        name
        phoneNumber
      }
      tripCost
    }
  }
}
    `;

/**
 * __useGetShipmentOrderForDeliveryShipmentQuery__
 *
 * To run a query within a React component, call `useGetShipmentOrderForDeliveryShipmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShipmentOrderForDeliveryShipmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShipmentOrderForDeliveryShipmentQuery({
 *   variables: {
 *      allocateOrderId: // value for 'allocateOrderId'
 *      dispenseOrderId: // value for 'dispenseOrderId'
 *   },
 * });
 */
export function useGetShipmentOrderForDeliveryShipmentQuery(baseOptions?: Apollo.QueryHookOptions<GetShipmentOrderForDeliveryShipmentQuery, GetShipmentOrderForDeliveryShipmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShipmentOrderForDeliveryShipmentQuery, GetShipmentOrderForDeliveryShipmentQueryVariables>(GetShipmentOrderForDeliveryShipmentDocument, options);
      }
export function useGetShipmentOrderForDeliveryShipmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShipmentOrderForDeliveryShipmentQuery, GetShipmentOrderForDeliveryShipmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShipmentOrderForDeliveryShipmentQuery, GetShipmentOrderForDeliveryShipmentQueryVariables>(GetShipmentOrderForDeliveryShipmentDocument, options);
        }
export type GetShipmentOrderForDeliveryShipmentQueryHookResult = ReturnType<typeof useGetShipmentOrderForDeliveryShipmentQuery>;
export type GetShipmentOrderForDeliveryShipmentLazyQueryHookResult = ReturnType<typeof useGetShipmentOrderForDeliveryShipmentLazyQuery>;
export type GetShipmentOrderForDeliveryShipmentQueryResult = Apollo.QueryResult<GetShipmentOrderForDeliveryShipmentQuery, GetShipmentOrderForDeliveryShipmentQueryVariables>;