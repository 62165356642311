/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { CategoryFragmentFragmentDoc } from '../../../../fragment/categories/__generated__/Category';
import { CategoriesErrorsFragmentFragmentDoc } from '../../../../fragment/categories/__generated__/categoryErrorsFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CategoryCreateMutationVariables = Types.Exact<{
  input: Types.CategoryInput;
  parent?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type CategoryCreateMutation = { __typename?: 'Mutation', categoryCreate?: { __typename?: 'CategoryCreate', category?: { __typename?: 'Category', id: string, name?: string | null, level: number, seoTitle?: string | null, description?: string | null, slug: string, backgroundImage?: { __typename?: 'Image', url?: string | null, alt?: string | null } | null, parent?: { __typename?: 'Category', id: string } | null, children?: { __typename?: 'CategoryCountableConnection', totalCount?: number | null } | null } | null, productErrors: Array<{ __typename?: 'ProductError', field?: string | null, message?: string | null, code: Types.ProductErrorCode }> } | null };


export const CategoryCreateDocument = gql`
    mutation categoryCreate($input: CategoryInput!, $parent: ID) {
  categoryCreate(input: $input, parent: $parent) {
    category {
      ...CategoryFragment
    }
    productErrors {
      ...CategoriesErrorsFragment
    }
  }
}
    ${CategoryFragmentFragmentDoc}
${CategoriesErrorsFragmentFragmentDoc}`;
export type CategoryCreateMutationFn = Apollo.MutationFunction<CategoryCreateMutation, CategoryCreateMutationVariables>;

/**
 * __useCategoryCreateMutation__
 *
 * To run a mutation, you first call `useCategoryCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCategoryCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [categoryCreateMutation, { data, loading, error }] = useCategoryCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      parent: // value for 'parent'
 *   },
 * });
 */
export function useCategoryCreateMutation(baseOptions?: Apollo.MutationHookOptions<CategoryCreateMutation, CategoryCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CategoryCreateMutation, CategoryCreateMutationVariables>(CategoryCreateDocument, options);
      }
export type CategoryCreateMutationHookResult = ReturnType<typeof useCategoryCreateMutation>;
export type CategoryCreateMutationResult = Apollo.MutationResult<CategoryCreateMutation>;
export type CategoryCreateMutationOptions = Apollo.BaseMutationOptions<CategoryCreateMutation, CategoryCreateMutationVariables>;