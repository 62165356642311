import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import AdminAr from "./locales/ar/Admin.json";
import ConsumerAr from "./locales/ar/Consumer.json";
import translationAr from "./locales/ar/translation.json";
import vendorAr from "./locales/ar/vendor.json";
import Admin from "./locales/en/Admin.json";
import Consumer from "./locales/en/Consumer.json";
import translation from "./locales/en/translation.json";
import vendor from "./locales/en/vendor.json";

const langDetectorOptions = {
  // order and from where user language should be detected
  order: ["cookie", "localStorage", "navigator"],

  // keys or params to lookup language from
  lookupCookie: "locale",
  lookupLocalStorage: "locale",

  // cache user language on
  caches: ["localStorage", "cookie"],
  excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

  // only detect languages that are in the whitelist
  checkWhitelist: true,
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        Admin: Admin,
        translation: translation,
        vendor: vendor,
        Consumer: Consumer,
      },
      ar: {
        Admin: AdminAr,
        translation: translationAr,
        vendor: vendorAr,
        Consumer: ConsumerAr,
      },
    },
    supportedLngs: ["en", "ar"],
    saveMissing: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    debug: false,
    detection: langDetectorOptions,
    load: "languageOnly",
    returnObjects: true,
    keySeparator: false,
    cleanCode: true,
  });

export default i18next;
