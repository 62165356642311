/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { PermissionGroupFragmentFragmentDoc } from '../../../../fragment/Group/__generated__/permissionGroupFragment';
import { PermissionGroupErrorsFragmentFragmentDoc } from '../../../../fragment/PermissionGroupError/__generated__/permissionGroupError';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PermissionGroupCreateMutationVariables = Types.Exact<{
  input: Types.PermissionGroupCreateInput;
}>;


export type PermissionGroupCreateMutation = { __typename?: 'Mutation', permissionGroupCreate?: { __typename?: 'PermissionGroupCreate', group?: { __typename?: 'Group', id: string, name: string, users?: Array<{ __typename?: 'User', id: string, email: string, firstName?: string | null, lastName?: string | null } | null> | null, permissions?: Array<{ __typename?: 'Permission', code: Types.PermissionEnum, name: string } | null> | null } | null, permissionGroupErrors: Array<{ __typename?: 'PermissionGroupError', field?: string | null, message?: string | null, code: Types.PermissionGroupErrorCode }> } | null };


export const PermissionGroupCreateDocument = gql`
    mutation permissionGroupCreate($input: PermissionGroupCreateInput!) {
  permissionGroupCreate(input: $input) {
    group {
      ...PermissionGroupFragment
    }
    permissionGroupErrors {
      ...PermissionGroupErrorsFragment
    }
  }
}
    ${PermissionGroupFragmentFragmentDoc}
${PermissionGroupErrorsFragmentFragmentDoc}`;
export type PermissionGroupCreateMutationFn = Apollo.MutationFunction<PermissionGroupCreateMutation, PermissionGroupCreateMutationVariables>;

/**
 * __usePermissionGroupCreateMutation__
 *
 * To run a mutation, you first call `usePermissionGroupCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePermissionGroupCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [permissionGroupCreateMutation, { data, loading, error }] = usePermissionGroupCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePermissionGroupCreateMutation(baseOptions?: Apollo.MutationHookOptions<PermissionGroupCreateMutation, PermissionGroupCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PermissionGroupCreateMutation, PermissionGroupCreateMutationVariables>(PermissionGroupCreateDocument, options);
      }
export type PermissionGroupCreateMutationHookResult = ReturnType<typeof usePermissionGroupCreateMutation>;
export type PermissionGroupCreateMutationResult = Apollo.MutationResult<PermissionGroupCreateMutation>;
export type PermissionGroupCreateMutationOptions = Apollo.BaseMutationOptions<PermissionGroupCreateMutation, PermissionGroupCreateMutationVariables>;