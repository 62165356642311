import { useTranslation } from "@health/i18n";
import { Grid, Typography } from "@mui/material";
import { Moment } from "moment";
import moment from "moment-timezone";
import React, { FC } from "react";
import WorkingHoursComponent from "../WorkingHours";
import { DAYS } from "./constants";
import { useStyles } from "./styles";
import { DayTimeRange, WorkingHour, WorkingHoursCardProps } from "./types";
import { getMomentTime } from "./utils";

const noop = () => {
  void 0;
};
const WorkingHoursCard: FC<WorkingHoursCardProps> = ({ onChange = noop, workingHours, withTitle = true }) => {
  const classes = useStyles();
  const userTz = moment.tz.guess();
  const handleAddTime = (dayIndex: number, index: number) => {
    const newWeekDays = [...workingHours];
    const day = { ...newWeekDays[dayIndex] };
    day.openTimeRanges = day.openTimeRanges.slice();
    day.openTimeRanges.splice(index + 1, 0, {
      id: `${Math.random() * 1000}`,
      openTime: "",
      closeTime: "",
    });
    newWeekDays[dayIndex] = day;
    onChange(newWeekDays);
  };

  const handleTimeChange = (timeKey: string, dayIndex: number, newTime: Moment, indexWorkingHours: number) => {
    const newWeekDays = [...workingHours];
    const time = newWeekDays.map((item, index) =>
      index === dayIndex
        ? {
            ...item,
            openTimeRanges: item?.openTimeRanges.map((elem, index) =>
              index === indexWorkingHours
                ? {
                    ...elem,
                    [timeKey]: moment.tz(newTime, userTz).format("HH:mm:ss"),
                  }
                : {
                    ...elem,
                  }
            ),
          }
        : item
    );
    onChange(time);
  };

  const handleDays = (dayIndex, index) => {
    const newWeekDays = [...workingHours];
    const day = { ...newWeekDays[dayIndex] };
    day.openTimeRanges = day.openTimeRanges.slice();
    day.openTimeRanges.splice(index, 1);
    newWeekDays[dayIndex] = day;
    onChange(newWeekDays);
  };
  const handleRemoveTime = (dayIndex: number, index: number) => {
    if (workingHours[dayIndex].openTimeRanges.length === 1) {
      handleTimeChange("closeTime", dayIndex, null, index);
      handleTimeChange("openTime", dayIndex, null, index);
      return;
    }
    handleDays(dayIndex, index);
  };

  const { t } = useTranslation();
  return (
    <Grid container>
      {withTitle && (
        <Grid item xs={12} className={classes.header}>
          <Typography className={classes.cardTitle}>{t("Working Hours")}</Typography>
        </Grid>
      )}
      {workingHours?.map((workingDay: WorkingHour, dayIndex: number) => (
        <React.Fragment key={dayIndex}>
          {workingDay.openTimeRanges.map((timeRange: DayTimeRange, timeIndex: number) => {
            return (
              <Grid key={`${workingDay.day}_${timeRange.id}`} item xs={12} className={classes.day}>
                <WorkingHoursComponent
                  onTimeChange={(date: Moment, type) => handleTimeChange(type, dayIndex, date, timeIndex)}
                  min={getMomentTime(workingDay?.openTimeRanges[timeIndex - 1]?.closeTime)}
                  labelInputOne={t("Opening")}
                  labelInputTwo={t("Closing")}
                  closeTime={timeRange.closeTime}
                  openTime={timeRange.openTime}
                  dayName={timeIndex > 0 ? "" : DAYS(t)?.[workingDay.day]}
                  onRemoveTime={() => handleRemoveTime(dayIndex, timeIndex)}
                  isLastItem={timeIndex === workingDay?.openTimeRanges?.length - 1}
                  onAddTime={() => handleAddTime(dayIndex, timeIndex)}
                />
              </Grid>
            );
          })}
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default WorkingHoursCard;
