/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VendorProductVariantStocksUpdateMutationVariables = Types.Exact<{
  input: Types.VendorProductVariantStocksUpdateInput;
}>;


export type VendorProductVariantStocksUpdateMutation = { __typename?: 'Mutation', vendorProductVariantStocksUpdate?: { __typename?: 'VendorProductVariantStocksUpdate', vendorProductVariant?: { __typename?: 'VendorProductVariant', id: string } | null, stockErrors: Array<{ __typename?: 'StockError', field?: string | null, message?: string | null, code: Types.StockErrorCode }> } | null };


export const VendorProductVariantStocksUpdateDocument = gql`
    mutation vendorProductVariantStocksUpdate($input: VendorProductVariantStocksUpdateInput!) {
  vendorProductVariantStocksUpdate(input: $input) {
    vendorProductVariant {
      id
    }
    stockErrors {
      field
      message
      code
    }
  }
}
    `;
export type VendorProductVariantStocksUpdateMutationFn = Apollo.MutationFunction<VendorProductVariantStocksUpdateMutation, VendorProductVariantStocksUpdateMutationVariables>;

/**
 * __useVendorProductVariantStocksUpdateMutation__
 *
 * To run a mutation, you first call `useVendorProductVariantStocksUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVendorProductVariantStocksUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vendorProductVariantStocksUpdateMutation, { data, loading, error }] = useVendorProductVariantStocksUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVendorProductVariantStocksUpdateMutation(baseOptions?: Apollo.MutationHookOptions<VendorProductVariantStocksUpdateMutation, VendorProductVariantStocksUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VendorProductVariantStocksUpdateMutation, VendorProductVariantStocksUpdateMutationVariables>(VendorProductVariantStocksUpdateDocument, options);
      }
export type VendorProductVariantStocksUpdateMutationHookResult = ReturnType<typeof useVendorProductVariantStocksUpdateMutation>;
export type VendorProductVariantStocksUpdateMutationResult = Apollo.MutationResult<VendorProductVariantStocksUpdateMutation>;
export type VendorProductVariantStocksUpdateMutationOptions = Apollo.BaseMutationOptions<VendorProductVariantStocksUpdateMutation, VendorProductVariantStocksUpdateMutationVariables>;