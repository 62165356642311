import { TFunction } from "@health/i18n";

export const formatNames = (...args) => args.filter(Boolean).join(" ") || "-";

export const getCreatorName = (statusText, creator, t: TFunction<"Admin", undefined>) => {
  if (statusText === "Draft" || statusText === undefined || statusText === "Expired" || creator === undefined || creator === null) {
    return statusText;
  } else {
    return statusText + t("- By :") + " " + creator;
  }
};
