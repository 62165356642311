import { makeStyles } from "tss-react/mui";
import { DotProps } from "./dot.types";

export const useDotStyle = makeStyles<DotProps>()((theme, props) => {
  return {
    root: {
      width: props.width || 10,
      height: props.height || 10,
      borderRadius: "50%",
      background: props.background || theme.palette?.secondary?.main,
    },
  };
});
