/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProcessPaymentCheckoutMutationVariables = Types.Exact<{
  paymentCheckoutInput: Types.PaymentCheckoutInput;
}>;


export type ProcessPaymentCheckoutMutation = { __typename?: 'Mutation', processPaymentCheckout?: { __typename?: 'PaymentCheckoutResponse', id?: string | null, shopperResultUrl?: string | null, checkoutPaymentFormUrl?: string | null } | null };


export const ProcessPaymentCheckoutDocument = gql`
    mutation ProcessPaymentCheckout($paymentCheckoutInput: PaymentCheckoutInput!) {
  processPaymentCheckout(paymentCheckoutInput: $paymentCheckoutInput) {
    id
    shopperResultUrl
    checkoutPaymentFormUrl
  }
}
    `;
export type ProcessPaymentCheckoutMutationFn = Apollo.MutationFunction<ProcessPaymentCheckoutMutation, ProcessPaymentCheckoutMutationVariables>;

/**
 * __useProcessPaymentCheckoutMutation__
 *
 * To run a mutation, you first call `useProcessPaymentCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessPaymentCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processPaymentCheckoutMutation, { data, loading, error }] = useProcessPaymentCheckoutMutation({
 *   variables: {
 *      paymentCheckoutInput: // value for 'paymentCheckoutInput'
 *   },
 * });
 */
export function useProcessPaymentCheckoutMutation(baseOptions?: Apollo.MutationHookOptions<ProcessPaymentCheckoutMutation, ProcessPaymentCheckoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessPaymentCheckoutMutation, ProcessPaymentCheckoutMutationVariables>(ProcessPaymentCheckoutDocument, options);
      }
export type ProcessPaymentCheckoutMutationHookResult = ReturnType<typeof useProcessPaymentCheckoutMutation>;
export type ProcessPaymentCheckoutMutationResult = Apollo.MutationResult<ProcessPaymentCheckoutMutation>;
export type ProcessPaymentCheckoutMutationOptions = Apollo.BaseMutationOptions<ProcessPaymentCheckoutMutation, ProcessPaymentCheckoutMutationVariables>;