/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../fragment/PageInfo/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StocksQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.StockFilterInput>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type StocksQuery = { __typename?: 'Query', stocks?: { __typename?: 'StockCountableConnection', pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'StockCountableEdge', node: { __typename?: 'Stock', quantity: number, quantityAllocated: number, branch?: { __typename?: 'Branch', id: string, name: string, nameAr?: string | null } | null, productVariant: { __typename?: 'ProductVariant', name?: string | null } } }> } | null };


export const StocksDocument = gql`
    query Stocks($filter: StockFilterInput, $after: String, $last: Int, $before: String, $first: Int) {
  stocks(
    filter: $filter
    after: $after
    last: $last
    before: $before
    first: $first
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        quantity
        branch {
          id
          name
          nameAr
        }
        productVariant {
          name
        }
        quantityAllocated
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useStocksQuery__
 *
 * To run a query within a React component, call `useStocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useStocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStocksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useStocksQuery(baseOptions?: Apollo.QueryHookOptions<StocksQuery, StocksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StocksQuery, StocksQueryVariables>(StocksDocument, options);
      }
export function useStocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StocksQuery, StocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StocksQuery, StocksQueryVariables>(StocksDocument, options);
        }
export type StocksQueryHookResult = ReturnType<typeof useStocksQuery>;
export type StocksLazyQueryHookResult = ReturnType<typeof useStocksLazyQuery>;
export type StocksQueryResult = Apollo.QueryResult<StocksQuery, StocksQueryVariables>;