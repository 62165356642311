/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminProductVariantsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type AdminProductVariantsQuery = { __typename?: 'Query', products?: { __typename?: 'ProductCountableConnection', pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'ProductCountableEdge', node: { __typename?: 'Product', id: string, name?: string | null, productType: { __typename?: 'ProductType', variantAttributes?: Array<{ __typename?: 'Attribute', name?: string | null, id: string, slug?: string | null, valueRequired: boolean, values?: Array<{ __typename?: 'AttributeValue', id: string, name?: string | null, slug?: string | null } | null> | null } | null> | null } } }> } | null };


export const AdminProductVariantsDocument = gql`
    query adminProductVariants($first: Int, $last: Int, $after: String, $before: String) {
  products(first: $first, last: $last, after: $after, before: $before) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        id
        name
        productType {
          variantAttributes {
            name
            id
            values {
              id
              name
              slug
            }
            slug
            valueRequired
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAdminProductVariantsQuery__
 *
 * To run a query within a React component, call `useAdminProductVariantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminProductVariantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminProductVariantsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useAdminProductVariantsQuery(baseOptions?: Apollo.QueryHookOptions<AdminProductVariantsQuery, AdminProductVariantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminProductVariantsQuery, AdminProductVariantsQueryVariables>(AdminProductVariantsDocument, options);
      }
export function useAdminProductVariantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminProductVariantsQuery, AdminProductVariantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminProductVariantsQuery, AdminProductVariantsQueryVariables>(AdminProductVariantsDocument, options);
        }
export type AdminProductVariantsQueryHookResult = ReturnType<typeof useAdminProductVariantsQuery>;
export type AdminProductVariantsLazyQueryHookResult = ReturnType<typeof useAdminProductVariantsLazyQuery>;
export type AdminProductVariantsQueryResult = Apollo.QueryResult<AdminProductVariantsQuery, AdminProductVariantsQueryVariables>;