import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { getAppFonts } from "../../customization";

export const TypographyRegular = styled(Typography)(() => ({
  fontFamily: getAppFonts("regular"),
  fontSize: "16px",
}));

export const TypographyMedium = styled(Typography)(() => ({
  fontFamily: getAppFonts("medium"),
  fontSize: "16px",
}));

export const TypographyBold = styled(Typography)(() => ({
  fontFamily: getAppFonts("bold"),
  fontSize: "16px",
}));
