import React, { FC, useState } from "react";
import { MenuItemContext } from "./context";

export const MenuContextItemsProvider: FC = ({ children }) => {
  const [id, setId] = useState();
  return (
    <MenuItemContext.Provider
      value={{
        id,
        setId,
      }}
    >
      {children}
    </MenuItemContext.Provider>
  );
};
