// import { PrescriptionDispenseStatus } from "@health/queries";

import { Maybe, Money, PrescriptionDispenseStatus } from "@health/queries";
import { cyan, darkBlue, darkRed, green, orange } from "@health/ui";

export const priceFormatter = (price?: Maybe<Money>) => (price ? `${price?.currency} ${price?.amount}` : 0);

export const GetStatusColor = status => {
  switch (status) {
    case PrescriptionDispenseStatus.Pending:
      return orange;
    case PrescriptionDispenseStatus.OutForDelivery:
      return cyan;
    case PrescriptionDispenseStatus.ErxHubRejected:
      return darkRed;
    case PrescriptionDispenseStatus.ErxHubPartiallyApproved:
      return green;
    case PrescriptionDispenseStatus.WaitingErxHubApproval:
      return orange;
    case PrescriptionDispenseStatus.WaitingProviderSelection:
      return orange;

    default:
      return darkBlue;
  }
};
