import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import { darkBlue } from "../../customization/colors";

export const CustomAdminTab = styled(Tab)({
  color: darkBlue,
  backgroundColor: "white",
  "&.MuiTab-root.Mui-selected": {
    backgroundColor: "transparent",
    fontWeight: "bold",
  },
});
export { default as CustomAdminTabs } from "@mui/material/Tabs";
