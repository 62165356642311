import { getEnvVariable } from "./env";

export const getMediaLink = (file: string | null | undefined) => {
  const DOWNLOAD_SERVICE: string | undefined | any = getEnvVariable("DOWNLOAD_SERVICE_URL");

  if (!file) return "";
  if (typeof file !== "string") {
    if (Object.prototype.hasOwnProperty.call(file, "url") && file?.["url"]) {
      return `${DOWNLOAD_SERVICE}${file?.["url"]}`;
    }
    return file;
  }
  if (file.startsWith("http")) return file;
  if (file.includes(getEnvVariable("BASE_MEDIA_URL"))) return file;
  return `${DOWNLOAD_SERVICE}${file}`;
};

export const getAssetsFromCDN = (name: string) => getEnvVariable("IMAGES_DOMAIN_URL") + "/" + name;
export const getAssetsFromFileStorage = (name: string) => getEnvVariable("DOWNLOAD_SERVICE_URL") + name;
