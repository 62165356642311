/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../fragment/PageInfo/__generated__/pageInfoFragment';
import { BranchFragmentFragmentDoc } from '../../../../fragment/branch/__generated__/branchFragment';
import { AddressFragmentFragmentDoc } from '../../../../fragment/address/__generated__/address';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BranchesDataQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.BranchFilterInput>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  sortBy?: Types.InputMaybe<Types.BranchOrder>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type BranchesDataQuery = { __typename?: 'Query', branches?: { __typename?: 'BranchCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'BranchCountableEdge', node: { __typename?: 'Branch', created: any, acceptsDelivery: boolean, acceptsPickup: boolean, contactEmail?: string | null, healthLicense?: string | null, healthLicenseStartDate?: any | null, healthLicenseEndDate?: any | null, contactNumber?: string | null, type?: Types.BranchType | null, id: string, isActive: boolean, name: string, nameAr?: string | null, description: string, vendor: { __typename?: 'Vendor', id: string, isActive: boolean, hasMultipleBranches: boolean }, address?: { __typename: 'Address', id: string, name?: string | null, area?: string | null, streetAddress1?: string | null, streetAddress2?: string | null, buildingName?: string | null, buildingNumber?: string | null, phone?: string | null, isDefaultShippingAddress?: boolean | null, region?: string | null, city: { __typename?: 'City', id: string, name: string, nameAr?: string | null }, coordinates?: { __typename?: 'LocationType', lng?: number | null, lat?: number | null } | null, translations?: Array<{ __typename?: 'AddressTranslation', streetAddress1: string, streetAddress2: string, area: string, language: { __typename?: 'LanguageDisplay', code: Types.LanguageCodeEnum, language: string } } | null> | null } | null, workingHours?: Array<{ __typename?: 'WorkingHour', day?: Types.DayOfWeekEnum | null, openTimeRanges?: Array<{ __typename?: 'TimeRange', openTime?: any | null, closeTime?: any | null } | null> | null } | null> | null } }> } | null };


export const BranchesDataDocument = gql`
    query BranchesData($first: Int, $filter: BranchFilterInput, $after: String, $sortBy: BranchOrder, $before: String, $last: Int) {
  branches(
    first: $first
    filter: $filter
    after: $after
    sortBy: $sortBy
    before: $before
    last: $last
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        created
        vendor {
          id
          isActive
          hasMultipleBranches
        }
        acceptsDelivery
        acceptsPickup
        contactEmail
        healthLicense
        healthLicenseStartDate
        healthLicenseEndDate
        ...BranchFragment
        address {
          ...AddressFragment
        }
        workingHours {
          day
          openTimeRanges {
            openTime
            closeTime
          }
        }
        contactNumber
        type
        healthLicense
      }
    }
    totalCount
  }
}
    ${PageInfoFragmentFragmentDoc}
${BranchFragmentFragmentDoc}
${AddressFragmentFragmentDoc}`;

/**
 * __useBranchesDataQuery__
 *
 * To run a query within a React component, call `useBranchesDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchesDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchesDataQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *      after: // value for 'after'
 *      sortBy: // value for 'sortBy'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useBranchesDataQuery(baseOptions?: Apollo.QueryHookOptions<BranchesDataQuery, BranchesDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BranchesDataQuery, BranchesDataQueryVariables>(BranchesDataDocument, options);
      }
export function useBranchesDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BranchesDataQuery, BranchesDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BranchesDataQuery, BranchesDataQueryVariables>(BranchesDataDocument, options);
        }
export type BranchesDataQueryHookResult = ReturnType<typeof useBranchesDataQuery>;
export type BranchesDataLazyQueryHookResult = ReturnType<typeof useBranchesDataLazyQuery>;
export type BranchesDataQueryResult = Apollo.QueryResult<BranchesDataQuery, BranchesDataQueryVariables>;