import { ActiveFilters, CustomTable, PageWrapper, StaticFilterDialog } from "@health/ui";
import React from "react";
import usePharmacyListingCriteriaHook from "./usePharmacyListingCriteriaHook";

const PharmacyListingCriteriaList = () => {
  const {
    columns,
    items,
    pageSize,
    pageInfo,
    isLoading,
    activeFilters,
    totalCount,
    fields,
    handleEditRow,
    handleApplyFilters,
    handleGotoNext,
    handleGoToPrevious,
    handlePageSizeChange,
    handleSortData,
    isFilterOpened,
    handleToggleFilters,
  } = usePharmacyListingCriteriaHook();
  const pageCount = (totalCount || pageSize) / pageSize;

  return (
    <PageWrapper
      start={<ActiveFilters onOpenFilters={handleToggleFilters} activeFilters={activeFilters ? activeFilters : []} />}
      actions={
        <>
          <StaticFilterDialog
            isOpen={isFilterOpened}
            onToggleDialog={handleToggleFilters}
            activeFilters={activeFilters}
            filterFields={fields}
            onApplyFilters={handleApplyFilters}
          />
        </>
      }
    >
      <CustomTable
        data={items}
        columns={columns}
        isLoading={isLoading}
        isEditVisible
        isDeleteVisible={false}
        pageSize={pageSize}
        pageIndex={1}
        totalCount={totalCount}
        pagesCount={pageCount}
        hasNextPage={pageInfo?.hasNextPage}
        hasPreviousPage={pageInfo?.hasPreviousPage}
        onSortColumn={handleSortData}
        onGoToNext={handleGotoNext}
        onGoToPrevious={handleGoToPrevious}
        onEditRow={handleEditRow}
        onPageSizeChange={handlePageSizeChange}
      />
    </PageWrapper>
  );
};

export default PharmacyListingCriteriaList;
