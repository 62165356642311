/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../fragment/PageInfo/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ParentCategoriesDropDownQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.CategoryFilterInput>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  sortBy?: Types.InputMaybe<Types.CategorySortingInput>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type ParentCategoriesDropDownQuery = { __typename?: 'Query', categories?: { __typename?: 'CategoryCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'CategoryCountableEdge', node: { __typename?: 'Category', id: string, name?: string | null } }> } | null };


export const ParentCategoriesDropDownDocument = gql`
    query parentCategoriesDropDown($first: Int, $filter: CategoryFilterInput, $after: String, $sortBy: CategorySortingInput, $before: String, $last: Int) {
  categories(
    first: $first
    filter: $filter
    after: $after
    sortBy: $sortBy
    before: $before
    last: $last
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    totalCount
    edges {
      node {
        id
        name
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useParentCategoriesDropDownQuery__
 *
 * To run a query within a React component, call `useParentCategoriesDropDownQuery` and pass it any options that fit your needs.
 * When your component renders, `useParentCategoriesDropDownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParentCategoriesDropDownQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *      after: // value for 'after'
 *      sortBy: // value for 'sortBy'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useParentCategoriesDropDownQuery(baseOptions?: Apollo.QueryHookOptions<ParentCategoriesDropDownQuery, ParentCategoriesDropDownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ParentCategoriesDropDownQuery, ParentCategoriesDropDownQueryVariables>(ParentCategoriesDropDownDocument, options);
      }
export function useParentCategoriesDropDownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParentCategoriesDropDownQuery, ParentCategoriesDropDownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ParentCategoriesDropDownQuery, ParentCategoriesDropDownQueryVariables>(ParentCategoriesDropDownDocument, options);
        }
export type ParentCategoriesDropDownQueryHookResult = ReturnType<typeof useParentCategoriesDropDownQuery>;
export type ParentCategoriesDropDownLazyQueryHookResult = ReturnType<typeof useParentCategoriesDropDownLazyQuery>;
export type ParentCategoriesDropDownQueryResult = Apollo.QueryResult<ParentCategoriesDropDownQuery, ParentCategoriesDropDownQueryVariables>;