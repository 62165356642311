import { onError } from "@apollo/client/link/error";
import { removeTokenFromLocalStorage, removeUserFromLocalStorage } from "@health/common";

const redirectToLogin = () => {
  removeUserFromLocalStorage();
  removeTokenFromLocalStorage();
  window.location.href = "/login";
};

export const errorLink = onError(e => {
  const { graphQLErrors, networkError } = e;
  const graphql401Error =
    graphQLErrors?.some(({ message, extensions }) => {
      return (
        message === "Exception: Invalid or expired token." ||
        message === "Invalid or expired token." ||
        extensions?.response?.status === 401
      );
    }) || false;

  if (graphql401Error || networkError?.["statusCode"] === 401) {
    redirectToLogin();
  }
  graphQLErrors?.forEach(({ message, locations, path }) => {
    console.error(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`);
  });
  if (networkError) console.error(`[Network error]: ${networkError}`);
});
