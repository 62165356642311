import { combineErrors, getRequiredValidation } from "@health/common";
import { i18n, useTranslation } from "@health/i18n";
import { Box, Button, EyeOffIcon, IconButton, InputAdornment, Show, TextField, Typography } from "@health/ui";
import React, { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useConfirmAccountHooks } from "../ConfirmAccount.hook";
import { FormErrors } from "../FormErrors.component";
import { useForgetPasswordStyle } from "../styles/useForgetPasswordStyle";
import { FormProps } from "./types";
import { patternPassword } from "./utils";

export const ChangePasswordForm: FC<FormProps> = ({ isPage, onChangeStep }) => {
  const { t } = useTranslation();
  const currentDir = i18n.dir(i18n.language);
  const { classes } = useForgetPasswordStyle();
  const {
    errors,
    showPassword,
    showConfirmPassword,
    handleResetError,
    onSubmit,
    handleLogin,
    handleClickShowPassword,
    handleClickShowConfirmPassword,
  } = useConfirmAccountHooks(onChangeStep, isPage);

  const {
    watch,
    handleSubmit,
    formState: { errors: fromErrors },
    register,
  } = useForm();

  const formErrors = combineErrors(fromErrors, errors);

  const data = watch();
  useEffect(() => {
    const noMsgError = fromErrors.confirmPassword?.message || fromErrors.password?.message;
    !noMsgError && handleResetError();
  }, [JSON.stringify(data)]);
  const textStyle = currentDir === "rtl" ? classes.textArabic : classes.text;

  return (
    <div className={classes.container}>
      <Typography fontFamily='Airbnb Cereal App Medium'>{t("Fill your new password")}</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box m='10px 0px'>
          <TextField
            fullWidth
            name='password'
            label={t("New Password")}
            placeholder={t("New Password")}
            type={showPassword ? "text" : "password"}
            error={formErrors.password?.message === t("Required") && Boolean(formErrors.password?.message)}
            helperText={formErrors.password?.message === t("Required") ? t(formErrors.password?.message) : ""}
            {...register("password", {
              required: getRequiredValidation(t, true),
              pattern: patternPassword,
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton sx={{ padding: 0 }} onClick={handleClickShowPassword}>
                    {showPassword ? <Show /> : <EyeOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box m='10px 0px'>
          <TextField
            fullWidth
            name='confirmPassword'
            label={t("Confirm Password")}
            placeholder={t("Confirm Password")}
            type={showConfirmPassword ? "text" : "password"}
            error={formErrors.confirmPassword?.message === t("Required") ? Boolean(formErrors.confirmPassword?.message) : false}
            helperText={formErrors.confirmPassword?.message === t("Required") ? t(formErrors.confirmPassword?.message) : ""}
            {...register("confirmPassword", {
              required: getRequiredValidation(t, true),
              pattern: patternPassword,
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton sx={{ padding: 0 }} onClick={handleClickShowConfirmPassword}>
                    {showConfirmPassword ? <Show /> : <EyeOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {errors.length > 0 && <FormErrors errors={errors} />}

        <Button type='submit' disabled={fromErrors.confirmPassword || fromErrors.password} fullWidth className={classes.button}>
          {t("Reset password")}
        </Button>

        <Button variant='text' className={classes.backToRegister} onClick={handleLogin}>
          {t("Back to Signin")}
        </Button>
      </form>
      <div
        className={
          formErrors.password?.type === "pattern" || formErrors.confirmPassword?.type === "pattern"
            ? classes.validationRed
            : classes.validation
        }
      >
        <Typography fontFamily='Airbnb Cereal App Medium' className={textStyle} gutterBottom>
          {t("Password Must Contain")}:
        </Typography>
        <Typography className={textStyle} gutterBottom>
          {t("Minimum of 8 characters")}
        </Typography>

        <Typography className={textStyle} gutterBottom>
          {t("At least one uppercase letter")}
        </Typography>
        <Typography className={textStyle} gutterBottom>
          {t("At least one special character")}
          {t("(I.e !@#$%&)")}
        </Typography>
      </div>
    </div>
  );
};
