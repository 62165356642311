/* eslint-disable */
/* prettier-ignore */
import * as Types from '@health/queries/dist/graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSubListMutationVariables = Types.Exact<{
  subList?: Types.InputMaybe<Types.SubListInput>;
}>;


export type UpdateSubListMutation = { __typename?: 'Mutation', response?: { __typename?: 'SubList', id?: string | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', field?: string | null, code?: Types.DecisionMakerErrorCodes | null, message?: string | null } | null> | null } | null };


export const UpdateSubListDocument = gql`
    mutation updateSubList($subList: SubListInput) {
  response: updateSubList(subList: $subList) {
    id
    errors {
      field
      code
      message
    }
  }
}
    `;
export type UpdateSubListMutationFn = Apollo.MutationFunction<UpdateSubListMutation, UpdateSubListMutationVariables>;

/**
 * __useUpdateSubListMutation__
 *
 * To run a mutation, you first call `useUpdateSubListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubListMutation, { data, loading, error }] = useUpdateSubListMutation({
 *   variables: {
 *      subList: // value for 'subList'
 *   },
 * });
 */
export function useUpdateSubListMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubListMutation, UpdateSubListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubListMutation, UpdateSubListMutationVariables>(UpdateSubListDocument, options);
      }
export type UpdateSubListMutationHookResult = ReturnType<typeof useUpdateSubListMutation>;
export type UpdateSubListMutationResult = Apollo.MutationResult<UpdateSubListMutation>;
export type UpdateSubListMutationOptions = Apollo.BaseMutationOptions<UpdateSubListMutation, UpdateSubListMutationVariables>;