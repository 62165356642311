import { lazyRetry } from "@health/common";
import { lazy } from "react";

export const VendorsFormEditPage = lazy(() => lazyRetry(() => import("./edit/VendorEdit.screen")));
export const VendorsList = lazy(() => lazyRetry(() => import("./home/HealthVendorsList.screen")));
export const VendorsFormNewPage = lazy(() => lazyRetry(() => import("./new/VendorAdd.screen")));
export const BranchesInfo = lazy(() => lazyRetry(() => import("../Branches/Branches.screen")));
export const BranchForm = lazy(() => lazyRetry(() => import("../Branches/BranchesForm/BranchForm.component")));
export const NewBranchPage = lazy(() => lazyRetry(() => import("../Branches/BranchesForm/BranchNew/BranchesNewPage.component")));
export const EditBranchPage = lazy(() => lazyRetry(() => import("../Branches/BranchesForm/BranchEdit/BranchEditPage.component")));
