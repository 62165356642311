/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CompleteCheckoutMutationVariables = Types.Exact<{
  checkoutId: Types.Scalars['ID'];
}>;


export type CompleteCheckoutMutation = { __typename?: 'Mutation', checkoutComplete?: { __typename: 'CheckoutComplete', orders?: Array<{ __typename?: 'Order', id: string, number?: string | null, user?: { __typename?: 'User', id: string, checkout?: { __typename?: 'Checkout', id: string } | null } | null, shippingAddress?: { __typename: 'OrderAddress', id: string, streetAddress1?: string | null, streetAddress2?: string | null, postalCode: string, phone: string, city?: { __typename?: 'City', name: string, nameAr?: string | null } | null } | null } | null> | null, errors: Array<{ __typename: 'Error', field?: string | null, message?: string | null }>, checkoutErrors: Array<{ __typename: 'CheckoutError', field?: string | null, message?: string | null }> } | null };


export const CompleteCheckoutDocument = gql`
    mutation CompleteCheckout($checkoutId: ID!) {
  checkoutComplete(checkoutId: $checkoutId) {
    orders {
      id
      number
      user {
        id
        checkout {
          id
        }
      }
      shippingAddress {
        id
        streetAddress1
        streetAddress2
        postalCode
        phone
        city {
          name
          nameAr
        }
        __typename
      }
    }
    errors {
      field
      message
      __typename
    }
    checkoutErrors {
      field
      message
      __typename
    }
    __typename
  }
}
    `;
export type CompleteCheckoutMutationFn = Apollo.MutationFunction<CompleteCheckoutMutation, CompleteCheckoutMutationVariables>;

/**
 * __useCompleteCheckoutMutation__
 *
 * To run a mutation, you first call `useCompleteCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeCheckoutMutation, { data, loading, error }] = useCompleteCheckoutMutation({
 *   variables: {
 *      checkoutId: // value for 'checkoutId'
 *   },
 * });
 */
export function useCompleteCheckoutMutation(baseOptions?: Apollo.MutationHookOptions<CompleteCheckoutMutation, CompleteCheckoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteCheckoutMutation, CompleteCheckoutMutationVariables>(CompleteCheckoutDocument, options);
      }
export type CompleteCheckoutMutationHookResult = ReturnType<typeof useCompleteCheckoutMutation>;
export type CompleteCheckoutMutationResult = Apollo.MutationResult<CompleteCheckoutMutation>;
export type CompleteCheckoutMutationOptions = Apollo.BaseMutationOptions<CompleteCheckoutMutation, CompleteCheckoutMutationVariables>;