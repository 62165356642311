import { darkRed, greyVendor, makeStyles, orange, Theme, turquoise } from "@health/ui";

export const useStatusStyles = makeStyles()((theme: Theme) => ({
  text: {
    fontSize: 14,
    fontFamily: "Airbnb Cereal App Medium, Arab Kufi Medium",
    textTransform: "capitalize",
  },
  warning: {
    color: orange,
  },
  success: {
    color: theme.palette.success.main,
  },
  info: {
    color: greyVendor,
  },
  primary: {
    color: theme.palette.primary.dark,
  },
  error: {
    color: darkRed,
  },
  blue: {
    color: "#22D5CB",
  },
  preparing: {
    color: "#F1C502",
  },
  pending: {
    color: turquoise,
  },
}));
