/* eslint-disable max-statements */
/* eslint-disable max-lines */
import { UserContext } from "@health/common";
import { useTranslation } from "@health/i18n";
import { useFileUpload } from "@health/queries";
import {
  bg,
  Box,
  Button,
  CustomIcon,
  darkRed,
  Dialog,
  DialogActions,
  Grid,
  LinearProgress,
  Typography,
  useAddToast,
  white,
} from "@health/ui";
import UploadImage from "assets/svg/icons8_upload_to_cloud.svg";
import icons8_xls from "assets/svg/icons8_xls.svg";
import React, { FC, memo, useContext, useState } from "react";
import { useStyles } from "./uploadDialog.styles";
import { UploadDialogProps } from "./uploadDialog.types";
const FileExtNotSupportedText = "File extension is not supported,please upload only xlsx format";
const MaxFileSizeMessage = "Please import a file with less than {{maxSize}} mb";
const maxFileSizeBytes = 20971520;
const One_MB = 2 ** 20;

type FileValidationError = {
  type: "noFile" | "SizeTooBig" | "invalidExtension";
  message: string;
};
const UploadDialog: FC<UploadDialogProps> = props => {
  const {
    title,
    isOpen,
    subTitle,
    hasErrors,
    errorMessage,
    dialogLoading,
    templateDownloadLink,
    templateDownloadFileName = "Template.xlsx",
    handleClose,
    handleResetError,
    onFileUploadedSuccessfully: handleOnFileUploadedSuccessfully,
    uploadServiceUrl,
    isUploadSubmitting,
  } = props;
  const {
    classes: { container, backDropStyle, paperStyle, dialogContainerStyle, progressRoot, dragArea },
  } = useStyles();

  const { t } = useTranslation("Admin");
  let [file, setFile] = useState<File | undefined>();
  const [isDownloading, setIsDownloading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const { token } = useContext(UserContext);
  const { failed } = useAddToast();
  const [customError, setCustomError] = useState("");
  const [dragActive, setDragActive] = React.useState(false);
  const { fetchUploadFile, setFiles, files, handleDragDropEvent } = useFileUpload();
  const handleUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event?.target?.files?.[0];
    handleFileSelected(selectedFile!, event);
  };

  file = files[0];
  const handleFileSelected = (selectedFile: File, e: React.ChangeEvent<HTMLInputElement>) => {
    if (!selectedFile) return;
    const errors = getFileValidationErrors(selectedFile);
    if (!isFileValid(errors)) {
      setCustomError(errors[0].message);
      return;
    }
    setCustomError("");
    setFiles(e as any, "a");
  };
  const isFileValid = (fileErrors: FileValidationError[]): boolean => {
    return fileErrors.length == 0;
  };

  const getFileValidationErrors = (file: File): FileValidationError[] => {
    const issues: FileValidationError[] = [];
    if (file?.size > maxFileSizeBytes) {
      issues.push({
        type: "SizeTooBig",
        message: t(MaxFileSizeMessage, { maxSize: Math.floor(maxFileSizeBytes / One_MB) }),
      });
    }
    const fileName = file?.name;
    const rE = /(\.xlsx)$/i;
    if (!rE.exec(fileName)) {
      issues.push({
        type: "invalidExtension",
        message: t(FileExtNotSupportedText),
      });
    }

    return issues;
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    handleDragDropEvent(e);
    const selectedFile = e.dataTransfer.files?.[0]!;
    handleFileSelected(selectedFile, e);
  };

  const handleCancelClick = () => {
    handleResetError?.();
    setFile(undefined);
    setCustomError("");
  };

  const handleCloseClick = () => {
    setFile(undefined);
    handleClose();
    setCustomError("");
  };

  const handleUploadClick = () => {
    setIsUploading(true);
    fetchUploadFile({
      uploadServiceUrl: uploadServiceUrl,
      onComplete: fileName => {
        handleOnFileUploadedSuccessfully(fileName);
        setIsUploading(false);
      },
      onError: error => {
        failed(t(error));
      },
    });
  };

  const handleDownloadTemplate = () => {
    setIsDownloading(true);
    fetch(templateDownloadLink!, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", templateDownloadFileName);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        setIsDownloading(false);
      })
      .catch(error => {
        // Handle errors here
        console.error("Error fetching template:", error);
        setIsDownloading(false);
      });
  };

  return (
    <Dialog
      BackdropProps={{
        className: backDropStyle,
      }}
      classes={{
        container,
      }}
      PaperProps={{
        className: paperStyle,
        sx: {
          width: "40%",
        },
      }}
      open={isOpen}
      onClose={handleCloseClick}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <Box flex={1} padding={1} paddingLeft={3} paddingRight={3}>
        <Typography fontSize={24} textAlign='center'>
          {title ? title : "No title"}
        </Typography>
        <Typography fontSize={16} textAlign='center' color={"#5B7798"}>
          {subTitle ? subTitle : "No subtitle"}
        </Typography>

        <Box
          onDragEnter={handleDrag}
          sx={{ backgroundColor: dragActive ? bg : white }}
          border={"1px #5B7798 dashed"}
          borderRadius={4}
          height={240}
          marginTop={2}
          display='flex'
          flexDirection={"column"}
          alignItems={"center"}
          padding={2}
        >
          <img src={UploadImage} width={120} height={90} />
          <Typography fontSize={16} textAlign='center' color={"#5B7798"} marginTop={1} marginBottom={1}>
            {t("Drag & Drop Your Files Here")}
          </Typography>
          <Typography fontSize={16} textAlign='center' marginBottom={1}>
            {t("OR")}
          </Typography>
          <Button variant='contained' component='label'>
            {t("Browse File")}
            <input type='file' accept='.xlsx' hidden onChange={handleUploadFile} />
          </Button>
          {dragActive && (
            <div className={dragArea} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>
          )}
        </Box>
        {!!file && (
          <Grid container spacing={1} marginTop={2}>
            <Grid item>
              <img src={icons8_xls} width={23} height={29} />
            </Grid>
            <Grid item flex={1}>
              <Typography flex={1} fontSize={14} paddingBottom={0.5}>
                {t(file.name)}
              </Typography>
              {dialogLoading && (
                <LinearProgress
                  color='success'
                  classes={{
                    root: progressRoot,
                  }}
                  value={0}
                />
              )}
            </Grid>
            {!dialogLoading && (
              <Grid item>
                <Grid onClick={handleCancelClick}>
                  <CustomIcon icon='icons8_cancel' color='#D8E5F2' />
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Box>
      {customError && (
        <Box marginBottom='20px'>
          <Typography
            sx={{
              fontSize: 16,
              textAlign: "center",
              color: darkRed,
            }}
          >
            {t("Error")}: {customError}
          </Typography>
        </Box>
      )}
      {hasErrors && (
        <Box marginBottom='20px'>
          <Typography
            sx={{
              fontSize: 16,
              textAlign: "center",
              color: darkRed,
            }}
          >
            {t("Error")}: {t(errorMessage || "")}
          </Typography>
        </Box>
      )}

      <DialogActions className={dialogContainerStyle}>
        {templateDownloadLink && (
          <Button
            variant='outlined'
            onClick={handleDownloadTemplate}
            sx={{
              marginRight: "auto",
            }}
            disabled={isDownloading}
          >
            {isDownloading ? t("Downloading") : t("Download Template")}
          </Button>
        )}
        <Button disabled={!file || dialogLoading || isUploadSubmitting || isUploading} onClick={handleUploadClick}>
          {t("Submit File")}
        </Button>
        <Button variant='outlined' onClick={handleCloseClick}>
          {t("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(UploadDialog);
