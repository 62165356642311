/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { UserErrorFragmentFragmentDoc } from '../../../../fragment/User/__generated__/UserErrorFragment';
import { UserFragmentFragmentDoc } from '../../../../fragment/User/__generated__/User';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VendorUserCreateMutationVariables = Types.Exact<{
  input: Types.VendorUserCreateInput;
}>;


export type VendorUserCreateMutation = { __typename?: 'Mutation', vendorUserCreate?: { __typename?: 'VendorUserCreate', accountErrors: Array<{ __typename?: 'AccountError', field?: string | null, message?: string | null, code: Types.AccountErrorCode }>, user?: { __typename?: 'User', id: string, email: string, firstName?: string | null, lastName?: string | null, mobile?: string | null, isStaff?: boolean | null, isSuperuser?: boolean | null, isVendorAdmin?: boolean | null, note?: string | null, healthLicenseNumber?: string | null, healthLicenseStartDate?: any | null, healthLicenseEndDate?: any | null, appType?: Types.AppTypes | null, appRole?: Types.AppRoleTypes | null, isActive: boolean, vendor?: { __typename?: 'Vendor', id: string, name: string } | null, branches?: Array<{ __typename?: 'Branch', id: string, name: string } | null> | null, permissionGroups?: Array<{ __typename?: 'Group', id: string, name: string, permissions?: Array<{ __typename?: 'Permission', name: string, code: Types.PermissionEnum } | null> | null } | null> | null } | null } | null };


export const VendorUserCreateDocument = gql`
    mutation vendorUserCreate($input: VendorUserCreateInput!) {
  vendorUserCreate(input: $input) {
    accountErrors {
      ...UserErrorFragment
    }
    user {
      ...UserFragment
    }
  }
}
    ${UserErrorFragmentFragmentDoc}
${UserFragmentFragmentDoc}`;
export type VendorUserCreateMutationFn = Apollo.MutationFunction<VendorUserCreateMutation, VendorUserCreateMutationVariables>;

/**
 * __useVendorUserCreateMutation__
 *
 * To run a mutation, you first call `useVendorUserCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVendorUserCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vendorUserCreateMutation, { data, loading, error }] = useVendorUserCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVendorUserCreateMutation(baseOptions?: Apollo.MutationHookOptions<VendorUserCreateMutation, VendorUserCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VendorUserCreateMutation, VendorUserCreateMutationVariables>(VendorUserCreateDocument, options);
      }
export type VendorUserCreateMutationHookResult = ReturnType<typeof useVendorUserCreateMutation>;
export type VendorUserCreateMutationResult = Apollo.MutationResult<VendorUserCreateMutation>;
export type VendorUserCreateMutationOptions = Apollo.BaseMutationOptions<VendorUserCreateMutation, VendorUserCreateMutationVariables>;