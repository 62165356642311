/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../fragment/PageInfo/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminDropDownProductsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.ProductFilterInput>;
}>;


export type AdminDropDownProductsQuery = { __typename?: 'Query', products?: { __typename?: 'ProductCountableConnection', pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'ProductCountableEdge', node: { __typename?: 'Product', id: string, name?: string | null } }> } | null };


export const AdminDropDownProductsDocument = gql`
    query AdminDropDownProducts($first: Int, $last: Int, $after: String, $before: String, $filter: ProductFilterInput) {
  products(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        id
        name
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useAdminDropDownProductsQuery__
 *
 * To run a query within a React component, call `useAdminDropDownProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminDropDownProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminDropDownProductsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminDropDownProductsQuery(baseOptions?: Apollo.QueryHookOptions<AdminDropDownProductsQuery, AdminDropDownProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminDropDownProductsQuery, AdminDropDownProductsQueryVariables>(AdminDropDownProductsDocument, options);
      }
export function useAdminDropDownProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminDropDownProductsQuery, AdminDropDownProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminDropDownProductsQuery, AdminDropDownProductsQueryVariables>(AdminDropDownProductsDocument, options);
        }
export type AdminDropDownProductsQueryHookResult = ReturnType<typeof useAdminDropDownProductsQuery>;
export type AdminDropDownProductsLazyQueryHookResult = ReturnType<typeof useAdminDropDownProductsLazyQuery>;
export type AdminDropDownProductsQueryResult = Apollo.QueryResult<AdminDropDownProductsQuery, AdminDropDownProductsQueryVariables>;