/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { SiteSettingsFragmentFragmentDoc } from '../../../../fragment/SiteSettings/__generated__/SiteSettingsFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSiteSettingsMutationVariables = Types.Exact<{
  input: Types.SiteSettingsInput;
}>;


export type UpdateSiteSettingsMutation = { __typename?: 'Mutation', siteSettingsUpdate?: { __typename?: 'SiteSettingsUpdate', errors: Array<{ __typename?: 'SiteSettingsError', field?: string | null, code: Types.SiteSettingsErrorCode, message?: string | null }>, siteSettings?: { __typename?: 'SiteSettings', id: string, vatPercentage?: number | null, enablePickup?: boolean | null, defaultCurrency: string, orderDeliveryTimeout?: number | null, orderBaseDeliveryFee?: number | null, orderAcceptanceTimeout?: number | null, orderDispensingTimeout?: number | null, orderErxHubApprovalTimeout?: number | null, multiplePharmaciesDelivery?: boolean | null, orderErxHubApprovalResubmitTimeout?: number | null, orderPaymentTimeout?: number | null, deliveryNumberOfReminders?: number | null, deliveryMinutesTimeGapBeforeEachReminder?: number | null, marketPlaceEnabled?: boolean | null, scheduleDeliveryEnabled?: boolean | null } | null } | null };


export const UpdateSiteSettingsDocument = gql`
    mutation updateSiteSettings($input: SiteSettingsInput!) {
  siteSettingsUpdate(input: $input) {
    errors: siteSettingsErrors {
      field
      code
      message
    }
    siteSettings {
      ...SiteSettingsFragment
    }
  }
}
    ${SiteSettingsFragmentFragmentDoc}`;
export type UpdateSiteSettingsMutationFn = Apollo.MutationFunction<UpdateSiteSettingsMutation, UpdateSiteSettingsMutationVariables>;

/**
 * __useUpdateSiteSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateSiteSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteSettingsMutation, { data, loading, error }] = useUpdateSiteSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSiteSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSiteSettingsMutation, UpdateSiteSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSiteSettingsMutation, UpdateSiteSettingsMutationVariables>(UpdateSiteSettingsDocument, options);
      }
export type UpdateSiteSettingsMutationHookResult = ReturnType<typeof useUpdateSiteSettingsMutation>;
export type UpdateSiteSettingsMutationResult = Apollo.MutationResult<UpdateSiteSettingsMutation>;
export type UpdateSiteSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateSiteSettingsMutation, UpdateSiteSettingsMutationVariables>;