import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import React, { FC, useEffect } from "react";
import { FilterField } from "../..";
import { FieldsTypes, IFilterProps } from "./Filter.types";
import FilterItemInput from "./FilterItemsInput.component";
import { useFiltersStyles } from "./Filters.styles";

const Filter: FC<IFilterProps> = props => {
  const { children, onChangeText, fields, register, control, onFieldDelete } = props;

  const { classes } = useFiltersStyles();
  const [filteredFields, setFilteredFields] = React.useState(fields);

  useEffect(() => {
    fields && !!fields.length && setFilteredFields(fields);
  }, [fields]);

  const handleInnerFieldDelete = (field: any) => () => {
    const filtered = filteredFields?.filter(item => item?.id != field?.id)?.slice(0) || [];
    setFilteredFields(filtered);
    onFieldDelete?.(field);
  };

  const handleTextChange = (field: FilterField) => e => {
    if (field.type === "autocomplete" || field.type === FieldsTypes.autocomplete) {
      const getValue = field.fieldProps.getValueFromSelectedOptionLabel || (() => e.target.value);
      control.setValue(field.id, getValue(e.target.value));
      onChangeText?.(field.id, getValue(e.target.value));
      return;
    }
    onChangeText?.(field.id, e.target.value);
  };
  return (
    <div className={classes.root}>
      {children && (
        <Grid item xs={12}>
          {children}
        </Grid>
      )}

      {filteredFields && (
        <Grid container spacing={2}>
          {filteredFields?.map(field => {
            return (
              <Grid container alignItems={"center"} key={field?.id} item xs={field?.grid || 12} sm={6} spacing={1}>
                <Grid flex={1} item>
                  <FilterItemInput props={{ ...field, onChange: handleTextChange(field) }} control={control} register={register} />
                </Grid>
                {onFieldDelete && (
                  <Grid item onClick={handleInnerFieldDelete(field)}>
                    <IconButton>
                      <CloseIcon sx={{ width: "20px", height: "20px" }} />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            );
          })}
        </Grid>
      )}
    </div>
  );
};

export default Filter;
