import { createStyles } from "@mui/styles";
import { primary } from "../../customization/colors";

export const styles = () =>
  createStyles({
    div: {
      display: "flex",
      background: primary,
      color: "#fff",
      justifyContent: "space-between",
      padding: 12,
      alignItems: "center",
    },
    header: {
      marginTop: "10px",
      marginBottom: "-10px",
    },
    dialog: {
      backgroundColor: "transparent",
    },
    icon: {
      background: "#fff",
      "&:hover": {
        background: "#fff",
      },
    },
    button: {
      backgroundColor: "#B41B1B",
      "&:hover": {
        backgroundColor: "#B41B1B",
      },
    },
    cancel: {
      backgroundColor: "#B1BDD1",
      padding: "8px",
      "&:hover": {
        backgroundColor: "#B1BDD1",
      },
    },
  });
