import { CustomTableColumnProps } from "../Table/types";

export const toggleAllColumnsVisibility = <T = unknown>(columns: CustomTableColumnProps<T>[], isHidden: boolean) => {
  return columns.map(col => (col.disableToggleVisibility ? col : { ...col, isHidden }));
};
export const toggleColumnVisibility = <T = unknown>(columns: CustomTableColumnProps<T>[], updatedColumn: CustomTableColumnProps) => {
  return columns.map(column => (column.key === updatedColumn.key ? { ...column, isHidden: !updatedColumn.isHidden } : column));
};

export default {
  toggleAllColumnsVisibility,
  toggleColumnVisibility,
};
