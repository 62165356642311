import { getRequiredValidation } from "@health/common";
import { useTranslation } from "@health/i18n";
import { Grid, TextField } from "@health/ui";
import { useFormContext } from "react-hook-form";
import { formGirdBreakPoints, formGirdSpacing } from "shared/constants";

const InventoryCard = ({ defaultProductData }: any) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation("Admin");
  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item {...formGirdBreakPoints}>
        <TextField
          fullWidth
          placeholder={t("SKU")}
          label={t("Sku")}
          defaultValue={defaultProductData?.sku || defaultProductData?.variants[0]?.sku}
          error={Boolean(errors?.sku?.message)}
          helperText={t(errors?.sku?.message)}
          {...register("sku", {
            required: getRequiredValidation(t, true),
          })}
        />
      </Grid>
    </Grid>
  );
};
export default InventoryCard;
