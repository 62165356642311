import { Tooltip, Typography, TypographyProps } from "@mui/material";
import React, { FC, useRef } from "react";

export const TruncateTypography: FC<{ text: string; maxWidth?: number } & TypographyProps> = ({ text, maxWidth, sx, ...restOfProps }) => {
  const ref = useRef(null);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    const element = ref.current!;
    setOpen(element?.scrollWidth > element?.clientWidth);
  };

  return (
    <Tooltip title={text} open={open} onClose={handleClose} onOpen={handleOpen}>
      <Typography
        ref={ref}
        sx={{
          maxWidth: maxWidth,
          fontSize: 14,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          cursor: "pointer",
          ...sx,
        }}
        {...restOfProps}
      >
        {text}
      </Typography>
    </Tooltip>
  );
};

TruncateTypography.defaultProps = {
  maxWidth: 250,
};
