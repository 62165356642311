import { useNavigate } from "react-router-dom";

export const useUnknownPageHook = () => {
  const navigate = useNavigate();

  const handleHandleGoHome = () => {
    navigate("/");
  };
  const handleHandleLogIn = () => {
    navigate("/login");
  };

  return {
    handleHandleLogIn,
    handleHandleGoHome,
  };
};
