import { makeStyles } from "tss-react/mui";

export const useActionsBarStyles = makeStyles()({
  filters: {
    display: "flex",
    "@media(max-Width: 900px)": {
      padding: "5px 0px 5px 0px",
    },
  },
  filterItem: {
    padding: "0 10px",
    margin: "0px 10px 10px 0px",
  },
  itemStyle: {
    whiteSpace: "nowrap",
    maxWidth: 140,
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
  },
  button: {
    margin: "10px 5px",
  },
  direction: {
    position: "fixed",
    width: "100%",
    bottom: 0,
    height: 70,
    zIndex: 10,
  },
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "white",
    padding: "0 15px",
    flexWrap: "wrap-reverse",
    overflowX: "auto",
  },
});
