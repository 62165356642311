/* eslint-disable max-lines */
import { useTranslation } from "@health/i18n";
import {
  Box,
  Button,
  CustomDialog,
  CustomIcon,
  CustomInfiniteScroll,
  Divider,
  ExpandMoreIcon,
  Grid,
  primary,
  StyledButton,
  Typography,
  VersionsIcon,
} from "@health/ui";
import React, { FC, useState } from "react";
import ContentLoader from "react-content-loader";
import { VersionRow } from "../VersionRow";
import { VersionsListProps } from "./types";

export const VersionsList: FC<VersionsListProps> = props => {
  const {
    versionData,
    isPublishing,
    versionDataLoading,
    isFetchingMoreVersions,
    hasNextPageVersions,
    isRefetchingVersions,
    onVersionDownload: handleVersionDownload,
    onVersionOpenClick,
    onFetchMoreVersions: handleOnFetchMoreVersions,
    onChangeActiveVersions,
    onVersionDialogOpen: handleVersionDialogOpen,
  } = props;

  const { t } = useTranslation("Admin");
  const [isVersionsOpened, setIsVersionsOpened] = useState(false);
  const handleChangeActiveVersion = props => {
    onChangeActiveVersions?.(props);
    setIsVersionsOpened(false);
  };

  const handleToggleVersions = () => {
    setIsVersionsOpened(prev => {
      if (!prev) {
        handleVersionDialogOpen && handleVersionDialogOpen();
      }
      return !prev;
    });
  };

  const handleVersionOpenClick = (id: string) => {
    setIsVersionsOpened(false);
    onVersionOpenClick && onVersionOpenClick(id);
  };
  const latestApprovedVersionNumber = versionData?.find(item => item?.processingStatus === "APPROVED")?.version;
  return (
    <CustomDialog
      maxWidth='lg'
      title={t("Versions")}
      open={isVersionsOpened}
      onCloseModal={handleToggleVersions}
      button={
        <StyledButton
          variant='outlined'
          color='primary'
          size='medium'
          startIcon={<VersionsIcon color='inherit' />}
          onClick={handleToggleVersions}
          sx={{
            border: "1px solid #1B2346",
          }}
        >
          {t("Versions")}
        </StyledButton>
      }
    >
      {versionDataLoading ? (
        <Box>
          {new Array(3).fill(0).map((el, key) => (
            <Box key={key}>
              <ContentLoader speed={2} width={900} viewBox='0 0 400 160' backgroundColor='rgb(230,230,230)' foregroundColor='lightgray'>
                <rect x='20' y='0' rx='0' ry='0' width='850' height='15' />
              </ContentLoader>
            </Box>
          ))}
        </Box>
      ) : (
        <CustomInfiniteScroll
          dataLength={versionData?.length || 0}
          isFetchingMore={isFetchingMoreVersions}
          onRefresh={handleOnFetchMoreVersions}
        >
          {!isRefetchingVersions && versionData?.length ? (
            <>
              <Grid container spacing={2} marginBottom='8px'>
                <Grid item flex={1} container direction={"column"} alignItems={"center"}></Grid>
                <Grid item flex={1} container direction={"column"} alignItems={"center"}>
                  <Grid item marginRight={1} display='flex' marginLeft={"16px"}>
                    <CustomIcon icon='personalization' />
                    <Typography color='primary' fontWeight={"bold"} fontSize={14}>
                      {t("Status")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item flex={1} container direction={"column"} alignItems={"center"} paddingLeft={"16px"}>
                  <Grid item marginRight={1} display='flex'>
                    <CustomIcon icon='icons8_download' />
                    <Typography color='primary' fontWeight={"bold"} fontSize={14}>
                      {t("Creation date")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item flex={1} container direction={"column"} alignItems={"center"}>
                  <Grid item marginRight={1} display='flex'>
                    <CustomIcon icon='icons8_restart' />{" "}
                    <Typography color='primary' fontWeight={"bold"} fontSize={14}>
                      {t("Last update")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item flex={1} container direction={"column"} alignItems={"center"}></Grid>
                <Grid item flex={1} container direction={"column"} alignItems={"center"}></Grid>
                <Grid item flex={1} container direction={"column"} alignItems={"center"}></Grid>
              </Grid>
              <Divider sx={{ marginBottom: "16px", borderBottomWidth: "medium", borderColor: primary }} />
              {versionData.map(version => (
                <VersionRow
                  key={version?.id}
                  isLoading={isPublishing}
                  version={version}
                  onVersionOpenClick={handleVersionOpenClick}
                  onDownload={handleVersionDownload}
                  onVersionActiveChange={handleChangeActiveVersion}
                  latestApprovedVersionNumber={latestApprovedVersionNumber}
                />
              ))}
              {hasNextPageVersions && (
                <Box display='flex' justifyContent='center'>
                  <Button disabled={!hasNextPageVersions} variant='outlined' onClick={handleOnFetchMoreVersions}>
                    {t("More versions")}
                    <ExpandMoreIcon />
                  </Button>
                </Box>
              )}
            </>
          ) : (
            <Typography variant='h6' color='primary'>
              {t("No versions has been added or created for this Medlist ")}
            </Typography>
          )}
        </CustomInfiniteScroll>
      )}
    </CustomDialog>
  );
};
