import { useTranslation } from "@health/i18n";
import { useProviderUsersQuery, User } from "@health/queries";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { staffUsersColumns } from "../UsersManagement/Users/list/usersColumns";

export const useProviderUsersHook = () => {
  const { t } = useTranslation("Admin");
  const params = useParams();
  const [columns] = useState(staffUsersColumns(t));

  const { data, loading } = useProviderUsersQuery({
    variables: {
      id: String(params.id),
    },
  });
  const vendor = data?.vendor;

  const users = vendor?.users?.map(user => user) as User[];

  const handleSortColumn = () => {};
  return {
    loading,
    columns,
    users,
    vendor,
    handleSortColumn,
  };
};
