import { makeStyles } from "@health/ui";

export const useSuccessStyle = makeStyles()(() => ({
  text: {
    color: "#46A200",
    fontSize: 18,
  },
  button: {
    width: 322,
    height: 50,
    margin: 0,
    marginTop: 10,
    fontSize: 14,
  },
}));
