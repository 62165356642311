/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../fragment/PageInfo/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrdersLifeCycleQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.OrderFilterInput>;
}>;


export type OrdersLifeCycleQuery = { __typename?: 'Query', orders?: { __typename?: 'OrderCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'OrderCountableEdge', node: { __typename?: 'Order', id: string, number?: string | null, status: Types.OrderStatus, failReason?: string | null, helpDiskTicketId?: string | null, total?: { __typename?: 'TaxedMoney', gross: { __typename?: 'Money', amount: number, currency: string } } | null, branch?: { __typename?: 'Branch', name: string, nameAr?: string | null } | null, events?: Array<{ __typename?: 'OrderEvent', id: string, date?: any | null, type?: Types.OrderEventsEnum | null, orderNumber?: string | null } | null> | null, children?: Array<{ __typename?: 'Order', id: string, number?: string | null, status: Types.OrderStatus, type?: Types.OrderTypesEnum | null, created: any, branch?: { __typename?: 'Branch', name: string, nameAr?: string | null } | null, events?: Array<{ __typename?: 'OrderEvent', id: string, date?: any | null, type?: Types.OrderEventsEnum | null, orderNumber?: string | null } | null> | null } | null> | null } }> } | null };


export const OrdersLifeCycleDocument = gql`
    query ordersLifeCycle($first: Int, $after: String, $filter: OrderFilterInput) {
  orders(first: $first, after: $after, filter: $filter) {
    totalCount
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        id
        number
        status
        failReason
        helpDiskTicketId
        total {
          gross {
            amount
            currency
          }
        }
        branch {
          name
          nameAr
        }
        events {
          id
          date
          type
          orderNumber
        }
        children {
          id
          branch {
            name
            nameAr
          }
          number
          status
          type
          created
          events {
            id
            date
            type
            orderNumber
          }
        }
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useOrdersLifeCycleQuery__
 *
 * To run a query within a React component, call `useOrdersLifeCycleQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersLifeCycleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersLifeCycleQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrdersLifeCycleQuery(baseOptions?: Apollo.QueryHookOptions<OrdersLifeCycleQuery, OrdersLifeCycleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersLifeCycleQuery, OrdersLifeCycleQueryVariables>(OrdersLifeCycleDocument, options);
      }
export function useOrdersLifeCycleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersLifeCycleQuery, OrdersLifeCycleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersLifeCycleQuery, OrdersLifeCycleQueryVariables>(OrdersLifeCycleDocument, options);
        }
export type OrdersLifeCycleQueryHookResult = ReturnType<typeof useOrdersLifeCycleQuery>;
export type OrdersLifeCycleLazyQueryHookResult = ReturnType<typeof useOrdersLifeCycleLazyQuery>;
export type OrdersLifeCycleQueryResult = Apollo.QueryResult<OrdersLifeCycleQuery, OrdersLifeCycleQueryVariables>;