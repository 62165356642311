/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { PermissionGroupFragmentFragmentDoc } from '../../../../fragment/Group/__generated__/permissionGroupFragment';
import { PermissionGroupErrorsFragmentFragmentDoc } from '../../../../fragment/PermissionGroupError/__generated__/permissionGroupError';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PermissionGroupDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type PermissionGroupDeleteMutation = { __typename?: 'Mutation', permissionGroupDelete?: { __typename?: 'PermissionGroupDelete', group?: { __typename?: 'Group', id: string, name: string, users?: Array<{ __typename?: 'User', id: string, email: string, firstName?: string | null, lastName?: string | null } | null> | null, permissions?: Array<{ __typename?: 'Permission', code: Types.PermissionEnum, name: string } | null> | null } | null, permissionGroupErrors: Array<{ __typename?: 'PermissionGroupError', field?: string | null, message?: string | null, code: Types.PermissionGroupErrorCode }> } | null };


export const PermissionGroupDeleteDocument = gql`
    mutation permissionGroupDelete($id: ID!) {
  permissionGroupDelete(id: $id) {
    group {
      ...PermissionGroupFragment
    }
    permissionGroupErrors {
      ...PermissionGroupErrorsFragment
    }
  }
}
    ${PermissionGroupFragmentFragmentDoc}
${PermissionGroupErrorsFragmentFragmentDoc}`;
export type PermissionGroupDeleteMutationFn = Apollo.MutationFunction<PermissionGroupDeleteMutation, PermissionGroupDeleteMutationVariables>;

/**
 * __usePermissionGroupDeleteMutation__
 *
 * To run a mutation, you first call `usePermissionGroupDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePermissionGroupDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [permissionGroupDeleteMutation, { data, loading, error }] = usePermissionGroupDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePermissionGroupDeleteMutation(baseOptions?: Apollo.MutationHookOptions<PermissionGroupDeleteMutation, PermissionGroupDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PermissionGroupDeleteMutation, PermissionGroupDeleteMutationVariables>(PermissionGroupDeleteDocument, options);
      }
export type PermissionGroupDeleteMutationHookResult = ReturnType<typeof usePermissionGroupDeleteMutation>;
export type PermissionGroupDeleteMutationResult = Apollo.MutationResult<PermissionGroupDeleteMutation>;
export type PermissionGroupDeleteMutationOptions = Apollo.BaseMutationOptions<PermissionGroupDeleteMutation, PermissionGroupDeleteMutationVariables>;