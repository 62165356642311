/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type LanguageDisplayFragment = { __typename?: 'LanguageDisplay', code: Types.LanguageCodeEnum, language: string };

export const LanguageDisplayFragmentDoc = gql`
    fragment languageDisplay on LanguageDisplay {
  code
  language
}
    `;