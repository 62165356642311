/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { AddressFragmentFragmentDoc } from '../../../../fragment/address/__generated__/address';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TokenCreateMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  password: Types.Scalars['String'];
  app: Types.AppTypes;
}>;


export type TokenCreateMutation = { __typename?: 'Mutation', tokenCreate?: { __typename?: 'SSOCreateToken', token?: string | null, accountErrors: Array<{ __typename?: 'AccountError', field?: string | null, message?: string | null, code: Types.AccountErrorCode }>, branches?: Array<{ __typename?: 'Branch', id: string, name: string, nameAr?: string | null, isActive: boolean } | null> | null, user?: { __typename?: 'User', appRole?: Types.AppRoleTypes | null, appType?: Types.AppTypes | null, id: string, lastLogin?: any | null, email: string, mobile?: string | null, firstName?: string | null, lastName?: string | null, userPermissions?: Array<{ __typename?: 'UserPermission', code: Types.PermissionEnum, name: string } | null> | null, vendor?: { __typename?: 'Vendor', id: string, isVip: boolean, type?: Types.VendorType | null, hasMultipleBranches: boolean } | null, patient?: { __typename?: 'Patient', id: string, firstName?: string | null, lastName?: string | null, memberId?: string | null } | null, addresses?: Array<{ __typename: 'Address', id: string, name?: string | null, area?: string | null, streetAddress1?: string | null, streetAddress2?: string | null, buildingName?: string | null, buildingNumber?: string | null, phone?: string | null, isDefaultShippingAddress?: boolean | null, region?: string | null, city: { __typename?: 'City', id: string, name: string, nameAr?: string | null }, coordinates?: { __typename?: 'LocationType', lng?: number | null, lat?: number | null } | null, translations?: Array<{ __typename?: 'AddressTranslation', streetAddress1: string, streetAddress2: string, area: string, language: { __typename?: 'LanguageDisplay', code: Types.LanguageCodeEnum, language: string } } | null> | null } | null> | null } | null } | null };


export const TokenCreateDocument = gql`
    mutation tokenCreate($email: String!, $password: String!, $app: AppTypes!) {
  tokenCreate(username: $email, password: $password, app: $app) {
    accountErrors {
      field
      message
      code
    }
    token
    branches {
      id
      name
      nameAr
      isActive
    }
    user {
      appRole
      appType
      id
      lastLogin
      email
      mobile
      firstName
      lastName
      userPermissions {
        code
        name
      }
      vendor {
        id
        isVip
        type
        hasMultipleBranches
      }
      patient {
        id
        firstName
        lastName
        memberId
      }
      addresses {
        ...AddressFragment
      }
    }
  }
}
    ${AddressFragmentFragmentDoc}`;
export type TokenCreateMutationFn = Apollo.MutationFunction<TokenCreateMutation, TokenCreateMutationVariables>;

/**
 * __useTokenCreateMutation__
 *
 * To run a mutation, you first call `useTokenCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTokenCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tokenCreateMutation, { data, loading, error }] = useTokenCreateMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      app: // value for 'app'
 *   },
 * });
 */
export function useTokenCreateMutation(baseOptions?: Apollo.MutationHookOptions<TokenCreateMutation, TokenCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TokenCreateMutation, TokenCreateMutationVariables>(TokenCreateDocument, options);
      }
export type TokenCreateMutationHookResult = ReturnType<typeof useTokenCreateMutation>;
export type TokenCreateMutationResult = Apollo.MutationResult<TokenCreateMutation>;
export type TokenCreateMutationOptions = Apollo.BaseMutationOptions<TokenCreateMutation, TokenCreateMutationVariables>;