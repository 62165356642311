import { useTranslation } from "@health/i18n";
import {
  formatMessageErrors,
  ProductVariantDocument,
  useProductQuery,
  useProductVariantQuery,
  useProductVariantUpdateMutation,
} from "@health/queries";
import { useToasts } from "@health/ui";
import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import { VariantFormData, VariantFormSubmitData } from "../components/variants/components/Form/type";
import VariantForm from "../components/variants/components/Form/VariantForm.component";

const VariantsFormEditPage = () => {
  const { t } = useTranslation("Admin");
  const { addToast } = useToasts();

  const params = useParams();
  const navigate = useNavigate();
  const idVariant = params?.vid as string;
  const [productVariantUpdate, { loading: isUpdateLoading }] = useProductVariantUpdateMutation({
    onCompleted: data => {
      const errors = data?.productVariantUpdate?.productErrors;
      if (errors && errors?.length > 0) {
        const error = formatMessageErrors(errors);
        addToast(t(error), {
          appearance: "error",
          autoDismiss: true,
        });
        return;
      }
      addToast(t("Product Variant Updated successfully"), {
        appearance: "success",
        autoDismiss: true,
      });
      navigate(-1);
    },
    refetchQueries: [
      {
        query: ProductVariantDocument,
        variables: {
          id: idVariant,
        },
      },
    ],
  });

  const { data: productVariantData, loading: LoadingVariant } = useProductVariantQuery({
    variables: {
      id: idVariant,
    },
  });
  const handleSubmit = (data: VariantFormSubmitData) => {
    productVariantUpdate({
      variables: {
        id: idVariant,
        input: {
          sku: data?.sku,
          attributes: data?.attributes.map(t => ({ id: t.attributeId, values: [t.value].flatMap(v => v?.name!) })) || [],
          trackInventory: data?.trackInventory,
        },
      },
    });
  };
  const idProduct = productVariantData?.productVariant?.product?.id;
  const { data } = useProductQuery({
    variables: {
      id: idProduct as string,
    },
    skip: !idProduct,
  });
  const defaultData: VariantFormData | undefined = useMemo(() => {
    if (!productVariantData?.productVariant) return undefined;
    return {
      sku: productVariantData?.productVariant?.sku!,
      trackInventory: !!productVariantData?.productVariant?.trackInventory,
      variants: productVariantData?.productVariant?.attributes?.map(t => ({
        attributeId: t.attribute?.id!,
        name: t.attribute?.name!,
        values: t.attribute?.values!,
        value: t.values?.[0]!,
      })) as VariantFormData["variants"],
    };
  }, [productVariantData?.productVariant]);
  return (
    <VariantForm
      defaultProductData={data?.product!}
      defaultValue={defaultData}
      productVariant={productVariantData}
      loading={LoadingVariant}
      isEdit={true}
      onSubmitData={handleSubmit}
      isSubmitDisabled={isUpdateLoading}
    />
  );
};
export default VariantsFormEditPage;
