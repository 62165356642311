/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { LanguageDisplayFragmentDoc } from '../../../../fragment/languageDisplay/__generated__/languageDisplay';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BranchProfileForMarketPlaceQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type BranchProfileForMarketPlaceQuery = { __typename?: 'Query', branch?: { __typename?: 'Branch', id: string, name: string, nameAr?: string | null, contactNumber?: string | null, vendor: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null, description?: string | null, logo?: string | null, backGroundImage?: string | null, slug: string, averageRating: number, ratingsSum: number }, address?: { __typename?: 'Address', area?: string | null, streetAddress1?: string | null, streetAddress2?: string | null, buildingNumber?: string | null, city: { __typename?: 'City', id: string, name: string, nameAr?: string | null }, coordinates?: { __typename?: 'LocationType', lng?: number | null, lat?: number | null } | null, translations?: Array<{ __typename?: 'AddressTranslation', area: string, streetAddress1: string, streetAddress2: string, language: { __typename?: 'LanguageDisplay', code: Types.LanguageCodeEnum, language: string } } | null> | null } | null, workingHours?: Array<{ __typename?: 'WorkingHour', day?: Types.DayOfWeekEnum | null, openTimeRanges?: Array<{ __typename?: 'TimeRange', closeTime?: any | null, openTime?: any | null } | null> | null } | null> | null } | null };


export const BranchProfileForMarketPlaceDocument = gql`
    query branchProfileForMarketPlace($id: ID!) {
  branch(id: $id) {
    vendor {
      id
      name
      nameAr
      description
      logo
      backGroundImage
      slug
      averageRating
      ratingsSum
    }
    id
    name
    nameAr
    contactNumber
    address {
      area
      streetAddress1
      streetAddress2
      buildingNumber
      city {
        id
        name
        nameAr
      }
      coordinates {
        lng
        lat
      }
      translations {
        area
        streetAddress1
        streetAddress2
        language {
          ...languageDisplay
        }
      }
    }
    workingHours {
      day
      openTimeRanges {
        closeTime
        openTime
      }
    }
  }
}
    ${LanguageDisplayFragmentDoc}`;

/**
 * __useBranchProfileForMarketPlaceQuery__
 *
 * To run a query within a React component, call `useBranchProfileForMarketPlaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchProfileForMarketPlaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchProfileForMarketPlaceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBranchProfileForMarketPlaceQuery(baseOptions: Apollo.QueryHookOptions<BranchProfileForMarketPlaceQuery, BranchProfileForMarketPlaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BranchProfileForMarketPlaceQuery, BranchProfileForMarketPlaceQueryVariables>(BranchProfileForMarketPlaceDocument, options);
      }
export function useBranchProfileForMarketPlaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BranchProfileForMarketPlaceQuery, BranchProfileForMarketPlaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BranchProfileForMarketPlaceQuery, BranchProfileForMarketPlaceQueryVariables>(BranchProfileForMarketPlaceDocument, options);
        }
export type BranchProfileForMarketPlaceQueryHookResult = ReturnType<typeof useBranchProfileForMarketPlaceQuery>;
export type BranchProfileForMarketPlaceLazyQueryHookResult = ReturnType<typeof useBranchProfileForMarketPlaceLazyQuery>;
export type BranchProfileForMarketPlaceQueryResult = Apollo.QueryResult<BranchProfileForMarketPlaceQuery, BranchProfileForMarketPlaceQueryVariables>;