/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type BranchErrorsFragmentFragment = { __typename?: 'BranchError', field?: string | null, message?: string | null, code: Types.VendorErrorCode };

export const BranchErrorsFragmentFragmentDoc = gql`
    fragment BranchErrorsFragment on BranchError {
  field
  message
  code
}
    `;