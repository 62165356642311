import { useTranslation } from "@health/i18n";
import { Branch, useBranchesDropDownOptionsQuery } from "@health/queries";
import { AutocompleteController, getTranslatedNameField, useAutocompleteCustomHook } from "@health/ui";
import React, { FC, useEffect } from "react";
import { UseFormReturn } from "react-hook-form";

export const BranchesAutocomplete: FC<{ control: UseFormReturn["control"]; branchValue: any; errors; name: string; vendorsValue? }> = ({
  branchValue,
  errors,
  control: passedControl,
  name,
  vendorsValue,
}) => {
  const { t } = useTranslation();

  const {
    data: branchesData,
    isLoading: isBranchLoading,
    onFetchMoreData,
    refetch,
  } = useAutocompleteCustomHook({
    useAutocompleteControllerQuery: useBranchesDropDownOptionsQuery,
    input: {
      first: 10,
      after: null,
      before: null,
      last: null,
      filter: {
        vendors: vendorsValue?.id ? [vendorsValue?.id] : null,
        isActive: true,
      },
    },
    dataAccessor: "branches",
  });
  const control = passedControl;
  const branches = branchesData?.branches?.edges?.map(branch => branch?.node)! as Branch[];
  const pageInfo = branchesData?.branches?.pageInfo;

  const handleSearchInput = name => {
    refetch({ filter: { name_Icontains: name, vendors: vendorsValue?.id ? [vendorsValue?.id] : null, isActive: true } });
  };

  useEffect(() => {
    vendorsValue?.id && refetch({ filter: { vendors: [vendorsValue?.id], isActive: true } });
  }, [vendorsValue]);

  return (
    <>
      {!isBranchLoading && (
        <AutocompleteController
          ControllerProps={{
            name: name,
            control: control,
            defaultValue: branchValue || [],
          }}
          multiple
          loading={!!branches.length || isBranchLoading}
          fetchMoreData={onFetchMoreData}
          onSearchInput={handleSearchInput}
          hasMore={Boolean(pageInfo?.hasNextPage)}
          getOptionLabel={option => getTranslatedNameField(option)}
          options={branches || []}
          TextFieldProps={{
            placeholder: t("Branches"),
            label: t("Branches"),
            error: Boolean(errors?.branches?.message),
            helperText: errors?.branches?.message,
          }}
        />
      )}
    </>
  );
};
BranchesAutocomplete.defaultProps = {
  vendorsValue: undefined,
};
