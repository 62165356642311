/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type ProductTypeFragmentFragment = { __typename?: 'ProductType', id: string, name?: string | null, slug: string, productAttributes?: Array<{ __typename?: 'Attribute', name?: string | null, inputType?: Types.AttributeInputTypeEnum | null, idAttributes: string, values?: Array<{ __typename?: 'AttributeValue', id: string, name?: string | null } | null> | null } | null> | null, variantAttributes?: Array<{ __typename?: 'Attribute', name?: string | null, id: string } | null> | null };

export const ProductTypeFragmentFragmentDoc = gql`
    fragment ProductTypeFragment on ProductType {
  id
  name
  slug
  productAttributes {
    name
    idAttributes: id
    inputType
    values {
      id
      name
    }
  }
  variantAttributes {
    name
    id
  }
}
    `;