import { createStyles } from "@mui/styles";
import { inputBorderColorDefault, primary } from "../../customization";

const border = (hasIcon, error) => {
  if (!hasIcon) {
    return "inherit";
  } else return error ? `1px solid #973149` : `1px solid ${inputBorderColorDefault}`;
};
export const styles = () =>
  createStyles({
    container: (props: { hasIcon: boolean; error: boolean }) => ({
      display: "flex",
      alignItems: "center",
      background: "#fff",
      borderRadius: 10,
      border: border(props.hasIcon, props.error),
    }),
    iconContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    iconBG: {
      height: 45,
      width: 45,
      margin: 1,
      background: primary,
      borderRadius: 10,
    },
    absolute: {
      position: "absolute",
    },
    text: (props: { hasIcon: boolean }) => ({
      width: "100%",
      border: props.hasIcon ? "0px" : "inherit",
      "& .MuiInputBase-input": {
        // background: "red",
        border: props.hasIcon ? "0px" : "inherit",
      },
      "& .MuiFilledInput-root": {
        border: props.hasIcon ? "0px" : "inherit",
      },
      "& .MuiFilledInput-input": {
        // backgroundColor: 'lightblue',
        border: props.hasIcon ? "none" : "inherit",
      },
      "&. MuiInputBase-root-MuiFilledInput-root": {
        border: props.hasIcon ? "0px" : "inherit",
      },
      "& .MuiFormHelperText-root": {
        position: "absolute",
        top: 50,
      },
      // MuiInputBase-root-MuiFilledInput-root
    }),
  });
