import { useTranslation } from "@health/i18n";
import moment, { Moment } from "moment";

export const getHelperText = (min: Moment, value: string, max?: Moment): string => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  const momentValue = moment(value, "HH:mm:ss");
  if (min && momentValue.isSameOrBefore(min)) {
    return `${t("Should be after", "Should be after")} ${min.format("hh:mmA")}`;
  }
  if (max && momentValue.isSameOrAfter(max)) {
    return `${t("Should be before", "Should be before")} ${max.format("hh:mmA")}`;
  }
  return "";
};
