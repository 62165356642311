/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { AttributeMutationFragmentFragmentDoc } from '../../../../fragment/attributes/__generated__/attribute';
import { AttributeErrorsFragmentFragmentDoc } from '../../../../fragment/attributes/__generated__/attributeErrors';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AttributeDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type AttributeDeleteMutation = { __typename?: 'Mutation', attributeDelete?: { __typename?: 'AttributeDelete', attribute?: { __typename?: 'Attribute', id: string, name?: string | null, values?: Array<{ __typename?: 'AttributeValue', name?: string | null, id: string, slug?: string | null } | null> | null } | null, productErrors: Array<{ __typename?: 'ProductError', field?: string | null, message?: string | null }> } | null };


export const AttributeDeleteDocument = gql`
    mutation attributeDelete($id: ID!) {
  attributeDelete(id: $id) {
    attribute {
      ...AttributeMutationFragment
    }
    productErrors {
      ...AttributeErrorsFragment
    }
  }
}
    ${AttributeMutationFragmentFragmentDoc}
${AttributeErrorsFragmentFragmentDoc}`;
export type AttributeDeleteMutationFn = Apollo.MutationFunction<AttributeDeleteMutation, AttributeDeleteMutationVariables>;

/**
 * __useAttributeDeleteMutation__
 *
 * To run a mutation, you first call `useAttributeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttributeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attributeDeleteMutation, { data, loading, error }] = useAttributeDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAttributeDeleteMutation(baseOptions?: Apollo.MutationHookOptions<AttributeDeleteMutation, AttributeDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AttributeDeleteMutation, AttributeDeleteMutationVariables>(AttributeDeleteDocument, options);
      }
export type AttributeDeleteMutationHookResult = ReturnType<typeof useAttributeDeleteMutation>;
export type AttributeDeleteMutationResult = Apollo.MutationResult<AttributeDeleteMutation>;
export type AttributeDeleteMutationOptions = Apollo.BaseMutationOptions<AttributeDeleteMutation, AttributeDeleteMutationVariables>;