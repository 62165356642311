/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductUpdateMutationVariables = Types.Exact<{
  input: Types.ProductInput;
  id: Types.Scalars['ID'];
}>;


export type ProductUpdateMutation = { __typename?: 'Mutation', productUpdate?: { __typename?: 'ProductUpdate', product?: { __typename?: 'Product', isPublished: boolean, name?: string | null, id: string, isAddon?: boolean | null, hasAddons?: boolean | null, benefits?: string | null, howToUse?: string | null, description?: string | null } | null, productErrors: Array<{ __typename?: 'ProductError', field?: string | null, message?: string | null }> } | null };


export const ProductUpdateDocument = gql`
    mutation productUpdate($input: ProductInput!, $id: ID!) {
  productUpdate(input: $input, id: $id) {
    product {
      isPublished
      name
      id
      isAddon
      hasAddons
      benefits
      howToUse
      description
    }
    productErrors {
      field
      message
    }
  }
}
    `;
export type ProductUpdateMutationFn = Apollo.MutationFunction<ProductUpdateMutation, ProductUpdateMutationVariables>;

/**
 * __useProductUpdateMutation__
 *
 * To run a mutation, you first call `useProductUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productUpdateMutation, { data, loading, error }] = useProductUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ProductUpdateMutation, ProductUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductUpdateMutation, ProductUpdateMutationVariables>(ProductUpdateDocument, options);
      }
export type ProductUpdateMutationHookResult = ReturnType<typeof useProductUpdateMutation>;
export type ProductUpdateMutationResult = Apollo.MutationResult<ProductUpdateMutation>;
export type ProductUpdateMutationOptions = Apollo.BaseMutationOptions<ProductUpdateMutation, ProductUpdateMutationVariables>;