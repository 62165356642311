import { useTranslation } from "@health/i18n";
import { Box, IconButton, Typography } from "@mui/material";
import { withStyles, WithStyles } from "@mui/styles";
import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment-timezone";
import React, { memo, useState } from "react";
import { darkRed, primary } from "../../customization";
import { TextField } from "../../mui";
import { CustomIcon } from "../CustomIcon";
import { styles } from "./styles";
import { IWorkingHoursProps } from "./types";
import { getHelperText } from "./utils";

const WorkingHours: React.FC<IWorkingHoursProps & WithStyles<typeof styles>> = ({
  onTimeChange: handleTimeChange,
  closeTime,
  openTime,
  dayName,
  onRemoveTime: handleRemoveTime,
  onAddTime: handleAddTime,
  min,
  max,
  classes,
  isLastItem,
}) => {
  const openTimeHelperText = getHelperText(min, openTime);
  const closeTimeHelperText = getHelperText(moment(openTime, "HH:mm:ss"), closeTime, max);
  const [isOpeningTimePickerOpen, setIsOpeningTimePickerOpen] = useState(false);
  const [isEndTimePickerOpen, setIsEndTimePickerOpen] = useState(false);
  const isDisableAdd = openTime && closeTime;

  const handleToggleOpeningTimePicker = () => {
    setIsOpeningTimePickerOpen(!isOpeningTimePickerOpen);
  };
  const handleToggleEndTimePicker = () => {
    setIsEndTimePickerOpen(!isEndTimePickerOpen);
  };
  const { t } = useTranslation();
  return (
    <Box sx={{ width: "calc(100% - 8px)" }} className={classes.container}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Typography component='span' className={classes.dayName}>
          {dayName}
        </Typography>
        <TimePicker
          toolbarTitle={t("Select Time")}
          showToolbar
          showTodayButton
          value={moment(openTime, "HH:mm:ss")}
          open={isOpeningTimePickerOpen}
          onOpen={handleToggleOpeningTimePicker}
          onClose={handleToggleOpeningTimePicker}
          onChange={date => handleTimeChange(date, "openTime")}
          renderInput={({ inputProps, ...params }) => (
            <TextField
              {...params}
              inputProps={{
                ...inputProps,
                placeholder: "HH:MM (AM|PM)",
              }}
              hiddenLabel
              helperText={openTimeHelperText}
              error={Boolean(openTimeHelperText)}
              sx={{
                width: "211px",
              }}
            />
          )}
        />
        <div className={classes.dash}>-</div>
        <TimePicker
          toolbarTitle={t("Select Time")}
          showToolbar
          showTodayButton
          open={isEndTimePickerOpen}
          onOpen={handleToggleEndTimePicker}
          onClose={handleToggleEndTimePicker}
          value={moment(closeTime, "HH:mm:ss")}
          onChange={date => handleTimeChange(date, "closeTime")}
          renderInput={({ inputProps, ...params }) => (
            <TextField
              {...params}
              inputProps={{
                ...inputProps,
                placeholder: "HH:MM (AM|PM)",
              }}
              hiddenLabel
              helperText={closeTimeHelperText}
              error={Boolean(closeTimeHelperText)}
              sx={{
                width: "211px",
              }}
            />
          )}
        />
        {isLastItem && (
          <IconButton onClick={handleAddTime} className={classes.iconButton} disabled={!isDisableAdd}>
            <CustomIcon icon='add' color={primary} width={31} height={31} viewBox='2 0 23 32' />
          </IconButton>
        )}
        <IconButton onClick={handleRemoveTime} className={classes.iconButton}>
          <CustomIcon icon='close' margin='10px 4px' color={darkRed} width={32} height={32} viewBox='-3 -2 32 32' />
        </IconButton>
      </LocalizationProvider>
    </Box>
  );
};

export default memo(withStyles(styles, { name: "QcWebUiWorkingHours" })(WorkingHours));
