import { useTranslation } from "@health/i18n";
import React, { FC } from "react";
import { EntryContainer } from "../../EntryContainer.component";
import { EntryLayout } from "../../EntryLayout.component";
import { ChangePasswordForm } from "../../Form/ChangePasswordForm";

export const ChangePasswordPage: FC<any> = ({ onRedirectLogin }) => {
  const { t } = useTranslation();
  const handleLogin = () => {
    onRedirectLogin();
  };

  return (
    <EntryLayout>
      <EntryContainer title={t("Change Password")}>
        <ChangePasswordForm isPage onChangeStep={handleLogin} />
      </EntryContainer>
    </EntryLayout>
  );
};
