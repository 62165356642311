import { cyan, makeStyles } from "@health/ui";

export const useEntryLayoutStyles = makeStyles()(() => ({
  body: {
    height: "100%",
  },
  image: {
    width: "100%",
    height: "calc(100% - 6px)",
    filter: "blur(6px)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  text: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0, 0.2)",
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 2,
  },
  imageCard: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  card: {
    height: 700,
    borderRadius: 20,
  },
  cardMobile: {
    height: "100vh",
    width: "100vw",
    borderRadius: 0,
    margin: 0,
  },
  container: {
    top: "50%",
    left: 190,
    position: "absolute",
    transform: "translate(-50%, -50%)",
  },
  border: {
    width: 72,
    height: 4,
    background: cyan,
    marginBottom: 14,
  },
  textContainer: {
    width: 347,
    fontSize: 17,
  },
  relativeImg: {
    height: "100%",
    position: "relative",
  },
}));
