import { createStyles } from "@mui/styles";

export const styles = () =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      padding: 10,
    },
    dayName: {
      minWidth: 150,
      padding: 4,
    },
    dash: {
      padding: 10,
    },
    iconButton: {
      padding: 7,
      width: 35,
      height: 35,
      paddingTop: 12,
      margin: 2,
    },
    picker: {
      width: 132,
      padding: 0,
    },
  });
