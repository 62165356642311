import { useTranslation } from "@health/i18n";
import { useToasts } from "react-toast-notifications";

const useAddToast = () => {
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const succeeded = (msg: string) => {
    addToast(t(msg, msg), {
      appearance: "success",
      autoDismiss: true,
    });
  };
  const failed = (msg: string, field?: any) => {
    addToast(field ? field.concat("," + msg) : msg, {
      appearance: "error",
      autoDismiss: true,
    });
  };
  return {
    succeeded,
    failed,
  };
};
export default useAddToast;
