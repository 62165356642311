import DateAdapter from "@mui/lab/AdapterMoment";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { DatePickerProps } from "@mui/x-date-pickers";
import moment from "moment";
import React, { FC } from "react";
import { Controller, ControllerProps } from "react-hook-form";

export type CustomDatePickerProps = Omit<TextFieldProps, "variant"> & {
  label: string;
  name: string;
  placeholder: string;
  control?;
  register?;
  format?: string;
  helperText?: string;
  error?: string | boolean;
  controllerProps?: Omit<ControllerProps, "render" | "name">;
  datePickerProps?: Omit<DatePickerProps, "onChange" | "value" | "renderInput">;
};

export const CustomDatePicker: FC<CustomDatePickerProps> = ({
  format,
  label,
  control,
  name,
  placeholder,
  helperText,
  error,
  controllerProps,
  ...props
}) => {
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <Controller
        name={name}
        control={controllerProps?.control || control}
        defaultValue={controllerProps?.defaultValue || props.defaultValue}
        rules={controllerProps?.rules}
        render={({ field: { onChange: handleOnChange, value } }) => {
          const handleSelectedDate = date => {
            handleOnChange(moment(date)?.isValid() ? moment(date).format(format) : null);
          };
          return (
            <DatePicker
              label={label}
              value={value ? moment(value, format) : null}
              onChange={handleSelectedDate}
              allowSameDateSelection={false}
              renderInput={params => (
                <TextField
                  fullWidth
                  label={label}
                  error={error}
                  helperText={helperText}
                  placeholder={placeholder}
                  required={props.required}
                  {...params}
                  sx={{
                    "& .MuiFormHelperText-root": {
                      color: error ? "#973149" : "primary",
                    },
                    "& .MuiInputLabel-root": {
                      color: error ? "#973149" : "primary",
                    },
                  }}
                />
              )}
              {...props.datePickerProps}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

CustomDatePicker.defaultProps = {
  format: "yyyy-MM-DD",
  control: undefined,
  register: undefined,
  helperText: undefined,
  error: undefined,
  datePickerProps: {},
  controllerProps: {},
};
