/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { ProductTypeFragmentFragmentDoc } from '../../../../fragment/productTypes/__generated__/productTypes';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductTypeUpdateMutationVariables = Types.Exact<{
  input: Types.ProductTypeInput;
  id: Types.Scalars['ID'];
}>;


export type ProductTypeUpdateMutation = { __typename?: 'Mutation', productTypeUpdate?: { __typename?: 'ProductTypeUpdate', productType?: { __typename?: 'ProductType', id: string, name?: string | null, slug: string, productAttributes?: Array<{ __typename?: 'Attribute', name?: string | null, inputType?: Types.AttributeInputTypeEnum | null, idAttributes: string, values?: Array<{ __typename?: 'AttributeValue', id: string, name?: string | null } | null> | null } | null> | null, variantAttributes?: Array<{ __typename?: 'Attribute', name?: string | null, id: string } | null> | null } | null, productErrors: Array<{ __typename?: 'ProductError', field?: string | null, message?: string | null }> } | null };


export const ProductTypeUpdateDocument = gql`
    mutation productTypeUpdate($input: ProductTypeInput!, $id: ID!) {
  productTypeUpdate(input: $input, id: $id) {
    productType {
      ...ProductTypeFragment
    }
    productErrors {
      field
      message
    }
  }
}
    ${ProductTypeFragmentFragmentDoc}`;
export type ProductTypeUpdateMutationFn = Apollo.MutationFunction<ProductTypeUpdateMutation, ProductTypeUpdateMutationVariables>;

/**
 * __useProductTypeUpdateMutation__
 *
 * To run a mutation, you first call `useProductTypeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductTypeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productTypeUpdateMutation, { data, loading, error }] = useProductTypeUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductTypeUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ProductTypeUpdateMutation, ProductTypeUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductTypeUpdateMutation, ProductTypeUpdateMutationVariables>(ProductTypeUpdateDocument, options);
      }
export type ProductTypeUpdateMutationHookResult = ReturnType<typeof useProductTypeUpdateMutation>;
export type ProductTypeUpdateMutationResult = Apollo.MutationResult<ProductTypeUpdateMutation>;
export type ProductTypeUpdateMutationOptions = Apollo.BaseMutationOptions<ProductTypeUpdateMutation, ProductTypeUpdateMutationVariables>;