/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { CityErrorsFragmentFragmentDoc } from '../../../../fragment/city/__generated__/cityErrorFragment';
import { CityFragmentFragmentDoc } from '../../../../fragment/city/__generated__/city';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CityDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type CityDeleteMutation = { __typename?: 'Mutation', cityDelete?: { __typename?: 'CityDelete', cityErrors: Array<{ __typename?: 'BlockError', field?: string | null, message?: string | null, code: Types.BlockErrorCode }>, city?: { __typename?: 'City', id: string, deliveryPriceAmount: number, name: string, nameAr?: string | null, created: any, modified: any, deliveryPrice?: { __typename?: 'Money', amount: number, currency: string } | null } | null } | null };


export const CityDeleteDocument = gql`
    mutation cityDelete($id: ID!) {
  cityDelete(id: $id) {
    cityErrors {
      ...CityErrorsFragment
    }
    city {
      ...CityFragment
    }
  }
}
    ${CityErrorsFragmentFragmentDoc}
${CityFragmentFragmentDoc}`;
export type CityDeleteMutationFn = Apollo.MutationFunction<CityDeleteMutation, CityDeleteMutationVariables>;

/**
 * __useCityDeleteMutation__
 *
 * To run a mutation, you first call `useCityDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCityDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cityDeleteMutation, { data, loading, error }] = useCityDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCityDeleteMutation(baseOptions?: Apollo.MutationHookOptions<CityDeleteMutation, CityDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CityDeleteMutation, CityDeleteMutationVariables>(CityDeleteDocument, options);
      }
export type CityDeleteMutationHookResult = ReturnType<typeof useCityDeleteMutation>;
export type CityDeleteMutationResult = Apollo.MutationResult<CityDeleteMutation>;
export type CityDeleteMutationOptions = Apollo.BaseMutationOptions<CityDeleteMutation, CityDeleteMutationVariables>;