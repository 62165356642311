import { useTranslation } from "@health/i18n";
import {
  BranchesQueryVariables,
  Order,
  OrderDeliveryStatusEnum,
  OrderDirection,
  OrderSortField,
  OrderSourcesEnum,
  OrderStatus,
  PrescriptionsAdminQueryVariables,
  useBranchesQuery,
  useDataGridState,
  useMarketPlaceOrderTrackingQuery,
} from "@health/queries";
import { getTranslatedNameField, mapActiveFiltersForHeader, useAutocompleteCustomHook } from "@health/ui";
import { useState } from "react";
import { useMarketplaceOrdersTrackingScreenFilters } from "./MarketplaceOrdersTrackingScreenFilters";

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useMarketplaceOrdersTrackingPage = () => {
  const { t } = useTranslation("Admin");
  const inputs: PrescriptionsAdminQueryVariables = {
    first: 10,
    after: undefined,
    before: undefined,
    last: undefined,
    filter: {
      source: [OrderSourcesEnum.Checkout],
    },
    sortBy: {
      direction: OrderDirection.Desc,
      field: OrderSortField.Number,
    },
  };

  const { data, isLoading, refetch, pageSize, doChangePageSize, doSort, doFilter, doGoToNext, doGoToPrevious } = useDataGridState({
    useCustomQuery: useMarketPlaceOrderTrackingQuery,
    input: inputs,
  });

  const dataAccessorForBranches = "branches";
  const inputsBranches: BranchesQueryVariables = {
    first: 10,
    after: null,
    before: null,
    last: null,
    filter: {
      isActive: true,
    },
  };

  const {
    data: branches,
    isLoading: loadingBranches,
    onFetchMoreData: onFetchMoreBranches,
  } = useAutocompleteCustomHook({
    useAutocompleteControllerQuery: useBranchesQuery,
    input: inputsBranches,
    dataAccessor: dataAccessorForBranches,
  });
  const pageInfoForBranches = branches?.[dataAccessorForBranches]?.pageInfo;
  const hasMoreForInfoForBranches = pageInfoForBranches?.hasNextPage;
  const pharmacyBranches = branches?.[dataAccessorForBranches]?.edges?.map(item => item?.node)!;

  const prescriptions = (data?.orders?.edges?.map(prescription => prescription?.node) as Order[]) || [];
  const pageInfo = data?.orders?.pageInfo;
  const totalCount = data?.orders?.totalCount;
  const [activeFilters, setActiveFilters] = useState<Record<string, any>>({});
  const handleGotoNext = () => {
    doGoToNext();
  };
  const handleGoToPrevious = () => {
    doGoToPrevious();
  };

  const handlePageSizeChange = (size: number): void => {
    doChangePageSize(size);
  };
  const handleSortData = row => {
    doSort(row.sortColumnEnum);
  };
  const [isFilterOpened, setIsFilterOpened] = useState(false);

  const handleToggleFilters = () => {
    setIsFilterOpened(state => !state);
  };

  const pageHeaderFiltersMappers = {
    branches: value => (!value ? undefined : `${getTranslatedNameField(value)} `),
  };
  const activeFiltersDisplay = mapActiveFiltersForHeader(activeFilters, pageHeaderFiltersMappers);
  const { filters } = useMarketplaceOrdersTrackingScreenFilters(activeFilters, branches);

  const handleApplyFilters = filteredData => {
    const { createdTo, status, deliveryStatus, ordersNumbers, ...rest } = filteredData;
    const filteredStatus = status ? [OrderStatus[status.replace(/\s+/g, "")]] : [];
    setActiveFilters({ ...filteredData, [t("orderType")]: filteredData.orderType });

    const deliveryStatusData = deliveryStatus ? [OrderDeliveryStatusEnum[deliveryStatus.replace(/\s+/g, "")]] : [];
    doFilter({
      ...rest,
      source: [OrderSourcesEnum.Checkout],
      branches: filteredData?.branches?.id ? [filteredData?.branches?.id] : null,
      deliveryStatus: deliveryStatusData || [],
      numbers: ordersNumbers && !isNaN(ordersNumbers) ? [String(ordersNumbers)] : [],
      status: filteredStatus || [],
      deliveryTimeSlot: filteredData?.deliveryTimeSlot?.id,
      deliveryDate: filteredData?.deliveryDate || undefined,
      created:
        filteredData?.created || createdTo
          ? {
              lte: filteredData?.createdTo || null,
              gte: filteredData?.created || null,
            }
          : null,
    });
  };

  return {
    branches: {
      loading: loadingBranches,
      onFetchMore: onFetchMoreBranches,
      hasMore: hasMoreForInfoForBranches,
      options: pharmacyBranches,
    },
    filters,
    isLoading,
    pageSize,
    pageInfo,
    totalCount,
    prescriptions,
    activeFilters,
    isFilterOpened,
    activeFiltersDisplay,
    handleToggleFilters,
    refetch,
    handleGotoNext,
    handleGoToPrevious,
    handlePageSizeChange,
    handleSortData,
    handleApplyFilters,
  };
};
