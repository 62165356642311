import { useTranslation } from "@health/i18n";
import { Permission } from "@health/queries";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import React, { FC } from "react";
import { SearchIcon } from "../../icons/icons";
import { useCustomListComponentStyle } from "./CustomTransferList.styles";

export const ListComponent: FC<{
  items: Permission | any | undefined;
  handleToggle: (value) => () => void;
  checked: Permission | any | undefined;
  handleSearch: (value) => void;
  searchedValue: any;
}> = ({ items, handleToggle, checked, handleSearch, searchedValue }) => {
  const { t } = useTranslation();
  const { classes } = useCustomListComponentStyle();
  return (
    <List
      sx={{
        bgcolor: "background.paper",
        overflow: "scroll",
        height: "578px",
      }}
      dense
      component='div'
      role='list'
    >
      <ListItem role='listitem' className={classes.listItem}>
        <TextField
          sx={{
            width: "calc(90% + 14px)",
            padding: "10px",
            color: "#5B7798",
          }}
          hiddenLabel
          value={searchedValue}
          onChange={handleSearch}
          placeholder={t("Search")}
          InputProps={{
            startAdornment: (
              <InputAdornment position='end'>
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </ListItem>
      {items.map(value => {
        const labelId = `transfer-list-all-item-${value}-label`;
        return (
          <ListItem key={value} role='listitem' className={classes.listItem} button onClick={handleToggle(value)}>
            <ListItemIcon>
              <Checkbox
                checked={checked.indexOf(value) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{
                  "aria-labelledby": labelId,
                }}
              />
            </ListItemIcon>
            <ListItemText className={classes.listItemText} id={labelId} primary={`${value.name}`} />
          </ListItem>
        );
      })}
      <ListItem />
    </List>
  );
};
