/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type AttributeErrorsFragmentFragment = { __typename?: 'ProductError', field?: string | null, message?: string | null };

export const AttributeErrorsFragmentFragmentDoc = gql`
    fragment AttributeErrorsFragment on ProductError {
  field
  message
}
    `;