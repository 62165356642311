/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type UserErrorFragmentFragment = { __typename?: 'AccountError', field?: string | null, message?: string | null, code: Types.AccountErrorCode };

export const UserErrorFragmentFragmentDoc = gql`
    fragment UserErrorFragment on AccountError {
  field
  message
  code
}
    `;