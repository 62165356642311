/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { OrderAddressFragmentFragmentDoc } from '../../../../fragment/address/__generated__/orderAddress';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CheckoutOrderQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type CheckoutOrderQuery = { __typename?: 'Query', order?: { __typename?: 'Order', id: string, status: Types.OrderStatus, number?: string | null, created: any, shippingAddress?: { __typename: 'OrderAddress', id: string, name?: string | null, buildingName?: string | null, buildingNumber?: string | null, streetAddress1?: string | null, streetAddress2?: string | null, phone: string, postalCode: string, city?: { __typename: 'City', id: string, name: string, nameAr?: string | null, currency: string, deliveryPriceAmount: number } | null, coordinates?: { __typename: 'LocationType', lng?: number | null, lat?: number | null } | null, translations?: Array<{ __typename?: 'OrderAddressTranslation', streetAddress1: string, streetAddress2: string, language: { __typename?: 'LanguageDisplay', code: Types.LanguageCodeEnum, language: string } } | null> | null } | null, lines: Array<{ __typename?: 'OrderLine', id: string, productName: string, variantName: string, quantity: number, translatedProductName: string, thumbnail?: { __typename?: 'Image', alt?: string | null, url?: string | null } | null, variant?: { __typename?: 'ProductVariant', id: string, name?: string | null } | null, unitPrice?: { __typename?: 'TaxedMoney', currency: string, gross: { __typename?: 'Money', currency: string, amount: number } } | null } | null> } | null };


export const CheckoutOrderDocument = gql`
    query checkoutOrder($id: ID!) {
  order(id: $id) {
    id
    status
    number
    shippingAddress {
      ...OrderAddressFragment
    }
    created
    lines {
      id
      productName
      thumbnail {
        alt
        url
      }
      variantName
      variant {
        id
        name
      }
      unitPrice {
        gross {
          currency
          amount
        }
        currency
      }
      quantity
      translatedProductName
    }
  }
}
    ${OrderAddressFragmentFragmentDoc}`;

/**
 * __useCheckoutOrderQuery__
 *
 * To run a query within a React component, call `useCheckoutOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckoutOrderQuery(baseOptions: Apollo.QueryHookOptions<CheckoutOrderQuery, CheckoutOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckoutOrderQuery, CheckoutOrderQueryVariables>(CheckoutOrderDocument, options);
      }
export function useCheckoutOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckoutOrderQuery, CheckoutOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckoutOrderQuery, CheckoutOrderQueryVariables>(CheckoutOrderDocument, options);
        }
export type CheckoutOrderQueryHookResult = ReturnType<typeof useCheckoutOrderQuery>;
export type CheckoutOrderLazyQueryHookResult = ReturnType<typeof useCheckoutOrderLazyQuery>;
export type CheckoutOrderQueryResult = Apollo.QueryResult<CheckoutOrderQuery, CheckoutOrderQueryVariables>;