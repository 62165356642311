/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClientProfileMessagesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ClientProfileMessagesQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, unReadMessagesCount?: number | null } | null };


export const ClientProfileMessagesDocument = gql`
    query ClientProfileMessages {
  me {
    id
    unReadMessagesCount
  }
}
    `;

/**
 * __useClientProfileMessagesQuery__
 *
 * To run a query within a React component, call `useClientProfileMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientProfileMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientProfileMessagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientProfileMessagesQuery(baseOptions?: Apollo.QueryHookOptions<ClientProfileMessagesQuery, ClientProfileMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientProfileMessagesQuery, ClientProfileMessagesQueryVariables>(ClientProfileMessagesDocument, options);
      }
export function useClientProfileMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientProfileMessagesQuery, ClientProfileMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientProfileMessagesQuery, ClientProfileMessagesQueryVariables>(ClientProfileMessagesDocument, options);
        }
export type ClientProfileMessagesQueryHookResult = ReturnType<typeof useClientProfileMessagesQuery>;
export type ClientProfileMessagesLazyQueryHookResult = ReturnType<typeof useClientProfileMessagesLazyQuery>;
export type ClientProfileMessagesQueryResult = Apollo.QueryResult<ClientProfileMessagesQuery, ClientProfileMessagesQueryVariables>;