/* eslint-disable max-lines */
import { useTranslation } from "@health/i18n";
import { OrderStatusEnum } from "@health/queries";
import { FieldsTypes, FilterField, getTranslatedNameField } from "@health/ui";
import { deliveryStatus, statuses } from "./components/utils";

export const useMarketplaceOrdersTrackingScreenFilters = (activeFilters?: any, branches?) => {
  const { t } = useTranslation("Admin");

  const filters: FilterField[] = [
    {
      id: "1",
      grid: 6,
      name: "ordersNumbers",
      type: FieldsTypes.input,
      fieldProps: {
        placeholder: t("Order Number"),
        label: t("Order Number"),
        name: "ordersNumbers",
        defaultValue: activeFilters?.ordersNumbers,
      },
    },
    {
      id: "3",
      grid: 6,
      name: "status",
      type: FieldsTypes.autocomplete,
      fieldProps: {
        placeholder: t("Status"),
        label: t("Status"),
        name: "status",
        defaultValue: statuses?.find(item => item?.display === activeFilters?.status),
        getOptionLabel: o => o.display,
        isOptionEqualToValue: (option, value) => option.id === value.id,
        options: statuses.filter(
          item => item.id !== OrderStatusEnum.WaitingProviderSelection && !item.id.toString().toLowerCase().includes("erx")
        ),
      },
    },
    {
      id: "4",
      grid: 6,
      name: "deliveryStatus",
      type: FieldsTypes.autocomplete,
      fieldProps: {
        placeholder: t("Delivery Status"),
        label: t("Delivery Status"),
        name: "deliveryStatus",
        defaultValue: deliveryStatus?.find(item => item?.display === activeFilters?.deliveryStatus),
        getOptionLabel: o => o.display,
        isOptionEqualToValue: (option, value) => option.id === value.id,
        options: deliveryStatus,
      },
    },
    {
      id: "5",
      grid: 6,
      name: "created",
      type: FieldsTypes.date,
      fieldProps: {
        placeholder: t("Created Date From"),
        label: t("Created Date From"),
        name: "created",
        defaultValue: activeFilters?.created,
      },
    },
    {
      id: "6",
      grid: 6,
      name: "createdTo",
      type: FieldsTypes.date,
      fieldProps: {
        placeholder: t("Created Date To"),
        label: t("Created Date To"),
        name: "createdTo",
        defaultValue: activeFilters?.createdTo,
      },
    },
    {
      id: "7",
      grid: 6,
      name: "branches",
      type: FieldsTypes.lazyAutocomplete,
      fieldProps: {
        placeholder: t("Branches"),
        loading: branches?.loading,
        name: "branches",
        onFetchMore: branches?.onFetchMore,
        hasMore: branches?.hasMore,
        defaultValue: branches?.options?.length ? branches?.options?.find(item => item?.id === activeFilters?.branches?.id) : undefined,
        isOptionEqualToValue: (option, value) => option === value,
        getOptionLabel: option => `${getTranslatedNameField(option)} - ${option?.healthLicense ?? ""}`,

        options: branches?.options,
      },
    },
  ];

  return {
    filters,
  };
};
