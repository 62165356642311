import { useTranslation } from "@health/i18n";
import { useBranchUsersQuery, User } from "@health/queries";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { staffUsersColumns } from "../UsersManagement/Users/list/usersColumns";

export const useBranchUsersHook = () => {
  const { t } = useTranslation("Admin");
  const params = useParams();
  const [columns] = useState(staffUsersColumns(t));

  const { data, loading } = useBranchUsersQuery({
    variables: {
      first: 10,
      id: String(params.id),
    },
  });

  const vendor = data?.vendor;
  const users = vendor?.branches?.edges?.find(branch => branch?.node?.id === params?.idBranch)?.node?.users as User[];
  const handleSortColumn = () => {};
  return {
    loading,
    columns,
    users,
    vendor,
    handleSortColumn,
  };
};
