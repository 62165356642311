import { OrderStatus } from "@health/queries";
import { Typography } from "@health/ui";
import React, { FC } from "react";
import { useStatusStyles } from "./Status.styles";

type StatusProps = {
  status;
};

export const Status: FC<StatusProps> = ({ status: statusData }) => {
  const { classes } = useStatusStyles();

  const arrayStatus = String(statusData).split("_");
  const status = arrayStatus.map(item => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(" ");

  const getColor = {
    [OrderStatus.Delivered]: "success",
    [OrderStatus.Failed]: "error",
    [OrderStatus.Allocated]: "pending",
    [OrderStatus.CanceledByConsumer]: "error",
    [OrderStatus.ErxHubClaimSubmitTimedOut]: "error",
    [OrderStatus.OutForDelivery]: "warning",
    [OrderStatus.NewRequest]: "preparing",
    [OrderStatus.WaitingProviderSelection]: "preparing",
    [OrderStatus.Dispensed]: "blue",
    [OrderStatus.ErxHubAuthRequestFailed]: "error",
    [OrderStatus.ErxHubRejected]: "error",
    [OrderStatus.ErxHubClaimFailed]: "error",
    [OrderStatus.ErxHubClaimRejected]: "error",
    [OrderStatus.ErxHubTimedOut]: "error",
    [OrderStatus.InProgress]: "blue",
    [OrderStatus.ErxHubClaimApproved]: "success",
    [OrderStatus.ErxHubFullyApproved]: "success",
    [OrderStatus.WaitingErxHubApproval]: "success",
    [OrderStatus.ReadyForCustomerPickup]: "success",
    [OrderStatus.ErxHubPartiallyApproved]: "success",
    [OrderStatus.WaitingErxHubClaimApproval]: "success",
  };

  return <Typography className={`${classes.text} ${classes[getColor[statusData]]}`}>{status}</Typography>;
};
