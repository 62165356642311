/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { SiteSettingsFragmentFragmentDoc } from '../../../../fragment/SiteSettings/__generated__/SiteSettingsFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SiteSettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SiteSettingsQuery = { __typename?: 'Query', siteSettings: { __typename?: 'SiteSettings', id: string, vatPercentage?: number | null, enablePickup?: boolean | null, defaultCurrency: string, orderDeliveryTimeout?: number | null, orderBaseDeliveryFee?: number | null, orderAcceptanceTimeout?: number | null, orderDispensingTimeout?: number | null, orderErxHubApprovalTimeout?: number | null, multiplePharmaciesDelivery?: boolean | null, orderErxHubApprovalResubmitTimeout?: number | null, orderPaymentTimeout?: number | null, deliveryNumberOfReminders?: number | null, deliveryMinutesTimeGapBeforeEachReminder?: number | null, marketPlaceEnabled?: boolean | null, scheduleDeliveryEnabled?: boolean | null } };


export const SiteSettingsDocument = gql`
    query siteSettings {
  siteSettings {
    ...SiteSettingsFragment
  }
}
    ${SiteSettingsFragmentFragmentDoc}`;

/**
 * __useSiteSettingsQuery__
 *
 * To run a query within a React component, call `useSiteSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSiteSettingsQuery(baseOptions?: Apollo.QueryHookOptions<SiteSettingsQuery, SiteSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SiteSettingsQuery, SiteSettingsQueryVariables>(SiteSettingsDocument, options);
      }
export function useSiteSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SiteSettingsQuery, SiteSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SiteSettingsQuery, SiteSettingsQueryVariables>(SiteSettingsDocument, options);
        }
export type SiteSettingsQueryHookResult = ReturnType<typeof useSiteSettingsQuery>;
export type SiteSettingsLazyQueryHookResult = ReturnType<typeof useSiteSettingsLazyQuery>;
export type SiteSettingsQueryResult = Apollo.QueryResult<SiteSettingsQuery, SiteSettingsQueryVariables>;