/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../fragment/PageInfo/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BranchUsersQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type BranchUsersQuery = { __typename?: 'Query', vendor?: { __typename?: 'Vendor', name: string, nameAr?: string | null, ownerName: string, nationalId: string, description?: string | null, branches?: { __typename?: 'BranchCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'BranchCountableEdge', node: { __typename?: 'Branch', id: string, name: string, nameAr?: string | null, users?: Array<{ __typename?: 'User', id: string, email: string, firstName?: string | null, lastName?: string | null, mobile?: string | null, isStaff?: boolean | null, isSuperuser?: boolean | null, isVendorAdmin?: boolean | null, healthLicenseNumber?: string | null, healthLicenseStartDate?: any | null, healthLicenseEndDate?: any | null, appType?: Types.AppTypes | null, appRole?: Types.AppRoleTypes | null, isActive: boolean } | null> | null } }> } | null } | null };


export const BranchUsersDocument = gql`
    query branchUsers($id: ID!, $first: Int, $after: String) {
  vendor(id: $id) {
    name
    nameAr
    ownerName
    nationalId
    description
    branches(first: $first, after: $after) {
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          id
          name
          nameAr
          users {
            id
            email
            firstName
            lastName
            mobile
            isStaff
            isSuperuser
            isVendorAdmin
            healthLicenseNumber
            healthLicenseStartDate
            healthLicenseEndDate
            appType
            appRole
            isActive
          }
        }
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useBranchUsersQuery__
 *
 * To run a query within a React component, call `useBranchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useBranchUsersQuery(baseOptions: Apollo.QueryHookOptions<BranchUsersQuery, BranchUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BranchUsersQuery, BranchUsersQueryVariables>(BranchUsersDocument, options);
      }
export function useBranchUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BranchUsersQuery, BranchUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BranchUsersQuery, BranchUsersQueryVariables>(BranchUsersDocument, options);
        }
export type BranchUsersQueryHookResult = ReturnType<typeof useBranchUsersQuery>;
export type BranchUsersLazyQueryHookResult = ReturnType<typeof useBranchUsersLazyQuery>;
export type BranchUsersQueryResult = Apollo.QueryResult<BranchUsersQuery, BranchUsersQueryVariables>;