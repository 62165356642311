/* eslint-disable max-lines */
/* eslint-disable react/jsx-handler-names */
import { formGirdBreakPoints } from "@health/common";
import { SearchParameter } from "@health/queries";
import { AutocompleteController, CircularProgress, CustomMuiDialog, Grid, StyledButton, Typography } from "@health/ui";
import React, { FC, Fragment } from "react";
import { useFetchToSubListHook } from "./FetchToSubListHook";

export const FetchToSubList: FC<{ searchParameters: SearchParameter[]; open: boolean; onClose: () => void }> = ({
  searchParameters,
  open,
  onClose,
}) => {
  const { t, methods, getValue, fetchMoreData, handleOnFetchToMedList, subLists, isLoading, isSubmitting, hasMoreData, selectedSubList } =
    useFetchToSubListHook({ searchParameters });
  return (
    <CustomMuiDialog
      onClose={onClose}
      open={open}
      button={<></>}
      DialogTitleProps={{
        title: t("Import current list to a Sublist"),
      }}
      DialogActionsProps={{
        children: (
          <>
            <StyledButton
              color='success'
              disabled={isSubmitting}
              size='medium'
              type={"submit"}
              onClick={methods.handleSubmit(handleOnFetchToMedList)}
            >
              {isSubmitting && <CircularProgress size={20} sx={{ mx: 1 }} />} {isSubmitting ? t("Fetching") : t("Fetch")}
            </StyledButton>
          </>
        ),
      }}
    >
      <form onSubmit={methods.handleSubmit(handleOnFetchToMedList)}>
        <Grid container spacing={4} lg={12} flexDirection='column'>
          <Grid item container spacing={2} {...formGirdBreakPoints}>
            {searchParameters?.map(param => (
              <Fragment key={param.code}>
                <Grid item display={"flex"} alignItems='center' {...formGirdBreakPoints}>
                  <Typography variant='body1'>{param.code}: </Typography>
                  <Typography variant='body2'> {getValue(param)}</Typography>
                </Grid>
              </Fragment>
            ))}
          </Grid>
          <Grid item md={4}>
            <AutocompleteController
              ControllerProps={{
                name: "subList",
                control: methods.control,
                defaultValue: null,
              }}
              hasMore={hasMoreData}
              loading={isLoading}
              fetchMoreData={fetchMoreData}
              getOptionLabel={o => o?.display || ""}
              options={subLists}
              value={selectedSubList}
              TextFieldProps={{
                placeholder: t("SubList"),
                error: Boolean(methods.formState.errors.subList?.message),
                helperText: methods.formState.errors.subList?.message,
              }}
            />
          </Grid>
        </Grid>
      </form>
    </CustomMuiDialog>
  );
};
