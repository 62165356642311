/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AddressFragmentFragmentDoc } from '../../address/__generated__/address';
export type AdminVendorFragmentFragment = { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null, description?: string | null, backGroundImage?: string | null, logo?: string | null, ownerName: string, nationalId: string, created: any, type?: Types.VendorType | null, address?: { __typename: 'Address', id: string, name?: string | null, area?: string | null, streetAddress1?: string | null, streetAddress2?: string | null, buildingName?: string | null, buildingNumber?: string | null, phone?: string | null, isDefaultShippingAddress?: boolean | null, region?: string | null, city: { __typename?: 'City', id: string, name: string, nameAr?: string | null }, coordinates?: { __typename?: 'LocationType', lng?: number | null, lat?: number | null } | null, translations?: Array<{ __typename?: 'AddressTranslation', streetAddress1: string, streetAddress2: string, area: string, language: { __typename?: 'LanguageDisplay', code: Types.LanguageCodeEnum, language: string } } | null> | null } | null, bankInfo?: { __typename?: 'VendorBankInfo', bankName: string, accountNumber: string, iban: string, accountName?: string | null } | null };

export const AdminVendorFragmentFragmentDoc = gql`
    fragment AdminVendorFragment on Vendor {
  id
  name
  nameAr
  description
  backGroundImage
  logo
  ownerName
  nationalId
  created
  address {
    ...AddressFragment
  }
  type
  bankInfo {
    bankName
    accountNumber
    iban
    accountName
  }
}
    ${AddressFragmentFragmentDoc}`;