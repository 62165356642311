import { useTranslation } from "@health/i18n";
import { useMemo, useState } from "react";
import { useLocationHooks } from "../utils";
import { ILocationPickerProps } from "./types";

export const useLocationPickerHooks = (props: ILocationPickerProps) => {
  const { getAddressFromLatLng } = useLocationHooks();
  const { t } = useTranslation();
  const { location, defaultCenter, hasLocationLngLat, onLocationChange } = props;
  const center = useMemo(() => (location?.lat && location?.lng ? location : defaultCenter), [defaultCenter, location]);

  const [isChecked, setIsChecked] = useState(false);

  const handlePickLocation = async event => {
    const locationAddress = await getAddressFromLatLng(event?.latLng?.lat(), event?.latLng?.lng());
    onLocationChange(
      {
        coordinates: { lat: event?.latLng?.lat(), lng: event?.latLng?.lng() },
        city: locationAddress?.city,
        streetAddress1: locationAddress?.streetAddress1,
        name: locationAddress?.city,
        id: "",
        postalCode: "",
      },
      {
        source: "pickLocation",
      }
    );
  };

  const handleIsChecked = () => {
    setIsChecked(state => !state);
  };

  const handleLocationChanged = (type: "lng" | "lat") => async event => {
    const pickedLocation = { ...center, [type]: Number(event.target.value) };
    const locationAddress = await getAddressFromLatLng(pickedLocation?.lat, pickedLocation?.lng);
    onLocationChange(
      {
        coordinates: pickedLocation,
        city: locationAddress?.city,
        streetAddress1: locationAddress?.streetAddress1,
        name: locationAddress?.city,
        id: "",
        postalCode: "",
      },
      {
        source: "inputFields",
      }
    );
  };

  return {
    ...props,
    hasLocationLngLat,
    center,
    isChecked,
    t,
    handlePickLocation,
    handleIsChecked,
    handleLocationChanged,
    handleSearchLocation: (newLocation, extra) =>
      onLocationChange(newLocation, {
        source: extra.source === "search" ? "search" : "pickLocation",
      }),
  };
};
