/* eslint-disable max-lines */
import { TicketPrescriptionDispenseInput } from "@health/queries";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { onSubmitFunction } from "./utils";

export interface ManualPrescriptionOrderFormProps {
  onDone: (data: TicketPrescriptionDispenseInput) => void;
}

export const useManualPrescriptionOrderForm = ({ onDone }: ManualPrescriptionOrderFormProps) => {
  const navigate = useNavigate();

  const methods = useForm<TicketPrescriptionDispenseInput>({
    mode: "all",
    criteriaMode: "all",
  });
  const {
    handleSubmit,
    formState: { isDirty },
  } = methods;

  const handleCancel = () => navigate(-1);

  const handleClick = data => {
    const result = onSubmitFunction(data);
    onDone(result);
  };

  return {
    isDirty,
    methods,
    handleClick,
    handleSubmit,
    handleCancel,
  };
};
