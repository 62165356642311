import { useTranslation } from "@health/i18n";
import { formatMessageErrors, ProductDocument, ProductError, useProductQuery, useProductVariantCreateMutation } from "@health/queries";
import { useAddToast } from "@health/ui";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { VariantFormSubmitData } from "../components/variants/components/Form/type";
import VariantForm from "../components/variants/components/Form/VariantForm.component";

const VariantsFormNewPage = () => {
  const [errors, setErrors] = useState<Partial<ProductError>[] | undefined>([]);
  const params = useParams();
  const id = params?.id as string;
  const { succeeded, failed } = useAddToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const productVariantCreatedSuccessfully = t("Product Variant Created successfully");
  const [productVariantCreate, { loading: isCreateLoading }] = useProductVariantCreateMutation({
    onCompleted: data => {
      const errors = data?.productVariantCreate?.productErrors;
      setErrors(errors);
      if (errors && errors?.length > 0) {
        failed(t(formatMessageErrors(errors)));
        return;
      }
      succeeded(productVariantCreatedSuccessfully);
      navigate(data?.productVariantCreate?.productVariant?.id!);
    },
    onError: () => {
      failed(t("Product Variant Created Failed"));
    },
    refetchQueries: [
      {
        query: ProductDocument,
        variables: {
          id,
        },
      },
    ],
  });

  // const { data: productVariantData, loading: LoadingVariant } = useProductVariantQuery({
  //   variables: {
  //     id: idVariant,
  //   },
  // });
  const { data: productData, loading: LoadingProduct } = useProductQuery({
    variables: {
      id,
    },
  });
  const handleSubmit = (data: VariantFormSubmitData) => {
    productVariantCreate({
      variables: {
        input: {
          sku: data?.sku,
          product: id,
          attributes: data?.attributes.map(t => ({ id: t.attributeId, values: [t.value].flatMap(v => v?.name!) })) || [],
          trackInventory: data?.trackInventory,
        },
      },
    });
  };
  return (
    <VariantForm
      defaultProductData={productData?.product!}
      errors={errors}
      isEdit={false}
      onSubmitData={handleSubmit}
      loading={LoadingProduct}
      isSubmitDisabled={isCreateLoading}
    />
  );
};

export default VariantsFormNewPage;
