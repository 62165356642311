import { useTranslation } from "@health/i18n";
import {
  CitiesSettingsQueryVariables,
  City,
  CityOrderField,
  nextSortDirection,
  OrderDirection,
  useCitiesSettingsQuery,
  useDataGridState,
} from "@health/queries";
import { CustomTableColumnProps, FieldsTypes, FilterField } from "@health/ui";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PharmacyListingCriteriaColumns } from "./PharmacyListingCriteriaColumns";

const usePharmacyListingCriteriaHook = () => {
  const { t } = useTranslation("Admin");
  const navigate = useNavigate();

  const [activeFilters, setActiveFilters] = useState<Record<string, any>>({});
  const inputs: CitiesSettingsQueryVariables = {
    first: 10,
    sortBy: {
      field: CityOrderField.Name,
      direction: OrderDirection.Desc,
    },
    filter: null,
  };

  const {
    data,
    isLoading,
    pageSize,
    variables,
    doSort,
    doFilter,
    doChangePageSize,
    doGoToNext: handleGotoNext,
    doGoToPrevious: handleGoToPrevious,
  } = useDataGridState({
    useCustomQuery: useCitiesSettingsQuery,
    input: inputs,
  });
  const [columns, setColumns] = useState(PharmacyListingCriteriaColumns(t));
  const entity = data?.cities;
  const items = entity?.edges.map(item => item.node) as City[];
  const pageInfo = entity?.pageInfo;
  const totalCount = entity?.totalCount || 0;

  const handleSortData = (columnObj: CustomTableColumnProps<City>) => {
    const sortDirection = nextSortDirection(variables.sortBy?.direction);
    setColumns(cols =>
      cols.map(column => {
        if (column.key === columnObj.key) {
          column.sortDirection = sortDirection ? sortDirection : false;
        } else {
          column.sortDirection = undefined;
        }
        return column;
      })
    );
    doSort(columnObj.sortColumnEnum);
  };

  const handlePageSizeChange = (size: number): void => {
    doChangePageSize(size);
  };

  const fields: FilterField[] = [
    {
      type: FieldsTypes.input,
      name: "name_Icontains",
      fieldProps: {
        defaultValue: activeFilters?.name_Icontains,
        placeholder: t("Name"),
        label: t("Name"),
        name: "name_Icontains",
      },
    },
  ];

  const handleApplyFilters = (filters: Record<string, string | unknown>) => {
    setActiveFilters(filters);
    doFilter({
      name_Icontains: filters.name_Icontains || null,
    });
  };

  const handleDismissFilters = () => {
    setActiveFilters({});
  };
  const handleEditRow = (row: City) => {
    navigate("form/" + row.id);
  };
  const [isFilterOpened, setIsFilterOpened] = useState(false);

  const handleToggleFilters = () => {
    setIsFilterOpened(state => !state);
  };
  return {
    items,
    fields,
    columns,
    pageSize,
    pageInfo,
    isLoading,
    totalCount,
    activeFilters,
    isFilterOpened,
    handleToggleFilters,
    handleEditRow,
    handleGotoNext,
    handleSortData,
    handleGoToPrevious,
    handleApplyFilters,
    handleDismissFilters,
    handlePageSizeChange,
  };
};

export default usePharmacyListingCriteriaHook;
