import { useTranslation } from "@health/i18n";
import React, { FC } from "react";
import { VerificationForm } from "../Form/VerificationForm";
import { Tabs } from "./Tabs";
import { FormProps } from "./types";

export const ConfirmationFormComponent: FC<FormProps> = props => {
  const { onChangeStep: handleStep } = props;
  const { t } = useTranslation();

  return (
    <Tabs
      items={[
        {
          name: t("Verify your account"),
          value: "1",
          content: <VerificationForm isPage={false} onChangeStep={handleStep} />,
        },
      ]}
    />
  );
};
