/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { WishlistFragmentFragmentDoc } from '../../../../fragment/wishlist/__generated__/wishlist';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClientProfileWishListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ClientProfileWishListQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, wishlist?: { __typename?: 'Wishlist', id: string, createdAt: any, items?: Array<{ __typename?: 'WishlistItem', id: string, createdAt: any, vendorProductVariant: { __typename?: 'VendorProductVariant', id: string, priceAmount?: number | null, price?: { __typename?: 'Money', currency: string, amount: number } | null, vendor?: { __typename?: 'Vendor', id: string, branches?: { __typename?: 'BranchCountableConnection', edges: Array<{ __typename?: 'BranchCountableEdge', node: { __typename?: 'Branch', id: string } }> } | null } | null, productVariant: { __typename?: 'ProductVariant', id: string, product?: { __typename?: 'Product', id: string, name?: string | null, description?: string | null, images?: Array<{ __typename?: 'ProductImage', id: string, url: string } | null> | null, category?: { __typename?: 'Category', id: string, name?: string | null } | null } | null } } } | null> | null } | null } | null };


export const ClientProfileWishListDocument = gql`
    query ClientProfileWishList {
  me {
    id
    wishlist {
      id
      createdAt
      items {
        ...WishlistFragment
      }
    }
  }
}
    ${WishlistFragmentFragmentDoc}`;

/**
 * __useClientProfileWishListQuery__
 *
 * To run a query within a React component, call `useClientProfileWishListQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientProfileWishListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientProfileWishListQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientProfileWishListQuery(baseOptions?: Apollo.QueryHookOptions<ClientProfileWishListQuery, ClientProfileWishListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientProfileWishListQuery, ClientProfileWishListQueryVariables>(ClientProfileWishListDocument, options);
      }
export function useClientProfileWishListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientProfileWishListQuery, ClientProfileWishListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientProfileWishListQuery, ClientProfileWishListQueryVariables>(ClientProfileWishListDocument, options);
        }
export type ClientProfileWishListQueryHookResult = ReturnType<typeof useClientProfileWishListQuery>;
export type ClientProfileWishListLazyQueryHookResult = ReturnType<typeof useClientProfileWishListLazyQuery>;
export type ClientProfileWishListQueryResult = Apollo.QueryResult<ClientProfileWishListQuery, ClientProfileWishListQueryVariables>;