/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { ProductTypeFragmentFragmentDoc } from '../../../../fragment/productTypes/__generated__/productTypes';
import { PageInfoFragmentFragmentDoc } from '../../../../fragment/PageInfo/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductTypeQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  first: Types.Scalars['Int'];
}>;


export type ProductTypeQuery = { __typename?: 'Query', productType?: { __typename?: 'ProductType', id: string, name?: string | null, slug: string, availableAttributes?: { __typename?: 'AttributeCountableConnection', edges: Array<{ __typename?: 'AttributeCountableEdge', node: { __typename?: 'Attribute', id: string, name?: string | null, slug?: string | null } }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } } | null, productAttributes?: Array<{ __typename?: 'Attribute', name?: string | null, inputType?: Types.AttributeInputTypeEnum | null, idAttributes: string, values?: Array<{ __typename?: 'AttributeValue', id: string, name?: string | null } | null> | null } | null> | null, variantAttributes?: Array<{ __typename?: 'Attribute', name?: string | null, id: string } | null> | null } | null };


export const ProductTypeDocument = gql`
    query productType($id: ID!, $first: Int!) {
  productType(id: $id) {
    ...ProductTypeFragment
    availableAttributes(first: $first) {
      edges {
        node {
          id
          name
          slug
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
}
    ${ProductTypeFragmentFragmentDoc}
${PageInfoFragmentFragmentDoc}`;

/**
 * __useProductTypeQuery__
 *
 * To run a query within a React component, call `useProductTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useProductTypeQuery(baseOptions: Apollo.QueryHookOptions<ProductTypeQuery, ProductTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductTypeQuery, ProductTypeQueryVariables>(ProductTypeDocument, options);
      }
export function useProductTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductTypeQuery, ProductTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductTypeQuery, ProductTypeQueryVariables>(ProductTypeDocument, options);
        }
export type ProductTypeQueryHookResult = ReturnType<typeof useProductTypeQuery>;
export type ProductTypeLazyQueryHookResult = ReturnType<typeof useProductTypeLazyQuery>;
export type ProductTypeQueryResult = Apollo.QueryResult<ProductTypeQuery, ProductTypeQueryVariables>;