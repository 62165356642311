/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetEditCodeSystemQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetEditCodeSystemQuery = { __typename?: 'Query', getCodeSystem?: { __typename?: 'CodeSystem', id?: string | null, codeSystemDefinition?: { __typename?: 'CodeSystemDefinition', id?: string | null, code?: Types.CodeSystemCode | null, codeSystemConceptFields?: Array<{ __typename?: 'CodeSystemConceptFieldDefinition', code?: string | null, display?: string | null, fieldType?: Types.Type | null, isMandatory?: boolean | null } | null> | null } | null } | null };


export const GetEditCodeSystemDocument = gql`
    query getEditCodeSystem($id: ID!) {
  getCodeSystem(id: $id) {
    id
    codeSystemDefinition {
      id
      code
      codeSystemConceptFields {
        code
        display
        fieldType
        isMandatory
      }
    }
  }
}
    `;

/**
 * __useGetEditCodeSystemQuery__
 *
 * To run a query within a React component, call `useGetEditCodeSystemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditCodeSystemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditCodeSystemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEditCodeSystemQuery(baseOptions: Apollo.QueryHookOptions<GetEditCodeSystemQuery, GetEditCodeSystemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEditCodeSystemQuery, GetEditCodeSystemQueryVariables>(GetEditCodeSystemDocument, options);
      }
export function useGetEditCodeSystemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEditCodeSystemQuery, GetEditCodeSystemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEditCodeSystemQuery, GetEditCodeSystemQueryVariables>(GetEditCodeSystemDocument, options);
        }
export type GetEditCodeSystemQueryHookResult = ReturnType<typeof useGetEditCodeSystemQuery>;
export type GetEditCodeSystemLazyQueryHookResult = ReturnType<typeof useGetEditCodeSystemLazyQuery>;
export type GetEditCodeSystemQueryResult = Apollo.QueryResult<GetEditCodeSystemQuery, GetEditCodeSystemQueryVariables>;