/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { PermissionGroupFragmentFragmentDoc } from '../../../../fragment/Group/__generated__/permissionGroupFragment';
import { PermissionGroupErrorsFragmentFragmentDoc } from '../../../../fragment/PermissionGroupError/__generated__/permissionGroupError';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PermissionGroupUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.PermissionGroupUpdateInput;
}>;


export type PermissionGroupUpdateMutation = { __typename?: 'Mutation', permissionGroupUpdate?: { __typename?: 'PermissionGroupUpdate', group?: { __typename?: 'Group', id: string, name: string, users?: Array<{ __typename?: 'User', id: string, email: string, firstName?: string | null, lastName?: string | null } | null> | null, permissions?: Array<{ __typename?: 'Permission', code: Types.PermissionEnum, name: string } | null> | null } | null, permissionGroupErrors: Array<{ __typename?: 'PermissionGroupError', field?: string | null, message?: string | null, code: Types.PermissionGroupErrorCode }> } | null };


export const PermissionGroupUpdateDocument = gql`
    mutation permissionGroupUpdate($id: ID!, $input: PermissionGroupUpdateInput!) {
  permissionGroupUpdate(id: $id, input: $input) {
    group {
      ...PermissionGroupFragment
    }
    permissionGroupErrors {
      ...PermissionGroupErrorsFragment
    }
  }
}
    ${PermissionGroupFragmentFragmentDoc}
${PermissionGroupErrorsFragmentFragmentDoc}`;
export type PermissionGroupUpdateMutationFn = Apollo.MutationFunction<PermissionGroupUpdateMutation, PermissionGroupUpdateMutationVariables>;

/**
 * __usePermissionGroupUpdateMutation__
 *
 * To run a mutation, you first call `usePermissionGroupUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePermissionGroupUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [permissionGroupUpdateMutation, { data, loading, error }] = usePermissionGroupUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePermissionGroupUpdateMutation(baseOptions?: Apollo.MutationHookOptions<PermissionGroupUpdateMutation, PermissionGroupUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PermissionGroupUpdateMutation, PermissionGroupUpdateMutationVariables>(PermissionGroupUpdateDocument, options);
      }
export type PermissionGroupUpdateMutationHookResult = ReturnType<typeof usePermissionGroupUpdateMutation>;
export type PermissionGroupUpdateMutationResult = Apollo.MutationResult<PermissionGroupUpdateMutation>;
export type PermissionGroupUpdateMutationOptions = Apollo.BaseMutationOptions<PermissionGroupUpdateMutation, PermissionGroupUpdateMutationVariables>;