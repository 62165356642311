/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { AttributeMutationFragmentFragmentDoc } from '../../../../fragment/attributes/__generated__/attribute';
import { AttributeErrorsFragmentFragmentDoc } from '../../../../fragment/attributes/__generated__/attributeErrors';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AttributeCreateMutationVariables = Types.Exact<{
  input: Types.AttributeCreateInput;
}>;


export type AttributeCreateMutation = { __typename?: 'Mutation', attributeCreate?: { __typename?: 'AttributeCreate', attribute?: { __typename?: 'Attribute', name?: string | null, id: string, values?: Array<{ __typename?: 'AttributeValue', name?: string | null, id: string, slug?: string | null } | null> | null } | null, productErrors: Array<{ __typename?: 'ProductError', field?: string | null, message?: string | null }> } | null };


export const AttributeCreateDocument = gql`
    mutation attributeCreate($input: AttributeCreateInput!) {
  attributeCreate(input: $input) {
    attribute {
      name
      id
      ...AttributeMutationFragment
    }
    productErrors {
      ...AttributeErrorsFragment
    }
  }
}
    ${AttributeMutationFragmentFragmentDoc}
${AttributeErrorsFragmentFragmentDoc}`;
export type AttributeCreateMutationFn = Apollo.MutationFunction<AttributeCreateMutation, AttributeCreateMutationVariables>;

/**
 * __useAttributeCreateMutation__
 *
 * To run a mutation, you first call `useAttributeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttributeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attributeCreateMutation, { data, loading, error }] = useAttributeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAttributeCreateMutation(baseOptions?: Apollo.MutationHookOptions<AttributeCreateMutation, AttributeCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AttributeCreateMutation, AttributeCreateMutationVariables>(AttributeCreateDocument, options);
      }
export type AttributeCreateMutationHookResult = ReturnType<typeof useAttributeCreateMutation>;
export type AttributeCreateMutationResult = Apollo.MutationResult<AttributeCreateMutation>;
export type AttributeCreateMutationOptions = Apollo.BaseMutationOptions<AttributeCreateMutation, AttributeCreateMutationVariables>;