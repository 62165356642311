import { darkBlue, darkRed, inputBorderColorDefault, makeStyles, orange } from "@health/ui";
export const useConfirmationStyle = makeStyles<{ currentDir?: any }>()((theme, { currentDir }) => ({
  button: {
    height: 50,
    margin: 0,
    marginTop: 10,
    fontSize: 14,
  },
  mobile: {
    textAlign: "center",
    display: "block",
  },
  confirm: {
    margin: "10px 0px",
    borderRadius: 15,
    "--ReactInputVerificationCode-itemWidth": "70px",
    "--ReactInputVerificationCode-itemHeight": "70px",
    "--ReactInputVerificationCode-itemSpacing": "2rem",
    display: "flex",
    justifyContent: "center",
    "& .ReactInputVerificationCode__item": {
      borderRadius: 10,
      fontSize: 40,
      border: `2px solid ${inputBorderColorDefault}`,
      boxShadow: "none",
    },
    "& .ReactInputVerificationCode__item.is-active": {
      boxShadow: "none",
      border: `2px solid ${darkBlue}`,
    },
  },
  confirmError: {
    "& .ReactInputVerificationCode__item": {
      borderRadius: 10,
      color: darkRed,
      border: `2px solid ${darkRed} !important`,
      boxShadow: "none",
    },
    "& .ReactInputVerificationCode__item.is-active": {
      boxShadow: "none",
      border: `2px solid ${darkRed}`,
    },
  },
  error: {
    fontSize: 10,
    textAlign: currentDir === "rtl" ? "right" : "left",
  },
  receive: {
    color: orange,
    fontSize: 16,
    margin: 8,
  },
  resend: {
    color: orange,
    marginTop: "6px",
    textDecoration: "underline",
    "& .MuiButton-label": {
      textDecoration: "underline",
      fontSize: 16,
    },
    "&:hover": {
      textDecoration: "underline",
    },
  },
  backToRegister: {
    fontSize: 16,
    marginTop: 6,
  },
}));
