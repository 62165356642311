import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import React, { FC } from "react";
import { ProductAction } from "./ProductAction";
import { styles } from "./styles";
import { ProductCardProps } from "./type";

const ProductCard: FC<ProductCardProps> = ({
  classes,
  name,
  img,
  description,
  category,
  currency,
  price,
  onAddToCart: handleAddToCart,
  onLikeProduct: handleLikeProduct,
}) => {
  return (
    <div className={classes.root}>
      <div className={classes.img}>{img}</div>
      {/* <img src={img} alt='img' className={classes.img} />*/}
      <Typography className={classes.category}>{category}</Typography>
      <Typography variant='h6'>{name}</Typography>
      <Typography className={classes.description}>{description}</Typography>
      <Divider className={classes.divider} />

      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Box display='flex'>
          <Typography className={classes.bold}>{currency + " " + price}</Typography>
        </Box>
        <ProductAction classes={classes} onAddToCart={handleAddToCart} onLikeProduct={handleLikeProduct} />
      </Box>
    </div>
  );
};

export default withStyles(styles)(ProductCard);
