/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { CategoryClientFragmentFragmentDoc } from '../../../../fragment/clients/categoriesClient/__generated__/categoriesClient';
import { PageInfoFragmentFragmentDoc } from '../../../../fragment/PageInfo/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CategoriesClientQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  level?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.CategoryFilterInput>;
}>;


export type CategoriesClientQuery = { __typename?: 'Query', categories?: { __typename?: 'CategoryCountableConnection', totalCount?: number | null, edges: Array<{ __typename?: 'CategoryCountableEdge', node: { __typename?: 'Category', id: string, name?: string | null, level: number, description?: string | null, seoTitle?: string | null, backgroundImage?: { __typename?: 'Image', alt?: string | null, url?: string | null } | null, icon?: { __typename?: 'Image', alt?: string | null, url?: string | null } | null } }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } } | null };


export const CategoriesClientDocument = gql`
    query categoriesClient($first: Int, $after: String, $before: String, $last: Int, $level: Int, $filter: CategoryFilterInput) {
  categories(
    first: $first
    after: $after
    last: $last
    before: $before
    filter: $filter
    level: $level
  ) {
    edges {
      node {
        ...CategoryClientFragment
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
    totalCount
  }
}
    ${CategoryClientFragmentFragmentDoc}
${PageInfoFragmentFragmentDoc}`;

/**
 * __useCategoriesClientQuery__
 *
 * To run a query within a React component, call `useCategoriesClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesClientQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      level: // value for 'level'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCategoriesClientQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesClientQuery, CategoriesClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesClientQuery, CategoriesClientQueryVariables>(CategoriesClientDocument, options);
      }
export function useCategoriesClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesClientQuery, CategoriesClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesClientQuery, CategoriesClientQueryVariables>(CategoriesClientDocument, options);
        }
export type CategoriesClientQueryHookResult = ReturnType<typeof useCategoriesClientQuery>;
export type CategoriesClientLazyQueryHookResult = ReturnType<typeof useCategoriesClientLazyQuery>;
export type CategoriesClientQueryResult = Apollo.QueryResult<CategoriesClientQuery, CategoriesClientQueryVariables>;