import { makeStyles } from "tss-react/mui";
import { green } from "../../customization/colors";

export const useAddNewSubscriptionPlansStyle = makeStyles()({
  newSubscriptionContainer: {
    height: 400,
    padding: 10,
    justifyContent: "flex",
  },
  addContainer: {
    textAlign: "center",
  },
  addText: {
    fontSize: 22,
    cursor: "pointer",
  },
  card: {
    position: "relative",
  },
  addIcon: {
    color: green,
    height: 108,
    width: 108,
    opacity: 1,
    cursor: "pointer",
    marginBottom: 16,
  },
});
