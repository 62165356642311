import { useTranslation } from "@health/i18n";
import { AppRoleTypes, AppTypes, Branch, formatMessageErrors, useUserUpdateMutation } from "@health/queries";
import { CustomToggleButton, useAddToast } from "@health/ui";
import React, { FC } from "react";
type ActiveToggleProps = {
  id: string;
  isActive: boolean;
  branches: Branch[];
  appRole: string;
  appType: string;
};

export const ActiveToggleButton: FC<ActiveToggleProps> = ({ id, isActive, branches, appRole, appType }) => {
  const { t } = useTranslation("Admin");
  const [checked, setChecked] = React.useState(isActive);
  const { succeeded, failed } = useAddToast();
  const getBranchesWithId = branches?.map(branch => branch?.id);

  const [updateUser] = useUserUpdateMutation({
    onCompleted: data => {
      const errors = data?.userUpdate?.accountErrors;
      if (errors && errors.length > 0) {
        const error = formatMessageErrors(errors);
        failed(error);
      } else {
        setChecked(data?.userUpdate?.user?.isActive as boolean);
        succeeded(t("User Updated Successfully"));
      }
    },
    onError: () => {
      failed(t("User Update Failed"));
    },
  });

  const handleChangeActivity = event => {
    const isChecked: boolean = event.target.checked;
    const isUser = appRole === AppRoleTypes.User && appType === AppTypes.Vendor;
    updateUser({
      variables: {
        id: id,
        input: {
          isActive: isChecked,
          branches: isUser ? getBranchesWithId : [],
        },
      },
    });
  };

  return <CustomToggleButton checked={checked} onChange={handleChangeActivity} />;
};
