import { CustomTabs, useIsMobileView } from "@health/ui";
import React, { FC } from "react";
import { useDialogConsumerStyle } from "../styles/useDialogConsumerStyle";
import { TabsProps } from "./types";

export const Tabs: FC<TabsProps> = ({ items }) => {
  const { classes } = useDialogConsumerStyle();
  const isMobile = useIsMobileView();
  return (
    <CustomTabs
      items={items}
      TabListProps={{
        classes: {
          flexContainer: isMobile ? `${classes.tabs} ${classes.tabsMobile}` : classes.tabs,
        },
      }}
    />
  );
};
