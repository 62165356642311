/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RatePharmacyMutationVariables = Types.Exact<{
  input: Types.VendorRatingInput;
}>;


export type RatePharmacyMutation = { __typename?: 'Mutation', rateVendor?: { __typename?: 'RateVendor', vendorErrors: Array<{ __typename?: 'VendorError', field?: string | null, message?: string | null }>, vendorRating?: { __typename?: 'VendorRating', id: string, rating: number } | null } | null };


export const RatePharmacyDocument = gql`
    mutation ratePharmacy($input: VendorRatingInput!) {
  rateVendor(input: $input) {
    vendorErrors {
      field
      message
    }
    vendorRating {
      id
      rating
    }
  }
}
    `;
export type RatePharmacyMutationFn = Apollo.MutationFunction<RatePharmacyMutation, RatePharmacyMutationVariables>;

/**
 * __useRatePharmacyMutation__
 *
 * To run a mutation, you first call `useRatePharmacyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRatePharmacyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ratePharmacyMutation, { data, loading, error }] = useRatePharmacyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRatePharmacyMutation(baseOptions?: Apollo.MutationHookOptions<RatePharmacyMutation, RatePharmacyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RatePharmacyMutation, RatePharmacyMutationVariables>(RatePharmacyDocument, options);
      }
export type RatePharmacyMutationHookResult = ReturnType<typeof useRatePharmacyMutation>;
export type RatePharmacyMutationResult = Apollo.MutationResult<RatePharmacyMutation>;
export type RatePharmacyMutationOptions = Apollo.BaseMutationOptions<RatePharmacyMutation, RatePharmacyMutationVariables>;