/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { ProductBrandFragmentFragmentDoc } from '../../../../fragment/ProductBrand/__generated__/ProductBrand';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductBrandDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type ProductBrandDeleteMutation = { __typename?: 'Mutation', productBrandDelete?: { __typename?: 'ProductBrandDelete', productBrand?: { __typename?: 'ProductBrand', name: string, id: string } | null, productErrors: Array<{ __typename?: 'ProductError', field?: string | null, message?: string | null }> } | null };


export const ProductBrandDeleteDocument = gql`
    mutation ProductBrandDelete($id: ID!) {
  productBrandDelete(id: $id) {
    productBrand {
      ...ProductBrandFragment
    }
    productErrors {
      field
      message
    }
  }
}
    ${ProductBrandFragmentFragmentDoc}`;
export type ProductBrandDeleteMutationFn = Apollo.MutationFunction<ProductBrandDeleteMutation, ProductBrandDeleteMutationVariables>;

/**
 * __useProductBrandDeleteMutation__
 *
 * To run a mutation, you first call `useProductBrandDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductBrandDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productBrandDeleteMutation, { data, loading, error }] = useProductBrandDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductBrandDeleteMutation(baseOptions?: Apollo.MutationHookOptions<ProductBrandDeleteMutation, ProductBrandDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductBrandDeleteMutation, ProductBrandDeleteMutationVariables>(ProductBrandDeleteDocument, options);
      }
export type ProductBrandDeleteMutationHookResult = ReturnType<typeof useProductBrandDeleteMutation>;
export type ProductBrandDeleteMutationResult = Apollo.MutationResult<ProductBrandDeleteMutation>;
export type ProductBrandDeleteMutationOptions = Apollo.BaseMutationOptions<ProductBrandDeleteMutation, ProductBrandDeleteMutationVariables>;