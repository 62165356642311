/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetVendorWebHookMutationVariables = Types.Exact<{
  inputs: Types.VendorWebhookInput;
}>;


export type SetVendorWebHookMutation = { __typename?: 'Mutation', setVendorWebHook?: { __typename?: 'VendorWebhook', errors?: Array<Types.IntegrationApiErrorCodes | null> | null, isActive?: boolean | null, secretKey?: string | null, vendorUrl?: string | null, vendor?: { __typename?: 'Vendor', id: string } | null } | null };


export const SetVendorWebHookDocument = gql`
    mutation setVendorWebHook($inputs: VendorWebhookInput!) {
  setVendorWebHook(vendorWebhook: $inputs) {
    errors
    isActive
    secretKey
    vendor {
      id
    }
    vendorUrl
  }
}
    `;
export type SetVendorWebHookMutationFn = Apollo.MutationFunction<SetVendorWebHookMutation, SetVendorWebHookMutationVariables>;

/**
 * __useSetVendorWebHookMutation__
 *
 * To run a mutation, you first call `useSetVendorWebHookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetVendorWebHookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setVendorWebHookMutation, { data, loading, error }] = useSetVendorWebHookMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useSetVendorWebHookMutation(baseOptions?: Apollo.MutationHookOptions<SetVendorWebHookMutation, SetVendorWebHookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetVendorWebHookMutation, SetVendorWebHookMutationVariables>(SetVendorWebHookDocument, options);
      }
export type SetVendorWebHookMutationHookResult = ReturnType<typeof useSetVendorWebHookMutation>;
export type SetVendorWebHookMutationResult = Apollo.MutationResult<SetVendorWebHookMutation>;
export type SetVendorWebHookMutationOptions = Apollo.BaseMutationOptions<SetVendorWebHookMutation, SetVendorWebHookMutationVariables>;