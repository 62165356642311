import { useEffect } from "react";
import { SubscribeFn } from "./types";
import { useNotificationService } from "./useNotificationService";

export const useSubscribeToNotificationTopic: SubscribeFn = (topic, listener) => {
  const { subscribe, unSubscribe } = useNotificationService();

  useEffect(() => {
    unSubscribe();
    subscribe(topic, listener);
    return () => {
      unSubscribe();
    };
  }, [listener, topic]);
};
