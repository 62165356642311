/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { LanguageDisplayFragmentDoc } from '../../languageDisplay/__generated__/languageDisplay';
export type AddressFragmentFragment = { __typename: 'Address', id: string, name?: string | null, area?: string | null, streetAddress1?: string | null, streetAddress2?: string | null, buildingName?: string | null, buildingNumber?: string | null, phone?: string | null, isDefaultShippingAddress?: boolean | null, region?: string | null, city: { __typename?: 'City', id: string, name: string, nameAr?: string | null }, coordinates?: { __typename?: 'LocationType', lng?: number | null, lat?: number | null } | null, translations?: Array<{ __typename?: 'AddressTranslation', streetAddress1: string, streetAddress2: string, area: string, language: { __typename?: 'LanguageDisplay', code: Types.LanguageCodeEnum, language: string } } | null> | null };

export const AddressFragmentFragmentDoc = gql`
    fragment AddressFragment on Address {
  __typename
  id
  name
  area
  streetAddress1
  streetAddress2
  buildingName
  buildingNumber
  phone
  isDefaultShippingAddress
  city {
    id
    name
    nameAr
  }
  region
  area
  coordinates {
    lng
    lat
  }
  translations {
    streetAddress1
    streetAddress2
    area
    language {
      ...languageDisplay
    }
  }
}
    ${LanguageDisplayFragmentDoc}`;