/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductVariantUpdateMutationVariables = Types.Exact<{
  input: Types.ProductVariantInput;
  id: Types.Scalars['ID'];
}>;


export type ProductVariantUpdateMutation = { __typename?: 'Mutation', productVariantUpdate?: { __typename?: 'ProductVariantUpdate', productVariant?: { __typename?: 'ProductVariant', id: string, name?: string | null } | null, productErrors: Array<{ __typename?: 'ProductError', field?: string | null, message?: string | null }> } | null };


export const ProductVariantUpdateDocument = gql`
    mutation productVariantUpdate($input: ProductVariantInput!, $id: ID!) {
  productVariantUpdate(input: $input, id: $id) {
    productVariant {
      id
      name
    }
    productErrors {
      field
      message
    }
  }
}
    `;
export type ProductVariantUpdateMutationFn = Apollo.MutationFunction<ProductVariantUpdateMutation, ProductVariantUpdateMutationVariables>;

/**
 * __useProductVariantUpdateMutation__
 *
 * To run a mutation, you first call `useProductVariantUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductVariantUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productVariantUpdateMutation, { data, loading, error }] = useProductVariantUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductVariantUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ProductVariantUpdateMutation, ProductVariantUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductVariantUpdateMutation, ProductVariantUpdateMutationVariables>(ProductVariantUpdateDocument, options);
      }
export type ProductVariantUpdateMutationHookResult = ReturnType<typeof useProductVariantUpdateMutation>;
export type ProductVariantUpdateMutationResult = Apollo.MutationResult<ProductVariantUpdateMutation>;
export type ProductVariantUpdateMutationOptions = Apollo.BaseMutationOptions<ProductVariantUpdateMutation, ProductVariantUpdateMutationVariables>;