import { bg, makeStyles } from "@health/ui";

export const useAddressFormDialogStyles = makeStyles()({
  container: {
    width: "100%",
    position: "relative",
    backgroundColor: bg,
    display: "flex",
    justifyContent: "center",
  },

  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 20px",
  },
  actions: {
    padding: "15px",
    boxShadow: " 0px 0px 6px #0000001A",
    borderRadius: "0px 0px 15px 15px",
    justifyContent: "flex-start",
  },
});
