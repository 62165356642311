import { useTranslation } from "@health/i18n";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Checkbox, Typography } from "@mui/material";
import React, { FC } from "react";
import { darkBlue } from "../../customization";
import { CustomMuiDialog } from "../CustomMuiDialog";
import { StyledIconButton } from "../StaticFilterDialog/StaticFilterDialog.style";
import { TableSettingsProps } from "./types";

const TableSettings: FC<TableSettingsProps> = ({ columns, onToggleColumnVisibility, onToggleAllColumnsVisibility }) => {
  const { t } = useTranslation();

  const editableColumns = columns?.filter(item => {
    return !item.hideFromSettings;
  });

  const areAllColumnsVisible = editableColumns
    ?.filter(item => {
      return !item.disableToggleVisibility;
    })
    .every(col => !col.isHidden);

  const handleToggleAllColumnsVisibility = () => {
    onToggleAllColumnsVisibility(areAllColumnsVisible);
  };

  const handleToggleColumnVisibility = column => {
    onToggleColumnVisibility(column);
  };

  return (
    <React.Fragment>
      <CustomMuiDialog
        button={
          <StyledIconButton>
            <MoreVertIcon />
          </StyledIconButton>
        }
        DialogTitleProps={{
          title: t("Table Setting"),
        }}
        maxWidth='sm'
      >
        <Box display='flex' alignItems='center'>
          <Checkbox edge='start' checked={areAllColumnsVisible} onClick={handleToggleAllColumnsVisibility} />
          <Typography fontSize={14} fontWeight='bold' color={darkBlue}>
            {t("All")}
          </Typography>
        </Box>
        {editableColumns?.map(column => {
          return (
            <Box display='flex' alignItems='center' key={column.key}>
              <Checkbox
                edge='start'
                checked={!column.isHidden}
                disabled={column.disableToggleVisibility}
                onClick={() => handleToggleColumnVisibility(column)}
              />
              <Typography fontSize={14} fontWeight='bold' color={darkBlue}>
                {column.header}
              </Typography>
            </Box>
          );
        })}
      </CustomMuiDialog>
    </React.Fragment>
  );
};

export default TableSettings;
