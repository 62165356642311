import { getRequiredValidation, UserContext } from "@health/common";
import { useTranslation } from "@health/i18n";
import { LocationPicker } from "@health/maps";
import { FormControlLabel, getTranslationForObjProperty, Grid, Switch, TextField } from "@health/ui";
import React, { FC, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CitiesAutocomplete } from "../../Cities";
import { AnonymousAddressOption } from "../AddressPickerWithAnonymous";
import { useFormInputsStyles } from "./FormInputs.styles";
import { AddressInput, FormInputsProps } from "./FormInputs.types";

export const FormInputs: FC<FormInputsProps> = ({ address, isDefault, onIsDefaultAddress }) => {
  const { t } = useTranslation();
  const { classes } = useFormInputsStyles();
  const { isAuthenticated } = useContext(UserContext);
  const methods = useFormContext<AddressInput>();
  const {
    watch,
    register,
    setValue,
    getValues,
    control,
    formState: { errors, dirtyFields },
  } = methods;
  const location = watch("coordinates");

  const handleIsDefaultAddressChanged = (event, value: boolean) => {
    onIsDefaultAddress?.(value);
  };

  const handleLocationChange = locationInput => {
    setValue("streetAddress1", locationInput?.streetAddress1);
    setValue("name", locationInput?.name);
    setValue("coordinates", locationInput?.coordinates);
  };

  return (
    <Grid container spacing={2} sx={{ padding: [2, 3] }}>
      <LocationPicker
        containerStyle={{ padding: [2, 3] }}
        hasSearch
        defaultZoom={16}
        addressError={errors?.coordinates && t("Please select a location on the map, or Enable the location from browser settings")}
        location={location}
        containerClassName={classes.mapElement}
        onLocationChange={locationItem => handleLocationChange(locationItem)}
      />
      <Grid item xs={12}>
        <TextField
          InputLabelProps={{ shrink: !!getValues()?.name || dirtyFields?.name }}
          label={address?.id === AnonymousAddressOption.id && t("Address Name")}
          placeholder={t("Address Name")}
          size='small'
          fullWidth
          defaultValue={address?.id === AnonymousAddressOption.id ? AnonymousAddressOption?.name : address?.name}
          error={Boolean(errors?.name?.message)}
          helperText={errors?.name?.message}
          {...register("name", {
            required: getRequiredValidation(t, true),
          })}
        />
      </Grid>
      <Grid item xs={6} paddingLeft='9px' paddingTop='10px'>
        <CitiesAutocomplete
          rules={{ required: getRequiredValidation(t, true) }}
          cityItem={address?.city!}
          errors={errors}
          control={control}
          name='city'
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label={t("Area")}
          placeholder={t("Area")}
          size='small'
          fullWidth
          defaultValue={getTranslationForObjProperty(address, "area")}
          {...register("area")}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          InputLabelProps={{ shrink: !!getValues()?.streetAddress1 || dirtyFields?.streetAddress1 }}
          label={t("Street 1")}
          hiddenLabel
          placeholder={t("Street 1")}
          size='small'
          fullWidth
          defaultValue={getTranslationForObjProperty(address, "streetAddress1")}
          {...register("streetAddress1")}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label={t("Street 2")}
          hiddenLabel
          placeholder={t("Street 2")}
          size='small'
          fullWidth
          {...register("streetAddress2")}
          defaultValue={getTranslationForObjProperty(address, "streetAddress2")}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label={t("House Number")}
          hiddenLabel
          placeholder={t("House Number")}
          size='small'
          fullWidth
          defaultValue={address?.buildingNumber}
          {...register("buildingNumber")}
        />
      </Grid>
      {isAuthenticated && (
        <Grid container item xs={12} sm={6}>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch checked={isDefault} onChange={handleIsDefaultAddressChanged} name='isDefaultShippingAddress' color='success' />
              }
              label={t("Default Address")}
            />
          </Grid>
          {address?.id === "UNSAVED_ADDRESS" && (
            <Grid item xs={12} sm={6}>
              <FormControlLabel control={<Switch {...register("shouldSaveAddress")} color='success' />} label={t("Save Address")} />
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};
