import { OrderEventsEnum, OrdersTabQuery, useOrdersLifeCycleQuery } from "@health/queries";
import { getTranslatedNameField } from "@health/ui";
import { useState } from "react";
import { OrdersEventsType } from "./OrdersEvents.types";

export const useLifeCycleHooks = (prescriptionID, number) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  const handleChange = (e, value) => {
    setValue(value);
  };
  const getType = item => Object.keys(OrderEventsEnum).filter(status => OrderEventsEnum[status] === item);

  const { data, loading, fetchMore, refetch } = useOrdersLifeCycleQuery({
    variables: {
      first: 5,
      filter: {
        numbers: [String(number)],
        prescription: prescriptionID,
      },
    },
    skip: !open,
  });
  const ordersData = data?.orders?.edges?.map(order => ({
    ...order?.node,
    events: [
      ...(order?.node?.events?.map(event => ({
        ...event,
        branchName: getTranslatedNameField(order?.node?.branch),
        number: order?.node?.number,
        isParentEvent: true,
      })) || []),
      ...(order?.node?.children?.reduce((acc, child) => {
        // flatten/merge an events array
        child?.events?.forEach(
          event =>
            event &&
            acc.push({ ...event, number: child?.number, branchName: getTranslatedNameField(child?.branch)! || "", isParentEvent: false })
        );
        return acc;
      }, [] as OrdersEventsType) || []),
    ],
  }));
  const pageInfo = data?.orders?.pageInfo;

  const fetchMoreData = () => {
    if (pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          first: 5,
          after: pageInfo?.endCursor,
        },
        updateQuery: (prev: OrdersTabQuery, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return Object.assign({}, prev, {
            ...fetchMoreResult,
            orders: {
              ...fetchMoreResult.orders,
              edges: [...(prev?.orders?.edges || []), ...(fetchMoreResult?.orders?.edges || [])],
            },
          });
        },
      });
    }
  };

  const handleSearch = () => {
    refetch({
      filter: {
        prescription: prescriptionID,
        numbers: value?.length ? [String(value)] : [String(number)],
      },
    });
  };

  return {
    open,
    value,
    getType,
    loading,
    ordersData,
    pageInfo,
    handleChange,
    handleSearch,
    fetchMoreData,
    handleClickOpen,
    handleClickClose,
  };
};
