/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductVariantImageAssignMutationVariables = Types.Exact<{
  imageId: Types.Scalars['ID'];
  variantId: Types.Scalars['ID'];
}>;


export type ProductVariantImageAssignMutation = { __typename?: 'Mutation', productVariantImageAssign?: { __typename?: 'ProductVariantImageAssign', image?: { __typename?: 'ProductImage', url: string, id: string, alt: string } | null, productErrors: Array<{ __typename?: 'ProductError', code: Types.ProductErrorCode, field?: string | null, message?: string | null }> } | null };


export const ProductVariantImageAssignDocument = gql`
    mutation productVariantImageAssign($imageId: ID!, $variantId: ID!) {
  productVariantImageAssign(imageId: $imageId, variantId: $variantId) {
    image {
      url
      id
      alt
    }
    productErrors {
      code
      field
      message
    }
  }
}
    `;
export type ProductVariantImageAssignMutationFn = Apollo.MutationFunction<ProductVariantImageAssignMutation, ProductVariantImageAssignMutationVariables>;

/**
 * __useProductVariantImageAssignMutation__
 *
 * To run a mutation, you first call `useProductVariantImageAssignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductVariantImageAssignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productVariantImageAssignMutation, { data, loading, error }] = useProductVariantImageAssignMutation({
 *   variables: {
 *      imageId: // value for 'imageId'
 *      variantId: // value for 'variantId'
 *   },
 * });
 */
export function useProductVariantImageAssignMutation(baseOptions?: Apollo.MutationHookOptions<ProductVariantImageAssignMutation, ProductVariantImageAssignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductVariantImageAssignMutation, ProductVariantImageAssignMutationVariables>(ProductVariantImageAssignDocument, options);
      }
export type ProductVariantImageAssignMutationHookResult = ReturnType<typeof useProductVariantImageAssignMutation>;
export type ProductVariantImageAssignMutationResult = Apollo.MutationResult<ProductVariantImageAssignMutation>;
export type ProductVariantImageAssignMutationOptions = Apollo.BaseMutationOptions<ProductVariantImageAssignMutation, ProductVariantImageAssignMutationVariables>;