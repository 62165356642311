/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConfirmPasswordRestOtpMutationVariables = Types.Exact<{
  mobile: Types.Scalars['String'];
  sessionToken: Types.Scalars['String'];
  token: Types.Scalars['String'];
}>;


export type ConfirmPasswordRestOtpMutation = { __typename?: 'Mutation', confirmPasswordRestOtp?: { __typename?: 'ConfirmPasswordRestOTP', success?: boolean | null, token?: string | null, accountErrors: Array<{ __typename?: 'AccountError', message?: string | null, field?: string | null }> } | null };


export const ConfirmPasswordRestOtpDocument = gql`
    mutation confirmPasswordRestOtp($mobile: String!, $sessionToken: String!, $token: String!) {
  confirmPasswordRestOtp(
    mobile: $mobile
    sessionToken: $sessionToken
    token: $token
  ) {
    success
    token
    accountErrors {
      message
      field
    }
  }
}
    `;
export type ConfirmPasswordRestOtpMutationFn = Apollo.MutationFunction<ConfirmPasswordRestOtpMutation, ConfirmPasswordRestOtpMutationVariables>;

/**
 * __useConfirmPasswordRestOtpMutation__
 *
 * To run a mutation, you first call `useConfirmPasswordRestOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmPasswordRestOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmPasswordRestOtpMutation, { data, loading, error }] = useConfirmPasswordRestOtpMutation({
 *   variables: {
 *      mobile: // value for 'mobile'
 *      sessionToken: // value for 'sessionToken'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmPasswordRestOtpMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmPasswordRestOtpMutation, ConfirmPasswordRestOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmPasswordRestOtpMutation, ConfirmPasswordRestOtpMutationVariables>(ConfirmPasswordRestOtpDocument, options);
      }
export type ConfirmPasswordRestOtpMutationHookResult = ReturnType<typeof useConfirmPasswordRestOtpMutation>;
export type ConfirmPasswordRestOtpMutationResult = Apollo.MutationResult<ConfirmPasswordRestOtpMutation>;
export type ConfirmPasswordRestOtpMutationOptions = Apollo.BaseMutationOptions<ConfirmPasswordRestOtpMutation, ConfirmPasswordRestOtpMutationVariables>;