import { MuiDrawer, ShowButton } from "@health/ui";
import React, { FC } from "react";

type LifeCycleDrawerProps = {
  open: boolean;
  onClickClose: () => void;
  onClickOpen: () => void;
};

export const LifeCycleDrawerComponents: FC<LifeCycleDrawerProps> = ({
  children,
  open: isOpen,
  onClickClose: handleClickClose,
  onClickOpen: handleClickOpen,
}) => {
  return (
    <>
      <ShowButton onClick={handleClickOpen} />
      {isOpen && (
        <MuiDrawer
          anchor='right'
          open={isOpen}
          onClose={handleClickClose}
          PaperProps={{
            sx: {
              width: 554,
            },
          }}
        >
          {children}
        </MuiDrawer>
      )}
    </>
  );
};
