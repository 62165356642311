/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type ErrorsFragmentFragment = { __typename?: 'Error', field?: string | null, message?: string | null };

export const ErrorsFragmentFragmentDoc = gql`
    fragment ErrorsFragment on Error {
  field
  message
}
    `;