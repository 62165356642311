/* eslint-disable */
/* prettier-ignore */
import * as Types from '@health/queries/dist/graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteDecisionMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteDecisionMutation = { __typename?: 'Mutation', deleteDecision?: { __typename: 'Decision', id?: string | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', field?: string | null, code?: Types.DecisionMakerErrorCodes | null, message?: string | null } | null> | null } | null };


export const DeleteDecisionDocument = gql`
    mutation deleteDecision($id: ID!) {
  deleteDecision(id: $id) {
    __typename
    id
    errors {
      field
      code
      message
    }
  }
}
    `;
export type DeleteDecisionMutationFn = Apollo.MutationFunction<DeleteDecisionMutation, DeleteDecisionMutationVariables>;

/**
 * __useDeleteDecisionMutation__
 *
 * To run a mutation, you first call `useDeleteDecisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDecisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDecisionMutation, { data, loading, error }] = useDeleteDecisionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDecisionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDecisionMutation, DeleteDecisionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDecisionMutation, DeleteDecisionMutationVariables>(DeleteDecisionDocument, options);
      }
export type DeleteDecisionMutationHookResult = ReturnType<typeof useDeleteDecisionMutation>;
export type DeleteDecisionMutationResult = Apollo.MutationResult<DeleteDecisionMutation>;
export type DeleteDecisionMutationOptions = Apollo.BaseMutationOptions<DeleteDecisionMutation, DeleteDecisionMutationVariables>;