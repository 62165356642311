/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../fragment/PageInfo/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketPlaceOrderTrackingQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.OrderFilterInput>;
  sortBy?: Types.InputMaybe<Types.OrderSortingInput>;
}>;


export type MarketPlaceOrderTrackingQuery = { __typename?: 'Query', orders?: { __typename?: 'OrderCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'OrderCountableEdge', node: { __typename?: 'Order', id: string, created: any, number?: string | null, status: Types.OrderStatus, deliveryStatus?: string | null, lines: Array<{ __typename?: 'OrderLine', id: string, quantity: number, variantName: string, productName: string, variant?: { __typename?: 'ProductVariant', sku: string, product?: { __typename?: 'Product', images?: Array<{ __typename?: 'ProductImage', id: string, url: string } | null> | null } | null } | null } | null>, user?: { __typename?: 'User', firstName?: string | null, lastName?: string | null, mobile?: string | null } | null, branch?: { __typename?: 'Branch', name: string, nameAr?: string | null, contactNumber?: string | null, vendor: { __typename?: 'Vendor', name: string, nameAr?: string | null } } | null, children?: Array<{ __typename?: 'Order', number?: string | null, status: Types.OrderStatus, type?: Types.OrderTypesEnum | null } | null> | null, total?: { __typename?: 'TaxedMoney', gross: { __typename?: 'Money', amount: number, currency: string } } | null, shippingAddress?: { __typename?: 'OrderAddress', id: string, streetAddress1?: string | null, streetAddress2?: string | null, postalCode: string, phone: string, city?: { __typename?: 'City', name: string, nameAr?: string | null } | null } | null, shipmentInformation?: { __typename?: 'DeliveryShipmentGetShipmentResponseResult', data?: { __typename?: 'DeliveryShipmentGetShipmentResponse', deliveryWorkflowId?: string | null } | null } | null } }> } | null };

export type MarketPlaceTrackingOrderFragment = { __typename?: 'OrderLine', id: string, quantity: number, variantName: string, productName: string, variant?: { __typename?: 'ProductVariant', sku: string, product?: { __typename?: 'Product', images?: Array<{ __typename?: 'ProductImage', id: string, url: string } | null> | null } | null } | null };

export const MarketPlaceTrackingOrderFragmentDoc = gql`
    fragment MarketPlaceTrackingOrder on OrderLine {
  id
  quantity
  variantName
  productName
  variant {
    sku
    product {
      images {
        id
        url
      }
    }
  }
}
    `;
export const MarketPlaceOrderTrackingDocument = gql`
    query marketPlaceOrderTracking($first: Int, $after: String, $before: String, $last: Int, $filter: OrderFilterInput, $sortBy: OrderSortingInput) {
  orders(
    filter: $filter
    first: $first
    last: $last
    after: $after
    before: $before
    sortBy: $sortBy
  ) {
    totalCount
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        lines {
          ...MarketPlaceTrackingOrder
        }
        id
        created
        number
        status
        deliveryStatus
        user {
          firstName
          lastName
          mobile
        }
        branch {
          name
          nameAr
          vendor {
            name
            nameAr
          }
          contactNumber
        }
        children {
          number
          status
          type
        }
        total {
          gross {
            amount
            currency
          }
        }
        shippingAddress {
          id
          streetAddress1
          streetAddress2
          postalCode
          city {
            name
            nameAr
          }
          phone
        }
        shipmentInformation {
          data {
            deliveryWorkflowId
          }
        }
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}
${MarketPlaceTrackingOrderFragmentDoc}`;

/**
 * __useMarketPlaceOrderTrackingQuery__
 *
 * To run a query within a React component, call `useMarketPlaceOrderTrackingQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketPlaceOrderTrackingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketPlaceOrderTrackingQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useMarketPlaceOrderTrackingQuery(baseOptions?: Apollo.QueryHookOptions<MarketPlaceOrderTrackingQuery, MarketPlaceOrderTrackingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketPlaceOrderTrackingQuery, MarketPlaceOrderTrackingQueryVariables>(MarketPlaceOrderTrackingDocument, options);
      }
export function useMarketPlaceOrderTrackingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketPlaceOrderTrackingQuery, MarketPlaceOrderTrackingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketPlaceOrderTrackingQuery, MarketPlaceOrderTrackingQueryVariables>(MarketPlaceOrderTrackingDocument, options);
        }
export type MarketPlaceOrderTrackingQueryHookResult = ReturnType<typeof useMarketPlaceOrderTrackingQuery>;
export type MarketPlaceOrderTrackingLazyQueryHookResult = ReturnType<typeof useMarketPlaceOrderTrackingLazyQuery>;
export type MarketPlaceOrderTrackingQueryResult = Apollo.QueryResult<MarketPlaceOrderTrackingQuery, MarketPlaceOrderTrackingQueryVariables>;