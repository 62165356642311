import { makeStyles } from "@health/ui";

export const useNotificationsStyles = makeStyles()({
  container: {
    padding: 1,
    height: "79vh",
    overflow: "auto",
  },
  infinite: {
    overflow: "hidden !important",
  },
});
