/* eslint-disable react/jsx-handler-names */
import { getCurrentLocation } from "@health/common";
import { useTranslation } from "@health/i18n";
import { Box, CircularProgress, IconButton, MyLocationIcon, TextField as MuiTextField, Typography } from "@health/ui";
import { StandaloneSearchBox } from "@react-google-maps/api";
import React, { useEffect, useRef, useState } from "react";
import { useLocationHooks } from "../utils";
import { useGoogleMapSearchWrapperStyles } from "./styles";
import { IGoogleMapSearchProps } from "./types";

const GoogleMapSearchComponent = (props: IGoogleMapSearchProps) => {
  const { onLocationChange, defaultValue } = props;
  const { t } = useTranslation();
  const { getAddressFromLatLng } = useLocationHooks();
  const { classes } = useGoogleMapSearchWrapperStyles();
  const refs = useRef(null);
  const [places, setPlaces] = useState([]);
  const [address, setAddress] = useState(defaultValue || "");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setAddress(defaultValue || "");
  }, [defaultValue]);

  const handlePlacesChanged = () => {
    const newPlaces = (refs as any).current.getPlaces();
    setPlaces(places);
    if (newPlaces.length) {
      onLocationChange(
        {
          coordinates: {
            lat: newPlaces[0]?.geometry?.location.lat(),
            lng: newPlaces[0]?.geometry?.location.lng(),
          },
          city: getCityFromGoogleApi(newPlaces[0]),
          streetAddress1: newPlaces[0]?.formatted_address,
          name: newPlaces[0]?.name,
        },
        {
          source: "search",
        }
      );
      setAddress(newPlaces[0]?.formatted_address);
    }
  };

  const getCityFromGoogleApi = result => {
    let city;
    for (let i = 0; i < result.address_components.length; i++) {
      for (let b = 0; b < result.address_components[i].types.length; b++) {
        if (result.address_components[i].types[b] == "locality") {
          city = result.address_components[i];
          break;
        }
      }
    }
    return city?.long_name;
  };

  const handleGetMyLocation = async () => {
    try {
      setLoading(true);
      const location = await getCurrentLocation();
      await getAddressFromLatLng(location?.lat, location?.lng).then(response => {
        setLoading(false);
        onLocationChange(response, {
          source: "detect-my-location",
        });
      });
    } catch (error) {
      setLoading(false);
      alert(
        t("You did not allow location permissions, to enjoy this feature please go to settings and give your browser location permissions")
      );
    }
  };
  const handleSearchLocation = e => setAddress(e.target.value);
  return (
    <div className={classes.search}>
      <StandaloneSearchBox
        onLoad={instance => {
          refs.current = instance;
        }}
        onPlacesChanged={handlePlacesChanged}
      >
        <Box sx={{ position: "relative", width: "100%" }}>
          <MuiTextField
            placeholder={t("Enter Area")}
            hiddenLabel
            fullWidth
            value={address}
            className={classes.text}
            onChange={handleSearchLocation}
          />
          <IconButton onClick={handleGetMyLocation} className={classes.searchIcon}>
            {loading ? <CircularProgress size={20} color='inherit' /> : <MyLocationIcon color='inherit' />}
            {
              <Typography fontSize={12} m='0 5px' color='inherit' sx={{ display: "flex" }}>
                {t("Current Location", {
                  context: "MineButton",
                })}
              </Typography>
            }
          </IconButton>
        </Box>
      </StandaloneSearchBox>
      <Box sx={{ position: "absolute", zIndex: 4 }}>
        <ol>
          {places &&
            places.map(({ placeId, formattedAddress, geometry: { location } }) => (
              <li key={placeId}>
                {formattedAddress}
                {" at "}({location.lat()}, {location.lng()})
              </li>
            ))}
        </ol>
      </Box>
    </div>
  );
};
export default GoogleMapSearchComponent;
