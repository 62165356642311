/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { UserFragmentFragmentDoc } from '../../../../fragment/User/__generated__/User';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminUserByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type AdminUserByIdQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, email: string, firstName?: string | null, lastName?: string | null, mobile?: string | null, isStaff?: boolean | null, isSuperuser?: boolean | null, isVendorAdmin?: boolean | null, note?: string | null, healthLicenseNumber?: string | null, healthLicenseStartDate?: any | null, healthLicenseEndDate?: any | null, appType?: Types.AppTypes | null, appRole?: Types.AppRoleTypes | null, isActive: boolean, vendor?: { __typename?: 'Vendor', id: string, name: string } | null, branches?: Array<{ __typename?: 'Branch', id: string, name: string } | null> | null, permissionGroups?: Array<{ __typename?: 'Group', id: string, name: string, permissions?: Array<{ __typename?: 'Permission', name: string, code: Types.PermissionEnum } | null> | null } | null> | null } | null };


export const AdminUserByIdDocument = gql`
    query adminUserById($id: ID!) {
  user(id: $id) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useAdminUserByIdQuery__
 *
 * To run a query within a React component, call `useAdminUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminUserByIdQuery(baseOptions: Apollo.QueryHookOptions<AdminUserByIdQuery, AdminUserByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminUserByIdQuery, AdminUserByIdQueryVariables>(AdminUserByIdDocument, options);
      }
export function useAdminUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminUserByIdQuery, AdminUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminUserByIdQuery, AdminUserByIdQueryVariables>(AdminUserByIdDocument, options);
        }
export type AdminUserByIdQueryHookResult = ReturnType<typeof useAdminUserByIdQuery>;
export type AdminUserByIdLazyQueryHookResult = ReturnType<typeof useAdminUserByIdLazyQuery>;
export type AdminUserByIdQueryResult = Apollo.QueryResult<AdminUserByIdQuery, AdminUserByIdQueryVariables>;