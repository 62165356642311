import { useTranslation } from "@health/i18n";
import React, { FC } from "react";
import { EntryContainer } from "../../EntryContainer.component";
import { EntryLayout } from "../../EntryLayout.component";
import { ForgetPasswordForm } from "../../Form/ForgetPasswordForm";

export const ForgetPasswordPage: FC<any> = ({ app, onRedirect }) => {
  const { t } = useTranslation();

  const handleLogin = value => {
    onRedirect(value);
  };

  return (
    <EntryLayout>
      <EntryContainer title={t("Entry:Reset Password", "Reset Password")} buttonLabel='Log in' onClick={() => handleLogin("login")}>
        <ForgetPasswordForm app={app} onChangeStep={handleLogin} />
      </EntryContainer>
    </EntryLayout>
  );
};
