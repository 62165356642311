/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { AddressFragmentFragmentDoc } from '../../../../fragment/address/__generated__/address';
import { BranchFragmentFragmentDoc } from '../../../../fragment/branch/__generated__/branchFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BranchQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type BranchQuery = { __typename?: 'Query', branch?: { __typename?: 'Branch', type?: Types.BranchType | null, contactNumber?: string | null, contactEmail?: string | null, healthLicense?: string | null, healthLicenseStartDate?: any | null, healthLicenseEndDate?: any | null, acceptsDelivery: boolean, acceptsPickup: boolean, id: string, isActive: boolean, name: string, nameAr?: string | null, description: string, address?: { __typename: 'Address', id: string, name?: string | null, area?: string | null, streetAddress1?: string | null, streetAddress2?: string | null, buildingName?: string | null, buildingNumber?: string | null, phone?: string | null, isDefaultShippingAddress?: boolean | null, region?: string | null, city: { __typename?: 'City', id: string, name: string, nameAr?: string | null }, coordinates?: { __typename?: 'LocationType', lng?: number | null, lat?: number | null } | null, translations?: Array<{ __typename?: 'AddressTranslation', streetAddress1: string, streetAddress2: string, area: string, language: { __typename?: 'LanguageDisplay', code: Types.LanguageCodeEnum, language: string } } | null> | null } | null, workingHours?: Array<{ __typename?: 'WorkingHour', day?: Types.DayOfWeekEnum | null, openTimeRanges?: Array<{ __typename?: 'TimeRange', openTime?: any | null, closeTime?: any | null } | null> | null } | null> | null } | null };


export const BranchDocument = gql`
    query branch($id: ID!) {
  branch(id: $id) {
    address {
      ...AddressFragment
    }
    ...BranchFragment
    type
    contactNumber
    contactEmail
    healthLicense
    healthLicenseStartDate
    healthLicenseEndDate
    acceptsDelivery
    acceptsPickup
    workingHours {
      day
      openTimeRanges {
        openTime
        closeTime
      }
    }
  }
}
    ${AddressFragmentFragmentDoc}
${BranchFragmentFragmentDoc}`;

/**
 * __useBranchQuery__
 *
 * To run a query within a React component, call `useBranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBranchQuery(baseOptions: Apollo.QueryHookOptions<BranchQuery, BranchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BranchQuery, BranchQueryVariables>(BranchDocument, options);
      }
export function useBranchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BranchQuery, BranchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BranchQuery, BranchQueryVariables>(BranchDocument, options);
        }
export type BranchQueryHookResult = ReturnType<typeof useBranchQuery>;
export type BranchLazyQueryHookResult = ReturnType<typeof useBranchLazyQuery>;
export type BranchQueryResult = Apollo.QueryResult<BranchQuery, BranchQueryVariables>;