/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DownloadCodeSystemFileQueryVariables = Types.Exact<{
  code: Types.CodeSystemCode;
  codeSystemId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type DownloadCodeSystemFileQuery = { __typename?: 'Query', downloadCodeSystemFile?: string | null };


export const DownloadCodeSystemFileDocument = gql`
    query downloadCodeSystemFile($code: CodeSystemCode!, $codeSystemId: ID) {
  downloadCodeSystemFile(code: $code, codeSystemId: $codeSystemId)
}
    `;

/**
 * __useDownloadCodeSystemFileQuery__
 *
 * To run a query within a React component, call `useDownloadCodeSystemFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadCodeSystemFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadCodeSystemFileQuery({
 *   variables: {
 *      code: // value for 'code'
 *      codeSystemId: // value for 'codeSystemId'
 *   },
 * });
 */
export function useDownloadCodeSystemFileQuery(baseOptions: Apollo.QueryHookOptions<DownloadCodeSystemFileQuery, DownloadCodeSystemFileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadCodeSystemFileQuery, DownloadCodeSystemFileQueryVariables>(DownloadCodeSystemFileDocument, options);
      }
export function useDownloadCodeSystemFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadCodeSystemFileQuery, DownloadCodeSystemFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadCodeSystemFileQuery, DownloadCodeSystemFileQueryVariables>(DownloadCodeSystemFileDocument, options);
        }
export type DownloadCodeSystemFileQueryHookResult = ReturnType<typeof useDownloadCodeSystemFileQuery>;
export type DownloadCodeSystemFileLazyQueryHookResult = ReturnType<typeof useDownloadCodeSystemFileLazyQuery>;
export type DownloadCodeSystemFileQueryResult = Apollo.QueryResult<DownloadCodeSystemFileQuery, DownloadCodeSystemFileQueryVariables>;