const subscriptionsManagement = "subscriptions-management";
const newSubscription = "new";
const editSubscription = `edit/:id`;

export const subscriptionManagementRoutes = {
  name: "Subscription Management",
  path: subscriptionsManagement,
  subscriptions: {
    name: "Subscriptions",
    path: "",
    fullPath: [subscriptionsManagement].join("/"),
    new: {
      name: "New Subscription",
      path: [newSubscription].join("/"),
      fullPath: [subscriptionsManagement, newSubscription].join("/"),
    },
    edit: {
      name: "Edit Subscription",
      path: [editSubscription].join("/"),
      fullPath: [subscriptionsManagement, editSubscription].join("/"),
    },
  },
};
