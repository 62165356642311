import { useTranslation } from "@health/i18n";
import { Box, Typography } from "@health/ui";
import React, { FC } from "react";
import { DotBadge } from "../Badge/DotBadge.components";
import { getCodeSystemProcessingStatusColor } from "../medlistUtils";
import { MedListProps } from "./types";

export const MedList: FC<MedListProps> = props => {
  const { processingStatus, children } = props;
  const { t } = useTranslation("Admin");

  return (
    <>
      {!!processingStatus && (
        <Box display='inline'>
          <Typography display='inline' fontSize={"14px"}>
            {t("List Status")}: &nbsp; &nbsp;
          </Typography>
          <Typography color={getCodeSystemProcessingStatusColor(processingStatus)} display='inline' marginRight={1} fontSize={"14px"}>
            <DotBadge processingStatus={processingStatus} />
          </Typography>
        </Box>
      )}
      <>{children}</>
    </>
  );
};
