import type { EmotionCache } from "@emotion/cache";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { useTranslation } from "@health/i18n";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import React, { FC, useEffect, useState } from "react";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import { CustomTheme } from "./theme";

let muiCache: EmotionCache | undefined = undefined;

// Client-side cache, shared for the whole session of the user in the browser.
export const createMuiCache = isRtl =>
  (muiCache = createCache({ key: isRtl ? "mui-rtl" : "mui", prepend: true, stylisPlugins: isRtl ? [prefixer, rtlPlugin] : [] }));

export const AppThemeProvider: FC = ({ children }) => {
  const [appTheme, setAppTheme] = useState(CustomTheme);
  const { i18n } = useTranslation();
  const direction = i18n.dir(i18n.language);

  useEffect(() => {
    if (typeof window == "undefined") return;
    const i18nDefault = localStorage.getItem("locale-default");
    if (!i18nDefault) {
      i18n.changeLanguage("ar", () => {
        localStorage.setItem("locale-default", "ar");
        // is in consumer app!!!!
        if (!process.env.NEXT_PUBLIC_UPLOAD_SERVICE_URL) location.reload();
      });
    }
  }, []);
  useEffect(() => {
    if (typeof window !== "undefined") {
      document.dir = direction;
      document?.getElementsByTagName("html")?.[0]?.setAttribute("dir", direction);
      document?.getElementsByTagName("html")?.[0]?.setAttribute("lang", i18n.language);
      document?.getElementsByTagName("body")?.[0]?.setAttribute("dir", direction);
      setAppTheme({ ...appTheme, direction });
    }
  }, [direction]);

  return (
    <CacheProvider value={muiCache ?? createMuiCache(direction === "rtl")}>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </CacheProvider>
  );
};
