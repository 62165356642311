/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { ProductBrandFragmentFragmentDoc } from '../../../../fragment/ProductBrand/__generated__/ProductBrand';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductBrandUpdateMutationVariables = Types.Exact<{
  input: Types.ProductBrandInput;
  id: Types.Scalars['ID'];
}>;


export type ProductBrandUpdateMutation = { __typename?: 'Mutation', productBrandUpdate?: { __typename?: 'ProductBrandUpdate', productBrand?: { __typename?: 'ProductBrand', name: string, id: string } | null, productErrors: Array<{ __typename?: 'ProductError', field?: string | null, message?: string | null }> } | null };


export const ProductBrandUpdateDocument = gql`
    mutation ProductBrandUpdate($input: ProductBrandInput!, $id: ID!) {
  productBrandUpdate(input: $input, id: $id) {
    productBrand {
      ...ProductBrandFragment
    }
    productErrors {
      field
      message
    }
  }
}
    ${ProductBrandFragmentFragmentDoc}`;
export type ProductBrandUpdateMutationFn = Apollo.MutationFunction<ProductBrandUpdateMutation, ProductBrandUpdateMutationVariables>;

/**
 * __useProductBrandUpdateMutation__
 *
 * To run a mutation, you first call `useProductBrandUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductBrandUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productBrandUpdateMutation, { data, loading, error }] = useProductBrandUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductBrandUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ProductBrandUpdateMutation, ProductBrandUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductBrandUpdateMutation, ProductBrandUpdateMutationVariables>(ProductBrandUpdateDocument, options);
      }
export type ProductBrandUpdateMutationHookResult = ReturnType<typeof useProductBrandUpdateMutation>;
export type ProductBrandUpdateMutationResult = Apollo.MutationResult<ProductBrandUpdateMutation>;
export type ProductBrandUpdateMutationOptions = Apollo.BaseMutationOptions<ProductBrandUpdateMutation, ProductBrandUpdateMutationVariables>;