import { GraphQLError } from "graphql";

export const formatGraphQLError = (graphQLErrors: readonly GraphQLError[]): string => {
  return graphQLErrors
    ?.map(error => {
      return `${error.message || ""}`;
    })
    .filter(Boolean)
    .join(", ");
};

export const formatMessageErrors = errors => {
  return errors?.map(error => {
    return `${error.field ? error.field + ",\r\n" : ""} ${error.message || ""}\r\n`;
  });
};

export const formatTranslatedErrorMessages = (errors, t) => {
  return errors?.map(error => {
    return t(error.message) || "";
  });
};
