/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../fragment/PageInfo/__generated__/pageInfoFragment';
import { LanguageDisplayFragmentDoc } from '../../../../fragment/languageDisplay/__generated__/languageDisplay';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClientVendorsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.VendorFilterInput>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  sortBy?: Types.InputMaybe<Types.VendorOrder>;
  branchSortBy?: Types.InputMaybe<Types.BranchOrder>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  userLocation?: Types.InputMaybe<Types.LocationInput>;
}>;


export type ClientVendorsQuery = { __typename?: 'Query', vendors?: { __typename?: 'VendorCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'VendorCountableEdge', node: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null, description?: string | null, logo?: string | null, slug: string, isFavorite?: boolean | null, deliveryMinFrom: number, deliveryMinTo: number, backGroundImage?: string | null, address?: { __typename?: 'Address', area?: string | null, streetAddress1?: string | null, city: { __typename?: 'City', id: string, name: string, nameAr?: string | null }, translations?: Array<{ __typename?: 'AddressTranslation', area: string, streetAddress1: string, language: { __typename?: 'LanguageDisplay', code: Types.LanguageCodeEnum, language: string } } | null> | null } | null, branches?: { __typename?: 'BranchCountableConnection', edges: Array<{ __typename?: 'BranchCountableEdge', node: { __typename?: 'Branch', id: string } }> } | null } }> } | null };


export const ClientVendorsDocument = gql`
    query ClientVendors($first: Int, $filter: VendorFilterInput, $after: String, $sortBy: VendorOrder, $branchSortBy: BranchOrder, $before: String, $last: Int, $userLocation: LocationInput) {
  vendors(
    first: $first
    filter: $filter
    after: $after
    sortBy: $sortBy
    before: $before
    last: $last
    userLocation: $userLocation
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        id
        name
        nameAr
        description
        logo
        address {
          area
          streetAddress1
          city {
            id
            name
            nameAr
          }
          translations {
            area
            streetAddress1
            language {
              ...languageDisplay
            }
          }
        }
        slug
        isFavorite
        deliveryMinFrom
        deliveryMinTo
        backGroundImage
        branches(
          first: 1
          userLocation: $userLocation
          sortBy: $branchSortBy
          filter: {type: PHARMACY}
        ) {
          edges {
            node {
              id
            }
          }
        }
      }
    }
    totalCount
  }
}
    ${PageInfoFragmentFragmentDoc}
${LanguageDisplayFragmentDoc}`;

/**
 * __useClientVendorsQuery__
 *
 * To run a query within a React component, call `useClientVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientVendorsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *      after: // value for 'after'
 *      sortBy: // value for 'sortBy'
 *      branchSortBy: // value for 'branchSortBy'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      userLocation: // value for 'userLocation'
 *   },
 * });
 */
export function useClientVendorsQuery(baseOptions?: Apollo.QueryHookOptions<ClientVendorsQuery, ClientVendorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientVendorsQuery, ClientVendorsQueryVariables>(ClientVendorsDocument, options);
      }
export function useClientVendorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientVendorsQuery, ClientVendorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientVendorsQuery, ClientVendorsQueryVariables>(ClientVendorsDocument, options);
        }
export type ClientVendorsQueryHookResult = ReturnType<typeof useClientVendorsQuery>;
export type ClientVendorsLazyQueryHookResult = ReturnType<typeof useClientVendorsLazyQuery>;
export type ClientVendorsQueryResult = Apollo.QueryResult<ClientVendorsQuery, ClientVendorsQueryVariables>;