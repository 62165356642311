import { getEnvVariable } from "@health/common";
import { useTranslation } from "@health/i18n";
import {
  ActiveFilters,
  CustomTable,
  CustomTableColumnProps,
  ExcelIcon,
  PageWrapper,
  PlusIcon,
  StaticFilterDialog,
  StyledButton,
  TableSettings,
} from "@health/ui";
import React from "react";
import { UploadDialog } from "shared/components";
import { usePrescriptionsPage } from "./Prescriptions.hook";

export const PrescriptionsPageScreen = () => {
  const { t } = useTranslation("Admin");
  const {
    filters,
    columns,
    prescriptions,
    isLoading,
    pageSize,
    pageInfo,
    totalCount,
    activeFilters,
    isFilterOpened,
    activeFiltersDisplay,
    isCreateLoading,
    importData,
    isUploadDialogOpened,
    handleOnUpload,
    onCloseImportDialog,
    handleGotoNext,
    handleGoToPrevious,
    handlePageSizeChange,
    handleSortData,
    handleApplyFilters,
    handleToggleColumnVisibility,
    handleSetAllColumnVisibility,
    handleToggleFilters,
    handleAddNewFailedOrder,
    handleOpenImport,
  } = usePrescriptionsPage();

  return (
    <PageWrapper
      start={<ActiveFilters onOpenFilters={handleToggleFilters} activeFilters={activeFiltersDisplay} i18nNs='Admin' />}
      actions={
        <>
          <StaticFilterDialog
            isOpen={isFilterOpened}
            onToggleDialog={handleToggleFilters}
            activeFilters={activeFilters}
            filterFields={filters}
            onApplyFilters={handleApplyFilters}
          />
          <TableSettings
            columns={columns! as CustomTableColumnProps<Record<string, unknown>>[]}
            onToggleColumnVisibility={handleToggleColumnVisibility}
            onToggleAllColumnsVisibility={handleSetAllColumnVisibility}
          />
          <StyledButton color='success' size='medium' type='submit' startIcon={<ExcelIcon color='inherit' />} onClick={handleOpenImport}>
            {t("Upload Orders")}
          </StyledButton>
          <StyledButton
            color='success'
            size='medium'
            type='submit'
            startIcon={<PlusIcon color='inherit' />}
            onClick={handleAddNewFailedOrder}
          >
            {t("Create CS Order")}
          </StyledButton>
        </>
      }
    >
      <CustomTable
        totalCount={totalCount}
        data={prescriptions || []}
        columns={columns}
        isLoading={isLoading}
        isEditVisible={false}
        isDeleteVisible={false}
        pageSize={pageSize}
        pageIndex={1}
        pagesCount={1}
        hasNextPage={pageInfo?.hasNextPage}
        hasPreviousPage={pageInfo?.hasPreviousPage}
        onGoToNext={handleGotoNext}
        onGoToPrevious={handleGoToPrevious}
        onPageSizeChange={handlePageSizeChange}
        onSortColumn={handleSortData}
      />
      <UploadDialog
        dialogLoading={isCreateLoading}
        isOpen={isUploadDialogOpened}
        onFileUploadedSuccessfully={handleOnUpload}
        isUploadSubmitting={isCreateLoading}
        handleClose={onCloseImportDialog}
        hasErrors={Boolean(importData?.uploadBulkFile?.errors?.length)}
        title={t("Upload CS Orders")}
        subTitle={t("Upload CS Orders you want here")}
        errorMessage={importData?.uploadBulkFile?.errors?.map(e => `${e?.field} : ${e?.message}`).join(", ")}
        templateDownloadLink={getEnvVariable("PRESCRIPTION_ORDER_DOWNLOAD")}
        templateDownloadFileName={"prescription_order_template.xlsx"}
        uploadServiceUrl={getEnvVariable("PRESCRIPTION_ORDER_UPLOAD")}
      />
    </PageWrapper>
  );
};
