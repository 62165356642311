import Box from "@mui/material/Box";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { withStyles, WithStyles } from "@mui/styles";
import React, { FC } from "react";
import { styles } from "./styles";

type CustomTextFieldProps = WithStyles<typeof styles> &
  TextFieldProps & {
    icon?: React.ReactNode;
    hasIcon?: boolean;
  };

const CustomTextField: FC<CustomTextFieldProps> = ({ classes, label, placeholder, icon, hasIcon = false, ...props }) => {
  return (
    <Box className={classes.container}>
      {hasIcon && (
        <div className={classes.iconContainer}>
          <div className={classes.iconBG} />
          <div className={classes.absolute}>{icon}</div>
        </div>
      )}
      <TextField className={classes.text} id='input-with-sx' label={label} placeholder={placeholder} {...props} />
    </Box>
  );
};

export default withStyles(styles)(CustomTextField);

CustomTextField.defaultProps = {
  icon: undefined,
  hasIcon: false,
};
