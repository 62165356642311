/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CitySettingsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type CitySettingsQuery = { __typename?: 'Query', city?: { __typename?: 'City', id: string, name: string, nameAr?: string | null, maxRadiusForPickup: number, maxNumberOfRounds?: number | null, maxNumberOfTries?: number | null, timeOutPeriod?: number | null, rounds?: Array<{ __typename?: 'CityRound', id: string, radius: number, maxNumberOfPharmacies: number, pharmaciesTypes?: Array<Types.VendorBranchTypeEnum | null> | null } | null> | null } | null };


export const CitySettingsDocument = gql`
    query citySettings($id: ID!) {
  city(id: $id) {
    id
    name
    nameAr
    maxRadiusForPickup
    maxNumberOfRounds
    maxNumberOfTries
    timeOutPeriod
    rounds {
      id
      radius
      maxNumberOfPharmacies
      pharmaciesTypes
    }
  }
}
    `;

/**
 * __useCitySettingsQuery__
 *
 * To run a query within a React component, call `useCitySettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCitySettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCitySettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCitySettingsQuery(baseOptions: Apollo.QueryHookOptions<CitySettingsQuery, CitySettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CitySettingsQuery, CitySettingsQueryVariables>(CitySettingsDocument, options);
      }
export function useCitySettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CitySettingsQuery, CitySettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CitySettingsQuery, CitySettingsQueryVariables>(CitySettingsDocument, options);
        }
export type CitySettingsQueryHookResult = ReturnType<typeof useCitySettingsQuery>;
export type CitySettingsLazyQueryHookResult = ReturnType<typeof useCitySettingsLazyQuery>;
export type CitySettingsQueryResult = Apollo.QueryResult<CitySettingsQuery, CitySettingsQueryVariables>;