/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type CityErrorsFragmentFragment = { __typename?: 'BlockError', field?: string | null, message?: string | null, code: Types.BlockErrorCode };

export const CityErrorsFragmentFragmentDoc = gql`
    fragment CityErrorsFragment on BlockError {
  field
  message
  code
}
    `;