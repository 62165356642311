import { useTranslation } from "@health/i18n";
import { Decision, DecisionMakerOrderDirection, DecisionSortField, formatMessageErrors, useDataGridState } from "@health/queries";
import { FieldsTypes, FilterField, handleSortGeneric, useToasts } from "@health/ui";
import { GetDecisionsQueryVariables, useDeleteDecisionMutation, useGetDecisionsQuery } from "admin-gql";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useReportAnError from "shared/hooks/useLogError";
import { decisionFormRelative } from "../route";
import { getColumns } from "./PatientEligibilityPlanColumns";

const usePatientEligibilityDecisionHook = () => {
  const { t } = useTranslation("Admin");
  const [activeFilters, setActiveFilters] = useState<Record<string, any>>({});
  const [columns, setColumns] = useState(getColumns(t));
  const navigate = useNavigate();
  const inputs: GetDecisionsQueryVariables = {
    sortBy: {
      direction: DecisionMakerOrderDirection.Asc,
      field: DecisionSortField.Name,
    },
    first: 10,
    after: null,
    before: null,
    last: null,
  };

  const {
    data,
    isLoading,
    pageSize,
    doFilter,
    doChangePageSize,
    doGoToNext: handleGotoNext,
    doGoToPrevious: handleGoToPrevious,
    doSort,
  } = useDataGridState({
    useCustomQuery: useGetDecisionsQuery,
    input: inputs,
    size: 10,
  });
  const { addToast } = useToasts();
  const entity = data?.decisions;
  const items = (entity?.edges?.map(item => item?.node) as Decision[]) ?? [];
  const pageInfo = entity?.pageInfo;
  const totalCount = entity?.totalCount || 0;
  const { reportGraphQlErrors } = useReportAnError();

  const [deleteDecision] = useDeleteDecisionMutation();
  const handlePageSizeChange = (size: number): void => {
    doChangePageSize(size);
  };

  const fields: FilterField[] = [
    {
      type: FieldsTypes.input,
      name: "name",
      fieldProps: {
        defaultValue: activeFilters?.name,
        placeholder: t("Rule Name"),
        label: t("Rule Name"),
        name: "name",
      },
    },
    {
      type: FieldsTypes.checkbox,
      name: "isActive",
      fieldProps: {
        defaultChecked: activeFilters?.isActive,
        label: t("is Active"),
        name: "isActive",
      },
    },
  ];

  const handleApplyFilters = (_data: Record<string, string | unknown>) => {
    setActiveFilters(_data || {});
    doFilter(null, {
      ...inputs,
      decisionName: (_data?.name as string) || null,
      isActive: (Boolean(_data?.isActive) as boolean) || null,
    });
  };

  const handleDismissFilters = () => {
    setActiveFilters({});
  };
  const handleEditRow = (row: Decision) => {
    if (!row) return;
    navigate(decisionFormRelative + "/" + row.id);
  };
  const handleDeleteRow = (row: Decision) => {
    if (!row) return;
    deleteDecision({
      variables: {
        id: row!.id!,
      },
      onCompleted: e => {
        if (Number(e.deleteDecision?.errors?.length) > 0) {
          addToast(t("Failed to delete plan") + "\n" + formatMessageErrors(e.deleteDecision?.errors), {
            appearance: "error",
            autoDismiss: true,
          });
          return;
        }
        addToast(t("Deleted successfully"), {
          appearance: "success",
          autoDismiss: true,
        });
      },
      onError: ({ graphQLErrors }) => reportGraphQlErrors(t("Error deleting plan"))(graphQLErrors),
      update(cache) {
        const normalizedId = cache.identify({ id: row.id!, __typename: "Decision" });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
  };

  const handleAddItem = () => {
    navigate(decisionFormRelative);
  };
  const handleOnSort = handleSortGeneric({ setColumns, doSort });
  const [isFilterOpened, setIsFilterOpened] = useState(false);

  const handleToggleFilters = () => {
    setIsFilterOpened(state => !state);
  };
  return {
    columns,
    items,
    fields,
    pageSize,
    pageInfo,
    isLoading,
    totalCount,
    activeFilters,
    isFilterOpened,
    handleToggleFilters,
    handleOnSort,
    handleEditRow,
    handleAddItem,
    handleDeleteRow,
    handleGotoNext,
    handleGoToPrevious,
    handlePageSizeChange,
    handleApplyFilters,
    handleDismissFilters,
  };
};

export default usePatientEligibilityDecisionHook;
