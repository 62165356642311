import { useContext } from "react";
import { TablePreferencesContext } from "./TablePreferencesDialog.component";

export const useTablePreferencesContext = () => {
  const context = useContext(TablePreferencesContext);
  if (!context) {
    throw new Error("Sortable Item component cannot be used outside the Table Preferences Dialog component");
  }
  return context;
};
