/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type CityFragmentFragment = { __typename?: 'City', id: string, deliveryPriceAmount: number, name: string, nameAr?: string | null, created: any, modified: any, deliveryPrice?: { __typename?: 'Money', amount: number, currency: string } | null };

export const CityFragmentFragmentDoc = gql`
    fragment CityFragment on City {
  id
  deliveryPriceAmount
  name
  nameAr
  created
  modified
  deliveryPriceAmount
  deliveryPrice {
    amount
    currency
  }
}
    `;