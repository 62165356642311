/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../fragment/PageInfo/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChatCustomersListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.CustomerFilterInput>;
  branch: Types.Scalars['ID'];
}>;


export type ChatCustomersListQuery = { __typename?: 'Query', chatCustomersList?: { __typename?: 'UserCountableConnection', pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'UserCountableEdge', node: { __typename?: 'User', id: string, email: string, firstName?: string | null, lastName?: string | null, avatar?: string | null, lastMessage?: { __typename?: 'Message', content?: string | null, created: any, seenDate?: any | null, recipient?: { __typename?: 'User', id: string } | null } | null } }> } | null };


export const ChatCustomersListDocument = gql`
    query ChatCustomersList($first: Int, $after: String, $last: Int, $before: String, $filter: CustomerFilterInput, $branch: ID!) {
  chatCustomersList: customers(
    first: $first
    after: $after
    last: $last
    before: $before
    sortBy: {field: LAST_MESSAGE_SENT, direction: DESC}
    filter: $filter
    branch: $branch
    hasChatWith: true
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        id
        email
        firstName
        lastName
        avatar
        lastMessage {
          content
          created
          seenDate
          created
          recipient {
            id
          }
        }
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useChatCustomersListQuery__
 *
 * To run a query within a React component, call `useChatCustomersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatCustomersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatCustomersListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      branch: // value for 'branch'
 *   },
 * });
 */
export function useChatCustomersListQuery(baseOptions: Apollo.QueryHookOptions<ChatCustomersListQuery, ChatCustomersListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatCustomersListQuery, ChatCustomersListQueryVariables>(ChatCustomersListDocument, options);
      }
export function useChatCustomersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatCustomersListQuery, ChatCustomersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatCustomersListQuery, ChatCustomersListQueryVariables>(ChatCustomersListDocument, options);
        }
export type ChatCustomersListQueryHookResult = ReturnType<typeof useChatCustomersListQuery>;
export type ChatCustomersListLazyQueryHookResult = ReturnType<typeof useChatCustomersListLazyQuery>;
export type ChatCustomersListQueryResult = Apollo.QueryResult<ChatCustomersListQuery, ChatCustomersListQueryVariables>;