import { useTranslation } from "@health/i18n";
import { Attribute, ProductVariant } from "@health/queries";
import { Checkbox, FormActions, FormCard, FormControlLabel, Grid, PageWrapper } from "@health/ui";
import React, { FC, useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { formGirdSpacing } from "shared/constants";
import InventoryCardComponent from "../../../Form/AttributeSection/InventoryCard.component";
import { ProductVariantImages } from "./ProductVariantImages/ProductVariantImages";
import { VariantFormData, VariantFormType } from "./type";
import VariantInformation from "./VariantInformation/VariantInformation";

const VariantForm: FC<VariantFormType> = ({
  defaultValue,
  onSubmitData,
  defaultProductData,
  isEdit = false,
  loading,
  errors,
  productVariant,
  isSubmitDisabled,
}) => {
  const { t } = useTranslation("Admin");
  const methods = useForm<VariantFormData>({
    mode: "onChange",
  });
  const { handleSubmit } = methods;

  useEffect(() => {
    if (!defaultValue) return;
    methods.setValue("trackInventory", !!defaultValue?.trackInventory);
    methods.setValue("sku", defaultValue?.sku!);
    methods.setValue("variants", defaultValue?.variants!);
  }, [defaultValue]);

  const onSubmitVariant = (formData: VariantFormData) => {
    if (isSubmitDisabled) {
      return;
    }
    onSubmitData({
      sku: formData?.sku,
      attributes: formData?.variants,
      trackInventory: formData?.trackInventory,
    });
  };
  const nav = useNavigate();
  const handleNavigationCancel = () => {
    if (productVariant) {
      nav(-2);
    } else {
      nav(-1);
    }
  };

  const isTrackInventoryChecked = productVariant?.productVariant?.trackInventory ?? true;

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitVariant)}>
          <PageWrapper
            actions={
              <FormActions
                hasEdit={isEdit}
                hasSave={!isEdit}
                hasCancel
                onEditItem={handleSubmit(onSubmitVariant)}
                onSave={handleSubmit(onSubmitVariant)}
                onNavigation={handleNavigationCancel}
                isSaveDisabled={isSubmitDisabled}
              />
            }
          >
            <Grid container spacing={formGirdSpacing}>
              <Grid item xs={12} margin={1}>
                <VariantInformation
                  isEdit={isEdit}
                  loading={loading}
                  defaultValue={defaultValue?.variants}
                  variantAttributes={(defaultProductData?.productType?.variantAttributes! as Attribute[]) || []}
                  variant={productVariant?.productVariant as unknown as ProductVariant}
                />
              </Grid>
            </Grid>
            <Grid container spacing={formGirdSpacing}>
              <Grid item xs={12} margin={1}>
                <FormCard loading={loading} doYouHaveData={true} title={t("Inventory")}>
                  <InventoryCardComponent errors={errors} defaultProductData={productVariant?.productVariant} />
                  <Controller
                    name='trackInventory'
                    render={({ field: { onChange: handleChange, value } }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked={isTrackInventoryChecked}
                            onChange={e => handleChange(e.target.checked)}
                            checked={value}
                          />
                        }
                        label={t<string>("Track inventory")}
                      />
                    )}
                  />
                </FormCard>
              </Grid>
              {productVariant?.productVariant?.id && (
                <ProductVariantImages product={defaultProductData} idVariant={productVariant?.productVariant?.id} />
              )}
            </Grid>
          </PageWrapper>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};

export default VariantForm;
