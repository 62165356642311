/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react/jsx-handler-names */
/* eslint-disable max-lines */
import { useTranslation } from "@health/i18n";
import { AppRoleTypes, AppTypes, PermissionEnum, ProcessingStatus } from "@health/queries";
import {
  ActiveFilters,
  BlockUiOverlay,
  Box,
  breadcrumbTitleVar,
  Button,
  CustomIcon,
  CustomTable,
  darkGrey,
  DynamicFilterDialog,
  Menu,
  PageWrapper,
  primary,
  TablePreferencesDialog,
  Typography,
} from "@health/ui";
import React, { FC, memo, useEffect, useState } from "react";
import { MedList, MedListActions, MedListDialog, UploadDialog, VersionsList } from "shared/components";
import { FetchToSubList } from "shared/components/MedListPageWrapper/FetchToSubList";
import { useIsMobileView } from "shared/hooks/IsMobile.hook";
import PanelComponent from "./components/Panel.component";
import { useMedListTableHooks } from "./useMedListTableHooks";

const MedListTable: FC = () => {
  const {
    columns,
    isLoading,
    codeSystemConcepts,
    pageSize,
    isTableLoading,
    totalCount,
    isOpen,
    dialogLoading,
    filterFields,
    activeFilters,
    versionData,
    versionDataLoading,
    processingStatus,
    code,
    isAssignDialogOpen,
    users,
    user,
    isPublishing,
    onAssign,
    getSearchParameters,
    onAssignDialogClose,
    handleDownloadFile,
    handleGotoNext,
    handlePageSizeChange,
    handleGoToPrevious,
    handleSortData,
    handleImportFile,
    onDialogClose,
    onResetErrorMessage,
    onUploadCodeSystemFile,
    handleApplyFilters,
    handleVersionDialogOpen,
    handleVersionOpenClick,
    onCloneClicked,
    handleDeleteRow,
    handleEditRow,
    handleRejectClicked,
    handlePublishClicked,
    handleApprove,
    handleUnderReview,
    handleReassign,
    versionNumber,
    hasUploadFileErrors,
    errorUploadMessage,
    hasNextPage,
    hasPreviousPage,
    handleOnFetchMoreVersions,
    handleGetSearchOptions,
    hasNextPageVersions,
    isRefetchingVersions,
    isFetchingMoreVersions,
    handleChangeActiveVersion,
    handleColumnsChanged,
    cloneLoading,
    reviewerUserName,
    medListItemName,
    editorUserId,
    reviewerUserId,
    isEditable,
    openActionsMenu,
    menuActionsAnchorEl,
    openFetchDialogue,
    handleClickActionsMenuAnchorEl,
    handleOpenFetchDialog,
    handleCloseFetchDialog,
    handleCloseActionsMenuAnchorEl,
    reviewerData,
    handleUndefinedString,
    handleDownloadFileFromVersion,
  } = useMedListTableHooks();
  const cloneCheck = !!codeSystemConcepts?.length;
  const checkReassign =
    !!user?.userPermissions?.find(item => item?.code === PermissionEnum.ManageCodeSystemEditorReviewers) ||
    (user?.appRole === AppRoleTypes.Admin && user?.appType === AppTypes.Admin);
  const { t } = useTranslation("Admin");
  useEffect(() => {
    breadcrumbTitleVar(t(medListItemName || "") + `/ ${t("version")} ${versionNumber ? versionNumber : t("Loading")}` || "");
  }, [medListItemName, code]);
  const isMobileView = useIsMobileView();
  const [isFilterOpened, setIsFilterOpened] = useState(false);

  const handleToggleFilters = () => {
    setIsFilterOpened(state => !state);
  };

  return (
    <>
      <BlockUiOverlay open={cloneLoading} background='rgba(255,255,255, 0.5)' size={100}>
        <PageWrapper
          start={
            <Box display={"flex"} flexDirection={"row"} alignItems='center' paddingTop='10px'>
              <ActiveFilters onOpenFilters={handleToggleFilters} activeFilters={activeFilters ? activeFilters : []} />
            </Box>
          }
          actions={
            <Box
              sx={{
                "@media (max-width: 881px)": {
                  paddingTop: "10px",
                },
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Box display={isMobileView ? "flex" : "inline-flex"} flexWrap={isMobileView ? "wrap" : "nowrap"} alignItems='center'>
                {filterFields && (
                  <DynamicFilterDialog
                    onFilterChanged={handleToggleFilters}
                    isFilterOpened={isFilterOpened}
                    filterFields={filterFields}
                    activeFilters={activeFilters}
                    onApplyFilters={handleApplyFilters}
                    onGetSearchOptions={handleGetSearchOptions}
                  />
                )}
                <TablePreferencesDialog columnsPreferences={columns} onApplyPreferences={handleColumnsChanged} />
                <VersionsList
                  versionData={versionData}
                  hasNextPageVersions={hasNextPageVersions}
                  isPublishing={isPublishing}
                  isRefetchingVersions={isRefetchingVersions}
                  versionDataLoading={versionDataLoading}
                  isFetchingMoreVersions={isFetchingMoreVersions}
                  onVersionDownload={handleDownloadFileFromVersion}
                  onVersionOpenClick={handleVersionOpenClick}
                  onVersionDialogOpen={handleVersionDialogOpen}
                  onFetchMoreVersions={handleOnFetchMoreVersions}
                  onChangeActiveVersions={handleChangeActiveVersion}
                />
                <Button
                  id='dropDown-button'
                  aria-controls={openActionsMenu ? "dropDown-menu" : undefined}
                  aria-haspopup='true'
                  aria-expanded={openActionsMenu ? "true" : undefined}
                  onClick={handleClickActionsMenuAnchorEl}
                  endIcon={<CustomIcon icon='expandArrow' color='white' />}
                >
                  {t("Actions")}
                </Button>
                <Menu
                  id='dropDown-menu'
                  anchorEl={menuActionsAnchorEl}
                  open={openActionsMenu}
                  onClose={handleCloseActionsMenuAnchorEl}
                  MenuListProps={{
                    "aria-labelledby": "dropDown-button",
                  }}
                >
                  <MedListActions
                    hasImport={!!isEditable}
                    hasClone={!!isEditable}
                    hasDownload
                    hasReassign={checkReassign}
                    editorUserId={editorUserId}
                    reviewerUserId={reviewerUserId}
                    cloneDisabled={cloneCheck}
                    hasReject={processingStatus === ProcessingStatus.UnderReview}
                    hasApprove={processingStatus === ProcessingStatus.UnderReview}
                    hasPublish={processingStatus === ProcessingStatus.Approved}
                    hasUnderReview={processingStatus === ProcessingStatus.Draft}
                    onCloneClicked={onCloneClicked}
                    onRejectClicked={handleRejectClicked}
                    onImportClicked={handleImportFile}
                    onApproveClicked={handleApprove}
                    onPublishClicked={handlePublishClicked}
                    onReassignClicked={handleReassign}
                    onUnderReviewClicked={handleUnderReview}
                    onCloseFetchToSubList={handleOpenFetchDialog}
                    onDownloadClicked={handleDownloadFile}
                  />
                </Menu>
              </Box>
            </Box>
          }
        >
          <Box marginTop='-40px' paddingTop={"12px"} paddingBottom={"12px"}>
            <MedList processingStatus={processingStatus} versionNumber={versionNumber} />
          </Box>

          {processingStatus && (
            <PanelComponent
              processingStatus={processingStatus}
              versionNumber={versionNumber}
              userName={
                handleUndefinedString(reviewerUserName) ? handleUndefinedString(reviewerUserName) : handleUndefinedString(reviewerData)
              }
            />
          )}
          <CustomTable
            title={medListItemName ? "Delete " + " " + medListItemName + " item " : "delete my data "}
            columns={columns}
            data={(codeSystemConcepts as Array<any>) || []}
            pageIndex={1}
            pageSize={pageSize}
            pagesCount={Math.ceil((totalCount || pageSize) / pageSize)}
            isLoading={isLoading || isTableLoading}
            isEditVisible={processingStatus === ProcessingStatus.Draft}
            isDeleteVisible={processingStatus === ProcessingStatus.Draft}
            hasNextPage={hasNextPage}
            hasPreviousPage={hasPreviousPage}
            onGoToNext={handleGotoNext}
            onGoToPrevious={handleGoToPrevious}
            onPageSizeChange={handlePageSizeChange}
            onSortColumn={handleSortData}
            onDeleteRow={handleDeleteRow}
            onEditRow={handleEditRow}
            withoutDataMessage={
              <Typography fontSize='16px' color={darkGrey}>
                {Object.keys(activeFilters)?.length ? (
                  t("No Results Found")
                ) : (
                  <>
                    {t("No Data has been added ,")}
                    <Typography fontSize='17px' color={darkGrey}>
                      {t("Please download the")}{" "}
                      <Typography
                        fontSize='20px'
                        color={darkGrey}
                        component='span'
                        sx={{
                          cursor: "pointer",
                          textDecoration: "underline",
                          "&:hover": {
                            color: primary,
                          },
                        }}
                        onClick={() => {
                          handleDownloadFile();
                        }}
                      >
                        {" "}
                        {t("Template")}{" "}
                      </Typography>{" "}
                      {t("to start adding data")}{" "}
                    </Typography>
                  </>
                )}
              </Typography>
            }
          />
        </PageWrapper>
      </BlockUiOverlay>
      <UploadDialog
        dialogLoading={dialogLoading}
        isOpen={isOpen}
        onFileUploadedSuccessfully={onUploadCodeSystemFile}
        handleClose={onDialogClose}
        handleResetError={onResetErrorMessage}
        hasErrors={hasUploadFileErrors}
        errorMessage={errorUploadMessage}
        title={t("Upload medlist")}
        subTitle={t("Upload Med List You Want To Get")}
      />
      <MedListDialog isOpen={isAssignDialogOpen} users={users} code={code!} handleAssign={onAssign} handleClose={onAssignDialogClose} />
      <FetchToSubList searchParameters={getSearchParameters(activeFilters)} open={openFetchDialogue} onClose={handleCloseFetchDialog} />
    </>
  );
};
export default memo(MedListTable);
