/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { CategoryFragmentFragmentDoc } from '../../../../fragment/categories/__generated__/Category';
import { CategoriesErrorsFragmentFragmentDoc } from '../../../../fragment/categories/__generated__/categoryErrorsFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CategoryUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.CategoryInput;
}>;


export type CategoryUpdateMutation = { __typename?: 'Mutation', categoryUpdate?: { __typename?: 'CategoryUpdate', category?: { __typename?: 'Category', id: string, name?: string | null, level: number, seoTitle?: string | null, description?: string | null, slug: string, backgroundImage?: { __typename?: 'Image', url?: string | null, alt?: string | null } | null, parent?: { __typename?: 'Category', id: string } | null, children?: { __typename?: 'CategoryCountableConnection', totalCount?: number | null } | null } | null, productErrors: Array<{ __typename?: 'ProductError', field?: string | null, message?: string | null, code: Types.ProductErrorCode }> } | null };


export const CategoryUpdateDocument = gql`
    mutation categoryUpdate($id: ID!, $input: CategoryInput!) {
  categoryUpdate(id: $id, input: $input) {
    category {
      ...CategoryFragment
    }
    productErrors {
      ...CategoriesErrorsFragment
    }
  }
}
    ${CategoryFragmentFragmentDoc}
${CategoriesErrorsFragmentFragmentDoc}`;
export type CategoryUpdateMutationFn = Apollo.MutationFunction<CategoryUpdateMutation, CategoryUpdateMutationVariables>;

/**
 * __useCategoryUpdateMutation__
 *
 * To run a mutation, you first call `useCategoryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCategoryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [categoryUpdateMutation, { data, loading, error }] = useCategoryUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCategoryUpdateMutation(baseOptions?: Apollo.MutationHookOptions<CategoryUpdateMutation, CategoryUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CategoryUpdateMutation, CategoryUpdateMutationVariables>(CategoryUpdateDocument, options);
      }
export type CategoryUpdateMutationHookResult = ReturnType<typeof useCategoryUpdateMutation>;
export type CategoryUpdateMutationResult = Apollo.MutationResult<CategoryUpdateMutation>;
export type CategoryUpdateMutationOptions = Apollo.BaseMutationOptions<CategoryUpdateMutation, CategoryUpdateMutationVariables>;