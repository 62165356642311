/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCodeSystemDefinitionQueryVariables = Types.Exact<{
  code: Types.CodeSystemCode;
}>;


export type GetCodeSystemDefinitionQuery = { __typename?: 'Query', getCodeSystemDefinition?: { __typename?: 'CodeSystemDefinition', isEditable?: boolean | null, editorUserId?: { __typename?: 'User', id: string } | null, reviewerUserId?: { __typename?: 'User', id: string } | null, codeSystemConceptFields?: Array<{ __typename?: 'CodeSystemConceptFieldDefinition', code?: string | null, display?: string | null, fieldType?: Types.Type | null, isMandatory?: boolean | null } | null> | null } | null };


export const GetCodeSystemDefinitionDocument = gql`
    query getCodeSystemDefinition($code: CodeSystemCode!) {
  getCodeSystemDefinition(code: $code) {
    isEditable
    editorUserId {
      id
    }
    reviewerUserId {
      id
    }
    codeSystemConceptFields {
      code
      display
      fieldType
      isMandatory
    }
  }
}
    `;

/**
 * __useGetCodeSystemDefinitionQuery__
 *
 * To run a query within a React component, call `useGetCodeSystemDefinitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCodeSystemDefinitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCodeSystemDefinitionQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetCodeSystemDefinitionQuery(baseOptions: Apollo.QueryHookOptions<GetCodeSystemDefinitionQuery, GetCodeSystemDefinitionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCodeSystemDefinitionQuery, GetCodeSystemDefinitionQueryVariables>(GetCodeSystemDefinitionDocument, options);
      }
export function useGetCodeSystemDefinitionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCodeSystemDefinitionQuery, GetCodeSystemDefinitionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCodeSystemDefinitionQuery, GetCodeSystemDefinitionQueryVariables>(GetCodeSystemDefinitionDocument, options);
        }
export type GetCodeSystemDefinitionQueryHookResult = ReturnType<typeof useGetCodeSystemDefinitionQuery>;
export type GetCodeSystemDefinitionLazyQueryHookResult = ReturnType<typeof useGetCodeSystemDefinitionLazyQuery>;
export type GetCodeSystemDefinitionQueryResult = Apollo.QueryResult<GetCodeSystemDefinitionQuery, GetCodeSystemDefinitionQueryVariables>;