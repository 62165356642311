import { SiteSettings } from "@health/queries";
import { OrderTimeoutsFormInputs } from "./types";

export const convertSettingsToBeInSeconds = (
  {
    orderAcceptanceTimeout,
    orderErxHubApprovalTimeout,
    orderErxHubApprovalResubmitTimeout,
    orderPaymentTimeout,
  }: OrderTimeoutsFormInputs = {} as OrderTimeoutsFormInputs
) => {
  return {
    orderAcceptanceTimeout: 60 * Number(orderAcceptanceTimeout),
    orderPaymentTimeout: 60 * Number(orderPaymentTimeout),
    orderErxHubApprovalTimeout: 60 * Number(orderErxHubApprovalTimeout),
    orderErxHubApprovalResubmitTimeout: 60 * Number(orderErxHubApprovalResubmitTimeout),
  };
};
export const convertSettingsToBeInMinutes = (settings: SiteSettings) => {
  if (!settings) return settings;
  const {
    orderAcceptanceTimeout,
    orderErxHubApprovalTimeout,
    orderDispensingTimeout,
    orderErxHubApprovalResubmitTimeout,
    orderDeliveryTimeout,
    orderPaymentTimeout,
  } = settings;
  return {
    orderDeliveryTimeout: Number(orderDeliveryTimeout) / 60,
    orderAcceptanceTimeout: Number(orderAcceptanceTimeout) / 60,
    orderDispensingTimeout: Number(orderDispensingTimeout) / 60,
    orderErxHubApprovalTimeout: Number(orderErxHubApprovalTimeout) / 60,
    orderErxHubApprovalResubmitTimeout: Number(orderErxHubApprovalResubmitTimeout) / 60,
    orderPaymentTimeout: Number(orderPaymentTimeout) / 60,
  };
};
