import { useTranslation } from "@health/i18n";
import { formatMessageErrors, ProductVariant, useProductVariantDeleteMutation } from "@health/queries";
import { CustomDialog, CustomTable, FormCard, Grid, useToasts } from "@health/ui";
import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { variantSubRoute } from "shared/constants/routes/products";
import { VariantColumns } from "../../Columns/VariantColumns";
import useVariantProduct from "../hooks/variantProduct.hook";
import { VariantInterface } from "../type";
const VariantAttributes: FC<VariantInterface> = ({ id, defaultProductData, idRoute, isLoading }) => {
  const { t } = useTranslation("Admin");
  const { addToast } = useToasts();
  const { refetch } = useVariantProduct(id, idRoute);
  const [productVariantDelete, { loading: loadingDelete }] = useProductVariantDeleteMutation({
    onCompleted: data => {
      const errors = data?.productVariantDelete?.productErrors;
      if (errors) {
        if (errors?.length === 0) {
          refetch();
          addToast("Product Variant Deleted Successfully", {
            appearance: "success",
            autoDismiss: true,
          });
        } else if (errors?.length > 0) {
          addToast(t(formatMessageErrors(errors)), {
            appearance: "error",
            autoDismiss: true,
          });
          console.error("<< Product Variant Image Assign >>", formatMessageErrors(errors));
        }
      } else {
        addToast("Product Variant Deleted Successfully", {
          appearance: "success",
          autoDismiss: true,
        });
        refetch();
      }
    },
    onError: () => {
      addToast(t("Error Product Variant Deleted"), {
        appearance: "error",
        autoDismiss: true,
      });
    },
  });
  const variantAttributes = defaultProductData?.variants;
  const [open, setOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const handleClickOpen = () => {
    setOpen(!open);
  };
  const handleDeleteRow = row => {
    productVariantDelete({
      variables: {
        id: row?.id,
      },
    });
  };
  const navigate = useNavigate();

  const handleSortData = () => {};
  const handleEdit = row => {
    navigate(`${variantSubRoute}/${row.id}`);
  };

  const pageSize = 10;
  const totalCount = variantAttributes?.length!;
  const handleGoToNext = () => {
    if (pageIndex < Math.ceil(totalCount / pageSize)) {
      setPageIndex(pageIndex + 1);
    }
  };

  const handleGoToPrevious = () => {
    if (pageIndex > 1) {
      setPageIndex(pageIndex - 1);
    }
  };
  const visibleVariants = variantAttributes?.slice((pageIndex - 1) * pageSize, pageIndex * pageSize);
  return (
    <React.Fragment>
      {defaultProductData?.variants?.length !== 0 && (
        <Grid container>
          <CustomDialog title={t("Delete Variant Product")} onCloseModal={handleClickOpen} onConfirm={handleDeleteRow} open={open} />
          <Grid item xs={12}>
            <FormCard title={t("Variant")} doYouHaveData={true} loading={isLoading || loadingDelete}>
              <CustomTable
                data={visibleVariants! as ProductVariant[]}
                columns={VariantColumns(t) as any}
                isLoading={isLoading || loadingDelete}
                isEditVisible
                isDeleteVisible
                pageSize={pageSize}
                pageIndex={pageIndex}
                hasNextPage={pageIndex < Math.ceil(totalCount / pageSize)}
                hasPreviousPage={pageIndex > 1}
                onEditRow={handleEdit}
                onDeleteRow={handleDeleteRow}
                onSortColumn={handleSortData}
                onGoToNext={handleGoToNext}
                onGoToPrevious={handleGoToPrevious}
              />
            </FormCard>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};
export default VariantAttributes;
