/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AttributeValueDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type AttributeValueDeleteMutation = { __typename?: 'Mutation', attributeValueDelete?: { __typename?: 'AttributeValueDelete', attributeValue?: { __typename?: 'AttributeValue', id: string, name?: string | null, inputType?: Types.AttributeInputTypeEnum | null } | null, attribute?: { __typename?: 'Attribute', id: string, name?: string | null, values?: Array<{ __typename?: 'AttributeValue', id: string, name?: string | null, inputType?: Types.AttributeInputTypeEnum | null } | null> | null } | null, productErrors: Array<{ __typename?: 'ProductError', field?: string | null, message?: string | null }> } | null };


export const AttributeValueDeleteDocument = gql`
    mutation attributeValueDelete($id: ID!) {
  attributeValueDelete(id: $id) {
    attributeValue {
      id
      name
      inputType
    }
    attribute {
      id
      name
      values {
        id
        name
        inputType
      }
    }
    productErrors {
      field
      message
    }
  }
}
    `;
export type AttributeValueDeleteMutationFn = Apollo.MutationFunction<AttributeValueDeleteMutation, AttributeValueDeleteMutationVariables>;

/**
 * __useAttributeValueDeleteMutation__
 *
 * To run a mutation, you first call `useAttributeValueDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttributeValueDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attributeValueDeleteMutation, { data, loading, error }] = useAttributeValueDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAttributeValueDeleteMutation(baseOptions?: Apollo.MutationHookOptions<AttributeValueDeleteMutation, AttributeValueDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AttributeValueDeleteMutation, AttributeValueDeleteMutationVariables>(AttributeValueDeleteDocument, options);
      }
export type AttributeValueDeleteMutationHookResult = ReturnType<typeof useAttributeValueDeleteMutation>;
export type AttributeValueDeleteMutationResult = Apollo.MutationResult<AttributeValueDeleteMutation>;
export type AttributeValueDeleteMutationOptions = Apollo.BaseMutationOptions<AttributeValueDeleteMutation, AttributeValueDeleteMutationVariables>;