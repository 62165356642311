import createStyles from "@mui/styles/createStyles";
import { darkGrey, lightGrey } from "../../customization";

export const styles = () =>
  createStyles({
    root: {
      margin: 8,
      padding: 13,
      width: 260,
      height: 335,
      borderRadius: 15,
      background: "#fff",
    },
    img: {
      height: 160,
      padding: 20,
      textAlign: "center",
    },
    category: {
      fontSize: 12,
      color: darkGrey,
    },
    description: {
      fontSize: 12,
    },
    divider: {
      marginTop: 10,
      marginBottom: 10,
    },
    bold: {
      fontWeight: "bold",
    },
    iconButton: {
      margin: 2,
      background: lightGrey,
      borderRadius: 15,
    },
  });
