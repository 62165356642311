import { isEqual } from "@health/common";
import { useTranslation } from "@health/i18n";
import { formatMessageErrors, RoundInput, useCitySettingsQuery, useCityUpdateMutation, VendorType } from "@health/queries";
import { breadcrumbTitleVar, useToasts } from "@health/ui";
import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useReportAnError from "shared/hooks/useLogError";
import { PharmacyListingInputs } from "./types";

export const usePharmacyListingForm = ({ id }) => {
  const methods = useForm<PharmacyListingInputs>({
    mode: "all",
  });
  const maxNumberOfRounds = 5;
  const { data, loading: isDataLoading } = useCitySettingsQuery({
    variables: {
      id: id!,
    },
  });
  const defaults = data?.city;
  const navigate = useNavigate();
  const [update, { loading: isSubmitting }] = useCityUpdateMutation();
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const { reportGraphQlErrors } = useReportAnError();
  const numberOfRounds = methods.watch("maxNumberOfRounds", 1);
  const { fields: roundsFields, replace } = useFieldArray({
    control: methods.control,
    name: "rounds",
  });

  useEffect(() => {
    regenerateRoundsFields(defaults?.maxNumberOfRounds);
  }, [defaults?.maxNumberOfRounds]);

  useEffect(() => {
    regenerateRoundsFields(numberOfRounds);
  }, [numberOfRounds]);

  useEffect(() => {
    breadcrumbTitleVar("");
  }, []);

  function regenerateRoundsFields(numberOfRounds) {
    const _roundFields = Array.from<RoundInput | null | undefined>({
      length: Math.max(1, Math.min(maxNumberOfRounds, Number(numberOfRounds))),
    }).map((r, i) => {
      const round = defaults?.rounds?.at(i);
      return {
        maxNumberOfPharmacies: round?.maxNumberOfPharmacies || null,
        radius: round?.radius || null,
        pharmaciesTypes: round?.pharmaciesTypes || [VendorType.Pharmacy],
      };
    });
    replace(_roundFields as unknown as RoundInput[]);
  }

  const isNotModified = (input?: PharmacyListingInputs) => {
    const mapRounds = (r: RoundInput) => {
      return {
        maxNumberOfPharmacies: Number(r?.maxNumberOfPharmacies || 1),
        radius: Number(r?.radius || 1),
        pharmaciesTypes: r?.pharmaciesTypes?.filter(Boolean) ?? [],
      };
    };
    const userInput = {
      maxNumberOfRounds: Number(input?.maxNumberOfRounds),
      timeOutPeriod: Number(input?.timeOutPeriod),
      maxNumberOfTries: Number(input?.maxNumberOfTries),
      rounds: input?.rounds?.map(mapRounds as any) || [],
      maxRadiusForPickup: Number(input?.maxRadiusForPickup),
    };
    const defaultValue = {
      maxNumberOfRounds: Number(defaults?.maxNumberOfRounds),
      timeOutPeriod: Number(defaults?.timeOutPeriod),
      maxNumberOfTries: Number(defaults?.maxNumberOfTries),
      maxRadiusForPickup: defaults?.maxRadiusForPickup !== undefined ? Number(defaults?.maxRadiusForPickup) : undefined,
      rounds: defaults?.rounds?.map(mapRounds as any) || [],
    };
    return isEqual(userInput, defaultValue);
  };
  const onSubmit = (input?: PharmacyListingInputs) => {
    const roundsWithNoCheckedTypes = input?.rounds
      ?.map((round, index) => ({ index, round }))
      .filter(({ round: r }) => r?.pharmaciesTypes?.filter(Boolean).length === 0);
    if (roundsWithNoCheckedTypes?.length) {
      roundsWithNoCheckedTypes.forEach(({ index }) => {
        methods.setError(`rounds.${index}.pharmaciesTypes`, { type: "required" });
      });
      return;
    }
    update({
      variables: {
        id: id,
        input: {
          maxNumberOfRounds: Number(input?.maxNumberOfRounds),
          timeOutPeriod: Number(input?.timeOutPeriod),
          maxNumberOfTries: Number(input?.maxNumberOfTries),
          maxRadiusForPickup: Number(input?.maxRadiusForPickup),
          rounds:
            input?.rounds?.map(r => {
              return {
                ...r,
                maxNumberOfPharmacies: Number(r?.maxNumberOfPharmacies || 1),
                radius: Number(r?.radius || 1),
                pharmaciesTypes: r?.pharmaciesTypes?.filter(Boolean) ?? [],
              };
            }) || [],
        },
      },
      onCompleted: e => {
        if (e.cityUpdate?.cityErrors?.length) {
          addToast(formatMessageErrors(e.cityUpdate?.cityErrors), { appearance: "error", autoDismiss: true });
          return;
        }
        if (!id) {
          navigate(e?.cityUpdate?.city?.id!);
        } else {
          navigateToListPage();
        }
        addToast(t("Saved successfully"), {
          appearance: "success",
          autoDismiss: true,
        });
      },
      onError: ({ graphQLErrors }) => reportGraphQlErrors(t("Error saving settings"))(graphQLErrors),
    });
  };
  const navigateToListPage = () => {
    navigate("/system-rules/pharmacy-listing-criteria");
  };
  const handleSubmit = methods.handleSubmit(onSubmit);
  const handleCancel = () => {
    navigateToListPage();
  };
  return {
    defaults,
    methods,
    isDataLoading,
    isSubmitting,
    roundsFields,
    maxNumberOfRounds,
    isNotModified,
    handleSubmit,
    onSubmit,
    handleCancel,
  };
};

export default usePharmacyListingForm;
