import { FallbackComponent } from "@health/ui";
import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ROUTE_PATHS } from "../../constants";
import { LayoutComponent } from "./LayoutComponent";
import { PageNotFound } from "./PageNotFound";
import { UnAuthorizedPage } from "./UnAuthorizedPage";
import { useAppRoutes } from "./useAppRoutes";

export function LoggedInRoutes(): React.ReactElement {
  const routes = useAppRoutes();

  const getRoutes = _routers => {
    return _routers.map(route =>
      route?.isProhibited ? (
        <Route key={route.id} path={route.route} element={<UnAuthorizedPage />}>
          {!!route?.subItems?.length && getRoutes(route.subItems)}
        </Route>
      ) : (
        <Route key={route.id} path={route.route} element={route?.element}>
          {!!route?.subItems?.length && getRoutes(route.subItems)}
        </Route>
      )
    );
  };

  return (
    <Suspense fallback={<FallbackComponent />}>
      <Routes>
        <Route element={<LayoutComponent />}>
          {getRoutes(routes)}
          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
              routes for.
          */}
          <Route index element={<Navigate to={ROUTE_PATHS.dashboard.path} replace={true} />} />
          <Route path='*' element={<PageNotFound />} />
        </Route>
      </Routes>
    </Suspense>
  );
}
