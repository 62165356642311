import { makeVar, useReactiveVar } from "@apollo/client";
import { useTranslation } from "@health/i18n";
import { Link } from "@mui/material";
import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { default as React, FC, useEffect } from "react";
import { useBreadcrumbsStyles } from "./Breadcrumbs.styles";
import { BreadcrumbsProps } from "./Breadcrumbs.types";

export const breadcrumbTitleVar = makeVar("");
const Breadcrumbs: FC<BreadcrumbsProps> = props => {
  const { data } = props;
  const { classes } = useBreadcrumbsStyles();
  const { t } = useTranslation();
  const breadcrumbTitle = useReactiveVar(breadcrumbTitleVar);
  useEffect(() => {
    breadcrumbTitleVar("");
  }, [data]);
  return (
    <MuiBreadcrumbs aria-label='breadcrumb'>
      {!!data?.length &&
        data?.map((item, index) => {
          const { id, name, fullPath, onClick } = item;
          const handleItemClicked = () => {
            onClick && onClick(fullPath);
          };

          if (index < data?.length - 1) {
            return (
              <Link key={id} underline='hover' color='inherit' className={classes.link} onClick={handleItemClicked}>
                {t(name)}
              </Link>
            );
          } else {
            return (
              <Typography key={id} className={classes.text}>
                {t(name)}
              </Typography>
            );
          }
        })}
      {breadcrumbTitle && <Typography className={classes.text}>{breadcrumbTitle}</Typography>}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
