import { Box, Button, Card, Dot, Grid, Tooltip, Typography } from "@health/ui";
import { FC, memo } from "react";
import { MedListDialog } from "shared/components";
import { useMedicalCardHooks } from "./MedicalCard.hook";
import { MedicalCardProps } from "./medicalCard.types";
import { useMedicalCardStyle } from "./medicalCared.style";
import { formatNames, getCreatorName } from "./utils";

const MedicalCard: FC<MedicalCardProps> = props => {
  const { data, users, handleDetailsClick, onUpdateAssign } = props;
  const {
    code,
    creator,
    display,
    editorUserId,
    onAssign,
    onDialogClose,
    getStatusColor,
    handleAssignClick,
    id,
    isOpen,
    lastCadeSystemProcessingStatus,
    lastCodeSystem,
    reviewerUserId,
    statusText,
    svgIcon,
    t,
    updateDate,
    uploadDate,
  } = useMedicalCardHooks(data, onUpdateAssign);
  const { classes } = useMedicalCardStyle();
  return (
    <>
      <MedListDialog isOpen={isOpen} users={users} code={code!} handleAssign={onAssign} handleClose={onDialogClose} />
      <Card elevation={0}>
        <Box minHeight={330} padding={1} paddingBottom={0}>
          <Grid container alignItems='center'>
            {lastCadeSystemProcessingStatus ? (
              <>
                {lastCodeSystem === null ? undefined : <Dot background={getStatusColor()} />}
                <Typography paddingLeft={1} color={getStatusColor()} fontSize={12}>
                  {getCreatorName(statusText, creator, t)}
                </Typography>
              </>
            ) : (
              <Box height='18px'></Box>
            )}
          </Grid>
          <Grid container flexDirection={"column"} alignItems='center' paddingTop={3}>
            <div dangerouslySetInnerHTML={{ __html: svgIcon.replace(/height=*"/, 'height="70px"') }} className={classes.imgHeight} />
            <Typography fontSize={18} color='black' marginTop={1} marginBottom={1}>
              {display}
            </Typography>

            <Grid container justifyContent={"center"} spacing={2}>
              <Grid item container flex={1} display={"flex"} direction='column' rowSpacing={0.5}>
                {[
                  { title: t("Upload Date"), value: t(uploadDate) || "-" },
                  { title: t("Last Update"), value: t(updateDate) || "-" },
                  { title: <b>{t("Assigned Editor")}</b>, value: formatNames(reviewerUserId?.firstName, reviewerUserId?.lastName) },
                  { title: <b>{t("Assigned Reviewer")}</b>, value: formatNames(editorUserId?.firstName, editorUserId?.lastName) },
                ].map(item => {
                  return (
                    <Grid key={item.value} item container columnSpacing={2}>
                      <Grid item xs={6} sx={{ textAlign: "end" }}>
                        <Typography fontSize={12} color='black'>
                          {item.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Tooltip title={item.value}>
                          <Typography
                            fontSize={12}
                            color='black'
                            sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", maxWidth: "90%!important", overflow: "hidden" }}
                          >
                            {item.value}
                          </Typography>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid container justifyContent={"center"} marginTop={2}>
              <Button variant='contained' onClick={() => handleDetailsClick(code!, id as any)}>
                {t("Details")}
              </Button>
              <Button variant='contained' color='success' onClick={handleAssignClick}>
                {editorUserId || reviewerUserId ? t("Reassign") : t("Assign")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );
};

export default memo(MedicalCard);
