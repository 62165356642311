import { RequestPasswordResetMutation, useRequestPasswordResetMutation } from "@health/queries";
import { useState } from "react";
import { dialogStep } from "./dialog/utils";
import { registerVar } from "./reactiveVar";

export const useForgetPasswordHooks = (app, onChangeStep) => {
  const [errorsForget, setErrors] = useState([]);
  const [mobile, setMobile] = useState("");
  const [resetPassword] = useRequestPasswordResetMutation({
    onCompleted: (data: RequestPasswordResetMutation) => {
      if (data.requestPasswordResetByMobile!.accountErrors.length === 0) {
        registerVar({ mobile, sessionToken: data?.requestPasswordResetByMobile?.sessionToken, isForget: true });

        onChangeStep(dialogStep.verify);
      } else {
        setErrors(data.requestPasswordResetByMobile?.accountErrors || []);
      }
    },
  });
  const handleSubmitData = data => {
    setErrors([]);
    setMobile(data.mobile);
    resetPassword({
      variables: {
        mobile: data.mobile,
        app: app,
      },
    });
  };

  const handleLogin = () => {
    onChangeStep(dialogStep.login);
  };

  return {
    errorsForget,
    handleLogin,
    handleSubmitData,
  };
};
