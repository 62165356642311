import React, { FC, useState } from "react";
import { ConfirmationFormComponent } from "./ConfirmationForm.component";
import { Tabs } from "./Tabs";
import { dialogStep, useChangePassword, useForget, useResetSuccessfully, useSuccess, useTabs } from "./utils";

type DialogViewProps = {
  onRouteView: () => void;
};

export const DialogView: FC<DialogViewProps> = ({ onRouteView }) => {
  const [step, setStep] = useState(dialogStep.login);
  const handleStep = currentStep => {
    setStep(currentStep);
  };
  const tabs = useTabs(handleStep, onRouteView);
  const success = useSuccess(handleStep);
  const resetSuccessfully = useResetSuccessfully(handleStep);
  const forget = useForget(handleStep);
  const changePassword = useChangePassword(handleStep);

  const getStep = {
    [dialogStep.login]: tabs,
    [dialogStep.forget]: forget,
    [dialogStep.success]: success,
    [dialogStep.changePassword]: changePassword,
    [dialogStep.resetSuccess]: resetSuccessfully,
  };

  return (
    <>
      {step !== dialogStep.verify ? (
        <Tabs items={getStep[step]} />
      ) : (
        <ConfirmationFormComponent onChangeStep={value => handleStep(value)} />
      )}
    </>
  );
};
