import { ApolloProvider } from "@apollo/client";
import { getEnvVariable, NotificationsServiceProvider, UserContextProvider } from "@health/common";
import { i18n, I18nextProvider } from "@health/i18n";
import { SiteSettingsProvider } from "@health/smart-ui";
import { AppThemeProvider } from "@health/ui";
import React, { FC } from "react";
import { BrowserRouter } from "react-router-dom";
import { client } from "shared/configs/apollo";
import { ErrorBoundary } from "./ErrorBoundary";
import { RootRouter } from "./shared/components/Root/RootRoutes";
const eventSourceUrl = getEnvVariable("SUBSCRIPTION_URL");

const AppRootProviders: FC = ({ children }) => {
  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <SiteSettingsProvider>
          <UserContextProvider apolloClient={client}>
            <NotificationsServiceProvider eventSourceUrl={eventSourceUrl}>
              <I18nextProvider i18n={i18n}>
                <BrowserRouter>
                  <AppThemeProvider>{children}</AppThemeProvider>
                </BrowserRouter>
              </I18nextProvider>
            </NotificationsServiceProvider>
          </UserContextProvider>
        </SiteSettingsProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

function App(): React.ReactElement {
  return (
    <AppRootProviders>
      <RootRouter />
    </AppRootProviders>
  );
}

export default App;
