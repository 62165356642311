/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../fragment/PageInfo/__generated__/pageInfoFragment';
import { CategoryFragmentFragmentDoc } from '../../../../fragment/categories/__generated__/Category';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VendorCategoriesQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.CategoryFilterInput>;
  sortBy?: Types.InputMaybe<Types.CategorySortingInput>;
}>;


export type VendorCategoriesQuery = { __typename?: 'Query', vendorCategories?: { __typename?: 'CategoryCountableConnection', pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'CategoryCountableEdge', node: { __typename?: 'Category', id: string, name?: string | null, level: number, seoTitle?: string | null, description?: string | null, slug: string, backgroundImage?: { __typename?: 'Image', url?: string | null, alt?: string | null } | null, parent?: { __typename?: 'Category', id: string } | null, children?: { __typename?: 'CategoryCountableConnection', totalCount?: number | null } | null } }> } | null };


export const VendorCategoriesDocument = gql`
    query vendorCategories($first: Int, $before: String, $last: Int, $after: String, $filter: CategoryFilterInput, $sortBy: CategorySortingInput) {
  vendorCategories(
    first: $first
    before: $before
    last: $last
    after: $after
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        ...CategoryFragment
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}
${CategoryFragmentFragmentDoc}`;

/**
 * __useVendorCategoriesQuery__
 *
 * To run a query within a React component, call `useVendorCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorCategoriesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useVendorCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<VendorCategoriesQuery, VendorCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VendorCategoriesQuery, VendorCategoriesQueryVariables>(VendorCategoriesDocument, options);
      }
export function useVendorCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VendorCategoriesQuery, VendorCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VendorCategoriesQuery, VendorCategoriesQueryVariables>(VendorCategoriesDocument, options);
        }
export type VendorCategoriesQueryHookResult = ReturnType<typeof useVendorCategoriesQuery>;
export type VendorCategoriesLazyQueryHookResult = ReturnType<typeof useVendorCategoriesLazyQuery>;
export type VendorCategoriesQueryResult = Apollo.QueryResult<VendorCategoriesQuery, VendorCategoriesQueryVariables>;