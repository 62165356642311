import React, { useEffect, useState } from "react";
import { CartContext } from "./context";
import { CartContextType, ICartContextProvider } from "./types";
import { getCartId, removeCartId, saveCartId } from "./utils";

export const CartContextProvider = (props: ICartContextProvider) => {
  const handleDeleteCart = () => {
    removeCartId();
    setState({ ...state, cartId: "" });
  };

  const handleSaveCart = (cartId?: string) => {
    saveCartId(cartId);
    setState({ ...state, cartId: cartId });
  };

  const initState: CartContextType = {
    cartId: getCartId(),
    onDeleteCart: handleDeleteCart,
    onSaveCart: handleSaveCart,
  };

  const [state, setState] = useState<CartContextType>(initState);

  useEffect(() => {
    setState(initState);
  }, []);

  useEffect(() => {
    setState({ ...state, cartId: getCartId() });
  }, [getCartId()]);

  return <CartContext.Provider value={state}>{props.children}</CartContext.Provider>;
};
