/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VendorProductVariantStocksCreateMutationVariables = Types.Exact<{
  input: Types.VendorProductVariantStocksCreateInput;
}>;


export type VendorProductVariantStocksCreateMutation = { __typename?: 'Mutation', vendorProductVariantStocksCreate?: { __typename?: 'VendorProductVariantStocksCreate', vendorProductVariant?: { __typename?: 'VendorProductVariant', id: string } | null, stockErrors: Array<{ __typename?: 'StockError', field?: string | null, message?: string | null, code: Types.StockErrorCode }> } | null };


export const VendorProductVariantStocksCreateDocument = gql`
    mutation vendorProductVariantStocksCreate($input: VendorProductVariantStocksCreateInput!) {
  vendorProductVariantStocksCreate(input: $input) {
    vendorProductVariant {
      id
    }
    stockErrors {
      field
      message
      code
    }
  }
}
    `;
export type VendorProductVariantStocksCreateMutationFn = Apollo.MutationFunction<VendorProductVariantStocksCreateMutation, VendorProductVariantStocksCreateMutationVariables>;

/**
 * __useVendorProductVariantStocksCreateMutation__
 *
 * To run a mutation, you first call `useVendorProductVariantStocksCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVendorProductVariantStocksCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vendorProductVariantStocksCreateMutation, { data, loading, error }] = useVendorProductVariantStocksCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVendorProductVariantStocksCreateMutation(baseOptions?: Apollo.MutationHookOptions<VendorProductVariantStocksCreateMutation, VendorProductVariantStocksCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VendorProductVariantStocksCreateMutation, VendorProductVariantStocksCreateMutationVariables>(VendorProductVariantStocksCreateDocument, options);
      }
export type VendorProductVariantStocksCreateMutationHookResult = ReturnType<typeof useVendorProductVariantStocksCreateMutation>;
export type VendorProductVariantStocksCreateMutationResult = Apollo.MutationResult<VendorProductVariantStocksCreateMutation>;
export type VendorProductVariantStocksCreateMutationOptions = Apollo.BaseMutationOptions<VendorProductVariantStocksCreateMutation, VendorProductVariantStocksCreateMutationVariables>;