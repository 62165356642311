import { VerificationPage as Verification } from "@health/entry";
import React from "react";
import { useNavigate } from "react-router-dom";

export const VerificationPage = () => {
  const navigate = useNavigate();

  const handleRedirectLogin = value => {
    navigate("/" + value);
  };

  return <Verification onRedirectLogin={handleRedirectLogin} />;
};
