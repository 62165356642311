/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { CityFragmentFragmentDoc } from '../../../../fragment/city/__generated__/city';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CityQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type CityQuery = { __typename?: 'Query', city?: { __typename?: 'City', id: string, deliveryPriceAmount: number, name: string, nameAr?: string | null, created: any, modified: any, deliveryPrice?: { __typename?: 'Money', amount: number, currency: string } | null } | null };


export const CityDocument = gql`
    query city($id: ID!) {
  city(id: $id) {
    ...CityFragment
  }
}
    ${CityFragmentFragmentDoc}`;

/**
 * __useCityQuery__
 *
 * To run a query within a React component, call `useCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCityQuery(baseOptions: Apollo.QueryHookOptions<CityQuery, CityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CityQuery, CityQueryVariables>(CityDocument, options);
      }
export function useCityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CityQuery, CityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CityQuery, CityQueryVariables>(CityDocument, options);
        }
export type CityQueryHookResult = ReturnType<typeof useCityQuery>;
export type CityLazyQueryHookResult = ReturnType<typeof useCityLazyQuery>;
export type CityQueryResult = Apollo.QueryResult<CityQuery, CityQueryVariables>;