/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrdersTabCountNewQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.OrderFilterInput>;
}>;


export type OrdersTabCountNewQuery = { __typename?: 'Query', new?: { __typename?: 'OrderCountableConnection', totalCount?: number | null } | null };

export type OrdersTabCountPendingQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.OrderFilterInput>;
}>;


export type OrdersTabCountPendingQuery = { __typename?: 'Query', pending?: { __typename?: 'OrderCountableConnection', totalCount?: number | null } | null };

export type OrdersTabCountDispensingQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.OrderFilterInput>;
}>;


export type OrdersTabCountDispensingQuery = { __typename?: 'Query', dispensing?: { __typename?: 'OrderCountableConnection', totalCount?: number | null } | null };

export type OrdersTabCountDeliveredQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.OrderFilterInput>;
}>;


export type OrdersTabCountDeliveredQuery = { __typename?: 'Query', delivered?: { __typename?: 'OrderCountableConnection', totalCount?: number | null } | null };


export const OrdersTabCountNewDocument = gql`
    query OrdersTabCountNew($filter: OrderFilterInput) {
  new: orders(filter: $filter) {
    totalCount
  }
}
    `;

/**
 * __useOrdersTabCountNewQuery__
 *
 * To run a query within a React component, call `useOrdersTabCountNewQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersTabCountNewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersTabCountNewQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrdersTabCountNewQuery(baseOptions?: Apollo.QueryHookOptions<OrdersTabCountNewQuery, OrdersTabCountNewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersTabCountNewQuery, OrdersTabCountNewQueryVariables>(OrdersTabCountNewDocument, options);
      }
export function useOrdersTabCountNewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersTabCountNewQuery, OrdersTabCountNewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersTabCountNewQuery, OrdersTabCountNewQueryVariables>(OrdersTabCountNewDocument, options);
        }
export type OrdersTabCountNewQueryHookResult = ReturnType<typeof useOrdersTabCountNewQuery>;
export type OrdersTabCountNewLazyQueryHookResult = ReturnType<typeof useOrdersTabCountNewLazyQuery>;
export type OrdersTabCountNewQueryResult = Apollo.QueryResult<OrdersTabCountNewQuery, OrdersTabCountNewQueryVariables>;
export const OrdersTabCountPendingDocument = gql`
    query OrdersTabCountPending($filter: OrderFilterInput) {
  pending: orders(filter: $filter) {
    totalCount
  }
}
    `;

/**
 * __useOrdersTabCountPendingQuery__
 *
 * To run a query within a React component, call `useOrdersTabCountPendingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersTabCountPendingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersTabCountPendingQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrdersTabCountPendingQuery(baseOptions?: Apollo.QueryHookOptions<OrdersTabCountPendingQuery, OrdersTabCountPendingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersTabCountPendingQuery, OrdersTabCountPendingQueryVariables>(OrdersTabCountPendingDocument, options);
      }
export function useOrdersTabCountPendingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersTabCountPendingQuery, OrdersTabCountPendingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersTabCountPendingQuery, OrdersTabCountPendingQueryVariables>(OrdersTabCountPendingDocument, options);
        }
export type OrdersTabCountPendingQueryHookResult = ReturnType<typeof useOrdersTabCountPendingQuery>;
export type OrdersTabCountPendingLazyQueryHookResult = ReturnType<typeof useOrdersTabCountPendingLazyQuery>;
export type OrdersTabCountPendingQueryResult = Apollo.QueryResult<OrdersTabCountPendingQuery, OrdersTabCountPendingQueryVariables>;
export const OrdersTabCountDispensingDocument = gql`
    query OrdersTabCountDispensing($filter: OrderFilterInput) {
  dispensing: orders(filter: $filter) {
    totalCount
  }
}
    `;

/**
 * __useOrdersTabCountDispensingQuery__
 *
 * To run a query within a React component, call `useOrdersTabCountDispensingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersTabCountDispensingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersTabCountDispensingQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrdersTabCountDispensingQuery(baseOptions?: Apollo.QueryHookOptions<OrdersTabCountDispensingQuery, OrdersTabCountDispensingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersTabCountDispensingQuery, OrdersTabCountDispensingQueryVariables>(OrdersTabCountDispensingDocument, options);
      }
export function useOrdersTabCountDispensingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersTabCountDispensingQuery, OrdersTabCountDispensingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersTabCountDispensingQuery, OrdersTabCountDispensingQueryVariables>(OrdersTabCountDispensingDocument, options);
        }
export type OrdersTabCountDispensingQueryHookResult = ReturnType<typeof useOrdersTabCountDispensingQuery>;
export type OrdersTabCountDispensingLazyQueryHookResult = ReturnType<typeof useOrdersTabCountDispensingLazyQuery>;
export type OrdersTabCountDispensingQueryResult = Apollo.QueryResult<OrdersTabCountDispensingQuery, OrdersTabCountDispensingQueryVariables>;
export const OrdersTabCountDeliveredDocument = gql`
    query OrdersTabCountDelivered($filter: OrderFilterInput) {
  delivered: orders(filter: $filter) {
    totalCount
  }
}
    `;

/**
 * __useOrdersTabCountDeliveredQuery__
 *
 * To run a query within a React component, call `useOrdersTabCountDeliveredQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersTabCountDeliveredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersTabCountDeliveredQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrdersTabCountDeliveredQuery(baseOptions?: Apollo.QueryHookOptions<OrdersTabCountDeliveredQuery, OrdersTabCountDeliveredQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersTabCountDeliveredQuery, OrdersTabCountDeliveredQueryVariables>(OrdersTabCountDeliveredDocument, options);
      }
export function useOrdersTabCountDeliveredLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersTabCountDeliveredQuery, OrdersTabCountDeliveredQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersTabCountDeliveredQuery, OrdersTabCountDeliveredQueryVariables>(OrdersTabCountDeliveredDocument, options);
        }
export type OrdersTabCountDeliveredQueryHookResult = ReturnType<typeof useOrdersTabCountDeliveredQuery>;
export type OrdersTabCountDeliveredLazyQueryHookResult = ReturnType<typeof useOrdersTabCountDeliveredLazyQuery>;
export type OrdersTabCountDeliveredQueryResult = Apollo.QueryResult<OrdersTabCountDeliveredQuery, OrdersTabCountDeliveredQueryVariables>;