import _ from "lodash";
import { useForm } from "react-hook-form";
import { FieldsTypes } from "../Filter";
import { lazyAutocompleteTextPostfix } from "../Filter/FilterItemsInput.component";

export const useStaticFilterDialogHooks = props => {
  const { onCloseDialog, activeFilters, filterFields, onApplyFilters: onSubmit, isOpen, onToggleDialog } = props;
  const { control, formState, handleSubmit, register, reset, resetField } = useForm<Record<string, unknown>>();

  const handleToggle = () => {
    if (activeFilters) {
      reset(activeFilters);
    }
    onToggleDialog();
  };

  const handleFormReset = () => {
    const fields = filterFields?.reduce((acc, item) => {
      if (item.type === FieldsTypes.lazyAutocomplete && item.fieldProps.multiple) {
        resetField(item.name, { defaultValue: [] });
        return { ...acc, [item.name]: [] };
      } else {
        return { ...acc, [item.name]: null };
      }
    }, {});

    reset(fields, { keepValues: false });
  };

  const handleSubmitClicked = (data: Record<string, unknown>) => {
    const d = _.omitBy(data, (_, key) => key.includes(lazyAutocompleteTextPostfix));
    onSubmit(d);
    onCloseDialog ? onCloseDialog() : onToggleDialog();
  };

  return { filterFields, formState, isOpen, control, handleSubmitClicked, handleFormReset, handleToggle, register, handleSubmit, reset };
};
