/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetActiveDispensedPrescriptionsQueryVariables = Types.Exact<{
  patientIdentifier: Types.Scalars['String'];
}>;


export type GetActiveDispensedPrescriptionsQuery = { __typename?: 'Query', getActiveDispensedPrescriptions?: Array<{ __typename?: 'ActiveDispensedPrescription', overallStatus?: string | null, activities?: Array<{ __typename?: 'Activity', code: string, display?: string | null, quantity?: number | null, instructions?: string | null } | null> | null } | null> | null };


export const GetActiveDispensedPrescriptionsDocument = gql`
    query getActiveDispensedPrescriptions($patientIdentifier: String!) {
  getActiveDispensedPrescriptions(patientIdentifier: $patientIdentifier) {
    overallStatus
    activities {
      code
      display
      quantity
      instructions
    }
  }
}
    `;

/**
 * __useGetActiveDispensedPrescriptionsQuery__
 *
 * To run a query within a React component, call `useGetActiveDispensedPrescriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveDispensedPrescriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveDispensedPrescriptionsQuery({
 *   variables: {
 *      patientIdentifier: // value for 'patientIdentifier'
 *   },
 * });
 */
export function useGetActiveDispensedPrescriptionsQuery(baseOptions: Apollo.QueryHookOptions<GetActiveDispensedPrescriptionsQuery, GetActiveDispensedPrescriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveDispensedPrescriptionsQuery, GetActiveDispensedPrescriptionsQueryVariables>(GetActiveDispensedPrescriptionsDocument, options);
      }
export function useGetActiveDispensedPrescriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveDispensedPrescriptionsQuery, GetActiveDispensedPrescriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveDispensedPrescriptionsQuery, GetActiveDispensedPrescriptionsQueryVariables>(GetActiveDispensedPrescriptionsDocument, options);
        }
export type GetActiveDispensedPrescriptionsQueryHookResult = ReturnType<typeof useGetActiveDispensedPrescriptionsQuery>;
export type GetActiveDispensedPrescriptionsLazyQueryHookResult = ReturnType<typeof useGetActiveDispensedPrescriptionsLazyQuery>;
export type GetActiveDispensedPrescriptionsQueryResult = Apollo.QueryResult<GetActiveDispensedPrescriptionsQuery, GetActiveDispensedPrescriptionsQueryVariables>;