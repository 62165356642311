/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../fragment/PageInfo/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrderRejectionReasonsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type OrderRejectionReasonsQuery = { __typename?: 'Query', orderRejectionReasons?: { __typename?: 'OrderRejectionReasonCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'OrderRejectionReasonCountableEdge', node: { __typename?: 'OrderRejectionReason', id: string, reason: string, type: Types.OrderRejectionReasonType } }> } | null };


export const OrderRejectionReasonsDocument = gql`
    query orderRejectionReasons($after: String, $first: Int, $last: Int, $before: String) {
  orderRejectionReasons(
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    totalCount
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        id
        reason
        type
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useOrderRejectionReasonsQuery__
 *
 * To run a query within a React component, call `useOrderRejectionReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderRejectionReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderRejectionReasonsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useOrderRejectionReasonsQuery(baseOptions?: Apollo.QueryHookOptions<OrderRejectionReasonsQuery, OrderRejectionReasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderRejectionReasonsQuery, OrderRejectionReasonsQueryVariables>(OrderRejectionReasonsDocument, options);
      }
export function useOrderRejectionReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderRejectionReasonsQuery, OrderRejectionReasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderRejectionReasonsQuery, OrderRejectionReasonsQueryVariables>(OrderRejectionReasonsDocument, options);
        }
export type OrderRejectionReasonsQueryHookResult = ReturnType<typeof useOrderRejectionReasonsQuery>;
export type OrderRejectionReasonsLazyQueryHookResult = ReturnType<typeof useOrderRejectionReasonsLazyQuery>;
export type OrderRejectionReasonsQueryResult = Apollo.QueryResult<OrderRejectionReasonsQuery, OrderRejectionReasonsQueryVariables>;