/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { BranchErrorsFragmentFragmentDoc } from '../../../../fragment/BranchesErrors/__generated__/BranchesErrorsFragment';
import { BranchFragmentFragmentDoc } from '../../../../fragment/branch/__generated__/branchFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BranchDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type BranchDeleteMutation = { __typename?: 'Mutation', branchDelete?: { __typename?: 'BranchDelete', branchErrors: Array<{ __typename?: 'BranchError', field?: string | null, message?: string | null, code: Types.VendorErrorCode }>, branch?: { __typename?: 'Branch', id: string, isActive: boolean, name: string, nameAr?: string | null, description: string } | null } | null };


export const BranchDeleteDocument = gql`
    mutation BranchDelete($id: ID!) {
  branchDelete(id: $id) {
    branchErrors {
      ...BranchErrorsFragment
    }
    branch {
      ...BranchFragment
    }
  }
}
    ${BranchErrorsFragmentFragmentDoc}
${BranchFragmentFragmentDoc}`;
export type BranchDeleteMutationFn = Apollo.MutationFunction<BranchDeleteMutation, BranchDeleteMutationVariables>;

/**
 * __useBranchDeleteMutation__
 *
 * To run a mutation, you first call `useBranchDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBranchDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [branchDeleteMutation, { data, loading, error }] = useBranchDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBranchDeleteMutation(baseOptions?: Apollo.MutationHookOptions<BranchDeleteMutation, BranchDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BranchDeleteMutation, BranchDeleteMutationVariables>(BranchDeleteDocument, options);
      }
export type BranchDeleteMutationHookResult = ReturnType<typeof useBranchDeleteMutation>;
export type BranchDeleteMutationResult = Apollo.MutationResult<BranchDeleteMutation>;
export type BranchDeleteMutationOptions = Apollo.BaseMutationOptions<BranchDeleteMutation, BranchDeleteMutationVariables>;