import { i18next } from "@health/i18n";
import { BusinessRulesIcon } from "@health/ui";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import OrderTimeoutsForm from "./OrderTimeoutsForm/OrderTimeoutsForm";
import { PatientEligibilityPlanForm } from "./PatientEligibilityPlanForm";
import PatientEligibilityPlanList from "./PatientEligibilityPlanList/PatientEligibilityPlans";
import PharmacyListingCriteriaList from "./PharmacyListingCriteriaList/PharmacyListingCriteria";
import PharmacyListingForm from "./PharmacyListingForm/PharmacyListingForm";
import { SubListForm } from "./SubListForm";
import EditSubListItem from "./SubListItemForm/EditSubListItem";
import { SubListsManagementList } from "./SubListsManagementList";

const baseBath = "system-rules";
export const decisionFormRelative = "decision";
export const decisionsListRelative = "patient-eligibility-plans";
export const SubListsManagementRelative = "sub-lists-management";
export const getSystemRulesRouteTree = (navigate: NavigateFunction, t: i18next.TFunction): RouteItem => {
  const form = t("form");
  return {
    id: "system-rules",
    text: t("System & Business Rules"),
    icon: <BusinessRulesIcon />,
    route: baseBath,
    subItems: [
      {
        id: "system-rules order-timeouts",
        route: "order-timeouts",
        fullPath: `${baseBath}/order-timeouts`,
        text: t("Order Timeouts"),
        element: <OrderTimeoutsForm />,
        onClick: route => navigate(route),
      },
      {
        id: "system-rules pharmacy-listing-criteria-form",
        route: "pharmacy-listing-criteria/form/:id",
        fullPath: `${baseBath}/pharmacy-listing-criteria-form/:id`,
        text: t("Pharmacy Listing Criteria form"),
        element: <PharmacyListingForm />,
        hidden: true,
      },
      {
        id: "system-rules pharmacy-listing-criteria",
        route: "pharmacy-listing-criteria",
        fullPath: `${baseBath}/pharmacy-listing-criteria`,
        text: t("Pharmacy Listing Criteria"),
        element: <PharmacyListingCriteriaList />,
        onClick: route => navigate(route),
      },
      {
        id: "system-rules patient-eligibility-plans",
        route: `${decisionsListRelative}`,
        fullPath: `${baseBath}/${decisionsListRelative}`,
        text: t("Patient Eligibility Plans"),
        element: <PatientEligibilityPlanList />,
        onClick: route => navigate(route),
        subItems: [],
      },
      {
        id: "system-rules decision/form add",
        route: `${decisionsListRelative}/${decisionFormRelative}`,
        fullPath: `${baseBath}/${decisionsListRelative}/${decisionFormRelative}`,
        text: t("Patient Eligibility Plan"),
        element: <PatientEligibilityPlanForm />,
        hidden: true,
      },
      {
        id: "system-rules decision/form edit",
        route: `${decisionsListRelative}/${decisionFormRelative}/:id`,
        fullPath: `${baseBath}/${decisionsListRelative}/${decisionFormRelative}/:id`,
        text: t("Patient Eligibility Plan"),
        element: <PatientEligibilityPlanForm />,
        hidden: true,
      },
      {
        id: "system-rules Sub-lists Management",
        route: `${SubListsManagementRelative}`,
        fullPath: `${baseBath}/${SubListsManagementRelative}`,
        text: t("Sub Lists Management"),
        element: <SubListsManagementList />,
        onClick: route => navigate(route),
      },
      {
        id: "system-rules Sub-lists Management new form",
        route: `${SubListsManagementRelative}/${form}`,
        fullPath: `${baseBath}/${SubListsManagementRelative}/${form}`,
        text: t("SubList Form New "),
        element: <SubListForm />,
        hidden: true,
      },
      {
        id: "system-rules Sub-lists Management form edit",
        route: `${SubListsManagementRelative}/form/:id`,
        fullPath: `${baseBath}/${SubListsManagementRelative}/form/:id`,
        text: t("SubList Form Edit"),
        element: <SubListForm />,
        hidden: true,
      },
      {
        id: "system-rules Sub-lists Management form edit",
        route: `${SubListsManagementRelative}/form/:id/items/:itemId`,
        fullPath: `${baseBath}/${SubListsManagementRelative}/form/:id/items/:itemId`,
        text: t("SubList Item Form Edit"),
        element: <EditSubListItem />,
        hidden: true,
      },
    ],
    /** AddHere */
  };
};
