import { usePrevious } from "@health/common";
import { AvatarProps, default as MuiAvatar } from "@mui/material/Avatar";
import React, { Fragment, useEffect, useState } from "react";
import { getFallBackImage } from "../images";

export const Avatar = (props: AvatarProps) => {
  const [error, setError] = useState(false);
  const oldSrc = usePrevious(props.src);

  useEffect(() => {
    if (oldSrc !== props.src) setError(false);
  }, [props.src, oldSrc]);
  const ImageSvg = getFallBackImage();
  return (
    <Fragment key={props.src}>
      {!props.src || error ? (
        <ImageSvg sx={props.sx} className={props.className} />
      ) : (
        <MuiAvatar
          {...props}
          src={props.src}
          imgProps={{
            onError: () => {
              setError(true);
            },
          }}
          {...{
            onError: () => {
              setError(true);
            },
          }}
        />
      )}
    </Fragment>
  );
};
