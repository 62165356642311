/* eslint-disable */
/* prettier-ignore */
import * as Types from '@health/queries/dist/graphql/types';

import { gql } from '@apollo/client';
import { RuleFieldFragmentDoc } from './RuleField';
export type RuleConditionFragment = { __typename?: 'Condition', id?: string | null, conditionOperation?: Types.ConditionOperation | null, entityType?: Types.EntityType | null, isGroup?: boolean | null, operator?: Types.Operator | null, value?: { __typename?: 'ConditionValue', valueDate?: any | null, valueList?: string | null, valueID?: string | null, valueNumber?: number | null, valueString?: string | null } | null, field?: { __typename?: 'Field', id?: string | null, code?: string | null, display?: string | null, operators?: Array<{ __typename?: 'FieldOperator', id?: string | null, operator?: Types.Operator | null } | null> | null, fieldType?: { __typename?: 'FieldType', id?: string | null, type?: Types.EntityType | null } | null } | null };

export const RuleConditionFragmentDoc = gql`
    fragment RuleCondition on Condition {
  id
  conditionOperation
  entityType
  isGroup
  operator
  value {
    valueDate
    valueList
    valueID
    valueNumber
    valueString
  }
  field {
    ...RuleField
  }
}
    ${RuleFieldFragmentDoc}`;