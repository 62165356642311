/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
export type ProductClientFragmentFragment = { __typename?: 'VendorProductVariant', id: string, vendor?: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null, logo?: string | null } | null, productVariant: { __typename?: 'ProductVariant', id: string, rating?: number | null, images?: Array<{ __typename?: 'ProductImage', url: string } | null> | null, product?: { __typename?: 'Product', id: string, name?: string | null, description?: string | null, descriptionJson?: any | null, isLiked?: boolean | null, category?: { __typename?: 'Category', id: string, name?: string | null } | null, images?: Array<{ __typename?: 'ProductImage', id: string, url: string } | null> | null } | null }, pricing?: { __typename?: 'VariantPricingInfo', onSale?: boolean | null, price?: { __typename?: 'TaxedMoney', gross: { __typename?: 'Money', amount: number, currency: string } } | null, priceUndiscounted?: { __typename?: 'TaxedMoney', gross: { __typename?: 'Money', amount: number, currency: string } } | null, discount?: { __typename?: 'TaxedMoney', gross: { __typename?: 'Money', amount: number, currency: string } } | null } | null };

export const ProductClientFragmentFragmentDoc = gql`
    fragment ProductClientFragment on VendorProductVariant {
  id
  vendor {
    id
    name
    nameAr
    logo
  }
  productVariant {
    id
    images {
      url
    }
    product {
      id
      name
      description
      descriptionJson
      category {
        id
        name
      }
      images {
        id
        url
      }
      isLiked
    }
    rating
  }
  pricing {
    onSale
    price {
      gross {
        amount
        currency
      }
    }
    priceUndiscounted {
      gross {
        amount
        currency
      }
    }
    discount {
      gross {
        amount
        currency
      }
    }
  }
}
    `;