/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { AddressFragmentFragmentDoc } from '../../../../fragment/address/__generated__/address';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CustomersQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  sortBy?: Types.InputMaybe<Types.UserSortingInput>;
  filter?: Types.InputMaybe<Types.CustomerFilterInput>;
}>;


export type CustomersQuery = { __typename?: 'Query', customers?: { __typename?: 'UserCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'UserCountableEdge', cursor: string, node: { __typename?: 'User', id: string, email: string, firstName?: string | null, lastName?: string | null, mobile?: string | null, avatar?: string | null, totlaRevenue?: { __typename?: 'TaxedMoney', currency: string, gross: { __typename?: 'Money', amount: number, currency: string }, net: { __typename?: 'Money', amount: number, currency: string } } | null, orderedPlaced?: { __typename: 'Address', id: string, name?: string | null, area?: string | null, streetAddress1?: string | null, streetAddress2?: string | null, buildingName?: string | null, buildingNumber?: string | null, phone?: string | null, isDefaultShippingAddress?: boolean | null, region?: string | null, city: { __typename?: 'City', id: string, name: string, nameAr?: string | null }, coordinates?: { __typename?: 'LocationType', lng?: number | null, lat?: number | null } | null, translations?: Array<{ __typename?: 'AddressTranslation', streetAddress1: string, streetAddress2: string, area: string, language: { __typename?: 'LanguageDisplay', code: Types.LanguageCodeEnum, language: string } } | null> | null } | null, ordersCount?: { __typename?: 'OrderCountableConnection', totalCount?: number | null } | null, firstOrderedAt?: { __typename?: 'OrderCountableConnection', edges: Array<{ __typename?: 'OrderCountableEdge', node: { __typename?: 'Order', created: any } }> } | null, lastOrderedAt?: { __typename?: 'OrderCountableConnection', edges: Array<{ __typename?: 'OrderCountableEdge', node: { __typename?: 'Order', created: any } }> } | null } }> } | null };


export const CustomersDocument = gql`
    query Customers($first: Int, $after: String, $last: Int, $before: String, $sortBy: UserSortingInput, $filter: CustomerFilterInput) {
  customers(
    first: $first
    after: $after
    last: $last
    before: $before
    sortBy: $sortBy
    filter: $filter
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        email
        firstName
        lastName
        mobile
        avatar
        totlaRevenue: totalOrdersAmount {
          gross {
            amount
            currency
          }
          net {
            amount
            currency
          }
          currency
        }
        orderedPlaced: defaultShippingAddress {
          ...AddressFragment
        }
        ordersCount: orders {
          totalCount
        }
        firstOrderedAt: orders(first: 1, sortBy: {field: CREATION_DATE, direction: ASC}) {
          edges {
            node {
              created
            }
          }
        }
        lastOrderedAt: orders(first: 1, sortBy: {field: CREATION_DATE, direction: DESC}) {
          edges {
            node {
              created
            }
          }
        }
      }
      cursor
    }
    totalCount
  }
}
    ${AddressFragmentFragmentDoc}`;

/**
 * __useCustomersQuery__
 *
 * To run a query within a React component, call `useCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      sortBy: // value for 'sortBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCustomersQuery(baseOptions?: Apollo.QueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
      }
export function useCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
        }
export type CustomersQueryHookResult = ReturnType<typeof useCustomersQuery>;
export type CustomersLazyQueryHookResult = ReturnType<typeof useCustomersLazyQuery>;
export type CustomersQueryResult = Apollo.QueryResult<CustomersQuery, CustomersQueryVariables>;