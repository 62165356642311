import { useAccountReSendOtpCodeMutation } from "@health/queries";
import { dialogStep } from "./dialog/utils";
import { registerVar } from "./reactiveVar";

export const useAccountReSendOtpCodeHook = (mobile, onChangeStep, isForget = false) => {
  const register = registerVar();

  const [accountReSendOtpCodeMutation, { loading: isLoading }] = useAccountReSendOtpCodeMutation({
    onCompleted: data => {
      const errors = data?.accountReSendOtpCode?.errors;

      if (errors?.length === 0) {
        registerVar({ ...register, mobile, sessionToken: data?.accountReSendOtpCode?.sessionToken, isForget });
        onChangeStep(dialogStep.verify);
      }
    },
  });

  const handleResendCode = () => {
    accountReSendOtpCodeMutation({
      variables: {
        mobile,
      },
    });
  };

  return {
    isLoading,
    handleResendCode,
  };
};
