/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VendorWebHookQueryVariables = Types.Exact<{
  vendorId: Types.Scalars['ID'];
}>;


export type VendorWebHookQuery = { __typename?: 'Query', vendorWebHook?: { __typename?: 'VendorWebhook', errors?: Array<Types.IntegrationApiErrorCodes | null> | null, isActive?: boolean | null, secretKey?: string | null, vendorUrl?: string | null, vendor?: { __typename?: 'Vendor', id: string } | null } | null };


export const VendorWebHookDocument = gql`
    query vendorWebHook($vendorId: ID!) {
  vendorWebHook(vendor: $vendorId) {
    errors
    isActive
    secretKey
    vendor {
      id
    }
    vendorUrl
  }
}
    `;

/**
 * __useVendorWebHookQuery__
 *
 * To run a query within a React component, call `useVendorWebHookQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorWebHookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorWebHookQuery({
 *   variables: {
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useVendorWebHookQuery(baseOptions: Apollo.QueryHookOptions<VendorWebHookQuery, VendorWebHookQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VendorWebHookQuery, VendorWebHookQueryVariables>(VendorWebHookDocument, options);
      }
export function useVendorWebHookLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VendorWebHookQuery, VendorWebHookQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VendorWebHookQuery, VendorWebHookQueryVariables>(VendorWebHookDocument, options);
        }
export type VendorWebHookQueryHookResult = ReturnType<typeof useVendorWebHookQuery>;
export type VendorWebHookLazyQueryHookResult = ReturnType<typeof useVendorWebHookLazyQuery>;
export type VendorWebHookQueryResult = Apollo.QueryResult<VendorWebHookQuery, VendorWebHookQueryVariables>;