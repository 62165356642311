/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../fragment/PageInfo/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VendorsDropDownOptionsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.VendorFilterInput>;
}>;


export type VendorsDropDownOptionsQuery = { __typename?: 'Query', vendors?: { __typename?: 'VendorCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'VendorCountableEdge', node: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null } }> } | null };


export const VendorsDropDownOptionsDocument = gql`
    query vendorsDropDownOptions($first: Int, $after: String, $before: String, $last: Int, $filter: VendorFilterInput) {
  vendors(
    first: $first
    after: $after
    last: $last
    before: $before
    filter: $filter
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    totalCount
    edges {
      node {
        id
        name
        nameAr
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useVendorsDropDownOptionsQuery__
 *
 * To run a query within a React component, call `useVendorsDropDownOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorsDropDownOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorsDropDownOptionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useVendorsDropDownOptionsQuery(baseOptions?: Apollo.QueryHookOptions<VendorsDropDownOptionsQuery, VendorsDropDownOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VendorsDropDownOptionsQuery, VendorsDropDownOptionsQueryVariables>(VendorsDropDownOptionsDocument, options);
      }
export function useVendorsDropDownOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VendorsDropDownOptionsQuery, VendorsDropDownOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VendorsDropDownOptionsQuery, VendorsDropDownOptionsQueryVariables>(VendorsDropDownOptionsDocument, options);
        }
export type VendorsDropDownOptionsQueryHookResult = ReturnType<typeof useVendorsDropDownOptionsQuery>;
export type VendorsDropDownOptionsLazyQueryHookResult = ReturnType<typeof useVendorsDropDownOptionsLazyQuery>;
export type VendorsDropDownOptionsQueryResult = Apollo.QueryResult<VendorsDropDownOptionsQuery, VendorsDropDownOptionsQueryVariables>;