/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClientVendorRatingsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type ClientVendorRatingsQuery = { __typename?: 'Query', vendor?: { __typename?: 'Vendor', averageRating: number, ratingsSum: number, ratings?: { __typename?: 'VendorRatingCountableConnection', totalCount?: number | null, edges: Array<{ __typename?: 'VendorRatingCountableEdge', node: { __typename?: 'VendorRating', comment?: string | null, rating: number, id: string, customer?: { __typename?: 'User', firstName?: string | null, lastName?: string | null } | null } }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } } | null } | null };


export const ClientVendorRatingsDocument = gql`
    query ClientVendorRatings($id: ID!, $first: Int, $after: String, $before: String, $last: Int) {
  vendor(id: $id) {
    averageRating
    ratingsSum
    ratings(first: $first, after: $after, before: $before, last: $last) {
      edges {
        node {
          comment
          customer {
            firstName
            lastName
          }
          rating
          id
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
}
    `;

/**
 * __useClientVendorRatingsQuery__
 *
 * To run a query within a React component, call `useClientVendorRatingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientVendorRatingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientVendorRatingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useClientVendorRatingsQuery(baseOptions: Apollo.QueryHookOptions<ClientVendorRatingsQuery, ClientVendorRatingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientVendorRatingsQuery, ClientVendorRatingsQueryVariables>(ClientVendorRatingsDocument, options);
      }
export function useClientVendorRatingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientVendorRatingsQuery, ClientVendorRatingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientVendorRatingsQuery, ClientVendorRatingsQueryVariables>(ClientVendorRatingsDocument, options);
        }
export type ClientVendorRatingsQueryHookResult = ReturnType<typeof useClientVendorRatingsQuery>;
export type ClientVendorRatingsLazyQueryHookResult = ReturnType<typeof useClientVendorRatingsLazyQuery>;
export type ClientVendorRatingsQueryResult = Apollo.QueryResult<ClientVendorRatingsQuery, ClientVendorRatingsQueryVariables>;