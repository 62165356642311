import { withStyles, WithStyles } from "@mui/styles";
import React, { FC } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { styles } from "./styles";

type InfiniteScrollContainerProps = WithStyles<typeof styles> & {
  dataLength: number;
  next: () => void;
  hasMore: boolean;
  loader: JSX.Element;
  height?: number;
  scrollableTarget?: string;
};

const InfiniteScrollContainer: FC<InfiniteScrollContainerProps> = props => {
  const { dataLength, children, next, hasMore, loader, scrollableTarget, classes } = props;

  return (
    <InfiniteScroll
      dataLength={dataLength || 0}
      next={next}
      hasMore={hasMore}
      loader={loader}
      scrollableTarget={scrollableTarget}
      className={classes.infinite}
      {...props}
    >
      {children}
    </InfiniteScroll>
  );
};

export default withStyles(styles)(InfiniteScrollContainer);

InfiniteScrollContainer.defaultProps = {
  height: undefined,
  scrollableTarget: undefined,
};
