import { useTranslation } from "@health/i18n";
import { Address, useAddressesQuery } from "@health/queries";
import { AddIcon, Autocomplete, ExpandMoreIcon, Grid, TextField, Typography } from "@health/ui";
import React, { FC, useEffect, useState } from "react";
import { anoynemousAddressVar, isAddressFormDialogOpenVar } from "../AddressFormDialog/AddressesFormDialog.hook";
import { AddressFormDialog } from "../AddressFormDialog/AddressFormDialog.component";
import { AddAnonymousAddressOption, AnonymousAddressOption } from "./constants";
import { useStyles } from "./styles";
import { AddressPickerWithAnonymousProps } from "./types";

export const AddressPickerWithAnonymous: FC<AddressPickerWithAnonymousProps> = props => {
  const { classes } = useStyles();
  const { autofill = true, selectedAddress, onAddressSelect, rootGridProps, outterFormGridProps, errorText, hasErrors } = props;
  const [localAddress, setLocalAddress] = useState<Address | null | undefined>(null);
  const { data: addressesData } = useAddressesQuery();
  const addresses = addressesData?.me?.addresses as Address[];

  const defaultAddress = addresses?.find(d => d?.isDefaultShippingAddress);
  const { t } = useTranslation();

  useEffect(() => {
    if (!selectedAddress) return;
    anoynemousAddressVar(selectedAddress);
    setLocalAddress(selectedAddress);
  }, [selectedAddress]);

  useEffect(() => {
    autofill && defaultAddress?.id && onAddressSelect(defaultAddress as Address);
  }, [defaultAddress?.id, autofill]);

  const handleAddressChange = (event: any, newValue: Address | null) => {
    setLocalAddress(newValue);
    if (newValue?.id === AnonymousAddressOption.id) {
      event?.preventDefault();
      anoynemousAddressVar(AnonymousAddressOption);
      handleShowAddAddressDialog();
      return;
    }
    onAddressSelect(newValue);
  };

  const handleCancel = () => {
    if (!localAddress?.coordinates?.lat && !localAddress?.coordinates?.lng) {
      setLocalAddress(null);
      onAddressSelect(null);
    }
  };

  const handleDialogSelectAddress = (addressInput?: Address) => {
    const address = addressInput?.id === AnonymousAddressOption?.id ? addressInput : addresses?.find(d => d?.id === addressInput?.id);
    setLocalAddress(address);
    onAddressSelect(addressInput as Address);
  };
  const handleShowAddAddressDialog = () => {
    isAddressFormDialogOpenVar(true);
    return false;
  };

  const hasUnSavedAdderess = localAddress?.id === AnonymousAddressOption?.id;

  return (
    <Grid container m={"10px 0px"} justifyContent={"center"} {...rootGridProps}>
      <Grid xs={7.8} sm={7.8} lg={7.8} {...outterFormGridProps} item>
        <Autocomplete
          popupIcon={<ExpandMoreIcon />}
          onChange={handleAddressChange}
          value={localAddress}
          isOptionEqualToValue={(option: Address, value: Address) => {
            return option?.id === value?.id;
          }}
          getOptionLabel={(option: Address) => option.name || option.id}
          renderOption={(oProps, option) => {
            return option.id === AddAnonymousAddressOption.id ? (
              <Typography {...oProps} data-testid='addOption'>
                <AddIcon />
                <Typography sx={{ margin: "0 10px" }}>{t("Add")}</Typography>
              </Typography>
            ) : (
              <Typography {...oProps}>{option?.name}</Typography>
            );
          }}
          options={[hasUnSavedAdderess ? localAddress : AddAnonymousAddressOption, ...(addresses || [])]}
          data-testid='selectAddressAutoComplete'
          renderInput={params => (
            <TextField
              classes={{ root: classes.autocomplete }}
              {...params}
              hiddenLabel
              inputProps={{ ...params.inputProps, value: params.inputProps?.["value"] }}
              placeholder={t("Select address")}
              data-testid='selectAddressAutoCompleteTextField'
              fullWidth
              error={hasErrors}
              helperText={hasErrors && errorText}
            />
          )}
        />
      </Grid>
      <Grid xs={outterFormGridProps ? "auto" : 4.2} item>
        <AddressFormDialog onSelectAddress={handleDialogSelectAddress} address={localAddress} onCancel={handleCancel} />
      </Grid>
    </Grid>
  );
};
