import { useTranslation } from "@health/i18n";
import { Button, Dialog } from "@mui/material";
import React, { FC } from "react";
import { FilterIcon } from "../../icons";
import { CustomMuiDialogActions, CustomMuiDialogContent, CustomMuiDialogTitle } from "../CustomMuiDialog";
import { Filter } from "../Filter";
import { useStaticFilterDialogHooks } from "./StaticFilterDialog.hooks";
import { StyledIconButton } from "./StaticFilterDialog.style";
import { StaticFilterDialogProps } from "./StaticFilterDialog.types";

export const StaticFilterDialog: FC<StaticFilterDialogProps> = props => {
  const { t } = useTranslation();
  const { filterFields, formState, isOpen, control, handleToggle, handleSubmit, register, handleSubmitClicked, handleFormReset } =
    useStaticFilterDialogHooks(props);

  return (
    <>
      <React.Fragment>
        <StyledIconButton color='primary' size='medium' onClick={handleToggle} id='filterDialogButton' disableFocusRipple>
          <FilterIcon />
        </StyledIconButton>
        <Dialog
          maxWidth={"md"}
          fullWidth
          open={isOpen}
          PaperProps={{
            style: { borderRadius: "15px" },
          }}
          scroll={"paper"}
          aria-labelledby='scroll-dialog-title'
          aria-describedby='scroll-dialog-description'
        >
          <form onSubmit={handleSubmit(handleSubmitClicked)}>
            <CustomMuiDialogTitle onClose={handleToggle} title={t("Filter")} />
            <CustomMuiDialogContent>
              {filterFields && <Filter fields={filterFields} register={register} control={control} />}
            </CustomMuiDialogContent>
            <CustomMuiDialogActions>
              <Button disabled={formState.isSubmitting} type='submit'>
                {t("Submit")}
              </Button>
              <Button variant='outlined' onClick={handleFormReset}>
                {t("Reset")}
              </Button>
            </CustomMuiDialogActions>
          </form>
        </Dialog>
      </React.Fragment>
    </>
  );
};
