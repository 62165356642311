/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type VendorOrdersHistoryUserFragmentFragment = { __typename?: 'User', id: string, email: string, nationalId?: string | null, firstName?: string | null, lastName?: string | null, mobile?: string | null };

export const VendorOrdersHistoryUserFragmentFragmentDoc = gql`
    fragment VendorOrdersHistoryUserFragment on User {
  id
  email
  nationalId
  firstName
  lastName
  mobile
}
    `;