/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { UserFragmentFragmentDoc } from '../../../../fragment/User/__generated__/User';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VendorUserByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type VendorUserByIdQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, email: string, firstName?: string | null, lastName?: string | null, mobile?: string | null, isStaff?: boolean | null, isSuperuser?: boolean | null, isVendorAdmin?: boolean | null, note?: string | null, healthLicenseNumber?: string | null, healthLicenseStartDate?: any | null, healthLicenseEndDate?: any | null, appType?: Types.AppTypes | null, appRole?: Types.AppRoleTypes | null, isActive: boolean, vendor?: { __typename?: 'Vendor', id: string, name: string } | null, branches?: Array<{ __typename?: 'Branch', id: string, name: string } | null> | null, permissionGroups?: Array<{ __typename?: 'Group', id: string, name: string, permissions?: Array<{ __typename?: 'Permission', name: string, code: Types.PermissionEnum } | null> | null } | null> | null } | null };


export const VendorUserByIdDocument = gql`
    query vendorUserById($id: ID!) {
  user(id: $id) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useVendorUserByIdQuery__
 *
 * To run a query within a React component, call `useVendorUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVendorUserByIdQuery(baseOptions: Apollo.QueryHookOptions<VendorUserByIdQuery, VendorUserByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VendorUserByIdQuery, VendorUserByIdQueryVariables>(VendorUserByIdDocument, options);
      }
export function useVendorUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VendorUserByIdQuery, VendorUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VendorUserByIdQuery, VendorUserByIdQueryVariables>(VendorUserByIdDocument, options);
        }
export type VendorUserByIdQueryHookResult = ReturnType<typeof useVendorUserByIdQuery>;
export type VendorUserByIdLazyQueryHookResult = ReturnType<typeof useVendorUserByIdLazyQuery>;
export type VendorUserByIdQueryResult = Apollo.QueryResult<VendorUserByIdQuery, VendorUserByIdQueryVariables>;