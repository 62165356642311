/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { AttributeFragmentFragmentDoc } from '../../../../fragment/attributes/__generated__/attributes';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AttributeQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type AttributeQuery = { __typename?: 'Query', attribute?: { __typename?: 'Attribute', inputType?: Types.AttributeInputTypeEnum | null, name?: string | null, id: string, slug?: string | null, values?: Array<{ __typename?: 'AttributeValue', id: string, name?: string | null } | null> | null } | null };


export const AttributeDocument = gql`
    query attribute($id: ID!) {
  attribute(id: $id) {
    ...AttributeFragment
    inputType
  }
}
    ${AttributeFragmentFragmentDoc}`;

/**
 * __useAttributeQuery__
 *
 * To run a query within a React component, call `useAttributeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttributeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttributeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAttributeQuery(baseOptions: Apollo.QueryHookOptions<AttributeQuery, AttributeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AttributeQuery, AttributeQueryVariables>(AttributeDocument, options);
      }
export function useAttributeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AttributeQuery, AttributeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AttributeQuery, AttributeQueryVariables>(AttributeDocument, options);
        }
export type AttributeQueryHookResult = ReturnType<typeof useAttributeQuery>;
export type AttributeLazyQueryHookResult = ReturnType<typeof useAttributeLazyQuery>;
export type AttributeQueryResult = Apollo.QueryResult<AttributeQuery, AttributeQueryVariables>;