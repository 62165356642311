import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { FC, Fragment, ReactNode } from "react";
import { darkGrey } from "../../customization/colors";
import { EmptyBoxIcon } from "../../icons/icons";
import { useEmptyCardStyles } from "./EmptyCard.styles";

interface EmptyCardProps {
  title?: ReactNode;
  icon?: ReactNode;
  message?: ReactNode;
}

export const EmptyCard: FC<EmptyCardProps> = props => {
  const { title, icon, message, ...BoxProps } = props;
  const { classes } = useEmptyCardStyles();

  return (
    <Box className={classes.mainDiv} {...BoxProps}>
      {icon ? (
        icon
      ) : (
        <EmptyBoxIcon
          sx={{
            height: "130px",
            width: "130px",
          }}
          fill='#5b7798'
        />
      )}
      <Fragment>
        {title && (
          <Typography fontSize='20px' color={darkGrey} noWrap>
            {title}
          </Typography>
        )}
        {message && (
          <Typography fontSize='16px' color={darkGrey} gutterBottom noWrap>
            {message}
          </Typography>
        )}
      </Fragment>
    </Box>
  );
};
EmptyCard.defaultProps = {
  title: "No content has been added yet",
  message: "Start by adding data assets",
  icon: undefined,
};
