/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CustomerMarkMessagesAsSeenMutationVariables = Types.Exact<{
  branchId: Types.Scalars['String'];
}>;


export type CustomerMarkMessagesAsSeenMutation = { __typename?: 'Mutation', customerMarkMessagesAsSeen?: { __typename?: 'CustomerMarkMessagesAsSeen', updatedCount?: number | null, chatErrors: Array<{ __typename?: 'ChatError', field?: string | null, message?: string | null }> } | null };


export const CustomerMarkMessagesAsSeenDocument = gql`
    mutation customerMarkMessagesAsSeen($branchId: String!) {
  customerMarkMessagesAsSeen(branchId: $branchId) {
    chatErrors {
      field
      message
    }
    updatedCount
  }
}
    `;
export type CustomerMarkMessagesAsSeenMutationFn = Apollo.MutationFunction<CustomerMarkMessagesAsSeenMutation, CustomerMarkMessagesAsSeenMutationVariables>;

/**
 * __useCustomerMarkMessagesAsSeenMutation__
 *
 * To run a mutation, you first call `useCustomerMarkMessagesAsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerMarkMessagesAsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerMarkMessagesAsSeenMutation, { data, loading, error }] = useCustomerMarkMessagesAsSeenMutation({
 *   variables: {
 *      branchId: // value for 'branchId'
 *   },
 * });
 */
export function useCustomerMarkMessagesAsSeenMutation(baseOptions?: Apollo.MutationHookOptions<CustomerMarkMessagesAsSeenMutation, CustomerMarkMessagesAsSeenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CustomerMarkMessagesAsSeenMutation, CustomerMarkMessagesAsSeenMutationVariables>(CustomerMarkMessagesAsSeenDocument, options);
      }
export type CustomerMarkMessagesAsSeenMutationHookResult = ReturnType<typeof useCustomerMarkMessagesAsSeenMutation>;
export type CustomerMarkMessagesAsSeenMutationResult = Apollo.MutationResult<CustomerMarkMessagesAsSeenMutation>;
export type CustomerMarkMessagesAsSeenMutationOptions = Apollo.BaseMutationOptions<CustomerMarkMessagesAsSeenMutation, CustomerMarkMessagesAsSeenMutationVariables>;