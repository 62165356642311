/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type AttributeFragmentFragment = { __typename?: 'Attribute', name?: string | null, id: string, slug?: string | null, values?: Array<{ __typename?: 'AttributeValue', id: string, name?: string | null } | null> | null };

export const AttributeFragmentFragmentDoc = gql`
    fragment AttributeFragment on Attribute {
  name
  id
  slug
  values {
    id
    name
  }
}
    `;