/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EarlyRefillRequestCreateMutationVariables = Types.Exact<{
  input: Types.EarlyRefillRequestInput;
}>;


export type EarlyRefillRequestCreateMutation = { __typename?: 'Mutation', earlyRefillRequestCreate?: { __typename?: 'EarlyRefillRequestCreate', earlyRefillRequest?: { __typename?: 'EarlyRefillRequest', status: Types.EarlyRefillRequestStatus, id: string } | null, earlyRefillRequestErrors: Array<{ __typename?: 'RefillError', code: Types.RefillErrorCode, field?: string | null, message?: string | null }>, errors: Array<{ __typename?: 'Error', field?: string | null, message?: string | null }> } | null };


export const EarlyRefillRequestCreateDocument = gql`
    mutation EarlyRefillRequestCreate($input: EarlyRefillRequestInput!) {
  earlyRefillRequestCreate(input: $input) {
    earlyRefillRequest {
      status
      id
    }
    earlyRefillRequestErrors {
      code
      field
      message
    }
    errors {
      field
      message
    }
  }
}
    `;
export type EarlyRefillRequestCreateMutationFn = Apollo.MutationFunction<EarlyRefillRequestCreateMutation, EarlyRefillRequestCreateMutationVariables>;

/**
 * __useEarlyRefillRequestCreateMutation__
 *
 * To run a mutation, you first call `useEarlyRefillRequestCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEarlyRefillRequestCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [earlyRefillRequestCreateMutation, { data, loading, error }] = useEarlyRefillRequestCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEarlyRefillRequestCreateMutation(baseOptions?: Apollo.MutationHookOptions<EarlyRefillRequestCreateMutation, EarlyRefillRequestCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EarlyRefillRequestCreateMutation, EarlyRefillRequestCreateMutationVariables>(EarlyRefillRequestCreateDocument, options);
      }
export type EarlyRefillRequestCreateMutationHookResult = ReturnType<typeof useEarlyRefillRequestCreateMutation>;
export type EarlyRefillRequestCreateMutationResult = Apollo.MutationResult<EarlyRefillRequestCreateMutation>;
export type EarlyRefillRequestCreateMutationOptions = Apollo.BaseMutationOptions<EarlyRefillRequestCreateMutation, EarlyRefillRequestCreateMutationVariables>;