/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { CategoryFragmentFragmentDoc } from '../../../../fragment/categories/__generated__/Category';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CategoryQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type CategoryQuery = { __typename?: 'Query', category?: { __typename?: 'Category', seoTitle?: string | null, sortOrder?: number | null, id: string, name?: string | null, level: number, description?: string | null, slug: string, parent?: { __typename?: 'Category', id: string, name?: string | null, description?: string | null, slug: string, backgroundImage?: { __typename?: 'Image', url?: string | null, alt?: string | null } | null } | null, backgroundImage?: { __typename?: 'Image', url?: string | null, alt?: string | null } | null, children?: { __typename?: 'CategoryCountableConnection', totalCount?: number | null } | null } | null };


export const CategoryDocument = gql`
    query category($id: ID!) {
  category(id: $id) {
    ...CategoryFragment
    seoTitle
    sortOrder
    parent {
      id
      name
      description
      backgroundImage {
        url
        alt
      }
      slug
    }
  }
}
    ${CategoryFragmentFragmentDoc}`;

/**
 * __useCategoryQuery__
 *
 * To run a query within a React component, call `useCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCategoryQuery(baseOptions: Apollo.QueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options);
      }
export function useCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options);
        }
export type CategoryQueryHookResult = ReturnType<typeof useCategoryQuery>;
export type CategoryLazyQueryHookResult = ReturnType<typeof useCategoryLazyQuery>;
export type CategoryQueryResult = Apollo.QueryResult<CategoryQuery, CategoryQueryVariables>;