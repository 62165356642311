import { CustomTable, Grid } from "@health/ui";
import React, { FC } from "react";
import { ProviderCard } from "shared/components";
import { useProviderUsersHook } from "./ProviderUsers.hook";

const ProviderUsersManagement: FC = () => {
  const { columns, loading, users, vendor, handleSortColumn } = useProviderUsersHook();

  return (
    <Grid container direction='column' spacing={2} m={2}>
      <Grid item xs={12}>
        <ProviderCard loading={loading} vendor={vendor} />
      </Grid>
      <Grid item xs={12}>
        <CustomTable
          data={users}
          columns={columns}
          pageSize={10}
          isLoading={loading}
          pageIndex={10}
          onSortColumn={handleSortColumn}
          pagesCount={10}
        />
      </Grid>
    </Grid>
  );
};
export default ProviderUsersManagement;
