import { getMaxValueValidation, getMinValueValidation, getRequiredValidation } from "@health/common";
import { useTranslation } from "@health/i18n";
import { FormActions, FormCard, getTranslatedNameField, Grid, PageWrapper, Typography } from "@health/ui";
import { FC } from "react";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import NumericField from "../NumericField";
import { PharmacyTypesFields } from "./PharmacyTypeFieldItem";
import usePharmacyListingForm from "./usePharmacyListingForm";

type PharmacyListingCriteriaFormProps = { id?: string };
const PharmacyListingCriteriaForm: FC<PharmacyListingCriteriaFormProps> = () => {
  const { t } = useTranslation("Admin");
  const { id } = useParams<{ id: string }>();
  const { defaults, methods, roundsFields, isSubmitting, isDataLoading, maxNumberOfRounds, isNotModified, handleSubmit, handleCancel } =
    usePharmacyListingForm({ id });
  return (
    <PageWrapper
      actions={
        <FormActions
          hasCancel
          hasSave
          isSaveDisabled={isNotModified(methods.watch()) || Object.values(methods.formState.errors).length > 0}
          loadingIndicators={{ save: isSubmitting }}
          onSave={handleSubmit}
          onNavigation={handleCancel}
        />
      }
    >
      <FormProvider {...methods}>
        <FormCard loading={isDataLoading} title={t("Range Expansion Logic")} doYouHaveData={true}>
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid container item mb={2}>
                <Grid item xs={3}>
                  <Typography variant='subtitle1'>
                    {t("City")}: {getTranslatedNameField(defaults) || defaults?.name}{" "}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} px={2}>
                <Grid item spacing={2}>
                  <NumericField
                    fieldName='maxNumberOfRounds'
                    label={t("Max Number of rounds")}
                    registerOptions={{
                      required: getRequiredValidation(t, true),
                      max: getMaxValueValidation(t, maxNumberOfRounds),
                      min: getMinValueValidation(t, 1),
                    }}
                    defaultValue={String(defaults?.maxNumberOfRounds)}
                  />
                </Grid>

                <Grid item spacing={2}>
                  <NumericField
                    fieldName='maxNumberOfTries'
                    label={t("Max Number of each try")}
                    registerOptions={{
                      required: getRequiredValidation(t, true),
                      max: getMaxValueValidation(t, 10),
                      min: getMinValueValidation(t, 1),
                    }}
                    defaultValue={String(defaults?.maxNumberOfTries)}
                  />
                </Grid>
                <Grid item spacing={2}>
                  <NumericField
                    fieldName='timeOutPeriod'
                    registerOptions={{
                      required: getRequiredValidation(t, true),
                      max: getMaxValueValidation(t, 60),
                    }}
                    label={t("Timeout to move to next try (minutes)")}
                    defaultValue={String(defaults?.timeOutPeriod)}
                  />
                </Grid>
                <Grid item spacing={2}>
                  <NumericField
                    fieldName='maxRadiusForPickup'
                    label={t("Max radius for pickup (km)")}
                    registerOptions={{
                      required: getRequiredValidation(t, true),
                      max: getMaxValueValidation(t, 10),
                      min: getMinValueValidation(t, 1),
                    }}
                    defaultValue={
                      [null, undefined].includes(defaults?.maxRadiusForPickup! as any)
                        ? (undefined as any)
                        : String(defaults?.maxRadiusForPickup)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </FormCard>
        {roundsFields.map(({ maxNumberOfPharmacies, pharmaciesTypes, radius }, index) => {
          return (
            <FormCard
              key={"round" + index}
              loading={isDataLoading}
              title={t("Round") + " " + (index + 1)}
              doYouHaveData={true}
              sx={{ mt: 2, px: 2, mb: 2 }}
            >
              <Grid container spacing={2} flexDirection='column' rowSpacing={1} paddingX={2} py={0}>
                <Grid container item spacing={2} rowSpacing={2} paddingX={2} py={0}>
                  <Grid item spacing={2}>
                    <NumericField
                      fieldName={`rounds.${index}.radius`}
                      registerOptions={{ max: getMaxValueValidation(t, 1000) }}
                      label={t("Radius in each round (KM)")}
                      defaultValue={radius}
                    />
                  </Grid>
                  <Grid item spacing={2}>
                    <NumericField
                      fieldName={`rounds.${index}.maxNumberOfPharmacies`}
                      label={t("Max Number of Pharmacies in each try")}
                      registerOptions={{ max: getMaxValueValidation(t, 20) }}
                      defaultValue={maxNumberOfPharmacies}
                    />
                  </Grid>
                </Grid>
                <Grid container item flexDirection={"column"} mt={2}>
                  <Grid item xs={12}>
                    <Typography fontSize={16} fontWeight='bold'>
                      {t("Pharmacies Types")}
                    </Typography>
                  </Grid>
                  <Grid item container flexDirection={"column"} xs={12} p={1}>
                    <PharmacyTypesFields pharmaciesTypes={pharmaciesTypes} roundIndex={index} />
                  </Grid>
                </Grid>
              </Grid>
            </FormCard>
          );
        })}
      </FormProvider>
    </PageWrapper>
  );
};
export default PharmacyListingCriteriaForm;
