import { i18n } from "@health/i18n";
import { Permission } from "@health/queries";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import React, { FC, useEffect } from "react";
import { CustomList } from "./CustomList.component";
import { useCustomTransferListStyle } from "./CustomTransferList.styles";
import { CustomTransferListProps } from "./CustomTransferList.types";
import { intersection, not } from "./CustomTransferList.utils";

export const CustomTransferList: FC<CustomTransferListProps> = props => {
  const { ChoicesLabel, ChosenLabel, row, onRightList, defaultPermissions } = props;
  const { classes } = useCustomTransferListStyle();
  const [checked, setChecked] = React.useState<Permission | any | undefined>([]);
  const [source, setSource] = React.useState<Permission | any | undefined>(row);
  const [destination, setDestination] = React.useState<Permission | any | undefined>(defaultPermissions || []);
  const [searchedSource, setSearchedSource] = React.useState(undefined);
  const [searchedDestination, setSearchedDestination] = React.useState(undefined);

  const onSearchSource = value => {
    setSearchedSource(value);
  };

  const onSearchDestination = event => {
    setSearchedDestination(event);
  };

  useEffect(() => {
    setSource(row);
  }, [row, JSON.stringify(row)]);

  const leftChecked = intersection(checked, source);
  const rightChecked = intersection(checked, destination);

  const onToggle = (value: any) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const handleCheckDestination = () => {
    setDestination(destination.concat(leftChecked));
    setSource(not(source, leftChecked));
    setChecked(not(checked, leftChecked));
    onRightList(destination.concat(leftChecked));
  };

  const handleCheckSource = () => {
    setSource(source.concat(rightChecked.length));
    setDestination(not(destination, rightChecked));
    setChecked(not(checked, rightChecked));
    onRightList(not(destination, rightChecked));
  };
  useEffect(() => {
    if (defaultPermissions) {
      setDestination(defaultPermissions);
      setSource(not(source, defaultPermissions));
    }
  }, [JSON.stringify(defaultPermissions)]);
  const currentDir = i18n.dir(i18n.language);

  return (
    <Grid container spacing={2} justifyContent='center' alignItems='center'>
      <Grid
        item
        sx={{
          width: {
            xs: "calc(34% + 8px)",
            sm: "calc(40% + 8px)",
            md: "calc(40% + 14px)",
            lg: "calc(43% + 14px)",
            xl: "calc(45% + 14px)",
          },
        }}
      >
        <CustomList
          title={ChoicesLabel}
          items={source}
          handleToggle={onToggle}
          checked={checked}
          handleSearch={onSearchSource}
          searchedValue={searchedSource}
        />
      </Grid>
      <Grid item>
        <Grid container direction='column' alignItems='center'>
          <Button
            className={classes.button}
            variant='outlined'
            size='small'
            onClick={handleCheckDestination}
            aria-label='move selected right'
          >
            {currentDir === "ltr" ? <ArrowForward /> : <ArrowBack />}
          </Button>
          <Button className={classes.button} variant='outlined' size='small' onClick={handleCheckSource} aria-label='move selected left'>
            {currentDir === "ltr" ? <ArrowBack /> : <ArrowForward />}
          </Button>
        </Grid>
      </Grid>
      <Grid
        item
        sx={{
          width: {
            xs: "calc(34% + 8px)",
            sm: "calc(40% + 8px)",
            md: "calc(40% + 14px)",
            lg: "calc(43% + 14px)",
            xl: "calc(45% + 14px)",
          },
        }}
      >
        <CustomList
          title={ChosenLabel}
          items={destination}
          handleToggle={onToggle}
          checked={checked}
          handleSearch={onSearchDestination}
          searchedValue={searchedDestination}
        />
      </Grid>
    </Grid>
  );
};
