/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { ProductTypeFragmentFragmentDoc } from '../../../../fragment/productTypes/__generated__/productTypes';
import { PageInfoFragmentFragmentDoc } from '../../../../fragment/PageInfo/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductTypeCreateMutationVariables = Types.Exact<{
  input: Types.ProductTypeInput;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type ProductTypeCreateMutation = { __typename?: 'Mutation', productTypeCreate?: { __typename?: 'ProductTypeCreate', productType?: { __typename?: 'ProductType', id: string, name?: string | null, slug: string, availableAttributes?: { __typename?: 'AttributeCountableConnection', edges: Array<{ __typename?: 'AttributeCountableEdge', node: { __typename?: 'Attribute', id: string, name?: string | null, slug?: string | null } }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } } | null, productAttributes?: Array<{ __typename?: 'Attribute', name?: string | null, inputType?: Types.AttributeInputTypeEnum | null, idAttributes: string, values?: Array<{ __typename?: 'AttributeValue', id: string, name?: string | null } | null> | null } | null> | null, variantAttributes?: Array<{ __typename?: 'Attribute', name?: string | null, id: string } | null> | null } | null, productErrors: Array<{ __typename?: 'ProductError', field?: string | null, message?: string | null }> } | null };


export const ProductTypeCreateDocument = gql`
    mutation productTypeCreate($input: ProductTypeInput!, $first: Int = 100) {
  productTypeCreate(input: $input) {
    productType {
      ...ProductTypeFragment
      availableAttributes(first: $first) {
        edges {
          node {
            id
            name
            slug
          }
        }
        pageInfo {
          ...PageInfoFragment
        }
      }
    }
    productErrors {
      field
      message
    }
  }
}
    ${ProductTypeFragmentFragmentDoc}
${PageInfoFragmentFragmentDoc}`;
export type ProductTypeCreateMutationFn = Apollo.MutationFunction<ProductTypeCreateMutation, ProductTypeCreateMutationVariables>;

/**
 * __useProductTypeCreateMutation__
 *
 * To run a mutation, you first call `useProductTypeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductTypeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productTypeCreateMutation, { data, loading, error }] = useProductTypeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useProductTypeCreateMutation(baseOptions?: Apollo.MutationHookOptions<ProductTypeCreateMutation, ProductTypeCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductTypeCreateMutation, ProductTypeCreateMutationVariables>(ProductTypeCreateDocument, options);
      }
export type ProductTypeCreateMutationHookResult = ReturnType<typeof useProductTypeCreateMutation>;
export type ProductTypeCreateMutationResult = Apollo.MutationResult<ProductTypeCreateMutation>;
export type ProductTypeCreateMutationOptions = Apollo.BaseMutationOptions<ProductTypeCreateMutation, ProductTypeCreateMutationVariables>;