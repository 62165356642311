import { darkRed, makeStyles } from "@health/ui";

export const useStyles = makeStyles()(() => ({
  content: {
    padding: "15px 26px",
  },
  button: {
    height: 50,
    margin: 0,
    marginTop: 5,
    fontSize: 14,
  },
  forgot: {
    margin: 0,
    marginBottom: 10,
    fontSize: 12,
    height: 30,
    paddingInline: "8px !important",
  },
  errors: {
    color: darkRed,
    fontSize: 12,
  },
}));
