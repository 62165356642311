/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { ProductBrandFragmentFragmentDoc } from '../../../../fragment/ProductBrand/__generated__/ProductBrand';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductBrandQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type ProductBrandQuery = { __typename?: 'Query', productBrand?: { __typename?: 'ProductBrand', name: string, id: string } | null };


export const ProductBrandDocument = gql`
    query productBrand($id: ID!) {
  productBrand(id: $id) {
    ...ProductBrandFragment
  }
}
    ${ProductBrandFragmentFragmentDoc}`;

/**
 * __useProductBrandQuery__
 *
 * To run a query within a React component, call `useProductBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductBrandQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductBrandQuery(baseOptions: Apollo.QueryHookOptions<ProductBrandQuery, ProductBrandQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductBrandQuery, ProductBrandQueryVariables>(ProductBrandDocument, options);
      }
export function useProductBrandLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductBrandQuery, ProductBrandQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductBrandQuery, ProductBrandQueryVariables>(ProductBrandDocument, options);
        }
export type ProductBrandQueryHookResult = ReturnType<typeof useProductBrandQuery>;
export type ProductBrandLazyQueryHookResult = ReturnType<typeof useProductBrandLazyQuery>;
export type ProductBrandQueryResult = Apollo.QueryResult<ProductBrandQuery, ProductBrandQueryVariables>;