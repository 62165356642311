/* eslint-disable max-lines */
import { getUserPermissions, hasAnyPermission, hasPermission } from "@health/common";
import { useTranslation } from "@health/i18n";
import { PermissionEnum } from "@health/queries";
import { MarketplaceOrdersTrackingScreen, useSiteSettingsContext } from "@health/smart-ui";
import {
  BarChartIcon,
  BlankOrderIcon,
  CirclePlusIcon,
  ComingSoon,
  GlobeIcon,
  MedicationIcon,
  PrescriptionIcon,
  primary,
  ProductIcon,
  ProviderIcon,
  ReceiptIcon,
  SettingsIcon,
  ShopIcon,
  UsersGroupIcon,
} from "@health/ui";
import { BlankOrderNewPage, BlankOrderPage } from "pages/BlankOrder";
import { BranchUsersManagement } from "pages/BranchUsers";
import { MedListEdit, MedListPage, MedListTable } from "pages/CentralLists";
import { CitiesContainer, CitiesEditPageForm, CitiesNewPageForm } from "pages/CitiesManagement";
import { PrescriptionsPageScreen } from "pages/PrescriptionsManagement";
import { FailedPrescriptionFormScreen } from "pages/PrescriptionsManagement/new";
import {
  AttributesFormEditPage,
  AttributesFormNewPage,
  AttributesList,
  CategoriesFormEditPage,
  CategoriesFormNewPage,
  CategoriesList,
  ProductsFormEditPage,
  ProductsFormNewPage,
  ProductsList,
  ProductTypesFromEditPage,
  ProductTypesFromNewPage,
  ProductTypesList,
  SubCatagoriesList,
} from "pages/ProductsManagement";
import { ProductBrandEditPage, ProductBrandList, ProductBrandNewPage } from "pages/ProductsManagement/productBrand";
import VariantsFormEditPage from "pages/ProductsManagement/products/edit/VariantsFormEditPage";
import VariantsFormNewPage from "pages/ProductsManagement/products/new/VariantsFormNewPage";
import {
  BranchesInfo,
  EditBranchPage,
  NewBranchPage,
  VendorsFormEditPage,
  VendorsFormNewPage,
  VendorsList,
} from "pages/ProvidersManagement";
import { ProviderUsersManagement } from "pages/ProviderUsers";
import { SettingsPage } from "pages/Settings";
import { SubscriptionsForm, SubscriptionsList } from "pages/Subscription";
import { getSystemRulesRouteTree } from "pages/SystemAndBusinessRules/route";
import { PermissionsEditPage, PermissionsList, PermissionsNewPage, UserFormEditPage, UsersList } from "pages/UsersManagement";
import { UserFormNewPage } from "pages/UsersManagement/Users";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../constants";
import RouteItem from "./routeTypes";

export const useAppRoutes = (): RouteItem[] => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userPermissions = getUserPermissions();
  const { settings, isMarketPlaceEnabled } = useSiteSettingsContext();
  return useMemo(
    () =>
      [
        {
          id: "dashboard",
          route: "dashboard",
          fullPath: "/dashboard",
          text: t("Dashboard"),
          icon: <BarChartIcon />,
          element: <ComingSoon />,
          onClick: route => navigate(route),
        },
        isMarketPlaceEnabled && {
          id: "product-catalogue",
          text: t("Product Catalogue"),
          icon: <ProductIcon />,
          route: ROUTE_PATHS.productsManagement.path,
          isProhibited: !hasPermission(PermissionEnum.ManageProducts),
          subItems: [
            {
              id: "products",
              text: t(ROUTE_PATHS.productsManagement.products.name),
              route: ROUTE_PATHS.productsManagement.products.path,
              fullPath: ROUTE_PATHS.productsManagement.products.fullPath,
              element: <ProductsList />,
              onClick: route => navigate(route),
            },
            {
              id: "new-Product",
              text: t(ROUTE_PATHS.productsManagement.products?.new?.name),
              route: ROUTE_PATHS.productsManagement.products?.new?.path,
              fullPath: ROUTE_PATHS.productsManagement.products?.new.fullPath,
              element: <ProductsFormNewPage />,
              onClick: route => navigate(route),
              hidden: true,
            },
            {
              id: "update-Product",
              text: t(ROUTE_PATHS.productsManagement.products?.edit?.name),
              route: ROUTE_PATHS.productsManagement.products?.edit?.path,
              fullPath: ROUTE_PATHS.productsManagement.products?.edit.fullPath,
              element: <ProductsFormEditPage />,
              onClick: route => navigate(route),
              hidden: true,
            },
            {
              id: "update-Variant",
              text: t(ROUTE_PATHS.productsManagement?.products?.variantEdit?.name),
              route: ROUTE_PATHS.productsManagement?.products?.variantEdit?.path,
              fullPath: ROUTE_PATHS.productsManagement?.products?.variantEdit?.fullPath,
              element: <VariantsFormEditPage />,
              onClick: route => navigate(route),
              hidden: true,
            },
            {
              id: "new-Variant",
              text: t(ROUTE_PATHS.productsManagement?.products?.variantAdd?.name),
              route: ROUTE_PATHS.productsManagement?.products?.variantAdd?.path,
              fullPath: ROUTE_PATHS.productsManagement?.products?.variantAdd?.fullPath,
              element: <VariantsFormNewPage />,
              onClick: route => navigate(route),
              hidden: true,
            },
            {
              id: "attributes",
              text: t(ROUTE_PATHS.productsManagement.attributes.name),
              route: ROUTE_PATHS.productsManagement.attributes.path,
              fullPath: ROUTE_PATHS.productsManagement.attributes.fullPath,
              element: <AttributesList />,
              onClick: route => navigate(route),
            },
            {
              id: "attributesAdd",
              text: ROUTE_PATHS.productsManagement?.attributes?.new?.name,
              route: ROUTE_PATHS.productsManagement?.attributes?.new?.path,
              fullPath: ROUTE_PATHS.productsManagement?.attributes?.new?.fullPath,
              element: <AttributesFormNewPage />,
              onClick: route => navigate(route),
              hidden: true,
            },
            {
              id: "attributesEdit",
              text: ROUTE_PATHS.productsManagement?.attributes?.edit?.name,
              route: ROUTE_PATHS.productsManagement?.attributes?.edit?.path,
              fullPath: ROUTE_PATHS.productsManagement?.attributes?.edit?.fullPath,
              element: <AttributesFormEditPage />,
              onClick: route => navigate(route),
              hidden: true,
            },
            {
              id: "product-types",
              text: t(ROUTE_PATHS.productsManagement.productType.name),
              route: ROUTE_PATHS.productsManagement.productType.path,
              fullPath: ROUTE_PATHS.productsManagement.productType.fullPath,
              element: <ProductTypesList />,
              onClick: route => navigate(route),
            },
            {
              id: "new-product-type",
              text: ROUTE_PATHS.productsManagement?.productType?.new?.name,
              route: ROUTE_PATHS.productsManagement?.productType?.new?.path,
              fullPath: ROUTE_PATHS.productsManagement.productType?.new.fullPath,
              element: <ProductTypesFromNewPage />,
              onClick: route => navigate(route),
              hidden: true,
            },
            {
              id: "edit-product-type",
              text: ROUTE_PATHS.productsManagement?.productType?.edit?.name,
              route: ROUTE_PATHS.productsManagement?.productType?.edit?.path,
              fullPath: ROUTE_PATHS.productsManagement?.productType?.edit?.fullPath,
              element: <ProductTypesFromEditPage />,
              onClick: route => navigate(route),
              hidden: true,
            },
            {
              id: "categories",
              text: t(ROUTE_PATHS.productsManagement.categories.name),
              route: ROUTE_PATHS.productsManagement.categories.path,
              fullPath: ROUTE_PATHS.productsManagement.categories.fullPath,
              element: <CategoriesList />,
              onClick: route => navigate(route),
            },
            {
              id: "new category",
              text: ROUTE_PATHS.productsManagement.categories.new.name,
              route: ROUTE_PATHS.productsManagement.categories.new.path,
              fullPath: ROUTE_PATHS.productsManagement.categories.new.fullPath,
              element: <CategoriesFormNewPage />,
              onClick: route => navigate(route),
              hidden: true,
            },
            {
              id: "edit category",
              fullPath: ROUTE_PATHS.productsManagement.categories.edit.fullPath,
              text: ROUTE_PATHS.productsManagement.categories.edit.name,
              route: ROUTE_PATHS.productsManagement.categories.edit.path,
              element: <CategoriesFormEditPage />,
              hidden: true,
            },
            {
              id: "Subcategory",
              fullPath: ROUTE_PATHS.productsManagement.categories.subCategory.fullPath,
              text: ROUTE_PATHS.productsManagement.categories.subCategory.name,
              route: ROUTE_PATHS.productsManagement.categories.subCategory.path,
              element: <SubCatagoriesList />,
              onClick: route => navigate(route),
              hidden: true,
            },
            {
              id: "product-Brand",
              text: t(ROUTE_PATHS.productsManagement?.productBrand?.name),
              route: ROUTE_PATHS.productsManagement.productBrand.path,
              fullPath: ROUTE_PATHS.productsManagement.productBrand.fullPath,
              element: <ProductBrandList />,
              onClick: route => navigate(route),
            },
            {
              id: "product-Brand-new",
              text: t(ROUTE_PATHS.productsManagement?.productBrand?.name),
              route: ROUTE_PATHS.productsManagement?.productBrand?.new?.path,
              fullPath: ROUTE_PATHS.productsManagement.productBrand?.new.fullPath,
              element: <ProductBrandNewPage />,
              onClick: route => navigate(route),
              hidden: true,
            },
            {
              id: "product-Brand-edit",
              text: t(ROUTE_PATHS.productsManagement?.productBrand?.name),
              route: ROUTE_PATHS.productsManagement?.productBrand?.edit?.path,
              fullPath: ROUTE_PATHS.productsManagement.productBrand?.edit.fullPath,
              element: <ProductBrandEditPage />,
              onClick: route => navigate(route),
              hidden: true,
            },
          ],
        },
        {
          id: "med-lists",
          text: t("Med Lists"),
          icon: <MedicationIcon />,
          route: "med-lists",
          isProhibited: !hasPermission(PermissionEnum.ManageCodeSystemLists),
          subItems: [
            {
              id: "medications",
              route: "",
              fullPath: "/med-lists",
              text: t("Terminology"),
              element: <MedListPage />,
              onClick: route => navigate(route),
            },
            {
              id: "med-table",
              route: ":id/:code",
              fullPath: "/med-lists/:id/:code",
              text: "",
              element: <MedListTable />,
              onClick: route => navigate(route),
              hidden: true,
            },
            {
              id: "edit-code-system",
              route: ":id/:code/:codeSystemConceptId",
              fullPath: "/med-lists/:id/:code/:codeSystemConceptId",
              text: "",
              element: <MedListEdit />,
              onClick: route => navigate(route),
              hidden: true,
            },
          ],
        },
        {
          id: "Medical Delivery Request",
          text: t("Medical Delivery Request"),
          icon: <BlankOrderIcon />,
          route: ROUTE_PATHS?.medicalDeliveryRequest?.path,
          fullPath: ROUTE_PATHS?.medicalDeliveryRequest?.fullPath,
          onClick: route => navigate(route),
          element: <BlankOrderPage />,
          isProhibited: !hasPermission(PermissionEnum.ManageMedicalDeliveryRequests),
        },
        {
          id: "New Delivery Request",
          text: t("New Delivery Request"),
          icon: <BlankOrderIcon />,
          route: ROUTE_PATHS?.medicalDeliveryRequest?.medicalDeliveryRequest?.new?.path,
          fullPath: ROUTE_PATHS?.medicalDeliveryRequest?.medicalDeliveryRequest?.new?.fullPath,
          onClick: route => navigate(route),
          element: <BlankOrderNewPage />,
          hidden: true,
          isProhibited: !hasPermission(PermissionEnum.ManageMedicalDeliveryRequests),
        },
        {
          id: "providers-lists",
          text: t("Health Providers"),
          icon: <ProviderIcon />,
          isProhibited: !hasPermission(PermissionEnum.ManageVendors),
          onClick: route => navigate(route),
          subItems: [
            {
              id: "providers-lists",
              text: t("Health Providers"),
              icon: <ProviderIcon />,
              isProhibited: !hasPermission(PermissionEnum.ManageVendors),
              route: ROUTE_PATHS.providersManagement.path,
              fullPath: ROUTE_PATHS.providersManagement.fullPath,
              element: <VendorsList />,
              onClick: route => navigate(route),
            },
            {
              id: "new-provider",
              fullPath: ROUTE_PATHS.providersManagement.provider.new.fullPath,
              text: t(ROUTE_PATHS.providersManagement.provider.new.name),
              route: ROUTE_PATHS.providersManagement.provider.new.path,
              element: <VendorsFormNewPage />,
              onClick: route => navigate(route),
              hidden: true,
            },
            {
              id: "edit-provider",
              fullPath: ROUTE_PATHS.providersManagement.provider.edit.fullPath,
              text: t(ROUTE_PATHS.providersManagement.provider.edit.name),
              route: ROUTE_PATHS.providersManagement.provider.edit.path,
              element: <VendorsFormEditPage />,
              hidden: true,
            },
            {
              id: "provider-users",
              fullPath: ROUTE_PATHS.providersManagement.provider.user.fullPath,
              text: t(ROUTE_PATHS.providersManagement.provider.user.name),
              route: ROUTE_PATHS.providersManagement.provider.user.path,
              element: <ProviderUsersManagement />,
              hidden: true,
            },
            {
              id: "Branches",
              fullPath: ROUTE_PATHS.providersManagement.provider.branch.fullPath,
              text: t(ROUTE_PATHS.providersManagement.provider.branch.name),
              route: ROUTE_PATHS.providersManagement.provider.branch.path,
              element: <BranchesInfo />,
              hidden: true,
            },
            {
              id: "New Branch",
              fullPath: ROUTE_PATHS.providersManagement.provider.newBranch.fullPath,
              text: t(ROUTE_PATHS.providersManagement.provider.newBranch.name),
              route: ROUTE_PATHS.providersManagement.provider.newBranch.path,
              element: <NewBranchPage />,
              hidden: true,
            },
            {
              id: "Edit Branch",
              fullPath: ROUTE_PATHS.providersManagement.provider.editBranch.fullPath,
              text: t(ROUTE_PATHS.providersManagement.provider.editBranch.name),
              route: ROUTE_PATHS.providersManagement.provider.editBranch.path,
              element: <EditBranchPage />,
              hidden: true,
            },
            {
              id: "user Branch",
              fullPath: ROUTE_PATHS.providersManagement.provider.branchUsers.fullPath,
              text: t(ROUTE_PATHS.providersManagement.provider.branchUsers.name),
              route: ROUTE_PATHS.providersManagement.provider.branchUsers.path,
              element: <BranchUsersManagement />,
              hidden: true,
            },
          ],
        },
        {
          id: "prescriptions",
          text: t("Prescriptions Tracking"),
          icon: <PrescriptionIcon />,
          route: "prescriptions",
          fullPath: "/prescriptions",
          element: <PrescriptionsPageScreen />,
          isProhibited: !hasPermission(PermissionEnum.ManagePrescriptions),
          onClick: route => navigate(route),
        },
        {
          id: "failed-prescription",
          fullPath: "/prescriptions/new",
          text: t("Failed Prescriptions Form"),
          route: "prescriptions/new",
          element: <FailedPrescriptionFormScreen />,
          onClick: route => navigate(route),
          isProhibited: !hasPermission(PermissionEnum.ManagePrescriptions),
          hidden: true,
        },
        isMarketPlaceEnabled && {
          id: "marketplace-orders-tracking",
          text: t("Marketplace Orders Tracking"),
          icon: <ShopIcon fill={primary} />,
          route: "marketplace-orders-tracking",
          fullPath: "/marketplace-orders-tracking",
          element: <MarketplaceOrdersTrackingScreen />,
          isProhibited: !isMarketPlaceEnabled || !hasPermission(PermissionEnum.ManagePrescriptions),
          onClick: route => navigate(route),
        },
        // {
        //   id: "orders",
        //   text: t(ROUTE_PATHS.ordersManagement.name),
        //   icon: <LocationIcon />,
        //   isProhibited: !hasPermission(PermissionEnum.ManageOrders),
        //   subItems: [
        //     {
        //       id: "1",
        //       route: ROUTE_PATHS.ordersManagement.path,
        //       fullPath: ROUTE_PATHS.ordersManagement.fullPath,
        //       text: t("Orders Tracking"),
        //       element: <OrdersTrackingList />,
        //       onClick: route => navigate(route),
        //     },
        //   ],
        // }, //TODO removed due ticket ISSUE-3163
        {
          id: "cities",
          text: t(ROUTE_PATHS.citiesManagement.name),
          route: ROUTE_PATHS.citiesManagement.path,
          fullPath: ROUTE_PATHS.citiesManagement.fullPath,
          element: <CitiesContainer />,
          onClick: route => navigate(route),
          icon: <GlobeIcon />,
          isProhibited: !hasPermission(PermissionEnum.ManageCities),
        },
        {
          ...getSystemRulesRouteTree(navigate, t),
          isProhibited: !hasPermission(PermissionEnum.ManageRuleEngineRules),
        },
        {
          id: "cities-form-new",
          fullPath: ROUTE_PATHS.citiesManagement.cities.new.fullPath,
          text: ROUTE_PATHS.citiesManagement.cities.new.Name,
          element: <CitiesNewPageForm />,
          route: ROUTE_PATHS.citiesManagement.cities.new.path,
          onClick: route => navigate(route),
          hidden: true,
          isProhibited: !hasPermission(PermissionEnum.ManageCities),
        },
        {
          id: "cities-form-edit",
          fullPath: ROUTE_PATHS.citiesManagement.cities.edit.fullPath,
          text: ROUTE_PATHS.citiesManagement.cities.edit.Name,
          route: ROUTE_PATHS.citiesManagement.cities.edit.path,
          element: <CitiesEditPageForm />,
          onClick: route => navigate(route),
          hidden: true,
          isProhibited: !hasPermission(PermissionEnum.ManageCities),
        },
        {
          id: "invoices",
          text: t("Invoices"),
          icon: <ReceiptIcon />,
          route: "invoices",
          fullPath: "/invoices",
          element: <ComingSoon />,
          isProhibited: !userPermissions?.find(item => item?.code === PermissionEnum.ManageInvoices),
          onClick: route => navigate(route),
        },
        {
          id: "subscriptions",
          text: t("Subscriptions Management"),
          icon: <CirclePlusIcon />,
          route: ROUTE_PATHS.subscriptionsManagement.path,
          isProhibited: !hasPermission(PermissionEnum.ManageSubscriptions),
          subItems: [
            {
              id: "subscriptions-list",
              route: ROUTE_PATHS?.subscriptionsManagement?.subscriptions?.path,
              text: t(ROUTE_PATHS?.subscriptionsManagement?.subscriptions?.name),
              fullPath: ROUTE_PATHS.subscriptionsManagement?.subscriptions?.fullPath,
              element: <SubscriptionsList />,
              onClick: route => navigate(route),
            },
            {
              id: "subscriptions-create",
              route: ROUTE_PATHS?.subscriptionsManagement?.subscriptions?.new?.path,
              text: ROUTE_PATHS?.subscriptionsManagement?.subscriptions?.new?.name,
              fullPath: ROUTE_PATHS?.subscriptionsManagement?.subscriptions?.new?.fullPath,
              element: <SubscriptionsForm />,
              onClick: route => navigate(route),
              hidden: true,
            },
            {
              id: "subscriptions-edit",
              route: ROUTE_PATHS?.subscriptionsManagement?.subscriptions?.edit?.path,
              text: ROUTE_PATHS?.subscriptionsManagement?.subscriptions?.edit?.name,
              fullPath: ROUTE_PATHS?.subscriptionsManagement?.subscriptions?.edit?.fullPath,
              element: <SubscriptionsForm />,
              onClick: route => navigate(route),
              hidden: true,
            },
          ],
        },
        {
          id: "users",
          text: t("Users Management"),
          icon: <UsersGroupIcon />,
          route: ROUTE_PATHS.usersManagement.path,
          isProhibited: !hasAnyPermission([PermissionEnum.ManageStaff, PermissionEnum.ManageUsers]),
          subItems: [
            {
              id: "users-management",
              text: t(ROUTE_PATHS.usersManagement.users.name),
              route: ROUTE_PATHS.usersManagement.users.path,
              fullPath: ROUTE_PATHS.usersManagement.users.fullPath,
              element: <UsersList />,
              onClick: route => navigate(route),
            },
            {
              id: "edit User Management",
              text: t(ROUTE_PATHS.usersManagement.users.edit.name),
              route: ROUTE_PATHS.usersManagement.users.edit.path,
              fullPath: ROUTE_PATHS.usersManagement.users.edit.fullPath,
              onClick: route => navigate(route),
              hidden: true,
              element: <UserFormEditPage />,
            },
            {
              id: "new User",
              text: t(ROUTE_PATHS.usersManagement.users.new.name),
              route: ROUTE_PATHS.usersManagement.users.new.path,
              fullPath: ROUTE_PATHS.usersManagement.users.new.fullPath,
              onClick: route => navigate(route),
              hidden: true,
              element: <UserFormNewPage />,
            },
            {
              id: "Permissions Management",
              text: t(ROUTE_PATHS.usersManagement.permissions.name),
              route: ROUTE_PATHS.usersManagement.permissions.path,
              fullPath: ROUTE_PATHS.usersManagement.permissions.fullPath,
              element: <PermissionsList />,
              onClick: route => navigate(route),
            },
            {
              id: "new Permissions Management",
              text: ROUTE_PATHS.usersManagement.permissions.new.name,
              route: ROUTE_PATHS.usersManagement.permissions.new.path,
              fullPath: ROUTE_PATHS.usersManagement.permissions.new.fullPath,
              element: <PermissionsNewPage />,
              onClick: route => navigate(route),
              hidden: true,
            },
            {
              id: "edit Permissions Management",
              text: ROUTE_PATHS.usersManagement.permissions.edit.name,
              route: ROUTE_PATHS.usersManagement.permissions.edit.path,
              fullPath: ROUTE_PATHS.usersManagement.permissions.edit.fullPath,
              element: <PermissionsEditPage />,
              onClick: route => navigate(route),
              hidden: true,
            },
          ],
        },
        {
          id: "settings",
          text: t("Settings"),
          icon: <SettingsIcon />,
          route: "settings",
          fullPath: "/settings",
          element: <SettingsPage />,
          isProhibited: !hasPermission(PermissionEnum.ManageSettings),
          onClick: route => navigate(route),
        },
      ].filter(Boolean) as RouteItem[],
    [userPermissions, settings]
  );
};
