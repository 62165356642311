import { GraphQLErrors } from "@apollo/client/errors";
import { formatGraphQLError } from "@health/queries";
import { useToasts } from "@health/ui";
const useReportAnError = () => {
  const { addToast } = useToasts();
  return {
    reportError: (param: { message: string; details?: string; e?: Error } | string) => {
      const message = typeof param === "string" ? param : param.message;
      const details = typeof param === "string" ? "" : param.details;
      console.error({ "@health/admin/error": param });
      addToast(message + details ? `\n ${details}` : "", { autoDismiss: true });
    },
    reportGenericApiError: (genericMessage: string) => (e: Error) => {
      console.error({ "@health/admin/error": e });
      addToast({ genericMessage, e }, { autoDismiss: true });
    },

    reportGraphQlErrors: (title: string) => (e: GraphQLErrors) => {
      console.error({ "@health/admin/error": e });
      const formattedMessage = `${title} \n ${formatGraphQLError(e)}`;
      addToast(formattedMessage, { appearance: "error", autoDismiss: true });
    },
  };
};
export default useReportAnError;
