import { useTranslation } from "@health/i18n";
import { Badge } from "@health/ui";
import React, { FC } from "react";
import { getCodeSystemProcessingStatusText } from "../medlistUtils";
import { useDotBadgeStyle } from "./DotBadge.style";
import { DotBadgeProps } from "./types";
import { getProcessDotColor } from "./util";

export const DotBadge: FC<DotBadgeProps> = ({ processingStatus }) => {
  const { classes } = useDotBadgeStyle();
  const { t } = useTranslation("Admin");
  return (
    <>
      <Badge
        color={getProcessDotColor(processingStatus)}
        variant='dot'
        classes={{
          colorSuccess: classes.colorApproved,
          colorWarning: classes.colorUnderReview,
          colorSecondary: classes.colorPublished,
          colorError: classes.colorRejected,
          colorPrimary: classes.colorPrimary,
          colorInfo: classes.colorDraft,
        }}
      />
      &nbsp; &nbsp;
      {getCodeSystemProcessingStatusText(t, processingStatus)}
    </>
  );
};
