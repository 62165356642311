/* eslint-disable */
/* prettier-ignore */
import * as Types from '@health/queries/dist/graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ImportTerminologyDataToSubListMutationVariables = Types.Exact<{
  codeSystemId: Types.Scalars['ID'];
  searchParameters: Array<Types.InputMaybe<Types.SearchParameter>> | Types.InputMaybe<Types.SearchParameter>;
  subListId: Types.Scalars['ID'];
}>;


export type ImportTerminologyDataToSubListMutation = { __typename?: 'Mutation', fetchTerminologyListData?: { __typename?: 'SubList', display?: string | null, id?: string | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', message?: string | null, field?: string | null } | null> | null } | null };


export const ImportTerminologyDataToSubListDocument = gql`
    mutation importTerminologyDataToSubList($codeSystemId: ID!, $searchParameters: [SearchParameter]!, $subListId: ID!) {
  fetchTerminologyListData(
    codeSystemId: $codeSystemId
    searchParameters: $searchParameters
    subListId: $subListId
  ) {
    display
    id
    errors {
      message
      field
    }
  }
}
    `;
export type ImportTerminologyDataToSubListMutationFn = Apollo.MutationFunction<ImportTerminologyDataToSubListMutation, ImportTerminologyDataToSubListMutationVariables>;

/**
 * __useImportTerminologyDataToSubListMutation__
 *
 * To run a mutation, you first call `useImportTerminologyDataToSubListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportTerminologyDataToSubListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importTerminologyDataToSubListMutation, { data, loading, error }] = useImportTerminologyDataToSubListMutation({
 *   variables: {
 *      codeSystemId: // value for 'codeSystemId'
 *      searchParameters: // value for 'searchParameters'
 *      subListId: // value for 'subListId'
 *   },
 * });
 */
export function useImportTerminologyDataToSubListMutation(baseOptions?: Apollo.MutationHookOptions<ImportTerminologyDataToSubListMutation, ImportTerminologyDataToSubListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportTerminologyDataToSubListMutation, ImportTerminologyDataToSubListMutationVariables>(ImportTerminologyDataToSubListDocument, options);
      }
export type ImportTerminologyDataToSubListMutationHookResult = ReturnType<typeof useImportTerminologyDataToSubListMutation>;
export type ImportTerminologyDataToSubListMutationResult = Apollo.MutationResult<ImportTerminologyDataToSubListMutation>;
export type ImportTerminologyDataToSubListMutationOptions = Apollo.BaseMutationOptions<ImportTerminologyDataToSubListMutation, ImportTerminologyDataToSubListMutationVariables>;