/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CheckoutVatQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CheckoutVatQuery = { __typename?: 'Query', siteSettings: { __typename?: 'SiteSettings', vatPercentage?: number | null } };


export const CheckoutVatDocument = gql`
    query checkoutVat {
  siteSettings {
    vatPercentage
  }
}
    `;

/**
 * __useCheckoutVatQuery__
 *
 * To run a query within a React component, call `useCheckoutVatQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutVatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutVatQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckoutVatQuery(baseOptions?: Apollo.QueryHookOptions<CheckoutVatQuery, CheckoutVatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckoutVatQuery, CheckoutVatQueryVariables>(CheckoutVatDocument, options);
      }
export function useCheckoutVatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckoutVatQuery, CheckoutVatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckoutVatQuery, CheckoutVatQueryVariables>(CheckoutVatDocument, options);
        }
export type CheckoutVatQueryHookResult = ReturnType<typeof useCheckoutVatQuery>;
export type CheckoutVatLazyQueryHookResult = ReturnType<typeof useCheckoutVatLazyQuery>;
export type CheckoutVatQueryResult = Apollo.QueryResult<CheckoutVatQuery, CheckoutVatQueryVariables>;