/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../fragment/PageInfo/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrdersAdminQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.OrderFilterInput>;
  sortBy?: Types.InputMaybe<Types.OrderSortingInput>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type OrdersAdminQuery = { __typename?: 'Query', orders?: { __typename?: 'OrderCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'OrderCountableEdge', node: { __typename: 'Order', id: string, trackingClientId: string, status: Types.OrderStatus, isPaid?: boolean | null, isCodPayment?: boolean | null, created: any, helpDiskTicketId?: string | null, number?: string | null, userEmail?: string | null, total?: { __typename?: 'TaxedMoney', gross: { __typename?: 'Money', currency: string, amount: number } } | null } }> } | null };


export const OrdersAdminDocument = gql`
    query ordersAdmin($first: Int, $after: String, $filter: OrderFilterInput, $sortBy: OrderSortingInput, $last: Int, $before: String) {
  orders(
    first: $first
    after: $after
    filter: $filter
    sortBy: $sortBy
    last: $last
    before: $before
  ) {
    totalCount
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        id
        trackingClientId
        status
        isPaid
        total {
          gross {
            currency
            amount
          }
        }
        isCodPayment
        created
        helpDiskTicketId
        number
        userEmail
        __typename
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useOrdersAdminQuery__
 *
 * To run a query within a React component, call `useOrdersAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersAdminQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useOrdersAdminQuery(baseOptions?: Apollo.QueryHookOptions<OrdersAdminQuery, OrdersAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersAdminQuery, OrdersAdminQueryVariables>(OrdersAdminDocument, options);
      }
export function useOrdersAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersAdminQuery, OrdersAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersAdminQuery, OrdersAdminQueryVariables>(OrdersAdminDocument, options);
        }
export type OrdersAdminQueryHookResult = ReturnType<typeof useOrdersAdminQuery>;
export type OrdersAdminLazyQueryHookResult = ReturnType<typeof useOrdersAdminLazyQuery>;
export type OrdersAdminQueryResult = Apollo.QueryResult<OrdersAdminQuery, OrdersAdminQueryVariables>;