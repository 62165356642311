/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendCodeSystemToReviewMutationVariables = Types.Exact<{
  codeSystemId: Types.Scalars['ID'];
}>;


export type SendCodeSystemToReviewMutation = { __typename?: 'Mutation', sendCodeSystemToReview?: { __typename?: 'CodeSystem', id?: string | null, display?: string | null, isActive?: boolean | null, version?: number | null, processingStatus?: Types.ProcessingStatus | null } | null };


export const SendCodeSystemToReviewDocument = gql`
    mutation sendCodeSystemToReview($codeSystemId: ID!) {
  sendCodeSystemToReview(codeSystemId: $codeSystemId) {
    id
    display
    isActive
    version
    processingStatus
  }
}
    `;
export type SendCodeSystemToReviewMutationFn = Apollo.MutationFunction<SendCodeSystemToReviewMutation, SendCodeSystemToReviewMutationVariables>;

/**
 * __useSendCodeSystemToReviewMutation__
 *
 * To run a mutation, you first call `useSendCodeSystemToReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCodeSystemToReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCodeSystemToReviewMutation, { data, loading, error }] = useSendCodeSystemToReviewMutation({
 *   variables: {
 *      codeSystemId: // value for 'codeSystemId'
 *   },
 * });
 */
export function useSendCodeSystemToReviewMutation(baseOptions?: Apollo.MutationHookOptions<SendCodeSystemToReviewMutation, SendCodeSystemToReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendCodeSystemToReviewMutation, SendCodeSystemToReviewMutationVariables>(SendCodeSystemToReviewDocument, options);
      }
export type SendCodeSystemToReviewMutationHookResult = ReturnType<typeof useSendCodeSystemToReviewMutation>;
export type SendCodeSystemToReviewMutationResult = Apollo.MutationResult<SendCodeSystemToReviewMutation>;
export type SendCodeSystemToReviewMutationOptions = Apollo.BaseMutationOptions<SendCodeSystemToReviewMutation, SendCodeSystemToReviewMutationVariables>;