/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../fragment/PageInfo/__generated__/pageInfoFragment';
import { AttributeFragmentFragmentDoc } from '../../../../fragment/attributes/__generated__/attributes';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AttributesQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.AttributeFilterInput>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  sortBy?: Types.InputMaybe<Types.AttributeSortingInput>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type AttributesQuery = { __typename?: 'Query', attributes?: { __typename?: 'AttributeCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'AttributeCountableEdge', node: { __typename?: 'Attribute', name?: string | null, id: string, slug?: string | null, values?: Array<{ __typename?: 'AttributeValue', id: string, name?: string | null } | null> | null } }> } | null };


export const AttributesDocument = gql`
    query attributes($first: Int, $filter: AttributeFilterInput, $after: String, $sortBy: AttributeSortingInput, $before: String, $last: Int) {
  attributes(
    first: $first
    filter: $filter
    after: $after
    sortBy: $sortBy
    before: $before
    last: $last
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    totalCount
    edges {
      node {
        ...AttributeFragment
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}
${AttributeFragmentFragmentDoc}`;

/**
 * __useAttributesQuery__
 *
 * To run a query within a React component, call `useAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttributesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *      after: // value for 'after'
 *      sortBy: // value for 'sortBy'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useAttributesQuery(baseOptions?: Apollo.QueryHookOptions<AttributesQuery, AttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AttributesQuery, AttributesQueryVariables>(AttributesDocument, options);
      }
export function useAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AttributesQuery, AttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AttributesQuery, AttributesQueryVariables>(AttributesDocument, options);
        }
export type AttributesQueryHookResult = ReturnType<typeof useAttributesQuery>;
export type AttributesLazyQueryHookResult = ReturnType<typeof useAttributesLazyQuery>;
export type AttributesQueryResult = Apollo.QueryResult<AttributesQuery, AttributesQueryVariables>;