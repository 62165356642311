const isV1EqualsV2 = (v1: string, v2: string) => {
  return v1?.toLowerCase()?.includes(v2?.toLowerCase());
};
export const autoCompleteI18nFiltrationOptions = (getOptionLabel?: (option: any) => string) => (options: any[], state) => {
  return state?.inputValue
    ? options?.filter(
        option =>
          isV1EqualsV2(getOptionLabel?.(option), state?.inputValue) ||
          isV1EqualsV2(option?.name, state?.inputValue) ||
          isV1EqualsV2(option?.nameAr, state?.inputValue) ||
          (typeof option === "string" && isV1EqualsV2(option, state?.inputValue))
      )
    : options;
};
