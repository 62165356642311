import { getEnvVariable, getTokenFromLocalStorage, MicrosoftSSE } from "@health/common";

const sseLink = new MicrosoftSSE({
  url: () => getEnvVariable("SUBSCRIPTION_URL"),
  method: "POST",
  headers: () => ({
    "Content-Type": "application/JSON",
    authorization: getTokenFromLocalStorage() ? `JWT ${getTokenFromLocalStorage()}` : undefined,
  }),
});

export default sseLink;
