import { ActiveFilters, CustomTable, MainListActions, PageWrapper, StaticFilterDialog } from "@health/ui";
import usePatientEligibilityPlanHook from "./usePatientEligibilityPlanHook";

const PatientEligibilityPlanList = () => {
  const {
    columns,
    items,
    pageSize,
    pageInfo,
    isLoading,
    totalCount,
    fields,
    activeFilters,
    handleOnSort,
    handleAddItem,
    handleEditRow,
    handleDeleteRow,
    handleGotoNext,
    handleGoToPrevious,
    handlePageSizeChange,
    handleApplyFilters,
    isFilterOpened,
    handleToggleFilters,
  } = usePatientEligibilityPlanHook();
  const pageCount = (totalCount || pageSize) / pageSize;
  return (
    <PageWrapper
      start={<ActiveFilters onOpenFilters={handleToggleFilters} activeFilters={activeFilters ? activeFilters : []} />}
      actions={
        <>
          <StaticFilterDialog
            isOpen={isFilterOpened}
            onToggleDialog={handleToggleFilters}
            activeFilters={activeFilters}
            filterFields={fields}
            onApplyFilters={handleApplyFilters}
          />
          <MainListActions hasAddNew onAddNewItem={handleAddItem} />
        </>
      }
    >
      <CustomTable
        data={items}
        columns={columns}
        isLoading={isLoading}
        isEditVisible={true}
        isDeleteVisible={true}
        pageSize={pageSize}
        pageIndex={1}
        totalCount={totalCount}
        pagesCount={pageCount}
        hasNextPage={pageInfo?.hasNextPage}
        hasPreviousPage={pageInfo?.hasPreviousPage}
        onGoToNext={handleGotoNext}
        onGoToPrevious={handleGoToPrevious}
        onPageSizeChange={handlePageSizeChange}
        onEditRow={handleEditRow}
        onDeleteRow={handleDeleteRow}
        onSortColumn={handleOnSort}
      />
    </PageWrapper>
  );
};

export default PatientEligibilityPlanList;
