import { makeStyles } from "@health/ui";

export const useConsumerEntryDialogStyles = makeStyles<{ isMobile? }>()((theme, { isMobile }) => ({
  container: {
    width: isMobile ? "inherit" : 355,
    margin: isMobile ? 0 : 8,
    background: "#fff",
    borderRadius: 15,
  },
  layout: {
    width: isMobile ? "inherit" : 370,
    // eslint-disable-next-line no-nested-ternary
    transform: isMobile ? "inherit" : theme.direction === "rtl" ? "translateX(8px)" : "translateX(-8px)",
    overflow: "visible",
    boxSizing: "border-box",
    // [theme.direction === "rtl" ? " " : "marginLeft"]: isMobile ? 0 : -7,
  },
}));
