import { darkBlue, darkGrey, darkRed, green, makeStyles, orange, tealBlue } from "@health/ui";

export const useDotBadgeStyle = makeStyles()(() => ({
  colorApproved: {
    backgroundColor: tealBlue,
  },
  colorUnderReview: {
    backgroundColor: orange,
  },
  colorRejected: {
    backgroundColor: darkRed,
  },
  colorPublished: {
    backgroundColor: green,
  },
  colorInfo: {
    backgroundColor: darkGrey,
  },
  colorPrimary: {
    backgroundColor: darkBlue,
  },
  colorDraft: {
    backgroundColor: darkGrey,
  },
}));
