/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type VendorErrorFragmentFragment = { __typename?: 'VendorError', field?: string | null, message?: string | null, code: Types.VendorErrorCode };

export const VendorErrorFragmentFragmentDoc = gql`
    fragment VendorErrorFragment on VendorError {
  field
  message
  code
}
    `;