/* eslint-disable react/no-unknown-property */
/* eslint-disable react/forbid-dom-props */
/* eslint-disable max-lines */
import React, { FC } from "react";
import { adminLayoutVariables } from "../../customization/layout";

export const WasfatyWithoutPlusLogo: FC<{ width?: string | number; height?: string | number }> = ({ width, height }) => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width={width ?? "100%"}
      height={height}
      viewBox='0 0 3558 2480'
      enableBackground='new 0 0 3558 2480'
    >
      <path
        fill='#1C2346'
        opacity='1.000000'
        stroke='none'
        d='
M1224.009521,1170.616577
	C1219.772339,1131.752197 1199.534912,1114.446045 1160.894287,1115.792603
	C1131.301880,1116.823975 1112.896606,1132.745361 1108.130737,1161.838135
	C1107.005127,1168.708496 1106.409546,1175.738770 1106.365723,1182.700562
	C1106.175049,1213.030762 1106.331299,1243.362671 1106.255127,1273.693848
	C1106.205566,1293.442139 1104.073242,1313.057251 1098.947632,1332.072266
	C1082.936035,1391.471558 1046.144531,1432.167114 987.439819,1451.558594
	C935.472778,1468.724609 883.114502,1468.440796 831.340576,1450.501099
	C775.436157,1431.130249 740.719971,1391.453003 724.302002,1335.251343
	C717.714661,1312.701538 715.840576,1289.423828 715.770081,1266.023804
	C715.649536,1226.026978 715.731323,1186.029419 715.731445,1146.032227
	C715.731445,1144.699219 715.721313,1143.363525 715.793884,1142.033447
	C716.262451,1133.449463 717.378235,1131.909424 725.760315,1131.853027
	C753.423218,1131.666748 781.089172,1131.668457 808.751770,1131.882080
	C816.557373,1131.942383 818.194580,1133.574341 818.105408,1141.545776
	C817.799988,1168.852051 816.936340,1196.152222 816.625732,1223.458618
	C816.353455,1247.404541 814.152954,1271.504272 820.019653,1295.130493
	C829.836731,1334.665405 853.913574,1360.055908 894.344604,1368.616577
	C932.844421,1376.768311 982.799561,1356.664062 996.915100,1306.334595
	C1000.347717,1294.095337 1002.426575,1281.703857 1002.348450,1268.884521
	C1002.133362,1233.554932 1002.058044,1198.221069 1002.409607,1162.893677
	C1002.518494,1151.946289 1003.256470,1140.841675 1005.280212,1130.105713
	C1013.431335,1086.863770 1038.656006,1055.830811 1078.246460,1038.520386
	C1139.213623,1011.863647 1201.054443,1012.104736 1260.764648,1042.594849
	C1302.170532,1063.737915 1324.530151,1099.345337 1327.537109,1145.905762
	C1328.952393,1167.819336 1328.101318,1189.878784 1328.286499,1211.872192
	C1328.314575,1215.204468 1328.314941,1218.539917 1328.469116,1221.867432
	C1328.688110,1226.596680 1331.218018,1229.212036 1335.971680,1229.509888
	C1338.298096,1229.655640 1340.634644,1229.689941 1342.966553,1229.690430
	C1378.964111,1229.697998 1414.961792,1229.758057 1450.958862,1229.615967
	C1456.250122,1229.595093 1461.613159,1228.989136 1466.812134,1227.979858
	C1480.537354,1225.315308 1489.705566,1217.055664 1492.966675,1203.522583
	C1494.974976,1195.188232 1496.070557,1186.436890 1496.128662,1177.862793
	C1496.432983,1132.867920 1496.261230,1087.869995 1496.285156,1042.873047
	C1496.294312,1025.753296 1496.331299,1025.729614 1513.546875,1025.711792
	C1536.212036,1025.688354 1558.877075,1025.699097 1581.542236,1025.716187
	C1584.540405,1025.718506 1587.539062,1025.828125 1590.536621,1025.915894
	C1595.336914,1026.056396 1597.926270,1028.469971 1598.059204,1033.339355
	C1598.150269,1036.670166 1598.269165,1040.001709 1598.270386,1043.333008
	C1598.285767,1082.663696 1598.158569,1121.994873 1598.364624,1161.324463
	C1598.418701,1171.625732 1599.472046,1181.922974 1600.114868,1192.218872
	C1600.218384,1193.875854 1600.501221,1195.532104 1600.828369,1197.163086
	C1605.030029,1218.119263 1616.572510,1228.205811 1637.942993,1229.562866
	C1640.600830,1229.731689 1643.273926,1229.693359 1645.939941,1229.693970
	C1685.603882,1229.701660 1725.267822,1229.727295 1764.931641,1229.659912
	C1769.106812,1229.652832 1773.280762,1229.083252 1778.919189,1228.666382
	C1777.433105,1223.652344 1776.587158,1219.879395 1775.216187,1216.308228
	C1748.043335,1145.532715 1773.800293,1063.480957 1836.650269,1020.786255
	C1898.437378,978.813538 1980.365845,983.034302 2037.437256,1031.130005
	C2090.361328,1075.730347 2109.869629,1149.212158 2086.122070,1214.447632
	C2084.769775,1218.161987 2083.555420,1221.926270 2082.442383,1225.183716
	C2085.546143,1230.807129 2090.314453,1229.599731 2094.410645,1229.625244
	C2116.408203,1229.761719 2138.406982,1229.701172 2160.405518,1229.702881
	C2178.737549,1229.704224 2197.070068,1229.739258 2215.401611,1229.661499
	C2219.387939,1229.644653 2223.421631,1229.527222 2227.349609,1228.915894
	C2246.523438,1225.932495 2256.437012,1216.535889 2259.754395,1197.523193
	C2261.062988,1190.021118 2261.612793,1182.298706 2261.634033,1174.674072
	C2261.788574,1119.345093 2261.705566,1064.015259 2261.730713,1008.685730
	C2261.738281,992.467163 2261.897949,992.308716 2277.730225,992.281738
	C2301.395264,992.241394 2325.060547,992.267944 2348.725586,992.277832
	C2351.057861,992.278809 2353.405273,992.210571 2355.719727,992.436157
	C2362.228271,993.070312 2363.880615,994.491089 2364.090088,1001.146179
	C2364.415039,1011.469238 2364.184326,1021.808594 2364.305176,1032.139771
	C2364.369629,1037.645508 2364.678467,1043.148315 2364.945557,1050.563110
	C2368.170166,1047.652344 2370.046387,1046.405396 2371.349121,1044.716064
	C2392.679932,1017.055542 2421.979492,1002.969543 2455.304688,996.685791
	C2469.949219,993.924438 2485.056641,992.575256 2499.968750,992.459534
	C2559.627686,991.996643 2619.292480,992.202454 2678.955078,992.307312
	C2699.408203,992.343262 2719.477539,995.504272 2738.993896,1001.582581
	C2779.852783,1014.307861 2807.621338,1041.250366 2823.716064,1080.584351
	C2831.472412,1099.539429 2836.148682,1119.307373 2837.842529,1139.673462
	C2838.972656,1153.262939 2839.567139,1166.930664 2839.642334,1180.567627
	C2839.868408,1221.563477 2839.739990,1262.561523 2839.712891,1303.558716
	C2839.701904,1320.233276 2839.658691,1320.285400 2823.021484,1320.286011
	C2525.708496,1320.297363 2228.395508,1320.295410 1931.082520,1320.295898
	C1833.089233,1320.296143 1735.095825,1320.366943 1637.102783,1320.196899
	C1625.811401,1320.177246 1614.433472,1319.461304 1603.252197,1317.927368
	C1584.227539,1315.317505 1567.346436,1308.016235 1554.914551,1292.512451
	C1553.717896,1291.020020 1552.000854,1289.944946 1549.849121,1288.094604
	C1547.444580,1290.130981 1544.978027,1291.752808 1543.098877,1293.890869
	C1534.223145,1303.989502 1523.043945,1310.162231 1510.312134,1313.955933
	C1495.164673,1318.469482 1479.669434,1320.270630 1463.928345,1320.274658
	C1388.933472,1320.293579 1313.938721,1320.286499 1238.943848,1320.288452
	C1238.610596,1320.288452 1238.277222,1320.287964 1237.943970,1320.283325
	C1225.200684,1320.105103 1224.345825,1319.371460 1224.310669,1306.523315
	C1224.187866,1261.529541 1224.160034,1216.535278 1224.009521,1170.616577
M2673.600098,1087.059814
	C2665.033447,1085.954102 2656.471924,1083.942139 2647.898926,1083.891479
	C2596.923096,1083.590210 2545.941650,1083.487061 2494.968262,1083.958862
	C2484.789551,1084.053101 2474.383545,1086.004395 2464.526611,1088.715088
	C2446.127686,1093.774536 2431.810303,1104.957764 2422.183350,1121.558350
	C2412.449463,1138.343506 2407.897705,1156.632690 2407.750244,1176.006958
	C2407.661377,1187.667969 2407.713623,1199.330078 2407.723389,1210.991699
	C2407.738770,1229.681030 2407.746094,1229.703247 2426.434814,1229.704834
	C2523.392822,1229.712769 2620.350586,1229.709839 2717.308594,1229.703613
	C2720.639893,1229.703369 2723.985596,1229.812744 2727.299316,1229.549438
	C2734.371582,1228.987671 2736.034668,1227.559204 2736.112793,1220.242798
	C2736.265381,1205.923340 2736.372314,1191.577026 2735.656738,1177.283936
	C2735.176270,1167.688477 2734.093994,1157.958374 2731.814697,1148.647461
	C2724.300293,1117.951050 2706.820557,1096.060669 2673.600098,1087.059814
M1907.948364,1235.559326
	C1916.178467,1236.459106 1924.409668,1238.141357 1932.638306,1238.127930
	C1976.272461,1238.056519 2013.578247,1199.259399 2011.530518,1155.802979
	C2008.896240,1099.900146 1958.096680,1069.708374 1915.978760,1077.861328
	C1875.610840,1085.675537 1841.432739,1124.403809 1851.082520,1173.584473
	C1857.155640,1204.536621 1876.802734,1224.618286 1907.948364,1235.559326
z'
      />
      <path
        fill='#1C2346'
        opacity='1.000000'
        stroke='none'
        d='
M930.665894,1722.665527
	C932.634399,1715.805420 934.471313,1709.746094 936.107239,1703.633057
	C937.492371,1698.457153 940.466370,1695.760742 946.056946,1695.779907
	C970.712463,1695.864502 995.369446,1695.675049 1020.023621,1695.875122
	C1027.671265,1695.937134 1028.534912,1696.982422 1031.113159,1706.059326
	C1038.212280,1731.052856 1045.131348,1756.097534 1052.209961,1781.096802
	C1061.194702,1812.827393 1070.248047,1844.538696 1079.326660,1876.242432
	C1080.317749,1879.703247 1081.669678,1883.060547 1083.573120,1888.531982
	C1085.577515,1884.838745 1087.077148,1882.896118 1087.756348,1880.699829
	C1096.122681,1853.651123 1103.933716,1826.422241 1112.759644,1799.525879
	C1123.247803,1767.564819 1134.653809,1735.905640 1145.484619,1704.055420
	C1147.518066,1698.076050 1151.220337,1695.696411 1157.384155,1695.720459
	C1187.037842,1695.836426 1216.692505,1695.737183 1246.346436,1695.818237
	C1248.907104,1695.825317 1251.465698,1696.590820 1255.379761,1697.222534
	C1254.414429,1701.604980 1254.023438,1705.459351 1252.722290,1708.977173
	C1229.841187,1770.837769 1207.402832,1832.870605 1183.677734,1894.406982
	C1168.946533,1932.615356 1152.018066,1969.972046 1136.567627,2007.911377
	C1133.202026,2016.176147 1128.129639,2018.532593 1119.801147,2018.411743
	C1094.816772,2018.049316 1069.823364,2018.335083 1044.833862,2018.259399
	C1032.430176,2018.221802 1031.769897,2017.928101 1028.125854,2005.706909
	C1015.847168,1964.527588 1003.823792,1923.272339 991.640381,1882.064575
	C988.842834,1872.602417 985.796082,1863.213989 982.864014,1853.791748
	C982.072144,1853.710449 981.280273,1853.629028 980.488342,1853.547729
	C979.179871,1857.057129 977.646851,1860.500854 976.597473,1864.086060
	C965.649353,1901.490723 954.811707,1938.927856 943.866333,1976.333374
	C940.685852,1987.202515 937.358459,1998.031372 933.912842,2008.819458
	C931.130615,2017.530273 930.434875,2018.157349 921.152039,2018.206665
	C894.830261,2018.346558 868.503723,2018.026367 842.186951,2018.417480
	C833.962524,2018.539673 829.424500,2015.500122 826.317749,2007.947754
	C812.631592,1974.678345 798.542297,1941.574829 784.598328,1908.411377
	C765.570801,1863.157471 753.352234,1815.640991 738.355591,1769.049561
	C731.926086,1749.074341 725.900452,1728.968018 719.845032,1708.874878
	C718.753845,1705.254150 718.411987,1701.407593 717.548157,1696.707275
	C722.128662,1696.296875 725.351624,1695.765137 728.575989,1695.757324
	C755.897949,1695.691528 783.220337,1695.661255 810.542175,1695.751343
	C821.974548,1695.789062 822.460938,1696.214111 825.816589,1707.284424
	C843.016663,1764.028442 860.166260,1820.787842 877.354736,1877.535400
	C878.299072,1880.653076 879.434753,1883.712646 880.663025,1887.335083
	C885.504883,1885.372803 885.499268,1881.389038 886.419922,1878.190430
	C895.817627,1845.539551 905.141296,1812.867065 914.440063,1780.187866
	C919.818787,1761.285034 925.114258,1742.358643 930.665894,1722.665527
z'
      />
      <path
        fill='#1C2346'
        opacity='1.000000'
        stroke='none'
        d='
M3015.323242,2066.767578
	C3040.415771,2070.458496 3064.632324,2071.439209 3088.676514,2065.789062
	C3106.407959,2061.622070 3121.949707,2053.773193 3131.560059,2037.349365
	C3140.183838,2022.612061 3143.556641,2006.431763 3143.785400,1989.510620
	C3143.797363,1988.638916 3143.047852,1987.756958 3142.444580,1986.416870
	C3141.169189,1986.710815 3139.686035,1986.636230 3138.779053,1987.322632
	C3116.353027,2004.295166 3090.409424,2010.859375 3062.990234,2011.932617
	C3036.433105,2012.972046 3010.440674,2009.273804 2985.929199,1998.263306
	C2953.499512,1983.695801 2932.207275,1959.401245 2923.933350,1924.676758
	C2921.332031,1913.758789 2919.975830,1902.291748 2919.906250,1891.064575
	C2919.525635,1829.756958 2919.701904,1768.445679 2919.738525,1707.135742
	C2919.747070,1692.912231 2920.326172,1692.329712 2934.269531,1692.292358
	C2959.259766,1692.225342 2984.250488,1692.272461 3009.240967,1692.273193
	C3010.240479,1692.273193 3011.240967,1692.264282 3012.239502,1692.298706
	C3021.912109,1692.631470 3023.324463,1693.957275 3023.669678,1703.285522
	C3023.792969,1706.613525 3023.724854,1709.948975 3023.725098,1713.281006
	C3023.728760,1765.261230 3023.630127,1817.241699 3023.845459,1869.221069
	C3023.875488,1876.470947 3024.736084,1883.887939 3026.456787,1890.923218
	C3029.665283,1904.039917 3037.881104,1913.534912 3050.618408,1918.253906
	C3071.073730,1925.832520 3091.626709,1926.464600 3111.845459,1917.172485
	C3123.274170,1911.920166 3131.181885,1903.236450 3135.792969,1891.548950
	C3140.004150,1880.874390 3141.759277,1869.791016 3141.742676,1858.322510
	C3141.670898,1808.674927 3141.703369,1759.026978 3141.724854,1709.379272
	C3141.732422,1692.317139 3141.768311,1692.288696 3159.024170,1692.273560
	C3183.681396,1692.251953 3208.338867,1692.207397 3232.995605,1692.313232
	C3244.322754,1692.361938 3245.673828,1693.677734 3245.679932,1705.039673
	C3245.728027,1797.004639 3246.033447,1888.971680 3245.477783,1980.933228
	C3245.322754,2006.593140 3241.650879,2032.050781 3232.419189,2056.383789
	C3215.050293,2102.164307 3182.301025,2130.871338 3135.427490,2143.679932
	C3101.580078,2152.928711 3067.079102,2153.835449 3032.316650,2150.710449
	C3005.034424,2148.257812 2978.139160,2143.827881 2951.880859,2135.908936
	C2947.422119,2134.564209 2943.070801,2132.857422 2938.684570,2131.278076
	C2934.062256,2129.613525 2932.706543,2126.454346 2934.264648,2121.815186
	C2941.159424,2101.286865 2947.860596,2080.692139 2954.951416,2060.231934
	C2957.128906,2053.948242 2958.597900,2053.799316 2965.814209,2055.484863
	C2982.009766,2059.267578 2998.239502,2062.904785 3015.323242,2066.767578
z'
      />
      <path
        fill='#1C2346'
        opacity='1.000000'
        stroke='none'
        d='
M2892.375732,1177.403564
	C2891.614014,1151.904175 2894.601562,1127.899658 2903.716553,1104.927612
	C2920.876465,1061.681396 2951.152588,1030.725464 2992.881836,1010.756470
	C3061.807129,977.773254 3153.559570,986.940674 3212.999268,1047.737183
	C3241.458984,1076.846680 3257.515381,1111.978516 3260.484863,1152.973145
	C3262.589355,1182.025879 3259.671143,1210.225220 3247.747559,1236.959961
	C3243.849365,1245.700317 3238.885254,1254.213867 3233.079346,1261.816040
	C3182.325684,1328.274292 3131.225098,1394.467163 3080.391846,1460.864990
	C3076.319580,1466.184204 3071.711914,1468.383423 3065.136719,1468.346436
	C3033.813477,1468.170288 3002.489014,1468.299805 2971.164795,1468.230347
	C2968.343506,1468.224121 2965.523682,1467.573242 2961.051270,1467.015625
	C2968.185791,1454.326172 2977.563477,1445.347656 2985.394043,1435.271484
	C2993.364014,1425.016235 3001.837402,1415.153442 3010.053223,1405.088257
	C3018.058105,1395.281616 3026.006592,1385.429077 3033.996338,1375.610107
	C3042.127441,1365.617554 3050.278809,1355.641113 3059.764404,1344.011108
	C3053.376709,1342.522827 3049.079102,1341.204102 3044.682373,1340.548828
	C3016.839844,1336.399658 2991.197266,1326.420776 2968.106934,1310.502808
	C2925.180420,1280.909912 2899.781738,1240.374390 2893.615967,1188.250610
	C2893.225098,1184.944336 2892.810791,1181.640869 2892.375732,1177.403564
M3053.289062,1079.904785
	C3026.649902,1088.513428 3007.780273,1105.505127 2997.464111,1131.698120
	C2991.846436,1145.962158 2991.062988,1160.887085 2991.798340,1175.943481
	C2994.427490,1229.783936 3040.836426,1267.258911 3094.314941,1256.690186
	C3124.642822,1250.696655 3145.558838,1232.030518 3156.273682,1202.924072
	C3164.602539,1180.299805 3164.527832,1157.222534 3157.061768,1134.306030
	C3143.789551,1093.569092 3099.339844,1067.305054 3053.289062,1079.904785
z'
      />
      <path
        fill='#1C2346'
        opacity='1.000000'
        stroke='none'
        d='
M2465.099609,1787.029175
	C2456.741943,1777.713013 2446.746826,1772.436890 2435.342529,1770.441162
	C2424.225342,1768.495850 2412.942871,1766.831055 2401.688232,1766.482544
	C2381.376953,1765.853516 2361.032715,1766.304565 2340.702148,1766.285400
	C2337.703613,1766.282593 2334.705322,1766.170288 2331.706787,1766.116211
	C2326.328613,1766.019043 2323.890381,1763.124878 2323.871582,1757.909546
	C2323.869141,1757.243530 2323.788818,1756.577881 2323.788818,1755.911987
	C2323.785645,1735.248657 2323.654053,1714.583862 2323.855713,1693.922485
	C2323.936035,1685.680908 2325.372559,1683.880005 2332.870605,1683.917236
	C2377.798828,1684.140503 2422.892090,1680.994385 2467.447510,1689.714355
	C2490.927490,1694.309692 2513.126953,1702.138672 2532.284912,1716.968140
	C2563.560059,1741.177002 2577.642578,1774.119141 2578.061035,1812.708374
	C2578.754883,1876.691650 2578.291504,1940.688110 2578.226318,2004.678955
	C2578.214600,2016.155762 2577.590820,2017.400635 2566.052734,2018.535156
	C2536.239746,2021.466553 2506.387695,2024.125366 2476.500488,2026.131592
	C2457.913330,2027.379272 2439.216797,2027.959717 2420.598389,2027.544678
	C2396.636475,2027.010742 2372.654053,2025.593018 2349.275879,2019.447754
	C2334.078125,2015.452759 2319.375977,2010.267212 2305.678711,2002.351196
	C2281.598633,1988.434692 2266.803467,1967.838257 2263.015869,1940.429199
	C2260.041748,1918.904541 2260.293945,1897.262329 2267.711670,1876.236084
	C2274.947021,1855.726318 2288.248291,1840.619141 2307.311768,1830.570435
	C2330.165283,1818.523804 2355.052490,1813.840698 2380.456055,1812.126465
	C2400.039795,1810.804810 2419.713135,1810.635986 2439.352051,1810.386353
	C2447.650635,1810.280762 2455.957031,1811.508423 2464.270508,1811.720093
	C2473.112305,1811.944946 2475.071777,1809.439697 2472.260498,1800.864380
	C2470.733887,1796.207886 2467.836914,1792.000488 2465.099609,1787.029175
M2417.485840,1880.325073
	C2414.833496,1880.496216 2412.159424,1880.509033 2409.532471,1880.866333
	C2398.953857,1882.305420 2388.457764,1884.097412 2379.168701,1889.879883
	C2367.774658,1896.972656 2363.795410,1907.748535 2364.314941,1920.554688
	C2364.826660,1933.175171 2371.209229,1941.956177 2382.926514,1946.371826
	C2389.415283,1948.817383 2396.191406,1950.892944 2403.021729,1951.966797
	C2423.861816,1955.243286 2444.790527,1953.997925 2465.662598,1951.916748
	C2473.900635,1951.095337 2475.462158,1949.527466 2475.587158,1941.278320
	C2475.823730,1925.650146 2475.802246,1910.014648 2475.637451,1894.384888
	C2475.532715,1884.438232 2474.092041,1882.607788 2464.187744,1881.988525
	C2449.276367,1881.056396 2434.320801,1880.833008 2417.485840,1880.325073
z'
      />
      <path
        fill='#1C2346'
        opacity='1.000000'
        stroke='none'
        d='
M1423.283203,1766.377563
	C1399.359741,1766.333374 1376.363525,1766.318604 1353.367432,1766.288208
	C1350.368652,1766.284302 1347.355347,1766.371216 1344.374146,1766.116821
	C1337.592407,1765.537842 1336.533691,1764.389526 1335.859497,1757.575806
	C1335.826782,1757.244629 1335.807129,1756.910400 1335.807007,1756.577637
	C1335.802124,1735.581543 1335.654419,1714.584106 1335.871216,1693.590210
	C1335.954590,1685.516602 1337.417603,1683.811401 1345.201538,1683.902100
	C1386.128662,1684.379395 1427.194214,1680.986328 1467.896729,1688.203003
	C1485.056885,1691.245605 1501.643799,1695.916138 1517.268799,1703.793091
	C1554.650269,1722.638184 1575.869507,1753.114990 1581.827515,1794.234497
	C1583.445679,1805.401489 1584.124146,1816.792480 1584.168457,1828.084839
	C1584.395996,1886.074463 1584.292114,1944.065552 1584.266235,2002.056030
	C1584.259521,2016.980469 1583.917725,2017.944946 1568.951538,2019.321411
	C1539.449951,2022.034790 1509.905151,2024.409180 1480.333862,2026.185669
	C1460.738525,2027.363037 1441.053101,2027.933716 1421.431152,2027.561279
	C1394.745972,2027.054565 1368.201294,2024.524048 1342.590210,2016.174561
	C1324.051758,2010.130981 1306.749268,2001.997925 1292.494507,1988.096802
	C1281.644531,1977.515625 1274.508911,1964.994995 1270.823730,1950.496826
	C1264.600464,1926.012817 1265.503052,1901.628662 1273.191284,1877.605957
	C1280.901733,1853.513428 1297.501831,1837.426758 1320.269531,1827.501831
	C1345.390869,1816.550781 1372.087036,1813.384399 1399.178223,1811.542725
	C1422.191895,1809.978394 1445.075317,1811.633179 1468.015259,1812.091187
	C1469.347290,1812.117798 1470.689453,1812.194092 1472.011475,1812.075928
	C1478.159424,1811.526367 1479.841797,1809.655029 1478.529541,1803.669189
	C1476.150879,1792.818481 1470.458008,1784.091919 1461.024780,1777.909912
	C1449.826538,1770.570679 1437.308594,1767.591919 1423.283203,1766.377563
M1480.267090,1938.592529
	C1480.240356,1923.284180 1480.375732,1907.973022 1480.117676,1892.668579
	C1479.991333,1885.181885 1478.566284,1883.319946 1471.825195,1882.571167
	C1449.299072,1880.068604 1426.675415,1878.484131 1404.268066,1883.128540
	C1397.230835,1884.587280 1390.105103,1887.270508 1383.942261,1890.941650
	C1372.416870,1897.806763 1369.213501,1909.247192 1370.010010,1921.992554
	C1370.775879,1934.246338 1377.324585,1942.483032 1388.590332,1946.810425
	C1394.147461,1948.944946 1399.918701,1950.816284 1405.762207,1951.857300
	C1426.917725,1955.625732 1448.178833,1954.380737 1469.396606,1952.261108
	C1478.946289,1951.306885 1479.238770,1950.780029 1480.267090,1938.592529
z'
      />
      <path
        fill='#1C2346'
        opacity='1.000000'
        stroke='none'
        d='
M1679.428955,1870.682129
	C1648.027832,1846.873901 1640.481201,1814.770264 1645.087280,1778.500732
	C1649.894287,1740.648926 1671.674683,1715.503418 1705.720947,1700.123901
	C1726.302612,1690.826538 1748.078491,1685.912476 1770.487305,1684.362793
	C1817.547119,1681.108643 1863.683228,1685.875977 1908.311890,1701.963867
	C1915.206299,1704.449219 1921.992065,1707.236694 1928.820923,1709.902710
	C1933.200684,1711.612427 1936.141357,1714.384277 1936.127441,1719.505737
	C1936.063599,1742.833862 1936.061768,1766.161987 1935.953735,1789.489746
	C1935.947998,1790.719482 1935.172974,1791.945557 1934.367798,1794.317139
	C1930.458862,1793.006592 1926.780762,1791.942627 1923.229492,1790.554199
	C1904.941772,1783.404785 1886.982910,1775.263916 1868.350098,1769.181885
	C1840.475220,1760.083130 1811.502197,1758.251099 1782.348755,1760.146851
	C1776.107910,1760.552612 1769.851196,1762.108276 1763.826294,1763.892090
	C1760.446899,1764.892578 1757.117432,1766.992432 1754.473145,1769.380493
	C1744.045532,1778.797852 1745.873413,1791.844604 1758.550537,1797.761597
	C1763.322998,1799.989258 1768.582397,1801.541748 1773.785522,1802.414062
	C1794.479004,1805.882935 1815.307129,1808.578003 1835.951782,1812.300171
	C1854.283813,1815.605347 1872.700928,1818.957764 1890.603516,1823.987061
	C1911.235107,1829.783081 1928.899536,1840.987793 1941.680054,1858.828125
	C1956.064087,1878.907104 1959.347900,1901.609009 1957.114624,1925.474976
	C1953.129150,1968.065430 1928.971680,1995.455078 1890.965820,2012.059692
	C1872.783081,2020.003662 1853.556519,2024.033203 1833.882812,2025.953735
	C1788.271484,2030.406372 1743.371338,2025.947632 1699.250000,2014.068726
	C1685.457642,2010.355591 1672.165649,2004.787842 1658.636719,2000.091553
	C1652.609497,1997.999268 1649.605835,1994.204590 1649.685059,1987.424927
	C1649.941772,1965.428833 1649.739990,1943.427612 1649.839600,1921.429077
	C1649.851074,1918.878784 1650.644409,1916.331909 1651.245361,1912.777344
	C1654.396362,1913.423706 1657.000488,1913.552490 1659.266602,1914.494507
	C1667.880249,1918.075562 1676.419434,1921.842651 1684.923706,1925.679077
	C1714.425293,1938.987305 1745.377563,1946.530396 1777.638672,1948.198364
	C1791.208496,1948.900024 1804.886719,1948.685425 1818.450073,1947.838745
	C1825.279175,1947.412109 1832.190308,1945.237305 1838.645996,1942.762085
	C1845.042358,1940.309570 1848.254272,1934.899170 1848.236084,1927.752197
	C1848.217285,1920.319336 1845.106079,1914.551270 1838.353271,1911.787354
	C1831.925659,1909.156494 1825.124512,1906.978516 1818.283081,1905.906860
	C1799.531860,1902.969482 1780.630005,1900.999390 1761.867432,1898.126221
	C1738.122559,1894.489868 1714.454346,1890.326172 1692.966431,1878.635986
	C1688.580200,1876.249878 1684.324707,1873.623535 1679.428955,1870.682129
z'
      />
      <path
        fill='#1C2346'
        opacity='1.000000'
        stroke='none'
        d='
M2119.724854,1872.000122
	C2119.725098,1914.998901 2119.726807,1956.997803 2119.723145,1998.996704
	C2119.722900,2001.996460 2119.797119,2005.002075 2119.641113,2007.994873
	C2119.200928,2016.447144 2117.885742,2018.112061 2109.583740,2018.163330
	C2081.586182,2018.336182 2053.585693,2018.340088 2025.588135,2018.143188
	C2017.528809,2018.086548 2016.362549,2016.592285 2015.837769,2008.793579
	C2015.659180,2006.137329 2015.722534,2003.462769 2015.722534,2000.796631
	C2015.718506,1895.466064 2015.715820,1790.135376 2015.724609,1684.804810
	C2015.726074,1666.734741 2017.277344,1648.833862 2022.508545,1631.447876
	C2033.666870,1594.362549 2057.296143,1569.186890 2094.205566,1556.754150
	C2121.281006,1547.633789 2149.174561,1546.868530 2177.232178,1549.033447
	C2195.228760,1550.422119 2212.973877,1553.520630 2229.140137,1562.380859
	C2230.893311,1563.341919 2232.713379,1564.190186 2234.418457,1565.228394
	C2246.059326,1572.315552 2247.190674,1574.931030 2244.045166,1588.044189
	C2240.313965,1603.597534 2236.558105,1619.145630 2232.664551,1634.658813
	C2231.817627,1638.033691 2230.341797,1641.250610 2228.967285,1645.063599
	C2226.062744,1643.878052 2224.152588,1643.351440 2222.500488,1642.384155
	C2208.399902,1634.129028 2192.972168,1629.494751 2176.895752,1628.969604
	C2166.816895,1628.640503 2156.127197,1630.211548 2146.602539,1633.506470
	C2130.735840,1638.995361 2121.723877,1651.766235 2120.562256,1668.303345
	C2119.304443,1686.214478 2120.009521,1704.265381 2119.919678,1722.255615
	C2119.892822,1727.625854 2122.855957,1730.080322 2128.083740,1730.113770
	C2132.416260,1730.141602 2136.748535,1730.253906 2141.080811,1730.257935
	C2163.746826,1730.278809 2186.413330,1730.201660 2209.078613,1730.315063
	C2220.373535,1730.371460 2221.633789,1731.657959 2221.680664,1743.130615
	C2221.778809,1767.129639 2221.783936,1791.129517 2221.675049,1815.128418
	C2221.624023,1826.410156 2220.346924,1827.640625 2208.819580,1827.690552
	C2185.154053,1827.792969 2161.487793,1827.726807 2137.821777,1827.741455
	C2134.822510,1827.743408 2131.811768,1827.662354 2128.825928,1827.882446
	C2121.572998,1828.416870 2120.117432,1829.631836 2119.910156,1837.003906
	C2119.591064,1848.328735 2119.761963,1859.667480 2119.724854,1872.000122
z'
      />
      <path
        fill='#1C2346'
        opacity='1.000000'
        stroke='none'
        d='
M2788.028076,1933.652832
	C2803.797363,1933.343262 2817.820557,1929.506348 2831.461670,1923.761475
	C2842.343506,1919.178589 2842.501465,1919.318115 2848.515381,1929.726074
	C2858.345947,1946.739380 2868.110352,1963.791260 2877.802979,1980.883545
	C2882.674561,1989.474243 2882.353027,1990.701294 2874.291748,1996.225464
	C2859.572266,2006.312622 2843.248535,2012.903442 2826.280762,2018.068726
	C2791.172119,2028.756592 2755.717285,2031.278198 2719.843018,2022.341675
	C2672.684570,2010.593994 2641.730469,1974.287964 2637.692871,1925.867310
	C2636.836914,1915.601562 2636.361816,1905.274536 2636.334717,1894.974121
	C2636.199951,1843.676636 2636.084473,1792.377686 2636.331055,1741.080933
	C2636.540771,1697.468872 2637.222168,1653.858887 2637.762207,1610.248779
	C2637.904053,1598.791260 2638.848389,1597.810303 2650.527588,1597.759399
	C2676.176025,1597.647461 2701.824951,1597.721191 2727.473877,1597.732178
	C2729.137939,1597.732910 2730.802002,1597.846191 2732.466064,1597.901978
	C2737.220459,1598.060791 2739.911133,1600.352295 2740.053223,1605.259399
	C2740.139893,1608.254883 2740.266846,1611.250977 2740.270020,1614.246948
	C2740.292236,1635.232422 2740.270508,1656.217896 2740.308105,1677.203369
	C2740.313477,1680.194824 2740.365723,1683.211548 2740.752930,1686.169922
	C2741.187500,1689.487305 2743.183350,1691.626221 2746.662354,1691.895996
	C2749.646484,1692.127075 2752.647217,1692.240967 2755.640625,1692.244873
	C2778.624756,1692.274170 2801.608643,1692.259644 2824.592773,1692.272217
	C2827.590088,1692.273926 2830.602783,1692.174927 2833.581299,1692.434937
	C2840.185303,1693.011597 2841.610596,1694.431274 2842.120605,1700.997070
	C2842.378174,1704.310669 2842.277100,1707.654907 2842.277344,1710.985229
	C2842.278564,1733.303101 2842.358643,1755.621582 2842.211426,1777.938599
	C2842.143066,1788.292603 2840.904297,1789.576050 2830.659424,1789.652710
	C2808.342529,1789.819824 2786.023926,1789.722290 2763.706055,1789.736328
	C2759.708740,1789.738892 2755.708984,1789.668457 2751.714844,1789.786743
	C2741.981934,1790.074707 2740.436523,1791.288574 2740.390869,1800.728882
	C2740.244629,1831.040161 2740.030762,1861.357544 2740.480957,1891.663086
	C2740.814453,1914.138184 2754.288086,1930.770996 2777.207031,1932.717041
	C2780.518799,1932.998169 2783.824951,1933.338989 2788.028076,1933.652832
z'
      />
      <path
        fill='#5D02B2'
        opacity='1.000000'
        stroke='none'
        d='
M304.097015,566.000854
	C303.958862,529.345276 303.928436,492.688690 303.594696,456.034882
	C303.525238,448.406128 307.916687,444.793671 313.700317,441.460327
	C343.427338,424.327423 373.082581,407.069855 402.749237,389.832397
	C433.569397,371.924683 464.526215,354.243958 495.092224,335.911987
	C504.214813,330.440674 511.638184,330.293304 520.794128,335.966064
	C542.589539,349.469727 564.973328,362.023804 587.316284,376.063049
	C583.549866,381.053772 580.026978,385.573975 575.536865,388.708618
	C564.100708,396.692657 552.129578,403.906433 540.592468,411.751892
	C536.529724,414.514587 533.106201,418.217255 529.390808,421.490723
	C524.470886,418.735474 519.310242,416.327362 514.697327,413.129303
	C509.881683,409.790771 505.877747,410.354309 501.069061,413.195099
	C478.780640,426.362274 456.321930,439.240967 433.939941,452.249969
	C415.862366,462.757080 397.917603,473.500000 379.673462,483.708527
	C374.350006,486.687256 372.007477,490.160187 372.239227,496.271667
	C372.591125,505.552856 372.222321,514.861328 371.361725,525.304810
	C366.143494,528.690430 361.511505,530.594604 357.341827,533.230164
	C343.895477,541.729126 330.679077,550.591675 317.242157,559.106018
	C313.076660,561.745483 308.490540,563.721191 304.097015,566.000854
z'
      />
      <path
        fill='#29C2C9'
        opacity='1.000000'
        stroke='none'
        d='
M903.899292,752.006592
	C904.008789,774.048401 904.118286,796.090271 903.448181,819.329712
	C900.119141,822.083923 897.525024,823.572144 895.027161,825.207458
	C861.363708,847.247192 827.724060,869.323242 794.057312,891.358093
	C757.607605,915.214417 721.143555,939.049072 684.664673,962.860779
	C679.540466,966.205566 674.828857,969.792969 673.836060,976.400696
	C655.625000,965.788513 637.413940,955.176331 618.880371,943.509766
	C621.708801,939.192383 624.431458,935.309021 628.091003,932.782776
	C638.983398,925.263611 650.430725,918.545349 661.280945,910.969666
	C666.632935,907.232727 671.147888,902.296936 676.041626,897.903687
	C681.582153,900.916321 687.297058,903.653870 692.611877,907.022583
	C697.460144,910.095642 701.447815,909.726318 706.364685,906.817322
	C732.994385,891.062134 759.821594,875.640808 786.580444,860.103943
	C800.104980,852.251221 813.512756,844.186584 827.211182,836.650085
	C832.577148,833.697815 834.673767,829.987061 834.445679,823.932739
	C834.095215,814.626526 834.479004,805.292725 835.337036,794.890747
	C844.424316,788.980774 852.848389,784.347717 861.000244,779.276917
	C875.387451,770.327515 889.608643,761.111206 903.899292,752.006592
z'
      />
      <path
        fill='#25D1CB'
        opacity='1.000000'
        stroke='none'
        d='
M674.256348,976.921143
	C674.828857,969.792969 679.540466,966.205566 684.664673,962.860779
	C721.143555,939.049072 757.607605,915.214417 794.057312,891.358093
	C827.724060,869.323242 861.363708,847.247192 895.027161,825.207458
	C897.525024,823.572144 900.119141,822.083923 903.295044,820.255310
	C904.055664,834.307373 904.160583,848.631836 904.336121,862.955505
	C904.423828,870.114746 900.767639,874.511292 894.732239,877.999451
	C847.436584,905.334351 800.245300,932.849670 753.018372,960.303528
	C738.619263,968.674011 724.061401,976.784729 709.867676,985.489563
	C702.815308,989.814819 696.491638,990.375305 689.484009,985.739136
	C684.778137,982.625732 679.627991,980.183899 674.256348,976.921143
z'
      />
      <path
        fill='#1C2346'
        opacity='1.000000'
        stroke='none'
        d='
M924.783569,1528.740967
	C948.003113,1510.586914 974.656982,1512.402222 993.368103,1532.883057
	C1006.082825,1546.800171 1008.843628,1568.632080 1000.095337,1586.079834
	C991.018860,1604.182007 972.074890,1614.171143 951.204773,1611.859863
	C932.101196,1609.744019 915.199829,1596.120239 911.106750,1577.822876
	C907.046265,1559.671265 909.600525,1542.640991 924.783569,1528.740967
z'
      />
      <path
        fill='#1C2346'
        opacity='1.000000'
        stroke='none'
        d='
M812.890808,1597.065918
	C796.251892,1578.925659 795.945801,1549.830078 812.802856,1531.785034
	C830.331604,1513.020996 859.012329,1511.006836 878.794922,1527.137451
	C897.679382,1542.536011 901.921387,1571.479004 888.181396,1591.182983
	C873.457214,1612.298340 845.544312,1618.585571 823.564331,1605.539185
	C819.888428,1603.357422 816.746216,1600.276367 812.890808,1597.065918
z'
      />
      <path
        fill='#1C2346'
        opacity='1.000000'
        stroke='none'
        d='
M1598.716309,973.018921
	C1569.172729,970.979126 1546.493896,942.126404 1556.637207,909.788635
	C1563.719116,887.210999 1586.139282,874.366760 1611.171997,878.135803
	C1633.103027,881.437744 1649.840698,901.375305 1650.156006,924.573120
	C1650.475952,948.114441 1634.265259,968.102661 1611.400757,972.142273
	C1607.505737,972.830444 1603.491455,972.844055 1598.716309,973.018921
z'
      />
      <path
        fill='#1C2346'
        opacity='1.000000'
        stroke='none'
        d='
M1922.793213,940.082764
	C1889.125366,935.493164 1871.060913,897.858032 1888.904785,867.904114
	C1898.654053,851.538574 1913.987305,844.042480 1932.508911,845.085266
	C1952.356567,846.202759 1967.330811,856.188904 1974.371094,875.320801
	C1981.066284,893.515015 1978.458496,910.498352 1965.173828,925.416687
	C1953.960449,938.008972 1939.727417,941.485352 1922.793213,940.082764
z'
      />
      <path
        fill='#1C2346'
        opacity='1.000000'
        stroke='none'
        d='
M1506.321899,878.828308
	C1525.680176,885.417175 1536.971069,897.875793 1540.689697,917.457397
	C1546.015503,945.501892 1522.280518,977.346802 1486.944336,972.507324
	C1466.701416,969.734924 1453.185303,958.097900 1447.607666,939.026855
	C1442.086914,920.149658 1446.399170,902.746216 1461.580444,889.092041
	C1469.508057,881.961853 1478.773804,877.753418 1489.596436,877.797974
	C1494.899048,877.819824 1500.199341,878.336121 1506.321899,878.828308
z'
      />
      <path
        fill='#4C3FBA'
        opacity='1.000000'
        stroke='none'
        d='
M698.130798,439.865356
	C708.420227,442.889069 712.873352,449.276215 712.528564,460.446625
	C711.812683,483.640472 712.178528,506.867706 711.252747,531.109619
	C705.887268,534.121704 701.038635,535.582825 696.912781,538.188416
	C685.104736,545.645569 673.736572,553.801758 661.895325,561.203003
	C656.323486,564.685547 650.064148,567.067932 644.116333,569.948914
	C643.960022,546.336121 643.920593,522.721436 643.536926,499.112305
	C643.469971,494.994110 641.953491,490.899506 640.802856,485.571564
	C643.703918,481.108917 646.495056,477.263702 650.188904,474.739075
	C662.517578,466.312683 675.305298,458.557007 687.619812,450.110840
	C691.595520,447.383942 694.653198,443.318512 698.130798,439.865356
z'
      />
      <path
        fill='#4559BD'
        opacity='1.000000'
        stroke='none'
        d='
M494.763733,751.955200
	C494.627899,720.673157 494.297241,689.390015 494.452057,658.109375
	C494.514099,645.577515 502.614502,640.462585 513.837708,645.893494
	C528.191833,652.839294 542.159241,660.585815 556.276306,668.019409
	C561.529297,670.785400 563.758301,675.232483 563.657349,681.126648
	C563.492004,690.776001 563.519287,700.428711 562.681641,711.163513
	C557.491577,714.447937 552.844849,716.274475 548.724365,718.920471
	C536.766968,726.599304 525.150940,734.813171 513.135498,742.396484
	C507.317871,746.068115 500.906342,748.798645 494.763733,751.955200
z'
      />
      <path
        fill='#4559BD'
        opacity='1.000000'
        stroke='none'
        d='
M643.994995,570.884766
	C650.064148,567.067932 656.323486,564.685547 661.895325,561.203003
	C673.736572,553.801758 685.104736,545.645569 696.912781,538.188416
	C701.038635,535.582825 705.887268,534.121704 711.145020,532.037842
	C711.964844,556.006226 712.045532,580.074829 711.349243,605.297729
	C706.535461,608.645752 702.327209,610.576904 698.492859,613.080566
	C685.257812,621.722351 672.254761,630.722534 658.941223,639.238770
	C654.578247,642.029602 649.537598,643.760864 644.806763,645.976685
	C644.434937,642.051636 643.752441,638.127319 643.743042,634.201416
	C643.693420,613.408020 643.812866,592.614258 643.994995,570.884766
z'
      />
      <path
        fill='#4C3FBA'
        opacity='1.000000'
        stroke='none'
        d='
M386.223969,643.631531
	C406.650787,655.366882 427.077606,667.102295 447.631683,679.911133
	C444.141235,684.263611 440.825867,687.976318 436.852875,690.745544
	C420.668396,702.026123 404.282532,713.017761 387.970917,724.115906
	C367.629059,712.448975 347.287201,700.782043 326.948975,688.171997
	C331.966278,683.500427 336.831940,679.549805 342.024231,676.089294
	C353.259369,668.601196 364.815460,661.586182 375.913239,653.905151
	C379.839508,651.187622 382.814606,647.095886 386.223969,643.631531
z'
      />
      <path
        fill='#5326B6'
        opacity='1.000000'
        stroke='none'
        d='
M385.727539,643.168823
	C382.814606,647.095886 379.839508,651.187622 375.913239,653.905151
	C364.815460,661.586182 353.259369,668.601196 342.024231,676.089294
	C336.831940,679.549805 331.966278,683.500427 326.475067,687.702881
	C303.694397,677.431580 303.677460,677.431580 303.663300,652.613953
	C303.658600,644.366150 303.765381,636.118408 304.658081,626.780823
	C309.059174,623.975037 312.837524,622.595215 316.150452,620.484741
	C330.414368,611.397949 344.485840,602.008850 358.753571,592.928223
	C362.917908,590.277771 367.500061,588.283813 371.890686,585.989014
	C372.040619,598.146301 372.728119,610.328674 372.162231,622.452576
	C371.745422,631.382690 374.029968,637.804993 382.727020,641.140015
	C383.629242,641.486023 384.399597,642.175781 385.727539,643.168823
z'
      />
      <path
        fill='#5326B6'
        opacity='1.000000'
        stroke='none'
        d='
M697.654053,439.391144
	C694.653198,443.318512 691.595520,447.383942 687.619812,450.110840
	C675.305298,458.557007 662.517578,466.312683 650.188904,474.739075
	C646.495056,477.263702 643.703918,481.108917 640.285828,484.972717
	C620.295654,474.205933 600.518799,462.815552 580.680542,450.392395
	C585.707764,445.268768 590.619507,440.931091 595.918701,437.134247
	C610.596558,426.617798 625.435852,416.326843 640.211365,405.946777
	C659.200012,416.936829 678.188660,427.926880 697.654053,439.391144
z'
      />
      <path
        fill='#3A84C1'
        opacity='1.000000'
        stroke='none'
        d='
M509.732056,880.703552
	C507.925415,879.881836 506.130890,879.032166 504.310028,878.243286
	C497.029358,875.088989 494.079285,869.569824 494.253326,861.658936
	C494.588776,846.414062 494.466125,831.159241 495.313293,814.825562
	C500.513794,811.526733 505.158417,809.664185 509.313538,807.029541
	C522.480530,798.681152 535.327515,789.821716 548.596680,781.644104
	C553.009460,778.924683 558.324341,777.669067 563.226807,775.744141
	C563.389893,791.242371 563.808167,806.743958 563.596130,822.237000
	C563.505554,828.854004 565.166870,833.830383 571.194092,837.474976
	C567.996948,839.571167 564.742981,840.543640 562.013489,842.321594
	C546.789307,852.238098 531.600769,862.216736 516.632324,872.511902
	C513.795898,874.462708 512.005188,877.933838 509.732056,880.703552
z'
      />
      <path
        fill='#3A84C1'
        opacity='1.000000'
        stroke='none'
        d='
M826.018311,599.877441
	C845.051392,610.863037 864.084534,621.848633 883.217102,633.892090
	C879.567810,638.162048 876.043945,641.692078 872.030762,644.530212
	C856.054749,655.828430 839.926208,666.910889 823.854126,678.073242
	C810.129089,670.358521 796.384827,662.677612 782.688477,654.912292
	C777.270752,651.840698 771.959473,648.581360 766.554688,644.377686
	C771.843933,639.018738 776.920959,634.314514 782.567993,630.441406
	C793.146484,623.186096 804.152832,616.556396 814.776550,609.364136
	C818.814087,606.630676 822.287659,603.064209 826.018311,599.877441
z'
      />
      <path
        fill='#2FACC6'
        opacity='1.000000'
        stroke='none'
        d='
M675.580078,897.418518
	C671.147888,902.296936 666.632935,907.232727 661.280945,910.969666
	C650.430725,918.545349 638.983398,925.263611 628.091003,932.782776
	C624.431458,935.309021 621.708801,939.192383 618.346680,943.086548
	C599.080261,932.789246 580.025024,921.860901 561.062622,910.030273
	C566.219238,905.341064 571.127686,901.321289 576.379211,897.815369
	C587.457153,890.419739 598.877747,883.527649 609.812561,875.932129
	C613.769348,873.183716 616.739685,869.015198 620.160034,865.494629
	C638.479492,875.974182 656.799011,886.453735 675.580078,897.418518
z'
      />
      <path
        fill='#2FACC6'
        opacity='1.000000'
        stroke='none'
        d='
M904.051025,751.079651
	C889.608643,761.111206 875.387451,770.327515 861.000244,779.276917
	C852.848389,784.347717 844.424316,788.980774 835.453369,793.968628
	C834.694519,772.716248 834.605225,751.306213 835.342651,728.881897
	C840.957458,725.783875 846.080627,724.240234 850.471069,721.515747
	C863.923706,713.167786 876.991882,704.200745 890.437561,695.841187
	C894.551697,693.283447 899.401794,691.909363 903.914856,689.993225
	C904.010864,710.046387 904.106873,730.099487 904.051025,751.079651
z'
      />
      <path
        fill='#3F70BF'
        opacity='1.000000'
        stroke='none'
        d='
M563.351318,774.961243
	C558.324341,777.669067 553.009460,778.924683 548.596680,781.644104
	C535.327515,789.821716 522.480530,798.681152 509.313538,807.029541
	C505.158417,809.664185 500.513794,811.526733 495.424500,813.897583
	C494.680389,793.977051 494.604980,773.901428 494.646667,752.890503
	C500.906342,748.798645 507.317871,746.068115 513.135498,742.396484
	C525.150940,734.813171 536.766968,726.599304 548.724365,718.920471
	C552.844849,716.274475 557.491577,714.447937 562.566406,712.089233
	C563.315186,732.680359 563.395508,753.429382 563.351318,774.961243
z'
      />
      <path
        fill='#3498C4'
        opacity='1.000000'
        stroke='none'
        d='
M904.053589,689.065796
	C899.401794,691.909363 894.551697,693.283447 890.437561,695.841187
	C876.991882,704.200745 863.923706,713.167786 850.471069,721.515747
	C846.080627,724.240234 840.957458,725.783875 835.457275,727.953369
	C834.599670,717.495239 833.885193,706.918457 834.489990,696.417664
	C834.967651,688.124695 832.513428,682.389648 824.375793,678.544922
	C839.926208,666.910889 856.054749,655.828430 872.030762,644.530212
	C876.043945,641.692078 879.567810,638.162048 883.670959,634.374512
	C884.660034,633.996155 885.306519,634.161865 885.927734,634.394836
	C899.741943,639.573547 906.716980,648.665710 904.552185,664.204529
	C903.459595,672.046936 904.252136,680.152039 904.053589,689.065796
z'
      />
      <path
        fill='#3498C4'
        opacity='1.000000'
        stroke='none'
        d='
M619.710693,865.013733
	C616.739685,869.015198 613.769348,873.183716 609.812561,875.932129
	C598.877747,883.527649 587.457153,890.419739 576.379211,897.815369
	C571.127686,901.321289 566.219238,905.341064 560.625244,909.519653
	C543.591064,900.508850 527.086975,891.106445 510.157471,881.203857
	C512.005188,877.933838 513.795898,874.462708 516.632324,872.511902
	C531.600769,862.216736 546.789307,852.238098 562.013489,842.321594
	C564.742981,840.543640 567.996948,839.571167 571.713013,837.983826
	C588.031921,846.671570 603.646606,855.602234 619.710693,865.013733
z'
      />
      <path
        fill='#5812B4'
        opacity='1.000000'
        stroke='none'
        d='
M372.008423,585.058716
	C367.500061,588.283813 362.917908,590.277771 358.753571,592.928223
	C344.485840,602.008850 330.414368,611.397949 316.150452,620.484741
	C312.837524,622.595215 309.059174,623.975037 304.799683,625.853516
	C304.004639,606.628967 303.904846,587.241882 303.951050,566.927856
	C308.490540,563.721191 313.076660,561.745483 317.242157,559.106018
	C330.679077,550.591675 343.895477,541.729126 357.341827,533.230164
	C361.511505,530.594604 366.143494,528.690430 371.237549,526.235474
	C371.982910,545.389221 372.054535,564.758850 372.008423,585.058716
z'
      />
      <path
        fill='#5812B4'
        opacity='1.000000'
        stroke='none'
        d='
M639.702209,405.489838
	C625.435852,416.326843 610.596558,426.617798 595.918701,437.134247
	C590.619507,440.931091 585.707764,445.268768 580.208130,449.928894
	C579.444275,450.351105 579.091431,450.204010 577.946533,449.478577
	C576.710693,448.575043 576.241150,448.292725 575.745850,448.053284
	C560.628296,439.453705 545.510742,430.854126 529.891968,421.872620
	C533.106201,418.217255 536.529724,414.514587 540.592468,411.751892
	C552.129578,403.906433 564.100708,396.692657 575.536865,388.708618
	C580.026978,385.573975 583.549866,381.053772 587.790405,376.536804
	C605.109375,385.617035 622.151245,395.324951 639.702209,405.489838
z'
      />
      <path
        fill='#3F70BF'
        opacity='1.000000'
        stroke='none'
        d='
M825.526489,599.425293
	C822.287659,603.064209 818.814087,606.630676 814.776550,609.364136
	C804.152832,616.556396 793.146484,623.186096 782.567993,630.441406
	C776.920959,634.314514 771.843933,639.018738 766.075439,643.905518
	C765.025696,644.209595 764.387024,644.000427 763.797729,643.695862
	C745.689087,634.335266 745.663696,634.299072 745.672607,613.768005
	C745.677979,601.441711 746.149780,589.095398 745.601501,576.794250
	C744.958923,562.376526 757.252502,559.100159 766.408691,564.648865
	C785.773010,576.383606 805.472656,587.564880 825.526489,599.425293
z'
      />
      <path
        fill='#3A84C1'
        opacity='1.000000'
        stroke='none'
        d='
M658.028259,778.117798
	C645.098267,773.087158 643.723694,771.215637 643.682922,757.646179
	C643.636902,742.315979 643.550842,726.982849 643.790344,711.655762
	C644.000549,698.204224 652.480835,693.425720 664.563782,699.698425
	C676.094299,705.684265 687.348816,712.275635 699.179626,717.587280
	C709.504578,722.222717 714.278992,729.027954 711.313965,741.296204
	C707.706116,743.342468 704.648621,743.928467 702.224976,745.495056
	C689.484253,753.730896 676.815735,762.087402 664.339661,770.716431
	C661.762329,772.498901 660.107971,775.615845 658.028259,778.117798
z'
      />
      <path
        fill='#4559BD'
        opacity='1.000000'
        stroke='none'
        d='
M388.446381,724.577026
	C404.282532,713.017761 420.668396,702.026123 436.852875,690.745544
	C440.825867,687.976318 444.141235,684.263611 448.127014,680.387085
	C458.687469,682.835449 462.262848,689.749084 461.828186,700.182190
	C461.234436,714.433167 461.805450,728.728699 461.614349,743.001587
	C461.426910,757.002563 452.847778,762.029663 440.538177,755.087097
	C423.199097,745.307739 406.114990,735.076294 388.446381,724.577026
z'
      />
      <path
        fill='#3F70BF'
        opacity='1.000000'
        stroke='none'
        d='
M645.062256,646.564575
	C649.537598,643.760864 654.578247,642.029602 658.941223,639.238770
	C672.254761,630.722534 685.257812,621.722351 698.492859,613.080566
	C702.327209,610.576904 706.535461,608.645752 711.235168,606.229858
	C712.029968,624.913330 711.746582,643.834839 712.461975,662.718567
	C712.842834,672.769104 704.666748,679.887695 693.539856,674.413513
	C682.235779,668.852112 671.091553,662.946106 660.059631,656.860474
	C654.923462,654.027222 650.218262,650.412781 645.062256,646.564575
z'
      />
      <path
        fill='#3498C4'
        opacity='1.000000'
        stroke='none'
        d='
M658.522217,778.569702
	C660.107971,775.615845 661.762329,772.498901 664.339661,770.716431
	C676.815735,762.087402 689.484253,753.730896 702.224976,745.495056
	C704.648621,743.928467 707.706116,743.342468 711.184448,742.167480
	C712.031555,756.645691 712.435181,771.264648 712.215088,785.874084
	C712.035034,797.828125 704.324524,802.555969 693.293152,797.239746
	C681.645874,791.626770 670.423889,785.131470 658.522217,778.569702
z'
      />
      <path
        fill='#5326B6'
        opacity='1.000000'
        stroke='none'
        d='
M576.024414,448.368439
	C576.241150,448.292725 576.710693,448.575043 577.472778,449.155640
	C577.219910,449.322235 576.723877,449.079742 576.024414,448.368439
z'
      />
    </svg>
  );
};
WasfatyWithoutPlusLogo.defaultProps = {
  height: adminLayoutVariables.headerHeight - 8,
  width: adminLayoutVariables.drawerWidth - 8,
};
