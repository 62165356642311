import { getEnvVariable } from "@health/common";
import { i18n, useTranslation } from "@health/i18n";
import { CodeSystemCode, CodeSystemSummery, ProcessingStatus } from "@health/queries";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { getCodeSystemProcessingStatusColor, getCodeSystemProcessingStatusText } from "../medlistUtils";

export const useMedicalCardHooks = (
  data: CodeSystemSummery,
  onUpdateAssign: (code: CodeSystemCode, editorUser?: string, reviewerUser?: string, onFinish?: () => void) => void
) => {
  const { t } = useTranslation("Admin");
  const [isOpen, setIsOpen] = useState(false);

  const { activeCodeSystem, codeSystemDefinition, iconUrl, lastCodeSystem } = data;
  const { id, codeSystemHistory } = activeCodeSystem || {};
  const { code, display, editorUserId, reviewerUserId } = codeSystemDefinition || {};
  const updatedCodeSystem = codeSystemHistory?.[codeSystemHistory?.length - 1];
  const uploadDate = codeSystemHistory?.[0]?.updateDate
    ? moment(codeSystemHistory?.[0]?.updateDate).locale(i18n.language).format("DD MMM YYYY | HH:MM")
    : "";
  const updateDate = updatedCodeSystem?.updateDate
    ? moment(updatedCodeSystem?.updateDate).locale(i18n.language).format("DD MMM YYYY | HH:MM")
    : "";
  const lastCadeSystemProcessingStatus = lastCodeSystem?.processingStatus as ProcessingStatus;
  const creator = lastCodeSystem?.codeSystemHistory?.[lastCodeSystem?.codeSystemHistory?.length - 1]?.user?.firstName;

  const [svgIcon, setSvgIcon] = useState("");

  useEffect(() => {
    const url = getEnvVariable("SERVER_URL") + "/" + iconUrl;
    fetch(url)
      .then(res => res.text())
      .then(text => setSvgIcon(text));
  }, []);

  const getStatusColor = useCallback(() => {
    return getCodeSystemProcessingStatusColor(lastCadeSystemProcessingStatus);
  }, [lastCadeSystemProcessingStatus]);

  const statusText = getCodeSystemProcessingStatusText(t, lastCadeSystemProcessingStatus);

  const handleAssignClick = () => {
    setIsOpen(true);
  };
  const onDialogClose = () => {
    setIsOpen(false);
  };
  const onAssign = (code: CodeSystemCode, activeReviewer?: string | undefined, activeEditor?: string | undefined) => {
    onUpdateAssign(code, activeReviewer?.toString(), activeEditor?.toString(), () => {
      setIsOpen(false);
    });
  };

  return {
    code,
    creator,
    display,
    editorUserId,
    getStatusColor,
    handleAssignClick,
    id,
    isOpen,
    lastCadeSystemProcessingStatus,
    lastCodeSystem,
    onAssign,
    onDialogClose,
    reviewerUserId,
    statusText,
    svgIcon,
    t,
    updateDate,
    uploadDate,
  };
};
