export enum EVENT_TOPIC {
  BULK_FILE = "bulk-file",
  PING_PONG = "ping-pong",
  ORDER_STATUS_UPDATE = "order-status-update",
  MEDICAL_DELIVERY_REQUEST_STATUS_CHANGED = "erx-delivery-status-update",
  ACCOUNT_NOTIFICATION = "account-notification",
  PRESCRIPTION_STATUS_CHANGED = "erx-status-update",
  ORDER_DELETED = "order-deleted",
  CHAT_MESSAGE_RECEIVED = "chat",
  NEED_ACTION_CODE_SYSTEM = "need-action-code-system",
}
interface NotificationBaseEvent {
  topic: EVENT_TOPIC;
  data: any;
}

export interface MedicalDeliveryNotificationEvent extends NotificationBaseEvent {
  topic: EVENT_TOPIC.MEDICAL_DELIVERY_REQUEST_STATUS_CHANGED;
  data: {
    id: string;
    status: string;
    branchId: string;
  };
}
export interface AccountNotificationEvent extends NotificationBaseEvent {
  topic: EVENT_TOPIC.ACCOUNT_NOTIFICATION;
  data: {
    id: string;
    title: string;
    body: string;
    type: string;
    category: string;
    extraData: any;
  };
}

export interface PrescriptionStatusChangedNotificationEvent extends NotificationBaseEvent {
  topic: EVENT_TOPIC.PRESCRIPTION_STATUS_CHANGED;
  data: {
    id: string;
    status: string;
    branchId: string;
  };
}
export interface OrderStatusChangedNotificationEvent extends NotificationBaseEvent {
  topic: EVENT_TOPIC.ORDER_STATUS_UPDATE;
  data: {
    id: string;
    status: string;
    branchId: string;
    deliveryStatus: string;
    userId: string;
  };
}
export interface OrderDeletedNotificationEvent extends NotificationBaseEvent {
  topic: EVENT_TOPIC.ORDER_DELETED;
  data: {
    id: string;
    userId: string;
    branchId: string;
  };
}
export interface NeedActionCodeSystemNotificationEvent extends NotificationBaseEvent {
  topic: EVENT_TOPIC.NEED_ACTION_CODE_SYSTEM;
  data: {
    id: string;
    version: string;
    processingStatus: string;
    codeSystemCode: string;
  };
}

export interface BulkFileStatusChangedNotificationEvent extends NotificationBaseEvent {
  topic: EVENT_TOPIC.BULK_FILE;
  data: {
    fileId: string;
    processingStatus: string;
    processedRecordCount: number;
    recordsCount: number;
    failedRecordCount: number;
  };
}
export interface ChatMessageReceivedNotificationEvent extends NotificationBaseEvent {
  topic: EVENT_TOPIC.CHAT_MESSAGE_RECEIVED;
  data: {
    id;
    content;
    created;
    sender: {
      id;
    };
    recipient: {
      id;
    };
    branch: {
      id;
    };
    attachments: [
      {
        id;
        file;
        alt;
      }
    ];
  };
}

export type NotificationEvent =
  | AccountNotificationEvent
  | ChatMessageReceivedNotificationEvent
  | MedicalDeliveryNotificationEvent
  | NeedActionCodeSystemNotificationEvent
  | NotificationBaseEvent
  | OrderDeletedNotificationEvent
  | OrderStatusChangedNotificationEvent
  | PrescriptionStatusChangedNotificationEvent;

export interface EVENT_LISTENER<T extends NotificationBaseEvent = NotificationEvent> {
  (eventData: T): void;
}

export declare type TOPIC_LISTENER<T extends NotificationBaseEvent> = Record<EVENT_TOPIC, EVENT_LISTENER<T>>;
export declare type TOPIC_LISTENERS<T extends NotificationBaseEvent> = Record<EVENT_TOPIC, [EVENT_LISTENER<T>]>;
export type OrgSubscribeFn = <T extends EVENT_TOPIC, Y extends NotificationBaseEvent>(
  topic: T,
  callBacks: TOPIC_LISTENER<Y>[T]
) => () => void;
export type SubscribeFn = <T extends EVENT_TOPIC, Y extends NotificationBaseEvent>(topic: T, callBacks: TOPIC_LISTENER<Y>[T]) => void;
