/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeliveryTimeSlotUpdateStatusMutationVariables = Types.Exact<{
  deliveryTimeSlotUpdateStatusId: Types.Scalars['ID'];
  isActive: Types.Scalars['Boolean'];
}>;


export type DeliveryTimeSlotUpdateStatusMutation = { __typename?: 'Mutation', deliveryTimeSlotUpdateStatus?: { __typename?: 'DeliveryTimeSlotUpdateStatus', deliveryTimeSlot?: { __typename?: 'DeliveryTimeSlot', startTime: any, endTime: any, deliveryType: Types.DeliveryTimeSlotDeliveryType, id: string, isActive: boolean } | null, deliveryTimeSlotsErrors: Array<{ __typename?: 'DeliveryTimeSlotError', code: Types.DeliveryTimeSlotErrorCode, field?: string | null, message?: string | null }> } | null };


export const DeliveryTimeSlotUpdateStatusDocument = gql`
    mutation DeliveryTimeSlotUpdateStatus($deliveryTimeSlotUpdateStatusId: ID!, $isActive: Boolean!) {
  deliveryTimeSlotUpdateStatus(
    id: $deliveryTimeSlotUpdateStatusId
    isActive: $isActive
  ) {
    deliveryTimeSlot {
      startTime
      endTime
      deliveryType
      id
      isActive
    }
    deliveryTimeSlotsErrors {
      code
      field
      message
    }
  }
}
    `;
export type DeliveryTimeSlotUpdateStatusMutationFn = Apollo.MutationFunction<DeliveryTimeSlotUpdateStatusMutation, DeliveryTimeSlotUpdateStatusMutationVariables>;

/**
 * __useDeliveryTimeSlotUpdateStatusMutation__
 *
 * To run a mutation, you first call `useDeliveryTimeSlotUpdateStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeliveryTimeSlotUpdateStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deliveryTimeSlotUpdateStatusMutation, { data, loading, error }] = useDeliveryTimeSlotUpdateStatusMutation({
 *   variables: {
 *      deliveryTimeSlotUpdateStatusId: // value for 'deliveryTimeSlotUpdateStatusId'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useDeliveryTimeSlotUpdateStatusMutation(baseOptions?: Apollo.MutationHookOptions<DeliveryTimeSlotUpdateStatusMutation, DeliveryTimeSlotUpdateStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeliveryTimeSlotUpdateStatusMutation, DeliveryTimeSlotUpdateStatusMutationVariables>(DeliveryTimeSlotUpdateStatusDocument, options);
      }
export type DeliveryTimeSlotUpdateStatusMutationHookResult = ReturnType<typeof useDeliveryTimeSlotUpdateStatusMutation>;
export type DeliveryTimeSlotUpdateStatusMutationResult = Apollo.MutationResult<DeliveryTimeSlotUpdateStatusMutation>;
export type DeliveryTimeSlotUpdateStatusMutationOptions = Apollo.BaseMutationOptions<DeliveryTimeSlotUpdateStatusMutation, DeliveryTimeSlotUpdateStatusMutationVariables>;