import { useTranslation } from "@health/i18n";
import { SearchParameter } from "@health/queries";
import { useToasts } from "@health/ui";
import { GetSubListsQuery, useGetSubListsQuery, useImportTerminologyDataToSubListMutation } from "admin-gql";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

export const useFetchToSubListHook = ({ searchParameters }) => {
  const { t } = useTranslation("Admin");
  const { id: codeSystemId } = useParams();
  const methods = useForm();
  const { addToast, updateToast } = useToasts();
  const { data, loading: isLoading, fetchMore } = useGetSubListsQuery();
  const selectedSubList = methods?.watch("subList");
  const subListId = selectedSubList?.id;
  const subLists = data?.subLists?.edges?.map(e => e?.node) || [];
  const hasMoreData = data?.subLists?.pageInfo?.hasNextPage;
  const getValue = ({ valueBoolean, valueDate, valueInteger, valueString, valueFloat }: SearchParameter) =>
    valueBoolean || valueDate || valueInteger || valueString || valueFloat;

  const [fetchImportTerminologyDataToSubList, { loading: isSubmitting }] = useImportTerminologyDataToSubListMutation();
  const handleOnFetchToMedList = () => {
    const toastId = "" + codeSystemId + searchParameters.map(p => `${p.code}:${getValue(p)}`).join("-");
    addToast(t("Fetching to SubList Please Wait ...."), {
      id: toastId,
      appearance: "info",
    });
    fetchImportTerminologyDataToSubList({
      variables: {
        codeSystemId: codeSystemId!,
        subListId: subListId!,
        searchParameters: searchParameters,
      },
      onCompleted() {
        updateToast(toastId, {
          content: t("Fetched Successfully"),
          appearance: "success",
          autoDismiss: true,
        });
      },
      onError() {
        updateToast(toastId, {
          content: t("Error fetching"),
          appearance: "error",
          autoDismiss: true,
        });
      },
    });
  };
  const fetchMoreData = () => {
    if (data?.subLists?.pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          first: 10,
          after: data?.subLists?.pageInfo?.endCursor,
        },
        updateQuery: (prev: GetSubListsQuery, { fetchMoreResult }: any) => {
          if (!fetchMoreResult) return prev;
          return {
            ...fetchMoreResult,
            subLists: {
              ...fetchMoreResult?.subLists,
              edges: [...(prev?.subLists?.edges || []), ...(fetchMoreResult?.subLists?.edges || [])],
            },
          };
        },
      });
    }
  };

  return {
    t,
    methods,
    getValue,
    fetchMoreData,
    handleOnFetchToMedList,
    subLists,
    isLoading,
    isSubmitting,
    hasMoreData,
    selectedSubList,
  };
};
