/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CheckoutDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type CheckoutDeleteMutation = { __typename?: 'Mutation', checkoutDelete?: { __typename?: 'CheckoutDelete', checkout?: { __typename?: 'Checkout', id: string } | null, checkoutErrors: Array<{ __typename?: 'CheckoutError', code: Types.CheckoutErrorCode, message?: string | null, field?: string | null }> } | null };


export const CheckoutDeleteDocument = gql`
    mutation checkoutDelete($id: ID!) {
  checkoutDelete(id: $id) {
    checkout {
      id
    }
    checkoutErrors {
      code
      message
      field
    }
  }
}
    `;
export type CheckoutDeleteMutationFn = Apollo.MutationFunction<CheckoutDeleteMutation, CheckoutDeleteMutationVariables>;

/**
 * __useCheckoutDeleteMutation__
 *
 * To run a mutation, you first call `useCheckoutDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutDeleteMutation, { data, loading, error }] = useCheckoutDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckoutDeleteMutation(baseOptions?: Apollo.MutationHookOptions<CheckoutDeleteMutation, CheckoutDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckoutDeleteMutation, CheckoutDeleteMutationVariables>(CheckoutDeleteDocument, options);
      }
export type CheckoutDeleteMutationHookResult = ReturnType<typeof useCheckoutDeleteMutation>;
export type CheckoutDeleteMutationResult = Apollo.MutationResult<CheckoutDeleteMutation>;
export type CheckoutDeleteMutationOptions = Apollo.BaseMutationOptions<CheckoutDeleteMutation, CheckoutDeleteMutationVariables>;