/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/ban-types */
import { NetworkStatus, QueryHookOptions, QueryResult, WatchQueryFetchPolicy } from "@apollo/client";
import { useEffect, useState } from "react";
import { OrderDirection } from "../graphql/types";

export type SortDirection = "DESC" | "ASC" | false;

export const nextSortDirection = (direction: SortDirection | undefined): SortDirection | undefined => {
  return direction === "ASC" ? "DESC" : "ASC";
};

export declare type GridOrder = {
  /** Specifies the direction in which to sort products. */
  direction: OrderDirection;
  /** Sort drivers by the selected field. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field?;
};
// todo ?? maybe send more than these variables!
interface BaseGridVariables {
  id?: string;
  first?: number | null;
  after?: string | null;
  before?: string | null;
  last?: number | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter?;
  sortBy?: GridOrder | null;
  [key: string]: any;
}

// todo: add types
const defaultVariables: BaseGridVariables = {
  first: 10,
  after: null,
  before: null,
  last: null,
  filter: null,
  sortBy: null,
};

// eslint-disable-next-line @typescript-eslint/ban-types
interface UseQGridStateProps<TQueryOptions, TQuery> {
  useCustomQuery(baseOptions?: QueryHookOptions<TQuery, TQueryOptions> | undefined): QueryResult<TQuery, TQueryOptions>;
  input?: QueryHookOptions<TQuery, TQueryOptions>["variables"];
  size?: number;
  fetchPolicy?: WatchQueryFetchPolicy;
  dataAccessor?: string;
  onCompleted?: (_data?: any) => void;
  onError?: (_data?: any) => void;
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDataGridState = <TQueryOptions, TQuery>({
  useCustomQuery,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  input = defaultVariables as any,
  onCompleted,
  onError,
  dataAccessor,
  fetchPolicy,
  size = 10,
}: UseQGridStateProps<TQueryOptions, TQuery>) => {
  const [pageSize, setPageSize] = useState<number>(size);
  const [variables, setVariables] = useState<QueryHookOptions<TQuery, TQueryOptions>["variables"]>(input);
  const { data, loading, networkStatus, fetchMore, refetch, ...queryResult } = useCustomQuery({
    variables,

    errorPolicy: "all",
    fetchPolicy: fetchPolicy,
    onCompleted: onCompleted,
    onError: onError,
  });

  const key = dataAccessor ? dataAccessor : (data && Object.keys(data!)[0]!) || ""; // ! do not trust this code
  const pageInfo = (key && data?.[key]?.pageInfo) || {}; // ! do not trust this code

  useEffect(() => {
    fetchMore({
      variables,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        // eslint-disable-next-line prefer-object-spread
        return Object.assign({}, prev, fetchMoreResult);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables]);

  const isLoading: boolean = loading || networkStatus === NetworkStatus.fetchMore || networkStatus === NetworkStatus.refetch;

  // todo: types
  const doGoToNext = (_input?) => {
    setVariables(
      _input ||
        (state => ({
          ...state,
          first: pageSize,
          after: pageInfo?.endCursor,
          last: null,
          before: null,
        }))
    );
  };
  // todo: types
  const doGoToPrevious = (_input?) => {
    setVariables(
      _input ||
        (state => ({
          ...state,
          last: pageSize,
          before: pageInfo?.startCursor,
          first: null,
          after: null,
        }))
    );
  };
  // todo: types
  const doSort = (field, _input?) => {
    setVariables(
      _input ||
        (state => ({
          ...state,
          first: pageSize,
          after: null,
          last: null,
          before: null,
          sortBy: nextSortDirection(state?.["sortBy"]?.direction)
            ? {
                direction: nextSortDirection(state?.["sortBy"]?.direction),
                field,
              }
            : null,
        }))
    );
  };
  // todo: types
  const doChangePageSize = (_size: number): void => {
    setPageSize(_size);
    setVariables(state => ({
      ...state,
      first: _size,
      after: null,
      last: null,
      before: null,
    }));
  };
  // todo: types
  const doFilter = (filter, _input?) => {
    setVariables(
      _input ||
        (state => ({
          ...state,
          first: pageSize,
          after: null,
          last: null,
          before: null,
          filter: {
            ...filter,
          },
        }))
    );
  };

  return {
    ...queryResult,
    networkStatus,
    data,
    isLoading,
    pageSize,
    variables,
    refetch,
    fetchMore,
    doSort,
    doFilter,
    doGoToNext,
    setPageSize,
    setVariables,
    doGoToPrevious,
    doChangePageSize,
  };
};
