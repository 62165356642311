/* eslint-disable react/no-unknown-property */
/* eslint-disable react/forbid-dom-props */
/* eslint-disable max-lines */
import React, { FC } from "react";
import { makeStyles } from "tss-react/mui";
import { adminLayoutVariables } from "../../customization/layout";

const useStyles = makeStyles()(() => ({
  st0: { fill: "#1C2346" },
  st1: { fill: "url(#SVGID_1_)" },
  st2: { fill: "url(#SVGID_00000042727515863602394500000000273361957468045238_)" },
  st3: { fill: "url(#SVGID_00000165208532490012510980000013433950246074644155_)" },
  st4: { fill: "url(#SVGID_00000132068979637457343280000008431883264524295555_)" },
}));
export const WasfatyLogo: FC<{ width?: string | number; height?: string | number }> = ({ width, height }) => {
  const { classes } = useStyles();
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      // xmlns:xlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 685.98 385.51'
      height={height || adminLayoutVariables.headerHeight}
      width={width || adminLayoutVariables.drawerWidth}
      // style={{ enableBackground: "new 0 0 685.98 385.51" }}
      // xml:space='preserve'
    >
      {/* <style type='text/css'></style> */}
      <g>
        <path
          className={classes.st0}
          d='M118.04,261.91c-4.8,13.55-8.63,24.39-9.01,25.73c-1.08,3.76-2.14,7.35-3.16,10.76
		c-1.03,3.41-1.99,6.55-2.9,9.39c-0.92,2.85-1.71,5.29-2.39,7.35c-0.68-2.39-1.56-5.44-2.65-9.14c-1.09-3.69-2.25-7.77-3.5-12.21
		c-1.25-4.44-2.59-9.19-4.01-14.26c-0.65-2.32-2.73-9.76-4.93-17.62H61.64c-2.58,9.22-4.98,17.77-5.48,19.5
		c-1.14,3.93-2.3,8.06-3.5,12.38c-1.19,4.33-2.36,8.46-3.5,12.39c-1.14,3.93-2.05,6.91-2.73,8.97c-0.8-2.39-1.77-5.47-2.91-9.22
		c-0.43-1.41-6.17-20.46-13.27-44.01H2.01c7.87,25.55,14.53,47.05,15.38,49.22c2.16,5.52,4.58,11.52,7.26,18.02
		c2.68,6.49,5.44,12.87,8.29,19.13H59.4c0.57-1.71,1.43-4.47,2.56-8.29c1.14-3.81,2.36-7.97,3.67-12.47
		c1.31-4.5,2.62-9.02,3.93-13.58c1.31-4.55,2.48-8.43,3.5-11.61c1.03,3.19,2.19,7,3.51,11.44c1.31,4.44,2.62,8.91,3.93,13.4
		c1.31,4.5,2.53,8.69,3.67,12.56c1.14,3.87,2.05,6.72,2.74,8.54h26.3c2.96-6.26,6.09-13.47,9.39-21.6
		c3.3-8.14,6.46-16.17,9.48-24.08c1.33-3.48,7.81-21.27,14.86-40.67H118.04z'
        />
        <path
          className={classes.st0}
          d='M497.59,278.24c-2.45-4.78-5.81-8.62-10.08-11.53c-4.27-2.91-9.31-4.95-15.12-6.15
		c-4.9-1.01-10.08-1.56-15.54-1.72l-23.76-0.07v22.01h23.6c5.39,0.36,9.42,1.44,12.04,3.28c3.25,2.28,4.87,5.41,4.87,9.39
		c-1.59-0.23-3.62-0.4-6.06-0.51c-2.45-0.11-5.5-0.17-9.14-0.17c-14.58,0-25.22,2.25-31.94,6.75c-6.72,4.49-10.08,11.98-10.08,22.46
		c0,3.87,0.68,7.55,2.05,11.02c1.37,3.47,3.76,6.52,7.17,9.14c3.42,2.62,7.97,4.69,13.66,6.23c5.69,1.54,12.87,2.31,21.52,2.31
		c5.13,0,9.99-0.12,14.6-0.34c4.61-0.23,8.71-0.51,12.3-0.85c3.59-0.35,6.58-0.66,8.97-0.94c2.39-0.28,3.93-0.54,4.61-0.77v-51.58
		C501.26,289,500.04,283.03,497.59,278.24 M473.77,330.33c-0.8,0.23-2.45,0.43-4.95,0.6c-2.51,0.17-5.24,0.26-8.2,0.26
		c-3.76,0-6.77-0.31-9.05-0.94c-2.28-0.63-3.99-1.39-5.12-2.31c-1.14-0.91-1.88-1.93-2.22-3.08c-0.34-1.13-0.51-2.22-0.51-3.24
		c0-1.37,0.23-2.68,0.68-3.93c0.46-1.25,1.28-2.36,2.48-3.33c1.19-0.96,2.87-1.73,5.04-2.3c2.16-0.57,4.95-0.85,8.37-0.85
		c3.98,0,6.97,0.09,8.97,0.26c1.99,0.17,3.5,0.37,4.52,0.6V330.33z'
        />
        <path
          className={classes.st0}
          d='M332.25,308.65c-1.65-3.19-3.87-5.81-6.65-7.86c-2.79-2.05-5.98-3.61-9.56-4.7
		c-3.59-1.08-7.32-1.91-11.19-2.48l-15.54-2.22c-3.65-0.57-6.38-1.25-8.2-2.05c-1.82-0.8-2.74-2.16-2.74-4.1c0-1.93,1-3.5,2.99-4.7
		c1.99-1.19,5.56-1.79,10.68-1.79c3,0,5.78,0.16,8.36,0.46c0.52,0.06,1.02,0.12,1.53,0.19c0.35,0.05,0.69,0.11,1.04,0.17
		c3.34,0.58,6.37,1.26,9.03,2.04c2.81,0.82,5.23,1.7,7.31,2.65c0,0,9.69,4.36,9.7,4.35v-21.94c-1.52-0.72-3.53-1.58-6.11-2.53
		c-0.66-0.25-1.35-0.49-2.06-0.73c-0.21-0.08-0.38-0.14-0.6-0.21c-0.05-0.02-0.08-0.02-0.13-0.03c-0.21-0.07-0.4-0.14-0.61-0.2
		c-7.92-2.52-16.23-4-24.54-4.27c-7.23-0.23-14.91,0.3-21.87,2.39c-5.12,1.54-9.33,3.65-12.64,6.32c-3.31,2.68-5.72,5.81-7.26,9.39
		c-1.54,3.59-2.3,7.49-2.3,11.7c0,5.01,0.91,9.17,2.73,12.47c1.82,3.31,4.3,6.01,7.43,8.11c3.13,2.11,6.75,3.7,10.85,4.78
		c4.1,1.08,8.37,1.91,12.81,2.48l8.88,1.03c4.21,0.57,7.32,1.28,9.31,2.13c1.99,0.85,2.99,2.42,2.99,4.7c0,2.16-1.19,3.67-3.59,4.52
		c-2.39,0.86-5.92,1.28-10.59,1.28c-4.1,0-8-0.31-11.7-0.94c-3.7-0.62-7-1.36-9.91-2.22c-2.91-0.85-5.41-1.76-7.56-2.74
		c0,0-10.03-4.51-10.03-4.5v22.7c1.57,0.74,3.66,1.64,6.32,2.62c0.68,0.26,1.39,0.51,2.13,0.75c0.22,0.07,0.4,0.14,0.62,0.22
		c0.05,0.02,0.08,0.02,0.13,0.03c0.21,0.07,0.4,0.14,0.63,0.21c3.75,1.2,8.22,2.25,13.41,3.17c5.18,0.91,11.13,1.36,17.85,1.36
		c12.86,0,22.94-2.68,30.23-8.03c7.29-5.35,10.93-12.81,10.93-22.37C334.73,315.71,333.91,311.84,332.25,308.65'
        />
        <path
          className={classes.st0}
          d='M378.12,257.41c0-4.55,1.25-7.97,3.75-10.25c2.51-2.28,5.92-3.42,10.25-3.42c3.08,0,6.07,0.49,8.97,1.45
		c2.9,0.97,5.04,2.08,6.4,3.33c0.09-0.05,0.18-0.12,0.28-0.17l4.51-18.57c-1.05-1.16-2.12-2.13-3.25-2.79
		c-2.62-1.59-5.72-2.76-9.3-3.5c-3.59-0.74-7.78-1.11-12.56-1.11c-5.47,0-10.45,0.72-14.95,2.14c-4.5,1.43-8.35,3.59-11.53,6.49
		c-3.19,2.91-5.67,6.63-7.43,11.19c-1.77,4.56-2.65,9.97-2.65,16.23v89.84h27.5v-51.33h27.49v-25.62h-27.49V257.41z'
        />
        <path
          className={classes.st0}
          d='M652.59,261.16v43.9c0,6.15-1.42,10.71-4.27,13.66c-2.85,2.96-6.83,4.44-11.95,4.44
		c-5.47,0-9.48-1.2-12.04-3.59c-2.56-2.39-3.85-6.32-3.85-11.79v-46.63h-27.5v52.26c0,5.7,0.94,10.62,2.82,14.78
		c1.87,4.16,4.49,7.6,7.86,10.34c3.36,2.73,7.29,4.75,11.78,6.06c4.49,1.31,9.36,1.97,14.6,1.97c4.44,0,8.88-0.77,13.32-2.31
		c4.45-1.53,7.75-3.61,9.91-6.23c0,8.65-1.94,14.85-5.81,18.61c-3.87,3.76-10.19,5.64-18.95,5.64c-3.08,0-6.07-0.2-8.97-0.6
		c-2.91-0.4-5.49-0.85-7.77-1.37c-2.28-0.51-4.18-1.02-5.73-1.53c-1.54-0.51-2.53-0.88-2.99-1.11l-6.66,20.15
		c0.34,0.23,1.48,0.69,3.42,1.37c1.93,0.68,4.5,1.39,7.69,2.13c3.19,0.74,6.89,1.39,11.1,1.96c4.22,0.57,8.82,0.85,13.84,0.85
		c7.17,0,13.66-0.88,19.47-2.65c5.81-1.77,10.82-4.55,15.03-8.37c4.21-3.82,7.46-8.77,9.74-14.86c2.28-6.09,3.41-13.47,3.41-22.12
		v-74.98H652.59z'
        />
        <path
          className={classes.st0}
          d='M220.82,266.57c-4.27-2.9-9.3-4.95-15.1-6.14c-4.89-1.01-10.07-1.56-15.52-1.72l-21.96-0.07v21.98h21.79
		c5.36,0,9.41,1.44,12.02,3.27c3.24,2.28,4.87,5.7,4.87,9.68c-1.59-0.23-3.61-0.4-6.06-0.51c-2.45-0.11-5.49-0.17-9.13-0.17
		c-14.56,0-25.2,2.25-31.9,6.74c-6.71,4.49-10.07,11.97-10.07,22.43c0,3.87,0.68,7.54,2.04,11c1.37,3.47,3.75,6.51,7.17,9.13
		c3.41,2.62,7.96,4.69,13.65,6.22c5.68,1.54,12.85,2.3,21.49,2.3c5.12,0,9.98-0.11,14.59-0.34c4.6-0.23,8.7-0.51,12.28-0.85
		c3.58-0.35,6.57-0.66,8.96-0.94c2.39-0.28,3.92-0.55,4.61-0.77V296.3c0-7.17-1.22-13.44-3.67-18.22S225.08,269.47,220.82,266.57
		 M207.08,330.42c-0.8,0.23-2.45,0.43-4.95,0.6c-2.51,0.17-5.24,0.26-8.19,0.26c-3.75,0-6.77-0.31-9.04-0.94
		c-2.28-0.63-3.98-1.39-5.12-2.3c-1.14-0.91-1.88-1.93-2.22-3.07c-0.34-1.13-0.51-2.22-0.51-3.24c0-1.36,0.23-2.67,0.68-3.92
		c0.45-1.25,1.28-2.36,2.48-3.33c1.19-0.96,2.87-1.73,5.03-2.3c2.16-0.57,4.95-0.85,8.36-0.85c3.98,0,6.97,0.09,8.96,0.26
		c1.99,0.17,3.49,0.37,4.52,0.6V330.42z'
        />
        <path
          className={classes.st0}
          d='M566.34,324.32c-2.62,0.97-5.53,1.45-8.72,1.45c-1.6,0-3.16-0.14-4.7-0.43c-1.54-0.28-2.91-0.88-4.11-1.79
		c-1.19-0.91-2.19-2.14-2.99-3.67c-0.8-1.54-1.2-3.62-1.2-6.24v-26.82h27.47v-25.64h-27.47v-25.64h-27.37
		c-0.05,0.56-0.14,84.08-0.14,84.08c0,9.46,3.02,17,9.06,22.64c6.03,5.64,14.4,8.46,25.12,8.46c3.75,0,7.35-0.37,10.76-1.11
		c3.42-0.75,6.49-1.62,9.23-2.65c2.73-1.03,5.06-2.11,7-3.25c1.93-1.14,3.41-2.16,4.44-3.07l-10.76-18.8
		C570.84,322.52,568.96,323.35,566.34,324.32'
        />
        <path
          className={classes.st0}
          d='M37.36,213.87c-7.03,0-12.74,5.72-12.74,12.74c0,7.03,5.71,12.74,12.74,12.74s12.74-5.72,12.74-12.74
		C50.1,219.59,44.38,213.87,37.36,213.87'
        />
        <path
          className={classes.st0}
          d='M66.55,213.87c-7.03,0-12.74,5.72-12.74,12.74c0,7.03,5.72,12.74,12.74,12.74c7.03,0,12.74-5.72,12.74-12.74
		C79.29,219.59,73.58,213.87,66.55,213.87'
        />
        <path
          className={classes.st0}
          d='M680.48,101.6c-2.54-5.67-6.09-10.66-10.55-14.83c-4.46-4.18-9.74-7.5-15.67-9.88
		c-5.93-2.38-12.41-3.59-19.27-3.59c-6.74,0-13.19,1.21-19.18,3.59c-5.99,2.38-11.29,5.7-15.75,9.88c-4.45,4.17-8,9.16-10.55,14.83
		c-2.54,5.66-3.83,11.94-3.83,18.65c0,6.72,1.29,13,3.83,18.66c2.54,5.67,6.09,10.66,10.55,14.83c4.47,4.18,9.77,7.5,15.75,9.88
		c3.65,1.45,7.67,2.48,11.93,3.05l3.32,0.45l-27.53,33.67h30.66c3.31-4.3,6.61-8.61,9.92-12.9c6.49-8.45,12.98-16.89,19.47-25.34
		c4.54-5.91,9.16-11.78,13.63-17.74c3.08-4.11,4.99-8.95,6.05-13.98c0.73-3.49,1.04-7.07,1.04-10.57
		C684.31,113.53,683.02,107.25,680.48,101.6 M656.44,129.98c-1.11,3.01-2.75,5.65-4.86,7.82c-2.09,2.16-4.55,3.88-7.29,5.12
		c-2.77,1.25-5.9,1.89-9.3,1.89c-3.28,0-6.37-0.63-9.2-1.89c-2.8-1.24-5.29-2.96-7.39-5.13h0c-2.11-2.18-3.74-4.81-4.86-7.83
		c-1.1-2.98-1.66-6.25-1.66-9.72c0-3.47,0.56-6.74,1.66-9.72c1.12-3.01,2.75-5.65,4.86-7.82c2.09-2.16,4.58-3.88,7.39-5.13
		c2.84-1.25,5.93-1.88,9.2-1.88c3.39,0,6.52,0.63,9.3,1.89c2.76,1.25,5.21,2.97,7.29,5.12c2.11,2.17,3.74,4.81,4.86,7.82
		c1.1,2.98,1.66,6.25,1.66,9.72C658.11,123.73,657.55,127.01,656.44,129.98'
        />
        <path
          className={classes.st0}
          d='M239.55,67.95c7.03,0,12.74-5.71,12.74-12.74c0-7.03-5.71-12.74-12.74-12.74c-7.03,0-12.74,5.72-12.74,12.74
		C226.81,62.23,232.52,67.95,239.55,67.95'
        />
        <path
          className={classes.st0}
          d='M210.35,67.95c7.03,0,12.74-5.71,12.74-12.74c0-7.03-5.71-12.74-12.74-12.74c-7.03,0-12.74,5.72-12.74,12.74
		C197.61,62.23,203.33,67.95,210.35,67.95'
        />
        <path
          className={classes.st0}
          d='M327.43,59.31c7.03,0,12.74-5.72,12.74-12.74c0-7.03-5.71-12.74-12.74-12.74c-7.03,0-12.74,5.72-12.74,12.74
		C314.69,53.59,320.41,59.31,327.43,59.31'
        />
        <path
          className={classes.st0}
          d='M525.54,73.28h-43.7c-15.74,0-27.31,4.21-34.68,12.64c-0.92,1.05-1.79,2.16-2.59,3.32
		c-0.28,0.34-0.56,0.67-0.82,1.01V73.28h-27.18v50.49c-0.07,1.32-0.18,2.56-0.34,3.71c-0.29,2.05-0.86,3.79-1.71,5.21
		c-0.85,1.42-2.11,2.51-3.76,3.25c-1.65,0.74-3.84,1.11-6.58,1.11h-22.01h-14.83c2.88-5.88,4.49-12.48,4.49-19.46
		c0-24.47-19.83-44.3-44.3-44.3c-24.47,0-44.3,19.83-44.3,44.3c0,6.99,1.64,13.58,4.52,19.46h-12.62h-24.15h-0.06
		c-2.74,0-4.93-0.37-6.58-1.11c-1.65-0.74-2.91-1.82-3.76-3.25c-0.86-1.43-1.43-3.17-1.71-5.21c-0.16-1.15-0.27-2.4-0.34-3.73v-41.4
		h-27.18v41.32c-0.07,1.36-0.18,2.63-0.34,3.8c-0.29,2.05-0.86,3.79-1.71,5.21c-0.86,1.42-2.11,2.51-3.76,3.25
		c-1.65,0.74-3.85,1.11-6.58,1.11h-33.04v-19.48c0-5.92-1.14-11.2-3.42-15.81c-2.28-4.61-5.39-8.46-9.32-11.54
		c-3.93-3.08-8.54-5.41-13.84-7c-5.3-1.59-10.91-2.39-16.83-2.39c-6.04,0-11.68,0.8-16.92,2.39c-5.24,1.6-9.85,3.93-13.85,7
		c-3.99,3.08-7.12,6.93-9.4,11.54c-2.28,4.61-3.41,9.89-3.41,15.81v31.1c-0.58,8.48-3.25,15.24-7.61,19.31
		c-4.67,4.35-10.63,6.88-18.04,6.88c-6.5-0.56-11.88-2.81-16.14-6.78c-4.95-4.62-8.46-11.99-8.46-22.13l0.52-35.26H2.01v36.35
		c0,3.56,0.21,6.92,0.63,10.08c0.02,0.17,0.06,0.33,0.08,0.51c0.12,0.86,0.25,1.72,0.4,2.55c0.14,0.75,0.3,1.49,0.46,2.22
		c0.05,0.23,0.09,0.48,0.14,0.71c0.68,2.89,1.57,5.58,2.65,8.08c2.91,6.67,6.81,12.12,11.72,16.34c4.9,4.22,10.6,7.3,17.11,9.24
		c3.79,1.13,7.68,1.9,11.61,2.36c2.45,0.29,4.88,0.44,7.31,0.46c0.27,0,0.53,0.02,0.8,0.02c0,0,0,0.01,0,0
		c1.43-0.01,2.87-0.08,4.31-0.2c3.43-0.32,6.81-0.91,10.15-1.69c1.3-0.3,2.56-0.63,3.83-1.01c0.04-0.01,0.08-0.02,0.12-0.03
		c6.51-1.94,12.21-5.02,17.11-9.24c0.33-0.28,0.62-0.6,0.93-0.9c0.39-0.36,0.76-0.71,1.11-1.07c0.31-0.31,0.65-0.6,0.95-0.92
		c0.49-0.43,0.97-0.96,1.58-1.7c0.15-0.19,0.29-0.39,0.44-0.58c0.1-0.12,0.17-0.26,0.26-0.38c1.16-1.45,2.25-3.01,3.26-4.66
		c0.05-0.09,0.11-0.16,0.16-0.25c1.14-1.9,2.19-3.92,3.11-6.05c1.64-3.77,2.68-8.04,3.36-12.73c0.01-0.09,0.03-0.18,0.04-0.27
		c0.06-0.45,0.13-0.9,0.19-1.36c0,0,0.01,0,0,0c0.3-2.41,0.52-4.95,0.58-7.59c0.02-0.01,0-25.1,0-25.1
		c0-11.81,5.36-17.74,16.07-17.74c5.13,0,9.06,1.45,11.79,4.36c2.74,2.91,4.1,7.38,4.1,13.42v37.09h65.18
		c4.33,0,8.49-0.69,12.48-2.05c3.98-1.36,7.11-3.87,9.4-7.52c2.28,3.64,5.21,6.15,8.8,7.52c3.59,1.37,7.6,2.05,12.05,2.05h4.67
		h26.44h100.26h4.56h26.56h8.44h154.03v-38.97c0-15.64-3.69-27.67-11.06-36.09C552.85,77.49,541.29,73.28,525.54,73.28
		 M327.54,95.68c12.1,0,21.9,9.81,21.9,21.91c0,12.1-9.81,21.9-21.9,21.9c-12.1,0-21.9-9.81-21.9-21.9
		C305.64,105.48,315.44,95.68,327.54,95.68 M543.76,137.05h-88.52v-11.92c0-8.89,2.1-15.73,6.28-20.51
		c4.19-4.79,10.76-7.18,19.71-7.18h36.53c8.95,0,15.52,2.39,19.7,7.18c4.19,4.78,6.29,11.62,6.29,20.51V137.05z'
        />
        <g>
          <linearGradient id='SVGID_1_' gradientUnits='userSpaceOnUse' x1='28.9293' y1='47.1123' x2='2.5243' y2='1.3773'>
            <stop offset='0' style={{ stopColor: "#5C00B3" }} />
            <stop offset='0.9982' style={{ stopColor: "#26D2CB" }} />
          </linearGradient>
          <path
            className={classes.st1}
            d='M2.02,55.21V1.67h18.1c4.52,0,8.41,0.73,11.65,2.19c3.24,1.46,5.72,3.57,7.43,6.33
			c1.71,2.77,2.56,6.11,2.56,10.03c0,2.71-0.52,5.2-1.55,7.46c-1.03,2.26-2.49,4.21-4.37,5.84c-1.89,1.63-4.14,2.89-6.75,3.77
			c-2.61,0.88-5.53,1.32-8.75,1.32c-1.71,0-3.52-0.15-5.43-0.45v17.04H2.02z M14.91,12.6v14.7c1.76,0.25,3.37,0.38,4.83,0.38
			c2.81,0,4.99-0.64,6.52-1.92c1.53-1.28,2.3-3.15,2.3-5.62c0-2.41-0.74-4.27-2.22-5.58c-1.48-1.31-3.63-1.96-6.45-1.96H14.91z'
          />

          <linearGradient
            id='SVGID_00000117649146643401964170000002942589668384942259_'
            gradientUnits='userSpaceOnUse'
            x1='73.0802'
            y1='60.4969'
            x2='41.3403'
            y2='5.5218'
          >
            <stop offset='0' style={{ stopColor: "#5C00B3" }} />
            <stop offset='0.9982' style={{ stopColor: "#26D2CB" }} />
          </linearGradient>
          <path
            style={{ fill: "url(#SVGID_00000117649146643401964170000002942589668384942259_)" }}
            d='M48.01,55.21V1.67h13.12v41.92h21.11
			v11.61H48.01z'
          />

          <linearGradient
            id='SVGID_00000068654279697669993770000006972377310294543752_'
            gradientUnits='userSpaceOnUse'
            x1='129.0918'
            y1='51.0663'
            x2='97.4234'
            y2='-3.7849'
          >
            <stop offset='0' style={{ stopColor: "#5C00B3" }} />
            <stop offset='0.9982' style={{ stopColor: "#26D2CB" }} />
          </linearGradient>
          <path
            style={{ fill: "url(#SVGID_00000068654279697669993770000006972377310294543752_)" }}
            d='M111.65,56.71
			c-3.62,0-6.87-0.55-9.76-1.66c-2.89-1.1-5.38-2.69-7.46-4.75c-2.09-2.06-3.68-4.54-4.79-7.43c-1.11-2.89-1.66-6.12-1.66-9.69V1.67
			h13.12v30.92c0,2.46,0.44,4.6,1.32,6.41c0.88,1.81,2.11,3.2,3.7,4.18c1.58,0.98,3.48,1.47,5.69,1.47c2.21,0,4.11-0.49,5.69-1.47
			c1.58-0.98,2.81-2.38,3.69-4.18c0.88-1.81,1.32-3.95,1.32-6.41V1.67h13.12v31.52c0,3.57-0.57,6.8-1.7,9.69
			c-1.13,2.89-2.74,5.37-4.83,7.43c-2.09,2.06-4.61,3.65-7.58,4.75C118.56,56.16,115.27,56.71,111.65,56.71z'
          />

          <linearGradient
            id='SVGID_00000103254483834894822960000008917756503991350659_'
            gradientUnits='userSpaceOnUse'
            x1='174.9441'
            y1='52.3444'
            x2='147.7322'
            y2='5.2118'
          >
            <stop offset='0' style={{ stopColor: "#5C00B3" }} />
            <stop offset='0.9982' style={{ stopColor: "#26D2CB" }} />
          </linearGradient>
          <path
            style={{ fill: "url(#SVGID_00000103254483834894822960000008917756503991350659_)" }}
            d='M161.49,56.34
			c-3.37,0-6.46-0.59-9.27-1.77s-5.18-2.87-7.09-5.05c-1.91-2.19-3.22-4.81-3.92-7.88l12.14-2.41c0.65,1.96,1.68,3.44,3.09,4.45
			c1.41,1.01,3.12,1.51,5.13,1.51s3.67-0.49,4.98-1.47s1.96-2.25,1.96-3.81c0-1.41-0.62-2.56-1.85-3.47
			c-1.23-0.9-3.31-1.68-6.22-2.34l-2.04-0.45c-5.43-1.21-9.42-3.19-11.99-5.96c-2.56-2.76-3.85-6.08-3.85-9.95
			c0-2.51,0.49-4.81,1.47-6.9c0.98-2.09,2.34-3.9,4.07-5.43c1.73-1.53,3.78-2.73,6.15-3.58c2.36-0.85,4.9-1.28,7.62-1.28
			c3.22,0,6.13,0.58,8.75,1.73c2.61,1.16,4.8,2.8,6.56,4.94c1.76,2.14,2.97,4.69,3.62,7.65l-11.91,2.41
			c-0.65-1.86-1.58-3.25-2.79-4.18c-1.21-0.93-2.67-1.39-4.37-1.39c-1.76,0-3.22,0.45-4.37,1.36c-1.16,0.9-1.73,2.09-1.73,3.54
			c0,1.11,0.34,2,1.02,2.68c0.68,0.68,1.62,1.23,2.83,1.66c1.21,0.43,2.56,0.82,4.07,1.17l1.96,0.45c5.43,1.26,9.47,3.22,12.14,5.88
			c2.66,2.67,4,6.06,4,10.18c0,2.61-0.5,5-1.51,7.16c-1.01,2.16-2.41,4.03-4.22,5.62c-1.81,1.58-3.93,2.8-6.37,3.66
			C167.08,55.91,164.41,56.34,161.49,56.34z'
          />
        </g>
      </g>
    </svg>
  );
};
WasfatyLogo.defaultProps = {
  height: adminLayoutVariables.headerHeight - 8,
  width: adminLayoutVariables.drawerWidth - 8,
};
