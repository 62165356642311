import { getCurrentLocation } from "@health/common";
import { Address } from "@health/queries";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AnonymousAddressOption } from "../AddressPickerWithAnonymous";
import { AddressInput } from "../FormInputs/FormInputs.types";

export const useAddressFormHooks = (props: {
  address: Address | null;
  onCancelAddress?: () => void;
  onSubmitAddress?: (data: AddressInput, options: { isDefault: boolean; shouldSaveAddress: boolean }) => void;
}) => {
  const { address, onCancelAddress, onSubmitAddress } = props;
  const [isDefault, setIsDefault] = useState<boolean>(address?.isDefaultShippingAddress || false);
  const methods = useForm<AddressInput>();

  useEffect(() => {
    !address?.id && setLocationToCurrentCoordinates();
    if (address?.id) {
      methods.reset(address as any);
      methods.register("coordinates", {
        required: true,
        value: address?.coordinates?.lat
          ? {
              lat: address?.coordinates?.lat!,
              lng: address?.coordinates?.lng!,
            }
          : undefined,
      });
      if (address?.id === AnonymousAddressOption.id) {
        setLocationToCurrentCoordinates();
        methods.setValue("name", null);
      } else {
        methods.setValue("city", address?.city?.id);
      }
    } else {
      methods.setValue("city", address?.city?.id);
      methods.setValue("shouldSaveAddress", true);
    }
  }, [address]);

  async function setLocationToCurrentCoordinates() {
    try {
      handleLocationChange(await getCurrentLocation());
    } catch (e) {
      console.error(e);
    }
  }
  const handleIsDefaultAddressChange = (value: boolean) => setIsDefault(value);

  const handleLocationChange = (coordinates: AddressInput["coordinates"]) => {
    methods.setValue("coordinates", coordinates);
  };

  const handleFormSubmit = (formData: AddressInput & { shouldSaveAddress?: boolean | null }) => {
    const data = { ...formData };
    delete data.shouldSaveAddress;
    onSubmitAddress?.({ ...formData }, { isDefault, shouldSaveAddress: !!formData.shouldSaveAddress });
  };

  const handleCancel = () => {
    methods.reset();
    onCancelAddress?.();
  };

  return {
    isDefault,
    methods,
    handleIsDefaultAddressChange,
    handleFormSubmit,
    handleCancel,
    handleLocationChange,
  };
};
