import { NetworkStatus } from "@apollo/client";
import { useTranslation } from "@health/i18n";
import {
  CodeSystemCode,
  CodeSystemSummery,
  formatGraphQLError,
  useAssignCodeSystemEditorAndReviewerMutation,
  useGetCodeSystemDefinitionsSummeryQuery,
  useMedlistUsersQuery,
  User,
} from "@health/queries";
import { useAddToast } from "@health/ui";
import { useNavigate } from "react-router-dom";

export const useMedListHooks = () => {
  const navigate = useNavigate();
  const { succeeded } = useAddToast();

  const { t } = useTranslation();
  const { data, networkStatus: medListsNetWorkStatues } = useGetCodeSystemDefinitionsSummeryQuery({
    notifyOnNetworkStatusChange: true,
  });

  const { data: _users } = useMedlistUsersQuery({
    variables: {
      first: 10,
    },
  });
  const users = _users?.medlistUsers?.edges.map(item => item.node) as User[];

  const medItems = data?.getCodeSystemDefinitionsSummery as CodeSystemSummery[];
  const [assignCodeSystemEditorAndReviewer] = useAssignCodeSystemEditorAndReviewerMutation({
    onCompleted: data => {
      if (data?.assignCodeSystemEditorAndReviewer?.reviewerUserId) {
        succeeded(t("Medlist assigned successfully"));
      }
    },
    onError: ({ graphQLErrors }) => {
      formatGraphQLError(graphQLErrors);
    },
  });

  const onDetailsClick = (code: string, id = "0") => {
    navigate(`${id}/${code}`);
  };

  const handleUpdateAssign = (code: CodeSystemCode, editorUser?: string, reviewerUser?: string, onFinish?: () => void) => {
    editorUser &&
      reviewerUser &&
      assignCodeSystemEditorAndReviewer({
        variables: {
          code,
          editorUser,
          reviewerUser,
        },
      });
    onFinish && onFinish();
  };

  return {
    medItems,
    isLoading: NetworkStatus.loading === medListsNetWorkStatues,
    users,
    onDetailsClick,
    handleUpdateAssign,
  };
};
