/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePlanMutationVariables = Types.Exact<{
  planInput: Types.PlanInput;
}>;


export type CreatePlanMutation = { __typename?: 'Mutation', planCreate?: { __typename?: 'PlanCreate', subscriptionErrors: Array<{ __typename?: 'SubscriptionError', field?: string | null, message?: string | null, code: Types.SubscriptionErrorCode }>, plan?: { __typename?: 'Plan', created: any, description?: string | null, fixedCostAmount: number, fixedOrderCostAmount: number, fixedOrderPercentage: number, id: string, isActive: boolean, isEditable?: boolean | null, name: string, period: Types.PlanPeriod, validFrom: any, validTill?: any | null, fixedCost?: { __typename?: 'Money', amount: number } | null, fixedOrderCost?: { __typename?: 'Money', currency: string, amount: number } | null } | null } | null };


export const CreatePlanDocument = gql`
    mutation CreatePlan($planInput: PlanInput!) {
  planCreate(input: $planInput) {
    subscriptionErrors {
      field
      message
      code
    }
    plan {
      created
      description
      fixedCostAmount
      fixedOrderCostAmount
      fixedOrderPercentage
      id
      isActive
      isEditable
      name
      period
      validFrom
      validTill
      fixedCost {
        amount
      }
      fixedOrderCost {
        currency
        amount
      }
    }
  }
}
    `;
export type CreatePlanMutationFn = Apollo.MutationFunction<CreatePlanMutation, CreatePlanMutationVariables>;

/**
 * __useCreatePlanMutation__
 *
 * To run a mutation, you first call `useCreatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlanMutation, { data, loading, error }] = useCreatePlanMutation({
 *   variables: {
 *      planInput: // value for 'planInput'
 *   },
 * });
 */
export function useCreatePlanMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlanMutation, CreatePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlanMutation, CreatePlanMutationVariables>(CreatePlanDocument, options);
      }
export type CreatePlanMutationHookResult = ReturnType<typeof useCreatePlanMutation>;
export type CreatePlanMutationResult = Apollo.MutationResult<CreatePlanMutation>;
export type CreatePlanMutationOptions = Apollo.BaseMutationOptions<CreatePlanMutation, CreatePlanMutationVariables>;