/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { CityErrorsFragmentFragmentDoc } from '../../../../fragment/city/__generated__/cityErrorFragment';
import { CityFragmentFragmentDoc } from '../../../../fragment/city/__generated__/city';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CityCreateMutationVariables = Types.Exact<{
  input: Types.CityInput;
}>;


export type CityCreateMutation = { __typename?: 'Mutation', cityCreate?: { __typename?: 'CityCreate', cityErrors: Array<{ __typename?: 'BlockError', field?: string | null, message?: string | null, code: Types.BlockErrorCode }>, city?: { __typename?: 'City', id: string, deliveryPriceAmount: number, name: string, nameAr?: string | null, created: any, modified: any, deliveryPrice?: { __typename?: 'Money', amount: number, currency: string } | null } | null } | null };


export const CityCreateDocument = gql`
    mutation cityCreate($input: CityInput!) {
  cityCreate(input: $input) {
    cityErrors {
      ...CityErrorsFragment
    }
    city {
      ...CityFragment
    }
  }
}
    ${CityErrorsFragmentFragmentDoc}
${CityFragmentFragmentDoc}`;
export type CityCreateMutationFn = Apollo.MutationFunction<CityCreateMutation, CityCreateMutationVariables>;

/**
 * __useCityCreateMutation__
 *
 * To run a mutation, you first call `useCityCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCityCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cityCreateMutation, { data, loading, error }] = useCityCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCityCreateMutation(baseOptions?: Apollo.MutationHookOptions<CityCreateMutation, CityCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CityCreateMutation, CityCreateMutationVariables>(CityCreateDocument, options);
      }
export type CityCreateMutationHookResult = ReturnType<typeof useCityCreateMutation>;
export type CityCreateMutationResult = Apollo.MutationResult<CityCreateMutation>;
export type CityCreateMutationOptions = Apollo.BaseMutationOptions<CityCreateMutation, CityCreateMutationVariables>;