import { getMediaLink } from "@health/common";
import { useTranslation } from "@health/i18n";
import { MarketPlaceTrackingOrderFragment } from "@health/queries";
import { CustomDialog, CustomTable, ShowButton, TruncateTypography } from "@health/ui";
import React, { FC, useState } from "react";

type LinesComponentProps = {
  lines: MarketPlaceTrackingOrderFragment[];
};

export const OpenLinesDialogButton: FC<LinesComponentProps> = ({ lines }) => {
  const { t } = useTranslation("Admin");

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(!open);
  };

  return (
    <CustomDialog
      title={t("Products")}
      open={open}
      onCloseModal={handleClickOpen}
      maxWidth='lg'
      button={<ShowButton onClick={handleClickOpen} />}
    >
      <CustomTable
        data={lines}
        pageIndex={0}
        pageSize={lines?.length}
        tableContainerSx={{
          minHeight: "100px !important",
        }}
        columns={[
          {
            key: "image",
            header: t("Image"),
            sx: {
              width: 150,
              textAlign: "center",
              align: "center",
            },
            accessor: ({ variant }) =>
              !variant?.product?.images?.[0]?.url ? (
                " - "
              ) : (
                <img
                  src={getMediaLink(variant?.product?.images?.[0]?.url)}
                  alt='product'
                  {...{ height: "50px", maxWidth: "100px", margin: "0 auto", align: "center" }}
                />
              ),
          },
          {
            key: "productName",
            header: t("Product Name"),
            accessor: ({ productName, variantName }) => (
              <TruncateTypography maxWidth={400} text={productName + " - " + variantName}>
                {productName + " - " + variantName}
              </TruncateTypography>
            ),
          },
          {
            key: "quantity",
            header: t("Quantity"),
            sx: {
              width: 150,
              textAlign: "center",
            },
            accessor: ({ quantity }) => quantity,
          },
        ]}
      />
    </CustomDialog>
  );
};
