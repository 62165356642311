/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductImageDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type ProductImageDeleteMutation = { __typename?: 'Mutation', productImageDelete?: { __typename?: 'ProductImageDelete', image?: { __typename?: 'ProductImage', id: string, url: string } | null, productErrors: Array<{ __typename?: 'ProductError', field?: string | null, message?: string | null }> } | null };


export const ProductImageDeleteDocument = gql`
    mutation productImageDelete($id: ID!) {
  productImageDelete(id: $id) {
    image {
      id
      url
    }
    productErrors {
      field
      message
    }
  }
}
    `;
export type ProductImageDeleteMutationFn = Apollo.MutationFunction<ProductImageDeleteMutation, ProductImageDeleteMutationVariables>;

/**
 * __useProductImageDeleteMutation__
 *
 * To run a mutation, you first call `useProductImageDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductImageDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productImageDeleteMutation, { data, loading, error }] = useProductImageDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductImageDeleteMutation(baseOptions?: Apollo.MutationHookOptions<ProductImageDeleteMutation, ProductImageDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductImageDeleteMutation, ProductImageDeleteMutationVariables>(ProductImageDeleteDocument, options);
      }
export type ProductImageDeleteMutationHookResult = ReturnType<typeof useProductImageDeleteMutation>;
export type ProductImageDeleteMutationResult = Apollo.MutationResult<ProductImageDeleteMutation>;
export type ProductImageDeleteMutationOptions = Apollo.BaseMutationOptions<ProductImageDeleteMutation, ProductImageDeleteMutationVariables>;