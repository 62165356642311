/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../fragment/PageInfo/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminProductTypesQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.ProductTypeFilterInput>;
}>;


export type AdminProductTypesQuery = { __typename?: 'Query', productTypes?: { __typename?: 'ProductTypeCountableConnection', pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'ProductTypeCountableEdge', node: { __typename?: 'ProductType', id: string, name?: string | null } }> } | null };


export const AdminProductTypesDocument = gql`
    query AdminProductTypes($after: String, $first: Int, $last: Int, $filter: ProductTypeFilterInput) {
  productTypes(after: $after, first: $first, last: $last, filter: $filter) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        id
        name
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useAdminProductTypesQuery__
 *
 * To run a query within a React component, call `useAdminProductTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminProductTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminProductTypesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminProductTypesQuery(baseOptions?: Apollo.QueryHookOptions<AdminProductTypesQuery, AdminProductTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminProductTypesQuery, AdminProductTypesQueryVariables>(AdminProductTypesDocument, options);
      }
export function useAdminProductTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminProductTypesQuery, AdminProductTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminProductTypesQuery, AdminProductTypesQueryVariables>(AdminProductTypesDocument, options);
        }
export type AdminProductTypesQueryHookResult = ReturnType<typeof useAdminProductTypesQuery>;
export type AdminProductTypesLazyQueryHookResult = ReturnType<typeof useAdminProductTypesLazyQuery>;
export type AdminProductTypesQueryResult = Apollo.QueryResult<AdminProductTypesQuery, AdminProductTypesQueryVariables>;