/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductVariantReviewsQueryVariables = Types.Exact<{
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.ProductVariantReviewFilterInput>;
}>;


export type ProductVariantReviewsQuery = { __typename?: 'Query', productVariantReviews?: { __typename?: 'ProductVariantReviewCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'ProductVariantReviewCountableEdge', node: { __typename?: 'ProductVariantReview', content?: string | null, created: any, helpfulCount: number, id: string, modified: any, published: boolean, rank?: number | null, rating: number, productVariant: { __typename?: 'ProductVariant', id: string, name?: string | null, quantityAvailable: number, sku: string, rating?: number | null, product?: { __typename?: 'Product', id: string, name?: string | null } | null }, createdBy?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null } }> } | null };


export const ProductVariantReviewsDocument = gql`
    query ProductVariantReviews($last: Int, $first: Int, $after: String, $filter: ProductVariantReviewFilterInput) {
  productVariantReviews(
    last: $last
    first: $first
    after: $after
    filter: $filter
  ) {
    totalCount
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        content
        created
        helpfulCount
        id
        modified
        published
        productVariant {
          id
          name
          product {
            id
            name
          }
          quantityAvailable
          sku
          rating
        }
        rank
        rating
        createdBy {
          id
          firstName
          lastName
        }
      }
    }
  }
}
    `;

/**
 * __useProductVariantReviewsQuery__
 *
 * To run a query within a React component, call `useProductVariantReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductVariantReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductVariantReviewsQuery({
 *   variables: {
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProductVariantReviewsQuery(baseOptions?: Apollo.QueryHookOptions<ProductVariantReviewsQuery, ProductVariantReviewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductVariantReviewsQuery, ProductVariantReviewsQueryVariables>(ProductVariantReviewsDocument, options);
      }
export function useProductVariantReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductVariantReviewsQuery, ProductVariantReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductVariantReviewsQuery, ProductVariantReviewsQueryVariables>(ProductVariantReviewsDocument, options);
        }
export type ProductVariantReviewsQueryHookResult = ReturnType<typeof useProductVariantReviewsQuery>;
export type ProductVariantReviewsLazyQueryHookResult = ReturnType<typeof useProductVariantReviewsLazyQuery>;
export type ProductVariantReviewsQueryResult = Apollo.QueryResult<ProductVariantReviewsQuery, ProductVariantReviewsQueryVariables>;