import { useTranslation } from "@health/i18n";
import { Filter, FormActions, FormCard, Grid, PageWrapper } from "@health/ui";
import React, { FC } from "react";
import ContentLoader from "react-content-loader";
import { useNavigate } from "react-router-dom";
import { useMedListEditHooks } from "./useMedListEditHooks";

const MedListEdit: FC = () => {
  const { fields, handleSubmit, register, control, onSubmit, loadingGetCodeSystemConceptResponse, CodeTextName, isChanged } =
    useMedListEditHooks();
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate(-1);
  };
  const { t } = useTranslation("Admin");
  return (
    <PageWrapper
      actions={
        <FormActions hasEdit={true} onEditItem={handleSubmit(onSubmit)} hasCancel onNavigation={handleCancel} isChanged={isChanged} />
      }
    >
      <FormCard doYouHaveData={true} loading={false} title={t("Edit " + CodeTextName)}>
        {loadingGetCodeSystemConceptResponse ? (
          <Grid container padding={4} spacing={2}>
            {new Array(20).fill(0).map((el, key) => (
              <Grid key={key} item xs={12} sm={6} lg={4} xl={3}>
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                  <ContentLoader>
                    <rect x='20' y='20' rx='4' ry='4' width='750' height='30' />
                  </ContentLoader>
                </Grid>
              </Grid>
            ))}
          </Grid>
        ) : (
          <>{!!fields?.length && <Filter fields={fields as any} register={register} control={control} />}</>
        )}
      </FormCard>
    </PageWrapper>
  );
};

export default MedListEdit;
