import { QuillVDescriptionSchemaV1, RTESchema } from "../types/RichTextEditor";

export const schemaVersions = { quillV1: "quillV1" as const };
export const defaultRTESchema = schemaVersions.quillV1;

const textToJsonSchema = {
  quillV1: (text: string): QuillVDescriptionSchemaV1 => {
    const schema: QuillVDescriptionSchemaV1 = {
      version: "quillV1",
      text,
    };
    return schema;
  },
  default: (text: string): RTESchema => {
    return textToJsonSchema[defaultRTESchema](text);
  },
};

export const convertTextToRteJsonSchema = (text: string, version: keyof typeof schemaVersions = defaultRTESchema): RTESchema => {
  if (typeof textToJsonSchema[version] !== "function") {
    throw new Error(
      `[fn: convertTextToJsonSchema]: Undefined schema version ${version} supported versions are : ${Object.keys(schemaVersions)}`
    );
  } else return textToJsonSchema[version](text);
};

export const schemaJsonParsers = {
  quillV1: quillV1JsonParser,
  default: (json: string): RTESchema => {
    return schemaJsonParsers[defaultRTESchema](json);
  },
};

export const convertRteJsonSchemaToText = (jsonSchema: string, version: keyof typeof schemaVersions = defaultRTESchema): string => {
  if (typeof schemaJsonParsers[version] !== "function") {
    throw new Error(
      `[fn:convertJsonSchemaToText] Undefined schema version ${version} supported versions are : ${Object.keys(schemaVersions)}`
    );
  }

  if (!jsonSchema?.length) {
    return "";
  }

  return schemaJsonParsers[version](jsonSchema).text;
};

function quillV1JsonParser(json: string): QuillVDescriptionSchemaV1 {
  if (!json) {
    return {
      text: "",
      version: schemaVersions.quillV1,
    };
  }
  if (!json.includes(schemaVersions.quillV1)) {
    return {
      text: json,
      version: schemaVersions.quillV1,
    };
  }
  const schema: QuillVDescriptionSchemaV1 = JSON.parse(json?.replace(/'/g, '"'));
  return schema;
}
