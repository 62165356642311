/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type SiteSettingsErrorFragmentFragment = { __typename?: 'SiteSettingsError', field?: string | null, message?: string | null, code: Types.SiteSettingsErrorCode };

export const SiteSettingsErrorFragmentFragmentDoc = gql`
    fragment SiteSettingsErrorFragment on SiteSettingsError {
  field
  message
  code
}
    `;