/* eslint-disable */
/* prettier-ignore */
import * as Types from '@health/queries/dist/graphql/types';

import { gql } from '@apollo/client';
import { DecisionPlanFragmentDoc } from './DecisionPlan';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDecisionPlansQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetDecisionPlansQuery = { __typename?: 'Query', plans?: Array<{ __typename?: 'DecisionPlan', id?: string | null, code?: string | null, display?: string | null, actionDefinitionFields?: Array<{ __typename?: 'ActionDefinitionField', code?: string | null, display?: string | null, id?: string | null, type?: Types.ActionFieldType | null } | null> | null, fields?: Array<{ __typename?: 'Field', id?: string | null, code?: string | null, display?: string | null, operators?: Array<{ __typename?: 'FieldOperator', id?: string | null, operator?: Types.Operator | null } | null> | null, fieldType?: { __typename?: 'FieldType', id?: string | null, type?: Types.EntityType | null } | null } | null> | null } | null> | null };


export const GetDecisionPlansDocument = gql`
    query getDecisionPlans {
  plans: getPlans {
    ...DecisionPlan
    actionDefinitionFields {
      code
      display
      id
      type
    }
  }
}
    ${DecisionPlanFragmentDoc}`;

/**
 * __useGetDecisionPlansQuery__
 *
 * To run a query within a React component, call `useGetDecisionPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDecisionPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDecisionPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDecisionPlansQuery(baseOptions?: Apollo.QueryHookOptions<GetDecisionPlansQuery, GetDecisionPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDecisionPlansQuery, GetDecisionPlansQueryVariables>(GetDecisionPlansDocument, options);
      }
export function useGetDecisionPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDecisionPlansQuery, GetDecisionPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDecisionPlansQuery, GetDecisionPlansQueryVariables>(GetDecisionPlansDocument, options);
        }
export type GetDecisionPlansQueryHookResult = ReturnType<typeof useGetDecisionPlansQuery>;
export type GetDecisionPlansLazyQueryHookResult = ReturnType<typeof useGetDecisionPlansLazyQuery>;
export type GetDecisionPlansQueryResult = Apollo.QueryResult<GetDecisionPlansQuery, GetDecisionPlansQueryVariables>;