import { formGirdSpacing } from "@health/common";
import { Vendor, VendorWebhook } from "@health/queries";
import { Button, CustomDialog, Grid, ShowButton } from "@health/ui";
import React, { FC, Fragment, useState } from "react";
import { FormProvider } from "react-hook-form";
import Information from "./Information.component";
import { useVendorWebHookFormHooks } from "./VendorWebHookForm.hooks";
import { useVendorWebHookFormStyles } from "./VendorWebHookForm.style";

const VendorWebHookDialog: FC<{ vendor: Vendor }> = ({ vendor }) => {
  const { classes } = useVendorWebHookFormStyles();

  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => {
    setIsOpen(state => !state);
  };

  const { t, methods, webHook, loading, isSubmitting, handleSubmit, onSubmit } = useVendorWebHookFormHooks(vendor, !isOpen, handleToggle);

  return (
    <Fragment>
      <ShowButton onClick={handleToggle} />
      {isOpen && (
        <CustomDialog
          open={isOpen}
          onCloseModal={handleToggle}
          title={t("Provider Webhook")}
          footerComponent={
            <>
              <Button
                onClick={handleSubmit(onSubmit)}
                className={classes.buttons}
                variant={"contained"}
                color={"primary"}
                type='submit'
                form='custom-dialog-form'
                disabled={isSubmitting}
              >
                {t("Save")}
              </Button>
            </>
          }
        >
          <FormProvider {...methods}>
            <Grid container spacing={formGirdSpacing}>
              <Grid item width='100%'>
                {!loading && <Information webHook={webHook?.vendorWebHook as VendorWebhook} loading={Boolean(loading)} />}
              </Grid>
            </Grid>
          </FormProvider>
        </CustomDialog>
      )}
    </Fragment>
  );
};

export default VendorWebHookDialog;
