/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClientProfileSettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ClientProfileSettingsQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, nationalId?: string | null, mobile?: string | null, email: string, addresses?: Array<{ __typename?: 'Address', id: string, name?: string | null, city: { __typename?: 'City', id: string, name: string, nameAr?: string | null } } | null> | null } | null };


export const ClientProfileSettingsDocument = gql`
    query ClientProfileSettings {
  me {
    id
    firstName
    lastName
    nationalId
    addresses {
      id
      name
      city {
        id
        name
        nameAr
      }
    }
    mobile
    email
  }
}
    `;

/**
 * __useClientProfileSettingsQuery__
 *
 * To run a query within a React component, call `useClientProfileSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientProfileSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientProfileSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientProfileSettingsQuery(baseOptions?: Apollo.QueryHookOptions<ClientProfileSettingsQuery, ClientProfileSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientProfileSettingsQuery, ClientProfileSettingsQueryVariables>(ClientProfileSettingsDocument, options);
      }
export function useClientProfileSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientProfileSettingsQuery, ClientProfileSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientProfileSettingsQuery, ClientProfileSettingsQueryVariables>(ClientProfileSettingsDocument, options);
        }
export type ClientProfileSettingsQueryHookResult = ReturnType<typeof useClientProfileSettingsQuery>;
export type ClientProfileSettingsLazyQueryHookResult = ReturnType<typeof useClientProfileSettingsLazyQuery>;
export type ClientProfileSettingsQueryResult = Apollo.QueryResult<ClientProfileSettingsQuery, ClientProfileSettingsQueryVariables>;