/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VendorCategoryCreateMutationVariables = Types.Exact<{
  input: Types.CategoryInput;
  parent?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type VendorCategoryCreateMutation = { __typename?: 'Mutation', vendorCategoryCreate?: { __typename?: 'CategoryCreate', category?: { __typename?: 'Category', description?: string | null, name?: string | null } | null, vendorErrors: Array<{ __typename?: 'ProductError', field?: string | null, message?: string | null }> } | null };


export const VendorCategoryCreateDocument = gql`
    mutation vendorCategoryCreate($input: CategoryInput!, $parent: ID) {
  vendorCategoryCreate: categoryCreate(input: $input, parent: $parent) {
    category {
      description
      name
    }
    vendorErrors: productErrors {
      field
      message
    }
  }
}
    `;
export type VendorCategoryCreateMutationFn = Apollo.MutationFunction<VendorCategoryCreateMutation, VendorCategoryCreateMutationVariables>;

/**
 * __useVendorCategoryCreateMutation__
 *
 * To run a mutation, you first call `useVendorCategoryCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVendorCategoryCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vendorCategoryCreateMutation, { data, loading, error }] = useVendorCategoryCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      parent: // value for 'parent'
 *   },
 * });
 */
export function useVendorCategoryCreateMutation(baseOptions?: Apollo.MutationHookOptions<VendorCategoryCreateMutation, VendorCategoryCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VendorCategoryCreateMutation, VendorCategoryCreateMutationVariables>(VendorCategoryCreateDocument, options);
      }
export type VendorCategoryCreateMutationHookResult = ReturnType<typeof useVendorCategoryCreateMutation>;
export type VendorCategoryCreateMutationResult = Apollo.MutationResult<VendorCategoryCreateMutation>;
export type VendorCategoryCreateMutationOptions = Apollo.BaseMutationOptions<VendorCategoryCreateMutation, VendorCategoryCreateMutationVariables>;