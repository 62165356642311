import { i18n, useTranslation } from "@health/i18n";
import { Box, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { default as React, FC, Fragment, memo } from "react";
import { adminLayoutVariables } from "../../customization/layout";
import Breadcrumbs from "../Breadcrumb/Breadcrumbs.component";
import { LanguageButton } from "../LanguageButton";
import { NetworkStatus } from "../NetworkStatus";
import { useAdminHeaderStyles } from "./AdminHeader.styles";
import { AdminHeaderProps } from "./AdminHeader.types";
import { leftIcons, rightIcons } from "./routes";
import { openPharmacyBranch } from "./utils";

const AdminHeader: FC<AdminHeaderProps> = props => {
  const theme = useTheme();
  const currentDir = i18n.dir(i18n.language);
  const { t } = useTranslation();

  const { drawerWidth } = adminLayoutVariables;
  const {
    position = "fixed",
    leftItems = leftIcons,
    rightItems = rightIcons,
    title,
    breadCrumb,
    isOpen,
    onToggle,
    children,
    branchName,
  } = props;

  const { classes } = useAdminHeaderStyles({ drawerWidth, isOpen });

  const matches = useMediaQuery("(max-width:600px)");
  const handleToggleDrawer = () => {
    onToggle && onToggle(!isOpen);
  };
  const handleOpenBranchesDialog = () => {
    openPharmacyBranch(true);
  };
  return (
    <>
      <NetworkStatus isOpen={isOpen} />
      <MuiAppBar elevation={0} className={classes.appMenu} position={position}>
        <Toolbar
          sx={{
            height: "100%",
          }}
          disableGutters={false}
          variant='regular'
        >
          {!!leftItems?.length &&
            leftItems?.map(item => {
              const { id, icon, onClick: onPressItem } = item || {};

              const handleClick = () => {
                onPressItem ? onPressItem(id) : handleToggleDrawer();
              };

              return (
                <IconButton
                  key={id}
                  onClick={handleClick}
                  edge='start'
                  className={classes.leftIcon}
                  sx={{
                    transform: currentDir === "rtl" ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                >
                  {icon}
                </IconButton>
              );
            })}
          <Typography
            variant='h6'
            component='div'
            sx={{
              flexGrow: 1,
              display: "none",
              margin: "0 10px",
              [theme.breakpoints.up("sm")]: {
                display: "block",
              },
            }}
          >
            <Typography className={classes.title}>{title}</Typography>
            <Breadcrumbs data={breadCrumb} />
          </Typography>
          {children}
          {!matches && branchName && (
            <Box sx={{ marginInline: 2 }} display='flex' alignItems='center'>
              <Typography className={classes.title}>{t("Your branch is")}: </Typography>
              <Typography className={`${classes.title} ${classes.branchName}`} onClick={handleOpenBranchesDialog}>
                {branchName}
              </Typography>
            </Box>
          )}
          <LanguageButton />

          {!!rightItems?.length &&
            rightItems?.map(item => {
              const { id, icon, count, onClick: onPressItem } = item || {};

              const handleClickItem = () => {
                onPressItem && onPressItem(id);
              };

              return (
                <Fragment key={id}>
                  {count ? (
                    <IconButton className={classes.iconButton} key={id} onClick={handleClickItem} edge='start'>
                      <Badge badgeContent={count} color='error'>
                        {icon}
                      </Badge>
                    </IconButton>
                  ) : (
                    icon
                  )}
                </Fragment>
              );
            })}
        </Toolbar>
      </MuiAppBar>
    </>
  );
};

export default memo(AdminHeader);
