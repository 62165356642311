import { darkRed, makeStyles } from "@health/ui";

export const useForgetPasswordStyle = makeStyles()(() => ({
  container: {
    padding: "15px 26px",
    textAlign: "center",
  },
  containerMobile: {
    padding: "10px 12px 20px 12px",
  },
  button: {
    margin: 0,
    height: 50,
  },
  validation: {
    marginTop: 0,
  },
  text: {
    textAlign: "left",
    fontSize: 12,
  },
  textArabic: {
    textAlign: "right",
    fontSize: 12,
  },
  backToRegister: {
    margin: 10,
  },
  validationRed: {
    marginTop: 0,
    color: darkRed,
  },
}));
