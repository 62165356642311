import { makeVar } from "@apollo/client";
import { darkBlue, darkRed, greyVendor, orange, success } from "../../customization";

export const isOpenOrderCardDialog = makeVar<boolean>(false);

export const OrderColorEnum = {
  primary: darkBlue,
  warning: orange,
  success: success,
  info: greyVendor,
  error: darkRed,
};
