import { makeStyles } from "tss-react/mui";
import { lightGrey } from "../../customization";
import { renderSwitch } from "./utils";

export const useNotificationItemStyles = makeStyles<{ type }>()((_, { type }) => ({
  card: {
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${lightGrey}`,
    padding: 5,
    paddingInline: 0,
    color: renderSwitch(type).color,
  },
  icon: {
    width: 40,
    height: 40,
    padding: 8,
    borderRadius: "50%",
    boxShadow: "0px 3px 6px #00000029",
    background: renderSwitch(type).color,
  },
  titleNote: {
    fontWeight: 600,
  },
  item: {
    margin: 10,
  },
  link: {
    color: renderSwitch(type).color,
  },
}));
