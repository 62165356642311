import { combineErrors, getPatternMobileErrorMessage, getRequiredValidation, lodashGet } from "@health/common";
import { useTranslation } from "@health/i18n";
import { OptionalLabel, StandardTextFieldProps, TextField } from "@health/ui";
import React, { FC } from "react";
import { RegisterOptions, useFormContext, ValidationRule } from "react-hook-form";

export interface CustomTextFieldProps extends StandardTextFieldProps {
  name: string;
  isRequired?: boolean;
  defaultValue?: string | null;
  pattern?: ValidationRule<RegExp>;
  errorsInput?;
  label?;
  registerProps?: RegisterOptions;
}

export const CustomTextField: FC<CustomTextFieldProps> = ({
  name,
  label,
  defaultValue,
  pattern,
  isRequired = true,
  errorsInput,
  registerProps = {},
  ...props
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const formErrors = combineErrors(errors, errorsInput);

  const errorMsg = lodashGet(formErrors, name + ".message"); // name.includes(".") ? formErrors?.[newName[0]]?.[newName[1]]?.message : formErrors?.[name]?.message;
  const { t } = useTranslation("Admin");
  return (
    <TextField
      fullWidth
      label={isRequired ? label : <OptionalLabel label={String(label)} />}
      defaultValue={defaultValue ?? null}
      error={Boolean(errorMsg)}
      helperText={getPatternMobileErrorMessage(t, errorMsg)}
      {...register(name, { required: getRequiredValidation(t, isRequired), pattern: pattern, ...registerProps })}
      {...props}
    />
  );
};

CustomTextField.defaultProps = {
  isRequired: undefined,
  pattern: undefined,
  defaultValue: undefined,
  errorsInput: undefined,
  registerProps: {},
  label: undefined,
};
