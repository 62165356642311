/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EarlyRefillReasonsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type EarlyRefillReasonsQuery = { __typename?: 'Query', earlyRefillReasons?: Array<{ __typename?: 'EarlyRefillReason', id: string, reason: string, modified: any } | null> | null };


export const EarlyRefillReasonsDocument = gql`
    query earlyRefillReasons {
  earlyRefillReasons {
    id
    reason
    modified
  }
}
    `;

/**
 * __useEarlyRefillReasonsQuery__
 *
 * To run a query within a React component, call `useEarlyRefillReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEarlyRefillReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEarlyRefillReasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEarlyRefillReasonsQuery(baseOptions?: Apollo.QueryHookOptions<EarlyRefillReasonsQuery, EarlyRefillReasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EarlyRefillReasonsQuery, EarlyRefillReasonsQueryVariables>(EarlyRefillReasonsDocument, options);
      }
export function useEarlyRefillReasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EarlyRefillReasonsQuery, EarlyRefillReasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EarlyRefillReasonsQuery, EarlyRefillReasonsQueryVariables>(EarlyRefillReasonsDocument, options);
        }
export type EarlyRefillReasonsQueryHookResult = ReturnType<typeof useEarlyRefillReasonsQuery>;
export type EarlyRefillReasonsLazyQueryHookResult = ReturnType<typeof useEarlyRefillReasonsLazyQuery>;
export type EarlyRefillReasonsQueryResult = Apollo.QueryResult<EarlyRefillReasonsQuery, EarlyRefillReasonsQueryVariables>;