import { SvgIcon, SvgIconProps } from "@mui/material";
import React, { FC } from "react";

export const BlankOrderIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon fill='#000000' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 128 128' width='64px' height='64px' {...props}>
      <path d='M 32 21 C 31.2 21 30.400391 21.300391 29.900391 21.900391 L 9.9003906 41.900391 C 9.3003906 42.400391 9 43.2 9 44 L 9 84 C 9 85.7 10.3 87 12 87 C 13.7 87 15 85.7 15 84 L 15 47 L 113 47 L 113 84 C 113 85.7 114.3 87 116 87 C 117.7 87 119 85.7 119 84 L 119 44 C 119 43.2 118.69961 42.400391 118.09961 41.900391 L 98.099609 21.900391 C 97.599609 21.300391 96.8 21 96 21 L 32 21 z M 33.199219 27 L 61 27 L 61 41 L 19.199219 41 L 33.199219 27 z M 67 27 L 94.800781 27 L 108.80078 41 L 67 41 L 67 27 z M 110.10352 98.351562 C 108.72129 98.383008 107.46172 99.400781 107.19922 100.80078 C 106.89922 102.40078 107.99961 104.00078 109.59961 104.30078 L 116.5 105.59961 C 82.8 127.29961 38.300391 125.89961 5.9003906 101.59961 C 4.6003906 100.59961 2.6992188 100.89922 1.6992188 102.19922 C 0.69921875 103.49922 1.0007813 105.40039 2.3007812 106.40039 C 20.500781 120.10039 42.299609 126.90039 64.099609 126.90039 C 83.499609 126.90039 102.90078 121.49961 119.80078 110.59961 L 118.5 117.30078 C 118.2 118.90078 119.30039 120.50078 120.90039 120.80078 C 121.10039 120.80078 121.3 120.90039 121.5 120.90039 C 122.9 120.90039 124.20039 119.9 124.40039 118.5 L 127 104.59961 C 127.1 103.79961 127 103.00039 126.5 102.40039 C 126.1 101.70039 125.39961 101.29961 124.59961 101.09961 L 110.69922 98.400391 C 110.49922 98.362891 110.30098 98.34707 110.10352 98.351562 z' />
    </SvgIcon>
  );
};
