/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type MessageFragmentFragment = { __typename?: 'Message', id: string, content?: string | null, seenDate?: any | null, created: any, sender: { __typename?: 'User', id: string, email: string, avatar?: string | null }, recipient?: { __typename?: 'User', id: string, email: string, avatar?: string | null } | null, branch: { __typename?: 'Branch', id: string, name: string, nameAr?: string | null, vendor: { __typename?: 'Vendor', logo?: string | null } }, order?: { __typename?: 'Order', id: string, customerNote: string, status: Types.OrderStatus, total?: { __typename?: 'TaxedMoney', gross: { __typename?: 'Money', amount: number, currency: string } } | null } | null, attachments?: Array<{ __typename?: 'Attachment', id: string, file?: string | null, contentType: string, sortOrder?: number | null, alt: string } | null> | null };

export const MessageFragmentFragmentDoc = gql`
    fragment MessageFragment on Message {
  id
  content
  sender {
    id
    email
    avatar
  }
  recipient {
    id
    email
    avatar
  }
  seenDate
  branch {
    id
    name
    nameAr
    vendor {
      logo
    }
  }
  order {
    id
    customerNote
    total {
      gross {
        amount
        currency
      }
    }
    status
  }
  created
  attachments {
    id
    file
    contentType
    sortOrder
    alt
  }
}
    `;