/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { LanguageDisplayFragmentDoc } from '../../../../fragment/languageDisplay/__generated__/languageDisplay';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MedicalDeliveryRequestCreateMutationVariables = Types.Exact<{
  input: Types.MedicalDeliveryRequestInput;
}>;


export type MedicalDeliveryRequestCreateMutation = { __typename?: 'Mutation', medicalDeliveryRequestCreate?: { __typename?: 'MedicalDeliveryRequestCreate', medicalDeliveryRequest?: { __typename?: 'MedicalDeliveryRequest', id: string, created: any, number?: string | null, description?: string | null, carrierName?: string | null, temperature?: string | null, requiredDeliveryTime?: number | null, dropOffContactName?: string | null, dropOffPhoneNumber?: string | null, deliveryType: Types.MedicalDeliveryRequestDeliveryType, deliveryStatus: Types.MedicalDeliveryRequestDeliveryStatus, cost?: { __typename?: 'Money', amount: number, currency: string } | null, shippingPrice?: { __typename?: 'TaxedMoney', net: { __typename?: 'Money', amount: number, currency: string } } | null, weight?: { __typename?: 'Weight', unit: string, value: number } | null, dropOffAddress: { __typename?: 'OrderAddress', id: string, name?: string | null, area?: string | null, streetAddress1?: string | null, streetAddress2?: string | null, buildingNumber?: string | null, city?: { __typename?: 'City', id: string, name: string, nameAr?: string | null } | null, translations?: Array<{ __typename?: 'OrderAddressTranslation', name?: string | null, streetAddress1: string, streetAddress2: string, language: { __typename?: 'LanguageDisplay', code: Types.LanguageCodeEnum, language: string } } | null> | null, coordinates?: { __typename?: 'LocationType', lat?: number | null, lng?: number | null } | null }, branch?: { __typename?: 'Branch', id: string, name: string, vendor: { __typename?: 'Vendor', name: string, nameAr?: string | null } } | null } | null, deliveryErrors: Array<{ __typename?: 'DeliveryError', code: Types.DeliveryErrorCode, field?: string | null, message?: string | null }> } | null };


export const MedicalDeliveryRequestCreateDocument = gql`
    mutation medicalDeliveryRequestCreate($input: MedicalDeliveryRequestInput!) {
  medicalDeliveryRequestCreate(input: $input) {
    medicalDeliveryRequest {
      id
      created
      number
      description
      carrierName
      cost {
        amount
        currency
      }
      shippingPrice {
        net {
          amount
          currency
        }
      }
      weight {
        unit
        value
      }
      temperature
      requiredDeliveryTime
      dropOffContactName
      dropOffPhoneNumber
      dropOffAddress {
        id
        name
        area
        streetAddress1
        streetAddress2
        buildingNumber
        city {
          id
          name
          nameAr
        }
        translations {
          name
          streetAddress1
          streetAddress2
          language {
            ...languageDisplay
          }
        }
        coordinates {
          lat
          lng
        }
      }
      deliveryType
      deliveryStatus
      branch {
        id
        name
        vendor {
          name
          nameAr
        }
      }
    }
    deliveryErrors {
      code
      field
      message
    }
  }
}
    ${LanguageDisplayFragmentDoc}`;
export type MedicalDeliveryRequestCreateMutationFn = Apollo.MutationFunction<MedicalDeliveryRequestCreateMutation, MedicalDeliveryRequestCreateMutationVariables>;

/**
 * __useMedicalDeliveryRequestCreateMutation__
 *
 * To run a mutation, you first call `useMedicalDeliveryRequestCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMedicalDeliveryRequestCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [medicalDeliveryRequestCreateMutation, { data, loading, error }] = useMedicalDeliveryRequestCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMedicalDeliveryRequestCreateMutation(baseOptions?: Apollo.MutationHookOptions<MedicalDeliveryRequestCreateMutation, MedicalDeliveryRequestCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MedicalDeliveryRequestCreateMutation, MedicalDeliveryRequestCreateMutationVariables>(MedicalDeliveryRequestCreateDocument, options);
      }
export type MedicalDeliveryRequestCreateMutationHookResult = ReturnType<typeof useMedicalDeliveryRequestCreateMutation>;
export type MedicalDeliveryRequestCreateMutationResult = Apollo.MutationResult<MedicalDeliveryRequestCreateMutation>;
export type MedicalDeliveryRequestCreateMutationOptions = Apollo.BaseMutationOptions<MedicalDeliveryRequestCreateMutation, MedicalDeliveryRequestCreateMutationVariables>;