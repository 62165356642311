import { i18n } from "@health/i18n";
import { makeStyles } from "tss-react/mui";
import { lightGrey } from "../../customization";

export const useOrdersCardStyles = makeStyles<{ color: string }>()((_, { color }) => ({
  card: {
    position: "relative",
    borderRadius: "5px 10px 10px 5px",
    boxShadow: "0px 0px 10px #0000001A",
  },
  text: {
    color,
    fontSize: "14px",
    fontFamily: "Airbnb Cereal App Medium, Arab Kufi Medium",
  },
  icon: {
    right: 0,
    left: "initial",
    zIndex: 1,
    position: "absolute",
    borderRadius: "50%",
    border: `1px solid ${lightGrey}`,
    display: "flex",
    justifyContent: "center",
    width: 30,
    height: 30,
    margin: 5,
    transition: "all 1s",
    "&:hover": {
      "& svg": {
        width: 10,
        height: 26,
      },
    },
  },
  iconRtl: {
    left: 0,
    right: "initial",
  },
  paper: {
    borderRadius: "15px 10px 10px 15px",
    padding: 0,
  },
  border: {
    borderRight: i18n.language == "ar" ? `4px solid ${color}` : "none",
    borderLeft: i18n.language == "en" ? `4px solid ${color}` : "none",
  },
}));
