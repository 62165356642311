import { makeStyles } from "@health/ui";
export const useMedicalCardStyle = makeStyles()({
  dateStyle: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "140px",
  },
  imgHeight: {
    height: 70,
  },
});
