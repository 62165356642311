/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UploadAttachmentMutationVariables = Types.Exact<{
  alt?: Types.InputMaybe<Types.Scalars['String']>;
  file: Types.Scalars['String'];
  contentType: Types.Scalars['String'];
}>;


export type UploadAttachmentMutation = { __typename?: 'Mutation', uploadAttachment?: { __typename: 'UploadAttachment', chatErrors: Array<{ __typename: 'ChatError', field?: string | null, message?: string | null, code: Types.ChatErrorCode }>, attachment?: { __typename: 'Attachment', id: string, file?: string | null, contentType: string, sortOrder?: number | null } | null } | null };


export const UploadAttachmentDocument = gql`
    mutation UploadAttachment($alt: String, $file: String!, $contentType: String!) {
  uploadAttachment(input: {alt: $alt, file: $file, contentType: $contentType}) {
    chatErrors {
      field
      message
      code
      __typename
    }
    attachment {
      id
      file
      contentType
      sortOrder
      __typename
    }
    __typename
  }
}
    `;
export type UploadAttachmentMutationFn = Apollo.MutationFunction<UploadAttachmentMutation, UploadAttachmentMutationVariables>;

/**
 * __useUploadAttachmentMutation__
 *
 * To run a mutation, you first call `useUploadAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAttachmentMutation, { data, loading, error }] = useUploadAttachmentMutation({
 *   variables: {
 *      alt: // value for 'alt'
 *      file: // value for 'file'
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useUploadAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<UploadAttachmentMutation, UploadAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadAttachmentMutation, UploadAttachmentMutationVariables>(UploadAttachmentDocument, options);
      }
export type UploadAttachmentMutationHookResult = ReturnType<typeof useUploadAttachmentMutation>;
export type UploadAttachmentMutationResult = Apollo.MutationResult<UploadAttachmentMutation>;
export type UploadAttachmentMutationOptions = Apollo.BaseMutationOptions<UploadAttachmentMutation, UploadAttachmentMutationVariables>;