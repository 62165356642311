import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles<Theme>(() => ({
  dayText: {
    minWidth: 80,
  },
  day: {
    display: "flex",
    alignItems: "center",
    textTransform: "capitalize",
  },
  cardTitle: {
    fontSize: 18,
    fontWeight: 600,
  },
  timeDivider: {},
  addIcon: {
    height: 21,
    width: 21,
    opacity: 1,
    cursor: "pointer",
  },
  deleteIcon: {
    height: 21,
    width: 21,
    opacity: 1,
    cursor: "pointer",
  },
  header: {
    background: "#D3DDEA",
    padding: "12px 19px",
    height: 45,
  },
}));
