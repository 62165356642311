import { useTranslation } from "@health/i18n";
import { Box, Breakpoint, Button, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import React, { FC, ReactNode } from "react";
import { makeStyles } from "tss-react/mui";
import { ErrorIcon, ModalCloseIcon } from "../../icons";

const useDialogStyles = makeStyles()({
  header: {
    display: "flex",
    flex: 1,
    height: "50px",
    padding: "15px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  actions: {
    justifyContent: "flex-start",
    padding: "15px",
    boxShadow: " 0px 0px 6px #0000001A",
    borderRadius: "0px 0px 15px 15px",
  },
});

export interface WarningConfirmationDialogProps {
  isOpen: boolean;
  confirmButtonColor?: "inherit" | "error" | "primary" | "secondary" | "success" | "info" | "warning";
  cancelButtonColor?: "inherit" | "error" | "primary" | "secondary" | "success" | "info" | "warning";
  bodyText?: string;
  textColor?: string;
  titleText?: string;
  cancelText?: string;
  confirmText?: string;
  Icon?: ReactNode;
  onConfirm?: () => void;
  onClose: () => void;
  subBodyText?: string;
  maxWidth?: false | Breakpoint;
}

export const WarningConfirmationDialog: FC<WarningConfirmationDialogProps> = props => {
  const { t } = useTranslation();
  const { isOpen, onClose: handleClose, onConfirm, maxWidth, confirmButtonColor, cancelButtonColor, textColor } = props;
  const {
    bodyText = t("Are you sure you want to perform this action"),
    titleText = t("Confirmation Required"),
    cancelText = t("cancel"),
    subBodyText = undefined,
    confirmText = t("confirm"),
  } = props;
  const { classes } = useDialogStyles();
  const handleDeleteConfirmation = e => {
    e.preventDefault();
    onConfirm?.();
    handleClose();
  };
  const descriptionElementRef = React.useRef<HTMLElement>(null);

  return (
    <Dialog
      maxWidth={maxWidth ? maxWidth : "xs"}
      fullWidth
      open={isOpen}
      PaperProps={{
        style: { borderRadius: "15px" },
      }}
      onClose={handleClose}
      scroll={"paper"}
      aria-labelledby='warning-confirmation-dialog'
      aria-describedby='warning-confirmation-dialog'
    >
      <DialogTitle className={classes.header} id='warning-confirmation-dialog' color='error'>
        <Typography color={textColor} fontSize={16} fontFamily='Airbnb Cereal App Medium,Arab Kufi Regular'>
          {titleText}
        </Typography>
        <IconButton onClick={handleClose} sx={{ height: "30px", width: "30px" }}>
          <ModalCloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='scroll-dialog-description' ref={descriptionElementRef} tabIndex={-1}>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "center" }}>
            {props.Icon || <ErrorIcon sx={{ fontSize: 50 }} />}
            <Typography fontSize={20} marginTop={3} color={textColor} textAlign='center'>
              {bodyText}
            </Typography>
            {subBodyText && (
              <Typography fontSize={20} color={textColor} textAlign='center'>
                {subBodyText}
              </Typography>
            )}
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button color={confirmButtonColor} onClick={handleDeleteConfirmation} data-testid='warningConfirmationDialogConfirmButton'>
          {confirmText}
        </Button>
        <Button color={cancelButtonColor} onClick={handleClose} data-testid='warningConfirmationDialogCancelButton'>
          {cancelText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

WarningConfirmationDialog.defaultProps = {
  bodyText: undefined, // the default is inside to be translated
  titleText: undefined, // the default is inside to be translated
  cancelText: undefined, // the default is inside to be translated
  confirmText: undefined, // the default is inside to be translated
  Icon: <ErrorIcon sx={{ fontSize: 40 }} />,
  subBodyText: undefined,
  confirmButtonColor: "error",
  cancelButtonColor: "inherit",
  textColor: "#973149",
  maxWidth: "xs",
  onConfirm: undefined,
};
