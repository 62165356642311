import { TFunction } from "@health/i18n";
import moment, { default as Moment } from "moment";
import momentTz from "moment-timezone";
import "moment/locale/ar";
import "moment/locale/en-il";

export const formatDate = (date, locale = "en") => (date ? Moment(date).locale(locale).format("DD MMM YYYY") : "");

export const format = (date: string, format?: string, locale = "en") =>
  date
    ? moment(date)
        .locale(locale)
        .format(format || "DD MMM YYYY")
    : "";

export const formatTime = (time: string): string => {
  return moment(time, "HH:mm:ss").format("hh:mmA");
};
export const formatDateTime = (time: string, t: TFunction<"translation", undefined>): string => {
  return momentTz.tz(momentTz(time), momentTz.tz.guess()).format("DD/MM/YYYY, hh:mm A").replace("AM", t("AM")).replace("PM", t("PM"));
};

export const getDayString = (shortDay: string): string => {
  switch (shortDay) {
    case "SAT":
      return "Saturday";
    case "SUN":
      return "Sunday";
    case "MON":
      return "Monday";
    case "TUE":
      return "Tuesday";
    case "WED":
      return "Wednesday";
    case "THU":
      return "Thursday";
    default:
      return "Friday";
  }
};
