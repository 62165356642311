import { useTranslation } from "@health/i18n";
import { ExpandMore } from "@mui/icons-material";
import { Autocomplete, Checkbox, FormControlLabel, TextField, TextFieldProps, Typography } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { autoCompleteI18nFiltrationOptions } from "../Autocomplete/utils";
import { AutocompleteController } from "../AutocompleteController";
import { AutocompleteControllerComponentProps } from "../AutocompleteController/types";
import { CustomDatePicker } from "../Form";
import { FieldsTypes, IFields } from "./Filter.types";
export const lazyAutocompleteTextPostfix = "lazyAutocompleteText";
const FilterItemInput = ({ register, control, props }: IFields) => {
  const { t } = useTranslation();
  switch (props.type) {
    case "autocomplete":
    case FieldsTypes.autocomplete:
      return (
        <Autocomplete
          {...props.fieldProps}
          popupIcon={<ExpandMore />}
          // eslint-disable-next-line react/jsx-handler-names
          renderInput={params => (
            <TextField
              hiddenLabel
              {...params}
              {...(props.fieldProps as any as TextFieldProps)}
              {...register(props.fieldProps?.name)}
              onChange={props.onChange as any}
            />
          )}
        />
      );
    case "lazyAutocomplete":
    case FieldsTypes.lazyAutocomplete:
      return (
        <AutocompleteController
          ControllerProps={{
            name: props?.name,
            control: control,
            defaultValue: props?.fieldProps?.defaultValue,
            rules: { required: false },
          }}
          loading={props?.fieldProps?.loading}
          TextFieldProps={{
            placeholder: props?.fieldProps?.placeholder,
            ...register(props?.name + lazyAutocompleteTextPostfix),
          }}
          filterOptions={autoCompleteI18nFiltrationOptions(props?.fieldProps?.getOptionLabel)}
          onChange={props.onChange as AutocompleteControllerComponentProps["onChange"]}
          isOptionEqualToValue={props.fieldProps?.isOptionEqualToValue}
          fetchMoreData={props.fieldProps?.onFetchMore}
          hasMore={props?.fieldProps?.hasMore}
          getOptionLabel={props?.fieldProps?.getOptionLabel}
          placeholder={props?.fieldProps.placeholder}
          options={props?.fieldProps?.options || []}
          multiple={props?.fieldProps?.multiple}
          onSearchInput={props?.fieldProps?.handleInputChange}
        />
      );
    case "input":
    case FieldsTypes.input:
      return (
        <TextField
          {...props.fieldProps}
          inputProps={
            props?.isFloat && {
              step: "0.01",
            }
          }
          fullWidth
          {...register(props.fieldProps?.name)}
        />
      );
    case "date":
    case FieldsTypes.date:
      return <CustomDatePicker {...props.fieldProps} control={control} name={props.name} register={register(props.fieldProps?.name)} />;
    case "checkbox":
    case FieldsTypes.checkbox:
      return (
        <FormControlLabel
          {...props.fieldProps}
          disabled={props.fieldProps?.isDisabled}
          control={
            <Controller
              {...props.fieldProps}
              name={props.fieldProps?.name}
              control={control}
              // eslint-disable-next-line react/jsx-handler-names
              render={({ field: { value, ...field } }) => <Checkbox {...field} checked={!!value} />}
            />
          }
        />
      );
    case "custom":
    case FieldsTypes.custom:
      return props.component;
    default:
      return <Typography> {t("Component type not defined")}</Typography>;
  }
};

export default FilterItemInput;
