import { ApolloLink, FetchResult, Observable, Operation } from "@apollo/client";
import { print } from "graphql";
import { fetchEventSource } from "./fetch";

class FatalError extends Error {}

interface SubscriptionClientOptions {
  url: string | (() => string);
  method?: "POST" | "GET";
  headers: Record<string, string | undefined> | (() => Record<string, string | undefined>);
}
export class MicrosoftSSE extends ApolloLink {
  private options: SubscriptionClientOptions;
  constructor(options: SubscriptionClientOptions) {
    super();
    this.options = options;
  }

  public request(operation: Operation): Observable<FetchResult> | null {
    return new Observable(sink => {
      const url = typeof this.options.url === "function" ? this.options.url() : this.options.url;
      const headers = typeof this.options.headers === "function" ? this.options.headers() : this.options.headers;
      fetchEventSource(url, {
        ...this.options,
        headers: {
          "Content-Type": "application/json",
          ...headers,
        },
        body: JSON.stringify({ ...operation, query: print(operation.query) }),
        onmessage: ev => {
          if (ev.event === "FatalError") {
            console.error("subscription errorss", ev);
            throw new FatalError(ev.data);
          }
          if (ev.data) {
            if (!!window["WasfatyPlusEnableSSELogging"] || process.env.NODE_ENV === "development") {
              console.info("SSe Event Received ", { query: print(operation.query), data: JSON.parse(ev?.data) });
            }
            sink.next.bind(sink)(JSON.parse(ev?.data));
          }
        },
        onclose() {
          console.log("Connection closed by the server " + new Date().toLocaleString());
        },
        onerror(err) {
          if (err instanceof FatalError) {
            console.error("subscription error", err);
            sink.error.bind(sink)(err.message);
            throw err; // rethrow to stop the operation
          } else {
            console.error("subscription error", err);
            // do nothing to automatically retry. You can also
            // return a specific retry interval here.
          }
        },
      });
    });
  }
}
