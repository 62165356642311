/* eslint-disable max-statements */
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNetworkStatus } from "use-network-status";
import { UserContext } from "../authentication";
import { fetchEventSource } from "../sse/fetch";
import { NotificationsServiceContext } from "./NotificationsServiceContext";
import { NotificationEvent, OrgSubscribeFn, TOPIC_LISTENERS } from "./types";

class FatalError extends Error {}
/// todo: implement sentry error to catch the various exceptions
function uuidv4() {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
}

function unifyTopic(t: string): string {
  return t;
}

export function safeJsonParse(st: string): any {
  try {
    return JSON.parse(st);
  } catch (e) {
    console.warn("JSON parse failed  ", e, st);

    return st;
  }
}

export const NotificationsServiceProvider: FC<{ eventSourceUrl: string }> = ({ children, eventSourceUrl }) => {
  const { token } = useContext(UserContext);
  const windowID = useRef(uuidv4());
  const [retryCount, setRetryCount] = useState(0);
  const [connectionStatus, setConnectionStatus] = useState<"loading" | "connected" | "disconnected">("disconnected");
  const crossTabEventsChannel = useRef<BroadcastChannel | null>(
    typeof window === "undefined" ? null : new BroadcastChannel("crossTabEventsChannel")
  ).current;

  const topicsSubscribers = useRef<TOPIC_LISTENERS<NotificationEvent>>({} as TOPIC_LISTENERS<NotificationEvent>);
  const isOnline = useNetworkStatus();
  const onMessage = useCallback(
    ev => {
      if (!!window["EnableSSELogging"] || process.env.NODE_ENV === "development") {
        console.info("SSe Event Received ", ev?.data, { data: safeJsonParse(ev?.data) });
      }
      if (ev.event === "FatalError") {
        console.error("subscription errorss", ev);
        throw new FatalError(ev.data);
      }
      if (!ev.data) {
        return;
      }
      const data = safeJsonParse(ev.data);
      if (data.type === "ping-pong") {
        window["EnableSSELogging"] && console.log("pong received " + new Date().toLocaleString());
        return;
      }
      const topic = unifyTopic(data.type);
      (topicsSubscribers.current?.[topic] || [])?.map(listener => listener?.(data));
    },
    [topicsSubscribers.current]
  );

  useEffect(() => {
    if (!crossTabEventsChannel) return;
    crossTabEventsChannel.onmessage = function (ev: MessageEvent<NotificationEvent>) {
      const eventString = ev.data as unknown as string;
      if (!eventString.includes("SSE_EVENT_RECEIVED") || eventString.includes("ping-pong")) {
        return;
      }
      const data = safeJsonParse(eventString);
      onMessage(data.data);
    };
  }, [crossTabEventsChannel]);

  useEffect(() => {
    startListening(eventSourceUrl, token);
  }, [eventSourceUrl, token]);

  function startListening(_eventSourceUrl: string, _token: string) {
    if (!eventSourceUrl || !token || !isOnline || connectionStatus === "loading" || connectionStatus == "connected") return;
    if (retryCount >= 20) {
      console.log("NotificationsServiceProvider: retry count exceeded 20 times");
      return;
    }
    setConnectionStatus("loading");
    const sseConnectionRef = new AbortController();
    window["sseControllerRef"] = sseConnectionRef;
    fetchEventSource(_eventSourceUrl, {
      signal: sseConnectionRef?.signal,
      headers: {
        Authorization: `Bearer ${_token}`,
      },
      openWhenHidden: true,
      method: "GET",
      onmessage: content => {
        onMessage(content);
        crossTabEventsChannel?.postMessage(
          JSON.stringify({
            type: "SSE_EVENT_RECEIVED",
            data: content,
            src: windowID.current,
          })
        );
      },
      onerror: onError,
      onopen: onOpen,
      onclose() {
        setConnectionStatus("disconnected");
        setRetryCount(retryCount => retryCount + 1);
        window["EnableSSELogging"] && console.log("Connection closed by the server " + new Date().toLocaleString());
      },
    });
  }

  function onOpen(res) {
    if (res.ok && res.status === 200) {
      window["EnableSSELogging"] && console.log(`Connection made for ${res} `);
      setConnectionStatus("connected");
      setRetryCount(0);
      return Promise.resolve(res);
    } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
      window["EnableSSELogging"] && console.log(`Client side error ${res} `);
      setConnectionStatus("disconnected");
      setRetryCount(retryCount => retryCount + 1);
      return Promise.reject(res);
    } else {
      setRetryCount(retryCount => retryCount + 1);
      return Promise.resolve(res);
    }
  }

  function onError(err) {
    setConnectionStatus("disconnected");
    setRetryCount(retryCount => retryCount + 1);
    console.log("NotificationsService: error occurred ", err);
    if (err instanceof FatalError) {
      // do somethings!
    }
    // do nothing to automatically retry. You can also
    // return a specific retry interval here.
  }

  const subscribe: OrgSubscribeFn = (topic, listener) => {
    listener["ListenerID"] = Date.now();
    const unifiedTopic = unifyTopic(topic);
    const ts = topicsSubscribers.current;
    topicsSubscribers.current = {
      ...ts,
      [unifiedTopic]: [listener, ...(ts[unifiedTopic] || [])],
    } as TOPIC_LISTENERS<NotificationEvent>;

    return () => unSubscribe(listener["ListenerID"]);
  };

  function unSubscribe(listenerId: string) {
    const ts = topicsSubscribers.current;
    topicsSubscribers.current = Object.entries(ts).reduce<TOPIC_LISTENERS<NotificationEvent>>((_ts, [topic, listeners = []]) => {
      const unifiedTopic = unifyTopic(topic);
      return {
        ..._ts,
        [unifiedTopic]: listeners.filter(l => l["ListenerID"] !== listenerId),
      };
    }, {} as TOPIC_LISTENERS<NotificationEvent>);
  }
  return <NotificationsServiceContext.Provider value={{ subscribe, unSubscribe }}>{children}</NotificationsServiceContext.Provider>;
};
