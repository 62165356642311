import { ChangePasswordPage, ForgetPasswordPage, LoginPage, RegisterPage, VerificationPage } from "pages/auth";
import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { LoggedInRoutes } from "./LoggedInRoutes";
import { PageNotFound } from "./PageNotFound";
import { RequireAuth } from "./RequireAuth";

/*
  TODO: check the RouteObject
  https://stackblitz.com/github/remix-run/react-router/tree/main/examples/route-objects?file=src/App.tsx
*/
export const RootRouter: FC = () => {
  return (
    <Routes>
      <Route
        path='/*'
        element={
          <RequireAuth>
            <LoggedInRoutes />
          </RequireAuth>
        }
      />
      <Route path='login' element={<LoginPage />} />
      <Route path='forget-password' element={<ForgetPasswordPage />} />
      <Route path='verify' element={<VerificationPage />} />
      <Route path='change-password' element={<ChangePasswordPage />} />
      <Route path='sign-up' element={<RegisterPage />} />
      <Route path='404' element={<PageNotFound />} />
      <Route path='soon' element={<PageUnderConstruction />} />
      <Route path='app' element={<LoggedInRoutes />} />
    </Routes>
  );
};

const PageUnderConstruction = () => (
  <div>
    <h2>PAGE UNDER CONSTRUCTION!</h2>
  </div>
);
