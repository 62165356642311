import { useTranslation } from "@health/i18n";
import React, { FC } from "react";
import { EntryContainer } from "../../EntryContainer.component";
import { EntryLayout } from "../../EntryLayout.component";
import { VerificationForm } from "../../Form/VerificationForm";
import { VerificationPageProps } from "./types";

export const VerificationPage: FC<VerificationPageProps> = ({ onRedirectLogin: handleLogin }) => {
  const { t } = useTranslation();

  return (
    <EntryLayout>
      <EntryContainer title={t("Verify Your Account")} buttonLabel='Log in' onClick={() => handleLogin("login")}>
        <VerificationForm isPage onChangeStep={() => handleLogin("login")} />
      </EntryContainer>
    </EntryLayout>
  );
};
export default VerificationPage;
