/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { MessageFragmentFragmentDoc } from '../../../../fragment/chat/__generated__/message';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CustomerSendMessageMutationVariables = Types.Exact<{
  content?: Types.InputMaybe<Types.Scalars['String']>;
  branch?: Types.InputMaybe<Types.Scalars['ID']>;
  attachments?: Types.InputMaybe<Array<Types.InputMaybe<Types.MessageAttachmentInput>> | Types.InputMaybe<Types.MessageAttachmentInput>>;
}>;


export type CustomerSendMessageMutation = { __typename?: 'Mutation', customerSendMessage?: { __typename?: 'CustomerSendMessage', message?: { __typename?: 'Message', id: string, content?: string | null, seenDate?: any | null, created: any, sender: { __typename?: 'User', id: string, email: string, avatar?: string | null }, recipient?: { __typename?: 'User', id: string, email: string, avatar?: string | null } | null, branch: { __typename?: 'Branch', id: string, name: string, nameAr?: string | null, vendor: { __typename?: 'Vendor', logo?: string | null } }, order?: { __typename?: 'Order', id: string, customerNote: string, status: Types.OrderStatus, total?: { __typename?: 'TaxedMoney', gross: { __typename?: 'Money', amount: number, currency: string } } | null } | null, attachments?: Array<{ __typename?: 'Attachment', id: string, file?: string | null, contentType: string, sortOrder?: number | null, alt: string } | null> | null } | null, chatErrors: Array<{ __typename?: 'ChatError', field?: string | null, message?: string | null, code: Types.ChatErrorCode }> } | null };


export const CustomerSendMessageDocument = gql`
    mutation customerSendMessage($content: String, $branch: ID, $attachments: [MessageAttachmentInput]) {
  customerSendMessage(
    input: {content: $content, branch: $branch, attachments: $attachments}
  ) {
    message {
      ...MessageFragment
    }
    chatErrors {
      field
      message
      code
    }
  }
}
    ${MessageFragmentFragmentDoc}`;
export type CustomerSendMessageMutationFn = Apollo.MutationFunction<CustomerSendMessageMutation, CustomerSendMessageMutationVariables>;

/**
 * __useCustomerSendMessageMutation__
 *
 * To run a mutation, you first call `useCustomerSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerSendMessageMutation, { data, loading, error }] = useCustomerSendMessageMutation({
 *   variables: {
 *      content: // value for 'content'
 *      branch: // value for 'branch'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useCustomerSendMessageMutation(baseOptions?: Apollo.MutationHookOptions<CustomerSendMessageMutation, CustomerSendMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CustomerSendMessageMutation, CustomerSendMessageMutationVariables>(CustomerSendMessageDocument, options);
      }
export type CustomerSendMessageMutationHookResult = ReturnType<typeof useCustomerSendMessageMutation>;
export type CustomerSendMessageMutationResult = Apollo.MutationResult<CustomerSendMessageMutation>;
export type CustomerSendMessageMutationOptions = Apollo.BaseMutationOptions<CustomerSendMessageMutation, CustomerSendMessageMutationVariables>;