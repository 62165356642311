/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type WishlistFragmentFragment = { __typename?: 'WishlistItem', id: string, createdAt: any, vendorProductVariant: { __typename?: 'VendorProductVariant', id: string, priceAmount?: number | null, price?: { __typename?: 'Money', currency: string, amount: number } | null, vendor?: { __typename?: 'Vendor', id: string, branches?: { __typename?: 'BranchCountableConnection', edges: Array<{ __typename?: 'BranchCountableEdge', node: { __typename?: 'Branch', id: string } }> } | null } | null, productVariant: { __typename?: 'ProductVariant', id: string, product?: { __typename?: 'Product', id: string, name?: string | null, description?: string | null, images?: Array<{ __typename?: 'ProductImage', id: string, url: string } | null> | null, category?: { __typename?: 'Category', id: string, name?: string | null } | null } | null } } };

export const WishlistFragmentFragmentDoc = gql`
    fragment WishlistFragment on WishlistItem {
  id
  createdAt
  vendorProductVariant {
    id
    priceAmount
    price {
      currency
      amount
    }
    vendor {
      id
      branches(first: 1) {
        edges {
          node {
            id
          }
        }
      }
    }
    productVariant {
      id
      product {
        id
        name
        description
        images {
          id
          url
        }
        category {
          id
          name
        }
      }
    }
  }
}
    `;