import { useTranslation } from "@health/i18n";
import { FormCard, Grid } from "@health/ui";
import React, { FC } from "react";
import { FormDataProps } from "../types";
import { GeneralInformation } from "./GeneralInformation.component";

export const OrderInformation: FC<FormDataProps> = props => {
  const { isLoading, errorsInput } = props;
  const { t } = useTranslation("Admin");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormCard title={t("Order Information")} loading={isLoading} doYouHaveData={true}>
          <GeneralInformation errorsInput={errorsInput} />
        </FormCard>
      </Grid>
    </Grid>
  );
};
