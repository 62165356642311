/* eslint-disable react/jsx-handler-names */
import { useTranslation } from "@health/i18n";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, { FC, useEffect, useMemo } from "react";
import InfiniteScrollComponent from "../InfiniteScroll/InfiniteScrollComponent";
import { useAutocompleteStyles } from "./Autocomplete.styles";
import { AutocompleteComponentProps } from "./types";
import { useDebounce } from "./useDebounce";
import { autoCompleteI18nFiltrationOptions } from "./utils";

export const AutocompleteComponent: FC<AutocompleteComponentProps> = ({
  fetchMoreData,
  hasMore,
  customFilter,
  field,
  TextFieldProps,
  onSearchInput,
  onChange,
  ...props
}) => {
  const handleChange = field?.onChange || onChange;
  const { classes } = useAutocompleteStyles();
  const [inputValue, setInputValue] = React.useState("");
  const debouncedSearchInput = useDebounce(inputValue, 500);
  useEffect(() => {
    if (debouncedSearchInput) {
      onSearchInput && onSearchInput(inputValue);
    } else {
      onSearchInput && onSearchInput("");
    }
  }, [debouncedSearchInput]);
  const { t } = useTranslation();
  const handleInputChange = (e, newInputValue) => {
    setInputValue(newInputValue);
  };
  const value = useMemo(() => {
    if (field) {
      return props?.multiple ? field?.value || [] : field?.value || null;
    }
    return props?.value;
  }, [field?.value, props?.multiple, props?.value]);

  return (
    <Autocomplete
      {...props}
      value={value}
      noOptionsText={t("No options")}
      loadingText={t("Loading...")}
      className={classes.autocomplete}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      blurOnSelect
      filterOptions={autoCompleteI18nFiltrationOptions(props?.getOptionLabel)}
      options={props?.options || []}
      onChange={(_, data) => handleChange?.(data)}
      ListboxComponent={listBoxProps => {
        return (
          <InfiniteScrollComponent
            {...listBoxProps}
            options={props?.options}
            fetchMoreData={fetchMoreData}
            hasMore={hasMore}
            customFilter={customFilter}
          />
        );
      }}
      renderInput={params => (
        <TextField
          classes={{ root: classes.customTextField }}
          {...TextFieldProps}
          variant={"filled"}
          className={classes.textField}
          error={TextFieldProps?.error}
          fullWidth
          hiddenLabel
          {...params}
          inputProps={{
            ...params?.inputProps,
            endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
          }}
        />
      )}
    />
  );
};
