/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClientProfileOrdersQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type ClientProfileOrdersQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, orders?: { __typename?: 'OrderCountableConnection', edges: Array<{ __typename?: 'OrderCountableEdge', node: { __typename?: 'Order', id: string, status: Types.OrderStatus, number?: string | null, source: Types.OrderSource } }> } | null } | null };


export const ClientProfileOrdersDocument = gql`
    query ClientProfileOrders($first: Int, $last: Int) {
  me {
    id
    orders(first: $first, last: $last) {
      edges {
        node {
          id
          status
          number
          source
        }
      }
    }
  }
}
    `;

/**
 * __useClientProfileOrdersQuery__
 *
 * To run a query within a React component, call `useClientProfileOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientProfileOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientProfileOrdersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useClientProfileOrdersQuery(baseOptions?: Apollo.QueryHookOptions<ClientProfileOrdersQuery, ClientProfileOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientProfileOrdersQuery, ClientProfileOrdersQueryVariables>(ClientProfileOrdersDocument, options);
      }
export function useClientProfileOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientProfileOrdersQuery, ClientProfileOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientProfileOrdersQuery, ClientProfileOrdersQueryVariables>(ClientProfileOrdersDocument, options);
        }
export type ClientProfileOrdersQueryHookResult = ReturnType<typeof useClientProfileOrdersQuery>;
export type ClientProfileOrdersLazyQueryHookResult = ReturnType<typeof useClientProfileOrdersLazyQuery>;
export type ClientProfileOrdersQueryResult = Apollo.QueryResult<ClientProfileOrdersQuery, ClientProfileOrdersQueryVariables>;