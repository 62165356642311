/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { WishlistFragmentFragmentDoc } from '../../../../fragment/wishlist/__generated__/wishlist';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WishlistItemRemoveMutationVariables = Types.Exact<{
  vendorProductVariant: Types.Scalars['ID'];
}>;


export type WishlistItemRemoveMutation = { __typename?: 'Mutation', wishlistRemoveVendorProductVariant?: { __typename?: 'WishlistRemoveVendorProductVariantMutation', wishlist?: { __typename?: 'Wishlist', id: string, createdAt: any, items?: Array<{ __typename?: 'WishlistItem', id: string, createdAt: any, vendorProductVariant: { __typename?: 'VendorProductVariant', id: string, priceAmount?: number | null, price?: { __typename?: 'Money', currency: string, amount: number } | null, vendor?: { __typename?: 'Vendor', id: string, branches?: { __typename?: 'BranchCountableConnection', edges: Array<{ __typename?: 'BranchCountableEdge', node: { __typename?: 'Branch', id: string } }> } | null } | null, productVariant: { __typename?: 'ProductVariant', id: string, product?: { __typename?: 'Product', id: string, name?: string | null, description?: string | null, images?: Array<{ __typename?: 'ProductImage', id: string, url: string } | null> | null, category?: { __typename?: 'Category', id: string, name?: string | null } | null } | null } } } | null> | null } | null, wishlistErrors: Array<{ __typename?: 'WishlistError', code: Types.WishlistErrorCode, message?: string | null, field?: string | null }> } | null };


export const WishlistItemRemoveDocument = gql`
    mutation wishlistItemRemove($vendorProductVariant: ID!) {
  wishlistRemoveVendorProductVariant(vendorProductVariant: $vendorProductVariant) {
    wishlist {
      id
      createdAt
      items {
        ...WishlistFragment
      }
    }
    wishlistErrors {
      code
      message
      field
    }
  }
}
    ${WishlistFragmentFragmentDoc}`;
export type WishlistItemRemoveMutationFn = Apollo.MutationFunction<WishlistItemRemoveMutation, WishlistItemRemoveMutationVariables>;

/**
 * __useWishlistItemRemoveMutation__
 *
 * To run a mutation, you first call `useWishlistItemRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWishlistItemRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wishlistItemRemoveMutation, { data, loading, error }] = useWishlistItemRemoveMutation({
 *   variables: {
 *      vendorProductVariant: // value for 'vendorProductVariant'
 *   },
 * });
 */
export function useWishlistItemRemoveMutation(baseOptions?: Apollo.MutationHookOptions<WishlistItemRemoveMutation, WishlistItemRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WishlistItemRemoveMutation, WishlistItemRemoveMutationVariables>(WishlistItemRemoveDocument, options);
      }
export type WishlistItemRemoveMutationHookResult = ReturnType<typeof useWishlistItemRemoveMutation>;
export type WishlistItemRemoveMutationResult = Apollo.MutationResult<WishlistItemRemoveMutation>;
export type WishlistItemRemoveMutationOptions = Apollo.BaseMutationOptions<WishlistItemRemoveMutation, WishlistItemRemoveMutationVariables>;