import { makeStyles } from "tss-react/mui";

export const useFiltersStyles = makeStyles()(theme => ({
  selectStyle: {
    color: "black",
    boxShadow: "none",
    borderRadius: 20,
    option: {
      borderRadius: 20,
    },
  },
  root: {
    width: "100%",
  },
  heading: {
    // fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
    marginLeft: 10,
  },

  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
    backgroundColor: "rgb(211, 221, 234, .2)",
  },
  padding: {
    padding: "25px 15px",
  },
  titleColumn: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  column: {
    flexBasis: "33.33%",
    marginRight: 8,
  },
  input: {
    marginRight: 15,
    width: "100%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  createButton: {
    color: theme.palette.common.white,
    background: theme.palette.success.main,
    marginRight: theme.spacing(1),
    "&:hover": {
      background: theme.palette.success.light,
    },
  },
  cancelButton: {
    color: "#003971",
    background: "#B1BDD1",
    marginRight: theme.spacing(2),
  },
}));
