import { lodashGet } from "@health/common";
import { useTranslation } from "@health/i18n";
import { VendorBranchTypeEnum } from "@health/queries";
import { Checkbox, FormControlLabel, FormHelperText } from "@health/ui";
import React, { FC, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { PharmacyListingInputs } from "./types";
type PharmacyTypeFieldItemProps = {
  pharmaciesTypes;
  roundIndex: number;
  pharmacyIndex: number;
  label: string;
  value: VendorBranchTypeEnum;
  validate: () => any;
};
const PharmacyTypeFieldItem: FC<PharmacyTypeFieldItemProps> = ({ pharmaciesTypes, roundIndex, pharmacyIndex, label, value, validate }) => {
  const methods = useFormContext<PharmacyListingInputs>();
  const fieldKey = `rounds.${roundIndex}.pharmaciesTypes.${pharmacyIndex}` as `rounds.0.pharmaciesTypes.0`;
  const error = lodashGet(methods.formState.errors, `${fieldKey}.message`);
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            {...methods.register(fieldKey, {
              validate,
            })}
            defaultChecked={pharmaciesTypes?.includes(value)}
          />
        }
        label={label}
        value={value}
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </>
  );
};

export const PharmacyTypesFields: FC<Pick<PharmacyTypeFieldItemProps, "roundIndex" | "pharmaciesTypes">> = ({
  pharmaciesTypes,
  roundIndex,
}) => {
  const { t } = useTranslation("Admin");
  const methods = useFormContext();
  const fields = [
    { label: t("Participated Pharmacies"), value: VendorBranchTypeEnum.Pharmacy },
    { label: t("Reference Pharmacies"), value: VendorBranchTypeEnum.ReferencePharmacy },
    { label: t("Partner Warehouse"), value: VendorBranchTypeEnum.PartnerWarehouse },
  ];
  const validate = useCallback(() => {
    const errorMessage = t("choose one type at least");
    const values = methods.getValues(fields.map((v, pharmacyIndex) => `rounds.${roundIndex}.pharmaciesTypes.${pharmacyIndex}`));
    const isValid = values.some(v => v && v.length > 0);
    return isValid || errorMessage;
  }, []);
  return (
    <>
      {fields.map(({ label, value }, index) => {
        return (
          <PharmacyTypeFieldItem
            key={label}
            pharmaciesTypes={pharmaciesTypes}
            roundIndex={roundIndex}
            pharmacyIndex={index}
            validate={validate}
            label={label}
            value={value}
          />
        );
      })}
    </>
  );
};
export default PharmacyTypeFieldItem;
