import { useTranslation } from "@health/i18n";
import { useVendorQuery } from "@health/queries";
import { useParams } from "react-router-dom";

export const useProviderCardHook = () => {
  const { t } = useTranslation("Admin");
  const params = useParams();
  const { data, loading } = useVendorQuery({
    variables: {
      id: params?.id as string,
    },
  });
  const hasMultipleBranches = data?.vendor?.hasMultipleBranches;
  const vendor = data?.vendor;

  return {
    t,
    vendor,
    loading,
    hasMultipleBranches,
  };
};
