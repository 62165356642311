import { useTranslation } from "@health/i18n";
import {
  AccountError,
  formatMessageErrors,
  useAccountAddressCreateMutation,
  useAccountAddressDeleteMutation,
  useAccountAddressUpdateMutation,
  useAccountSetDefaultAddressMutation,
} from "@health/queries";
import { useAddToast } from "@health/ui";

export function useAddressFormQueries({
  onAddSuccess,
  onUpdateSuccess,
  onError,
}: {
  onError?: (errors: AccountError[]) => void;
  onAddSuccess: () => void;
  onUpdateSuccess: () => void;
}) {
  const { t } = useTranslation("Consumer");

  const { failed, succeeded } = useAddToast();
  const [createAccountAddress] = useAccountAddressCreateMutation({
    onCompleted: response => {
      const responseErrors = response?.accountAddressCreate?.accountErrors;
      if (responseErrors?.length) {
        onError?.((responseErrors as AccountError[]) || []);
        failed(formatMessageErrors(responseErrors));
      } else {
        succeeded(t("Address Created Successfully"));
        onAddSuccess();
      }
    },
  });

  const [updateAccountAddress] = useAccountAddressUpdateMutation({
    onCompleted: response => {
      const responseErrors = response?.accountAddressUpdate?.accountErrors;
      if (responseErrors?.length) {
        onError?.((responseErrors as AccountError[]) || []);
        failed(formatMessageErrors(responseErrors));
      } else {
        succeeded(t("Address Updated Successfully"));
        onUpdateSuccess();
      }
    },
  });

  const [setAccountAddressDefault] = useAccountSetDefaultAddressMutation({
    onCompleted: data => {
      const defaultAddressErrors = data?.accountSetDefaultAddress?.accountErrors;
      if (defaultAddressErrors?.length) {
        failed(defaultAddressErrors[0]?.message as string);
      }
    },
  });
  const [deleteAccountAddress] = useAccountAddressDeleteMutation({
    onCompleted: response => {
      const responseErrors = response?.accountAddressDelete?.accountErrors;
      if (responseErrors?.length) {
        onError?.((responseErrors as AccountError[]) || []);
        failed(formatMessageErrors(responseErrors));
      }
    },
  });

  return {
    createAccountAddress,
    updateAccountAddress,
    setAccountAddressDefault,
    deleteAccountAddress,
  };
}
