import { AccountRegister } from "@health/entry";
import { useNavigate } from "react-router-dom";

export const RegisterPage = () => {
  const navigate = useNavigate();

  const redirect = value => {
    return navigate("/" + value);
  };

  return <AccountRegister redirect={redirect} />;
};
