/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VendorInvoiceUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.VendorInvoiceUpdateInput;
}>;


export type VendorInvoiceUpdateMutation = { __typename?: 'Mutation', vendorInvoiceUpdate?: { __typename?: 'VendorInvoiceUpdate', vendorInvoice?: { __typename?: 'VendorInvoice', id: string, vat?: number | null } | null, invoiceErrors: Array<{ __typename?: 'InvoiceError', field?: string | null, message?: string | null, code: Types.InvoiceErrorCode }> } | null };


export const VendorInvoiceUpdateDocument = gql`
    mutation vendorInvoiceUpdate($id: ID!, $input: VendorInvoiceUpdateInput!) {
  vendorInvoiceUpdate(id: $id, input: $input) {
    vendorInvoice {
      id
      vat
    }
    invoiceErrors {
      field
      message
      code
    }
  }
}
    `;
export type VendorInvoiceUpdateMutationFn = Apollo.MutationFunction<VendorInvoiceUpdateMutation, VendorInvoiceUpdateMutationVariables>;

/**
 * __useVendorInvoiceUpdateMutation__
 *
 * To run a mutation, you first call `useVendorInvoiceUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVendorInvoiceUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vendorInvoiceUpdateMutation, { data, loading, error }] = useVendorInvoiceUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVendorInvoiceUpdateMutation(baseOptions?: Apollo.MutationHookOptions<VendorInvoiceUpdateMutation, VendorInvoiceUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VendorInvoiceUpdateMutation, VendorInvoiceUpdateMutationVariables>(VendorInvoiceUpdateDocument, options);
      }
export type VendorInvoiceUpdateMutationHookResult = ReturnType<typeof useVendorInvoiceUpdateMutation>;
export type VendorInvoiceUpdateMutationResult = Apollo.MutationResult<VendorInvoiceUpdateMutation>;
export type VendorInvoiceUpdateMutationOptions = Apollo.BaseMutationOptions<VendorInvoiceUpdateMutation, VendorInvoiceUpdateMutationVariables>;