import { i18n, useTranslation } from "@health/i18n";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import React, { FC } from "react";
import { useNetworkStatus } from "use-network-status";
import { useNetworkStatusStyles } from "./NetworkStatus.styles";

export const NetworkStatus: FC<{ isOpen: boolean }> = ({ isOpen }) => {
  const { t } = useTranslation();
  const currentDir = i18n.dir(i18n.language);
  const isOnline = useNetworkStatus();
  const { classes } = useNetworkStatusStyles({ isOpen });
  return (
    <Snackbar
      open={!isOnline}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      classes={{ root: currentDir === "ltr" ? `${classes.ltr}` : `${classes.rtl}` }}
    >
      <Alert severity='warning' icon={<CircularProgress size={20} />}>
        {t("No internet connection")}
      </Alert>
    </Snackbar>
  );
};
