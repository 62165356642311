/* eslint-disable jsx-a11y/no-static-element-interactions */
import { i18n, useTranslation } from "@health/i18n";
import { Box, Button, CircularProgress, Grid, Typography } from "@health/ui";
import React, { FC } from "react";
import ReactInputVerificationCode from "react-input-verification-code";
import { useConfirmationHooks } from "../Confirmation.hook";
import { useConfirmationStyle } from "../styles/useConfirmationStyle";
import { FormProps } from "./types";

export const VerificationForm: FC<FormProps> = ({ onChangeStep, isPage }) => {
  const { t } = useTranslation();
  const currentDir = i18n.dir(i18n.language);
  const { classes } = useConfirmationStyle({ currentDir });
  const {
    code,
    isWrongLanguage,
    disableVerifyButton,
    isSamePreviousCode,
    mobile,
    errors,
    isResendButtonDisabled,
    countDown,
    triedToSubmit,
    isTokenExpired,
    isSendingOtpCode,
    isSendingConfirmAccountMobileOtp,
    isSendingConfirmAccountMobile,
    handleLogin,
    handleResendCode,
    handleCheckLanguage,
    handleChangeInput,
    handleConfirmAccountMobile,
  } = useConfirmationHooks(onChangeStep, isPage);

  const isCountingDown = countDown > 0;

  return (
    <Box margin='20px' textAlign='center'>
      <Typography fontSize='14px'>
        {t("Please Enter the")} <strong>{t("confirmation code")}</strong> {t("that has been sent to your mobile number")}
        <Grid item className='mobile-phone-rtl-fix'>
          <strong className={classes.mobile}>{mobile}</strong>
        </Grid>
      </Typography>
      {isCountingDown ? (
        <>
          <Typography className={classes.receive} fontSize={14} textAlign='center'>
            {!triedToSubmit && !isTokenExpired ? t("You will receive the verification code in") : t("You can request new code after")}
            <Typography className={classes.receive} fontSize={14} textAlign='center'>
              {countDown} {t("s")}
            </Typography>
          </Typography>
        </>
      ) : (
        <>
          <Button
            variant='text'
            className={classes.resend}
            onClick={handleResendCode}
            disabled={isResendButtonDisabled || isSendingOtpCode}
          >
            {isSendingOtpCode && <CircularProgress size={14} />} {t("Resend")}
          </Button>
        </>
      )}

      <div
        className={errors.length > 0 ? `${classes.confirm} ${classes.confirmError}` : classes.confirm}
        onKeyPress={handleCheckLanguage}
        dir='ltr'
      >
        <ReactInputVerificationCode value={code} placeholder='' length={4} onChange={handleChangeInput} />
      </div>
      {isWrongLanguage && (
        <Typography variant='body2' textAlign='center' color='error'>
          {t("Please enter the number in english")}
        </Typography>
      )}
      {errors.length > 0 && (
        <div>
          {errors.map((error, index) => (
            <Typography className={classes.error} key={`${error.code}${index}`} color='error'>
              {t(error.message)}
            </Typography>
          ))}
        </div>
      )}

      <Button
        fullWidth
        variant='contained'
        type='submit'
        size='large'
        className={classes.button}
        onClick={handleConfirmAccountMobile}
        disabled={disableVerifyButton || isSamePreviousCode || isSendingConfirmAccountMobileOtp || isSendingConfirmAccountMobile}
      >
        {isSendingConfirmAccountMobile || (isSendingConfirmAccountMobileOtp && <CircularProgress size={14} />)}
        {t("Verify")}
      </Button>

      {!isPage && (
        <Button variant='text' className={classes.backToRegister} onClick={handleLogin}>
          {t("Back to login")}
        </Button>
      )}
    </Box>
  );
};
