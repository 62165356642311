/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { ProductClientFragmentFragmentDoc } from '../../../../fragment/clients/storeProducts/__generated__/ProductClientFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClientProductQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type ClientProductQuery = { __typename?: 'Query', storeProduct?: { __typename?: 'VendorProductVariant', id: string, vendor?: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null, logo?: string | null, branches?: { __typename?: 'BranchCountableConnection', edges: Array<{ __typename?: 'BranchCountableEdge', node: { __typename?: 'Branch', id: string } }> } | null } | null, productVariant: { __typename?: 'ProductVariant', id: string, rating?: number | null, images?: Array<{ __typename?: 'ProductImage', url: string } | null> | null, product?: { __typename?: 'Product', id: string, name?: string | null, description?: string | null, descriptionJson?: any | null, isLiked?: boolean | null, category?: { __typename?: 'Category', id: string, name?: string | null } | null, images?: Array<{ __typename?: 'ProductImage', id: string, url: string } | null> | null } | null }, pricing?: { __typename?: 'VariantPricingInfo', onSale?: boolean | null, price?: { __typename?: 'TaxedMoney', gross: { __typename?: 'Money', amount: number, currency: string } } | null, priceUndiscounted?: { __typename?: 'TaxedMoney', gross: { __typename?: 'Money', amount: number, currency: string } } | null, discount?: { __typename?: 'TaxedMoney', gross: { __typename?: 'Money', amount: number, currency: string } } | null } | null } | null };


export const ClientProductDocument = gql`
    query clientProduct($id: ID!) {
  storeProduct(id: $id) {
    ...ProductClientFragment
    vendor {
      branches(first: 10) {
        edges {
          node {
            id
          }
        }
      }
    }
  }
}
    ${ProductClientFragmentFragmentDoc}`;

/**
 * __useClientProductQuery__
 *
 * To run a query within a React component, call `useClientProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClientProductQuery(baseOptions: Apollo.QueryHookOptions<ClientProductQuery, ClientProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientProductQuery, ClientProductQueryVariables>(ClientProductDocument, options);
      }
export function useClientProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientProductQuery, ClientProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientProductQuery, ClientProductQueryVariables>(ClientProductDocument, options);
        }
export type ClientProductQueryHookResult = ReturnType<typeof useClientProductQuery>;
export type ClientProductLazyQueryHookResult = ReturnType<typeof useClientProductLazyQuery>;
export type ClientProductQueryResult = Apollo.QueryResult<ClientProductQuery, ClientProductQueryVariables>;