import { i18n } from "@health/i18n";
import { PrescriptionDispenseStatus } from "@health/queries";
import { cyan, lightGrey, makeStyles, primary, white } from "@health/ui";
import { GetStatusColor } from "../util";

const airbnbMedium = "Airbnb Cereal App Medium, Arab Kufi Regular";

export const useLifeCycleStyles = makeStyles<{ dispenseStatus?: PrescriptionDispenseStatus }>()((_, { dispenseStatus }) => ({
  container: {
    padding: 14,
    height: "100vh",
    overflow: "auto",
  },

  title: {
    fontSize: 22,
    fontFamily: airbnbMedium,
  },
  referenceNumber: {
    color: cyan,
  },
  text: {
    fontSize: 14,
  },
  date: {
    fontSize: 12,
    paddingLeft: 10,
  },
  textBold: {
    fontSize: 14,
    fontFamily: "Airbnb Cereal App Bold, Arab Kufi Bold",
  },
  dateBold: {
    fontSize: 12,
    fontFamily: airbnbMedium,
    whiteSpace: "nowrap",
  },
  dot: {
    margin: 0,
    width: 20,
    height: 20,
    position: "absolute",
    top: 35,
    left: -8,
    background: white,
    borderColor: GetStatusColor(dispenseStatus),
  },
  separator: {
    width: 5,
    background: lightGrey,
  },
  position: {
    position: "relative",
  },
  parentCard: {
    border: "1px solid " + primary + " !important",
    "&::after": {
      borderColor: primary + " transparent  !important",
    },
    "&::before": {
      borderColor: primary + ` transparent  !important`,
    },
  },
  card: {
    padding: 10,
    width: "100%",
    borderRadius: 10,
    marginLeft: 6,
    border: "1px solid #DFE8F2",
    boxShadow: "0px 0px 5px #0000001A",
    "&::after": {
      content: "' '",
      position: "absolute",
      top: 40,
      left: i18n.language == "en" ? 11 : "none",
      right: i18n.language == "ar" ? 6 : "none",
      borderStyle: "solid",
      borderWidth: "8px 8px 0",
      borderColor: "#FFFFFF transparent",
      display: "block",
      width: 0,
      zIndex: 1,
      transform: i18n.language == "ar" ? "rotate(270deg)" : "rotate(90deg)",
    },
    "&::before": {
      content: "' '",
      position: "absolute",
      top: 40,
      left: i18n.language == "en" ? 9 : "none",
      right: i18n.language == "ar" ? 4 : "none",
      borderStyle: "solid",
      borderWidth: "9px 9px 0",
      borderColor: `#DFE8F2 transparent`,
      display: "block",
      width: 0,
      zIndex: 0,
      transform: i18n.language == "ar" ? "rotate(270deg)" : "rotate(90deg)",
    },
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  line: {
    margin: 10,
    marginInline: 0,
  },
  content: {
    marginTop: 30,
    maxWidth: 120,
  },
  textField: {
    width: 250,
  },
  rootAuto: {
    "& .MuiFilledInput-root": {
      minHeight: 43,
      padding: 0,
      fontSize: 14,
    },
    "& .MuiFilledInput-root.MuiInputBase-sizeSmall": {
      padding: "0 5px !important",
    },
  },
  infinite: {
    overflow: "hidden !important",
  },
  deliveryWorkflowWrapper: {
    marginBlock: 5,
  },
}));
