import { lightGrey, makeStyles, white } from "@health/ui";

export const useDialogConsumerStyle = makeStyles()({
  paper: {
    width: 352,
    borderRadius: 15,
  },
  signin: {
    color: white,
  },
  tabs: {
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    background: lightGrey,
    height: 43,
    margin: 10,
    marginInline: 0,
  },
  tabsMobile: {
    borderRadius: 0,
    height: 47,
    paddingInline: 12,
  },
});
