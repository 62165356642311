/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { WishlistFragmentFragmentDoc } from '../../../../fragment/wishlist/__generated__/wishlist';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WishlistDeleteAllMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type WishlistDeleteAllMutation = { __typename?: 'Mutation', consumerWishlistDelete?: { __typename?: 'ConsumerWishlistDelete', wishlist?: { __typename?: 'Wishlist', id: string, createdAt: any, items?: Array<{ __typename?: 'WishlistItem', id: string, createdAt: any, vendorProductVariant: { __typename?: 'VendorProductVariant', id: string, priceAmount?: number | null, price?: { __typename?: 'Money', currency: string, amount: number } | null, vendor?: { __typename?: 'Vendor', id: string, branches?: { __typename?: 'BranchCountableConnection', edges: Array<{ __typename?: 'BranchCountableEdge', node: { __typename?: 'Branch', id: string } }> } | null } | null, productVariant: { __typename?: 'ProductVariant', id: string, product?: { __typename?: 'Product', id: string, name?: string | null, description?: string | null, images?: Array<{ __typename?: 'ProductImage', id: string, url: string } | null> | null, category?: { __typename?: 'Category', id: string, name?: string | null } | null } | null } } } | null> | null } | null, wishlistErrors: Array<{ __typename?: 'WishlistError', code: Types.WishlistErrorCode, message?: string | null, field?: string | null }> } | null };


export const WishlistDeleteAllDocument = gql`
    mutation wishlistDeleteAll {
  consumerWishlistDelete {
    wishlist {
      id
      createdAt
      items {
        ...WishlistFragment
      }
    }
    wishlistErrors {
      code
      message
      field
    }
  }
}
    ${WishlistFragmentFragmentDoc}`;
export type WishlistDeleteAllMutationFn = Apollo.MutationFunction<WishlistDeleteAllMutation, WishlistDeleteAllMutationVariables>;

/**
 * __useWishlistDeleteAllMutation__
 *
 * To run a mutation, you first call `useWishlistDeleteAllMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWishlistDeleteAllMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wishlistDeleteAllMutation, { data, loading, error }] = useWishlistDeleteAllMutation({
 *   variables: {
 *   },
 * });
 */
export function useWishlistDeleteAllMutation(baseOptions?: Apollo.MutationHookOptions<WishlistDeleteAllMutation, WishlistDeleteAllMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WishlistDeleteAllMutation, WishlistDeleteAllMutationVariables>(WishlistDeleteAllDocument, options);
      }
export type WishlistDeleteAllMutationHookResult = ReturnType<typeof useWishlistDeleteAllMutation>;
export type WishlistDeleteAllMutationResult = Apollo.MutationResult<WishlistDeleteAllMutation>;
export type WishlistDeleteAllMutationOptions = Apollo.BaseMutationOptions<WishlistDeleteAllMutation, WishlistDeleteAllMutationVariables>;