import { useTranslation } from "@health/i18n";
import {
  Badge,
  Box,
  CircularProgress,
  cyan,
  IconButton,
  InfiniteScrollContainer,
  NotificationIcon,
  NotificationsDrawer,
  NotificationsItem,
  Typography,
} from "@health/ui";
import React from "react";
import { useNotificationsHooks } from "./Notifications.hook";
import { useNotificationsStyles } from "./Notifications.styles";

export const Notifications = () => {
  const { t } = useTranslation();
  const { classes } = useNotificationsStyles();
  const { open, loading, notifications, pageInfo, totalCount, unReadNotificationsCount, handleToggle, fetchMoreData } =
    useNotificationsHooks();

  return (
    <>
      <IconButton color='primary' onClick={handleToggle}>
        <Badge badgeContent={unReadNotificationsCount} color='error'>
          <NotificationIcon />
        </Badge>
      </IconButton>
      {open && (
        <NotificationsDrawer open={open} number={Number(totalCount)} onClose={handleToggle}>
          {loading ? (
            <Box display='flex' justifyContent='center' color={cyan} m={1}>
              <CircularProgress size={50} color='inherit' />
            </Box>
          ) : (
            <>
              {notifications?.length ? (
                <div className={classes.container} id='scrollableDiv'>
                  <InfiniteScrollContainer
                    dataLength={Number(notifications?.length) || 0}
                    next={() => fetchMoreData()}
                    hasMore={Boolean(pageInfo?.hasNextPage)}
                    loader={
                      <Box display='flex' justifyContent='center' color={cyan} m={1}>
                        <CircularProgress size={50} color='inherit' />
                      </Box>
                    }
                    classes={{
                      infinite: classes.infinite,
                    }}
                    scrollableTarget='scrollableDiv'
                  >
                    {notifications?.map(notification => (
                      <NotificationsItem
                        key={notification.id}
                        title={String(notification.title)}
                        subTitle={String(notification.body)}
                        type={notification.type}
                      />
                    ))}
                  </InfiniteScrollContainer>
                </div>
              ) : (
                <Typography p={3} variant='h5'>
                  {t("There is no Notifications")}
                </Typography>
              )}
            </>
          )}
        </NotificationsDrawer>
      )}
    </>
  );
};
