/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CodeSystemConceptAutoCompleteSearchQueryVariables = Types.Exact<{
  codeSystemId?: Types.InputMaybe<Types.Scalars['ID']>;
  codeSystemCode?: Types.InputMaybe<Types.CodeSystemCode>;
  keyword: Types.Scalars['String'];
  propertyCode: Types.Scalars['String'];
}>;


export type CodeSystemConceptAutoCompleteSearchQuery = { __typename?: 'Query', codeSystemConceptAutoCompleteSearch?: Array<string | null> | null };


export const CodeSystemConceptAutoCompleteSearchDocument = gql`
    query codeSystemConceptAutoCompleteSearch($codeSystemId: ID, $codeSystemCode: CodeSystemCode, $keyword: String!, $propertyCode: String!) {
  codeSystemConceptAutoCompleteSearch(
    codeSystemId: $codeSystemId
    codeSystemCode: $codeSystemCode
    keyword: $keyword
    propertyCode: $propertyCode
  )
}
    `;

/**
 * __useCodeSystemConceptAutoCompleteSearchQuery__
 *
 * To run a query within a React component, call `useCodeSystemConceptAutoCompleteSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCodeSystemConceptAutoCompleteSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCodeSystemConceptAutoCompleteSearchQuery({
 *   variables: {
 *      codeSystemId: // value for 'codeSystemId'
 *      codeSystemCode: // value for 'codeSystemCode'
 *      keyword: // value for 'keyword'
 *      propertyCode: // value for 'propertyCode'
 *   },
 * });
 */
export function useCodeSystemConceptAutoCompleteSearchQuery(baseOptions: Apollo.QueryHookOptions<CodeSystemConceptAutoCompleteSearchQuery, CodeSystemConceptAutoCompleteSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CodeSystemConceptAutoCompleteSearchQuery, CodeSystemConceptAutoCompleteSearchQueryVariables>(CodeSystemConceptAutoCompleteSearchDocument, options);
      }
export function useCodeSystemConceptAutoCompleteSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CodeSystemConceptAutoCompleteSearchQuery, CodeSystemConceptAutoCompleteSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CodeSystemConceptAutoCompleteSearchQuery, CodeSystemConceptAutoCompleteSearchQueryVariables>(CodeSystemConceptAutoCompleteSearchDocument, options);
        }
export type CodeSystemConceptAutoCompleteSearchQueryHookResult = ReturnType<typeof useCodeSystemConceptAutoCompleteSearchQuery>;
export type CodeSystemConceptAutoCompleteSearchLazyQueryHookResult = ReturnType<typeof useCodeSystemConceptAutoCompleteSearchLazyQuery>;
export type CodeSystemConceptAutoCompleteSearchQueryResult = Apollo.QueryResult<CodeSystemConceptAutoCompleteSearchQuery, CodeSystemConceptAutoCompleteSearchQueryVariables>;