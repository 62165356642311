/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { ProductBrandFragmentFragmentDoc } from '../../../../fragment/ProductBrand/__generated__/ProductBrand';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductBrandCreateMutationVariables = Types.Exact<{
  input: Types.ProductBrandInput;
}>;


export type ProductBrandCreateMutation = { __typename?: 'Mutation', productBrandCreate?: { __typename?: 'ProductBrandCreate', productBrand?: { __typename?: 'ProductBrand', name: string, id: string } | null, productErrors: Array<{ __typename?: 'ProductError', field?: string | null, message?: string | null }> } | null };


export const ProductBrandCreateDocument = gql`
    mutation ProductBrandCreate($input: ProductBrandInput!) {
  productBrandCreate(input: $input) {
    productBrand {
      ...ProductBrandFragment
    }
    productErrors {
      field
      message
    }
  }
}
    ${ProductBrandFragmentFragmentDoc}`;
export type ProductBrandCreateMutationFn = Apollo.MutationFunction<ProductBrandCreateMutation, ProductBrandCreateMutationVariables>;

/**
 * __useProductBrandCreateMutation__
 *
 * To run a mutation, you first call `useProductBrandCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductBrandCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productBrandCreateMutation, { data, loading, error }] = useProductBrandCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductBrandCreateMutation(baseOptions?: Apollo.MutationHookOptions<ProductBrandCreateMutation, ProductBrandCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductBrandCreateMutation, ProductBrandCreateMutationVariables>(ProductBrandCreateDocument, options);
      }
export type ProductBrandCreateMutationHookResult = ReturnType<typeof useProductBrandCreateMutation>;
export type ProductBrandCreateMutationResult = Apollo.MutationResult<ProductBrandCreateMutation>;
export type ProductBrandCreateMutationOptions = Apollo.BaseMutationOptions<ProductBrandCreateMutation, ProductBrandCreateMutationVariables>;