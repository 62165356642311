/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type CategoryFragmentFragment = { __typename?: 'Category', id: string, name?: string | null, level: number, seoTitle?: string | null, description?: string | null, slug: string, backgroundImage?: { __typename?: 'Image', url?: string | null, alt?: string | null } | null, parent?: { __typename?: 'Category', id: string } | null, children?: { __typename?: 'CategoryCountableConnection', totalCount?: number | null } | null };

export const CategoryFragmentFragmentDoc = gql`
    fragment CategoryFragment on Category {
  id
  name
  level
  seoTitle
  description
  backgroundImage {
    url
    alt
  }
  slug
  parent {
    id
  }
  children {
    totalCount
  }
}
    `;