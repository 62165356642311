import { useTranslation } from "@health/i18n";
import { City, useCitiesQuery } from "@health/queries";
import { AutocompleteComponent, getTranslatedNameField } from "@health/ui";
import { AutocompleteComponentProps } from "@health/ui/dist/components/Autocomplete/types";
import _ from "lodash";
import React, { FC } from "react";

export const CitiesAutocompletePlain: FC<
  { errorMessage?: string; additiveOptions?: { id: string; name: string; nameAr: string }[] } & AutocompleteComponentProps
> = ({ errorMessage, additiveOptions = [], ...props }) => {
  const { t } = useTranslation();
  const {
    data: citiesData,
    loading: loadingCities,
    fetchMore,
    refetch,
  } = useCitiesQuery({
    variables: {
      first: 10,
      after: null,
      before: null,
      last: null,
    },
  });
  const cities = (citiesData?.cities?.edges?.map(city => city?.node)! as City[]) || [];
  const pageInfo = citiesData?.cities?.pageInfo;
  const hasMoreData = pageInfo?.hasNextPage;

  const fetchMoreData = () => {
    if (pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          first: 10,
          after: pageInfo?.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            ...fetchMoreResult,
            cities: {
              ...fetchMoreResult?.cities,
              edges: [...(prev?.cities?.edges || []), ...(fetchMoreResult?.cities?.edges || [])],
            },
          };
        },
      });
    }
  };
  const handleSearchInput = (name: string) => {
    refetch({ filter: { name_Icontains: name }, first: 10 });
  };

  return (
    <AutocompleteComponent
      fetchMoreData={fetchMoreData}
      isOptionEqualToValue={(o, v) => o?.id === v?.id}
      hasMore={hasMoreData}
      getOptionLabel={(option: City) => getTranslatedNameField(option) || option.name || option.id}
      options={_.uniqBy([...additiveOptions, ...cities], "id") || []}
      onSearchInput={handleSearchInput}
      TextFieldProps={{
        placeholder: t("City"),
        label: t("City"),
        error: Boolean(errorMessage),
        helperText: errorMessage,
      }}
      loading={loadingCities || !!cities.length}
      {...props}
    />
  );
};

CitiesAutocompletePlain.defaultProps = {
  errorMessage: undefined,
  additiveOptions: [],
};
