import { Tooltip, Typography, TypographyProps } from "@mui/material";
import React, { FC } from "react";
import { textTruncate } from "../../utils";

export const TooltipTypography: FC<{ text: string; maxChars: number } & TypographyProps> = ({ text, maxChars, sx, ...restOfProps }) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(text?.length > maxChars);
  };

  return (
    <Tooltip title={text} open={open} onClose={handleClose} onOpen={handleOpen}>
      <Typography
        {...restOfProps}
        sx={{
          cursor: "pointer",
          ...sx,
        }}
      >
        {textTruncate(text, maxChars)}
      </Typography>
    </Tooltip>
  );
};
