import { i18next } from "@health/i18n";
import { Branch, User, UserSortField } from "@health/queries";
import { CustomTableColumnProps } from "@health/ui";
import { ActiveToggleButton } from "../components/ActiveToggleButton.component";

export const staffUsersColumns = (t: i18next.TFunction): CustomTableColumnProps<User>[] => [
  {
    key: "FirstName",
    header: t("First Name"),
    accessor: "firstName",
    isSortable: true,
    sortDirection: undefined,
    hideFromSettings: true,
    disableToggleVisibility: true,
    sortColumnEnum: UserSortField.FirstName,
  },
  {
    key: "LastName",
    header: t("Last Name"),
    accessor: "lastName",
  },
  {
    key: "Email",
    header: t("Email"),
    accessor: "email",
    isSortable: true,
    sortDirection: undefined,
    sortColumnEnum: UserSortField.Email,
  },
  {
    key: "Mobile",
    header: t("Mobile"),
    accessor: "mobile",
  },
  {
    key: "isActive",
    header: t("Is Active"),
    accessor: row => (
      <ActiveToggleButton
        id={row?.id}
        isActive={row?.isActive}
        appRole={row?.appRole!}
        branches={row?.branches as Branch[]}
        appType={row?.appType!}
      />
    ),
  },
  {
    key: "isVendorAdmin",
    header: t("Is Vendor Admin"),
    accessor: ({ isVendorAdmin }) => (isVendorAdmin ? t("Yes") : t("No")),
  },
  {
    key: "isStaff",
    header: t("Staff"),
    accessor: ({ isStaff }) => (isStaff ? t("Yes") : t("No")),
  },
];
