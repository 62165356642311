/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PlanQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type PlanQuery = { __typename?: 'Query', plan?: { __typename?: 'Plan', id: string, name: string, isActive: boolean, period: Types.PlanPeriod, currency: string, validFrom: any, validTill?: any | null, description?: string | null, fixedCostAmount: number, fixedOrderCostAmount: number, fixedOrderPercentage: number, fixedCost?: { __typename?: 'Money', currency: string, amount: number } | null, fixedOrderCost?: { __typename?: 'Money', currency: string, amount: number } | null } | null };


export const PlanDocument = gql`
    query plan($id: ID!) {
  plan(id: $id) {
    id
    name
    isActive
    period
    currency
    validFrom
    validTill
    description
    fixedCostAmount
    fixedOrderCostAmount
    fixedOrderPercentage
    fixedCost {
      currency
      amount
    }
    fixedOrderCost {
      currency
      amount
    }
  }
}
    `;

/**
 * __usePlanQuery__
 *
 * To run a query within a React component, call `usePlanQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlanQuery(baseOptions: Apollo.QueryHookOptions<PlanQuery, PlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanQuery, PlanQueryVariables>(PlanDocument, options);
      }
export function usePlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanQuery, PlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanQuery, PlanQueryVariables>(PlanDocument, options);
        }
export type PlanQueryHookResult = ReturnType<typeof usePlanQuery>;
export type PlanLazyQueryHookResult = ReturnType<typeof usePlanLazyQuery>;
export type PlanQueryResult = Apollo.QueryResult<PlanQuery, PlanQueryVariables>;