import { useTranslation } from "@health/i18n";
import { DeliveryShipmentGetShipmentResponse, PrescriptionDispenseStatus } from "@health/queries";
import { Box, CircularProgress, cyan, EmptyCard, InfiniteScrollContainer, Typography } from "@health/ui";
import React, { FC, Fragment } from "react";
import { useLifeCycleHooks } from "./LifeCycle.hook";
import { useLifeCycleStyles } from "./LifeCycle.styles";
import { LifeCycleDrawerComponents } from "./LifeCycleDrawer.components";
import { OrdersEvents } from "./OrdersEvents.components";

export const LifeCycleModal: FC<{
  prescriptionID: string;
  referenceNumber: string;
  number: string;
  isMarketPlaceOrder: boolean;
  dispenseStatus: PrescriptionDispenseStatus;
  shipmentInformation: DeliveryShipmentGetShipmentResponse | null | undefined;
}> = ({ prescriptionID, number, referenceNumber, dispenseStatus, isMarketPlaceOrder = false, shipmentInformation }) => {
  const { t, i18n } = useTranslation("Admin");
  const { classes } = useLifeCycleStyles({ dispenseStatus });

  const { open, loading, ordersData, pageInfo, fetchMoreData, handleClickOpen, handleClickClose } = useLifeCycleHooks(
    prescriptionID,
    number
  );

  return (
    <LifeCycleDrawerComponents open={open} onClickOpen={handleClickOpen} onClickClose={handleClickClose}>
      <div className={classes.container} id='scrollableDiv'>
        <div className={classes.flex}>
          <Typography className={classes.title}>
            {isMarketPlaceOrder ? t("Order") : t("eRx")}
            <span className={classes.referenceNumber}> {t(referenceNumber)} </span>
            {i18n.language === "en" && t("History")}
          </Typography>
        </div>

        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {!ordersData?.length ? (
              <Box height='100%' display='flex' alignItems='center'>
                <EmptyCard title={t("There are no orders")} message={t("There are no orders related to this e-prescription yet")} />
              </Box>
            ) : (
              <InfiniteScrollContainer
                dataLength={Number(ordersData?.length) || 0}
                next={() => fetchMoreData()}
                hasMore={Boolean(pageInfo?.hasNextPage)}
                loader={
                  <Box display='flex' justifyContent='center' color={cyan} m={1}>
                    <CircularProgress size={50} color='inherit' />
                  </Box>
                }
                classes={{
                  infinite: classes.infinite,
                }}
                scrollableTarget='scrollableDiv'
              >
                {ordersData?.map((item, index) => {
                  return (
                    <Fragment key={String(item?.id) + index}>
                      <Typography className={classes.textBold}>
                        {t("Order No")}: {item?.number}
                      </Typography>

                      <OrdersEvents
                        dispenseStatus={dispenseStatus}
                        events={item?.events}
                        status={item?.status!}
                        failReason={item?.failReason!}
                        helpDiskTicketId={item?.helpDiskTicketId!}
                        deliveryWorkflowId={shipmentInformation?.deliveryWorkflowId}
                      />
                    </Fragment>
                  );
                })}
              </InfiniteScrollContainer>
            )}
          </>
        )}
      </div>
    </LifeCycleDrawerComponents>
  );
};
