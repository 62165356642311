/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MyOrdersCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyOrdersCountQuery = { __typename?: 'Query', me?: { __typename?: 'User', new?: { __typename?: 'OrderCountableConnection', totalCount?: number | null } | null, pending?: { __typename?: 'OrderCountableConnection', totalCount?: number | null } | null, onTheWay?: { __typename?: 'OrderCountableConnection', totalCount?: number | null } | null, delivered?: { __typename?: 'OrderCountableConnection', totalCount?: number | null } | null, returned?: { __typename?: 'OrderCountableConnection', totalCount?: number | null } | null, declined?: { __typename?: 'OrderCountableConnection', totalCount?: number | null } | null } | null };


export const MyOrdersCountDocument = gql`
    query myOrdersCount {
  me {
    new: orders(filter: {status: [WAITING_PROVIDER_SELECTION, NEW_REQUEST]}) {
      totalCount
    }
    pending: orders(
      filter: {status: [IN_PROGRESS, ALLOCATED, ERX_HUB_REJECTED, WAITING_ERX_HUB_APPROVAL, ERX_HUB_PARTIALLY_APPROVED, ERX_HUB_FULLY_APPROVED, ERX_HUB_TIMED_OUT, ERX_HUB_AUTH_REQUEST_FAILED, ERX_HUB_CLAIM_SUBMIT_TIMED_OUT]}
    ) {
      totalCount
    }
    onTheWay: orders(
      filter: {status: [OUT_FOR_DELIVERY, READY_FOR_CUSTOMER_PICKUP]}
    ) {
      totalCount
    }
    delivered: orders(
      filter: {status: [DELIVERED, WAITING_ERX_HUB_CLAIM_APPROVAL, ERX_HUB_CLAIM_APPROVED, ERX_HUB_CLAIM_REJECTED, ERX_HUB_CLAIM_FAILED, DISPENSED]}
    ) {
      totalCount
    }
    returned: orders(filter: {status: [RETURNED]}) {
      totalCount
    }
    declined: orders(filter: {status: [CANCELED_BY_CONSUMER]}) {
      totalCount
    }
  }
}
    `;

/**
 * __useMyOrdersCountQuery__
 *
 * To run a query within a React component, call `useMyOrdersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOrdersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOrdersCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyOrdersCountQuery(baseOptions?: Apollo.QueryHookOptions<MyOrdersCountQuery, MyOrdersCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyOrdersCountQuery, MyOrdersCountQueryVariables>(MyOrdersCountDocument, options);
      }
export function useMyOrdersCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyOrdersCountQuery, MyOrdersCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyOrdersCountQuery, MyOrdersCountQueryVariables>(MyOrdersCountDocument, options);
        }
export type MyOrdersCountQueryHookResult = ReturnType<typeof useMyOrdersCountQuery>;
export type MyOrdersCountLazyQueryHookResult = ReturnType<typeof useMyOrdersCountLazyQuery>;
export type MyOrdersCountQueryResult = Apollo.QueryResult<MyOrdersCountQuery, MyOrdersCountQueryVariables>;