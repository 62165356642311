import { Grid, MedListCardLoader } from "@health/ui";
import React, { FC, memo } from "react";
import { MedicalCard } from "shared/components";
import { useMedListHooks } from "./useMedListHooks";

const MedList: FC = () => {
  const { medItems, users, onDetailsClick, handleUpdateAssign, isLoading = true } = useMedListHooks();
  return (
    <>
      {isLoading ? (
        <>
          <Grid container padding={4} spacing={2}>
            {new Array(10).fill(0).map((el, key) => (
              <Grid key={key} item xs={12} sm={6} lg={4} xl={3}>
                <MedListCardLoader />
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <>
          <Grid container padding={4} spacing={2}>
            <>
              {medItems?.map((item, index) => (
                <Grid key={index} item xs={12} sm={6} lg={4} xl={3}>
                  <MedicalCard data={item} handleDetailsClick={onDetailsClick} users={users} onUpdateAssign={handleUpdateAssign} />
                </Grid>
              ))}
            </>
          </Grid>
        </>
      )}
    </>
  );
};

export default memo(MedList);
