/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TerminateVendorSubscriptionMutationVariables = Types.Exact<{
  vendor: Types.Scalars['ID'];
}>;


export type TerminateVendorSubscriptionMutation = { __typename?: 'Mutation', terminateVendorSubscription?: { __typename?: 'TerminateVendorSubscription', vendorErrors: Array<{ __typename?: 'VendorError', field?: string | null, message?: string | null, code: Types.VendorErrorCode }>, vendor?: { __typename?: 'Vendor', id: string, name: string, isActive: boolean } | null } | null };


export const TerminateVendorSubscriptionDocument = gql`
    mutation terminateVendorSubscription($vendor: ID!) {
  terminateVendorSubscription(vendor: $vendor) {
    vendorErrors {
      field
      message
      code
    }
    vendor {
      id
      name
      isActive
    }
  }
}
    `;
export type TerminateVendorSubscriptionMutationFn = Apollo.MutationFunction<TerminateVendorSubscriptionMutation, TerminateVendorSubscriptionMutationVariables>;

/**
 * __useTerminateVendorSubscriptionMutation__
 *
 * To run a mutation, you first call `useTerminateVendorSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminateVendorSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminateVendorSubscriptionMutation, { data, loading, error }] = useTerminateVendorSubscriptionMutation({
 *   variables: {
 *      vendor: // value for 'vendor'
 *   },
 * });
 */
export function useTerminateVendorSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<TerminateVendorSubscriptionMutation, TerminateVendorSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TerminateVendorSubscriptionMutation, TerminateVendorSubscriptionMutationVariables>(TerminateVendorSubscriptionDocument, options);
      }
export type TerminateVendorSubscriptionMutationHookResult = ReturnType<typeof useTerminateVendorSubscriptionMutation>;
export type TerminateVendorSubscriptionMutationResult = Apollo.MutationResult<TerminateVendorSubscriptionMutation>;
export type TerminateVendorSubscriptionMutationOptions = Apollo.BaseMutationOptions<TerminateVendorSubscriptionMutation, TerminateVendorSubscriptionMutationVariables>;