import { useTranslation } from "@health/i18n";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import { AddIcon } from "../../icons";
import { useAddNewSubscriptionPlansStyle } from "./AddNewSubscriptionPlans.styles";
import { AddNewSubscriptionPlansProps } from "./AddNewSubscriptionPlans.types";

const AddNewSubscriptionPlans: React.FC<AddNewSubscriptionPlansProps> = ({ onClick: handleAddPlan }) => {
  const { classes } = useAddNewSubscriptionPlansStyle();
  const { t } = useTranslation();
  return (
    <Card onClick={handleAddPlan}>
      <Grid container direction='row' alignItems='center' className={classes.newSubscriptionContainer}>
        <Grid item xs className={classes.addContainer}>
          <AddIcon className={classes.addIcon} />
          <Typography align='center' className={classes.addText}>
            {t("Add New")}
          </Typography>
          <Typography align='center' className={classes.addText}>
            {t("Subscription Plan")}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default AddNewSubscriptionPlans;
