import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button, ButtonProps, Fade, Menu, MenuItem, Typography } from "@mui/material";
import { WithStyles, withStyles } from "@mui/styles";
import classnames from "classnames";
import React, { FC } from "react";
import { dropdownStyles } from "./styles";
import { ColorsEnum } from "./util";

type ItemType = {
  id: string;
  label: string;
  /**
   * You can ignore this and use onOptionClick.
   */
  onClick?: (item: ItemType | any) => void;
};
type DropDownProps = WithStyles<typeof dropdownStyles> &
  ButtonProps & {
    items: ItemType[];
    title: string;
    /**
     * You can override this with onClick in items props.
     */
    onOptionClick?: (item: ItemType) => void;
  };

const CustomDropdownButton: FC<DropDownProps> = ({
  classes,
  color = "primary",
  items,
  title,
  onOptionClick: handleOnOptionClick,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleItemClick = item => (item.onClick ? item.onClick(item) : handleOnOptionClick(item));
  return (
    <>
      <Button
        aria-haspopup='true'
        aria-controls='simple-menu'
        {...props}
        color={color}
        variant='contained'
        className={anchorEl ? `${classes.button} ${classes.buttonWithOpen}` : classes.button}
        endIcon={<ArrowDropDownIcon />}
        onClick={handleClick}
      >
        <Typography className={classes.buttonLabel}>{title}</Typography>
      </Button>
      <Menu
        elevation={0}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          list: classnames({
            [classes.menuPrimary]: color === ColorsEnum.Primary,
            [classes.menuError]: color === ColorsEnum.Error,
            [classes.menuSuccess]: color === ColorsEnum.Success,
            [classes.menuInfo]: color === ColorsEnum.Info,
            [classes.menuWarning]: color === ColorsEnum.Warning,
          }),
          paper: anchorEl ? `${classes.paper} ${classes.list}` : classes.paper,
        }}
        TransitionComponent={Fade}
      >
        {items?.map(item => (
          <MenuItem key={item?.id} onClick={handleItemClick.bind(null, item)}>
            {item?.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

CustomDropdownButton.defaultProps = {
  onOptionClick: () => {},
};

export default withStyles(dropdownStyles)(CustomDropdownButton);
