import { SvgIcon, SvgIconProps } from "@mui/material";
import React, { FC } from "react";

export const LanguageGlobeIcon: FC<SvgIconProps> = ({ width, height, viewBox, ...props }) => {
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "24.042"}
      height={height ? height : "24.042"}
      viewBox={viewBox ? viewBox : "0 0 24.042 24.042"}
      {...props}
    >
      <path
        id='icons8-globe'
        d='M13.941,1.92A12.021,12.021,0,1,0,25.962,13.941,12.029,12.029,0,0,0,13.941,1.92Zm-.521,1.1V8.2A20.948,20.948,0,0,1,9.639,7.77a12.975,12.975,0,0,1,.7-1.728C11.2,4.32,12.294,3.272,13.42,3.023Zm1.042,0c1.126.249,2.218,1.3,3.079,3.019a12.984,12.984,0,0,1,.7,1.734,21.113,21.113,0,0,1-3.783.42Zm-3.556.366a8.971,8.971,0,0,0-1.5,2.187,14.2,14.2,0,0,0-.786,1.952A14.5,14.5,0,0,1,5.877,6.5,10.953,10.953,0,0,1,10.905,3.389Zm6.071,0A10.952,10.952,0,0,1,22,6.5a13.585,13.585,0,0,1-2.738,1.038,14.2,14.2,0,0,0-.789-1.96A8.971,8.971,0,0,0,16.976,3.389Zm5.706,3.924a10.913,10.913,0,0,1,2.225,6.108H20.2a21.476,21.476,0,0,0-.644-4.879A14.2,14.2,0,0,0,22.682,7.312ZM5.193,7.321A15.166,15.166,0,0,0,8.33,8.532a21.471,21.471,0,0,0-.646,4.888H2.975A10.913,10.913,0,0,1,5.193,7.321ZM9.345,8.777a21.962,21.962,0,0,0,4.075.462V13.42H8.725A20.5,20.5,0,0,1,9.345,8.777Zm9.194.007a20.508,20.508,0,0,1,.617,4.636H14.462V9.239A22.127,22.127,0,0,0,18.539,8.784ZM2.975,14.462H7.684a21.476,21.476,0,0,0,.644,4.879A14.2,14.2,0,0,0,5.2,20.569,10.913,10.913,0,0,1,2.975,14.462Zm5.751,0H13.42v4.181a22.127,22.127,0,0,0-4.077.455A20.508,20.508,0,0,1,8.725,14.462Zm5.736,0h4.695a20.5,20.5,0,0,1-.62,4.643,21.962,21.962,0,0,0-4.075-.462Zm5.736,0h4.709a10.913,10.913,0,0,1-2.219,6.1,15.166,15.166,0,0,0-3.136-1.212A21.471,21.471,0,0,0,20.2,14.462ZM13.42,19.685v5.174c-1.126-.249-2.218-1.3-3.079-3.019a12.983,12.983,0,0,1-.7-1.734A21.113,21.113,0,0,1,13.42,19.685Zm1.042,0a20.948,20.948,0,0,1,3.781.426,12.976,12.976,0,0,1-.7,1.728c-.861,1.722-1.953,2.77-3.079,3.019Zm-5.842.66a14.2,14.2,0,0,0,.789,1.96,8.971,8.971,0,0,0,1.5,2.187,10.952,10.952,0,0,1-5.024-3.11A13.585,13.585,0,0,1,8.62,20.346Zm10.64.008A14.5,14.5,0,0,1,22,21.379a10.953,10.953,0,0,1-5.028,3.114,8.971,8.971,0,0,0,1.5-2.187A14.2,14.2,0,0,0,19.259,20.354Z'
        transform='translate(-1.92 -1.92)'
        fill={props.fill ? props.fill : "#1b2346"}
      />
    </SvgIcon>
  );
};
