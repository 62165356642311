import { useTranslation } from "@health/i18n";
import { Address } from "@health/queries";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  EditIcon,
  IconButton,
  ModalCloseIcon,
  Typography,
  useIsMobileView,
} from "@health/ui";
import React, { FC } from "react";
import { FormProvider } from "react-hook-form";
import { FormInputs } from "../FormInputs";
import { useAddressesFormDialogHooks } from "./AddressesFormDialog.hook";
import { useAddressFormHooks } from "./AddressForm.hooks";
import { useAddressFormDialogStyles } from "./addressFormDialog.styles";
import { AddressesFormDialogProps } from "./types";

export const AddressFormDialog: FC<AddressesFormDialogProps> = props => {
  const { t } = useTranslation();
  const { classes } = useAddressFormDialogStyles();
  const isMobileView = useIsMobileView();
  const { isOpened, handleOpenDialog, handleCancelDialog, handleSubmit } = useAddressesFormDialogHooks(props);
  const useAddressFormHooksReturn = useAddressFormHooks({
    address: props.address as Address,
    onCancelAddress: handleCancelDialog,
    onSubmitAddress: handleSubmit,
  });

  const { isDefault, methods } = useAddressFormHooksReturn;
  const { handleIsDefaultAddressChange, handleLocationChange, handleFormSubmit, handleCancel } = useAddressFormHooksReturn;

  return (
    <React.Fragment>
      <Button
        fullWidth
        variant='outlined'
        onClick={handleOpenDialog}
        disabled={!props.address?.id}
        startIcon={isMobileView ? null : <EditIcon sx={{ "&:hover": { color: "white !important" } }} />}
        sx={{ height: "49px" }}
        data-testid='openAddressDialogButton'
      >
        {isMobileView ? <EditIcon sx={{ "&:hover": { color: "white !important" } }} /> : t("Address")}
      </Button>

      <Dialog
        maxWidth={"md"}
        fullWidth
        PaperProps={{
          style: { borderRadius: isMobileView ? "unset" : "15px", overflow: !isMobileView ? "auto" : "scroll" },
        }}
        open={isOpened}
        disableEscapeKeyDown
        onBackdropClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onClose={handleCancel}
        scroll={"paper"}
        keepMounted={false}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        fullScreen={isMobileView}
      >
        <DialogTitle className={classes.header} id='scroll-dialog-title'>
          <Typography fontSize={"16px"} fontFamily={"Airbnb Cereal App Medium, Arab Kufi Medium"}>
            {t("Select Area")}
          </Typography>
          <IconButton onClick={handleCancel} sx={{ width: 40 }}>
            <ModalCloseIcon sx={{ width: 10, height: 10 }} />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ padding: 0, width: "100%" }}>
          <form onSubmit={methods.handleSubmit(handleFormSubmit)} id='address-custom-form'>
            <FormProvider {...methods}>
              <FormInputs
                address={props.address}
                isDefault={isDefault}
                onIsDefaultAddress={handleIsDefaultAddressChange}
                onLocationChange={handleLocationChange}
              />
            </FormProvider>
          </form>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button type='submit' form='address-custom-form' data-testid='addressFormDialogApplyButton'>
            {t("Apply")}
          </Button>
          <Button variant='outlined' onClick={handleCancel} data-testid='addressFormDialogCancelButton'>
            {t("Cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
