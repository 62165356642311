import { makeStyles } from "tss-react/mui";
import { white } from "../../customization/colors";

export const useButtonWithIconStyles = makeStyles()(theme => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2),
      borderRadius: "15px",
      flex: 1,
      cursor: "pointer",
      justifyContent: "space-evenly",
      alignItems: "center",
      border: "2px solid #1B2346",
      height: "170px",
      width: "100%",
      backgroundColor: white,
      [theme.breakpoints.up("sm")]: {
        width: "215px",
      },
    },
  };
});
