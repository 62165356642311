import { makeStyles } from "tss-react/mui";
import { boxShadowCard, white } from "../../customization";

export const usePharmacyProfileStyles = makeStyles()({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    height: "280px",
    boxShadow: "none",
  },
  imageContainer: {
    width: "130px",
    height: "130px",
    borderRadius: 10,
    backgroundColor: white,
    marginTop: "-65px",
    boxShadow: boxShadowCard,
  },
  coverContainer: { borderRadius: "15px", width: "100%", height: "200px", backgroundSize: "stretch" },
  action: { margin: "10px 0" },
});
