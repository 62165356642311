/* eslint-disable react/require-default-props */
import { useTranslation } from "@health/i18n";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React, { FC, useState } from "react";
import { white } from "../../customization/colors";
import { CustomIcon } from "../CustomIcon";
import { useAlertStyle } from "./styles";
import { mapAppearanceToCardColor, mapAppearanceToCardTitle, mapAppearanceToSelectedIcon } from "./util";

type CustomAlertProps = {
  level: "module" | "system";
  appearance?: "error" | "success" | "warning";
  description?: string;
  title?: string;
  disableClose?: boolean;
};
const CustomAlert: FC<CustomAlertProps> = ({ description, level, appearance, children, title, disableClose }) => {
  const [toggle, setToggle] = useState(true);
  const handleOnClick = () => {
    setToggle(!toggle);
  };
  const { t } = useTranslation();
  const { classes } = useAlertStyle();
  return (
    <>
      {toggle && (
        <Box
          sx={{
            maxWidth: "100%",
            height: 80,
            backgroundColor: mapAppearanceToCardColor(appearance),
            marginBottom: "15px",
          }}
          display={"flex"}
          borderRadius={level === "module" ? "10px" : 0}
        >
          <Box paddingLeft={level === "module" ? "15px" : "135px"} paddingTop='22px'>
            <CustomIcon icon={mapAppearanceToSelectedIcon(appearance)} width={35} height={35} viewBox='0 0 37 37' color={white} />
          </Box>
          <Box className={classes.alertTextContainer}>
            <Box className={classes.alertText}>
              <Box className={classes.alertTitle}>{title || mapAppearanceToCardTitle(appearance, t)}</Box>
              <Box className={classes.alertTextContent}>
                <Typography className={classes.alertDescription}>{(description?.length && description) || children}</Typography>
              </Box>
            </Box>
            <Box className={classes.xButton}>
              {!disableClose && (
                <Button variant='text' onClick={handleOnClick}>
                  <CustomIcon icon={"xAlert"} color={white} />
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default CustomAlert;
