/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type ImageFragment = { __typename: 'ProductImage', alt: string, url: string, id: string };

export const ImageFragmentDoc = gql`
    fragment image on ProductImage {
  alt
  url
  id
  __typename
}
    `;