/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type PermissionGroupFragmentFragment = { __typename?: 'Group', id: string, name: string, users?: Array<{ __typename?: 'User', id: string, email: string, firstName?: string | null, lastName?: string | null } | null> | null, permissions?: Array<{ __typename?: 'Permission', code: Types.PermissionEnum, name: string } | null> | null };

export const PermissionGroupFragmentFragmentDoc = gql`
    fragment PermissionGroupFragment on Group {
  id
  name
  users {
    id
    email
    firstName
    lastName
  }
  permissions {
    code
    name
  }
}
    `;