/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
import { toPascalCase } from "@health/common";
import { useTranslation } from "@health/i18n";
import { Type, useGetCodeSystemConceptQuery, useGetEditCodeSystemQuery, useUpdateCodeSystemConceptMutation } from "@health/queries";
import { breadcrumbTitleVar, FieldsTypes, useAddToast } from "@health/ui";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

export const useMedListEditHooks = () => {
  const { codeSystemConceptId, id, code } = useParams();

  const { t } = useTranslation("Admin");
  const navigate = useNavigate();

  const { handleSubmit, register, reset, setValue, formState, control } = useForm<FormData>();
  useEffect(() => {
    breadcrumbTitleVar(`${t("edit")} ${code?.replaceAll("_", " ").toLowerCase()}`);
  }, [code, id, codeSystemConceptId]);
  const { failed, succeeded } = useAddToast();
  const { data: getCodeSystemConceptResponse, loading: loadingGetCodeSystemConceptResponse } = useGetCodeSystemConceptQuery({
    variables: {
      id: codeSystemConceptId as string,
    },
  });

  const CodeTextName = toPascalCase(code?.replaceAll("_", " "));
  const getCodeSystemConcept = getCodeSystemConceptResponse?.getCodeSystemConcept;

  const { data: GetCodeSystemResponse } = useGetEditCodeSystemQuery({
    variables: {
      id: id as string,
    },
  });

  const { codeSystemConceptFields } = GetCodeSystemResponse?.getCodeSystem?.codeSystemDefinition || {};
  const getInputType = (item: any) => {
    let type = "text";
    switch (item?.fieldType) {
      case Type.String:
        type = "text";
        break;
      case Type.Boolean:
        type = "text";
        break;
      case Type.Float:
        type = "number";
        break;
      case Type.Integer:
        type = "number";
        break;
      case Type.Date:
        type = "date";
        break;
      default:
        type = "text";
    }
    return type;
  };

  const getDefaultValue = (item: any) => {
    const itemData = getCodeSystemConcept?.properties?.find(row => row?.code == item?.code);

    let value;
    switch (item?.fieldType) {
      case Type.String:
        value = itemData?.valueString;
        break;
      case Type.Boolean:
        value = itemData?.valueBoolean ? "Yes" : "No";
        break;
      case Type.Float:
        value = itemData?.valueFloat;
        break;
      case Type.Integer:
        value = itemData?.valueInteger;
        break;
      case Type.Date:
        value = itemData?.valueDate;
        break;
      default:
        value = null;
    }
    return value;
  };
  const fields = codeSystemConceptFields?.map(item => ({
    id: item?.code,
    grid: 4,
    name: item?.code,
    type:
      item?.fieldType == Type.String || item?.fieldType == Type.Boolean
        ? FieldsTypes.autocomplete
        : item?.fieldType === Type.Date
        ? FieldsTypes.date
        : FieldsTypes.input,
    isFloat: item?.fieldType === Type.Float,
    fieldProps: {
      placeholder: item?.display,
      label: item?.isMandatory ? item?.display : item?.display + t(" - (optional)"),
      name: item?.code,
      type: getInputType(item),
      options:
        item?.fieldType != Type.Boolean
          ? []
          : [
              {
                label: "Yes",
                id: item.display + "-" + "Yes",
              },
              {
                label: "No",
                id: item.display + "-" + "No",
              },
            ],
      disableClearable: item?.fieldType != Type.Boolean,
      freeSolo: item?.fieldType != Type.Boolean,
      defaultValue: getDefaultValue(item),
      isMandatory: item?.isMandatory,
    },
  }));
  useEffect(() => {
    fields?.length &&
      fields.forEach(f => {
        f?.fieldProps?.name && setValue<any>(f?.fieldProps?.name, f?.fieldProps?.defaultValue);
      });
  }, [fields]);
  const [updateCodeSystemConcept] = useUpdateCodeSystemConceptMutation({
    onCompleted: () => {
      succeeded(t("You have updated the data successfully"));
      navigate(-1);
    },
    onError: ({ message }) => {
      failed(message);
    },
  });

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const onSubmit = data => {
    let errorMessage = "";
    const updatedData = codeSystemConceptFields?.map(item => {
      const value = {
        code: item?.code,
        fieldType: item?.fieldType,
        valueBoolean: false,
        valueDate: null,
        valueFloat: null,
        valueInteger: null,
        valueString: "",
      };

      const propertyValue = data[item?.code ?? ""];

      const currentProperty = getCodeSystemConcept?.properties?.find(prop => prop?.code == item?.code);

      if (
        item?.isMandatory &&
        (propertyValue == null || propertyValue == "") &&
        ((item.fieldType == Type.String && !currentProperty?.valueDate) ||
          (item.fieldType == Type.Float && !currentProperty?.valueFloat) ||
          (item.fieldType == Type.Integer && !currentProperty?.valueInteger) ||
          (item.fieldType == Type.String && !currentProperty?.valueString) ||
          (item.fieldType == Type.Boolean && typeof currentProperty?.valueBoolean != "boolean"))
      ) {
        errorMessage = `${item?.display} is required`;
      }

      switch (item?.fieldType) {
        case Type.String:
          value.valueString = propertyValue;
          break;
        case Type.Boolean:
          value.valueBoolean = propertyValue?.toLowerCase() == "yes";
          break;
        case Type.Float:
          value.valueFloat = propertyValue;
          break;
        case Type.Integer:
          value.valueInteger = propertyValue;
          break;
        case Type.Date:
          value.valueDate = propertyValue;
          break;
        default:
          value;
      }

      return value;
    });
    if (errorMessage) {
      return failed(errorMessage);
    }
    const updatedValues = { ...getCodeSystemConcept };
    updatedValues.properties = updatedData;
    delete updatedValues.__typename;
    delete updatedValues.display;
    updateCodeSystemConcept({
      variables: {
        codeSystemConcept: {
          ...updatedValues,
          properties: updatedValues?.properties?.map(item => ({
            ...item,
            valueString: item?.valueString === "" ? null : item?.valueString,
            valueInteger: (item?.valueInteger as any) === "" ? null : item?.valueInteger,
            valueFloat: (item?.valueFloat as any) === "" ? null : item?.valueFloat,
            valueBoolean: (item?.valueBoolean as any) === "" ? null : item?.valueBoolean,
            valueDate: (item?.valueDate as any) === "" ? null : item?.valueDate,
          })),
        },
      },
    });
  };

  const handleFormReset = () => {
    reset();
  };
  const isChanged = formState?.isDirty;
  return {
    fields,
    handleFormReset,
    register,
    control,
    isChanged,
    handleSubmit,
    onSubmit,
    loadingGetCodeSystemConceptResponse,
    CodeTextName,
  };
};
