import { darkGrey, makeStyles } from "@health/ui";

export const useStyles = makeStyles()(() => ({
  autocomplete: {
    "& input::placeholder": {
      color: darkGrey,
      opacity: 1,
      fontWeight: 100,
      fontSize: "14px",
    },
  },
}));
