/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { ProductTypeFragmentFragmentDoc } from '../../../../fragment/productTypes/__generated__/productTypes';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductTypeDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type ProductTypeDeleteMutation = { __typename?: 'Mutation', productTypeDelete?: { __typename?: 'ProductTypeDelete', productType?: { __typename?: 'ProductType', id: string, name?: string | null, slug: string, productAttributes?: Array<{ __typename?: 'Attribute', name?: string | null, inputType?: Types.AttributeInputTypeEnum | null, idAttributes: string, values?: Array<{ __typename?: 'AttributeValue', id: string, name?: string | null } | null> | null } | null> | null, variantAttributes?: Array<{ __typename?: 'Attribute', name?: string | null, id: string } | null> | null } | null, productErrors: Array<{ __typename?: 'ProductError', field?: string | null, message?: string | null }> } | null };


export const ProductTypeDeleteDocument = gql`
    mutation productTypeDelete($id: ID!) {
  productTypeDelete(id: $id) {
    productType {
      ...ProductTypeFragment
    }
    productErrors {
      field
      message
    }
  }
}
    ${ProductTypeFragmentFragmentDoc}`;
export type ProductTypeDeleteMutationFn = Apollo.MutationFunction<ProductTypeDeleteMutation, ProductTypeDeleteMutationVariables>;

/**
 * __useProductTypeDeleteMutation__
 *
 * To run a mutation, you first call `useProductTypeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductTypeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productTypeDeleteMutation, { data, loading, error }] = useProductTypeDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductTypeDeleteMutation(baseOptions?: Apollo.MutationHookOptions<ProductTypeDeleteMutation, ProductTypeDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductTypeDeleteMutation, ProductTypeDeleteMutationVariables>(ProductTypeDeleteDocument, options);
      }
export type ProductTypeDeleteMutationHookResult = ReturnType<typeof useProductTypeDeleteMutation>;
export type ProductTypeDeleteMutationResult = Apollo.MutationResult<ProductTypeDeleteMutation>;
export type ProductTypeDeleteMutationOptions = Apollo.BaseMutationOptions<ProductTypeDeleteMutation, ProductTypeDeleteMutationVariables>;