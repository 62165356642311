import { useTranslation } from "@health/i18n";
import { Attribute, ProductVariant } from "@health/queries";
import { AutocompleteController, FormCard, Grid, Typography } from "@health/ui";
import React, { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { formGirdBreakPoints, formGirdSpacing } from "shared/constants";
import { VariantFormData, VariantFormSubmitData } from "../type";
export interface VariantInformationType {
  variant?: ProductVariant | null;
  variantAttributes: Attribute[];
  isEdit?: boolean;
  loading?: boolean;
  defaultValue?: VariantFormSubmitData["attributes"];
}

const VariantInformation = ({ defaultValue, loading, isEdit, variantAttributes }: VariantInformationType) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors: error },
  } = useFormContext<VariantFormData>();

  const { fields, replace } = useFieldArray<VariantFormData, "variants">({
    control: control,
    name: "variants",
  });

  useEffect(() => {
    if (!defaultValue) return;
    replace(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (isEdit || !variantAttributes?.length) return;
    replace(
      variantAttributes?.map(attr => ({
        attributeId: attr?.id! as string,
        name: attr?.name! as string,
        values: attr?.values! as [],
      }))
    );
  }, [variantAttributes]);

  return (
    <>
      <FormCard title={t("Variant")} doYouHaveData={!loading} loading={loading}>
        <Grid container>
          {!variantAttributes?.length ? (
            <Typography>{t("No Variant")}</Typography>
          ) : (
            fields?.map((variant, index) => {
              const fieldName = `variants.${index}.value`;
              return (
                <Grid item margin={1} {...formGirdBreakPoints} spacing={formGirdSpacing} key={variant?.id}>
                  <AutocompleteController
                    ControllerProps={{
                      name: fieldName,
                      control: control,
                      // defaultValue: variant?.value,
                    }}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    options={variant?.values! || []}
                    TextFieldProps={{
                      placeholder: variant?.name || t("Variant"),
                      error: Boolean(error?.[fieldName]?.message),
                      helperText: error?.[fieldName]?.message,
                    }}
                    filterSelectedOptions
                    hasMore={false}
                    getOptionLabel={option => option.name}
                  />
                </Grid>
              );
            })
          )}
        </Grid>
      </FormCard>
    </>
  );
};

export default VariantInformation;
VariantInformation.defaultProps = {
  isEdit: true,
  loading: true,
  variant: undefined,
  defaultValue: undefined,
};
