/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../fragment/PageInfo/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetVendorProductBrandsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.ProductBrandFilterInput>;
}>;


export type GetVendorProductBrandsQuery = { __typename?: 'Query', productBrands?: { __typename?: 'ProductBrandCountableConnection', pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'ProductBrandCountableEdge', node: { __typename?: 'ProductBrand', id: string, name: string } }> } | null };


export const GetVendorProductBrandsDocument = gql`
    query getVendorProductBrands($first: Int, $last: Int, $after: String, $before: String, $filter: ProductBrandFilterInput) {
  productBrands(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        id
        name
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useGetVendorProductBrandsQuery__
 *
 * To run a query within a React component, call `useGetVendorProductBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorProductBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorProductBrandsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetVendorProductBrandsQuery(baseOptions?: Apollo.QueryHookOptions<GetVendorProductBrandsQuery, GetVendorProductBrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVendorProductBrandsQuery, GetVendorProductBrandsQueryVariables>(GetVendorProductBrandsDocument, options);
      }
export function useGetVendorProductBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVendorProductBrandsQuery, GetVendorProductBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVendorProductBrandsQuery, GetVendorProductBrandsQueryVariables>(GetVendorProductBrandsDocument, options);
        }
export type GetVendorProductBrandsQueryHookResult = ReturnType<typeof useGetVendorProductBrandsQuery>;
export type GetVendorProductBrandsLazyQueryHookResult = ReturnType<typeof useGetVendorProductBrandsLazyQuery>;
export type GetVendorProductBrandsQueryResult = Apollo.QueryResult<GetVendorProductBrandsQuery, GetVendorProductBrandsQueryVariables>;