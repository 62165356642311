import { useTranslation } from "@health/i18n";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button, Dialog, Typography } from "@mui/material";
import React, { createContext, FC, useMemo, useState } from "react";
import { darkRed } from "../../customization";
import { CustomMuiDialogActions, CustomMuiDialogContent, CustomMuiDialogTitle } from "../CustomMuiDialog";
import { SortableList } from "./components/SortableList/SortableList.components";
import { StyledIconButton } from "./TablePreferencesDialog.style";
import { TablePreferencesDialogProps } from "./TablePreferencesDialog.types";

export const TablePreferencesContext = createContext({ columns: undefined, setColumns: undefined });

export const TablePreferencesDialog: FC<TablePreferencesDialogProps> = ({ columnsPreferences, onApplyPreferences: onSubmit }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState<string>();
  const [columns, setColumns] = useState(columnsPreferences);
  const value = useMemo(() => ({ columns, setColumns }), [columns]);

  const handleToggle = () => {
    setError("");
    setColumns(columnsPreferences);
    setIsOpen(state => !state);
  };

  const handleCancel = () => {
    handleToggle();
  };

  const handleApplyChanges = () => {
    const isValid = columns?.find(item => !item?.isHidden);
    if (isValid) {
      setError("");
      onSubmit(columns);
      handleToggle();
    } else {
      setError(t("You should at least select on column to display"));
    }
  };

  return (
    <TablePreferencesContext.Provider value={value}>
      <StyledIconButton color='primary' size='medium' onClick={handleToggle}>
        <MoreVertIcon />
      </StyledIconButton>
      <Dialog
        maxWidth={"sm"}
        fullWidth
        open={isOpen}
        PaperProps={{
          style: { borderRadius: "15px" },
        }}
        scroll={"paper"}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <CustomMuiDialogTitle onClose={handleToggle} title={t("Columns")} />
        <CustomMuiDialogContent>
          {columns && <SortableList />}
          <Typography color={darkRed}>{error}</Typography>
        </CustomMuiDialogContent>
        <CustomMuiDialogActions>
          <Button onClick={handleApplyChanges}>{t("Save")}</Button>
          <Button variant='outlined' onClick={handleCancel}>
            {t("Cancel")}
          </Button>
        </CustomMuiDialogActions>
      </Dialog>
    </TablePreferencesContext.Provider>
  );
};
