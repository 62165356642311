import { getTokenFromLocalStorage } from "../authentication";

export const getUserDataFromToken = () => {
  const token = getTokenFromLocalStorage();
  if (!token) return null;
  const permissionsJSONText = atob(token.split(".")[1]);
  return JSON.parse(permissionsJSONText) ?? {};
};
export const getUserPermissions = () => {
  const userData = getUserDataFromToken();
  return userData?.permissions || [];
};

export const isSuperAdmin = () => {
  const userData = getUserDataFromToken();
  return userData?.app_role?.toLowerCase() === "ADMIN".toLowerCase() && userData?.app_type?.toLowerCase() === "ADMIN".toLowerCase();
};

export const hasPermission = (permission: string | { code: string }): boolean => {
  if (isSuperAdmin()) return true;
  const permissionCode: string = Object.prototype.hasOwnProperty.call(permission, "code")
    ? (permission as { code: string }).code
    : (permission as string) || null;

  if (!permissionCode) return;
  return getUserPermissions().some(p => p?.toLowerCase() === permissionCode?.toLowerCase());
};

export const hasAnyPermission = (permissions: Array<string | { code: string }>): boolean => {
  return permissions.some(hasPermission);
};

export const hasAllPermission = (permissions: Array<string | { code: string }>): boolean => {
  return permissions.every(hasPermission);
};
