/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type CategoriesErrorsFragmentFragment = { __typename?: 'ProductError', field?: string | null, message?: string | null, code: Types.ProductErrorCode };

export const CategoriesErrorsFragmentFragmentDoc = gql`
    fragment CategoriesErrorsFragment on ProductError {
  field
  message
  code
}
    `;