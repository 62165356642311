import { toPascalCase } from "@health/common";
import { useTranslation } from "@health/i18n";
import { Box, Button, Typography } from "@mui/material";
import React, { FC } from "react";
import { useActiveFilterStyles } from "./ActiveFilters.styles";
import { ActiveFiltersProps } from "./ActiveFilters.types";

export const mapActiveFiltersForHeader = (activeFilters: Record<string, unknown>, mapper: Record<string, (val: unknown) => any>) =>
  Object.entries(activeFilters)?.reduce(
    (obj, [label, val]) => ({
      ...obj,
      [label]: val ? mapper?.[label]?.(val) ?? val : val,
    }),
    {}
  );

export const ActiveFilters: FC<ActiveFiltersProps> = props => {
  const filters = {};
  const handleOpenFilter = () => {
    props.onOpenFilters?.();
  };

  Object.keys(props?.activeFilters).forEach(k => {
    if (props?.activeFilters[k]) {
      filters[k] = props?.activeFilters[k];
    }
  });
  const { t } = useTranslation();
  const counter = Object.keys(filters)?.length || 0;
  const { classes } = useActiveFilterStyles();
  if (!filters) return null;
  return (
    <Box>
      {filters && (
        <Box className={classes.filters} component='div'>
          {Object.entries(filters)
            .filter(([, value]) => Boolean(value))
            ?.slice(0, 3)
            ?.map(([filter, value]) => {
              return value ? (
                <Typography key={`active-filter-${filter}`} className={classes.filterItem} component='div'>
                  <Typography fontSize={10} className={classes.itemStyle}>
                    {t(toPascalCase(filter?.replace("Icontains", " ")).trim(), {
                      ns: props?.i18nNs,
                    })}
                  </Typography>
                  <Typography fontSize={14} fontFamily='Airbnb Cereal App Medium' className={classes.itemStyle}>
                    {t(value?.toString())}
                  </Typography>
                </Typography>
              ) : null;
            })}
          <>
            {counter > 3 && (
              <Button sx={{ fontFamily: "Airbnb Cereal App Medium, Arab Kufi Medium" }} variant='text' onClick={handleOpenFilter}>
                +{counter - 3} {t("items")}
              </Button>
            )}
          </>
        </Box>
      )}
    </Box>
  );
};
