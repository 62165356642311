/* eslint-disable react/default-props-match-prop-types */
import { default as React, FC, memo } from "react";
import { useCustomImageStyles } from "./CustomImage.styles";
import { CustomImageProps } from "./CustomImage.types";

const CustomImage: FC<CustomImageProps> = ({ src, alt, height, width, fallback, className }) => {
  const { classes } = useCustomImageStyles();
  const handleImageError = e => {
    e.target.onerror = null;
    e.target.src = fallback; // this method will replace the src on the target
  };

  return (
    <picture>
      {/* this component were used due to no way to handle url failure with CardMedia Component */}
      <img
        height={height || "45px"}
        width={width || "45px"}
        className={classes.imageContainer || className}
        alt={alt}
        src={src}
        onError={handleImageError}
      />
    </picture>
  );
};

CustomImage.defaultProps = {
  alt: "",
  fallback: "",
};

export default memo(CustomImage);
