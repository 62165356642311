/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { MessageFragmentFragmentDoc } from '../../../../fragment/chat/__generated__/message';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BranchMessagesQueryVariables = Types.Exact<{
  branchId: Types.Scalars['ID'];
  customerId: Types.Scalars['ID'];
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type BranchMessagesQuery = { __typename?: 'Query', branchMessages?: { __typename: 'Branch', id: string, name: string, nameAr?: string | null, messages?: { __typename?: 'MessageCountableConnection', totalCount?: number | null, edges: Array<{ __typename?: 'MessageCountableEdge', node: { __typename?: 'Message', id: string, content?: string | null, seenDate?: any | null, created: any, sender: { __typename?: 'User', id: string, email: string, avatar?: string | null }, recipient?: { __typename?: 'User', id: string, email: string, avatar?: string | null } | null, branch: { __typename?: 'Branch', id: string, name: string, nameAr?: string | null, vendor: { __typename?: 'Vendor', logo?: string | null } }, order?: { __typename?: 'Order', id: string, customerNote: string, status: Types.OrderStatus, total?: { __typename?: 'TaxedMoney', gross: { __typename?: 'Money', amount: number, currency: string } } | null } | null, attachments?: Array<{ __typename?: 'Attachment', id: string, file?: string | null, contentType: string, sortOrder?: number | null, alt: string } | null> | null } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } | null } | null };


export const BranchMessagesDocument = gql`
    query branchMessages($branchId: ID!, $customerId: ID!, $first: Int, $after: String, $last: Int, $before: String) {
  branchMessages: branch(id: $branchId) {
    __typename
    id
    name
    nameAr
    messages(
      first: $first
      customerId: $customerId
      after: $after
      last: $last
      before: $before
    ) {
      edges {
        node {
          ...MessageFragment
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
}
    ${MessageFragmentFragmentDoc}`;

/**
 * __useBranchMessagesQuery__
 *
 * To run a query within a React component, call `useBranchMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchMessagesQuery({
 *   variables: {
 *      branchId: // value for 'branchId'
 *      customerId: // value for 'customerId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useBranchMessagesQuery(baseOptions: Apollo.QueryHookOptions<BranchMessagesQuery, BranchMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BranchMessagesQuery, BranchMessagesQueryVariables>(BranchMessagesDocument, options);
      }
export function useBranchMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BranchMessagesQuery, BranchMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BranchMessagesQuery, BranchMessagesQueryVariables>(BranchMessagesDocument, options);
        }
export type BranchMessagesQueryHookResult = ReturnType<typeof useBranchMessagesQuery>;
export type BranchMessagesLazyQueryHookResult = ReturnType<typeof useBranchMessagesLazyQuery>;
export type BranchMessagesQueryResult = Apollo.QueryResult<BranchMessagesQuery, BranchMessagesQueryVariables>;