import { combineErrors, patternMobile, required } from "@health/common";
import { useTranslation } from "@health/i18n";
import { Box, Button, EyeOffIcon, IconButton, Show, TextField } from "@health/ui";
import { InputAdornment } from "@mui/material";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { useAccountRegisterHook } from "../AccountRegister.hook";
import { useStyles } from "../styles/useStyles";
import { FormProps } from "./types";

export const RegisterForm: FC<FormProps> = ({ onChangeStep }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { errors: formErrorsData },
    register,
  } = useForm({
    mode: "all",
  });

  const { errors, showPassword, showConfirmPassword, onSubmit, handleClickShowPassword, handleClickShowConfirmPassword } =
    useAccountRegisterHook(onChangeStep);
  const formErrors = combineErrors(formErrorsData, errors);

  const patternPassword = {
    value: /^(?=.*?[A-Z])(?=.*?[^\w\s]).{8,}$/,
    message: t(
      "Password Must Contain: Minimum of 8 characters, At least one uppercase letter, At least one special character (I.e !@#$%&)"
    ),
  };
  return (
    <div className={classes.content}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box m={"5px 0px"}>
          <TextField
            fullWidth
            name='nationalId'
            label={t("National ID")}
            placeholder={t("National ID")}
            error={Boolean(formErrors?.nationalId?.message)}
            helperText={t(formErrors?.nationalId?.message)}
            inputProps={{
              maxLength: 10,
            }}
            {...register("nationalId", {
              required: required(t),
              pattern: {
                value: /^\d{10}$/,
                message: t("National ID must be 10 numeric digits"),
              },
            })}
          />
        </Box>
        <Box m='15px 0px 10px'>
          <TextField
            fullWidth
            name='mobile'
            label={t("Mobile Number")}
            placeholder={t("Mobile Number")}
            inputProps={{
              maxLength: 14,
              className: "mobile-phone-rtl-fix",
            }}
            error={Boolean(formErrors?.mobile?.message)}
            helperText={
              <div
                dangerouslySetInnerHTML={{
                  __html: t(formErrors?.mobile?.message, {
                    phone: "<span class='mobile-phone-rtl-fix'>+966999999999</span>",
                    interpolation: { escapeValue: false },
                  }),
                }}
              />
            }
            {...register("mobile", {
              required: required(t),
              pattern: patternMobile,
            })}
          />
        </Box>
        <Box m='15px 0px 10px'>
          <TextField
            fullWidth
            label={t("Password")}
            name='password'
            type={showPassword ? "text" : "password"}
            placeholder={t("Password")}
            error={Boolean(formErrors?.password?.message)}
            helperText={t(formErrors.password?.message)}
            {...register("password", {
              required: required(t),
              pattern: patternPassword,
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton sx={{ padding: 0 }} onClick={handleClickShowPassword}>
                    {showPassword ? <Show /> : <EyeOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box m='15px 0px 15px'>
          <TextField
            fullWidth
            name='confirmPassword'
            label={t("Confirm Password")}
            type={showConfirmPassword ? "text" : "password"}
            placeholder={t("Confirm Password")}
            error={Boolean(formErrors?.confirmPassword?.message)}
            helperText={t(formErrors.confirmPassword?.message)}
            {...register("confirmPassword", {
              required: required(t),
              pattern: {
                value: /(.{8})/,
                message: t("password must be at least 8 letters length"),
              },
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton sx={{ padding: 0 }} onClick={handleClickShowConfirmPassword}>
                    {showConfirmPassword ? <Show /> : <EyeOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Button fullWidth variant='contained' type='submit' className={classes.button}>
          {t("Register Now!")}
        </Button>
      </form>
    </div>
  );
};
