import { useSiteSettingsQuery } from "@health/queries";
import React, { FC } from "react";
import { SiteSettingsContext } from "./SiteSettingsContext";
import { SiteSettingsProviderProps } from "./types";

export const SiteSettingsProvider: FC<SiteSettingsProviderProps> = ({ children }) => {
  const { data, loading: isLoading } = useSiteSettingsQuery();
  return (
    <SiteSettingsContext.Provider
      value={{
        settings: data?.siteSettings,
        isMarketPlaceEnabled: !!data?.siteSettings?.marketPlaceEnabled,
        isLoading: isLoading,
      }}
    >
      {children}
    </SiteSettingsContext.Provider>
  );
};
