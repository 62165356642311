/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCodeSystemConceptMutationVariables = Types.Exact<{
  codeSystemConcept?: Types.InputMaybe<Types.CodeSystemConceptInput>;
}>;


export type UpdateCodeSystemConceptMutation = { __typename?: 'Mutation', updateCodeSystemConcept?: { __typename?: 'CodeSystemConcept', id?: string | null, code?: string | null, display?: string | null, properties?: Array<{ __typename?: 'CodeSystemConceptProperty', code?: string | null, fieldType?: Types.Type | null, valueDate?: any | null, valueBoolean?: boolean | null, valueFloat?: number | null, valueInteger?: number | null, valueString?: string | null } | null> | null } | null };


export const UpdateCodeSystemConceptDocument = gql`
    mutation updateCodeSystemConcept($codeSystemConcept: CodeSystemConceptInput) {
  updateCodeSystemConcept(codeSystemConcept: $codeSystemConcept) {
    id
    code
    display
    properties {
      code
      fieldType
      valueDate
      valueBoolean
      valueFloat
      valueInteger
      valueString
    }
  }
}
    `;
export type UpdateCodeSystemConceptMutationFn = Apollo.MutationFunction<UpdateCodeSystemConceptMutation, UpdateCodeSystemConceptMutationVariables>;

/**
 * __useUpdateCodeSystemConceptMutation__
 *
 * To run a mutation, you first call `useUpdateCodeSystemConceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCodeSystemConceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCodeSystemConceptMutation, { data, loading, error }] = useUpdateCodeSystemConceptMutation({
 *   variables: {
 *      codeSystemConcept: // value for 'codeSystemConcept'
 *   },
 * });
 */
export function useUpdateCodeSystemConceptMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCodeSystemConceptMutation, UpdateCodeSystemConceptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCodeSystemConceptMutation, UpdateCodeSystemConceptMutationVariables>(UpdateCodeSystemConceptDocument, options);
      }
export type UpdateCodeSystemConceptMutationHookResult = ReturnType<typeof useUpdateCodeSystemConceptMutation>;
export type UpdateCodeSystemConceptMutationResult = Apollo.MutationResult<UpdateCodeSystemConceptMutation>;
export type UpdateCodeSystemConceptMutationOptions = Apollo.BaseMutationOptions<UpdateCodeSystemConceptMutation, UpdateCodeSystemConceptMutationVariables>;