import { getMediaLink } from "@health/common";
import { useTranslation } from "@health/i18n";
import { Button, CustomDialog, CustomIcon, FormCard, Grid, IconButton, Typography, useIsMobileView } from "@health/ui";
import React from "react";
import useProductVariantImages from "../hooks/ProductVariantImages.hook";
import { ProductVariantImagesInterface } from "./ProductVariantImages.type";
import { useGeneralInfoStyles } from "./styles";

export const ProductVariantImages = ({ LoadingVariant, product, productVariantCreate, idVariant }: ProductVariantImagesInterface) => {
  const { t } = useTranslation("Admin");
  const { classes } = useGeneralInfoStyles();
  const { data, imageCanAssign, handleVariantImageUnAssign, handleClickOpen, isOpen, result, handleAssignImage } = useProductVariantImages({
    product,
    productVariantCreate,
    idVariant,
  });
  const isMobileView = useIsMobileView();

  return (
    <Grid item xs={12}>
      <FormCard title={t("Choose Photo")} loading={LoadingVariant} doYouHaveData={true}>
        <Typography variant='body1' color={"secondary"}>
          {t("Select a specific variant image from product images")}
        </Typography>
        <Button color={"success"} onClick={handleClickOpen}>
          {t("Choose Photo")}
        </Button>
        <div className={classes.images}>
          {data?.productVariant?.images?.map((image: any) => (
            <div key={image.id}>
              <div>
                <img src={getMediaLink(image?.url)} key={image.id} width={348} height={202} className={classes.imgMargin} />
              </div>
              <IconButton className={classes.IconButtonStyle} size={"small"} onClick={() => handleVariantImageUnAssign(image?.id)}>
                <CustomIcon icon={"delete"} width={20} height={20} viewBox='0 0 24 24' />
              </IconButton>
            </div>
          ))}
        </div>
        <CustomDialog open={isOpen} title={t("Choose Image")} onCloseModal={handleClickOpen}>
          {result?.length ? (
            <>
              <div className={classes.imageApproveStatus}>
                {imageCanAssign?.length ? (
                  imageCanAssign?.map((image: any) => (
                    <img
                      key={image.id}
                      src={getMediaLink(image?.url)}
                      height={isMobileView ? 300 : 175}
                      width={isMobileView ? 548 : 348}
                      alt={String(image?.alt)}
                      onClick={() => handleAssignImage(image?.id)}
                    />
                  ))
                ) : (
                  <span className={classes.chooseImage}> {t("All Images are assigned")}</span>
                )}
              </div>
            </>
          ) : (
            <span className={classes.chooseImage}> {t("Please Add Product Image to be able to choose Image Variant")}</span>
          )}
        </CustomDialog>
      </FormCard>
    </Grid>
  );
};
