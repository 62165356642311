import { useTranslation } from "@health/i18n";
import { Box, Button, CustomIcon, Dialog, HeaderCustomerIcon, IconButton, Typography, WasfatyWithoutPlusLogo, white } from "@health/ui";
import React, { FC } from "react";
import { useDialogConsumerHooks } from "../DialogConsumer.hook";
import { useConsumerEntryDialogStyles } from "./ConsumerEntryDialog.styles";
import { DialogView } from "./DialogView";

interface ConsumerEntryDialogProps {
  isMobile?: boolean;
  isNotAuthenticated?: boolean;
  onRouteView?: () => void;
}

export const ConsumerEntryDialog: FC<ConsumerEntryDialogProps> = props => {
  const { isMobile, isNotAuthenticated, onRouteView: handleRouteView } = props;
  const { classes } = useConsumerEntryDialogStyles({ isMobile });
  const { open, handleOpen, handleClose } = useDialogConsumerHooks();
  const { t } = useTranslation();

  return (
    <>
      {isNotAuthenticated && (
        <Box>
          <Button
            size='large'
            color='info'
            variant='contained'
            onClick={handleOpen}
            sx={{
              padding: 1,
              color: white,
            }}
            data-testid='login-button'
          >
            <HeaderCustomerIcon />
            <Typography paddingLeft='2px'> {t("Sign in")}</Typography>
          </Button>
          {open && (
            <Dialog
              open={open}
              fullScreen={isMobile}
              onClose={handleClose}
              aria-labelledby='login-dialog-title'
              aria-describedby='login-dialog-description'
              data-testid='login-dialog'
              PaperProps={{
                ["data-testid" as any]: "login-dialog",
                sx: {
                  borderRadius: isMobile ? 0 : "15px",
                  boxShadow: "none",
                  margin: "0px",
                  backgroundColor: isMobile ? white : "transparent",
                },
              }}
            >
              {isMobile && (
                <Box display='flex' justifyContent='flex-end' m={1}>
                  <IconButton onClick={handleClose}>
                    <CustomIcon icon='miniClose' viewBox='0 0 22 22' />
                  </IconButton>
                </Box>
              )}

              <div className={classes.container}>
                <div className={classes.layout}>
                  <Box display='flex' justifyContent='center' m='10px 21px 2px'>
                    <WasfatyWithoutPlusLogo height={isMobile ? 96 : 85} width={isMobile ? 147 : 120} />
                  </Box>

                  <DialogView onRouteView={handleRouteView} />
                </div>
              </div>
            </Dialog>
          )}
        </Box>
      )}
    </>
  );
};

ConsumerEntryDialog.defaultProps = {
  isMobile: false,
  isNotAuthenticated: false,
  onRouteView: undefined,
};
