/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { ImageFragmentDoc } from '../../../../fragment/ProductImage/__generated__/image';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VariantQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type VariantQuery = { __typename?: 'Query', productVariant?: { __typename?: 'ProductVariant', trackInventory: boolean, sku: string, name?: string | null, id: string, attributes: Array<{ __typename?: 'SelectedAttribute', attribute: { __typename?: 'Attribute', id: string, name?: string | null, values?: Array<{ __typename?: 'AttributeValue', id: string, name?: string | null } | null> | null }, values: Array<{ __typename?: 'AttributeValue', id: string, name?: string | null } | null> }>, product?: { __typename?: 'Product', description?: string | null, name?: string | null, id: string, benefits?: string | null, howToUse?: string | null, images?: Array<{ __typename: 'ProductImage', alt: string, url: string, id: string } | null> | null, productType: { __typename?: 'ProductType', id: string, hasVariants: boolean, variantAttributes?: Array<{ __typename?: 'Attribute', id: string, slug?: string | null, name?: string | null, valueRequired: boolean, values?: Array<{ __typename?: 'AttributeValue', id: string, name?: string | null, slug?: string | null } | null> | null } | null> | null } } | null, images?: Array<{ __typename?: 'ProductImage', alt: string, url: string, id: string } | null> | null } | null };


export const VariantDocument = gql`
    query Variant($id: ID!) {
  productVariant(id: $id) {
    trackInventory
    attributes {
      attribute {
        id
        name
        values {
          id
          name
        }
      }
      values {
        id
        name
      }
    }
    sku
    name
    id
    product {
      description
      name
      id
      benefits
      howToUse
      images {
        ...image
      }
      description
      name
      id
      benefits
      howToUse
      productType {
        id
        hasVariants
        variantAttributes {
          id
          slug
          name
          valueRequired
          values {
            id
            name
            slug
          }
        }
      }
    }
    images {
      alt
      url
      id
    }
  }
}
    ${ImageFragmentDoc}`;

/**
 * __useVariantQuery__
 *
 * To run a query within a React component, call `useVariantQuery` and pass it any options that fit your needs.
 * When your component renders, `useVariantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVariantQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVariantQuery(baseOptions: Apollo.QueryHookOptions<VariantQuery, VariantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VariantQuery, VariantQueryVariables>(VariantDocument, options);
      }
export function useVariantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VariantQuery, VariantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VariantQuery, VariantQueryVariables>(VariantDocument, options);
        }
export type VariantQueryHookResult = ReturnType<typeof useVariantQuery>;
export type VariantLazyQueryHookResult = ReturnType<typeof useVariantLazyQuery>;
export type VariantQueryResult = Apollo.QueryResult<VariantQuery, VariantQueryVariables>;