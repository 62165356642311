/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { VendorErrorFragmentFragmentDoc } from '../../../../fragment/VendorError/__generated__/vendorErrorFragment';
import { AdminVendorFragmentFragmentDoc } from '../../../../fragment/Vendor/__generated__/adminVendorFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VendorUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.VendorUpdateAdminInput;
}>;


export type VendorUpdateMutation = { __typename?: 'Mutation', vendorUpdate?: { __typename?: 'VendorUpdate', vendorErrors: Array<{ __typename?: 'VendorError', field?: string | null, message?: string | null, code: Types.VendorErrorCode }>, vendor?: { __typename?: 'Vendor', isActive: boolean, id: string, name: string, nameAr?: string | null, description?: string | null, backGroundImage?: string | null, logo?: string | null, ownerName: string, nationalId: string, created: any, type?: Types.VendorType | null, address?: { __typename: 'Address', id: string, name?: string | null, area?: string | null, streetAddress1?: string | null, streetAddress2?: string | null, buildingName?: string | null, buildingNumber?: string | null, phone?: string | null, isDefaultShippingAddress?: boolean | null, region?: string | null, city: { __typename?: 'City', id: string, name: string, nameAr?: string | null }, coordinates?: { __typename?: 'LocationType', lng?: number | null, lat?: number | null } | null, translations?: Array<{ __typename?: 'AddressTranslation', streetAddress1: string, streetAddress2: string, area: string, language: { __typename?: 'LanguageDisplay', code: Types.LanguageCodeEnum, language: string } } | null> | null } | null, bankInfo?: { __typename?: 'VendorBankInfo', bankName: string, accountNumber: string, iban: string, accountName?: string | null } | null } | null } | null };


export const VendorUpdateDocument = gql`
    mutation vendorUpdate($id: ID!, $input: VendorUpdateAdminInput!) {
  vendorUpdate(id: $id, input: $input) {
    vendorErrors {
      ...VendorErrorFragment
    }
    vendor {
      ...AdminVendorFragment
      isActive
    }
  }
}
    ${VendorErrorFragmentFragmentDoc}
${AdminVendorFragmentFragmentDoc}`;
export type VendorUpdateMutationFn = Apollo.MutationFunction<VendorUpdateMutation, VendorUpdateMutationVariables>;

/**
 * __useVendorUpdateMutation__
 *
 * To run a mutation, you first call `useVendorUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVendorUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vendorUpdateMutation, { data, loading, error }] = useVendorUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVendorUpdateMutation(baseOptions?: Apollo.MutationHookOptions<VendorUpdateMutation, VendorUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VendorUpdateMutation, VendorUpdateMutationVariables>(VendorUpdateDocument, options);
      }
export type VendorUpdateMutationHookResult = ReturnType<typeof useVendorUpdateMutation>;
export type VendorUpdateMutationResult = Apollo.MutationResult<VendorUpdateMutation>;
export type VendorUpdateMutationOptions = Apollo.BaseMutationOptions<VendorUpdateMutation, VendorUpdateMutationVariables>;