/* eslint-disable max-lines */
import { getRequiredValidation } from "@health/common";
import { useTranslation } from "@health/i18n";
import { BranchesQueryVariables, useBranchesAutocompleteOptionsQuery } from "@health/queries";
import {
  AutocompleteController,
  ExpandMoreIcon,
  getTranslatedNameField,
  Grid,
  TextField,
  Typography,
  useAutocompleteCustomHook,
} from "@health/ui";
import React, { FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import { formGirdBreakPoints } from "shared/constants";
import { orderTypes } from "../../utils";
import { GeneralInformationProps } from "../types";

export const GeneralInformation: FC<GeneralInformationProps> = () => {
  const { t } = useTranslation("Admin");
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  const [search, setSearch] = useState("");

  const handleBranchSearch = value => {
    setSearch(value);
  };
  const dataAccessorForBranches = "branches";
  const inputsBranches: BranchesQueryVariables = {
    first: 10,
    after: null,
    before: null,
    last: null,
    filter: {
      isActive: true,
      branchGlobalSearch: search,
    },
  };

  const {
    data: branches,
    isLoading: loadingBranches,
    onFetchMoreData: onFetchMoreBranches,
  } = useAutocompleteCustomHook({
    useAutocompleteControllerQuery: useBranchesAutocompleteOptionsQuery,
    input: inputsBranches,
    dataAccessor: dataAccessorForBranches,
  });

  const pageInfoForBranches = branches?.[dataAccessorForBranches]?.pageInfo;
  const hasMoreForInfoForBranches = pageInfoForBranches?.hasNextPage;
  const pharmacyBranches = branches?.[dataAccessorForBranches]?.edges?.map(item => item?.node)!;

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item {...formGirdBreakPoints}>
          <TextField
            label={t("eRx Number")}
            placeholder={t("eRx Number")}
            fullWidth
            error={Boolean(errors?.referenceNumber?.message)}
            helperText={errors?.referenceNumber?.message}
            {...register(`referenceNumber`, {
              required: getRequiredValidation(t, true),
            })}
          />
        </Grid>
        <Grid item {...formGirdBreakPoints}>
          <TextField
            label={t("National ID")}
            placeholder={t("National ID")}
            fullWidth
            error={Boolean(errors?.nationalId?.message)}
            helperText={errors?.nationalId?.message}
            {...register(`nationalId`, {
              required: getRequiredValidation(t, true),
              pattern: {
                value: /^\d{10}$/,
                message: t("National ID must be 10 numeric Value"),
              },
            })}
          />
        </Grid>
        <Grid item {...formGirdBreakPoints}>
          <TextField
            label={t("Ticket ID")}
            placeholder={t("Ticket ID")}
            fullWidth
            error={Boolean(errors?.csTicketId?.message)}
            helperText={errors?.csTicketId?.message}
            {...register(`csTicketId`, {
              required: getRequiredValidation(t, true),
              pattern: {
                value: /[0-9]/,
                message: t("Ticket ID must be a numeric value"),
              },
            })}
          />
        </Grid>
        <Grid item flexDirection='row' {...formGirdBreakPoints}>
          <Grid>
            <Typography mb={2}>{t("Branch")}</Typography>
          </Grid>
          <AutocompleteController
            sx={{ mt: "15px" }}
            fullWidth
            popupIcon={<ExpandMoreIcon />}
            loading={loadingBranches}
            fetchMoreData={onFetchMoreBranches}
            hasMore={hasMoreForInfoForBranches}
            options={pharmacyBranches || []}
            ControllerProps={{
              name: "branch",
              control: control,
              rules: {
                required: false,
              },
            }}
            onSearchInput={handleBranchSearch}
            getOptionLabel={option => `${getTranslatedNameField(option)} - ${option?.healthLicense ?? ""}`}
            TextFieldProps={{
              placeholder: t("Branch"),
            }}
          />
        </Grid>
        <Grid container spacing={2} item alignItems='center' sx={{ padding: "8px" }}>
          <Grid item flexDirection='row' {...formGirdBreakPoints}>
            <Grid>
              <Typography mb={2}>{t("Order Type")}</Typography>
            </Grid>
            <AutocompleteController
              sx={{ mt: "15px" }}
              fullWidth
              popupIcon={<ExpandMoreIcon />}
              options={orderTypes || []}
              ControllerProps={{
                name: "orderType",
                control: control,
              }}
              getOptionLabel={option => t(option.label)}
              TextFieldProps={{
                placeholder: t("Order Type"),
                error: Boolean(errors?.orderType?.message),
                helperText: errors?.orderType?.message,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
