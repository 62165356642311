import { i18next } from "@health/i18n";
import { WorkingDay } from "./types";

export const DAYS = (t: i18next.TFunction) => ({
  SAT: t("Saturday"),
  SUN: t("Sunday"),
  MON: t("Monday"),
  TUE: t("Tuesday"),
  WED: t("Wednesday"),
  THU: t("Thursday"),
  FRI: t("Friday"),
});

export const defaultWeekWorkingDays: any[] = [
  {
    day: "SAT",
    openTimeRanges: [{ id: "1", openTime: "", closeTime: "" }],
  },
  {
    day: "SUN",
    openTimeRanges: [{ id: "1", openTime: "", closeTime: "" }],
  },
  {
    day: "MON",
    openTimeRanges: [{ id: "1", openTime: "", closeTime: "" }],
  },
  {
    day: "TUE",
    openTimeRanges: [{ id: "1", openTime: "", closeTime: "" }],
  },
  {
    day: "WED",
    openTimeRanges: [{ id: "1", openTime: "", closeTime: "" }],
  },
  {
    day: "THU",
    openTimeRanges: [{ id: "1", openTime: "", closeTime: "" }],
  },
  {
    day: "FRI",
    openTimeRanges: [{ id: "1", openTime: "", closeTime: "" }],
  },
];

export const getDefaultWeekWorkingDays = (): WorkingDay[] => [
  {
    day: "SAT",
    openTimeRanges: [{ openTime: "08:00", closeTime: "17:00" }],
  },
  {
    day: "SUN",
    openTimeRanges: [{ openTime: "08:00", closeTime: "17:00" }],
  },
  {
    day: "MON",
    openTimeRanges: [{ openTime: "08:00", closeTime: "17:00" }],
  },
  {
    day: "TUE",
    openTimeRanges: [{ openTime: "08:00", closeTime: "17:00" }],
  },
  {
    day: "WED",
    openTimeRanges: [{ openTime: "08:00", closeTime: "17:00" }],
  },
  {
    day: "THU",
    openTimeRanges: [{ openTime: "08:00", closeTime: "17:00" }],
  },
  {
    day: "FRI",
    openTimeRanges: [{ openTime: "08:00", closeTime: "17:00" }],
  },
];
