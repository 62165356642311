/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProviderUsersQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type ProviderUsersQuery = { __typename?: 'Query', vendor?: { __typename?: 'Vendor', name: string, nameAr?: string | null, ownerName: string, nationalId: string, description?: string | null, users?: Array<{ __typename?: 'User', id: string, email: string, firstName?: string | null, lastName?: string | null, mobile?: string | null, isStaff?: boolean | null, isSuperuser?: boolean | null, isVendorAdmin?: boolean | null, healthLicenseNumber?: string | null, healthLicenseStartDate?: any | null, healthLicenseEndDate?: any | null, appType?: Types.AppTypes | null, appRole?: Types.AppRoleTypes | null, isActive: boolean } | null> | null } | null };


export const ProviderUsersDocument = gql`
    query providerUsers($id: ID!) {
  vendor(id: $id) {
    name
    nameAr
    ownerName
    nationalId
    description
    users {
      id
      email
      firstName
      lastName
      mobile
      isStaff
      isSuperuser
      isVendorAdmin
      healthLicenseNumber
      healthLicenseStartDate
      healthLicenseEndDate
      appType
      appRole
      isActive
    }
  }
}
    `;

/**
 * __useProviderUsersQuery__
 *
 * To run a query within a React component, call `useProviderUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProviderUsersQuery(baseOptions: Apollo.QueryHookOptions<ProviderUsersQuery, ProviderUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProviderUsersQuery, ProviderUsersQueryVariables>(ProviderUsersDocument, options);
      }
export function useProviderUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProviderUsersQuery, ProviderUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProviderUsersQuery, ProviderUsersQueryVariables>(ProviderUsersDocument, options);
        }
export type ProviderUsersQueryHookResult = ReturnType<typeof useProviderUsersQuery>;
export type ProviderUsersLazyQueryHookResult = ReturnType<typeof useProviderUsersLazyQuery>;
export type ProviderUsersQueryResult = Apollo.QueryResult<ProviderUsersQuery, ProviderUsersQueryVariables>;