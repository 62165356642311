/* eslint-disable */
/* prettier-ignore */
import * as Types from '@health/queries/dist/graphql/types';

import { gql } from '@apollo/client';
export type DecisionPlanFragment = { __typename?: 'DecisionPlan', id?: string | null, code?: string | null, display?: string | null, actionDefinitionFields?: Array<{ __typename?: 'ActionDefinitionField', code?: string | null, display?: string | null, id?: string | null, type?: Types.ActionFieldType | null } | null> | null, fields?: Array<{ __typename?: 'Field', id?: string | null, code?: string | null, display?: string | null, operators?: Array<{ __typename?: 'FieldOperator', id?: string | null, operator?: Types.Operator | null } | null> | null, fieldType?: { __typename?: 'FieldType', id?: string | null, type?: Types.EntityType | null } | null } | null> | null };

export const DecisionPlanFragmentDoc = gql`
    fragment DecisionPlan on DecisionPlan {
  id
  code
  display
  actionDefinitionFields {
    code
    display
    id
    type
  }
  fields: field {
    id
    code
    display
    operators {
      id
      operator
    }
    fieldType {
      id
      type
    }
  }
}
    `;