/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VendorMarkMessagesAsSeenMutationVariables = Types.Exact<{
  branchId: Types.Scalars['String'];
  customerId: Types.Scalars['String'];
}>;


export type VendorMarkMessagesAsSeenMutation = { __typename?: 'Mutation', vendorMarkMessagesAsSeen?: { __typename?: 'VendorMarkMessagesAsSeen', updatedCount?: number | null, chatErrors: Array<{ __typename?: 'ChatError', field?: string | null, message?: string | null, code: Types.ChatErrorCode }> } | null };


export const VendorMarkMessagesAsSeenDocument = gql`
    mutation vendorMarkMessagesAsSeen($branchId: String!, $customerId: String!) {
  vendorMarkMessagesAsSeen(branchId: $branchId, customerId: $customerId) {
    updatedCount
    chatErrors {
      field
      message
      code
    }
  }
}
    `;
export type VendorMarkMessagesAsSeenMutationFn = Apollo.MutationFunction<VendorMarkMessagesAsSeenMutation, VendorMarkMessagesAsSeenMutationVariables>;

/**
 * __useVendorMarkMessagesAsSeenMutation__
 *
 * To run a mutation, you first call `useVendorMarkMessagesAsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVendorMarkMessagesAsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vendorMarkMessagesAsSeenMutation, { data, loading, error }] = useVendorMarkMessagesAsSeenMutation({
 *   variables: {
 *      branchId: // value for 'branchId'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useVendorMarkMessagesAsSeenMutation(baseOptions?: Apollo.MutationHookOptions<VendorMarkMessagesAsSeenMutation, VendorMarkMessagesAsSeenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VendorMarkMessagesAsSeenMutation, VendorMarkMessagesAsSeenMutationVariables>(VendorMarkMessagesAsSeenDocument, options);
      }
export type VendorMarkMessagesAsSeenMutationHookResult = ReturnType<typeof useVendorMarkMessagesAsSeenMutation>;
export type VendorMarkMessagesAsSeenMutationResult = Apollo.MutationResult<VendorMarkMessagesAsSeenMutation>;
export type VendorMarkMessagesAsSeenMutationOptions = Apollo.BaseMutationOptions<VendorMarkMessagesAsSeenMutation, VendorMarkMessagesAsSeenMutationVariables>;