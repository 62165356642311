import { Typography } from "@health/ui";
import React, { FC } from "react";
import { useCardContentStyles } from "./CardContent.styles";

type CardContentProps = {
  title: string;
  subTitle?: string | number | null;
  price?: string | undefined;
};

const CardContent: FC<CardContentProps> = ({ title, subTitle, price }) => {
  const { classes } = useCardContentStyles();

  return (
    <>
      <Typography className={classes.textBold}>{title}</Typography>
      {subTitle && (
        <Typography className={classes.text}>
          {subTitle} <strong> {price}</strong>
        </Typography>
      )}
    </>
  );
};

CardContent.defaultProps = {
  subTitle: "-",
  price: "",
};

export default CardContent;
