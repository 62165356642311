import Dialog from "@mui/material/Dialog";
import React, { FC, useState } from "react";
import { CustomMuiDialogProps } from "./CustomMuiDialog.types";
import { CustomMuiDialogActions } from "./CustomMuiDialogActions.component";
import { CustomMuiDialogContent } from "./CustomMuiDialogContent.component";
import { CustomMuiDialogTitle } from "./CustomMuiDialogTitle.component";

export const CustomMuiDialog: FC<CustomMuiDialogProps> = ({
  button,
  type = "default",
  children,
  DialogTitleProps,
  DialogActionsProps,
  DialogContentProps,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = (e?, r?) => {
    setIsOpen(state => !state);
    props.onClose && props.onClose(e, r);
  };
  return (
    <React.Fragment>
      {React.cloneElement(button, { onClick: button.props.onClick ? button.props.onClick : handleToggle })}
      <Dialog
        maxWidth={"md"}
        fullWidth
        open={isOpen || props.open}
        PaperProps={{
          style: { borderRadius: "15px" },
        }}
        onClose={handleToggle}
        scroll={"paper"}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        {...props}
      >
        {type == "default" && <CustomMuiDialogTitle onClose={handleToggle} {...DialogTitleProps} />}
        <CustomMuiDialogContent {...DialogContentProps}>{children}</CustomMuiDialogContent>
        <CustomMuiDialogActions onClose={handleToggle} {...DialogActionsProps} />
      </Dialog>
    </React.Fragment>
  );
};
