import { i18n, useTranslation } from "@health/i18n";
import { OrderEventsEnum, OrderStatus, PrescriptionDispenseStatus } from "@health/queries";
import {
  Box,
  Card,
  Divider,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  Typography,
} from "@health/ui";
import moment from "moment";
import React, { FC } from "react";
import CardContent from "./CardContent.component";
import DeliveryWorkflow from "./delivery-workflow/delivery-workflow.component";
import { useLifeCycleStyles } from "./LifeCycle.styles";
import { OrdersEventsType } from "./OrdersEvents.types";
import { getTypeEvent, isDeliveryStatus } from "./utils";

type OrdersEventsProps = {
  events?: OrdersEventsType | null;
  dispenseStatus: PrescriptionDispenseStatus;
  status: string;
  failReason: string;
  helpDiskTicketId: string;
  deliveryWorkflowId: string | null | undefined;
};

export const OrdersEvents: FC<OrdersEventsProps> = ({
  events,
  dispenseStatus,
  helpDiskTicketId,
  failReason,
  status,
  deliveryWorkflowId,
}) => {
  const { t } = useTranslation("Admin");
  const { classes, cx } = useLifeCycleStyles({ dispenseStatus });

  return (
    <Timeline sx={{ margin: 0, padding: "0px 16px" }}>
      {events?.map((event, index) => {
        const failedType =
          event.type === OrderEventsEnum.Failed ||
          event.type === OrderEventsEnum.PaymentFailed ||
          event.type === OrderEventsEnum.ErxHubClaimFailed ||
          event.type === OrderEventsEnum.OrderDeliveryFailed ||
          event.type === OrderEventsEnum.ErxHubAuthRequestFailed;
        return (
          <TimelineItem key={String(event?.id!) + index}>
            <TimelineOppositeContent className={classes.content}>
              <Typography className={classes.dateBold}>{moment(event?.date).locale(i18n.language).format("D MMM YYYY")}</Typography>
            </TimelineOppositeContent>

            <TimelineSeparator sx={{ position: "relative" }}>
              <TimelineDot variant='outlined' className={classes.dot} />
              <TimelineConnector className={classes.separator} />
            </TimelineSeparator>

            <TimelineContent className={classes.position}>
              <Card className={cx(classes.card, event?.isParentEvent && classes.parentCard)}>
                <div className={classes.flexColumn}>
                  <div className={classes.flex}>
                    <Typography className={classes.textBold}>{getTypeEvent(event?.type)}</Typography>
                    <Typography className={classes.date}>{moment(event?.date).locale(i18n.language).format("h:mm:ss a")}</Typography>
                  </div>

                  {isDeliveryStatus(event?.type!) && deliveryWorkflowId && (
                    <div className={classes.deliveryWorkflowWrapper}>
                      <DeliveryWorkflow deliveryWorkflowId={deliveryWorkflowId} />
                    </div>
                  )}
                </div>
                <Divider className={classes.line} />

                <Box display='flex' justifyContent='space-between'>
                  <Box>
                    <CardContent title={t("Order Number")} subTitle={"# " + String(event?.number)} />
                  </Box>
                  <Box>
                    <CardContent title={t("Pharmacy")} subTitle={event?.branchName} />
                  </Box>
                </Box>

                {failedType && status === OrderStatus.Failed && event?.isParentEvent && (
                  <Box sx={{ mt: 2 }}>
                    <CardContent title={t("Fail Reason")} subTitle={failReason} />
                  </Box>
                )}
                {helpDiskTicketId && (
                  <Box>
                    <CardContent title={t("Help Disk Ticket Id")} subTitle={helpDiskTicketId} />
                  </Box>
                )}
              </Card>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};

OrdersEvents.defaultProps = {
  events: undefined,
};
