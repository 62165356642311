import { lodashGet } from "@health/common";
import { City } from "@health/queries";
import { AutocompleteComponentProps } from "@health/ui/dist/components/Autocomplete/types";
import React, { FC } from "react";
import { Controller, ControllerProps, UseFormReturn } from "react-hook-form";
import { CitiesAutocompletePlain } from "./CitiesAutocompletePlain";
import { getCityItem } from "./utils";

export const CitiesAutocomplete: FC<{
  control: UseFormReturn["control"];
  cityItem: City | null;
  errors;
  name: string;
  rules?: ControllerProps["rules"];
  autoCompleteProps?: AutocompleteComponentProps;
}> = ({ cityItem, errors, rules, control: passedControl, name, autoCompleteProps = {} }) => {
  return (
    <Controller
      control={passedControl}
      name={name}
      rules={rules}
      defaultValue={getCityItem(cityItem) || null}
      render={({ field }) => (
        <CitiesAutocompletePlain
          defaultValue={getCityItem(cityItem) || null}
          field={field}
          errorMessage={lodashGet(errors, name + ".message")}
          {...autoCompleteProps}
        />
      )}
    />
  );
};

CitiesAutocomplete.defaultProps = {
  rules: undefined,
  autoCompleteProps: {},
};
