/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountReSendOtpCodeMutationVariables = Types.Exact<{
  mobile?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type AccountReSendOtpCodeMutation = { __typename?: 'Mutation', accountReSendOtpCode?: { __typename?: 'AccountReSendOTPCode', sessionToken?: string | null, errors: Array<{ __typename?: 'Error', field?: string | null, message?: string | null }> } | null };


export const AccountReSendOtpCodeDocument = gql`
    mutation accountReSendOtpCode($mobile: String) {
  accountReSendOtpCode(mobile: $mobile) {
    errors {
      field
      message
    }
    sessionToken
  }
}
    `;
export type AccountReSendOtpCodeMutationFn = Apollo.MutationFunction<AccountReSendOtpCodeMutation, AccountReSendOtpCodeMutationVariables>;

/**
 * __useAccountReSendOtpCodeMutation__
 *
 * To run a mutation, you first call `useAccountReSendOtpCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountReSendOtpCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountReSendOtpCodeMutation, { data, loading, error }] = useAccountReSendOtpCodeMutation({
 *   variables: {
 *      mobile: // value for 'mobile'
 *   },
 * });
 */
export function useAccountReSendOtpCodeMutation(baseOptions?: Apollo.MutationHookOptions<AccountReSendOtpCodeMutation, AccountReSendOtpCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AccountReSendOtpCodeMutation, AccountReSendOtpCodeMutationVariables>(AccountReSendOtpCodeDocument, options);
      }
export type AccountReSendOtpCodeMutationHookResult = ReturnType<typeof useAccountReSendOtpCodeMutation>;
export type AccountReSendOtpCodeMutationResult = Apollo.MutationResult<AccountReSendOtpCodeMutation>;
export type AccountReSendOtpCodeMutationOptions = Apollo.BaseMutationOptions<AccountReSendOtpCodeMutation, AccountReSendOtpCodeMutationVariables>;