import DragHandleIcon from "@mui/icons-material/DragHandle";
import { Checkbox } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import React, { FC, useState } from "react";
import { Draggable } from "react-smooth-dnd";
import { SortableItemProps } from "./SortableItem.types";

export const SortableItem: FC<SortableItemProps> = props => {
  const { id, isHidden, header, onChangeVisibility } = props;
  const [hidden, setHidden] = useState<boolean>(isHidden);
  const handleIsHiddenChanged = () => {
    setHidden(state => !state);
    onChangeVisibility?.(id);
  };

  return (
    <Draggable>
      <ListItem>
        <Checkbox defaultChecked={!isHidden} onChange={handleIsHiddenChanged} checked={!hidden} />
        <ListItemText primary={header} />
        <ListItemSecondaryAction sx={{ cursor: "pointer" }}>
          <ListItemIcon className='drag-handle'>
            <DragHandleIcon />
          </ListItemIcon>
        </ListItemSecondaryAction>
      </ListItem>
    </Draggable>
  );
};
