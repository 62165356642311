import { useReactiveVar } from "@apollo/client";
import { openLoginDialog, registerVar } from "./reactiveVar";

export const useDialogConsumerHooks = () => {
  const open = useReactiveVar(openLoginDialog);

  const handleOpen = () => {
    openLoginDialog(true);
  };

  const handleClose = () => {
    openLoginDialog(false);

    registerVar(undefined);
  };

  return {
    open,
    handleOpen,
    handleClose,
  };
};
