import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import React, { FC } from "react";

export interface BlockUiOverlayProps {
  open: boolean;
  size?: number;
  background?: string;
}
const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  overflow: "hidden",
}));
/**
 * blocker component that wrapper another html element and cover it with ui blocker
 */
export const BlockUiOverlay: FC<BlockUiOverlayProps> = ({ open, children, size = 50, background = "rgba(0,0,0,0.3)" }) => {
  if (!open) return <>{children}</>;
  return (
    <FlexBox
      sx={{
        position: "relative",
        zIndex: 998,
      }}
    >
      <Box sx={{ width: "100%" }}>{children}</Box>
      <FlexBox
        sx={{
          background: background ?? "rgba(0,0,0,0.3)",
          position: "absolute",
          zIndex: 999,
        }}
      >
        <CircularProgress size={size} />
      </FlexBox>
    </FlexBox>
  );
};

BlockUiOverlay.defaultProps = {
  background: "rgba(255,255,255, 0.5)",
  size: 100,
};
