import { makeStyles } from "@health/ui";

export const useFormErrorsStyles = makeStyles()(() => ({
  form: {
    textAlign: "center",
  },
  message: {
    margin: "8px",
  },
}));
