import { useTranslation } from "@health/i18n";
import { useSetPasswordMutation } from "@health/queries";
import { useState } from "react";
import { dialogStep } from "./dialog/utils";
import { registerVar } from "./reactiveVar";

export const useConfirmAccountHooks = (onChangeStep, isPage?) => {
  const { t } = useTranslation();

  const [errors, setErrors] = useState<any>([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const state = registerVar();

  const [changePassword] = useSetPasswordMutation({
    onCompleted: data => {
      if (data.setPassword!.accountErrors.length === 0) {
        isPage ? onChangeStep() : onChangeStep(dialogStep.resetSuccess);
        registerVar(undefined);
      } else {
        setErrors(data.setPassword!.accountErrors || []);
      }
    },
  });

  const handleResetError = () => {
    setErrors([]);
  };

  const onSubmit = form => {
    handleResetError();
    if (form?.confirmPassword !== form?.password) {
      setErrors([
        {
          field: "password",
          message: t("Passwords Doesn't Match"),
        },
      ]);
      return;
    }
    changePassword({
      variables: {
        token: state.token,
        username: state.mobile,
        password: form?.password,
      },
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleLogin = () => {
    onChangeStep(dialogStep.login);
  };

  return {
    errors,
    showPassword,
    showConfirmPassword,
    onSubmit,
    handleLogin,
    handleResetError,
    handleClickShowPassword,
    handleClickShowConfirmPassword,
  };
};
