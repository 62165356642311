/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../fragment/PageInfo/__generated__/pageInfoFragment';
import { NotificationFragmentFragmentDoc } from '../../../fragment/notification/__generated__/notification';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MeNotificationsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type MeNotificationsQuery = { __typename?: 'Query', me?: { __typename?: 'User', unReadNotificationsCount?: number | null, notifications?: { __typename?: 'NotificationCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'NotificationCountableEdge', node: { __typename?: 'Notification', id: string, created: any, body?: string | null, category?: Types.NotificationCategory | null, extraData?: string | null, title?: string | null, modified: any, type: Types.NotificationType } }> } | null } | null };


export const MeNotificationsDocument = gql`
    query meNotifications($first: Int, $after: String, $before: String, $last: Int) {
  me {
    unReadNotificationsCount
    notifications(first: $first, after: $after, before: $before, last: $last) {
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...NotificationFragment
        }
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}
${NotificationFragmentFragmentDoc}`;

/**
 * __useMeNotificationsQuery__
 *
 * To run a query within a React component, call `useMeNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeNotificationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useMeNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<MeNotificationsQuery, MeNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeNotificationsQuery, MeNotificationsQueryVariables>(MeNotificationsDocument, options);
      }
export function useMeNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeNotificationsQuery, MeNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeNotificationsQuery, MeNotificationsQueryVariables>(MeNotificationsDocument, options);
        }
export type MeNotificationsQueryHookResult = ReturnType<typeof useMeNotificationsQuery>;
export type MeNotificationsLazyQueryHookResult = ReturnType<typeof useMeNotificationsLazyQuery>;
export type MeNotificationsQueryResult = Apollo.QueryResult<MeNotificationsQuery, MeNotificationsQueryVariables>;