import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import React, { FC } from "react";
import { CustomIcon } from "../CustomIcon";
import { ProductActionProps } from "./type";

export const ProductAction: FC<ProductActionProps> = ({ onAddToCart: handleAddToCart, onLikeProduct: handleLikeProduct, classes }) => {
  return (
    <Box display='flex'>
      <IconButton onClick={handleLikeProduct} className={classes.iconButton}>
        <CustomIcon icon='heartClient' viewBox='0 3 27 27' />
      </IconButton>
      <IconButton onClick={handleAddToCart} className={classes.iconButton}>
        <CustomIcon icon='shopClient' />
      </IconButton>
    </Box>
  );
};
