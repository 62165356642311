import { useTranslation } from "@health/i18n";
import { AccountError, useSignupMutation } from "@health/queries";
import { useState } from "react";
import { dialogStep } from "./dialog/utils";
import { registerVar } from "./reactiveVar";

export const useAccountRegisterHook = onChangeStep => {
  const { t } = useTranslation();

  const [mobile, setMobile] = useState("");
  const [errors, setErrors] = useState<Partial<AccountError>[] | undefined>([]);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [signUp] = useSignupMutation({
    onCompleted: data => {
      const errors = data?.accountRegister?.accountErrors;

      if (errors?.length === 0) {
        registerVar({ mobile, sessionToken: data?.accountRegister?.sessionToken, isForget: false });
        onChangeStep(dialogStep.verify);
      } else {
        setErrors(errors);
      }
    },
  });

  const onSubmit = data => {
    if (data?.confirmPassword !== data?.password) {
      setErrors([
        {
          field: "confirmPassword",
          message: t("Passwords doesn't match"),
        },

        {
          field: "password",
          message: t("Passwords doesn't match"),
        },
      ]);
      return;
    }
    setErrors([]);
    setMobile(data.mobile);
    signUp({
      variables: {
        input: {
          nationalId: data?.nationalId,
          password: data?.password,
          firstName: data.firstName,
          mobile: data.mobile,
          lastName: data.lastName,
        },
      },
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return {
    errors,
    showPassword,
    showConfirmPassword,
    onSubmit,
    handleClickShowPassword,
    handleClickShowConfirmPassword,
  };
};
