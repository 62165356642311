import { LanguageCodeEnum, TicketPrescriptionDispenseInput } from "@health/queries";

export type TicketCustomInput = TicketPrescriptionDispenseInput | any;

export const onSubmitFunction = (data: TicketCustomInput) => {
  const { addressInput, arabicStreetAddress, arabicStreetAddress2, orderType, referenceNumber, nationalId, csTicketId, branch } = data;

  const { city, streetAddress1, area, ...addressInputRest } = addressInput;
  return {
    branchHealthLicenseNumber: branch?.healthLicense,
    csTicketId: csTicketId,
    isDeliveryRequested: orderType?.value === "Delivery",
    referenceNumber: referenceNumber,
    nationalId: nationalId,
    shippingAddress: {
      ...addressInputRest,
      city: city?.id,
      area,
      coordinates: {
        lat: addressInputRest?.coordinates?.lat,
        lng: addressInputRest?.coordinates?.lng,
      },
      translations: [
        {
          languageCode: LanguageCodeEnum.Ar,
          streetAddress2: arabicStreetAddress2,
          area,
          streetAddress1: arabicStreetAddress,
        },
        {
          languageCode: LanguageCodeEnum.En,
          streetAddress1,
          area,
          streetAddress2: addressInput.streetAddress2,
        },
      ],
    },
  };
};

export const orderTypes = [
  { value: "Delivery", label: "Delivery" },
  { value: "Pickup", label: "Pickup" },
];
