/* eslint-disable react/forbid-dom-props */
import { i18next, useTranslation } from "@health/i18n";
import { CloseOutlined } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, IconButton, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { FC } from "react";
import { styles } from "./styles";
import { ShowDialogProps } from "./types";

const ShowDialog: FC<ShowDialogProps> = ({
  isOpen,
  onClickClose: handleClose,
  children,
  headerTitle,
  onClickActionButton: handleAction,
  hasActionButton,
  titleAction,
  colorOfAction,
  classes,
  showHeader = true,
  hasCancelButton = true,
  titleCancel = true,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={isOpen}
      className={classes.dialog}
      PaperProps={{
        elevation: 1,
        style: {
          width: "100%",
        },
      }}
    >
      {showHeader && (
        <div
          style={{
            direction: i18next.default.dir() === "rtl" ? "rtl" : "ltr",
          }}
          className={classes.div}
        >
          <Typography variant={"h6"}>{headerTitle}</Typography>
          <div>
            <IconButton onClick={handleClose} size='small' className={classes.icon}>
              <CloseOutlined />
            </IconButton>
          </div>
        </div>
      )}
      <DialogContent className={classes.header}>{children}</DialogContent>
      <DialogActions>
        {hasActionButton && (
          <Button size={"small"} type={"submit"} onClick={handleAction} color={colorOfAction} className={classes.button}>
            {titleAction}
          </Button>
        )}
        {hasCancelButton && handleClose && (
          <Button color={"warning"} size={"small"} onClick={handleClose} className={classes.cancel}>
            {titleCancel ? t("Cancel", "Cancel") : t("Ok", "Ok")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default withStyles(styles)(ShowDialog);
