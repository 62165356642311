import React from "react";
import { getRequiredValidation } from "./messages";
export const patternMobileMessage = "Phone must be like : {{ phone }}";
export const mobileStartRegex = /^(\+|(00))/;
export const patternMobile = {
  message: patternMobileMessage,
  value: /^(\+|(00))\d{12}$/,
};

export const getPatternMobileErrorMessage = (t, errorMessage) => {
  if (errorMessage === patternMobileMessage) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: t(patternMobileMessage, {
            phone: "<span class='mobile-phone-rtl-fix'>+966599999999</span>",
            interpolation: { escapeValue: false },
          }),
        }}
      />
    );
  } else return t(errorMessage);
};

export const patternEmail = {
  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  message: "Please enter a valid email",
};

export const patternWebsite = {
  value: /https?:\/\/([-a-zA-Z0-9@:%._+~#=]{2,256})/gi,
  // /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  message: "Please enter a valid web url",
};

export const patternArabicText = {
  value: /^[\u0621-\u064A\u0660-\u0669 [0-9]+$/,
  message: "Please Enter Valid Arabic Name",
};

export const patternArabicTextWithChars = {
  value: /^[ء-ي١-٩\0-9._%+-]+$/,
  message: "Please Enter Valid Arabic Name",
};

export const patternInteger = {
  value: /^[0-9\b]+$/,
  message: "Please enter valid integer value",
};

export const required = t => getRequiredValidation(t, true);
