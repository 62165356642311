/* eslint-disable */
/* prettier-ignore */
import * as Types from '@health/queries/dist/graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDecisionsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  decisionName?: Types.InputMaybe<Types.Scalars['String']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  isActive?: Types.InputMaybe<Types.Scalars['Boolean']>;
  isScript?: Types.InputMaybe<Types.Scalars['Boolean']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  sortBy?: Types.InputMaybe<Types.DecisionSortingInput>;
}>;


export type GetDecisionsQuery = { __typename?: 'Query', decisions?: { __typename?: 'DecisionConnection', totalCount?: number | null, edges?: Array<{ __typename?: 'DecisionEdge', node?: { __typename?: 'Decision', id?: string | null, name?: string | null, isActive?: boolean | null, isScript?: boolean | null, decisionPlan?: { __typename?: 'DecisionPlan', code?: string | null, display?: string | null } | null } | null } | null> | null, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } | null } | null };


export const GetDecisionsDocument = gql`
    query getDecisions($after: String, $before: String, $decisionName: String, $first: Int, $isActive: Boolean, $isScript: Boolean, $last: Int, $sortBy: DecisionSortingInput) {
  decisions: getDecisions(
    first: $first
    after: $after
    before: $before
    decisionName: $decisionName
    isActive: $isActive
    isScript: $isScript
    last: $last
    sortBy: $sortBy
  ) {
    edges {
      node {
        id
        name
        isActive
        isScript
        decisionPlan {
          code
          display
        }
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useGetDecisionsQuery__
 *
 * To run a query within a React component, call `useGetDecisionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDecisionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDecisionsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      decisionName: // value for 'decisionName'
 *      first: // value for 'first'
 *      isActive: // value for 'isActive'
 *      isScript: // value for 'isScript'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetDecisionsQuery(baseOptions?: Apollo.QueryHookOptions<GetDecisionsQuery, GetDecisionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDecisionsQuery, GetDecisionsQueryVariables>(GetDecisionsDocument, options);
      }
export function useGetDecisionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDecisionsQuery, GetDecisionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDecisionsQuery, GetDecisionsQueryVariables>(GetDecisionsDocument, options);
        }
export type GetDecisionsQueryHookResult = ReturnType<typeof useGetDecisionsQuery>;
export type GetDecisionsLazyQueryHookResult = ReturnType<typeof useGetDecisionsLazyQuery>;
export type GetDecisionsQueryResult = Apollo.QueryResult<GetDecisionsQuery, GetDecisionsQueryVariables>;