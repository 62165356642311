/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AddressFragmentFragmentDoc } from '../../address/__generated__/address';
import { OrderAddressFragmentFragmentDoc } from '../../address/__generated__/orderAddress';
export type OrderFragmentFragment = { __typename: 'Order', id: string, type?: Types.OrderTypesEnum | null, status: Types.OrderStatus, number?: string | null, created: any, modified: any, deliveryStatus?: string | null, branch?: { __typename?: 'Branch', id: string, name: string, nameAr?: string | null, address?: { __typename: 'Address', id: string, name?: string | null, area?: string | null, streetAddress1?: string | null, streetAddress2?: string | null, buildingName?: string | null, buildingNumber?: string | null, phone?: string | null, isDefaultShippingAddress?: boolean | null, region?: string | null, city: { __typename?: 'City', id: string, name: string, nameAr?: string | null }, coordinates?: { __typename?: 'LocationType', lng?: number | null, lat?: number | null } | null, translations?: Array<{ __typename?: 'AddressTranslation', streetAddress1: string, streetAddress2: string, area: string, language: { __typename?: 'LanguageDisplay', code: Types.LanguageCodeEnum, language: string } } | null> | null } | null } | null, shippingAddress?: { __typename: 'OrderAddress', id: string, name?: string | null, buildingName?: string | null, buildingNumber?: string | null, streetAddress1?: string | null, streetAddress2?: string | null, phone: string, postalCode: string, city?: { __typename: 'City', id: string, name: string, nameAr?: string | null, currency: string, deliveryPriceAmount: number } | null, coordinates?: { __typename: 'LocationType', lng?: number | null, lat?: number | null } | null, translations?: Array<{ __typename?: 'OrderAddressTranslation', streetAddress1: string, streetAddress2: string, language: { __typename?: 'LanguageDisplay', code: Types.LanguageCodeEnum, language: string } } | null> | null } | null };

export const OrderFragmentFragmentDoc = gql`
    fragment OrderFragment on Order {
  __typename
  id
  type
  status
  number
  created
  modified
  deliveryStatus
  branch {
    id
    name
    nameAr
    address {
      ...AddressFragment
    }
  }
  shippingAddress {
    __typename
    ...OrderAddressFragment
  }
}
    ${AddressFragmentFragmentDoc}
${OrderAddressFragmentFragmentDoc}`;