/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductVariantReviewCreateMutationVariables = Types.Exact<{
  input: Types.ProductVariantReviewCreateInput;
}>;


export type ProductVariantReviewCreateMutation = { __typename?: 'Mutation', productVariantReviewCreate?: { __typename?: 'ProductVariantReviewCreate', productErrors: Array<{ __typename?: 'ProductError', field?: string | null, message?: string | null, code: Types.ProductErrorCode }>, productVariantReview?: { __typename?: 'ProductVariantReview', content?: string | null, created: any, helpfulCount: number, id: string, modified: any, published: boolean, rank?: number | null, rating: number, productVariant: { __typename?: 'ProductVariant', id: string, name?: string | null, quantityAvailable: number, sku: string, rating?: number | null, product?: { __typename?: 'Product', id: string, name?: string | null } | null }, createdBy?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null } | null } | null };


export const ProductVariantReviewCreateDocument = gql`
    mutation ProductVariantReviewCreate($input: ProductVariantReviewCreateInput!) {
  productVariantReviewCreate(input: $input) {
    productErrors {
      field
      message
      code
    }
    productVariantReview {
      content
      created
      helpfulCount
      id
      modified
      published
      productVariant {
        id
        name
        product {
          id
          name
        }
        quantityAvailable
        sku
        rating
      }
      rank
      rating
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
}
    `;
export type ProductVariantReviewCreateMutationFn = Apollo.MutationFunction<ProductVariantReviewCreateMutation, ProductVariantReviewCreateMutationVariables>;

/**
 * __useProductVariantReviewCreateMutation__
 *
 * To run a mutation, you first call `useProductVariantReviewCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductVariantReviewCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productVariantReviewCreateMutation, { data, loading, error }] = useProductVariantReviewCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductVariantReviewCreateMutation(baseOptions?: Apollo.MutationHookOptions<ProductVariantReviewCreateMutation, ProductVariantReviewCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductVariantReviewCreateMutation, ProductVariantReviewCreateMutationVariables>(ProductVariantReviewCreateDocument, options);
      }
export type ProductVariantReviewCreateMutationHookResult = ReturnType<typeof useProductVariantReviewCreateMutation>;
export type ProductVariantReviewCreateMutationResult = Apollo.MutationResult<ProductVariantReviewCreateMutation>;
export type ProductVariantReviewCreateMutationOptions = Apollo.BaseMutationOptions<ProductVariantReviewCreateMutation, ProductVariantReviewCreateMutationVariables>;