/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePlanMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  planInput: Types.PlanInput;
}>;


export type UpdatePlanMutation = { __typename?: 'Mutation', planUpdate?: { __typename?: 'PlanUpdate', subscriptionErrors: Array<{ __typename?: 'SubscriptionError', field?: string | null, message?: string | null, code: Types.SubscriptionErrorCode }>, plan?: { __typename?: 'Plan', created: any, description?: string | null, fixedCostAmount: number, fixedOrderCostAmount: number, fixedOrderPercentage: number, id: string, isActive: boolean, isEditable?: boolean | null, name: string, period: Types.PlanPeriod, validFrom: any, validTill?: any | null, fixedCost?: { __typename?: 'Money', amount: number } | null, fixedOrderCost?: { __typename?: 'Money', currency: string, amount: number } | null } | null } | null };


export const UpdatePlanDocument = gql`
    mutation UpdatePlan($id: ID!, $planInput: PlanInput!) {
  planUpdate(id: $id, input: $planInput) {
    subscriptionErrors {
      field
      message
      code
    }
    plan {
      created
      description
      fixedCostAmount
      fixedOrderCostAmount
      fixedOrderPercentage
      id
      isActive
      isEditable
      name
      period
      validFrom
      validTill
      fixedCost {
        amount
      }
      fixedOrderCost {
        currency
        amount
      }
    }
  }
}
    `;
export type UpdatePlanMutationFn = Apollo.MutationFunction<UpdatePlanMutation, UpdatePlanMutationVariables>;

/**
 * __useUpdatePlanMutation__
 *
 * To run a mutation, you first call `useUpdatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanMutation, { data, loading, error }] = useUpdatePlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *      planInput: // value for 'planInput'
 *   },
 * });
 */
export function useUpdatePlanMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlanMutation, UpdatePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlanMutation, UpdatePlanMutationVariables>(UpdatePlanDocument, options);
      }
export type UpdatePlanMutationHookResult = ReturnType<typeof useUpdatePlanMutation>;
export type UpdatePlanMutationResult = Apollo.MutationResult<UpdatePlanMutation>;
export type UpdatePlanMutationOptions = Apollo.BaseMutationOptions<UpdatePlanMutation, UpdatePlanMutationVariables>;