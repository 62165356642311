import { useTranslation } from "@health/i18n";
import { Button } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import React, { FC } from "react";
import { useCustomMuiDialogStyle } from "./CustomDialog.style";
import { CustomMuiDialogActionsProps } from "./CustomMuiDialog.types";

export const CustomMuiDialogActions: FC<CustomMuiDialogActionsProps> = props => {
  const {
    onSubmit,
    disableSubmit = false,
    submitTitle = "Submit",
    closeTitle = "Close",
    onClose: handleClose,
    children,
    hasClose,
    ...rest
  } = props;
  const { classes } = useCustomMuiDialogStyle();
  const { t } = useTranslation();

  return (
    <>
      {(onSubmit || hasClose || children) && (
        <DialogActions className={classes.actions} {...rest}>
          {children}
          {onSubmit && (
            <Button type='submit' disabled={disableSubmit}>
              {submitTitle}
            </Button>
          )}
          {hasClose && (
            <Button variant='outlined' onClick={handleClose}>
              {t(closeTitle)}
            </Button>
          )}
        </DialogActions>
      )}
    </>
  );
};
