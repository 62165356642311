import { useTranslation } from "@health/i18n";
import React, { FC } from "react";
import { ExcelIcon } from "../../../icons/icons";
import { StyledButton } from "./StyledButton.styles";
import { ListActionsProps } from "./types";

export const DownloadImportActions: FC<ListActionsProps> = props => {
  const { hasDownload, hasImport, onImportClicked: handleImportClicked, onDownloadClicked: handleDownloadClicked } = props;
  const { t } = useTranslation();

  return (
    <>
      {hasImport && (
        <StyledButton color='primary' size='medium' startIcon={<ExcelIcon fill='inherit' />} onClick={handleImportClicked}>
          {t("Import")} .xlsx
        </StyledButton>
      )}
      {hasDownload && (
        <StyledButton color='primary' size='medium' startIcon={<ExcelIcon fill='inherit' />} onClick={handleDownloadClicked}>
          {t("Download")} .xlsx
        </StyledButton>
      )}
    </>
  );
};
