import { patternMobile } from "@health/common";

export const locationFields = t => [
  {
    name: "addressInput.area",
    value: "area",
    label: t("Area"),
    isRequired: true,
  },
  {
    name: "addressInput.streetAddress1",
    value: "streetAddress1",
    label: t("English Street Address 1"),
    isRequired: true,
  },
  {
    name: "arabicStreetAddress",
    value: "arabicStreetAddress",
    label: t("Arabic Street Address 1"),
    isRequired: true,
  },
  {
    name: "addressInput.streetAddress2",
    value: "streetAddress2",
    label: t("English Street Address 2"),
    isRequired: false,
  },
  {
    name: "arabicStreetAddress2",
    value: "arabicStreetAddress2",
    label: t("Arabic Street Address 2"),
    isRequired: false,
  },
  {
    name: "addressInput.phone",
    value: "phone",
    label: t("Phone Number"),
    pattern: patternMobile,
    isRequired: false,
  },
];
