const retryLazyRefreshed = "retry-lazy-refreshed";

export const lazyRetry = componentImport => {
  return new Promise<any>((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(retryLazyRefreshed) || "false");
    // try to import the component
    componentImport()
      .then(component => {
        window.sessionStorage.setItem(retryLazyRefreshed, "false"); // success so reset the refresh
        resolve(component);
      })
      .catch(error => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(retryLazyRefreshed, "true"); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behavior as already tried refresh
      });
  });
};
