import { AutocompleteProps, FormControlLabelProps, TextFieldProps, Theme } from "@mui/material";
import { useForm } from "react-hook-form";
import { AutocompleteControllerComponentProps } from "../AutocompleteController/types";
import { CustomDatePickerProps } from "../Form/CustomDatePicker";

type SharedFilterFieldOptions = {
  id?: string | null;
  name?: string;
  grid?: number;
};

export type DynamicFilterField = SharedFilterFieldOptions & {
  fieldProps: { name?: string } & any;
  type: any;
};

type AutocompleteFilterField = SharedFilterFieldOptions & {
  fieldProps: Omit<AutocompleteProps<any, boolean, boolean, boolean>, "popupIcon" | "renderInput"> & {
    name?: string;
    label?: string;
    getValueFromSelectedOptionLabel?: (label: string) => string;
  };
  type: "autocomplete" | FieldsTypes.autocomplete;
};

type TextInputFilterField = SharedFilterFieldOptions & {
  fieldProps: TextFieldProps;
  type: "input" | FieldsTypes.input;
};
type DateInputFilterField = SharedFilterFieldOptions & {
  fieldProps?: Omit<CustomDatePickerProps, "control" | "register">;
  type: "date" | FieldsTypes.date;
};
type CheckboxInputFilterField = SharedFilterFieldOptions & {
  fieldProps?: Omit<FormControlLabelProps, "control"> & { isDisabled?: boolean; label?: string };
  type: "checkbox" | FieldsTypes.checkbox;
};
type LazyAutocompleteInputFilterField = SharedFilterFieldOptions & {
  fieldProps?: {
    defaultValue: any;
    name: string;
    onFetchMore: AutocompleteControllerComponentProps["fetchMoreData"];
    handleInputChange?: (inputValue: string) => void;
  } & Pick<
    AutocompleteControllerComponentProps,
    "placeholder" | "loading" | "isOptionEqualToValue" | "hasMore" | "getOptionLabel" | "options" | "multiple"
  >;
  type: "lazyAutocomplete" | FieldsTypes.lazyAutocomplete;
};

type CustomInputFilterField = SharedFilterFieldOptions & {
  type: "custom" | FieldsTypes.custom;
  component?: any;
};

export type FilterField = SharedFilterFieldOptions &
  (
    | AutocompleteFilterField
    | TextInputFilterField
    | DateInputFilterField
    | CheckboxInputFilterField
    | LazyAutocompleteInputFilterField
    | CustomInputFilterField
  );
export interface IFilterProps {
  title?: string;
  icon?: string;
  children?: any;
  fields?: FilterField[];
  register: any;
  control?;
  onFieldDelete?: (field: any) => void;
  onChangeText?: (field_id: string, keyword: string) => void;
}

export enum FieldsTypes {
  custom = "CUSTOM",
  checkbox = "CHECKBOX",
  autocomplete = "AUTOCOMPLETE",
  lazyAutocomplete = "LAZY_AUTOCOMPLETE",
  input = "INPUT",
  date = "DATE",
}

// type FieldsType = "custom" | "autocomplete" | "input";
export interface IFields {
  props: {
    name?: string;
    isFloat?: boolean;
    component?: any;
    onChange: AutocompleteControllerComponentProps["onChange"] | ((fieldId: string) => void);
  } & FilterField;
  register: ReturnType<typeof useForm>["register"];
  control: ReturnType<typeof useForm>["control"];
}

export interface FiltersStyleProps {
  theme?: Theme;
}
