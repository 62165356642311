import { i18next } from "@health/i18n";
import { Decision, DecisionMakerOrderDirection, DecisionSortField } from "@health/queries";
import { CustomTableColumnProps } from "@health/ui";

export const getColumns = (t: i18next.TFunction): CustomTableColumnProps<Decision>[] => [
  {
    key: "Number",
    header: t("Rule Name"),
    accessor: ({ name }) => name,
    type: "string",
    disableToggleVisibility: true,
    isSortable: true,
    sortDirection: DecisionMakerOrderDirection.Asc,
    sortColumnEnum: DecisionSortField.Name,
  },
  {
    key: "plan",
    header: t("Decision Plan"),
    accessor: ({ decisionPlan }) => decisionPlan?.display || "",
    type: "string",
    disableToggleVisibility: true,
    isSortable: true,
    sortDirection: undefined,
    sortColumnEnum: DecisionSortField.DecisionPlan,
  },
  {
    key: "type",
    header: t("Type"),
    accessor: ({ isScript }) => (isScript ? t("Script") : t("Dynamic")),
    type: "string",
    disableToggleVisibility: true,
    isSortable: true,
    sortDirection: undefined,
    sortColumnEnum: DecisionSortField.Type,
  },
  {
    key: "type",
    header: t("is Active"),
    accessor: ({ isActive }) => (isActive ? t("Active") : t("No")),
    type: "string",
    disableToggleVisibility: true,
    isSortable: true,
    sortDirection: undefined,
    sortColumnEnum: DecisionSortField.IsActive,
  },
];
