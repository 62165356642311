import List from "@mui/material/List";
import arrayMove from "array-move";
import React, { FC } from "react";
import { Container } from "react-smooth-dnd";
import { useTablePreferencesContext } from "../../TablePreferencesDialog.hooks";
import { SortableItem } from "../SortableItem";

export const SortableList: FC = () => {
  const { columns, setColumns } = useTablePreferencesContext();

  const handleDropItem = ({ removedIndex, addedIndex }) => {
    setColumns(arrayMove(columns, removedIndex, addedIndex));
  };

  const handleChangeVisibility = (key: string) => {
    const changedColumns = columns?.map(item => {
      return item?.key === key ? { ...item, isHidden: !item?.isHidden } : item;
    });
    setColumns(changedColumns);
  };

  return (
    <List>
      <Container dragHandleSelector='.drag-handle' lockAxis='y' onDrop={handleDropItem}>
        {!!columns?.length &&
          columns?.map(({ key, header, isHidden }) => (
            <SortableItem key={key} id={key} header={header} isHidden={isHidden} onChangeVisibility={handleChangeVisibility} />
          ))}
      </Container>
    </List>
  );
};
